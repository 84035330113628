import { Stepper } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { getStep } from "app/redux/resetPasswordWizard/resetPasswordWizard.selectors"
import { actions as wizardActions } from "app/redux/resetPasswordWizard/resetPasswordWizard.actions"
import { useDispatch, useSelector } from "react-redux"
import VerifyIdentity from "app/components/VerifyIdentity"
import EditPassword from "./EditPassword"
import { useEffect } from "react"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { Action as ReduxAction } from "redux"

export type RenewPasswordStepperProps = {
  handleOnVerification: (currentPassword: string, actionOnSuccess: () => ReduxAction<any>) => void
  handleOnPasswordChange: (newPassword: string) => void
  actionOnSuccess: () => ReduxAction<any>
  description: string
}

const RenewPasswordStepper = ({
  handleOnVerification,
  description,
  handleOnPasswordChange,
  actionOnSuccess
}: RenewPasswordStepperProps) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const step = useSelector(getStep)

  useEffect(() => {
    return () => {
      dispatch(wizardActions.clearWizard())
      dispatch(remove("renew-password-error"))
    }
  }, [dispatch])

  return (
    <Stepper
      stepText={translate("portal.account.account.password.step.label")}
      activeStep={step}
      config={[
        {
          title: translate("portal.authentication.title"),
          content: (
            <VerifyIdentity
              description={description}
              onSubmit={handleOnVerification}
              actionOnSuccess={actionOnSuccess}
            />
          )
        },
        {
          title: translate("portal.account.account.password.step2"),
          content: <EditPassword handleOnPasswordChange={handleOnPasswordChange} />
        }
      ]}
    />
  )
}

export default RenewPasswordStepper
