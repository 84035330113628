import BuildingSelectionWidget from "../BuildingSelectionWidget"
import { WidgetContainer, DashboardWrapper } from "./style"
import FacilityManagementWidget from "../DashboardWidgets/FacilityManagement"
import InvoicesWidget from "../DashboardWidgets/Invoices"
import EnergyWidget from "../DashboardWidgets/Energy"
import DocumentsWidget from "../DashboardWidgets/Documents"
import BuildingPassportWidget from "../DashboardWidgets/BuildingPassport"
import ExpertWidget from "../DashboardWidgets/Experts"
import { useSelector } from "react-redux"
import { getRentedBuildings } from "app/redux/me/me.selectors"
import NotificationBar from "./NotificationBar"
import ContactWidget from "../ContactWidget"

const DashboardWidgets = () => {
  const rentedBuildings = useSelector(getRentedBuildings)

  return (
    <DashboardWrapper>
      <NotificationBar />
      <WidgetContainer>
        <FacilityManagementWidget />
        <InvoicesWidget />
        <EnergyWidget />
        <DocumentsWidget />
        <BuildingPassportWidget />
        <ExpertWidget />
      </WidgetContainer>
      {rentedBuildings && <BuildingSelectionWidget />}
      <ContactWidget />
    </DashboardWrapper>
  )
}

export default DashboardWidgets
