import {
  Button,
  Typography,
  OnboardingSuccessImage
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Caption, Container, TypographyWrapper } from "./style"
import { patchContactPersonOnboarding } from "app/redux/contactPerson/contactPerson.actions"
import * as meSelectors from "app/redux/me/me.selectors"
import { useApi } from "hooks/api"
import { usePermission } from "hooks/permission"
import FaqLink from "../FaqLink"

const RegisterSuccess = () => {
  const { isExternal } = usePermission()
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const { call } = useApi()
  const contactPersonId = useSelector(meSelectors.getUserId)
  const preferredBuildingId = useSelector(meSelectors.getPreferredBuildingId)
  const isAdminOnPreferredBuilding = useSelector(
    meSelectors.isAdminOnPreferredBuilding(preferredBuildingId)
  )
  const isExternalUser = isExternal()

  const hasInvitedColleagues =
    sessionStorage.getItem("colleaguesInvited") === "true"

  useEffect(() => {
    if (contactPersonId) call(patchContactPersonOnboarding(contactPersonId))
  }, [call, contactPersonId])

  const handleClick = () => {
    navigate(
      `/${
        isExternalUser ? "facility-management" : "dashboard"
      }/${preferredBuildingId}`
    )
  }

  return (
    <Container>
      <OnboardingSuccessImage />
      <TypographyWrapper>
        <Typography variant="h4" fontStyle="bold">
          <>
            {!isAdminOnPreferredBuilding &&
              translate("portal.onboarding.success.notinvited")}
            {isAdminOnPreferredBuilding &&
              translate(
                `portal.onboarding.success.${
                  hasInvitedColleagues ? "invited" : "notinvited"
                }`
              )}
          </>
        </Typography>
      </TypographyWrapper>
      <Caption>
        {!isAdminOnPreferredBuilding &&
          translate("portal.onboarding.success.onboarded")}
        {isAdminOnPreferredBuilding &&
          translate(
            `portal.onboarding.success.colleagues.${
              hasInvitedColleagues ? "invited" : "skipped"
            }`
          )}
      </Caption>
      <Button
        dataTestId="portal.onboarding.success"
        onClick={handleClick}
        label={translate(
          hasInvitedColleagues
            ? "portal.onboarding.success.homepage"
            : "portal.onboarding.success.start"
        )}
      />
      <FaqLink
        linkContent="portal.onboarding.success.link.guidance"
        onClick={() => {
          navigate("/help")
        }}
      />
    </Container>
  )
}

export default RegisterSuccess
