import { Co2CalculationMethods, EnergyUsageComparisonData } from "app/types/energy"

export enum Types {
  GET = "GET_ENERGY_USAGE_COMPARISON",
  GET_SUCCESS = "GET_ENERGY_USAGE_COMPARISON_SUCCESS",
  GET_FAIL = "GET_ENERGY_USAGE_COMPARISON_FAIL"
}

export type EnergyUsageQueryParams = {
  previousPeriodFrom: string
  previousPeriodTo: string
  currentPeriodFrom: string
  currentPeriodTo: string
  co2CalculationMethod: Co2CalculationMethods
}

export type Action =
  | GetEnergyUsageComparisonAction
  | GetEnergyUsageComparisonSuccessAction
  | GetEnergyUsageComparisonFailAction

export type GetEnergyUsageComparisonAction = {
  type: Types.GET
  payload: {
    rentedBuildingId: string
    country: string
    params: EnergyUsageQueryParams
  }
}

export type GetEnergyUsageComparisonSuccessAction = {
  type: Types.GET_SUCCESS
  payload: {
    data: EnergyUsageComparisonData[]
  }
}

export type GetEnergyUsageComparisonFailAction = {
  type: Types.GET_FAIL
  payload: {
    errors: { status: number } & Record<string, unknown>
  }
}

export function getEnergyUsageComparison(
  rentedBuildingId: string,
  country: string,
  params: EnergyUsageQueryParams
): Action {
  return {
    type: Types.GET,
    payload: {
      rentedBuildingId,
      country,
      params
    }
  }
}

export function getEnergyUsageComparisonSuccess(
  data: EnergyUsageComparisonData[]
): Action {
  return {
    type: Types.GET_SUCCESS,
    payload: {
      data
    }
  }
}

export function getEnergyUsageComparisonFail(
  errors: { status: number } & Record<string, unknown>
): Action {
  return {
    type: Types.GET_FAIL,
    payload: {
      errors
    }
  }
}
