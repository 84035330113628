import {
  Document,
  DocumentResponse,
  DocumentStatisticsDto,
  PostDocument,
  SetDocumentFieldType
} from "app/types/document"

export enum Types {
  GET = "GET_DOCUMENTS",
  GET_SUCCESS = "GET_DOCUMENTS_SUCCESS",
  GET_FAIL = "GET_DOCUMENTS_FAIL",
  DOWNLOAD = "DOWNLOAD_DOCUMENT",
  DOWNLOAD_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS",
  DOWNLOAD_FAIL = "DOWNLOAD_DOCUMENT_FAIL",
  DOWNLOAD_CSV = "DOWNLOAD_DOCUMENT_CSV",
  DOWNLOAD_CSV_SUCCESS = "DOWNLOAD_DOCUMENT_CSV_SUCCESS",
  DOWNLOAD_CSV_FAIL = "DOWNLOAD_DOCUMENT_CSV_FAIL",
  GET_STATISTICS = "GET_DOCUMENTS_STATISTICS",
  GET_STATISTICS_SUCCESS = "GET_DOCUMENTS_STATISTICS_SUCCESS",
  GET_STATISTICS_FAIL = "GET_DOCUMENTS_STATISTICS_FAIL",
  GET_SUMMARY = "GET_DOCUMENTS_SUMMARY",
  GET_SUMMARY_SUCCESS = "GET_DOCUMENTS_SUMMARY_SUCCESS",
  GET_SUMMARY_FAIL = "GET_DOCUMENTS_SUMMARY_FAIL",
  GET_SELECTED_DOCUMENTS_FILTERS = "GET_SELECTED_DOCUMENTS_FILTERS",
  POST = "POST_DOCUMENTS",
  POST_SUCCESS = "POST_DOCUMENTS_SUCCESS",
  POST_FAIL = "POST_DOCUMENTS_FAIL",
  SET_FIELD_VALUE = "SET_DOCUMENT_FIELD_VALUE",
  DISCARD_DOCUMENT = "DISCARD_DOCUMENT"
}

export type GetDocumentsCSVParams = {
  contracts?: string[]
  documentTypes?: string[]
  uploadedBy?: string
  uploadDateFrom?: string
  uploadDateTo?: string
}

export type GetDocumentParams = {
  rentedBuildingId: string
  page: number
  perPage: number
  documentType?: string
  uploadDateFrom?: string
  uploadDateTo?: string
  uploadedBy?: string
  inspectionDateFrom?: string
  inspectionDateTo?: string
  contractId?: string[]
  sort?: string[]
}

export type PostDocumentParams = Omit<GetDocumentParams, "rentedBuildingId">

export type Action =
  | GetDocumentsAction
  | GetDocumentsSuccessAction
  | GetDocumentsFailAction
  | DownloadDocumentAction
  | DownloadDocumentSuccessAction
  | DownloadDocumentFailAction
  | DownloadDocumentsCSVAction
  | DownloadDocumentsCSVSuccessAction
  | DownloadDocumentsCSVFailAction
  | GetDocumentsStatisticsAction
  | GetDocumentsStatisticsSuccessAction
  | GetDocumentsStatisticsFailAction
  | GetDocumentsSummaryAction
  | GetDocumentsSummarySuccessAction
  | GetDocumentsSummaryFailAction
  | GetSelectedDocumentsFiltersAction
  | PostDocumentsAction
  | PostDocumentsSuccessAction
  | PostDocumentsFailAction
  | SetFieldAction
  | DiscardDocumentActions

export type DiscardDocumentActions = {
  type: Types.DISCARD_DOCUMENT
}

export type GetDocumentsAction = {
  payload: GetDocumentParams
  type: Types.GET
}

export type GetDocumentsSuccessAction = {
  payload: { data: DocumentResponse }
  type: Types.GET_SUCCESS
}

export type GetDocumentsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type DownloadDocumentAction = {
  payload: { id: string }
  type: Types.DOWNLOAD
}

export type DownloadDocumentSuccessAction = {
  type: Types.DOWNLOAD_SUCCESS
}

export type DownloadDocumentFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DOWNLOAD_FAIL
}

export type DownloadDocumentsCSVAction = {
  payload: { data: GetDocumentsCSVParams }
  type: Types.DOWNLOAD_CSV
}

export type DownloadDocumentsCSVSuccessAction = {
  type: Types.DOWNLOAD_CSV_SUCCESS
}

export type DownloadDocumentsCSVFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DOWNLOAD_CSV_FAIL
}

export type GetDocumentsStatisticsAction = {
  payload: {
    rentedBuildingId: string
  }
  type: Types.GET_STATISTICS
}

export type GetDocumentsStatisticsSuccessAction = {
  payload: { data: DocumentStatisticsDto[] }
  type: Types.GET_STATISTICS_SUCCESS
}

export type GetDocumentsStatisticsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_STATISTICS_FAIL
}

export type GetDocumentsSummaryAction = {
  payload: {
    rentedBuildingId: string
  }
  type: Types.GET_SUMMARY
}

export type GetDocumentsSummarySuccessAction = {
  payload: { data: Document[] }
  type: Types.GET_SUMMARY_SUCCESS
}

export type GetDocumentsSummaryFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_SUMMARY_FAIL
}

export type GetSelectedDocumentsFiltersAction = {
  payload: boolean
  type: Types.GET_SELECTED_DOCUMENTS_FILTERS
}

export type PostDocumentsAction = {
  payload: {
    document: PostDocument
    documentParams: PostDocumentParams
  }
  type: Types.POST
}

export type PostDocumentsSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostDocumentsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.POST_FAIL
}

export type SetFieldAction = {
  payload: {
    key: keyof SetDocumentFieldType
    value: string | Record<string, string> | File[] | undefined
  }
  type: Types.SET_FIELD_VALUE
}

export function discardDocument(): Action {
  return {
    type: Types.DISCARD_DOCUMENT
  }
}

export function getDocuments(payload: GetDocumentParams): Action {
  return {
    type: Types.GET,
    payload
  }
}

export function getDocumentsSuccess(data: DocumentResponse): Action {
  return {
    type: Types.GET_SUCCESS,
    payload: {
      data
    }
  }
}

export function getDocumentsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function downloadDocument(id: string): Action {
  return {
    type: Types.DOWNLOAD,
    payload: { id }
  }
}

export function downloadDocumentSuccess(): Action {
  return {
    type: Types.DOWNLOAD_SUCCESS
  }
}

export function downloadDocumentFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.DOWNLOAD_FAIL
  }
}

export function downloadDocumentsCSV(data: GetDocumentsCSVParams): Action {
  return {
    payload: { data },
    type: Types.DOWNLOAD_CSV
  }
}

export function downloadDocumentsCSVSuccess(): Action {
  return {
    type: Types.DOWNLOAD_CSV_SUCCESS
  }
}

export function downloadDocumentsCSVFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.DOWNLOAD_CSV_FAIL
  }
}

export function getDocumentsStatistics(rentedBuildingId: string): Action {
  return {
    type: Types.GET_STATISTICS,
    payload: {
      rentedBuildingId
    }
  }
}

export function getDocumentsStatisticsSuccess(
  data: DocumentStatisticsDto[]
): Action {
  return {
    type: Types.GET_STATISTICS_SUCCESS,
    payload: {
      data
    }
  }
}

export function getDocumentsStatisticsFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_STATISTICS_FAIL
  }
}

export function getDocumentsSummary(rentedBuildingId: string): Action {
  return {
    type: Types.GET_SUMMARY,
    payload: {
      rentedBuildingId
    }
  }
}

export function getDocumentsSummarySuccess(data: Document[]): Action {
  return {
    type: Types.GET_SUMMARY_SUCCESS,
    payload: {
      data
    }
  }
}

export function getDocumentsSummaryFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_SUMMARY_FAIL
  }
}

export function getSelectedDocumentsFilters(
  isOpen: boolean
): GetSelectedDocumentsFiltersAction {
  return {
    payload: isOpen,
    type: Types.GET_SELECTED_DOCUMENTS_FILTERS
  }
}

export function postDocuments(
  payload: PostDocument,
  filters: PostDocumentParams
): Action {
  return {
    type: Types.POST,
    payload: {
      document: payload,
      documentParams: filters
    }
  }
}

export function postDocumentsSuccess(): Action {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postDocumentsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function setFieldValue(
  key: keyof SetDocumentFieldType,
  value: string | {} | File[] | undefined
): Action {
  return {
    payload: { key, value },
    type: Types.SET_FIELD_VALUE
  }
}
