import { SecurityDeposit } from "app/types/rentedBuildingContract"
import ContractualGuarantee from "../ContractualGuarantee"

type ContractualGuaranteeListProps = {
  securityDeposits: SecurityDeposit[]
}

type Props = ContractualGuaranteeListProps

const ContractualGuaranteeList = ({
  securityDeposits
}: Props) => {
  return (
    <>
      {securityDeposits.map((securityDeposit) => {
        return <ContractualGuarantee securityDeposit={securityDeposit} key={securityDeposit.sourceId} />
      })}
    </>
  )
}

export default ContractualGuaranteeList
