import { Action, HttpErrorMapping, Types } from "./httpErrors.actions"

export type HttpErrorsState = {
  httpStatus?: number
  httpMessage?: string
  mapping: HttpErrorMapping
}

const initialState: HttpErrorsState = {
  httpStatus: undefined,
  httpMessage: undefined,
  mapping: []
}

export default (state = initialState, action: Action): HttpErrorsState => {
  switch (action.type) {
    case Types.HANDLE_HTTP_ERROR:
      return {
        ...state,
        httpStatus: action.payload.error.response?.status,
        httpMessage: action.payload.error.response?.data?.message,
        mapping: action.payload.mapping
      }

    case Types.HANDLE_HTTP_ERROR_NO_MATCH:
      return {
        ...state,
        httpStatus: action.httpStatus,
        httpMessage: action.httpMessage
      }

    case Types.CLEAR_HTTP_ERROR:
      return {
        httpStatus: undefined,
        httpMessage: undefined,
        mapping: []
      }

    default:
      return state
  }
}
