import { useSelector } from "react-redux"
import * as meSelectors from "app/redux/me/me.selectors"
import * as responsibilitySelectors from "app/redux/responsibilities/responsibilities.selectors"
import MultiBuildingUserResponsibilities from "./MultiBuildingUserResponsibilities"
import SingleBuildingUserResponsibilities from "./SingleBuildingUserResponsibilities"
import { mapResponsibilityReducerDataToNestedCheckboxDataStructure } from "app/utils/responsibilities.utils"
import { Separator, theme, WarningIcon } from "@paudigital/wdp-components"
import { RentedbuildingResponsibilities } from "app/types/responsibilities"
import React from "react"
import { ErrorContainer, ErrorText } from "./style"
import { useTranslate } from "hooks/translate"

type UserResponsibilitiesProps = {
  rentedbuildingResponsibilities: RentedbuildingResponsibilities[]
}

type Props = UserResponsibilitiesProps

const UserResponsibilities = ({ rentedbuildingResponsibilities }: Props) => {
  const { translate } = useTranslate()
  const hasSingleRentedBuilding = useSelector(
    meSelectors.hasSingleRentedBuilding
  )
  const hasMultipleCompanies = useSelector(
    meSelectors.hasMultipleCompanies
  )
  const responsibilityErrors = useSelector(responsibilitySelectors.getErrors)
  return (
    <>
      {hasSingleRentedBuilding && rentedbuildingResponsibilities.length > 0 ? (
        <SingleBuildingUserResponsibilities
          rentedBuildingId={rentedbuildingResponsibilities[0].rentedBuildingId}
          settingsData={mapResponsibilityReducerDataToNestedCheckboxDataStructure(
            rentedbuildingResponsibilities[0],
            hasMultipleCompanies
          )}
        />
      ) : (
        rentedbuildingResponsibilities.map((building, i) => (
          <React.Fragment key={building.rentedBuildingId}>
            <MultiBuildingUserResponsibilities
              rentedBuildingId={building.rentedBuildingId}
              settingsData={mapResponsibilityReducerDataToNestedCheckboxDataStructure(
                building,
                hasMultipleCompanies
              )}
              collapsed={false}
            />
            {i !== Object.keys(rentedbuildingResponsibilities).length - 1 && (
              <Separator />
            )}
          </React.Fragment>
        ))
      )}
      {responsibilityErrors && (
        <ErrorContainer>
          <WarningIcon
            width="16"
            height="16"
            fill={theme.colors.system.error}
          />
          <ErrorText>
            {translate(
              "portal.account.account.profile.edit.modal.responsibilities.error"
            )}
          </ErrorText>
        </ErrorContainer>
      )}
    </>
  )
}

export default UserResponsibilities
