import { theme, WarningIcon, InlineCustomSelect, ToolTip } from "@paudigital/wdp-components"
import { setCo2CalculationMethod } from "app/redux/energyPeriod/energyPeriod.actions"
import { getCo2CalculationMethod } from "app/redux/energyPeriod/energyPeriod.selectors"
import { Co2CalculationMethods } from "app/types/energy"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import DisclaimerTooltip from "./DisclaimerTooltip"
import {
  DisclaimerWrapper,
  StyledDisclaimer,
  StyledDisclaimerText
} from "./style"

const Co2CalculationDisclaimer = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const currentMethod = useSelector(getCo2CalculationMethod)

  const options = [
    {
      option: (
        <>{translate("portal.energy.comparison.co2.disclaimer.option.grey")}</>
      ),
      value: Co2CalculationMethods.GREY
    },
    {
      option: (
        <>{translate("portal.energy.comparison.co2.disclaimer.option.green")}</>
      ),
      value: Co2CalculationMethods.GREEN
    }
  ]

  const handleChange = (val: Co2CalculationMethods) => {
    dispatch(setCo2CalculationMethod(val))
  }

  return (
    <DisclaimerWrapper>
      <StyledDisclaimer>*</StyledDisclaimer>
      <StyledDisclaimerText>
        {translate("portal.energy.comparison.co2.disclaimer")}
      </StyledDisclaimerText>
      <InlineCustomSelect
        options={options}
        value={currentMethod}
        openDirection={"UP"}
        onChange={handleChange}
      />
      <StyledDisclaimerText>
        {translate("portal.energy.comparison.co2.disclaimer.grid.power")}
      </StyledDisclaimerText>
      <ToolTip value={<DisclaimerTooltip />} width="27rem">
        <WarningIcon
          width="16"
          height="16"
          fill={theme.colors.neutral.darkGrey}
        />
      </ToolTip>
    </DisclaimerWrapper>
  )
}

export default Co2CalculationDisclaimer
