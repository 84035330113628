import { InputLabel, Button, Link } from "@paudigital/wdp-components"
import React from "react"
import { Description, ButtonWrapper } from "./style"
import { actions } from "app/redux/registrationWizard/registrationWizard.actions"
import { useDispatch, useSelector } from "react-redux"
import { getRentedBuildings } from "app/redux/me/me.selectors"
import { getUser } from "app/redux/me/me.selectors"
import { patchContactPerson } from "app/redux/contactPerson/contactPerson.actions"
import PreferedBuilding from "app/components/PreferedBuilding"
import { useTranslate } from "hooks/translate"
import { useApi } from "hooks/api"

const BuildingPreferences = () => {
  const { translate } = useTranslate()
  const rentedbuildings = useSelector(getRentedBuildings)
  const contactPerson = useSelector(getUser)
  const [preferredBuilding, setPreferredBuilding] = React.useState<
    string | null
  >(null)
  const { call } = useApi()
  const dispatch = useDispatch()
  const contactPreferedBuilding = contactPerson?.preferredBuilding

  React.useEffect(() => {
    if (contactPreferedBuilding) {
      setPreferredBuilding(contactPreferedBuilding)
    }
  }, [contactPreferedBuilding])

  React.useEffect(() => {
    if (!contactPerson?.preferredBuilding && rentedbuildings?.[0])
      setPreferredBuilding(rentedbuildings[0].id)
  }, [contactPerson, rentedbuildings])

  React.useEffect(() => {
    if (contactPerson?.preferredBuilding) {
      setPreferredBuilding(contactPerson.preferredBuilding)
    }
  }, [contactPerson])

  const handleNext = () => {
    const id = contactPerson?.id
    if (id && contactPerson && preferredBuilding) {
      call(
        patchContactPerson(id, {
          ...contactPerson,
          preferredBuilding,
          termsAndConditionsAccepted: true,
          privacyPolicyAccepted: true
        })
      )
    }
  }

  const handlePrevious = () => {
    dispatch(actions.decreaseWizard())
  }

  const handlePreferedBuildingChange = (id: string) => {
    setPreferredBuilding(id)
  }

  return (
    <>
      <Description dataTestId="portal.onboarding.step2.title.label">
        <InputLabel
          required
          name="header"
          label={translate("portal.onboarding.step2.title.label")}
          guidanceText={translate("portal.onboarding.step2.title.guidanceText")}
        />
      </Description>
      {rentedbuildings?.map(r => (
        <PreferedBuilding
          key={`preferred-building-${r.id}`}
          rentedBuilding={r}
          onClick={handlePreferedBuildingChange}
          selected={preferredBuilding === r.id}
        />
      ))}
      <ButtonWrapper>
        <Link
          type="default"
          onClick={handlePrevious}
          value={translate("portal.onboarding.step2.button.previous")}
        />
        <Button
          dataTestId="portal.onboarding.step2.button.next"
          label={translate("portal.onboarding.step2.button.next")}
          onClick={handleNext}
        />
      </ButtonWrapper>
    </>
  )
}

export default BuildingPreferences
