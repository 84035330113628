import {
  changeChildValue,
  Data,
  GroupedCheckbox
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useDispatch } from "react-redux"
import * as responsibilityActions from "app/redux/responsibilities/responsibilities.actions"
import { reduceNestedCheckboxDataToResponsibilityReducerData } from "app/utils/responsibilities.utils"

type SingleBuildingUserResponsibilitiesProps = {
  rentedBuildingId: string
  settingsData: Data
}

type Props = SingleBuildingUserResponsibilitiesProps

const SingleBuildingUserResponsibilities = ({
  rentedBuildingId,
  settingsData
}: Props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()

  const translatedSettingsData = {
    parent: settingsData.parent,
    children: settingsData.children.map(currentChild => {
      return {
        label: translate(currentChild.label),
        name: currentChild.name ?? "",
        guidanceText: translate(currentChild.guidanceText ?? ""),
        selected: currentChild.selected
      }
    })
  }

  const handleChange = (val: boolean, name: string) => {
    const updateData = reduceNestedCheckboxDataToResponsibilityReducerData(
      rentedBuildingId,
      changeChildValue(settingsData, val, name)
    )
    dispatch(responsibilityActions.updateResponsibilities(updateData))
  }

  return (
    <>
      <GroupedCheckbox
        onChange={handleChange}
        data={translatedSettingsData.children}
      />
    </>
  )
}

export default SingleBuildingUserResponsibilities
