export enum Types {
  SET = "SET_BUILDING_BUILDING_SWITCHER"
}

export type Action = SetBuildingSwitcherAction

export type SetBuildingSwitcherAction = {
  payload: { id: string }
  type: Types.SET
}

export function setBuildingSwitcher(id: string): Action {
  return {
    payload: { id },
    type: Types.SET
  }
}
