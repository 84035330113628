export enum Modules {
  DASHBOARD = "dashboard",
  ENERGY = "energy",
  INVOICES = "invoices",
  FACILITY_MANAGEMENT = "facility-management",
  DOCUMENTS = "documents",
  USER_MANAGEMENT = "user-management",
  BUILDING = "building-passport",
  CONTRACTS = "contracts",
  PLANS = "plans",
  NOTIFICATIONS = "notifications",
  COMMERCIAL = "commercial",
  ACCOUNT = "account",
  FAQ = "faq"
}
