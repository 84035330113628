import { QueryParams } from "app/redux/invoices/invoices.actions"
import { GetInvoiceCSVParams } from "app/types/invoice"
import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getInvoices = ({
  rentedBuildingId,
  ...params
}: QueryParams): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/invoices`, {
    method: "GET",
    params
  })
}

export const getInvoicesSummary = (rentedBuildingId: string): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/invoices_summary`, {
    method: "GET"
  })
}

export const downloadInvoiceCSV = (
  data: GetInvoiceCSVParams,
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/invoices.csv`, {
    method: "GET",
    params: { ...data }
  })
}
