import ElectricityConsumptionGraph from "./Graphs/ElectricityConsumptionGraph"
import SolarProductionGraph from "./Graphs/SolarProductionGraph"
import GasConsumptionGraph from "./Graphs/GasConsumptionGraph"
import WaterConsumptionGraph from "./Graphs/WaterConsumptionGraph"
import ContactFooter from "../ContactFooter"
import { GraphWrapper, StyledNoticationWrapper } from "./style"
import { Modules } from "app/enums/modules"
import { availableMeters, loading } from "app/redux/graphs/graphs.selectors"
import { useDispatch, useSelector } from "react-redux"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useApi } from "hooks/api"
import { getAvailableMeters, TagEnum } from "app/redux/graphs/graphs.actions"
import React, { useEffect, useMemo } from "react"
import EnergyUsageComparisonOverview from "../EnergyUsageComparisonOverview"
import * as appEventActions from "../../../app/redux/appEvents/appEvents.actions"
import { PageWrapper } from "./style"
import { Notification } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { hasWarning as electricityHasWarning } from "app/redux/electricityMeterHistory/electricityMeterHistory.selectors"
import { hasWarning as solarHasWarning } from "app/redux/solarProductionHistory/solarProductionHistory.selectors"
import { hasWarning as gasHasWarning } from "app/redux/gasMeterHistory/gasMeterHistory.selectors"
import { hasWarning as waterHasWarning } from "app/redux/waterMeterHistory/waterMeterHistory.selectors"
import { NoEnergyReasons } from "app/types/rentedBuilding"
import { getPvProject } from "app/redux/pvProject/pvProject.actions"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { PvProjectOverview } from "app/components/PvProjectOverview"
import * as selectors from "app/redux/pvProject/pvProject.selectors"

const Energy = () => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const { rentedBuilding } = useRentedBuilding()
  const rentedBuidingAvailableMeters = useSelector(availableMeters)
  const isLoading = useSelector(loading)
  const e = useSelector(electricityHasWarning)
  const s = useSelector(solarHasWarning)
  const g = useSelector(gasHasWarning)
  const w = useSelector(waterHasWarning)
  const rentedBuildingId = useSelector(getActiveBuilding)
  const pvProject = useSelector(selectors.getPvProject)

  const hasEnergyReason =
    rentedBuilding?.noEnergyReason !== NoEnergyReasons.NANOGRID_READY
  const hasMeters = useMemo(() => {
    return rentedBuidingAvailableMeters.length > 0
  }, [rentedBuidingAvailableMeters.length])

  const showWarning = hasMeters && (e || s || g || w)

  useEffect(() => {
    if (rentedBuildingId) {
      call(getPvProject(rentedBuildingId))
    }
  }, [call, rentedBuildingId])

  React.useEffect(() => {
    if (!hasEnergyReason && rentedBuilding?.id) {
      call(
        getAvailableMeters(rentedBuilding.id, {
          tags: [
            TagEnum.ELECTRICITY_CONSUMPTION,
            TagEnum.GAS_MAIN,
            TagEnum.WATER_MAIN,
            TagEnum.ELECTRICITY_YIELD_INJECTION
          ]
        })
      )
    }
    return () => {
      dispatch(appEventActions.remove("energy-incomplete-data-error"))
    }
  }, [call, hasEnergyReason, rentedBuilding?.id, dispatch])

  const meterAvailable = (meterType: TagEnum) =>
    rentedBuidingAvailableMeters?.find(meter => meter.tag === meterType)

  const renderGraph = (graph: string): JSX.Element | null => {
    switch (graph) {
      case TagEnum.ELECTRICITY_CONSUMPTION:
        return <ElectricityConsumptionGraph />
      case TagEnum.ELECTRICITY_YIELD_INJECTION:
        return meterAvailable(TagEnum.ELECTRICITY_CONSUMPTION) ? (
          <SolarProductionGraph />
        ) : null
      case TagEnum.GAS_MAIN:
        return <GasConsumptionGraph />
      case TagEnum.WATER_MAIN:
        return <WaterConsumptionGraph />
      default:
        return null
    }
  }

  const graphs = (Object.keys(TagEnum) as Array<keyof typeof TagEnum>).map(
    tag => {
      const meter = meterAvailable(TagEnum[tag])
      return meter && !isLoading ? (
        <GraphWrapper key={meter.id}>{renderGraph(meter!.tag)}</GraphWrapper>
      ) : null
    }
  )

  return (
    <PageWrapper>
      {pvProject && <PvProjectOverview project={pvProject} />}
      {showWarning && (
        <StyledNoticationWrapper>
          <Notification
            show
            description={translate(
              "portal.energy.graphs.notification.error.message"
            )}
            variant="warning"
            delayInMs={0}
          />
        </StyledNoticationWrapper>
      )}
      <EnergyUsageComparisonOverview />
      {hasMeters && graphs}
      <ContactFooter module={Modules.ENERGY} />
    </PageWrapper>
  )
}

export default Energy
