import ListItem from "app/components/ListItem"

type Props = {
  label: string
  onClick: () => void
}

const BackButton = ({ onClick, label }: Props) => {
  return <ListItem label={label} onClick={onClick} isBackButton></ListItem>
}
export default BackButton
