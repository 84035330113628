import {
  deleteContactPersonOnRentedBuildings,
  getContactPersonRentedBuildingsWithAvailableContactPersons
} from "app/api/contactPerson"
import {
  ContactPerson,
  ContactPersonRentedBuildingsWithAvailableContactPersons
} from "app/types/contactPerson"
import { SagaIterator } from "redux-saga"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./deleteContactPerson.actions"
import * as meSelectors from "../me/me.selectors"
import { RentedBuilding } from "app/types/rentedBuilding"
import {
  determineDeleteFlow,
  sortRentedBuilding
} from "app/utils/deleteContactPerson.utils"
import { SortedBuildings } from "./deleteContactPerson.reducer"
import { getAssignedPersons } from "app/api/rentedBuilding"
import { getContactPersonsSuccess } from "../rentedBuildingContactPerson/rentedBuildingContactPerson.actions"
import { getContactPersonPerRentedBuilding } from "../rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import * as appEventActions from "../appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { intl, Locales } from "app/i18n/config"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* determineDeleteModalFlow(
  action: actions.DetermineDeleteFlowAction
): SagaIterator {
  try {
    // Get the rented buildings contact persons where the contact person is assigned to
    const response = yield call(
      getContactPersonRentedBuildingsWithAvailableContactPersons,
      action.payload.contactPersonId
    )
    const cpRentedBuildings: ContactPersonRentedBuildingsWithAvailableContactPersons[] =
      response.data
    // Get the rented buildings of the user where he is admin
    const currentUserId: string = yield select(meSelectors.getUserId)
    const userRentedBuildingsWhereAdmin: RentedBuilding[] = yield select(
      meSelectors.getRentedBuildingsWhereAdmin
    )
    // Filter the buildings where the user can delete the contact person on following conditions:
    // - User is present on cp building and admin of it
    const sortedBuildings = cpRentedBuildings.reduce(
      (sortedBuildings, cpRentedBuilding) =>
        sortRentedBuilding(
          sortedBuildings,
          cpRentedBuilding,
          userRentedBuildingsWhereAdmin
        ),
      { canDeleteFrom: [], cannotDeleteFrom: [] } as SortedBuildings
    )
    // Determine the flow based on the following parameters:
    // - Check if current user id is the same as the cp id you are going to delete
    // - Amount of buildings cp can be deleted from
    // - Check if cp is still assigned to other building(s) not controlled by user
    // - Is cp an admin on all buildings
    const isMultiBuildingDelete = sortedBuildings.canDeleteFrom.length > 1
    const isAssignedToBuildingsNotManagedByUser =
      sortedBuildings.cannotDeleteFrom.length > 0

    const determinedFlow = determineDeleteFlow(
      currentUserId,
      action.payload.contactPersonId,
      isMultiBuildingDelete,
      isAssignedToBuildingsNotManagedByUser
    )
    yield put(
      actions.determineDeleteFlowSuccess(
        determinedFlow,
        action.payload.contactPersonId,
        sortedBuildings
      )
    )
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.determineDeleteFlowFail(e))
  }
}

export function* deleteContactPersonFlow(
  action: actions.DeleteContactPersonAction
): SagaIterator {
  try {
    const contactPerson = yield select(
      getContactPersonPerRentedBuilding(action.payload.contactPersonId)
    )
    const onboardedAt = contactPerson.onboardedAt
    yield call(
      deleteContactPersonOnRentedBuildings,
      action.payload.contactPersonId,
      {
        rentedBuildingIds: action.payload.rentedBuildingIds,
        stillEmployed: action.payload.stillEmployed
      }
    )

    const userRentedBuildingIds: string[] = yield select(
      meSelectors.getRentedBuildingIds
    )
    const assignedPersons = userRentedBuildingIds.map(r =>
      call(getAssignedPersons, r)
    )
    const response = yield all(assignedPersons)
    const data = response.map(
      ({ data }: { data: ContactPerson[] }, i: number) => {
        return {
          id: userRentedBuildingIds[i],
          data
        }
      }
    )
    yield put(getContactPersonsSuccess(data))
    yield put(appEventActions.remove("delete-contact-person"))
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "account-deleted",
        eventName: PossibleAppEvents.ACCOUNT_DELETED
      })
    )
    yield put(actions.resetDeleteFlow())
    yield put(
      createGAEvent(PossibleGAEvents.DELETE_ACCOUNT, {
        onboarding_status: onboardedAt ? "onboarded" : "not-onboarded"
      })
    )
    yield put(actions.deleteContactPersonSuccess())
  } catch (e: any) {
    const preferredLanguage: Locales = yield select(
      meSelectors.getPreferredLanguage
    )
    yield put(
      appEventActions.fire({
        local: true,
        eventName: PossibleAppEvents.ERROR,
        uniqueIdentifier: "error",
        props: {
          title: intl[preferredLanguage].formatMessage({
            id: "portal.modal.error.title"
          }),
          description: intl[preferredLanguage].formatMessage({
            id: "portal.modal.error.delete.seat.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.deleteContactPersonFail(e))
  }
}

export default function* DeleteContactPersonSaga(): SagaIterator {
  yield takeLatest(actions.Types.DETERMINE, determineDeleteModalFlow)
  yield takeLatest(actions.Types.DELETE, deleteContactPersonFlow)
}
