import { Survey } from "app/types/survey"
import { Actions, Types } from "./surveys.actions"

export type SurveysState = {
  errors: Record<string, unknown>
  loading: boolean
  done: boolean
  data?: Record<Survey["target"], Survey>
}

export const initialState: SurveysState = {
  errors: {},
  loading: false,
  done: false,
  data: undefined
}

export default (state = initialState, action: Actions): SurveysState => {
  switch (action.type) {
    case Types.GET: {
      return {
        ...state,
        loading: true
      }
    }

    case Types.GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data
      }
    }
    case Types.GET_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }
    }

    case Types.SET: {
      return {
        ...state,
        loading: true
      }
    }

    case Types.SET_SUCCESS: {
      return {
        ...state,
        loading: false,
        done: true
      }
    }

    case Types.SET_FAIL: {
      return {
        ...state,
        loading: false,
        done: false,
        errors: action.payload.errors
      }
    }

    default: {
      return state
    }
  }
}
