import { getCampaign } from "app/redux/campaign/campaign.actions"
import { useApi } from "hooks/api"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import * as selectors from "app/redux/campaign/campaign.selectors"
import {
  BackgroundContainer,
  ButtonContainer,
  StyledLink,
  ColoredTitle
} from "../style"
import Hero from "./Hero.jpg"
import { getPreferredLanguage } from "app/redux/me/me.selectors"
import React from "react"
import { ModulesEnum } from "app/types/banner"

const OverviewPageHeader = () => {
  const { translate } = useTranslate()
  const { call } = useApi()
  const bannerInfo = useSelector(
    selectors.getBannerInfoForModule(ModulesEnum.DASHBOARD)
  )
  const preferredLanguage = useSelector(getPreferredLanguage)

  React.useEffect(() => {
    call(getCampaign())
  }, [call])

  return (
    <BackgroundContainer
      url={
        bannerInfo?.languageData[preferredLanguage]?.title
          ? bannerInfo.bannerPictureUrl || Hero
          : Hero
      }
    >
      <ColoredTitle
        rgbColor={
          bannerInfo?.color.rgb || {
            r: 26,
            g: 99,
            b: 90,
            a: 1
          }
        }
      >
        {bannerInfo?.languageData[preferredLanguage]?.title ||
          translate("portal.dashboard.pageheader.title")}
      </ColoredTitle>
      {!!bannerInfo?.languageData[preferredLanguage]?.hasLink && (
        <ButtonContainer>
          {bannerInfo?.languageData[preferredLanguage] &&
            bannerInfo?.languageData[preferredLanguage]?.url &&
            bannerInfo?.languageData[preferredLanguage]?.label && (
              <StyledLink
                type="default"
                href={bannerInfo?.languageData[preferredLanguage]!.url}
                value={bannerInfo?.languageData[preferredLanguage]!.label!}
              />
            )}
          {!bannerInfo?.languageData[preferredLanguage] &&
            translate("portal.dashboard.pageheader.title-link-value") !==
              "portal.dashboard.pageheader.title-link-value" &&
            translate("portal.dashboard.pageheader.title-link-href") !==
              "portal.dashboard.pageheader.title-link-href" && (
              <StyledLink
                type="default"
                href={translate("portal.dashboard.pageheader.title-link-href")}
                value={translate(
                  "portal.dashboard.pageheader.title-link-value"
                )}
              />
            )}
        </ButtonContainer>
      )}
    </BackgroundContainer>
  )
}

export default OverviewPageHeader
