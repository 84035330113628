import { RentedBuildingMeter } from "app/types/energy"
import State from "../state"
import { TagEnum } from "./graphs.actions"

export const hasErrors = (state: State): boolean =>
  state.gasMeterHistory.errors ? true : false

export const availableMeters = (state: State): RentedBuildingMeter[] => {
  return state.availableMeters.data
}

export const selectAvailableMetersByTag =
  (state: State) =>
  (tag: TagEnum): RentedBuildingMeter | undefined => {
    const meters = availableMeters(state)
    return meters.find(availableMeter => availableMeter.tag === tag)
  }

export const lastRentedBuildingId = (state: State): string | undefined => {
  return state.availableMeters.rentedBuildingId
}

export const loading = (state: State): boolean => {
  return state.availableMeters.loading
}
