import { useState } from "react"
import {
  InputLabel,
  CalendarIcon,
  InputError
} from "@paudigital/wdp-components"
import {
  StyledDatePicker,
  StyledSubLabel,
  DatepickerTheme,
  StyledError
} from "./style"
import { SingleDatePicker, isSameDay } from "react-dates-gte-react-17"
import "react-dates-gte-react-17/initialize"
import "react-dates-gte-react-17/src/defaultPhrases"
import "react-dates-gte-react-17/lib/css/_datepicker.css"
import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet"
import aphroditeInterface from "react-with-styles-interface-aphrodite"
import moment from "moment"

export type DatePickerProps = {
  id: string
  name: string
  label?: string
  subLabel?: string
  placeholder?: string
  displayFormat?: string
  monthFormat?: string
  disabled?: boolean
  readOnly?: boolean
  inputDate?: string
  startDate?: string
  endDate?: string
  includeStartDate?: boolean
  includeEndDate?: boolean
  blockWeekends?: boolean
  blockedDays?: string[]
  highlightedDays?: string[]
  highlightToday?: boolean
  keepOpenOnDateSelect?: boolean
  top?: number
  error?: boolean
  errorMessage?: string
  onBlur: (date: string, name: string) => void
  onClose?: (date: string, name: string) => void
}

type Props = DatePickerProps

ThemedStyleSheet.registerInterface(aphroditeInterface)
ThemedStyleSheet.registerTheme(DatepickerTheme)

const DatePicker = ({
  id,
  name,
  label,
  subLabel,
  placeholder = "Datum",
  displayFormat = "DD/MM/YYYY",
  monthFormat = "MMMM YYYY",
  disabled = false,
  readOnly = false,
  inputDate,
  startDate,
  endDate,
  includeStartDate = true,
  includeEndDate = true,
  blockWeekends = false,
  blockedDays,
  highlightedDays = [],
  highlightToday = false,
  keepOpenOnDateSelect = false,
  top = 44,
  error,
  errorMessage,
  onBlur,
  onClose
}: Props) => {
  const [inputFocused, setInputFocused] = useState(false)
  const [date, setDate] = useState(inputDate ? moment(inputDate) : null)

  const onFocusChange = ({ focused }: { focused: boolean }) => {
    setInputFocused(focused)
  }

  const close = ({ ...d }: { date: moment.Moment | null }) => {
    onClose && onClose(d.date?.format("YYYY-MM-DD") || "", name)
  }

  const isDayHighlighted = (day: moment.Moment) =>
    (highlightToday && isSameDay(moment(), day)) ||
    highlightedDays.find(hd => isSameDay(moment(hd), day))
      ? true
      : false

  const isDayBlocked = (day: moment.Moment) => {
    if (blockWeekends) return day.day() === 0 || day.day() === 6
    if (blockedDays)
      return blockedDays.find(bd => isSameDay(moment(bd), day)) ? true : false
    return false
  }

  const isOutsideRange = (day: moment.Moment) => {
    if (
      name === "from" &&
      endDate &&
      day.format("L") === getEndDate().format("L")
    ) {
      return true
    }
    if (
      name === "to" &&
      startDate &&
      day.format("L") === getStartDate().format("L")
    ) {
      return true
    }
    if (startDate && endDate)
      return day.isBefore(getStartDate()) || day.isAfter(getEndDate())
    if (startDate) return day.isBefore(getStartDate())
    if (endDate) return day.isAfter(getEndDate())
    return false
  }

  const getStartDate = () =>
    includeStartDate ? moment(startDate) : moment(startDate).add(1, "d")
  const getEndDate = () =>
    includeEndDate ? moment(endDate) : moment(endDate).subtract(1, "d")

  const handleChange = (d: moment.Moment | null) => {
    setDate(d)
    if (d) onBlur(d.format("YYYY-MM-DD"), name)
  }

  return (
    <StyledDatePicker className="datepicker" top={top} hasError={error}>
      <InputLabel name={label} label={label} />
      {subLabel && <StyledSubLabel>{subLabel}</StyledSubLabel>}
      <SingleDatePicker
        placeholder={placeholder}
        small
        disabled={disabled}
        readOnly={readOnly}
        numberOfMonths={1}
        displayFormat={displayFormat}
        monthFormat={monthFormat}
        inputIconPosition="after"
        customInputIcon={<CalendarIcon height="22" width="22" />}
        enableOutsideDays={false}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
        isDayBlocked={isDayBlocked}
        isDayHighlighted={isDayHighlighted}
        keepOpenOnDateSelect={keepOpenOnDateSelect}
        id={id}
        date={date}
        focused={inputFocused}
        onDateChange={handleChange}
        onClose={close}
        onFocusChange={onFocusChange}
        keepFocusOnInput
      />
      {error && (
        <StyledError isVisible={error}>
          <InputError errorMessage={errorMessage} />
        </StyledError>
      )}
    </StyledDatePicker>
  )
}

export default DatePicker
