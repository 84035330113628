import { Action, Types } from "./languagePreference.actions"

export type LanguagePreferenceState = {
  errors?: Record<string, unknown>
  loading: boolean
  success: boolean
}

const initialState: LanguagePreferenceState = {
  errors: undefined,
  loading: false,
  success: false
}

export default (
  state = initialState,
  action: Action
): LanguagePreferenceState => {
  switch (action.type) {
    case Types.PATCH:
      return {
        ...state,
        loading: true,
        success: false
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
