import { TicketTypes } from "app/types/ticket"
import Type from "./Type"
import { getTicketStatistics } from "app/redux/ticket/ticket.selectors"
import { useSelector } from "react-redux"
import { Filters } from "routes/FacilityManagement"

type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const TypeFilter = ({
  page,
  perPage,
  sort,
  status,
  type,
  from,
  to,
  ticket,
  description,
  setUrlParams
}: Props) => {
  const queryParams = {
    page,
    perPage,
    sort,
    status,
    type,
    from,
    to,
    ticket,
    description
  }
  const selectedFilterType = queryParams?.type
    ? (queryParams?.type as TicketTypes[])
    : []
  const stats = useSelector(getTicketStatistics)
  const countPerType = stats?.countPerType! || []

  const types = Object.values(TicketTypes).map(type => ({
    amount: countPerType[type] || 0,
    checked: selectedFilterType.includes(type),
    type
  }))

  const clickHandler = (ticketType: TicketTypes) => {
    if (selectedFilterType.includes(ticketType)) {
      const filteredTypes = selectedFilterType.filter(t => t !== ticketType)
      setUrlParams({ ...queryParams, type: filteredTypes, page: 1 })
    } else {
      setUrlParams({
        ...queryParams,
        type: [...selectedFilterType, ticketType],
        page: 1
      })
    }
  }
  return (
    <>
      {types?.map((filterType, i) => (
        <Type
          key={`${i}_${filterType.type}`}
          amount={filterType.amount || 0}
          checked={filterType.checked}
          type={filterType.type}
          onClick={clickHandler}
          dataTestId={`facility-managment.filter.${filterType.type}`}
        />
      ))}
    </>
  )
}
export default TypeFilter
