import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "hooks/translate"
import { LoadingContainer, NotificationsContainer } from "./style"
import MoreNotificationsButton from "./MoreNotificationsButton"
import NoNotifications from "./NoNotifications"
import TimelineHeader from "./TimelineHeader"
import TimeLine from "./TimeLine"
import { formatToLongDateWithTime } from "app/utils/date.utils"
import { getPreferredLanguage } from "app/redux/me/me.selectors"
import { useEffect } from "react"
import { resetTimer } from "app/redux/notifications/notifications.actions"
import { Spinner } from "@paudigital/wdp-components"
import * as selectors from "app/redux/notifications/notifications.selectors"

const Notifications = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const preferredLanguage = useSelector(getPreferredLanguage)
  const lastVisitedTime = useSelector(selectors.getLastVisitedTime)
  const isLoading = useSelector(selectors.isLoading)
  const formattedLastVisitedTime =
    lastVisitedTime &&
    formatToLongDateWithTime(new Date(lastVisitedTime), preferredLanguage)
  const notifications = useSelector(selectors.getNotifications)
  const unreadNotifications = useSelector(selectors.hasUnreadNotifications)

  useEffect(() => {
    dispatch(resetTimer())
  }, [dispatch])

  if (!isLoading && notifications && notifications.length === 0) {
    return <NoNotifications />
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    )
  }

  return (
    <NotificationsContainer>
      <TimelineHeader
        title={translate(
          `portal.notifications.header.${
            unreadNotifications ? "unread" : "read"
          }.title`
        )}
        description={
          lastVisitedTime &&
          translate(
            `portal.notifications.header.${
              unreadNotifications ? "unread" : "read"
            }.guidance`,
            {
              date: formattedLastVisitedTime
            }
          )
        }
      />
      {!isLoading && notifications && (
        <TimeLine notifications={notifications} />
      )}

      <MoreNotificationsButton />
    </NotificationsContainer>
  )
}

export default Notifications
