import {
  BuildingName,
  BuildingCityAddress,
  BuildingStreetAddress
} from "./style"
import { Typography } from "@paudigital/wdp-components"
import { RentedBuilding } from "app/types/rentedBuilding"

type Props = {
  rentedBuilding: RentedBuilding
  onClick: () => void
}

const BuildingHeader = ({ rentedBuilding, onClick }: Props) => {
  return (
    <BuildingName onClick={onClick}>
      <BuildingCityAddress>
        <Typography variant="h5" fontStyle="bold">
          {rentedBuilding.city}
        </Typography>
      </BuildingCityAddress>
      <BuildingStreetAddress>
        {rentedBuilding.street} {rentedBuilding.houseNo}
      </BuildingStreetAddress>
    </BuildingName>
  )
}

export default BuildingHeader
