import {
  NestedCheckbox,
  Data,
  changeChildValue
} from "@paudigital/wdp-components"
import { updateNotifications } from "app/redux/notificationSettings/notificationSettings.actions"
import { reduceNestedCheckboxDataToUpdateDto } from "app/utils/notificationSettings"
import { useTranslate } from "hooks/translate"
import { useApi } from "hooks/api"

type MultiBuildingNotificationSettingsProps = {
  rentedBuildingId: string
  settingsData: Data
  collapsed?: boolean
}

type Props = MultiBuildingNotificationSettingsProps

const MultiBuildingNotificationSettings = ({
  rentedBuildingId,
  settingsData,
  collapsed = false
}: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()

  const translatedSettingsData = {
    parent: settingsData.parent,
    children: settingsData.children.map(currentChild => {
      return {
        label: translate(currentChild.label),
        name: currentChild.name,
        guidanceText: translate(currentChild.guidanceText ?? ""),
        selected: currentChild.selected
      }
    })
  }

  const handleChildChange = (val: boolean, name: string) => {
    const updateData = reduceNestedCheckboxDataToUpdateDto(
      rentedBuildingId,
      changeChildValue(settingsData, val, name)
    )
    call(updateNotifications(updateData))
  }

  return (
    <NestedCheckbox
      onChildSelect={handleChildChange}
      data={translatedSettingsData}
      defaultCollapsed={collapsed}
      collapsable
    />
  )
}

export default MultiBuildingNotificationSettings
