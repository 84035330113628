import contactPerson from "./contactPerson/contactPerson.reducer"
import rentedBuildings from "./rentedBuildings/rentedBuildings.reducer"
import registrationWizard from "./registrationWizard/registrationWizard.reducer"
import notificationsSettings from "./notificationSettings/notificationSettings.reducer"
import notifications from "./notifications/notifications.reducer"
import rentedBuildingContactPersons from "./rentedBuildingContactPerson/rentedBuildingContactPerson.reducer"
import me from "./me/me.reducer"
import buildingSwitcher from "./buildingSwitcher/buildingSwitcher.reducer"
import token from "./token/token.reducer"
import deleteContactPerson from "./deleteContactPerson/deleteContactPerson.reducer"
import contactPersonContactRoles from "./contactPersonContactRoles/contactPersonContactRoles.reducer"
import preferredBuilding from "./preferredBuilding/preferredBuilding.reducers"
import appEvents from "./appEvents/appEvents.reducer"
import buildingSpecification from "./buildingSpecifications/buildingSpecifications.reducer"
import rentedBuildingContracts from "./rentedBuildingContracts/rentedBuildingContracts.reducer"
import rentedBuildingContacts from "./rentedBuildingContacts/rentedBuildingContacts.reducer"
import ticketCategories from "./ticketCategories/ticketCategories.reducer"
import ticketWizard from "./ticketWizard/ticketWizard.reducer"
import ticket from "./ticket/ticket.reducer"
import ticketCommunication from "./ticketCommunication/ticketCommunication.reducer"
import document from "./document/document.reducer"
import waterMeterHistory from "./waterMeterHistory/waterMeterHistory.reducer"
import gasMeterHistory from "./gasMeterHistory/gasMeterHistory.reducer"
import availableMeters from "./graphs/graphs.reducer"
import electricityMeterHistory from "./electricityMeterHistory/electricityMeterHistory.reducer"
import solarProductionHistory from "./solarProductionHistory/solarProductionHistory.reducer"
import energyPeriod from "./energyPeriod/energyPeriod.reducer"
import invoices from "./invoices/invoices.reducer"
import energyUsageComparison from "./energyUsageComparison/energyUsageComparison.reducer"
import documentWizard from "./documentWizard/documentWizard.reducer"
import documentTypes from "./documentTypes/documentTypes.reducer"
import authorization from "./authorization/authorization.reducer"
import responsibilities from "./responsibilities/responsibilities.reducer"
import energyCSV from "./energyCSV/energyCSV.reducer"
import languagePreference from "./languagePreference/languagePreference.reducer"
import gaEvents from "./gaEvents/gaEvents.reducer"
import httpErrors from "./httpErrors/httpErrors.reducer"
import faq from "./faq/faq.reducer"
import campaign from "./campaign/campaign.reducer"
import mfa from "./mfa/mfa.reducer"
import mfaOtpWizard from "./mfaOtpWizard/mfaOtpWizard.reducer"
import resetPasswordWizard from "./resetPasswordWizard/resetPasswordWizard.reducer"
import authentication from "./authentication/authentication.reducer"
import workOrders from "./workOrder/workOrder.reducer"
import surveys from "./surveys/surveys.reducer"
import pvProjects from "./pvProject/pvProject.reducers"
import adminBuildingSelect from "./adminBuildingSelect/adminBuildingSelect.reducer"

export default {
  appEvents,
  authorization,
  availableMeters,
  buildingSpecification,
  buildingSwitcher,
  contactPerson,
  contactPersonContactRoles,
  deleteContactPerson,
  document,
  documentTypes,
  documentWizard,
  electricityMeterHistory,
  energyCSV,
  energyPeriod,
  energyUsageComparison,
  faq,
  campaign,
  gaEvents,
  gasMeterHistory,
  invoices,
  languagePreference,
  me,
  notifications,
  notificationsSettings,
  preferredBuilding,
  registrationWizard,
  rentedBuildingContactPersons,
  rentedBuildingContacts,
  rentedBuildingContracts,
  rentedBuildings,
  responsibilities,
  solarProductionHistory,
  ticket,
  ticketCategories,
  ticketCommunication,
  ticketWizard,
  token,
  waterMeterHistory,
  httpErrors,
  mfa,
  mfaOtpWizard,
  resetPasswordWizard,
  authentication,
  workOrders,
  surveys,
  pvProjects,
  adminBuildingSelect
}
