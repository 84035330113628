import { SpinnerIcon } from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import { StyledButton, Wrapper } from "./style"
import * as selector from "app/redux/notifications/notifications.selectors"
import { useTranslate } from "hooks/translate"
import { useApi } from "hooks/api"
import { getMoreNotifications } from "app/redux/notifications/notifications.actions"

const MoreNotificationsButton = () => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const isLoading = useSelector(selector.isLoading)
  const page = useSelector(selector.getPage)
  const hasMoreNotifications = useSelector(selector.hasMoreNotifications)
  const label = translate(
    isLoading
      ? "portal.notifications.morebutton.loading.label"
      : "portal.notifications.morebutton.label"
  )

  const handleClick = () => {
    call(getMoreNotifications((page || 0) + 1))
  }

  if (!hasMoreNotifications) return null
  return (
    <Wrapper>
      <StyledButton
        label={label}
        type="secondary"
        startIcon={
          isLoading ? <SpinnerIcon width="2.4rem" height="2.4rem" /> : undefined
        }
        onClick={handleClick}
      />
    </Wrapper>
  )
}

export default MoreNotificationsButton
