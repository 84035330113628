import DocumentsPage from "app/components/DocumentManagement"
import { GetDocumentParams } from "app/redux/document/document.actions"
import { useQueryParams } from "hooks/queryParams"

export type Filters = Omit<GetDocumentParams, "rentedBuildingId">

const Documents = () => {
  const { queryParams, setUrlParams } = useQueryParams<Filters>({
    page: 1,
    perPage: 20,
    sort: ["date=desc"]
  })

  const {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  } = queryParams
  return (
    <DocumentsPage
      page={Number(page)}
      perPage={Number(perPage)}
      documentType={documentType as string}
      uploadDateFrom={uploadDateFrom as string}
      uploadDateTo={uploadDateTo as string}
      uploadedBy={uploadedBy as string}
      inspectionDateFrom={inspectionDateFrom as string}
      inspectionDateTo={inspectionDateTo as string}
      contractId={contractId as string[]}
      sort={sort as string[]}
      setUrlParams={setUrlParams}
    />
  )
}

export default Documents
