import { IntervalEnum } from "app/enums/interval"
import { Co2CalculationMethods } from "app/types/energy"
import { EnergyPeriodRange } from "app/types/energyPeriodRange"
import { formatISODateWithoutTimezone } from "app/utils/dateFormatter.utils"
import { Graphs } from "app/utils/graphs/graph.utils"
import { startOfDay, sub } from "date-fns"
import { Action, Types } from "./energyPeriod.actions"

export enum Periods {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
  custom = "custom",
  less_one_week = "less_one_week",
  one_week_one_month = "one_week_one_month",
  one_month_one_year = "one_month_one_year",
  plus_one_year = "plus_one_year"
}

export type EnergyPeriodState = {
  synchronize: boolean
  period: Periods
  range: EnergyPeriodRange
  options: IntervalEnum[]
  co2CalculationMethod: Co2CalculationMethods
  [Graphs.ELECTRICITY_CONSUMPTION]: IntervalEnum
  [Graphs.SOLAR_PRODUCTION]: IntervalEnum
  [Graphs.GAS_CONSUMPTION]: IntervalEnum
  [Graphs.WATER_CONSUMPTION]: IntervalEnum
}

export type GranularityMappingType = {
  [key in Periods]: {
    options: IntervalEnum[]
    defaultValue: IntervalEnum
  }
}

export const periodMapping: Record<Periods, number> = {
  [Periods.day]: 1,
  [Periods.week]: 7,
  [Periods.month]: 30,
  [Periods.year]: 365,
  [Periods.less_one_week]: 0,
  [Periods.one_week_one_month]: 0,
  [Periods.one_month_one_year]: 0,
  [Periods.plus_one_year]: 0,
  [Periods.custom]: 0
}

export const granularityMapping: GranularityMappingType = {
  [Periods.day]: {
    options: [IntervalEnum.FIFTEENMIN, IntervalEnum.HOUR],
    defaultValue: IntervalEnum.HOUR
  },
  [Periods.week]: {
    options: [IntervalEnum.HOUR, IntervalEnum.DAY],
    defaultValue: IntervalEnum.DAY
  },
  [Periods.month]: {
    options: [IntervalEnum.HOUR, IntervalEnum.DAY],
    defaultValue: IntervalEnum.DAY
  },
  [Periods.year]: {
    options: [IntervalEnum.DAY, IntervalEnum.WEEK, IntervalEnum.MONTH],
    defaultValue: IntervalEnum.MONTH
  },
  [Periods.less_one_week]: {
    options: [IntervalEnum.FIFTEENMIN, IntervalEnum.HOUR],
    defaultValue: IntervalEnum.HOUR
  },
  [Periods.one_week_one_month]: {
    options: [IntervalEnum.HOUR, IntervalEnum.DAY],
    defaultValue: IntervalEnum.DAY
  },
  [Periods.one_month_one_year]: {
    options: [IntervalEnum.DAY, IntervalEnum.WEEK, IntervalEnum.MONTH],
    defaultValue: IntervalEnum.MONTH
  },
  [Periods.plus_one_year]: {
    options: [IntervalEnum.WEEK, IntervalEnum.MONTH],
    defaultValue: IntervalEnum.MONTH
  },
  [Periods.custom]: {
    options: [],
    defaultValue: IntervalEnum.DAY
  }
}

const initialState: EnergyPeriodState = {
  synchronize: true,
  period: Periods.month,
  range: {
    from: formatISODateWithoutTimezone(
      startOfDay(sub(new Date(), { days: periodMapping[Periods.month] }))
    ),
    to: formatISODateWithoutTimezone(startOfDay(new Date()))
  },
  options: [IntervalEnum.HOUR, IntervalEnum.DAY],
  co2CalculationMethod: Co2CalculationMethods.GREY,
  [Graphs.ELECTRICITY_CONSUMPTION]: IntervalEnum.DAY,
  [Graphs.SOLAR_PRODUCTION]: IntervalEnum.DAY,
  [Graphs.GAS_CONSUMPTION]: IntervalEnum.DAY,
  [Graphs.WATER_CONSUMPTION]: IntervalEnum.DAY
}

export default (state = initialState, action: Action): EnergyPeriodState => {
  switch (action.type) {
    case Types.SET_ENERGY_PERIOD:
      const {period} = action.payload
      const { options, defaultValue } = granularityMapping[
        action.payload.period
      ]
      return {
        ...state,
        range: {
          from: action.payload.from,
          to: action.payload.to
        },
        period,
        options,
        synchronize: false,
        [Graphs.ELECTRICITY_CONSUMPTION]: defaultValue,
        [Graphs.SOLAR_PRODUCTION]: defaultValue,
        [Graphs.GAS_CONSUMPTION]: defaultValue,
        [Graphs.WATER_CONSUMPTION]: defaultValue
      }
    case Types.SET_GRAPH_INTERVAL:
      return {
        ...state,
        [action.payload.graph]: action.payload.interval
      }
    case Types.SET_CO2_CALCULATION:
      return {
        ...state,
        co2CalculationMethod: action.payload.method
      }

    case Types.RESET_ENERGY_PERIOD: {
      return initialState
    }

    default:
      return {
        ...state
      }
  }
}
