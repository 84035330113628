import { Page, LoginIcon } from "@paudigital/wdp-components"
import {
  authenticate,
  authenticateReset
} from "app/redux/authentication/authentication.actions"
import { actions as wizardActions } from "app/redux/resetPasswordWizard/resetPasswordWizard.actions"
import { useDispatch } from "react-redux"
import { Audiences, Scopes } from "app/api/auth0"
import RenewPasswordStepper from "app/components/RenewPasswordStepper"
import { useTranslate } from "hooks/translate"
import {
  PasswordUpdateType,
  updatePassword
} from "app/redux/authorization/authorization.actions"
import { useEffect } from "react"
import { Action as ReduxAction } from "redux"

const PasswordChange = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()

  useEffect(() => {
    dispatch(authenticateReset())
  }, [dispatch])

  const handleOnSubmitVerification = (currentPassword: string, actionOnSuccess: () => ReduxAction<any>) => {
    dispatch(
      authenticate({
        currentPassword,
        audience: Audiences.Rental,
        scope: Scopes.UpdatePassword,
        actionOnSuccess
      })
    )
  }

  const handleOnPasswordChange = (newPassword: string) => {
    dispatch(updatePassword(newPassword, PasswordUpdateType.PASSWORD_CHANGE_90))
  }

  return (
    <Page image={<LoginIcon />}>
      <RenewPasswordStepper
        actionOnSuccess={wizardActions.increaseWizard}
        handleOnPasswordChange={handleOnPasswordChange}
        description={translate("renew.password.description")}
        handleOnVerification={handleOnSubmitVerification}
      />
    </Page>
  )
}

export default PasswordChange
