import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledFacilityManagement = styled.div`
  margin: 4rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(4rem, auto) 1fr;
  gap: 1.2rem;
  grid-template-areas:
    "topbar-mobile"
    "content";

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 4rem 0;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 4rem 1rem;
    grid-template-columns: 0.3fr 1fr;
    grid-template-areas:
      "sidebar topbar"
      "sidebar content";
  }
`

export const MobileFilterContainer = styled.div`
  width: 100%;
  position: relative;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 2.4rem;
`

export const CreateTicketContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: ${theme.colors.neutral.white};
  border-radius: 4px;
  padding: 0.8rem;
  margin-bottom: 3.8rem;
  height: 10rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    padding: 0 3.2rem;
  }
`

export const SideBar = styled.section`
  max-width: 25rem;
  grid-area: sidebar;
`

export const Content = styled.section`
  grid-area: content;
`
