import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const WorkOrderTitle = styled.h6`
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  margin-right: 2rem;
  margin-bottom: 0.3rem;
  overflow: hidden;
`

export const WorkOrderIcon = styled.div`
  margin-right: 1.6rem;
  display: flex;
  justify-content: center;
`

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  margin: 1.6rem 2rem;
  justify-content: space-between;
  align-items: center;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChevronWrapper = styled.div<{ open: boolean }>`
  margin-right: 2.4rem;
  ${props =>
    props.open && {
      transform: "rotate(180deg)"
    }};
  cursor: pointer;
`
