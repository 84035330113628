import { Button, Link, CheckIcon, theme } from "@paudigital/wdp-components"
import { RentedBuilding } from "app/types/rentedBuilding"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
  BuildingWrapper,
  BuildingImage,
  BuildingDetailCard,
  BuildingCardCtas,
  CardContainer,
  PreferedBuilding,
  PreferedBuildingLabel,
  StyledCustomerNameTag
} from "./style"
import { patchPreferedBuilding } from "app/redux/preferredBuilding/preferredBuilding.actions"
import Section from "./Sections"
import { useViewport } from "hooks/viewport"
import BuildingHeader from "./BuildingHeader"
import { Modules } from "app/enums/modules"
import { setBuildingSwitcher } from "app/redux/buildingSwitcher/buildingSwitcher.actions"
import { useApi } from "hooks/api"
import { remove } from "app/redux/appEvents/appEvents.actions"
import {
  getPreferredBuildingId,
  hasMultipleCompanies
} from "app/redux/me/me.selectors"
import { usePermission } from "hooks/permission"

const placeholder = "https://cdn.pau.be/wdp/building-switcher.png"

export type BuildingWidgetProps = {
  rentedBuilding: RentedBuilding
  notificationsCount: number | undefined
}

type Props = BuildingWidgetProps

const BuildingWidget = ({ rentedBuilding, notificationsCount }: Props) => {
  const preferredBuilding = useSelector(getPreferredBuildingId)
  const hasMultipleCompaniesContext = useSelector(hasMultipleCompanies)
  const { id } = useParams<{ id?: string }>()
  const dispatch = useDispatch()
  const { call } = useApi()
  const navigate = useNavigate()
  const { width } = useViewport()
  const { translate } = useTranslate()
  const { hasPermission } = usePermission()

  const isPreferedBuilding = rentedBuilding.id === preferredBuilding

  const handleSetPreferedBuilding = () => {
    call(patchPreferedBuilding(rentedBuilding.id))
  }

  const handleClick = () => {
    dispatch(remove("building-switcher"))
    if (rentedBuilding.id === id) return
    dispatch(setBuildingSwitcher(rentedBuilding.id))
    if (hasPermission(Modules.DASHBOARD, rentedBuilding.id)) {
      return navigate(`/dashboard/${rentedBuilding.id}`)
    }
    return navigate(`/facility-management/${rentedBuilding.id}`)
  }

  return (
    <BuildingWrapper>
      {hasMultipleCompaniesContext && (
        <StyledCustomerNameTag name={rentedBuilding.entityName} />
      )}
      <BuildingImage src={placeholder} onClick={handleClick} />
      {width < theme.breakpointsValues.tablet && (
        <BuildingHeader onClick={handleClick} rentedBuilding={rentedBuilding} />
      )}
      <BuildingDetailCard>
        <CardContainer>
          <BuildingHeader
            onClick={handleClick}
            rentedBuilding={rentedBuilding}
          />
          <BuildingCardCtas>
            <Section
              rentedBuildingId={rentedBuilding.id}
              name={Modules.FACILITY_MANAGEMENT}
              title={translate(
                "portal.building.switcher.facilitymanagement.label"
              )}
            />
            <Section
              rentedBuildingId={rentedBuilding.id}
              name={Modules.INVOICES}
              title={translate("portal.building.switcher.invoices.label")}
            />
            <Section
              rentedBuildingId={rentedBuilding.id}
              name={Modules.ENERGY}
              title={translate("portal.building.switcher.energy.label")}
            />
            <Section
              rentedBuildingId={rentedBuilding.id}
              name={Modules.NOTIFICATIONS}
              title={translate("portal.building.switcher.notifications.label")}
              count={notificationsCount}
            />
          </BuildingCardCtas>
          <Button
            label={translate("portal.building.switcher.button.dashboard.goto")}
            fullWidth
            onClick={handleClick}
          />
        </CardContainer>
      </BuildingDetailCard>
      <PreferedBuilding>
        {isPreferedBuilding ? (
          <>
            <CheckIcon
              fill={theme.colors.system.success}
              width="12"
              height="9"
            />
            <PreferedBuildingLabel>
              {translate("portal.building.switcher.link.preferred.building")}
            </PreferedBuildingLabel>
          </>
        ) : (
          <Link
            type="default"
            onClick={handleSetPreferedBuilding}
            value={translate(
              "portal.building.switcher.link.preferred.building.change"
            )}
          />
        )}
      </PreferedBuilding>
    </BuildingWrapper>
  )
}

export default BuildingWidget
