import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
`

export const LoadingTitle = styled.label`
  margin: 1.8rem 2rem 1rem;
  font-weight: ${theme.fontWeights.black};
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
`

export const LoadingDescription = styled.p`
  font-weight: ${theme.fontWeights.light};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  color: ${theme.colors.neutral.darkerGrey};
`
