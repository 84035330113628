import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.actions"
import * as selectors from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import ContactPersonCard from "../ContactPersonCard"
import * as meSelectors from "app/redux/me/me.selectors"
import { useTranslate } from "hooks/translate"
import { InputLabel } from "@paudigital/wdp-components"
import Section from "app/components/Section"
import { Modules } from "app/enums/modules"
import { usePermission } from "hooks/permission"
import {
  BuildingWrapper,
  AddressContainer,
  StyledCustomerNameTag
} from "./style"
import { getAddress } from "app/utils/getAddress"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { useApi } from "hooks/api"

const Admin = () => {
  const dispatch = useDispatch()
  const { hasPermission } = usePermission()
  const { translate } = useTranslate()
  const { call } = useApi()

  const contactPersons = useSelector(selectors.getContactPersons)
  const rentedBuildings = useSelector(meSelectors.getRentedBuildings)
  const hasMultipleCompanies = useSelector(meSelectors.hasMultipleCompanies)
  const hasSingleRentedBuilding = useSelector(
    meSelectors.hasSingleRentedBuilding
  )

  React.useEffect(() => {
    const ids = rentedBuildings?.map(r => r.id)
    if (ids) {
      call(actions.getContactPersons(ids))
    }
  }, [call, rentedBuildings])

  const handleOpen = (id?: string) => {
    dispatch(
      appEventActions.fire({
        eventName: PossibleAppEvents.ADD_COLLEAGUE,
        uniqueIdentifier: "add-colleague",
        props: {
          id
        }
      })
    )
  }

  const handleDelete = (cpId: string) => {
    dispatch(
      appEventActions.fire({
        eventName: PossibleAppEvents.DELETE_CONTACT_PERSON,
        uniqueIdentifier: "delete-contact-person",
        props: {
          contactPersonIdToDelete: cpId
        }
      })
    )
  }

  return (
    <Section title={translate("portal.admin.title")}>
      <>
        {contactPersons?.map(c => {
          const { id, data: contactPersons } = c
          const rentedBuilding = rentedBuildings?.find(r => r.id === id)
          if (
            rentedBuilding &&
            hasPermission(Modules.USER_MANAGEMENT, rentedBuilding.id)
          ) {
            return (
              <BuildingWrapper key={id}>
                {!hasSingleRentedBuilding && (
                  <AddressContainer>
                    <InputLabel
                      fullWidth={false}
                      label={`${getAddress(rentedBuilding)}`}
                    />
                    {hasMultipleCompanies && (
                      <StyledCustomerNameTag name={rentedBuilding.entityName} />
                    )}
                  </AddressContainer>
                )}
                {[...Array(rentedBuilding.maxSeatCount)].map((_, i) => (
                  <ContactPersonCard
                    key={`${i}-${contactPersons[i]?.id || "empty"}`}
                    contactPerson={contactPersons[i]}
                    onAddContactPerson={handleOpen}
                    onEditContactPerson={handleOpen}
                    onDeleteContactPerson={handleDelete}
                  />
                ))}
              </BuildingWrapper>
            )
          }
          return null
        })}
      </>
    </Section>
  )
}

export default Admin
