import { useSelector } from "react-redux"
import {
  getGlobalAppEvents,
  getLocalAppEvents
} from "app/redux/appEvents/appEvents.selectors"
import { AppEvent as AppEventType } from "app/redux/appEvents/types"
import { AppEvent } from "./AppEvent"

type Props = {
  local: boolean
}

export const AppEventProvider = ({ local }: Props): JSX.Element => {
  const localAppEvents = useSelector(getLocalAppEvents)
  const globalAppEvents = useSelector(getGlobalAppEvents)

  if (local) {
    return (
      <>
        {localAppEvents.map((appEvent: AppEventType) => {
          return <AppEvent key={appEvent.uniqueIdentifier} {...appEvent} />
        })}
      </>
    )
  }

  return (
    <>
      {globalAppEvents.map((appEvent: AppEventType) => {
        return <AppEvent key={appEvent.uniqueIdentifier} {...appEvent} />
      })}
    </>
  )
}
