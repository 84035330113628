import { SagaIterator } from "redux-saga"
import { call, put, select, take, takeLatest } from "redux-saga/effects"
import * as responsibilityActions from "./responsibilities.actions"
import * as meActions from "../me/me.actions"
import * as meSelectors from "../me/me.selectors"
import { actions as wizardActions } from "./../registrationWizard/registrationWizard.actions"
import { patchContactPersonResponsibilities } from "app/api/contactPerson"
import { push } from "redux-first-history"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* patchResponsibilitiesFlow(
  action: responsibilityActions.PatchResponsibilitiesAction
): SagaIterator {
  try {
    yield call(patchContactPersonResponsibilities, action.payload)
    yield put(meActions.getMe())
    yield put(responsibilityActions.clearResponsibilities())
    yield put(responsibilityActions.patchResponsiblitiesSuccess())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(responsibilityActions.patchResponsiblitiesFailure(e))
  }
}

export function* patchInOnboardingResponsibilitiesFlow(
  action: responsibilityActions.PatchInOnboardingResponsibilitiesAction
): SagaIterator {
  try {
    yield call(patchContactPersonResponsibilities, action.payload)
    yield put(meActions.getMe())
    yield take(meActions.getMeSuccess)
    yield put(responsibilityActions.clearResponsibilities())
    yield put(responsibilityActions.patchResponsiblitiesSuccess())

    const isAdminInABuilding = yield select(meSelectors.isAdminInRentedBuilding)
    if (isAdminInABuilding) {
      return yield put(wizardActions.increaseWizard())
    }
    return yield put(push("/register/success"))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(responsibilityActions.patchResponsiblitiesFailure(e))
  }
}

export default function* ResponsibilitiesSaga(): SagaIterator {
  yield takeLatest(responsibilityActions.Types.PATCH, patchResponsibilitiesFlow)
  yield takeLatest(
    responsibilityActions.Types.PATCH_IN_ONBOARDING,
    patchInOnboardingResponsibilitiesFlow
  )
}
