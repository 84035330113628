import * as api from "app/api/ticket"
import { PostTicket } from "app/types/ticket"
import { SagaIterator } from "redux-saga"
import { call, put, select, take, takeLatest } from "redux-saga/effects"
import * as actions from "./ticket.actions"
import { getActiveBuilding } from "./../buildingSwitcher/buildingSwitcher.selectors"
import { fire, remove } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { actions as wizardActions } from "./../ticketWizard/ticketWizard.actions"
import { getTicketsForRentedBuilding, getTicketsById } from "app/api/ticket"
import { getParams } from "./ticket.selectors"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"
import {
  getContentTypeFromHeaders,
  getFileNameFromHeaders
} from "app/utils/getFileName"
import * as notificationActions from "../notifications/notifications.actions"

export function* postTicketFlow(
  action: actions.PostTicketAction
): SagaIterator {
  try {
    const {
      rentedBuildingId,
      data: { files },
      data
    } = action.payload
    const buildingId = yield select(getActiveBuilding)
    const params = yield select(getParams)
    const ticket = {
      ...data,
      files: undefined,
      buildingId: buildingId.split("_")[0]
    }
    const response = yield call(
      api.postTicket,
      rentedBuildingId,
      ticket as PostTicket
    )
    const { ticketId } = response.data
    if (files && files.length !== 0) {
      yield put(
        actions.postTicketAttachments({
          rentedBuildingId,
          id: ticketId,
          files: files!
        })
      )
      yield take(actions.postTicketAttachmentsSuccess)
    }
    yield put(remove("create-ticket"))
    yield put(
      fire({
        eventName: PossibleAppEvents.TICKET_CREATED,
        uniqueIdentifier: "ticket-created"
      })
    )
    yield put(actions.discardTicket())
    yield put(wizardActions.clearWizard())
    yield put(actions.getTickets(params))
    yield put(actions.postTicketSuccess())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.postTicketFail(e))
  }
}

export function* downloadTicketsFlow(
  action: actions.DownloadTicketAction
): SagaIterator {
  try {
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_LOADING,
        uniqueIdentifier: "document-loading-popup"
      })
    )
    const response = yield call(getTicketsById, action.payload)
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const contentType = getContentTypeFromHeaders(headers)
    if (contentType === "application/pdf") {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      )
      window.open(url)
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
    yield put(actions.downloadTicketSuccess())
    yield put(remove("document-loading-popup"))
  } catch (e: any) {
    yield put(remove("document-loading-popup"))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.downloadTicketFail(e))
  }
}

export function* postTicketAttachmentsFlow(
  action: actions.PostTicketAttachmentsAction
): SagaIterator {
  try {
    const { rentedBuildingId, id, files } = action.payload
    const formData = new FormData()
    files.forEach(file => formData.append("files", file))
    yield call(api.postTicketAttachments, {
      rentedBuildingId,
      id,
      data: formData
    })
    yield put(actions.postTicketAttachmentsSuccess())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.postTicketAttachmentsFail(e))
  }
}

export function* getTicketSummaryFlow(
  action: actions.GetTicketSummaryAction
): SagaIterator {
  try {
    const { id } = action.payload
    const response = yield call(api.getTicketSummary, id)
    yield put(actions.getTicketSummarySuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getTicketSummaryFail(e))
  }
}

export function* getTicketsFlow(
  action: actions.GetTicketsAction
): SagaIterator {
  try {
    const response = yield call(getTicketsForRentedBuilding, action.payload)
    yield put(
      actions.getTicketsSuccess({
        ...response.data,
        filters: { ...action.payload, pages: response.data.filters.pages }
      })
    )
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getTicketsFail(e))
  }
}

export function* getTicketAttachmentsFlow(
  action: actions.GetTicketAttachmentsAction
): SagaIterator {
  try {
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_LOADING,
        uniqueIdentifier: "document-loading-popup"
      })
    )
    const { rentedBuildingId, ticketId } = action.payload
    const response = yield call(
      api.getTicketAttachments,
      rentedBuildingId,
      ticketId
    )
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(actions.getTicketAttachmentsSuccess())
    yield put(remove("document-loading-popup"))
  } catch (e: any) {
    yield put(remove("document-loading-popup"))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getTicketAttachmentsFail(e))
  }
}

export function* downloadTicketsCSVFlow(
  action: actions.DownloadTicketCSVAction
): SagaIterator {
  try {
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_LOADING,
        uniqueIdentifier: "document-loading-popup"
      })
    )
    const rentedBuildingId = yield select(getActiveBuilding)
    const response = yield call(
      api.downloadTicketsCSV,
      action.payload.data,
      rentedBuildingId
    )
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(actions.downloadTicketsCSVSuccess())
    yield put(remove("download-tickets"))
    yield put(remove("document-loading-popup"))
  } catch (e: any) {
    yield put(remove("document-loading-popup"))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.downloadTicketsCSVFail(e))
  }
}

export function* cancelTicketFlow(
  action: actions.CancelTicketAction
): SagaIterator {
  try {
    const rentedBuildingId = yield select(getActiveBuilding)
    yield call(api.cancelTicket, rentedBuildingId, action.payload.ticketId)
    yield put(actions.downloadTicketSuccess())
    if (action.payload.notification) {
      yield put(notificationActions.getNotifications())
    } else {
      const params = yield select(getParams)
      yield put(actions.getTickets({ ...params, rentedBuildingId }))
    }
    yield put(
      fire({
        eventName: PossibleAppEvents.TICKET_CANCELLED,
        uniqueIdentifier: "ticket-cancelled"
      })
    )
  } catch (e: any) {
    yield put(actions.cancelTicketFail(e))
  }
}

export default function* ticketSaga(): SagaIterator {
  yield takeLatest(actions.Types.POST, postTicketFlow)
  yield takeLatest(actions.Types.POST_ATTACHMENTS, postTicketAttachmentsFlow)
  yield takeLatest(actions.Types.GET_SUMMARY, getTicketSummaryFlow)
  yield takeLatest(actions.Types.GET, getTicketsFlow)
  yield takeLatest(
    actions.Types.GET_TICKET_ATTACHMENTS,
    getTicketAttachmentsFlow
  )
  yield takeLatest(actions.Types.DOWNLOAD_CSV, downloadTicketsCSVFlow)
  yield takeLatest(actions.Types.DOWNLOAD, downloadTicketsFlow)
  yield takeLatest(actions.Types.CANCEL_TICKET, cancelTicketFlow)
}
