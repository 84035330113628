import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

const BaseSpan = styled.span`
  display: block;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.light};
  line-height: ${theme.lineHeights.captionSmall};
  text-transform: uppercase;

  strong {
    font-weight: ${theme.fontWeights.bold};
  }
`

export const OneTimePosing = styled(BaseSpan)`
  color: ${theme.colors.neutral.darkGrey};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 0.4rem;
`

export const DateRange = styled(BaseSpan)`
  color: ${theme.colors.green.contrast};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 0.4rem;
`
