import {
  NotificationSettings,
  UpdateNotificationSettingsDto
} from "app/types/notificationSettings"

export enum Types {
  GET = "GET_NOTIFICATION_SETTINGS",
  GET_SUCCESS = "GET_NOTIFICATION_SETTINGS_SUCCESS",
  GET_FAIL = "GET_NOTIFICATION_SETTINGS_FAIL",
  POST_SUCCESS = "POST_NOTIFICATION_SETTINGS_SUCCESS",
  PATCH_SUCCESS = "PATCH_NOTIFICATION_SETTINGS_SUCCESS",
  UPSERT = "UPSERT_NOTIFICATION_SETTINGS",
  UPSERT_SUCCESS = "UPSERT_NOTIFICATION_SETTINGS_SUCCESS",
  UPSERT_FAIL = "UPSERT_NOTIFICATION_SETTINGS_FAIL",
  UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS_SETTINGS"
}

export type Action =
  | GetNotificationsSettingsAction
  | GetNotificationsSuccessSettingsAction
  | GetNotificationsFailSettingsAction
  | PatchNotificationsSuccessSettingsAction
  | PostNotificationsSuccessSettingsAction
  | UpsertNotificationsSettingsAction
  | UpsertNotificationsSettingsSuccessAction
  | UpsertNotificationsSettingsFailureAction
  | UpdateNotificationsSettingsAction

export type PatchNotificationsSuccessSettingsAction = {
  type: Types.PATCH_SUCCESS
  payload: {
    data: NotificationSettings[]
  }
}

export type PostNotificationsSuccessSettingsAction = {
  type: Types.POST_SUCCESS
  payload: {
    data: NotificationSettings[]
  }
}

export type GetNotificationsSettingsAction = {
  payload: { contactPersonId: string }
  type: Types.GET
}

export type GetNotificationsSuccessSettingsAction = {
  payload: { data: NotificationSettings[] }
  type: Types.GET_SUCCESS
}

export type GetNotificationsFailSettingsAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type UpsertNotificationsSettingsAction = {
  type: Types.UPSERT
  payload: {
    data: NotificationSettings[]
  }
}

export type UpsertNotificationsSettingsSuccessAction = {
  type: Types.UPSERT_SUCCESS
}

export type UpsertNotificationsSettingsFailureAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.UPSERT_FAIL
}

export type UpdateNotificationsSettingsAction = {
  payload: { data: UpdateNotificationSettingsDto }
  type: Types.UPDATE_NOTIFICATIONS
}

export function patchNotificationsSuccess(
  data: NotificationSettings[]
): Action {
  return {
    type: Types.PATCH_SUCCESS,
    payload: {
      data
    }
  }
}

export function postNotificationsSuccess(data: NotificationSettings[]): Action {
  return {
    type: Types.POST_SUCCESS,
    payload: {
      data
    }
  }
}

export function getNotificationsSettings(contactPersonId: string): Action {
  return {
    type: Types.GET,
    payload: { contactPersonId }
  }
}

export function getNotificationsSettingsSuccess(
  data: NotificationSettings[]
): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getNotificationsSettingsFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function upsertNotifications(data: NotificationSettings[]): Action {
  return {
    type: Types.UPSERT,
    payload: {
      data
    }
  }
}

export function upsertNotificationsSuccess(): Action {
  return {
    type: Types.UPSERT_SUCCESS
  }
}

export function upsertNotificationsFailure(
  errors: Record<string, unknown>
): Action {
  return {
    type: Types.UPSERT_FAIL,
    payload: { errors }
  }
}

export function updateNotifications(
  data: UpdateNotificationSettingsDto
): Action {
  return {
    payload: { data },
    type: Types.UPDATE_NOTIFICATIONS
  }
}
