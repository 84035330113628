import { SagaIterator } from "redux-saga"
import { put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./appEvents.actions"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import { intl, Locales } from "app/i18n/config"
import { getPreferredLanguage } from "../me/me.selectors"

export function* createEventFlow(
  action: actions.FireEventAction
): SagaIterator {
  try {
    const languagePreference: Locales = yield select(getPreferredLanguage)
    const identifier = action.payload.event.uniqueIdentifier
    const title = identifier.replace("-", " ")
    const page_title = intl[languagePreference].formatMessage({
      id: `portal.route.title.modal.${identifier}`
    })
    yield put(
      createGAEvent(PossibleGAEvents.PAGE_VIEW, {
        page_title,
        page_location: `${title} modal`
      })
    )
  } catch (e: any) {
    console.warn(e)
  }
}

export default function* appEventsSaga(): SagaIterator {
  yield takeLatest(actions.Types.FIRE, createEventFlow)
}
