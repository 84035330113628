import React from "react"
import { useTranslate } from "hooks/translate"
import {
  BuildingCheckboxWrapper,
  BuildingSelectWrapper,
  DeleteModalTextBold,
  DeleteModalTextLight,
  RadioGroupWrapper
} from "../style"
import {
  BaseDeleteModal,
  Checkbox,
  Radio,
  RadioGroup
} from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import * as deleteCpSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { deleteContactPerson } from "app/redux/deleteContactPerson/deleteContactPerson.actions"
import { isCpSoleAdminOnAssignedBuilding } from "app/utils/deleteContactPerson.utils"
import * as meSelectors from "app/redux/me/me.selectors"
import { useApi } from "hooks/api"
import { useRentedBuilding } from "hooks/rentedBuilding"

type CheckBoxOptionProps = {
  key: string
  name: string
  checked: boolean
  label: string
  guidanceText: string
  disabled: boolean
}

type DeleteContactPersonMultiBuildingModalProps = {
  onCancel: () => void
}

type Props = DeleteContactPersonMultiBuildingModalProps

const DeleteContactPersonMultiBuildingModal = ({ onCancel }: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const { rentedBuilding } = useRentedBuilding()
  const [buildingsToDeleteFrom, setBuildingsToDeleteFrom] = React.useState<
    string[]
  >([])
  const [stillWorksAtCompany, setStillWorksAtCompany] = React.useState<
    string | undefined
  >(undefined)
  const email = useSelector(deleteCpSelectors.getEmailOfContactPerson)
  const contactPersonId = useSelector(deleteCpSelectors.getIdOfContactPerson)
  const isLoading = useSelector(deleteCpSelectors.isLoading)
  const companyName = useSelector(meSelectors.getUserCompanyName)
  const cpBuildingsToDeleteFrom = useSelector(
    deleteCpSelectors.getBuildingsCpCanBeDeletedFrom
  )

  const checkboxData = cpBuildingsToDeleteFrom.reduce((data, building) => {
    const contactPerson = building.contactPersons.find(
      cp => cp.id === contactPersonId
    )
    if (!contactPerson) return data
    return [
      ...data,
      {
        key: building.id,
        name: building.id,
        checked: buildingsToDeleteFrom.includes(building.id),
        label: `${building.street} ${building.houseNo}, ${building.city}`,
        guidanceText: contactPerson.roles.join(", "),
        disabled: isCpSoleAdminOnAssignedBuilding(contactPerson.id, building)
      }
    ]
  }, [] as CheckBoxOptionProps[])

  const onDelete = () => {
    if (
      !contactPersonId ||
      !rentedBuilding ||
      buildingsToDeleteFrom.length === 0
    )
      return
    call(
      deleteContactPerson(
        contactPersonId,
        buildingsToDeleteFrom,
        stillWorksAtCompany === undefined
          ? true
          : stillWorksAtCompany === "available"
      )
    )
  }

  const handleCheckboxClick = (val: boolean, name: string) => {
    if (buildingsToDeleteFrom.length === cpBuildingsToDeleteFrom.length)
      setStillWorksAtCompany(undefined)
    setBuildingsToDeleteFrom(
      buildingsToDeleteFrom.includes(name)
        ? buildingsToDeleteFrom.filter(building => building !== name)
        : [...buildingsToDeleteFrom, name]
    )
  }

  const handleRadioClick = (name: string) => {
    setStillWorksAtCompany(name)
  }

  return (
    <BaseDeleteModal
      title={translate("portal.admin.delete.seat.restrict.access")}
      cancelBtnText={translate("portal.admin.delete.seat.cancel")}
      deleteBtnText={
        buildingsToDeleteFrom.length === cpBuildingsToDeleteFrom.length
          ? translate("portal.admin.delete.seat.delete.account")
          : buildingsToDeleteFrom.length > 1 ||
            buildingsToDeleteFrom.length === 0
          ? translate("portal.admin.delete.seat.revoke.account.multi", {
              amount: buildingsToDeleteFrom.length
            })
          : translate("portal.admin.delete.seat.revoke.account.single")
      }
      deleteDisabled={
        isLoading ||
        !contactPersonId ||
        buildingsToDeleteFrom.length === 0 ||
        (!stillWorksAtCompany &&
          buildingsToDeleteFrom.length === cpBuildingsToDeleteFrom.length)
      }
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <>
        <DeleteModalTextBold marginBottom="1.6rem">
          {translate("portal.admin.delete.seat.restrict.access.multi.action", {
            email
          })}
        </DeleteModalTextBold>
        <DeleteModalTextLight marginBottom="2.4rem">
          {translate(
            "portal.admin.delete.seat.restrict.access.multi.description"
          )}
        </DeleteModalTextLight>
        <BuildingSelectWrapper>
          {checkboxData.map(data => {
            return (
              <BuildingCheckboxWrapper key={data.key}>
                <Checkbox
                  key={data.key}
                  name={data.name}
                  checked={data.checked}
                  label={data.label}
                  guidanceText={data.guidanceText}
                  disabled={data.disabled}
                  onClick={handleCheckboxClick}
                  tabIndex={0}
                  hasSpacing={true}
                />
              </BuildingCheckboxWrapper>
            )
          })}
        </BuildingSelectWrapper>
        {buildingsToDeleteFrom.length === cpBuildingsToDeleteFrom.length && (
          <>
            <DeleteModalTextBold marginBottom="1.6rem">
              {translate("portal.admin.delete.seat.delete.contact.action", {
                companyName
              })}
            </DeleteModalTextBold>
            <RadioGroupWrapper>
              <RadioGroup contentCentered={true}>
                <Radio
                  checked={stillWorksAtCompany === "available"}
                  disabled={false}
                  onClick={handleRadioClick}
                  label={translate(
                    "portal.admin.delete.seat.delete.contact.yes"
                  )}
                  name={"available"}
                />
                <Radio
                  checked={stillWorksAtCompany === "notAvailable"}
                  disabled={false}
                  onClick={handleRadioClick}
                  label={translate(
                    "portal.admin.delete.seat.delete.contact.no"
                  )}
                  name={"notAvailable"}
                />
              </RadioGroup>
            </RadioGroupWrapper>
          </>
        )}
      </>
    </BaseDeleteModal>
  )
}

export default DeleteContactPersonMultiBuildingModal
