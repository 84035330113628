import { AccountCreated, PopUp } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"

type Props = {
  onClose: () => void
}

export const AccountDeletedPopUp = ({ onClose }: Props): JSX.Element => {
  const { translate } = useTranslate()

  return (
    <PopUp show delayInMs={4000} onClose={onClose}>
      <AccountCreated value={translate("portal.admin.delete.seat.success")} />
    </PopUp>
  )
}
