import {
  InformationSection,
  InformationSectionContent,
  InformationSectionHeader
} from "@paudigital/wdp-components"
import InformationSectionTitle from "app/components/InformationSectionTitle"
import { useTranslate } from "hooks/translate"
import * as selectors from "app/redux/buildingSpecifications/buildingSpecifications.selectors"
import { useSelector } from "react-redux"
import SpecificationItem from "app/components/SpecificationItem"
import { SpecialSpecification } from "app/types/buildingSpecification"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"
import Certification from "./Certification"
import { isNull } from "lodash"

const BuildingSpecification = () => {
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()
  const buildingSpecification = useSelector(selectors.getBuildingSpecification)

  if (!buildingSpecification) return null

  const {
    quality,
    qualityDescription,
    edge,
    breeam,
    tapa,
    edgeDescription,
    breeamDescription,
    tapaDescription,
    sprinklered,
    floorFlatnessCategory,
    floorFlatnessStandard,
    floorFlatnessStandardDescription,
    freeHeight,
    floorLoad,
    depth,
    depthWarehouse,
    depthParking,
    solarPower,
    loadingDocksLevel,
    loadingDocksTrucks,
    loadingDocksVan
  } = buildingSpecification

  const buildSpecificationString = (
    specification: SpecialSpecification
  ): string => {
    const values = specification.value.sort((a, b) => {
      return b - a
    })
    return values
      .map(v =>
        translate(
          `portal.buildingpassport.measurementunit.${specification.unit}`,
          { value: decimalFormatter(v, 0) }
        )
      )
      .join(", ")
  }
  const buildSpecificationValue = (
    specification: SpecialSpecification
  ): string => {
    const value = specification.value.reduce((acc, val) => (acc += val), 0)
    return translate(
      `portal.buildingpassport.measurementunit.${specification.unit}`,
      { value: decimalFormatter(value, 0) }
    )
  }

  return (
    <InformationSection>
      <InformationSectionHeader>
        <InformationSectionTitle>
          {translate("portal.buildingpassport.specification.title")}
        </InformationSectionTitle>
      </InformationSectionHeader>
      <InformationSectionContent>
        <>
          {floorFlatnessCategory &&
            floorFlatnessStandardDescription &&
            floorFlatnessStandard && (
              <SpecificationItem label="portal.buildingpassport.specification.floorFlatness">
                <>
                  {translate(
                    "portal.buildingpassport.specification.floorFlatness.value",
                    {
                      floorFlatnessCategory,
                      floorFlatnessStandardDescription,
                      floorFlatnessStandard
                    }
                  )}
                </>
              </SpecificationItem>
            )}
          {depth && depth.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.Z100">
              {buildSpecificationString(depth)}
            </SpecificationItem>
          )}
          {depthWarehouse && depthWarehouse.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.warehouse.depth">
              {buildSpecificationString(depthWarehouse)}
            </SpecificationItem>
          )}
          {depthParking && depthParking.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.parking.depth">
              {buildSpecificationString(depthParking)}
            </SpecificationItem>
          )}
          {floorLoad && floorLoad.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.Z101">
              {buildSpecificationString(floorLoad)}
            </SpecificationItem>
          )}
          {freeHeight && freeHeight.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.Z102">
              {buildSpecificationString(freeHeight)}
            </SpecificationItem>
          )}
          {loadingDocksLevel && loadingDocksLevel.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.Z103">
              {buildSpecificationValue(loadingDocksLevel)}
            </SpecificationItem>
          )}
          {loadingDocksTrucks && loadingDocksTrucks.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.Z104">
              {buildSpecificationValue(loadingDocksTrucks)}
            </SpecificationItem>
          )}
          {loadingDocksVan && loadingDocksVan.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.Z105">
              {buildSpecificationValue(loadingDocksVan)}
            </SpecificationItem>
          )}
          {solarPower && solarPower.value.length > 0 && (
            <SpecificationItem label="portal.buildingpassport.specification.Z203">
              {buildSpecificationString(solarPower)}
            </SpecificationItem>
          )}
          {!isNull(sprinklered) && (
            <SpecificationItem label="portal.buildingpassport.specification.sprinklered">
              {translate(
                `portal.buildingpassport.specification.sprinklered.${
                  sprinklered ? "yes" : "no"
                }`
              )}
            </SpecificationItem>
          )}
          <Certification
            quality={quality}
            qualityDescription={qualityDescription}
            breeam={breeam}
            edge={edge}
            tapa={tapa}
            edgeDescription={edgeDescription}
            breeamDescription={breeamDescription}
            tapaDescription={tapaDescription}
          />
        </>
      </InformationSectionContent>
    </InformationSection>
  )
}

export default BuildingSpecification
