import { WorkOrder } from "app/types/ticket"
import WorkOrderCardHeader from "./WorkOrderCardHeader"
import {
  ContentWrapper,
  InfoWrapper,
  WorkOrderInformation,
  InfoTitle,
  InfoValue
} from "./style"
import { useState } from "react"
import { useTranslate } from "hooks/translate"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useApi } from "hooks/api"
import * as actions from "app/redux/workOrder/workOrder.actions"
import { Link } from "@paudigital/wdp-components"

export type WorkOrderProps = {
  workOrder: WorkOrder
}

export const Workorder = ({ workOrder }: WorkOrderProps) => {
  const { translate } = useTranslate()
  const [open, setOpen] = useState(false)
  const onClick = () => {
    setOpen(!open)
  }
  const { rentedBuilding } = useRentedBuilding()
  const { call } = useApi()

  const handleWorkOrderDownload = () => {
    if (workOrder.id && rentedBuilding) {
      call(actions.getWorkOrderAttachments(rentedBuilding.id, workOrder.id))
    }
  }

  return (
    <ContentWrapper>
      <WorkOrderCardHeader
        title={workOrder.description}
        status={workOrder.status}
        plannedOn={workOrder.plannedOn}
        open={open}
        onClick={onClick}
      />
      {open && (
        <WorkOrderInformation>
          {workOrder.plannedOn && (
            <InfoWrapper>
              <InfoTitle>
                {translate("portal.facilitymanagement.workorder.planned-on")}
              </InfoTitle>
              <InfoValue>{workOrder.plannedOn}</InfoValue>
            </InfoWrapper>
          )}
          {workOrder.technicallyCompletedOn && (
            <InfoWrapper>
              <InfoTitle>
                {translate(
                  "portal.facilitymanagement.workorder.technically-completed-on"
                )}
              </InfoTitle>
              <InfoValue>{workOrder.technicallyCompletedOn}</InfoValue>
            </InfoWrapper>
          )}
          {workOrder.administrativelyCompletedOn && (
            <InfoWrapper>
              <InfoTitle>
                {translate(
                  "portal.facilitymanagement.workorder.administrativly-completed-on"
                )}
              </InfoTitle>
              <InfoValue>{workOrder.administrativelyCompletedOn}</InfoValue>
            </InfoWrapper>
          )}
          {workOrder.supplier && (
            <InfoWrapper alignment={"vertical"}>
              <InfoTitle>
                {translate("portal.facilitymanagement.workorder.supplier")}
              </InfoTitle>
              <InfoValue>{workOrder.supplier}</InfoValue>
            </InfoWrapper>
          )}
          {workOrder.attachmentCount > 0 && (
            <InfoWrapper alignment={"vertical"}>
              <InfoTitle>
                {translate(
                  "portal.facilitymanagement.reportdetail.attachments"
                )}
              </InfoTitle>
              <InfoValue>
                {`${workOrder.attachmentCount} ${translate(
                  "portal.facilitymanagement.reportdetail.attachments.attachments"
                )} `}
                <Link
                  value={translate("portal.documents.download.button.label")}
                  type="default"
                  onClick={handleWorkOrderDownload}
                />
              </InfoValue>
            </InfoWrapper>
          )}
        </WorkOrderInformation>
      )}
    </ContentWrapper>
  )
}
