import { Title } from "./style"

type Props = {
  children: string
}

const ExportFilterTitle = ({ children }: Props) => {
  return <Title>{children}</Title>
}

export default ExportFilterTitle
