import State from "../state"
import {
  BuildingSpecification,
  Measurement
} from "app/types/buildingSpecification"

export const loading = (state: State): boolean =>
  state.buildingSpecification.loading
export const getBuildingSpecification = (
  state: State
): BuildingSpecification | undefined => state.buildingSpecification.data
export const errors = (state: State): object =>
  state.buildingSpecification.errors
export const getBuildingMeasurements = (
  state: State
): Measurement[] | undefined => state.buildingSpecification.data?.measurements
