import { BuildingSpecification } from "app/types/buildingSpecification"

export enum Types {
  GET = "BUILDING_SPECIFICATION_GET",
  GET_SUCCESS = "BUILDING_SPECIFICATION_GET_SUCCESS",
  GET_FAIL = "BUILDING_SPECIFICATION_GET_FAIL"
}

export type Action =
  | GetBuildingSpecificationAction
  | GetBuildingSpecificationSuccessAction
  | GetBuildingSpecificationFailAction

export type GetBuildingSpecificationAction = {
  type: Types.GET
}

export type GetBuildingSpecificationSuccessAction = {
  payload: BuildingSpecification
  type: Types.GET_SUCCESS
}

export type GetBuildingSpecificationFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export function getBuildingSpecification(): GetBuildingSpecificationAction {
  return {
    type: Types.GET
  }
}

export function getBuildingSpecificationSuccess(
  payload: BuildingSpecification
): GetBuildingSpecificationSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getBuildingSpecificationFail(
  errors: object
): GetBuildingSpecificationFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
