import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
`

export const DescriptionContainer = styled.div`
  font-size: ${theme.fontSizes.paragraph};
  line-heights: ${theme.lineHeights.paragraph};
  color: ${theme.colors.neutral.darkerGrey};
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ResendButton = styled.button`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.green.contrast};
  background: transparent;
  border: 0;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & strong {
    font-weight: ${theme.fontWeights.bold};
  }
`

export const SwitchButton = styled.button`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.paragraph};
  color: ${theme.colors.green.contrast};
  background: transparent;
  border: 0;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  font-weight: ${theme.fontWeights.bold};
`

export const SwitchButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
