import WdpAdminNavigation from "app/components/WdpAdminNavigation"

const WdpAdmin = () => {
  return (
    <>
      <WdpAdminNavigation/>
    </>
  )
}

export default WdpAdmin