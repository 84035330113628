import {
  DocumentIcon,
  ImageIcon,
  SettingsIcon
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { RentedBuildingContract } from "app/types/rentedBuildingContract"
import { format } from "date-fns"
import {
  StyledCardContent,
  StyledButton,
  StyledCardActions,
  StyledDataIdDiv,
  StyledDescription,
  StyledIcon,
  StyledInfo,
  StyledContractdate
} from "./style"
import { useSelector } from "react-redux"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { DocumentTypes } from "app/enums/documentTypes"
import { useMemo } from "react"

type WidgetCardProps = {
  docType:
    | DocumentTypes.Pictures
    | DocumentTypes.Plans
    | "technicalDetails"
    | "contract"
  amount?: Number
  contracts?: RentedBuildingContract[]
}

type Props = WidgetCardProps

const WidgetCard = ({ amount, docType, contracts }: Props) => {
  const rentedBuildingId = useSelector(getActiveBuilding)
  const { translate } = useTranslate()

  const page = 1
  const perpage = 20
  const sort = "date=desc"

  const getUrl = () => {
    switch (docType) {
      case DocumentTypes.Plans:
      case DocumentTypes.Pictures:
        return `/documents/${rentedBuildingId}?documentType=${docType}&page=${page}&perPage=${perpage}&sort[]=${sort}`
      case "technicalDetails":
        return `/building-passport/${rentedBuildingId}`
      case "contract":
        return `/building-passport/${rentedBuildingId}/#building-passport`
      default:
        return `/documents/${rentedBuildingId}`
    }
  }

  const getIcon = () => {
    switch (docType) {
      case DocumentTypes.Pictures:
        return <ImageIcon />
      case "technicalDetails":
        return <SettingsIcon />
      default:
        return <DocumentIcon />
    }
  }

  const contractTranslationString = useMemo(() => {
    const baseString = `portal.dashboard.widget.buildingPassport.${docType}`
    const translationKey = docType !== "contract" ? 
      baseString : 
      `${baseString}.${contracts!.length > 1 ? "multi" : "single"}`
    return translate(translationKey, { amount: contracts?.length || amount })
  }, [amount, contracts, docType, translate])

  const formatDate = (date: string) => {
    const dateFormat = "dd/MM/yyyy"
    return format(new Date(date), dateFormat)
  }

  const getContractDates = () => {
    return contracts
      ? `${formatDate(contracts[0].startDate)} - ${formatDate(
          contracts[0]?.endDate
        )}`
      : ""
  }

  return (
    <StyledDataIdDiv data-testid={`${docType}Widget`} to={getUrl()}>
      <StyledCardContent>
        <StyledIcon>{getIcon()}</StyledIcon>
        <StyledInfo>
          <>
            <StyledDescription>
              {contractTranslationString}
            </StyledDescription>
            {contracts?.length === 1 ? (
              <StyledContractdate>{getContractDates()}</StyledContractdate>
            ) : null}
          </>
        </StyledInfo>
      </StyledCardContent>
      <StyledCardActions alignment="horizontal">
        <StyledButton
          label={translate("portal.dashboard.widget.button.detail")}
          type="secondary"
        />
      </StyledCardActions>
    </StyledDataIdDiv>
  )
}

export default WidgetCard
