import {
  BaseCard,
  Button,
  CardActions,
  theme
} from "@paudigital/wdp-components"
import styled from "styled-components"
import { HashLink } from "react-router-hash-link"

export const StyledDataIdDiv = styled(HashLink)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  justify-content: space-between;
  color: initial;
`

export const StyledCardContent = styled(BaseCard)`
  align-items: center;
  flex-direction: row;
  box-shadow: none;
`

export const StyledButton = styled(Button)`
  height: 100%;

  button {
    font-weight: ${theme.fontWeights.regular};
    border: 0;
    border-left: 1px solid ${theme.colors.neutral.midGrey};
    border-radius: 0;
    height: 100%;
    min-width: 7.4rem;

    &:hover {
      background-color: initial;
    }
  }
`

export const StyledDescription = styled.h3`
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
`

export const StyledIcon = styled.div`
  margin: 0.4rem 1.3rem 0 1.8rem;
`

export const StyledInfo = styled.div`
  display: block;
`

export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
`

export const StyledContractdate = styled.p`
  font-size: 1.3rem;
`
