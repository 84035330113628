import State from "../state"
import { BuildingInfo } from "app/types/buildingSpecification"

export const getAdminBuildings = ({ adminBuildingSelect }: State) => {
  return adminBuildingSelect.buildings.map((b: BuildingInfo) => {
    return { label: `${b.city} ${b.postalCode} - ${b.street} ${b.houseNo} (${b.customer})`, value: b.id}
  })
}
export const getSearchStringValue = ({ adminBuildingSelect }: State) => adminBuildingSelect.searchString

export const getError = ({ adminBuildingSelect }: State) => adminBuildingSelect.error