export enum NotificationTitles {
  DOCUMENT_CREATED = "document_created",
  DOCUMENT_UPDATED = "document_updated",
  DOCUMENT_CREATED_INGESTION = "document_created_ingestion",
  DOCUMENT_UPDATED_INGESTION = "document_updated_ingestion",
  FACILITY_TICKET_CREATED = "facility_ticket_created",
  FACILITY_TICKET_UPDATED = "facility_ticket_updated",
  INVOICE_CREATED = "invoice_created"
}

export type Notification = {
  id: string
  userId: string
  roomId: string
  dateTime: string
  payload: any
  title: NotificationTitles
  subjectId: string
  read: boolean
}

export type PatchedNotification = {
  id: string
  userId: string
  roomId: string
  lastFetched: string
}
