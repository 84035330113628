import { Button } from "@paudigital/wdp-components"
import { SlideIn } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import MarkdownTranslation from "../MarkdownTranslation"
import { TermsWrapper } from "./style"

type TermsProps = {
  onClose: () => void
}

type Props = TermsProps

const Terms = ({ onClose }: Props) => {
  const { translate } = useTranslate()
  const handleClose = () => {
    onClose()
  }
  return (
    <SlideIn
      show
      onClose={handleClose}
      title={translate("portal.onboarding.terms.title")}
    >
      <TermsWrapper>
        <MarkdownTranslation label="portal.onboarding.terms.content" />
        <Button
          label={translate("portal.onboarding.terms.button.close")}
          onClick={handleClose}
        />
      </TermsWrapper>
    </SlideIn>
  )
}

export default Terms
