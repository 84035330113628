import styled from "styled-components"
import registration from "app/assets/images/registration-background.png"
import { theme } from "@paudigital/wdp-components"

export const Wrapper = styled.div`
  padding: 2.4rem;
  position: relative;
  background: ${theme.colors.neutral.white};
  margin-top: -2.4rem;
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
  @media only screen and (min-width: 60rem) {
    margin-top: 0;
    height: initial;
    padding-top: 6.6rem;
    height: calc(100vh - 9rem);
    overflow: scroll;
    width: 100%;
  }
`

export const SpinnerContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const Header = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 20rem;
  background-image: url(${registration});
  background-position: center;
  background-size: cover;
  @media only screen and (min-width: 60rem) {
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
  }
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 60rem) {
    flex-direction: row;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.neutral.white};
  width: 13rem;
  height: 9rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px 0px 24px 24px;

  @media only screen and (min-width: 60rem) {
    border-radius: 0;
    margin: 0;
    width: 21rem;
    height: 11rem;
  }
`

export const Content = styled.div`
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6.6rem;
`
