import { object, string } from "yup"

export type MfaValue = {
  oneTimeCode: string
}

export type PasswordValue = {
  currentPassword: string
}

export const validationSchema = object({
  currentPassword: string().required("error.required"),
})

export const mfaValidationSchema = object({
  oneTimeCode: string().required("error.required"),
})

export const initialValues = {
  currentPassword: ""
}

export const mfaInitialValues = {
  oneTimeCode: ""
}
