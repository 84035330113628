import Hero from "./Hero.jpg"
import { ModulesEnum } from "app/types/banner"
import ModuleHeader from "../ModuleHeader"

const BuildingPassportPageHeader = () => {
  return (
    <ModuleHeader
      page={"buildingpassport"}
      module={ModulesEnum.BUILDING_PASSPORT}
      Hero={Hero}
    />
  )
}

export default BuildingPassportPageHeader
