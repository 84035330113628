import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledDiv = styled.div`
  border-radius: 4px;
  margin-top: 2rem;
  background: white;
  width: 100%;
  height: 60rem;
  display: flex;
  justify-content: center;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-top: 3.2rem;
  }
`

export const StyledNoMetersContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: 40rem;
  }
`

export const StyledNoEnergyTitle = styled.h4`
  font-size: ${theme.fontSizes.h6};
  margin: 2rem 0;
  text-align: center;
  line-height: ${theme.lineHeights.h5};
  font-size: ${theme.fontSizes.h4};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const StyledNoEnergyText = styled.p`
  text-align: center;
  margin: 2rem 0;
  font-size: ${theme.fontSizes.caption};
  color: ${theme.colors.neutral.darkerGrey};
`
