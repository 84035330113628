import { boolean, object, string } from "yup"
import { phone1, phone2 } from "app/utils/phoneValidation.utils"

export const validationSchema = object({
  firstName: string().required("portal.input.required"),
  lastName: string().required("portal.input.required"),
  function: string().required("portal.input.required").nullable(),
  telephone: phone1.nullable(),
  mobilephone: phone2,
  termsAndConditionsAccepted: boolean().oneOf(
    [true],
    "portal.onboarding.terms.content"
  ),
  privacyPolicyAccepted: boolean().oneOf(
    [true],
    "portal.onboarding.privacy.content"
  )
})

export const initialValues = {
  firstName: "",
  lastName: "",
  function: "",
  telephone: "",
  mobilephone: undefined,
  termsAndConditionsAccepted: false,
  privacyPolicyAccepted: false,
  hasExtraPhoneNumber: false
}
