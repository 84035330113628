import { PvProject } from "app/types/pvProject"

export enum Types {
  GET = "GET_PV_PROJECT",
  GET_SUCCESS = "GET_PV_PROJECT_SUCCESS",
  GET_FAIL = "GET_PV_PROJECT_FAIL"
}

export type Action =
  | GetPvProjectAction
  | GetPvProjectSuccessAction
  | GetPvProjectFailAction

export type GetPvProjectAction = {
  payload: { rentedBuildingId: string }
  type: Types.GET
}

export type GetPvProjectSuccessAction = {
  payload: { data: PvProject }
  type: Types.GET_SUCCESS
}

export type GetPvProjectFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export function getPvProject(rentedBuildingId: string): GetPvProjectAction {
  return {
    payload: { rentedBuildingId },
    type: Types.GET
  }
}

export function getPvProjectSuccess(
  data: PvProject
): GetPvProjectSuccessAction {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getPvProjectFail(
  errors: Record<string, unknown>
): GetPvProjectFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
