import { RentedbuildingResponsibilities } from "app/types/responsibilities"
import { Action, Types } from "./responsibilities.actions"

export type ResponsiblitiesState = {
  data: RentedbuildingResponsibilities[] | undefined
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: ResponsiblitiesState = {
  data: undefined,
  errors: undefined,
  loading: false
}

export default (state = initialState, action: Action): ResponsiblitiesState => {
  switch (action.type) {
    case Types.PATCH:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.SET:
      return {
        ...state,
        data: action.payload.data
      }

    case Types.UPDATE:
      return {
        ...state,
        data: state.data!.map(entry => {
          if (entry.rentedBuildingId !== action.payload.data.rentedBuildingId) return entry
          return {
            ...entry,
            responsibilities: action.payload.data.responsibilities
          }
        })
      }

    case Types.CLEAR:
      return {
        ...state,
        data: undefined,
        loading: false,
        errors: undefined
      }

    default:
      return state
  }
}
