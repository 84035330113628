import { useParams } from "react-router-dom"
import { useApi } from "hooks/api"
import { useEffect } from "react"
import { downloadDocument } from "app/redux/document/document.actions"
import { useTranslate } from "hooks/translate"

const DocumentDownload = () => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const params = useParams()
  const { id } = params
  useEffect(() => {
    if (id) {
      call(downloadDocument(id))
    }
  }, [call, id])
  return <div>{translate("portal.download.start")}</div>
}

export default DocumentDownload
