import React from "react"
import { useSelector } from "react-redux"
import * as meSelectors from "app/redux/me/me.selectors"
import { getContactPersons } from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import { PossibleContactRoles } from "app/enums/roles"
import { RentedBuildingContactRoles } from "app/types/contactPerson"
import { BuildingWrapper } from "./style"
import {
  Separator,
  InputLabel,
  InputError,
  NestedCheckbox,
  SelfEditingWarning
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { RentedBuilding } from "app/types/rentedBuilding"
import { Modules } from "app/enums/modules"
import { usePermission } from "hooks/permission"

type Props = {
  data: RentedBuildingContactRoles[]
  error: boolean
  isEditing: boolean
  onChange: (val: boolean, name: string, rentedBuildingId: string) => void
  isSelf?: boolean
  id: string | undefined
}

const MultipleRentedBuildings = ({
  error,
  data,
  onChange,
  isSelf,
  id
}: Props) => {
  const { hasPermission } = usePermission()
  const { translate } = useTranslate()
  const rentedBuildings = useSelector(meSelectors.getRentedBuildings)
  const hasMultipleCompanies = useSelector(meSelectors.hasMultipleCompanies)
  const assignedPersons = useSelector(getContactPersons)

  const isFull = (maxSeatCount: number, rentedBuildingId: string) => {
    const contactPersonsForRentedbuilding = assignedPersons.find(
      c => c.id === rentedBuildingId
    )
    if (contactPersonsForRentedbuilding!.data.length >= maxSeatCount) {
      return contactPersonsForRentedbuilding!.data.find(p => p.id === id)
        ? false
        : true
    }
    return false
  }

  const transformData = (id: string) => {
    const rentedBuilding = rentedBuildings?.find(r => r.id === id)
    const existingRentedBuilding = data.find(
      (c: RentedBuildingContactRoles) => c.rentedBuildingId === id
    )
    return {
      parent: {
        label: `${rentedBuilding?.street} ${rentedBuilding?.houseNo}, ${rentedBuilding?.city}`,
        customerName: hasMultipleCompanies
          ? rentedBuilding?.entityName
          : undefined
      },
      children: Object.values(PossibleContactRoles).map(r => ({
        name: r,
        label: r,
        guidanceText: translate(`portal.guidanceText.${r}`),
        selected: existingRentedBuilding?.roles.includes(r as any) || false
      }))
    }
  }
  const handleChildSelect = (val: boolean, name: string, id?: string) => {
    onChange(val, name, id!)
  }

  return (
    <>
      <InputLabel
        label={translate("portal.onboarding.step4.title.facilities.label")}
        guidanceText={translate(
          "portal.onboarding.step4.title.facilities.guidance"
        )}
      />
      {isSelf && (
        <SelfEditingWarning
          title={translate("portal.warning.edit.self.title")}
          description={translate("portal.warning.edit.self.text")}
        />
      )}
      <BuildingWrapper>
        {rentedBuildings?.map((r: RentedBuilding, i: number) => {
          const full = isFull(r.maxSeatCount, r.id)
          if (!hasPermission(Modules.USER_MANAGEMENT, r.id)) return null
          return (
            <React.Fragment key={r.id}>
              <NestedCheckbox
                key={r.id}
                defaultCollapsed={false}
                id={r.id}
                data={transformData(r.id)}
                onChildSelect={handleChildSelect}
                full={full}
                fullText={translate("portal.onboarding.step4.roles.full")}
                isSelf={isSelf}
              />
              {i !== rentedBuildings.length - 1 && <Separator />}
            </React.Fragment>
          )
        })}
      </BuildingWrapper>
      {error && (
        <InputError
          errorMessage={translate("portal.onboarding.step4.roles.label")}
        />
      )}
    </>
  )
}

export default MultipleRentedBuildings
