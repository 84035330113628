import { RentedBuildingContact } from "app/types/rentedBuildingContact"

export enum Types {
  GET = "RENTEDBUILDING_CONTACTS_GET",
  GET_SUCCESS = "RENTEDBUILDING_CONTACTS_GET_SUCCESS",
  GET_FAIL = "RENTEDBUILDING_CONTACTS_GET_FAIL"
}

export type Action =
  | GetRentedBuildingContactsAction
  | GetRentedBuildingContactsSuccessAction
  | GetRentedBuildingContactsFailAction

export type GetRentedBuildingContactsAction = {
  payload: { rentedBuildingId: string }
  type: Types.GET
}

export type GetRentedBuildingContactsSuccessAction = {
  payload: RentedBuildingContact[]
  type: Types.GET_SUCCESS
}

export type GetRentedBuildingContactsFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export function getRentedBuildingContacts(
  rentedBuildingId: string
): GetRentedBuildingContactsAction {
  return {
    payload: { rentedBuildingId },
    type: Types.GET
  }
}

export function getRentedBuildingContactsSuccess(
  payload: RentedBuildingContact[]
): GetRentedBuildingContactsSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getRentedBuildingContactsFail(
  errors: object
): GetRentedBuildingContactsFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
