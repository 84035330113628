import { useSelector } from "react-redux"
import Contract from "./Contract"
import { getRentedBuildingContractNumbers } from "app/redux/rentedBuildingContracts/rentedBuildingContracts.actions"
import * as selectors from "app/redux/rentedBuildingContracts/rentedBuildingContracts.selector"
import { useApi } from "hooks/api"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import React from "react"
import { Filters } from "routes/Documents"

export type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const ContractFilter = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { call } = useApi()
  const rentedBuildingId = useSelector(getActiveBuilding)
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }

  const selectedContracts = queryParams?.contractId
    ? (queryParams?.contractId as string[])
    : []

  React.useEffect(() => {
    rentedBuildingId && call(getRentedBuildingContractNumbers(rentedBuildingId))
  }, [call, rentedBuildingId])

  const contracts: string[] = useSelector(
    selectors.getRentedBuildingContractNumbers
  )

  const clickHandler = (contractId: string) => {
    if (selectedContracts.includes(contractId)) {
      const contractIds = selectedContracts.filter(cId => cId !== contractId)
      setUrlParams({ ...queryParams, contractId: contractIds })
    } else {
      setUrlParams({
        ...queryParams,
        contractId: [...selectedContracts, contractId]
      })
    }
  }
  return (
    <>
      {contracts?.map((contract, i) => (
        <Contract
          key={contract}
          onClick={clickHandler}
          contract={contract}
          checked={queryParams?.contractId?.includes(contract) ?? false}
        />
      ))}
    </>
  )
}
export default ContractFilter
