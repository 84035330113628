import styled from "styled-components"

export const Description = styled.h1.attrs(({ dataTestId }: { dataTestId: string }) => ({
  "data-testid": dataTestId
})) <{ dataTestId: string }>`
  margin-bottom: 1.4rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    margin-right: 2.4rem;
  }
`
