import { SlideIn, Stepper } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import ProblemCategory from "../ReportProblem/ProblemCategory"
import ProblemDetails from "../ReportProblem/ProblemDetails"
import ProblemSummary from "../ReportProblem/ProblemSummary"
import { getStep } from "app/redux/ticketWizard/ticketWizard.selectors"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { fire, remove } from "app/redux/appEvents/appEvents.actions"
import { useApi } from "hooks/api"
import { useEffect } from "react"
import { getTicketCategories } from "app/redux/ticketCategories/ticketCategories.actions"
import { getCategories } from "app/redux/ticketCategories/ticketCategories.selectors"
import { CreateTicketContainer } from "./style"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

const CreateTicket = () => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const categories = useSelector(getCategories)
  const dispatch = useDispatch()
  const step = useSelector(getStep)

  useEffect(() => {
    if (categories.length === 0) call(getTicketCategories())
  }, [call, categories])

  useEffect(() => {
    switch (step) {
      case 1:
        dispatch(createGAEvent(PossibleGAEvents.TICKET_STEP1))
        break
      case 2:
        dispatch(createGAEvent(PossibleGAEvents.TICKET_STEP2))
        break
      case 3:
        dispatch(createGAEvent(PossibleGAEvents.TICKET_STEP3))
        break
      default:
        break
    }
  }, [dispatch, step])

  const handleClose = () => {
    dispatch(remove("create-ticket"))
    dispatch(
      fire({
        eventName: PossibleAppEvents.CLOSE_REPORT,
        uniqueIdentifier: "close-report",
        props: {
          previousUniqueIdentifier: "create-ticket",
          previousEvent: PossibleAppEvents.CREATE_TICKET,
          title: translate(
            "portal.facilitymanagement.createticket.delete.title"
          )
        }
      })
    )
  }

  return (
    <SlideIn show onClose={handleClose} showCloseIcon>
      <CreateTicketContainer data-testid="create-ticket-container">
        <Stepper
          stepText={translate(
            "portal.facilitymanagement.createticket.step.label"
          )}
          activeStep={step}
          config={[
            {
              title: translate("portal.facilitymanagement.createticket.step1"),
              content: <ProblemCategory />
            },
            {
              title: translate("portal.facilitymanagement.createticket.step2"),
              content: <ProblemDetails />
            },
            {
              title: translate("portal.facilitymanagement.createticket.step3"),
              content: <ProblemSummary />
            }
          ]}
        />
      </CreateTicketContainer>
    </SlideIn>
  )
}

export default CreateTicket
