import { PossibleContactRoles } from "app/enums/roles"
import { SortedBuildings } from "app/redux/deleteContactPerson/deleteContactPerson.reducer"
import { ContactPersonRentedBuildingsWithAvailableContactPersons } from "app/types/contactPerson"
import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import { RentedBuilding } from "app/types/rentedBuilding"

export const sortRentedBuilding = (
  sortedBuildings: SortedBuildings,
  cpRentedBuilding: ContactPersonRentedBuildingsWithAvailableContactPersons,
  userRentedBuildingsWhereAdmin: RentedBuilding[]
) => {
  const canDeleteOnThisBuilding = userRentedBuildingsWhereAdmin.some(
    userAdminBuilding => {
      return userAdminBuilding.id === cpRentedBuilding.id
    }
  )

  return canDeleteOnThisBuilding
    ? {
        canDeleteFrom: [...sortedBuildings.canDeleteFrom, cpRentedBuilding],
        cannotDeleteFrom: [...sortedBuildings.cannotDeleteFrom]
      }
    : {
        canDeleteFrom: [...sortedBuildings.canDeleteFrom],
        cannotDeleteFrom: [
          ...sortedBuildings.cannotDeleteFrom,
          cpRentedBuilding
        ]
      }
}

export const determineDeleteFlow = (
  currentUserId: string,
  cpToDeleteId: string,
  isMultiBuildingDelete: boolean,
  isAssignedToBuildingsNotManagedByUser: boolean
): DeleteContactPersonFlows => {
  if (currentUserId === cpToDeleteId)
    return DeleteContactPersonFlows.ADMIN_IS_CURRENT_USER
  if (isMultiBuildingDelete) {
    return isAssignedToBuildingsNotManagedByUser
      ? DeleteContactPersonFlows.REVOKE_ACCESS_MULTI_BUILDING
      : DeleteContactPersonFlows.DELETE_MULTI_BUILDING
  }
  return isAssignedToBuildingsNotManagedByUser
    ? DeleteContactPersonFlows.REVOKE_ACCESS_SINGLE_BUILDING
    : DeleteContactPersonFlows.DELETE_SINGLE_BUILDING
}

export const isCpSoleAdminOnAssignedBuilding = (
  cpId: string,
  building: ContactPersonRentedBuildingsWithAvailableContactPersons
): boolean => {
  const cp = building.contactPersons.find(cp => cp.id === cpId)
  const admins = building.contactPersons.filter(cp =>
    cp.roles.includes(PossibleContactRoles.ADMIN)
  )
  if (!cp) return false
  return cp.roles.includes(PossibleContactRoles.ADMIN) && admins.length <= 1
}
