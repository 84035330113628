import { NotificationSettings } from "app/types/notificationSettings"
import { Action, Types } from "./notificationSettings.actions"

export type NotificationsSettingsState = {
  data: NotificationSettings[] | null
  isDirty: boolean
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: NotificationsSettingsState = {
  data: null,
  isDirty: false,
  errors: undefined,
  loading: false
}

export default (state = initialState, action: Action): NotificationsSettingsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PATCH_SUCCESS:
    case Types.POST_SUCCESS:
      return {
        ...state,
        isDirty: true,
        data: [
          ...(state.data?.map(currentEntry => {
            const newEntry = action.payload.data.find(
              s => s.rentedBuildingId === currentEntry.rentedBuildingId
            )
            if (newEntry) {
              return newEntry
            }
            return currentEntry
          }) as NotificationSettings[])
        ]
      }

    case Types.UPSERT:
      return {
        ...state,
        loading: true
      }

    case Types.UPSERT_SUCCESS:
      return {
        ...state,
        isDirty: true,
        loading: false
      }

    case Types.UPSERT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        isDirty: true,
        data: [
          ...(state.data?.map(currentEntry => {
            if (
              currentEntry.rentedBuildingId ===
              action.payload.data.rentedBuildingId
            ) {
              return {
                id: currentEntry.id,
                rentedBuildingId: currentEntry.rentedBuildingId,
                facility: action.payload.data.facility,
                invoice: action.payload.data.invoice,
                documents: action.payload.data.documents
              }
            }
            return currentEntry
          }) as NotificationSettings[])
        ]
      }

    default:
      return state
  }
}
