import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"


export const TotalConsumptionGraphWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const GraphWrapper = styled.div`
`

export const LegendWrapper = styled.div`
  margin-left: 1.2rem;
`

export const LegendItemWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

export const LegendIconWrapper = styled.div`
  flex: 0 0 0;
`

export const LegendIcon = styled.span<{ color: string }>`
  display: inline-block;
  background: ${props => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`

export const LegendLabelWrapper = styled.div`
  margin-left: 0.8rem;
`

export const LegendLabel = styled.p`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  color: ${theme.colors.neutral.darkGrey};
  margin-left: 0.4rem;
  margin-bottom: 0.4rem;

  & > span {
    text-transform: initial;
    font-size: ${theme.fontSizes.paragraph};
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights.paragraph};
    color: ${theme.colors.neutral.darkerGrey};
    padding-right: 0.8rem;
  }
`

export const LegendLabelSubText = styled.p`
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  color: ${theme.colors.neutral.darkGrey};
  margin-left: 0.4rem;
  margin-bottom: 0.8rem;
`

export const SolarInjectionGraphWrapper = styled.div`
  margin-top: 4rem;
`
