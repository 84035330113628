import { Button, Typography } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { getAmountOfRentedBuildings } from "app/redux/me/me.selectors"
import { useDispatch, useSelector } from "react-redux"
import BuildingIllustration from "./icon"
import {
  BuildingSelectionWidgetContainer,
  BuildingSelectionWidgetBuildingWrapper,
  TypographyWrapper,
  Container
} from "./style"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"

const BuildingSelectionWidget = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const amount = useSelector(getAmountOfRentedBuildings)

  const handleClick = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.BUILDINGSWITCHER,
        uniqueIdentifier: "building-switcher"
      })
    )
  }

  if (amount === 1) return null

  return (
    <BuildingSelectionWidgetContainer>
      <Container>
        <BuildingSelectionWidgetBuildingWrapper>
          <BuildingIllustration />
          <TypographyWrapper>
            <Typography variant="h5" fontStyle="bold">
              {translate("portal.dashboard.widget.contact.title", {
                amount
              })}
            </Typography>
          </TypographyWrapper>
        </BuildingSelectionWidgetBuildingWrapper>
        <Button
          onClick={handleClick}
          label={translate("portal.dashboard.widget.contact.button.label")}
        />
      </Container>
    </BuildingSelectionWidgetContainer>
  )
}

export default BuildingSelectionWidget
