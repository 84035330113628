import { WorkOrder, ChevronIcon } from "@paudigital/wdp-components"
import WorkOrderStatus from "app/components/WorkOrderStatus"
import {
  HeaderContainer,
  WorkOrderIcon,
  HeaderInfo,
  WorkOrderTitle,
  InfoContainer,
  ChevronWrapper
} from "./style"

type WorkOrderCardHeaderProps = {
  title: string
  status: string
  plannedOn?: string
  open: boolean
  onClick: () => any
}

export const WorkOrderCardHeader = ({
  title,
  status,
  plannedOn,
  open,
  onClick
}: WorkOrderCardHeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderInfo>
        <WorkOrderIcon>{<WorkOrder />}</WorkOrderIcon>
        <InfoContainer>
          <WorkOrderTitle>{title}</WorkOrderTitle>
          <WorkOrderStatus status={status} plannedOn={plannedOn} />
        </InfoContainer>
      </HeaderInfo>
      <ChevronWrapper open={open} onClick={onClick}>
        <ChevronIcon />
      </ChevronWrapper>
    </HeaderContainer>
  )
}

export default WorkOrderCardHeader
