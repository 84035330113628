import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getBuildingSpecification = (
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(
    `rented_buildings/${rentedBuildingId}/building_specifications/`,
    {
      method: "GET"
    }
  )
}
