import { Periods } from "app/redux/energyPeriod/energyPeriod.reducer"
import { EnergyUsageComparisonData } from "app/types/energy"
import { useTranslate } from "hooks/translate"
import NoValue from "../NoValue"
import { StyledArrow, StyledSubText, BodyWrapper, Percentage } from "../style"
import {
  convertToTons,
  createArrow,
  getComparisonText,
  unitMapping
} from "../utils/energyHelper.utils"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"
import { convertPeriodToDays } from "app/utils/periodInDays.utils"
import { useSelector } from "react-redux"
import { getEnergyPeriodRange } from "app/redux/energyPeriod/energyPeriod.selectors"

type EnergyComparisonCardBodyProps = {
  comparisonData: EnergyUsageComparisonData
  period: Periods
  isCO2: boolean
}

type Props = EnergyComparisonCardBodyProps

const EnergyComparisonCardBody = ({ comparisonData, period, isCO2 }: Props) => {
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()
  const { from, to } = useSelector(getEnergyPeriodRange)
  const {
    name,
    currentPeriodTotal,
    previousPeriodTotal,
    percentageDifference
  } = comparisonData
  const unit = unitMapping[name]

  const totalDifference =
    currentPeriodTotal && previousPeriodTotal
      ? isCO2
        ? convertToTons(currentPeriodTotal - previousPeriodTotal)
        : currentPeriodTotal - previousPeriodTotal
      : undefined

  const days = convertPeriodToDays(from, to)
  const { text, value } = getComparisonText(period, percentageDifference, days)
  const subtext = translate(text, value)

  return (
    <BodyWrapper>
      <StyledSubText fontWeight="bold" diff={totalDifference} fontSize="h6">
        {totalDifference && (
          <StyledArrow diff={totalDifference}>
            {createArrow(totalDifference)}
          </StyledArrow>
        )}
        {totalDifference && totalDifference > 0 && "+"}
        {totalDifference && totalDifference !== 0 ? (
          decimalFormatter(totalDifference, 0)
        ) : (
          <NoValue />
        )}
        &nbsp;{unit}
        {percentageDifference && (
          <Percentage>
            &emsp;({percentageDifference > 0 && "+"}
            {decimalFormatter(percentageDifference, 0)}%)
          </Percentage>
        )}
      </StyledSubText>
      <StyledSubText fontWeight="regular" fontSize="captionExtraSmall">
        {subtext}
      </StyledSubText>
    </BodyWrapper>
  )
}

export default EnergyComparisonCardBody
