import { Action, Types } from "./workOrder.actions"

export type WorkOrderState = {
  errors: object
  loading: boolean
}

const initialState: WorkOrderState = {
  errors: {},
  loading: false
}

export default (state = initialState, action: Action): WorkOrderState => {
  switch (action.type) {
    case Types.GET_WORK_ORDER_ATTACHMENT:
      return {
        ...state,
        loading: true
      }
    case Types.GET_WORK_ORDER_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case Types.GET_WORK_ORDER_ATTACHMENT_FAIL:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
