import { Button, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const TitleWrapper = styled.div`
  flex: 1 1 100%;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex: auto;
  }
`

export const StyledH3 = styled.h3`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.h6};
  font-weight: ${theme.fontWeights.bold};
  line-height: ${theme.lineHeights.h6};
  margin-bottom: 0.6rem;
`

export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (${theme.breakpoints.tablet}) {
    justify-content: space-between;
  }
`

export const StyledButton = styled(Button)`
  margin-top: 0.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-top: 0;
    margin-left: 0.4rem;
  }
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const HiddenEmailAnchor = styled.a`
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
`
