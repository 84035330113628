import { BaseCard, CardContent, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledRuler = styled.hr`
  width: 100%;
  border: 0.5px solid ${theme.colors.neutral.midGrey};
  margin: 2.4rem 0rem;
`

export const StyledBaseCard = styled(BaseCard)`
  width: 100%;
  cursor: default;
`

export const StyledContentWrapper = styled(CardContent)`
  width: 100%;
  flex-wrap: wrap;
  padding: 2.4rem;
  box-sizing: border-box;

  @media only screen and (${theme.breakpoints.tablet}) {
    padding: 2.4rem 4rem;
  }
`

export const StyledAnchor = styled.a`
  cursor: pointer;
  align-items: center;
  color: ${theme.colors.neutral.darkGrey};
  font-size: ${theme.fontSizes.captionExtraSmall};
  display: none;

  @media only screen and (${theme.breakpoints.tablet}) {
    display: flex;
  }
`

export const HighchartsWrapper = styled.div`
  min-height: 45rem;
  width: 100%;

  .highcharts-tooltip > span {
    top: 0 !important;
    left: 0 !important;
    padding: 8px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid ${theme.colors.neutral.darkGrey};
  }

  g.highcharts-label.highcharts-tooltip {
    display: none;
  }
`
