import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { patchContactPerson } from "app/api/contactPerson"
import * as actions from "./languagePreference.actions"
import * as selectors from "../me/me.selectors"
import * as meActions from "../me/me.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* patchLanguagePreferenceFlow(
  action: actions.PatchLanguagePreferenceAction
): SagaIterator {
  try {
    const { languagePreference } = action.payload
    const user = yield select(selectors.getUser)
    yield call(patchContactPerson, user.id, {
      ...user,
      languagePreference,
      termsAndConditionsAccepted: true,
      privacyPolicyAccepted: true
    })
    yield put(meActions.getMe())
    yield put(actions.patchLanguagePreferenceSuccess())
  } catch (e: any) {
    yield put(actions.patchLanguagePreferenceFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export default function* LanguagePreferenceSaga(): SagaIterator {
  yield takeLatest(actions.Types.PATCH, patchLanguagePreferenceFlow)
}
