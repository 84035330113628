import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const DeleteModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 55rem;
`

export const IllustrationWrapper = styled.div`
  margin-bottom: 3.6rem;
`

export const DeleteModalTitle = styled.h3`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  text-align: center;
  margin-bottom: 0.8rem;
`

export const DeleteModalTextBold = styled.p<{ marginBottom: string }>`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  color: ${theme.colors.neutral.darkerGrey};
  white-space: pre-line;
  margin-bottom: ${props => props.marginBottom};
`

export const DeleteModalTextRegular = styled.p<{ marginBottom: string }>`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  text-align: center;
  color: ${theme.colors.neutral.darkerGrey};
  white-space: pre-line;
  margin-bottom: ${props => props.marginBottom};
`

export const DeleteModalTextLight = styled.p<{ marginBottom: string }>`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.light};
  text-align: center;
  color: ${theme.colors.neutral.darkerGrey};
  white-space: pre-line;
  margin-bottom: ${props => props.marginBottom};
`

export const RadioGroupWrapper = styled.div`
  margin-bottom: 3.6rem;
`

export const BuildingSelectWrapper = styled.div`
  box-sizing: border-box;
  background: ${theme.colors.neutral.lightGrey};
  padding: 2.4rem;
  width: 100%;
  margin-bottom: 1.6rem;

  div:last-child {
    margin-bottom: 0;
  }
`

export const BuildingCheckboxWrapper = styled.div`
  margin-bottom: 1.6rem;
`

export const FullWidthWrapper = styled.div`
  width: 100%;
`
