import InvoicesPage from "app/components/InvoicesManagement"
import { QueryParams } from "app/redux/invoices/invoices.actions"
import { InvoiceStatusTypes, InvoiceType } from "app/types/invoice"
import { useQueryParams } from "hooks/queryParams"

export type Filters = Omit<QueryParams, "rentedBuildingId">

const Invoices = () => {
  const { queryParams, setUrlParams } = useQueryParams<Filters>({
    page: 1,
    perPage: 20,
    sort: ["invoiceDate=desc"]
  })

  const {
    page,
    perPage,
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort,
    status
  } = queryParams
  return (
    <InvoicesPage
      page={Number(page)}
      perPage={Number(perPage)}
      invoiceType={invoiceType as InvoiceType[]}
      contractNumber={contractNumber as string[]}
      invoicePeriodTo={invoicePeriodTo as string}
      invoicePeriodFrom={invoicePeriodFrom as string}
      invoiceDateTo={invoiceDateTo as string}
      invoiceDateFrom={invoiceDateFrom as string}
      invoiceNumber={invoiceNumber as string}
      reference={reference as string}
      sort={sort as string[]}
      status={status as InvoiceStatusTypes[]}
      setUrlParams={setUrlParams}
    />
  )
}
export default Invoices
