import { Spinner } from "@paudigital/wdp-components"
import AppEventProvider from "app/components/AppEventsProvider"
import {
  getMfaToken,
  getLoading
} from "app/redux/authentication/authentication.selectors"
import { useSelector } from "react-redux"
import PasswordVerification from "./PasswordVerification"
import MfaVerification from "./MfaVerification"
import { DescriptionContainer, LoadingContainer } from "./style"
import { Action as ReduxAction } from "redux"

export type VerifyIdentityProps = {
  onSubmit: (currentPassord: string, actionOnSuccess: () => ReduxAction) => void
  actionOnSuccess: () => ReduxAction<any>
  description: string
}

const VerifyIdentity = ({
  onSubmit,
  description,
  actionOnSuccess
}: VerifyIdentityProps) => {
  const mfaToken = useSelector(getMfaToken)
  const isLoading = useSelector(getLoading)

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    )
  }

  return (
    <>
      <AppEventProvider local />
      {mfaToken ? (
        <MfaVerification actionOnSuccess={actionOnSuccess} />
      ) : (
        <>
          <DescriptionContainer>{description}</DescriptionContainer>
          <PasswordVerification
            actionOnSuccess={actionOnSuccess}
            onSubmit={onSubmit}
          />
        </>
      )}
    </>
  )
}

export default VerifyIdentity
