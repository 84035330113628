import { useState } from "react"
import { Button, InputError, SlideIn } from "@paudigital/wdp-components"
import DateRangePicker from "app/components/DateRangePicker"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { useTranslate } from "hooks/translate"
import { useDispatch } from "react-redux"
import { EnergyPeriodQueryParams } from "app/components/PageHeaders/EnergyPageHeader"
import {
  CustomPeriodWrapper,
  Title,
  DateRangeWrapper,
  CustomPeriodContainer,
  ErrorWrapper
} from "./style"
import { setEnergyPeriodAction } from "app/redux/energyPeriod/energyPeriod.actions"
import { formatISODateWithoutTimezone } from "app/utils/dateFormatter.utils"
import { startOfDay } from "date-fns"
import { getCustomEnergyPeriod } from "app/utils/energy.utils"
import moment from "moment"
import { Periods } from "app/redux/energyPeriod/energyPeriod.reducer"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

enum DateName {
  START_PERIOD = "start_period",
  END_PERIOD = "end_period"
}

type DatePickerDateName = "from" | "to"

type Props = {
  start_period: string
  end_period: string
  period: Periods
  setUrlParams: (params: EnergyPeriodQueryParams) => void
}

const dateNameMapper = {
  from: DateName.START_PERIOD,
  to: DateName.END_PERIOD
}

const CustomPeriod = ({
  start_period,
  end_period,
  period,
  setUrlParams
}: Props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()

  const [dates, setDates] = useState<EnergyPeriodQueryParams>({
    start_period,
    end_period,
    period
  } as EnergyPeriodQueryParams)

  const [datesErrors, setDatesErrors] = useState({ from: false, to: false })

  const handleApply = () => {
    const period = getCustomEnergyPeriod(dates.start_period, dates.end_period)
    setUrlParams({ ...dates, period })
    dispatch(
      setEnergyPeriodAction(dates.start_period, dates.end_period, period)
    )
    dispatch(
      createGAEvent(PossibleGAEvents.TIMEPERIOD, {
        period: "Custom"
      })
    )
    setTimeout(() => close(), 100)
  }

  const close = () => {
    dispatch(remove("custom-period"))
  }

  const hasErrors = Object.values(datesErrors).includes(true)

  const getStartOfDay = (date: string, name: DatePickerDateName) =>
    startOfDay(new Date(date !== "" ? date : dates[dateNameMapper[name]]))

  const updateDate = (date: string, name: DatePickerDateName) => {
    const dayStart = getStartOfDay(date, name)
    if (!dayStart) {
      setDatesErrors({ ...datesErrors, [name]: !date })
      return
    }
    const formattedDate = formatISODateWithoutTimezone(dayStart)
    setDatesErrors({ from: false, to: false })
    setDates({
      ...dates,
      [dateNameMapper[name]]: formattedDate
    })
  }

  return (
    <SlideIn show onClose={close} showCloseIcon>
      <CustomPeriodWrapper>
        <Title>{translate("portal.energy.period.custom.title")}</Title>
        <CustomPeriodContainer>
          <DateRangeWrapper>
            <DateRangePicker
              id="custom-energy-period"
              from={dates.start_period}
              to={dates.end_period}
              orientation="row"
              endDate={moment().add(1, "days").toISOString()}
              updateDate={updateDate as any}
            />
            {hasErrors && (
              <ErrorWrapper>
                <InputError
                  errorMessage={translate("portal.energy.period.custom.error")}
                />
              </ErrorWrapper>
            )}
          </DateRangeWrapper>
        </CustomPeriodContainer>
        <Button
          label={translate("portal.energy.period.custom.button")}
          fullWidth
          onClick={handleApply}
          disabled={hasErrors}
        />
      </CustomPeriodWrapper>
    </SlideIn>
  )
}

export default CustomPeriod
