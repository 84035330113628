export enum Types {
  GET_WORK_ORDER_ATTACHMENT = "GET_WORK_ORDER_ATTACHMENT",
  GET_WORK_ORDER_ATTACHMENT_SUCCESS = "GET_WORK_ORDER_ATTACHMENT_SUCCESS",
  GET_WORK_ORDER_ATTACHMENT_FAIL = "GET_WORK_ORDER_ATTACHMENT_FAIL"
}

export type Action =
  | GetWorkOrderAttachmentsAction
  | GetWorkOrderAttachmentsSuccessAction
  | GetWorkOrderAttachmentsFailAction

export type GetWorkOrderAttachmentsAction = {
  payload: { rentedBuildingId: string; workOrderId: string }
  type: Types.GET_WORK_ORDER_ATTACHMENT
}

export type GetWorkOrderAttachmentsSuccessAction = {
  type: Types.GET_WORK_ORDER_ATTACHMENT_SUCCESS
}

export type GetWorkOrderAttachmentsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_WORK_ORDER_ATTACHMENT_FAIL
}

export function getWorkOrderAttachments(
  rentedBuildingId: string,
  workOrderId: string
): GetWorkOrderAttachmentsAction {
  return {
    payload: { rentedBuildingId, workOrderId },
    type: Types.GET_WORK_ORDER_ATTACHMENT
  }
}

export function getWorkOrderAttachmentsSuccess(): GetWorkOrderAttachmentsSuccessAction {
  return {
    type: Types.GET_WORK_ORDER_ATTACHMENT_SUCCESS
  }
}

export function getWorkOrderAttachmentsFail(
  errors: Record<string, unknown>
): GetWorkOrderAttachmentsFailAction {
  return {
    payload: { errors },
    type: Types.GET_WORK_ORDER_ATTACHMENT_FAIL
  }
}
