import { Pagination } from "./style"

type Props = {
  children: JSX.Element[] | JSX.Element
}

const PagePagination = ({ children }: Props) => {
  return <Pagination>{children}</Pagination>
}

export default PagePagination
