import { Action, Types } from "./ticketCommunication.actions"

export type TicketCommunicationState = {
  customerCommunication:
    | { customerCommunication: string | undefined; files: File[] }
    | undefined
  errors: Record<string, unknown>
  loading: boolean
}

const initialState: TicketCommunicationState = {
  customerCommunication: undefined,
  errors: {},
  loading: false
}

export default (
  state = initialState,
  action: Action
): TicketCommunicationState => {
  switch (action.type) {
    case Types.PATCH:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        customerCommunication: undefined,
        errors: {},
        loading: false
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.SET:
      return {
        ...state,
        customerCommunication: action.payload
      }

    case Types.DISCARD:
      return {
        ...state,
        customerCommunication: undefined
      }

    default:
      return state
  }
}
