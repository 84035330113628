import { Title } from "./style"

type InformationSectionTitleProps = {
  children: JSX.Element | string
}

type Props = InformationSectionTitleProps

const InformationSectionTitle = ({ children }: Props) => {
  return <Title>{children}</Title>
}

export default InformationSectionTitle
