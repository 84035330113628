import { AppEvent } from "./types"

export enum Types {
  FIRE = "FIRE_EVENT",
  REMOVE = "REMOVE_EVENT",
  CLEAR = "CLEAR_EVENT"
}

export type Action = FireEventAction | RemoveEventAction | ClearEventAction

export type FireEventAction = {
  payload: { event: AppEvent }
  type: Types.FIRE
}

export type RemoveEventAction = {
  payload: { uniqueIdentifier: string }
  type: Types.REMOVE
}

export type ClearEventAction = {
  type: Types.CLEAR
}

export function fire(event: AppEvent): Action {
  return {
    payload: { event },
    type: Types.FIRE
  }
}

export function remove(uniqueIdentifier: string): Action {
  return {
    payload: { uniqueIdentifier },
    type: Types.REMOVE
  }
}

export function clear(): Action {
  return {
    type: Types.CLEAR
  }
}
