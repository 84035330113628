import { RentedBuildingContract } from "app/types/rentedBuildingContract"
import { Action, Types } from "./rentedBuildingContracts.actions"

export type RentedBuildingContractsState = {
  data: RentedBuildingContract[]
  contractNumbers: string[]
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: RentedBuildingContractsState = {
  data: [],
  contractNumbers: [],
  errors: undefined,
  loading: false
}

export default (
  state = initialState,
  action: Action
): RentedBuildingContractsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_CONTRACT_NUMBERS:
      return {
        ...state,
        loading: true
      }

    case Types.GET_CONTRACT_NUMBERS_SUCCESS:
      return {
        ...state,
        contractNumbers: action.payload.data,
        loading: false
      }

    case Types.GET_CONTRACT_NUMBERS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
