type Question = {
  "en-GB": string
  "fr-FR": string
  "nl-BE": string
  "nl-NL": string
  "ro-RO": string
  "de-DE": string
}

export enum SurveyTarget {
  TICKET_CREATION = "ticket_creation"
}

export type Survey = {
  surveyId: string
  target: SurveyTarget
  question: Question
}

export type SurveyAnswer = {
  surveyId: string
  rating: number
  response: string
}
