import FacilityManagementPage from "app/components/FacilityManagement"
import { FacilityTicketStatus } from "app/enums/ticketStatus"
import { QueryParams } from "app/redux/ticket/ticket.actions"
import { TicketTypes } from "app/types/ticket"
import { useQueryParams } from "hooks/queryParams"

export type Filters = Omit<QueryParams, "rentedBuildingId">
export type FiltersAndModalOpened = Omit<
  QueryParams,
  "rentedBuildingId" | "modalOpen"
>
const FacilityManagement = () => {
  const { queryParams, setUrlParams } = useQueryParams<Filters>({
    page: 1,
    perPage: 30,
    sort: ["creation_date=desc"]
  })
  const {
    page,
    perPage,
    sort,
    status,
    type,
    from,
    to,
    ticket,
    description,
    modalOpen
  } = queryParams

  return (
    <FacilityManagementPage
      page={Number(page)}
      perPage={Number(perPage)}
      sort={sort as string[]}
      status={status as FacilityTicketStatus[]}
      type={type as TicketTypes[]}
      from={from as string}
      to={to as string}
      ticket={ticket as string}
      description={description as string}
      setUrlParams={setUrlParams}
      modalOpen={modalOpen as Filters["modalOpen"]}
    />
  )
}

export default FacilityManagement
