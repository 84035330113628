import { BuildingInfo } from "app/types/buildingSpecification"
import { Action, Types } from "./adminBuildingSelect.actions"

export type AdminBuildingSelectState = {
  searchString: string | undefined
  buildings: BuildingInfo[]
  error: boolean
}

const initialState: AdminBuildingSelectState = {
  searchString: undefined,
  buildings: [],
  error: false
}

export default (state = initialState, action: Action): AdminBuildingSelectState => {
  switch (action.type) {
    case Types.GET_BUILDINGS:
      return {
        ...state,
        error: false
      }
    case Types.GET_BUILDINGS_SUCCESS:
      return {
        ...state,
        buildings: action.payload.data,
        error: true
      }
    case Types.GET_BUILDINGS_FAIL:
      return {
        ...state,
        error: false
      }
    case Types.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload.value
      }

    default:
      return state
  }
}
