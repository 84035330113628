import { Button, theme } from "@paudigital/wdp-components"
import { useViewport } from "hooks/viewport"
import {
  ApplyFiltersButton,
  PageFiltersWrapper,
  StyledPageFilter
} from "./style"
import { useTranslate } from "hooks/translate"

export type PageFilterProps = {
  children: JSX.Element[] | JSX.Element
  closeFilters?: () => void
}

type Props = PageFilterProps

const PageFilter = ({ children, closeFilters }: Props) => {
  const { width } = useViewport()
  const { translate } = useTranslate()

  const handleClose = () => {
    closeFilters && closeFilters()
  }

  return (
    <StyledPageFilter>
      <PageFiltersWrapper>{children}</PageFiltersWrapper>
      {width < theme.breakpointsValues.desktop && (
        <ApplyFiltersButton>
          <div onMouseUp={handleClose}>
            <Button
              label={translate("portal.pageFilter.apply")}
              onClick={handleClose}
            />
          </div>
        </ApplyFiltersButton>
      )}
    </StyledPageFilter>
  )
}

export default PageFilter
