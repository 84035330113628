import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const TopBarMobile = styled.div<{ isOpen: boolean }>`
  position: relative;
  margin: 0;
  grid-area: topbar-mobile;
  display: flex;
  flex-direction: column;
`

export const StyledTotalTickets = styled.h4<{ loading: boolean }>`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const TopButtons = styled.div`
  display: flex;
  gap: 0 1.6rem;
  margin-top: 1.6rem;
  justify-content: space-between;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  display: flex;

  Button {
    margin-right: 1.6rem;
  }
`
