import { Campaign } from "app/types/banner"
import { Action, Types } from "./campaign.actions"

export type CampaignState = {
  data: Campaign[]
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: CampaignState = {
  data: [],
  errors: undefined,
  loading: false
}

export default (state = initialState, action: Action): CampaignState => {
  switch (action.type) {
    case Types.GET_CAMPAIGN:
      return {
        ...state,
        loading: true
      }
    case Types.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }
    case Types.GET_CAMPAIGN_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: true
      }
    default:
      return state
  }
}
