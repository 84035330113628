import { DropDown, Separator } from "@paudigital/wdp-components"
import ModuleWidget from "app/components/ModuleWidget"
import { useTranslate } from "hooks/translate"
import React from "react"
import { useSelector } from "react-redux"
import Icon from "./icon"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { Expert, ExpertWrapper } from "./style"
import { getRolesByRentedBuildingId } from "app/redux/me/me.selectors"
import { useContactPerson } from "hooks/contactPerson"
import ExpertContactOptions from "app/components/ExpertContactOptions"
import { PossibleContactRoles } from "app/enums/roles"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { OnChangeValue } from "react-select"

const getOptionBasedOnContactRole = (roles: PossibleContactRoles[]) => {
  if (roles.includes(PossibleContactRoles.ADMIN)) {
    return "facility"
  }
  if (roles.includes(PossibleContactRoles.GENERAL_MANAGER)) {
    return "commercial"
  }
  if (roles.includes(PossibleContactRoles.FACILITY_MANAGER)) {
    return "facility"
  }
  if (roles.includes(PossibleContactRoles.FINANCE)) {
    return "finance"
  }
}
const options = [
  "energy",
  "finance",
  "facility",
  "portal",
  "commercial",
  "idea"
]
const Experts = () => {
  const { translate } = useTranslate()
  const { rentedBuilding } = useRentedBuilding()
  const { contactMapping } = useContactPerson()

  const id = rentedBuilding?.id
  const roles = useSelector(getRolesByRentedBuildingId(id))
  const [selectedOption, setSelectedOption] = React.useState<
    string | undefined
  >(undefined)

  React.useEffect(() => {
    if (!selectedOption && roles) {
      setSelectedOption(getOptionBasedOnContactRole(roles))
    }
  }, [selectedOption, setSelectedOption, roles])

  const handleChange = (optionData: OnChangeValue<CustomOptionType, false>) => {
    const value = (optionData as CustomOptionType).value
    setSelectedOption(value)
  }

  if (!selectedOption) return null
  const associatedContact = contactMapping[selectedOption]
  return (
    <ModuleWidget
    title={translate("portal.dashboard.widget.expert.title")}
    description={translate("portal.dashboard.widget.expert.guidance")}
    image={<Icon />}
    isExperts
    >
      <>
        <DropDown
          onChange={handleChange}
          options={options.map(o => ({
            value: o,
            label: translate(`portal.overview.contact.option.${o}`)
          }))}
          hasDropdownIndicator
          placeholder={translate(
            `portal.overview.contact.option.${selectedOption}`
          )}
        />
        <ExpertWrapper>
          <Expert>
            {translate("portal.dashboard.widget.expert.expert", {
              role: associatedContact?.role,
              firstName: associatedContact?.person?.firstName,
              lastName: associatedContact?.person?.lastName
            })}
          </Expert>
          <Separator />
          <ExpertContactOptions
            email={associatedContact?.person?.email}
            phone={associatedContact?.person?.mobilephone}
          />
        </ExpertWrapper>
      </>
    </ModuleWidget>
  )
}

export default Experts
