import {
  getDocuments,
  getDocumentsSummary,
  postDocument,
  getDocumentsStatistics,
  downloadDocumentsCSV
} from "app/api/documents"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./document.actions"
import { getFileNameFromHeaders } from "app/utils/getFileName"
import { getDocumentsById } from "app/api/documents"
import { getActiveBuilding } from "../buildingSwitcher/buildingSwitcher.selectors"
import { fire, remove } from "../appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { actions as wizardActions } from "app/redux/documentWizard/documentWizard.actions"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import { getDocumentById } from "./document.selectors"
import { getInvoiceById } from "./../invoices/invoices.selectors"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getDocumentsFlow(
  action: actions.GetDocumentsAction
): SagaIterator {
  try {
    const response = yield call(getDocuments, action.payload)
    yield put(actions.getDocumentsSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getDocumentsFail(e))
  }
}

export function* downloadDocumentsFlow(
  action: actions.DownloadDocumentAction
): SagaIterator {
  try {
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_LOADING,
        uniqueIdentifier: "document-loading-popup"
      })
    )
    const { id } = action.payload
    const response = yield call(getDocumentsById, id)
    const selectedDocument = yield select(getDocumentById(id))
    const invoice = yield select(getInvoiceById(id))
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.rel = "noreferrer"
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    const downloadedFile = selectedDocument || invoice
    yield put(
      createGAEvent(
        downloadedFile?.invoiceType
          ? PossibleGAEvents.DOWNLOAD_INVOICE
          : PossibleGAEvents.DOWNLOAD_DOCUMENT,
        {
          document_type: downloadedFile.invoiceType ?? downloadedFile.treeType
        }
      )
    )
    yield put(actions.downloadDocumentSuccess())
    yield put(remove("document-loading-popup"))
  } catch (e: any) {
    yield put(remove("document-loading-popup"))
    yield put(actions.downloadDocumentFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export function* downloadDocumentsCSVFlow(
  action: actions.DownloadDocumentsCSVAction
): SagaIterator {
  try {
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_LOADING,
        uniqueIdentifier: "document-loading-popup"
      })
    )
    const rentedBuildingId = yield select(getActiveBuilding)
    const response = yield call(
      downloadDocumentsCSV,
      action.payload.data,
      rentedBuildingId
    )
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(actions.downloadDocumentsCSVSuccess())
    yield put(remove("csv-documents"))
    yield put(remove("document-loading-popup"))
  } catch (e: any) {
    yield put(remove("document-loading-popup"))
    yield put(actions.downloadDocumentFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export function* getDocumentsStatisticsFlow(
  action: actions.GetDocumentsStatisticsAction
): SagaIterator {
  try {
    const { rentedBuildingId } = action.payload
    const response = yield call(getDocumentsStatistics, rentedBuildingId)
    yield put(actions.getDocumentsStatisticsSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getDocumentsStatisticsFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export function* getDocumentsSummaryFlow(
  action: actions.GetDocumentsSummaryAction
): SagaIterator {
  try {
    const { rentedBuildingId } = action.payload
    const response = yield call(getDocumentsSummary, rentedBuildingId)
    yield put(actions.getDocumentsSummarySuccess(response.data))
  } catch (e: any) {
    yield put(actions.getDocumentsSummaryFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export function* postDocumentsFlow(
  action: actions.PostDocumentsAction
): SagaIterator {
  try {
    const { document, documentParams } = action.payload
    const rentedBuildingId = yield select(getActiveBuilding)
    const formData = new FormData()
    formData.append("fileName", document.fileName)
    formData.append("upfile", document.upfile[0])
    formData.append("metadata", JSON.stringify(document.metadata))
    formData.append("documentTypeId", document.documentTypeId)
    formData.append("isParkRelated", document.isParkRelated)
    yield call(postDocument, formData, rentedBuildingId)
    yield put(
      actions.getDocuments({
        ...documentParams,
        rentedBuildingId
      })
    )
    yield put(remove("create-document"))
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_CREATED,
        uniqueIdentifier: "document-created"
      })
    )
    yield put(actions.discardDocument())
    yield put(wizardActions.setWizard(1))
    yield put(actions.postDocumentsSuccess())
  } catch (e: any) {
    yield put(actions.postDocumentsFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export default function* documentsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getDocumentsFlow)
  yield takeLatest(actions.Types.DOWNLOAD, downloadDocumentsFlow)
  yield takeLatest(actions.Types.GET_SUMMARY, getDocumentsSummaryFlow)
  yield takeLatest(actions.Types.GET_STATISTICS, getDocumentsStatisticsFlow)
  yield takeLatest(actions.Types.POST, postDocumentsFlow)
  yield takeLatest(actions.Types.DOWNLOAD_CSV, downloadDocumentsCSVFlow)
}
