import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const LinkWrapper = styled.div`
  margin-top: 0.4rem;
  margin-left: 3rem;
`

export const AddPhoneWrapper = styled.div`
  margin-bottom: 2.6rem;
`

export const TermsWrapper = styled.div`
  background: ${theme.colors.neutral.lightGrey};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.6rem;
  margin-bottom: 1.6rem;
`

export const ErrorWrapper = styled.div`
  margin-bottom: 0.8rem;
`
