const Icon = () => {
  return (
    <svg
      width="148"
      height="98"
      viewBox="0 0 148 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6885 71.7548L9.70966 67.9016C7.97405 67.1675 6.19835 66.5316 4.39099 65.9971L4.2674 67.7878L3.76625 65.815C1.52483 65.1762 0 64.8948 0 64.8948C0 64.8948 2.0597 72.7121 6.37978 78.6885L11.4136 79.571L7.50295 80.1338C8.04661 80.7865 8.63086 81.4045 9.25224 81.984C15.5367 87.8061 22.5365 90.4773 24.8866 87.9503C27.2367 85.4233 24.0473 78.655 17.7628 72.8329C15.8146 71.028 13.3671 69.5796 10.9173 68.4397L10.6885 71.7548Z"
        fill="#E6E6E6"
      />
      <path
        d="M19.9979 67.5703L21.1556 63.7668C20.0501 62.2429 18.8593 60.7825 17.5889 59.3925L16.5557 60.8617L17.1484 58.9143C15.5605 57.2111 14.4009 56.1836 14.4009 56.1836C14.4009 56.1836 12.1155 63.9381 12.7185 71.283L16.5706 74.6354L12.9315 73.0996C13.0588 73.9389 13.2389 74.7693 13.4707 75.586C15.8353 83.8121 20.444 89.71 23.7645 88.7592C27.0851 87.8084 27.86 80.3691 25.4955 72.1429C24.7625 69.5927 23.4175 67.0902 21.9107 64.8504L19.9979 67.5703Z"
        fill="#E6E6E6"
      />
      <path d="M148 89.3315H9.01477V89.742H148V89.3315Z" fill="#3F3D56" />
      <path
        d="M25.7653 46.8252C25.7653 46.8252 24.496 62.057 24.2235 65.3209C24.0366 67.3669 23.5784 69.3791 22.8609 71.3047C22.8609 71.3047 22.3159 72.3927 22.3159 73.4806V87.3522C22.3159 87.3522 21.2258 87.3522 21.4983 88.4402C21.7708 89.5281 22.0434 91.4321 22.0434 91.4321H26.1311C26.1311 91.4321 25.8586 90.6161 26.6762 90.3441C27.4937 90.0721 26.6762 87.8962 26.6762 87.8962L31.0364 71.5767L37.5769 57.9771C37.5769 57.9771 41.3921 70.2167 42.2096 71.5767C42.2096 71.5767 45.2073 86.8082 45.7524 88.1682C46.2974 89.5281 46.5699 89.5281 46.2974 90.0721C46.0249 90.6161 46.0249 91.1601 46.2974 91.4321C46.5699 91.7041 51.7478 91.4321 51.7478 91.4321L50.3852 72.1207L47.9325 49.0014L35.3967 45.1935L25.7653 46.8252Z"
        fill="#2F2E41"
      />
      <path
        d="M25.0411 89.2561C25.0411 89.2561 23.6785 84.3603 21.4984 88.1682C19.3182 91.976 19.3182 93.336 19.3182 93.336C19.3182 93.336 15.7755 98.5039 20.6808 97.9599C25.5861 97.4159 24.496 94.968 24.496 94.968C24.496 94.968 26.4037 93.88 26.4037 93.064C26.4037 92.248 25.0411 89.2561 25.0411 89.2561Z"
        fill="#2F2E41"
      />
      <path
        d="M47.1149 88.7121C47.1149 88.7121 48.4775 83.8162 50.6576 87.6241C52.8378 91.432 52.8378 92.792 52.8378 92.792C52.8378 92.792 56.3805 97.9598 51.4752 97.4158C46.5699 96.8719 47.6599 94.4239 47.6599 94.4239C47.6599 94.4239 45.7523 93.336 45.7523 92.52C45.7523 91.704 47.1149 88.7121 47.1149 88.7121Z"
        fill="#2F2E41"
      />
      <path
        d="M34.3066 10.1066C34.3066 10.1066 34.8516 13.6425 33.2165 14.4584C31.5814 15.2744 36.7592 18.2663 38.1218 18.2663C39.4844 18.2663 41.9371 15.5464 41.9371 15.5464C41.9371 15.5464 40.5745 11.4665 40.847 10.6506L34.3066 10.1066Z"
        fill="#A0616A"
      />
      <path
        d="M37.3043 12.8265C40.4649 12.8265 43.0272 10.2692 43.0272 7.11465C43.0272 3.96009 40.4649 1.40282 37.3043 1.40282C34.1436 1.40282 31.5814 3.96009 31.5814 7.11465C31.5814 10.2692 34.1436 12.8265 37.3043 12.8265Z"
        fill="#A0616A"
      />
      <path
        d="M48.4776 18.8103L41.6648 14.6907C41.6648 14.6907 37.7886 19.0823 33.595 14.1865L26.4036 16.0904L27.4937 34.5859C27.4937 34.5859 25.8586 41.9296 26.4036 43.2896C26.9487 44.6496 25.0411 45.1935 25.5861 45.4655C26.1311 45.7375 25.5861 47.6415 25.5861 47.6415C25.5861 47.6415 36.2143 53.0813 47.9325 49.0014L46.8425 45.4655C46.9908 45.0033 47.0186 44.511 46.9233 44.035C46.8279 43.5591 46.6125 43.1153 46.2974 42.7456C46.2974 42.7456 47.3875 41.1137 46.0249 39.7537C46.0249 39.7537 46.5699 37.3058 45.2073 36.2178L44.9348 32.4099L45.7524 30.506L48.4776 18.8103Z"
        fill="#E28637"
      />
      <path
        d="M34.3755 4.46251C34.3755 4.46251 33.6933 5.9362 36.4224 5.34672C36.4224 5.34672 37.4458 5.9362 37.4458 5.05199C37.4458 5.05199 38.8104 6.52568 39.1515 5.64146C39.4927 4.75725 40.175 5.9362 40.175 5.9362L40.8572 4.46251L41.5395 5.34672H43.9275C43.9275 5.34672 44.6098 -0.548032 36.4224 0.0414448C28.235 0.630922 32.2628 9.97058 32.2628 9.97058C32.2628 9.97058 32.3287 8.29411 33.011 9.17832C33.6933 10.0625 34.3755 4.46251 34.3755 4.46251Z"
        fill="#2F2E41"
      />
      <path
        d="M50.9303 29.69L51.2028 35.4018C51.2028 35.4018 52.2928 46.0095 50.1127 50.6334V53.0813C50.1127 53.0813 49.5677 59.3371 46.8425 59.0651C44.1173 58.7931 47.66 50.0894 47.66 50.0894L47.115 38.1217L46.57 31.322L50.9303 29.69Z"
        fill="#A0616A"
      />
      <path
        d="M45.4799 19.0823L48.1984 18.6415C50.1567 20.5194 51.4906 22.9517 52.0203 25.6101C52.8379 29.962 53.1104 30.778 53.1104 30.778L46.57 32.4099L43.0272 25.6101L45.4799 19.0823Z"
        fill="#CFCCE0"
      />
      <path
        d="M76.7806 15.9782H33.7748V37.3002H76.7806V15.9782Z"
        fill="white"
      />
      <path
        d="M38.4707 17.2604C37.3659 16.5973 36.0337 16.6479 35.5272 16.6986C35.7201 17.1686 36.2988 18.3673 37.4036 19.0308C38.5112 19.6956 39.8416 19.6434 40.3473 19.5931C40.1545 19.1233 39.5758 17.9241 38.4707 17.2604Z"
        fill="#AFAFAE"
      />
      <path
        d="M43.6625 30.4194H36.757C36.5528 30.4192 36.3506 30.4591 36.1619 30.537C35.9732 30.6148 35.8017 30.729 35.6572 30.873C35.5128 31.017 35.3982 31.1881 35.32 31.3763C35.2418 31.5646 35.2015 31.7664 35.2015 31.9701C35.2015 32.1739 35.2418 32.3757 35.32 32.564C35.3982 32.7522 35.5128 32.9233 35.6572 33.0673C35.8017 33.2113 35.9732 33.3255 36.1619 33.4033C36.3506 33.4812 36.5528 33.5211 36.757 33.5209H43.6625C43.8667 33.5211 44.0689 33.4812 44.2576 33.4033C44.4463 33.3255 44.6178 33.2113 44.7622 33.0673C44.9067 32.9233 45.0213 32.7522 45.0995 32.564C45.1777 32.3757 45.2179 32.1739 45.2179 31.9701C45.2179 31.7664 45.1777 31.5646 45.0995 31.3763C45.0213 31.1881 44.9067 31.017 44.7622 30.873C44.6178 30.729 44.4463 30.6148 44.2576 30.537C44.0689 30.4591 43.8667 30.4192 43.6625 30.4194Z"
        fill="#AFAFAE"
      />
      <path
        d="M74.9332 14.0504H36.2161C35.7658 14.0504 35.3198 14.139 34.9037 14.311C34.4876 14.483 34.1095 14.7352 33.791 15.053C33.4725 15.3709 33.2199 15.7482 33.0476 16.1635C32.8752 16.5788 32.7865 17.0239 32.7865 17.4735V36.3008C32.7865 37.2086 33.1478 38.0793 33.791 38.7212C34.4342 39.3631 35.3065 39.7238 36.2161 39.7238H74.9332C75.8428 39.7238 76.7151 39.3631 77.3583 38.7212C78.0015 38.0793 78.3628 37.2086 78.3628 36.3008V17.4735C78.3628 17.0239 78.2741 16.5788 78.1017 16.1635C77.9294 15.7482 77.6768 15.3709 77.3583 15.053C77.0398 14.7352 76.6617 14.483 76.2456 14.311C75.8295 14.139 75.3835 14.0504 74.9332 14.0504ZM35.2582 16.3855C35.3347 16.3717 37.1476 16.0637 38.6488 16.9653C40.15 17.8667 40.7267 19.6098 40.7505 19.6835L40.8112 19.8714L40.6164 19.9059C40.3385 19.9492 40.0575 19.9698 39.7762 19.9675C38.8839 19.9845 38.0033 19.763 37.2257 19.3259C35.7245 18.4247 35.1479 16.6816 35.1241 16.6079L35.0634 16.42L35.2582 16.3855ZM43.6627 33.8654H36.7572C36.2535 33.8654 35.7705 33.6657 35.4144 33.3103C35.0582 32.9549 34.8581 32.4728 34.8581 31.9701C34.8581 31.4674 35.0582 30.9853 35.4144 30.6299C35.7705 30.2744 36.2535 30.0747 36.7572 30.0747H43.6627C44.1663 30.0747 44.6493 30.2744 45.0055 30.6299C45.3616 30.9853 45.5617 31.4674 45.5617 31.9701C45.5617 32.4728 45.3616 32.9549 45.0055 33.3103C44.6493 33.6657 44.1663 33.8654 43.6627 33.8654ZM75.0827 33.5208C75.0811 34.0687 74.8623 34.5937 74.4741 34.9811C74.086 35.3685 73.56 35.5869 73.011 35.5885H67.8319C67.283 35.5869 66.757 35.3685 66.3688 34.9811C65.9806 34.5937 65.7619 34.0687 65.7603 33.5208V30.4194C65.7619 29.8715 65.9806 29.3465 66.3688 28.9591C66.757 28.5717 67.283 28.3533 67.8319 28.3517H73.011C73.56 28.3533 74.086 28.5717 74.4741 28.9591C74.8623 29.3465 75.0811 29.8715 75.0827 30.4194V33.5208Z"
        fill="#BFC1CC"
      />
      <path
        d="M69.4718 32.9178H67.2275C67.0673 32.9178 66.9136 32.9813 66.8003 33.0944C66.687 33.2075 66.6233 33.3609 66.6233 33.5208C66.6233 33.6808 66.687 33.8342 66.8003 33.9473C66.9136 34.0604 67.0673 34.1239 67.2275 34.1239H69.4718C69.6321 34.1239 69.7858 34.0604 69.8991 33.9473C70.0124 33.8342 70.076 33.6808 70.076 33.5208C70.076 33.3609 70.0124 33.2075 69.8991 33.0944C69.7858 32.9813 69.6321 32.9178 69.4718 32.9178ZM69.4718 33.7793H67.2275C67.1935 33.7794 67.1597 33.7728 67.1282 33.7598C67.0967 33.7469 67.0681 33.7279 67.044 33.7039C67.0198 33.6798 67.0007 33.6513 66.9877 33.6199C66.9746 33.5885 66.9679 33.5548 66.9679 33.5208C66.9679 33.4868 66.9746 33.4532 66.9877 33.4218C67.0007 33.3904 67.0198 33.3619 67.044 33.3379C67.0681 33.3138 67.0967 33.2948 67.1282 33.2819C67.1597 33.2689 67.1935 33.2623 67.2275 33.2624H69.4718C69.5404 33.2626 69.6061 33.2899 69.6545 33.3383C69.7029 33.3868 69.7301 33.4524 69.7301 33.5208C69.7301 33.5893 69.7029 33.6549 69.6545 33.7034C69.6061 33.7518 69.5404 33.7791 69.4718 33.7793Z"
        fill="#AFAFAE"
      />
      <path
        d="M73.6153 32.9178H71.371C71.2107 32.9178 71.057 32.9813 70.9437 33.0944C70.8304 33.2075 70.7667 33.3609 70.7667 33.5208C70.7667 33.6808 70.8304 33.8342 70.9437 33.9473C71.057 34.0604 71.2107 34.1239 71.371 34.1239H73.6153C73.7755 34.1239 73.9292 34.0604 74.0425 33.9473C74.1558 33.8342 74.2195 33.6808 74.2195 33.5208C74.2195 33.3609 74.1558 33.2075 74.0425 33.0944C73.9292 32.9813 73.7755 32.9178 73.6153 32.9178ZM73.6153 33.7793H71.371C71.3369 33.7794 71.3031 33.7728 71.2716 33.7598C71.2401 33.7469 71.2115 33.7279 71.1874 33.7039C71.1633 33.6798 71.1441 33.6513 71.1311 33.6199C71.118 33.5885 71.1113 33.5548 71.1113 33.5208C71.1113 33.4868 71.118 33.4532 71.1311 33.4218C71.1441 33.3904 71.1633 33.3619 71.1874 33.3379C71.2115 33.3138 71.2401 33.2948 71.2716 33.2819C71.3031 33.2689 71.3369 33.2623 71.371 33.2624H73.6153C73.6838 33.2626 73.7495 33.2899 73.7979 33.3383C73.8463 33.3868 73.8735 33.4524 73.8735 33.5208C73.8735 33.5893 73.8463 33.6549 73.7979 33.7034C73.7495 33.7518 73.6838 33.7791 73.6153 33.7793Z"
        fill="#AFAFAE"
      />
      <path
        d="M69.4718 31.3671H67.2275C67.0673 31.3671 66.9136 31.4306 66.8003 31.5437C66.687 31.6568 66.6233 31.8102 66.6233 31.9701C66.6233 32.1301 66.687 32.2835 66.8003 32.3966C66.9136 32.5097 67.0673 32.5732 67.2275 32.5732H69.4718C69.6321 32.5732 69.7858 32.5097 69.8991 32.3966C70.0124 32.2835 70.076 32.1301 70.076 31.9701C70.076 31.8102 70.0124 31.6568 69.8991 31.5437C69.7858 31.4306 69.6321 31.3671 69.4718 31.3671ZM69.4718 32.2286H67.2275C67.1935 32.2286 67.1598 32.2219 67.1284 32.2089C67.097 32.1959 67.0685 32.1769 67.0444 32.1529C67.0204 32.1289 67.0013 32.1004 66.9883 32.069C66.9753 32.0377 66.9686 32.0041 66.9686 31.9701C66.9686 31.9362 66.9753 31.9026 66.9883 31.8712C67.0013 31.8399 67.0204 31.8114 67.0444 31.7874C67.0685 31.7634 67.097 31.7443 67.1284 31.7313C67.1598 31.7184 67.1935 31.7117 67.2275 31.7117H69.4718C69.5058 31.7117 69.5395 31.7184 69.5709 31.7313C69.6023 31.7443 69.6309 31.7634 69.6549 31.7874C69.679 31.8114 69.698 31.8399 69.7111 31.8712C69.7241 31.9026 69.7308 31.9362 69.7308 31.9701C69.7308 32.0041 69.7241 32.0377 69.7111 32.069C69.698 32.1004 69.679 32.1289 69.6549 32.1529C69.6309 32.1769 69.6023 32.1959 69.5709 32.2089C69.5395 32.2219 69.5058 32.2286 69.4718 32.2286Z"
        fill="#AFAFAE"
      />
      <path
        d="M73.6153 31.3671H71.371C71.2107 31.3671 71.057 31.4306 70.9437 31.5437C70.8304 31.6568 70.7667 31.8102 70.7667 31.9701C70.7667 32.1301 70.8304 32.2835 70.9437 32.3966C71.057 32.5097 71.2107 32.5732 71.371 32.5732H73.6153C73.7755 32.5732 73.9292 32.5097 74.0425 32.3966C74.1558 32.2835 74.2195 32.1301 74.2195 31.9701C74.2195 31.8102 74.1558 31.6568 74.0425 31.5437C73.9292 31.4306 73.7755 31.3671 73.6153 31.3671ZM73.6153 32.2286H71.371C71.3369 32.2286 71.3033 32.2219 71.2719 32.2089C71.2404 32.1959 71.2119 32.1769 71.1878 32.1529C71.1638 32.1289 71.1447 32.1004 71.1317 32.069C71.1187 32.0377 71.112 32.0041 71.112 31.9701C71.112 31.9362 71.1187 31.9026 71.1317 31.8712C71.1447 31.8399 71.1638 31.8114 71.1878 31.7874C71.2119 31.7634 71.2404 31.7443 71.2719 31.7313C71.3033 31.7184 71.3369 31.7117 71.371 31.7117H73.6153C73.6493 31.7117 73.6829 31.7184 73.7143 31.7313C73.7458 31.7443 73.7743 31.7634 73.7984 31.7874C73.8224 31.8114 73.8415 31.8399 73.8545 31.8712C73.8675 31.9026 73.8742 31.9362 73.8742 31.9701C73.8742 32.0041 73.8675 32.0377 73.8545 32.069C73.8415 32.1004 73.8224 32.1289 73.7984 32.1529C73.7743 32.1769 73.7458 32.1959 73.7143 32.2089C73.6829 32.2219 73.6493 32.2286 73.6153 32.2286Z"
        fill="#AFAFAE"
      />
      <path
        d="M69.4718 29.8164H67.2275C67.0673 29.8164 66.9136 29.8799 66.8003 29.993C66.687 30.1061 66.6233 30.2595 66.6233 30.4194C66.6233 30.5794 66.687 30.7328 66.8003 30.8459C66.9136 30.959 67.0673 31.0225 67.2275 31.0225H69.4718C69.6321 31.0225 69.7858 30.959 69.8991 30.8459C70.0124 30.7328 70.076 30.5794 70.076 30.4194C70.076 30.2595 70.0124 30.1061 69.8991 29.993C69.7858 29.8799 69.6321 29.8164 69.4718 29.8164ZM69.4718 30.6779H67.2275C67.1935 30.678 67.1597 30.6714 67.1282 30.6584C67.0967 30.6455 67.0681 30.6264 67.044 30.6024C67.0198 30.5784 67.0007 30.5499 66.9877 30.5185C66.9746 30.4871 66.9679 30.4534 66.9679 30.4194C66.9679 30.3854 66.9746 30.3518 66.9877 30.3204C67.0007 30.289 67.0198 30.2604 67.044 30.2364C67.0681 30.2124 67.0967 30.1934 67.1282 30.1804C67.1597 30.1675 67.1935 30.1609 67.2275 30.161H69.4718C69.5404 30.1612 69.6061 30.1885 69.6545 30.2369C69.7029 30.2854 69.7301 30.351 69.7301 30.4194C69.7301 30.4879 69.7029 30.5535 69.6545 30.6019C69.6061 30.6504 69.5404 30.6777 69.4718 30.6779Z"
        fill="#AFAFAE"
      />
      <path
        d="M73.6153 29.8164H71.371C71.2107 29.8164 71.057 29.8799 70.9437 29.993C70.8304 30.1061 70.7667 30.2595 70.7667 30.4194C70.7667 30.5794 70.8304 30.7328 70.9437 30.8459C71.057 30.959 71.2107 31.0225 71.371 31.0225H73.6153C73.7755 31.0225 73.9292 30.959 74.0425 30.8459C74.1558 30.7328 74.2195 30.5794 74.2195 30.4194C74.2195 30.2595 74.1558 30.1061 74.0425 29.993C73.9292 29.8799 73.7755 29.8164 73.6153 29.8164ZM73.6153 30.6779H71.371C71.3369 30.678 71.3031 30.6714 71.2716 30.6584C71.2401 30.6455 71.2115 30.6264 71.1874 30.6024C71.1633 30.5784 71.1441 30.5499 71.1311 30.5185C71.118 30.4871 71.1113 30.4534 71.1113 30.4194C71.1113 30.3854 71.118 30.3518 71.1311 30.3204C71.1441 30.289 71.1633 30.2604 71.1874 30.2364C71.2115 30.2124 71.2401 30.1934 71.2716 30.1804C71.3031 30.1675 71.3369 30.1609 71.371 30.161H73.6153C73.6838 30.1612 73.7495 30.1885 73.7979 30.2369C73.8463 30.2854 73.8735 30.351 73.8735 30.4194C73.8735 30.4879 73.8463 30.5535 73.7979 30.6019C73.7495 30.6504 73.6838 30.6777 73.6153 30.6779Z"
        fill="#AFAFAE"
      />
      <path
        d="M22.3159 26.426C22.3159 26.426 19.8632 36.7617 22.8609 36.7617C25.8586 36.7617 32.944 25.0661 32.944 25.0661C32.944 25.0661 40.302 21.2582 37.3043 19.8982C34.3066 18.5383 30.4914 22.0742 30.4914 22.0742L26.0891 28.1743L25.8586 25.0661L22.3159 26.426Z"
        fill="#A0616A"
      />
      <path
        d="M28.0388 17.7223L26.4037 16.0904C26.4037 16.0904 23.1335 17.9943 22.8609 19.8983C22.5884 21.8022 21.2258 27.786 21.2258 27.786L26.2674 28.194L27.9025 25.7461L28.0388 17.7223Z"
        fill="#E28637"
      />
      <path
        d="M90.4969 13.8532C90.2916 13.2482 87.717 11.1915 89.2946 10.8458L90.5884 12.5676L96.5363 6.63117L97.1346 7.22823L90.4969 13.8532Z"
        fill="#EE730A"
      />
      <path
        d="M90.4969 34.0394C90.2916 33.4344 87.717 31.3777 89.2946 31.032L90.5884 32.7538L96.5363 26.8174L97.1346 27.4144L90.4969 34.0394Z"
        fill="#EE730A"
      />
      <path
        d="M108.513 13.2304C109.527 13.2304 110.349 12.4103 110.349 11.3985C110.349 10.3868 109.527 9.56666 108.513 9.56666C107.499 9.56666 106.678 10.3868 106.678 11.3985C106.678 12.4103 107.499 13.2304 108.513 13.2304Z"
        fill="#3F3D56"
      />
      <path
        d="M144.946 11.2154H115.396V11.5818H144.946V11.2154Z"
        fill="#3F3D56"
      />
      <path
        d="M108.513 33.4166C109.527 33.4166 110.349 32.5965 110.349 31.5848C110.349 30.573 109.527 29.7529 108.513 29.7529C107.499 29.7529 106.678 30.573 106.678 31.5848C106.678 32.5965 107.499 33.4166 108.513 33.4166Z"
        fill="#3F3D56"
      />
      <path
        d="M144.946 31.4016H115.396V31.7679H144.946V31.4016Z"
        fill="#3F3D56"
      />
      <path
        d="M108.513 53.8912C109.527 53.8912 110.349 53.0711 110.349 52.0593C110.349 51.0476 109.527 50.2275 108.513 50.2275C107.499 50.2275 106.678 51.0476 106.678 52.0593C106.678 53.0711 107.499 53.8912 108.513 53.8912Z"
        fill="#3F3D56"
      />
      <path
        d="M144.946 51.8761H115.396V52.2425H144.946V51.8761Z"
        fill="#3F3D56"
      />
      <path
        d="M95.1326 57.2435H85.9554V47.9009H92.1959V48.2673H86.3225V56.8771H94.7655V52.6638H95.1326V57.2435Z"
        fill="#3F3D56"
      />
      <path
        d="M95.1326 36.7264H85.9554V27.3839H92.1959V27.7502H86.3225V36.3601H94.7655V32.1467H95.1326V36.7264Z"
        fill="#3F3D56"
      />
      <path
        d="M95.1326 15.843H85.9554V6.50043H92.1959V6.86681H86.3225V15.4766H94.7655V11.2633H95.1326V15.843Z"
        fill="#3F3D56"
      />
    </svg>
  )
}

export default Icon
