import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledDatePicker = styled.div<{
  top: number
  hasError?: boolean
}>`
  color: ${theme.colors.green.contrast};
  width: 100% !important;
  vertical-align: middle !important;

  & > div {
    width: 100% !important;
    & > div {
      /* .SingleDatePickerInput_ */
      & > div {
        display: flex !important;
        align-items: center !important;
        border-color: ${props =>
          props.hasError
            ? theme.colors.system.error
            : theme.colors.neutral.darkerGrey} !important;
        border-radius: 4px !important;

        /* SingleDatePickerInput_calendarIcon_ = Calendar Button */
        & button {
          margin: 0 !important;
          margin-left: 3.5rem !important;

          &:focus {
            outline-color: ${theme.colors.system.outline};
            outline-offset: 0px;
          }
        }

        & > div {
          /* .DateInput_ */
          &:nth-of-type(1) {
            width: 100% !important;
            margin-left: 2px !important;

            & > input {
              width: 85% !important;
              height: 100% !important;
              padding: 14px !important;
            }

            /* .DateInput_fang_ */
            & > svg {
              display: none !important;
            }
          }

          /* .SingleDatePicker_picker_ */
          &:nth-of-type(2) {
            top: ${props => props.top + 4}px !important;

            & > div > div > div {
              /* .DayPicker_weekHeaders_ */
              & > div {
                /* .DayPicker_weekHeader_ */
                &:nth-of-type(1) {
                  & > div > ul {
                    margin-top: -0.5rem !important;
                  }
                }

                /* .DayPicker_focusRegion_ */
                &:nth-of-type(2) {
                  /* .DayPicker_transitionContainer_ */
                  & > div:nth-of-type(2) > div {
                    /* .CalendarMonthGrid_month_ */
                    & > div:nth-of-type(2) {
                      /* CalendarMonth_table_ td */
                      & > div > table > tbody > tr > td {
                        vertical-align: middle !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      & > button {
        border-radius: 50%;
        height: 32px !important;
        width: 32px !important;
        padding: 5px !important;
        margin: 0 !important;
        margin-left: 2.5rem !important;

        &:hover {
          background: ${theme.colors.neutral.veryLightGrey} !important;
        }

        &:disabled {
          filter: grayscale(1);
          &:hover {
            background: none !important;
            cursor: default !important;
          }
        }
      }
    }
  }
`
export const StyledSubLabel = styled.span`
  display: block;
  font-size: ${theme.fontSizes.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
`

export const StyledError = styled.div<{ isVisible: boolean }>`
  margin: ${props => props.isVisible && "0.8rem"} 0;
`

const core = {
  white: theme.colors.neutral.white,
  gray: theme.colors.neutral.darkerGrey,
  grayLight: theme.colors.neutral.darkGrey,
  grayLighter: theme.colors.neutral.veryLightGrey,
  grayLightest: theme.colors.neutral.ash,

  borderMedium: theme.colors.neutral.lightGrey,
  border: theme.colors.neutral.midGrey,
  borderLight: theme.colors.neutral.veryLightGrey,
  borderLighter: theme.colors.neutral.veryLightGrey,
  borderBright: "#f4f5f5",

  primary: theme.colors.oceanGreen.prc100,
  primaryShade_1: theme.colors.oceanGreen.prc125,
  primaryShade_2: theme.colors.oceanGreen.prc75,
  primaryShade_3: theme.colors.oceanGreen.prc50,
  primaryShade_4: theme.colors.oceanGreen.prc25,
  primary_dark: theme.colors.oceanGreen.prc125,

  secondary: theme.colors.orange.prc100,

  highlight: theme.colors.orange.prc25,
  highlight_dark: theme.colors.orange.prc50
}

export const DatepickerTheme = {
  reactDates: {
    zIndex: 0,
    border: {
      input: {
        border: 0,
        borderTop: 0,
        borderRight: 0,
        borderBottom: 0,
        borderLeft: 0,
        outlineFocused: 0,
        borderFocused: 0,
        borderTopFocused: 0,
        borderLeftFocused: 0,
        borderBottomFocused: 0,
        borderRightFocused: 0,
        borderRadius: 0
      },
      pickerInput: {
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 2
      }
    },

    color: {
      core,

      disabled: core.grayLightest,

      background: core.white,
      backgroundDark: "#f2f2f2",
      backgroundFocused: core.white,
      border: theme.colors.neutral.darkGrey,
      text: core.gray,
      textDisabled: core.border,
      textFocused: theme.colors.neutral.darkerGrey,
      placeholderText: theme.colors.neutral.darkGrey,

      outside: {
        backgroundColor: core.white,
        backgroundColor_active: core.white,
        backgroundColor_hover: core.white,
        color: core.gray,
        color_active: core.gray,
        color_hover: core.gray
      },

      highlighted: {
        backgroundColor: core.highlight,
        backgroundColor_active: core.highlight_dark,
        backgroundColor_hover: core.highlight_dark,
        color: core.gray,
        color_active: core.gray,
        color_hover: core.gray
      },

      minimumNights: {
        backgroundColor: core.white,
        backgroundColor_active: core.white,
        backgroundColor_hover: core.white,
        borderColor: core.borderLighter,
        color: core.grayLighter,
        color_active: core.grayLighter,
        color_hover: core.grayLighter
      },

      hoveredSpan: {
        backgroundColor: core.primaryShade_4,
        backgroundColor_active: core.primaryShade_3,
        backgroundColor_hover: core.primaryShade_4,
        borderColor: core.primaryShade_3,
        borderColor_active: core.primaryShade_3,
        borderColor_hover: core.primaryShade_3,
        color: core.secondary,
        color_active: core.secondary,
        color_hover: core.secondary
      },

      selectedSpan: {
        backgroundColor: core.primaryShade_2,
        backgroundColor_active: core.primaryShade_1,
        backgroundColor_hover: core.primaryShade_1,
        borderColor: core.primaryShade_1,
        borderColor_active: core.primary,
        borderColor_hover: core.primary,
        color: core.white,
        color_active: core.white,
        color_hover: core.white
      },

      selected: {
        backgroundColor: core.primary,
        backgroundColor_active: core.primary,
        backgroundColor_hover: core.primaryShade_1,
        borderColor: core.primary,
        borderColor_active: core.primary,
        borderColor_hover: core.primary,
        color: core.white,
        color_active: core.white,
        color_hover: core.white
      },

      blocked_calendar: {
        backgroundColor: core.grayLightest,
        backgroundColor_active: core.grayLightest,
        backgroundColor_hover: core.grayLightest,
        borderColor: core.grayLighter,
        borderColor_active: core.grayLighter,
        borderColor_hover: core.grayLighter,
        color: core.grayLight,
        color_active: core.grayLight,
        color_hover: core.grayLight
      },

      blocked_out_of_range: {
        backgroundColor: core.white,
        backgroundColor_active: core.white,
        backgroundColor_hover: core.white,
        borderColor: core.borderLighter,
        borderColor_active: core.borderLighter,
        borderColor_hover: core.borderLighter,
        color: core.grayLighter,
        color_active: core.grayLighter,
        color_hover: core.grayLighter
      }
    },

    spacing: {
      dayPickerHorizontalPadding: 9,
      captionPaddingTop: 22,
      captionPaddingBottom: 37,
      inputPadding: 0,
      displayTextPaddingVertical: undefined,
      displayTextPaddingTop: 11,
      displayTextPaddingBottom: 9,
      displayTextPaddingHorizontal: undefined,
      displayTextPaddingLeft: 11,
      displayTextPaddingRight: 11,
      displayTextPaddingVertical_small: undefined,
      displayTextPaddingTop_small: 7,
      displayTextPaddingBottom_small: 5,
      displayTextPaddingHorizontal_small: undefined,
      displayTextPaddingLeft_small: 7,
      displayTextPaddingRight_small: 7
    },

    sizing: {
      inputWidth: 130,
      inputWidth_small: 97,
      arrowWidth: 24
    },

    noScrollBarOnVerticalScrollable: false,

    font: {
      size: 14,
      captionSize: 18,
      input: {
        size: 14,
        weight: 200,
        lineHeight: "24px",
        size_small: 15,
        lineHeight_small: "18px",
        letterSpacing_small: "0.2px",
        styleDisabled: "italic"
      }
    }
  }
}
