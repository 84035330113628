import { theme } from "@paudigital/wdp-components"
import InvoiceCard from "app/components/InvoicesManagement/InvoiceCard"
import styled from "styled-components"

export const StyledNotification = styled.div`
  margin: 1.4rem 2.4rem 2.4rem;
  border: 2px solid ${theme.colors.neutral.midGrey};
  border-radius: 4px;
`

export const StyledInvoiceCard = styled(InvoiceCard)`
  margin: 0;
`
