import React from "react"
import {
  getNotificationsSettings,
  upsertNotifications
} from "app/redux/notificationSettings/notificationSettings.actions"
import {
  getUserId,
  getRentedBuildings,
  getPreferredBuildingId,
  getBuildingById,
  getPreferredLanguage
} from "app/redux/me/me.selectors"
import { Separator, InputLabel } from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import { useTranslate } from "hooks/translate"
import * as notificationSettingsSelectors from "app/redux/notificationSettings/notificationSettings.selectors"
import NotificationToggles from "app/components/NotificationToggles"
import Section from "app/components/Section"
import { PreferencesWrapper, PreferredBuildingSwitcherWrapper } from "./style"
import { useApi } from "hooks/api"
import { NotificationSettings } from "app/types/notificationSettings"
import PreferredbuildingDropDown from "app/components/PreferredbuildingDropDown"
import LanguageSelect from "app/components/LanguageSelect"
import MonthlyNewsLetterToggler from "./MonthlyNewsLetterToggler"

const Preferences = () => {
  const { translate } = useTranslate()
  const { call } = useApi()
  const contactPersonId = useSelector(getUserId)
  const notificationsSettings = useSelector(
    notificationSettingsSelectors.getNotificationsSettings
  )
  const rentedBuildings = useSelector(getRentedBuildings)
  const preferredBuildingId = useSelector(getPreferredBuildingId)
  const preferredBuilding = useSelector(getBuildingById(preferredBuildingId))
  const preferredLanguage = useSelector(getPreferredLanguage)

  React.useEffect(() => {
    if (contactPersonId) call(getNotificationsSettings(contactPersonId))
  }, [call, contactPersonId])

  const handleNotificationSettingChange = (settings: NotificationSettings) => {
    call(upsertNotifications([settings]))
  }

  return (
    <PreferencesWrapper>
      <Section title={translate("portal.account.preferences.title")}>
        <>
          <MonthlyNewsLetterToggler />
          <InputLabel
            guidanceText={translate(
              "portal.account.preferences.notifications.notifications.guidanceText"
            )}
            label={translate(
              "portal.account.preferences.notifications.notifications.subtitle"
            )}
          />
          {notificationsSettings?.map((n, i) => (
            <React.Fragment key={n.id}>
              <NotificationToggles
                key={n.id}
                notification={n}
                onNotificationSettingChange={handleNotificationSettingChange}
              />
              {i !== notificationsSettings.length - 1 && <Separator />}
            </React.Fragment>
          ))}
          <LanguageSelect
            defaultLanguage={preferredLanguage}
            label={translate("portal.preferred.language.select.label")}
          />
          {rentedBuildings && rentedBuildings.length > 1 && preferredBuilding && (
            <PreferredBuildingSwitcherWrapper data-testid="buildinSwitherAccount">
              <PreferredbuildingDropDown showSuccessMessage />
            </PreferredBuildingSwitcherWrapper>
          )}
        </>
      </Section>
    </PreferencesWrapper>
  )
}

export default Preferences
