import { useDispatch, useSelector } from "react-redux"
import { getRentedBuildings } from "app/redux/me/me.selectors"
import { BuildingSwitcherContainer, ButtonWrapper } from "./style"
import BuildingWidget from "../BuildingWidget"
import { Button, SlideIn, theme } from "@paudigital/wdp-components"
import { useViewport } from "hooks/viewport"
import { useNavigate } from "react-router-dom"
import { useTranslate } from "hooks/translate"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { getNotificationsCount } from "app/redux/notifications/notifications.actions"
import * as selectors from "app/redux/notifications/notifications.selectors"
import { useEffect } from "react"
import { useApi } from "hooks/api"

const BuildingSwitcher = () => {
  const dispatch = useDispatch()
  const { call } = useApi()
  const { translate } = useTranslate()
  const rentedBuildings = useSelector(getRentedBuildings)
  const notificationsCount = useSelector(selectors.getNotificationsCount)
  const { width } = useViewport()
  const navigate = useNavigate()

  useEffect(() => {
    call(getNotificationsCount())
  }, [call ])

  const navigateToAccount = () => {
    navigate("/account")
    handleClose()
  }

  const handleClose = () => {
    dispatch(remove("building-switcher"))
  }

  return (
    <SlideIn
      showCloseIcon
      show
      onClose={handleClose}
      title={translate("portal.building.switcher.title")}
      isWide
    >
      <BuildingSwitcherContainer>
        {rentedBuildings?.map(r => (
          <BuildingWidget
            key={r.id}
            rentedBuilding={r}
            notificationsCount={notificationsCount[r.id]}
          />
        ))}
        {width < theme.breakpointsValues.tablet && (
          <ButtonWrapper>
            <Button
              onClick={navigateToAccount}
              label={translate(
                "portal.building.switcher.link.preferred.building.changebuilding"
              )}
              type="secondary"
              fullWidth
            />
          </ButtonWrapper>
        )}
      </BuildingSwitcherContainer>
    </SlideIn>
  )
}

export default BuildingSwitcher
