import { CustomerNameTag, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const BuildingWrapper = styled.div`
  margin-bottom: 3.2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 32.5rem;
    padding: initial;
  }
`

export const StyledCustomerNameTag = styled(CustomerNameTag)`
  position: absolute;
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  margin: 0.6rem;
`

export const BuildingImage = styled.img`
  width: 100%;
  height: 11.5rem;
  border-radius: 12px;
  cursor: pointer;
  object-fit: cover;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-bottom: 2.4rem;
  }
`

export const BuildingDetailCard = styled.div`
  display: none;

  @media only screen and (${theme.breakpoints.tablet}) {
    background: ${theme.colors.neutral.lightGrey};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    flex: 1;
  }
`

export const CardContainer = styled.div`
  margin: 1.4rem 2.4rem 2.4rem;
`

export const BuildingCardCtas = styled.div`
  flex: 1;
  margin-bottom: 3rem;
`

export const PreferedBuilding = styled.div`
  display: none;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 100%;
    display: flex;
    margin-top: 1.6rem;
    justify-content: center;
    align-items: center;
  }
`

export const PreferedBuildingLabel = styled.span`
  color: ${theme.colors.system.success};
  font-size: ${theme.fontSizes.caption};
  margin-left: 1.2rem;
`
