import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  background: ${theme.colors.neutral.white};
  border-radius: 4px;
  padding: 8rem 1.6rem 1.6rem 1.6rem;
  position: relative;
  grid-area: contact;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const StyledImage = styled.img`
  border-radius: 50%;
  height: 8.9rem;
  width: 8.9rem;
  border: 4px solid ${theme.colors.neutral.white};
  position: absolute;
  top: -3.7rem;
`

export const StyledDescription = styled.span`
  margin: 1.2rem 0 3.2rem 0;
  color: ${theme.colors.neutral.darkGrey};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
`
