import {
  Container,
  StyledImage,
  StyledDescription,
  StyledLink,
  StyledEmailLink,
  StyledContactWrapper,
  DescriptionWrapper
} from "./style"
import Placeholder from "./placeholder.jpeg"
import { mappings, useContactPerson } from "hooks/contactPerson"
import { Modules } from "app/enums/modules"
import { useTranslate } from "hooks/translate"
import buildSubCategoryTranslation from "app/utils/buildSubCategoryTranslation.util"
import { AnswerCode } from "app/types/ticket"

type Props = {
  ticketId?: string
  type: string
  ticketTitle: string
  answerCodes: AnswerCode[]
}

const ReportDetailContactFooter = ({
  ticketId,
  type,
  ticketTitle,
  answerCodes
}: Props) => {
  const { translate } = useTranslate()
  const module = mappings[Modules.FACILITY_MANAGEMENT]
  const { contactMapping } = useContactPerson()
  const contactPerson = contactMapping[module]

  return (
    <Container>
      <StyledContactWrapper>
        <StyledImage src={contactPerson?.profilePicture ?? Placeholder} />

        <DescriptionWrapper>
          <StyledDescription>
            <span>
              <strong>
                {translate(
                  "portal.facilitymanagement.reportdetail.contactfooter.question"
                )}
              </strong>
            </span>
            <span>
              {translate(
                "portal.facilitymanagement.reportdetail.contactfooter.contactperson",
                { name: contactPerson?.person?.firstName }
              )}
            </span>
          </StyledDescription>
          <StyledDescription>
            <span>
              {translate(
                "portal.facilitymanagement.reportdetail.contactfooter.option.call"
              )}
            </span>
            <StyledLink href={`tel:${contactPerson?.person?.mobilephone}`}>
              {contactPerson?.person?.mobilephone}
            </StyledLink>
          </StyledDescription>
          <StyledDescription>
            <span>
              {translate(
                "portal.facilitymanagement.reportdetail.contactfooter.option.mail"
              )}
            </span>
            <StyledEmailLink
              href={`mailto:${contactPerson?.person?.email}?subject=${translate(
                "portal.facilitymanagement.ticket.question"
              )} ${ticketId ? ticketId : ""} ${translate(
                `portal.facilitymanagement.${type}` +
                  buildSubCategoryTranslation({ answerCodes, type })
              )}: ${ticketTitle}`}
            >
              {contactPerson?.person?.email}
            </StyledEmailLink>
          </StyledDescription>
        </DescriptionWrapper>
      </StyledContactWrapper>
    </Container>
  )
}

export default ReportDetailContactFooter
