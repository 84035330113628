import { Checkbox } from "@paudigital/wdp-components"
import { StyledContract, ContractTitle } from "./style"

export type TypeProps = {
  contract: string
  checked: boolean
  onClick: (contract: string) => void
}

type Props = TypeProps

const Contract = ({ contract, checked, onClick }: Props) => {
  const handleClick = () => {
    onClick(contract)
  }

  return (
    <StyledContract onClick={handleClick}>
      <Checkbox checked={checked} onClick={handleClick} />
      <ContractTitle>{contract}</ContractTitle>
    </StyledContract>
  )
}

export default Contract
