import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const FilterButton = styled.label`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  height: 2rem;

  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.light};
  color: ${theme.colors.green.contrast};

  &:nth-child(1) {
    margin-right: 1.4rem;
  }

  &:hover {
    color: ${theme.colors.green.dark};
  }
`
