import { Action, Types } from "./rentedBuildingContacts.actions"
import { RentedBuildingContact } from "app/types/rentedBuildingContact"

export type RentedBuildingContactState = {
  errors: object
  loading: boolean
  data: RentedBuildingContact[] | undefined
}

const initialState: RentedBuildingContactState = {
  errors: {},
  loading: false,
  data: undefined
}

export default (
  state = initialState,
  action: Action
): RentedBuildingContactState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
