import Hero from "./Hero.jpeg"
import { ModulesEnum } from "app/types/banner"
import ModuleHeader from "../ModuleHeader"

const NotificationsPageHeader = () => {
  return (
    <ModuleHeader
      page={"notifications"}
      module={ModulesEnum.NOTIFICATION}
      Hero={Hero}
    />
  )
}

export default NotificationsPageHeader
