import { Typography } from "@paudigital/wdp-components"
import { Container, ChildrenContainer } from "./style"

type SectionProps = {
  children: JSX.Element | JSX.Element[]
  title?: string
  subTitle?: JSX.Element
}

type Props = SectionProps

const Section = ({
  children,
  title,
  subTitle
}: Props) => {
  return (
    <Container>
      {title && (
        <Typography variant="h6" fontStyle="bold" as="h2">
          {title}
        </Typography>
      )}
      {subTitle}
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  )
}

export default Section
