import { SagaIterator } from "redux-saga"
import {
  takeLatest,
  take,
  race,
  call,
  delay,
  put,
  select,
  fork
} from "redux-saga/effects"
import * as actions from "./notifications.actions"
import * as api from "app/api/notifications"
import { isRunning } from "./notifications.selectors"
import { getActiveBuilding } from "../buildingSwitcher/buildingSwitcher.selectors"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getNotificationsFlow(
  action: actions.GetNotificationsAction
): SagaIterator {
  try {
    const { page } = action?.payload
    const rentedBuildingId = yield select(getActiveBuilding)
    const { data } = yield call(api.getNotifications, rentedBuildingId, page)
    yield put(actions.getNotificationsSuccess(data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getNotificationsFail(e))
  }
}

export function* getNotificationsCountFlow(): SagaIterator {
  try {
    const { data } = yield call(api.getNotificationCount)
    yield put(actions.getNotificationsCountSuccess(data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getNotificationsFail(e))
  }
}

export function* getMoreNotificationsFlow(
  action?: actions.GetMoreNotificationsAction
): SagaIterator {
  try {
    const page = action?.payload.page
    const rentedBuildingId = yield select(getActiveBuilding)
    const { data } = yield call(api.getNotifications, rentedBuildingId, page)
    yield put(actions.getMoreNotificationsSuccess(data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getNotificationsFail(e))
  }
}

function* pollTask(): SagaIterator {
  while (true) {
    try {
      const rentedBuildingId = yield select(getActiveBuilding)
      if (rentedBuildingId) yield call(getNotificationsCountFlow)

      yield put(actions.updateTimer())
      yield delay(60000)
    } catch (err: any) {
      yield put(actions.getNotificationsFail(err))
      yield put(actions.resetTimer())
    }
  }
}

export function* NotificationsTimerSaga(): SagaIterator {
  while (true) {
    yield take(actions.Types.START_TIMER)
    yield race([call(pollTask), take(actions.Types.RESET_TIMER)])
  }
}

export function* startTimerFlow(): SagaIterator {
  try {
    yield fork(NotificationsTimerSaga)
    yield put(actions.startTimer())
  } catch (e: any) {
    yield put(actions.resetTimer())
    yield put(actions.getNotificationsFail(e))
  }
}

export function* resetTimerFlow(): SagaIterator {
  const running = yield select(isRunning)

  if (running) yield put(actions.resetTimer())
  yield put(actions.getNotifications())
  yield take([actions.Types.GET_FAIL, actions.Types.GET_SUCCESS])
  yield put(actions.getNotificationsCount())
}

export default function* NotificationsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET_NOTIFICATION_COUNT, startTimerFlow)
  yield takeLatest(actions.Types.GET, getNotificationsCountFlow)
  yield takeLatest(actions.Types.GET, getNotificationsFlow)
  yield takeLatest(actions.Types.GET_MORE, getMoreNotificationsFlow)
  yield takeLatest(actions.Types.RESET_TIMER, resetTimerFlow)
  yield takeLatest(
    actions.Types.GET_NOTIFICATION_COUNT,
    getNotificationsCountFlow
  )
}
