import { Notification } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { NotificationContainer } from "./style"
import differenceInDays from "date-fns/differenceInDays"
import { format, add, sub, startOfDay } from "date-fns"
import { useRentedBuilding } from "hooks/rentedBuilding"

const NotificationBar = () => {
  const { rentedBuilding } = useRentedBuilding()
  const { translate } = useTranslate()
  if (!rentedBuilding?.endDate) return <></>
  const endDate = add(new Date(rentedBuilding?.endDate), { days: 91 })
  const daysLeft = differenceInDays(endDate, startOfDay(new Date()))
  const formattedDate = format(
    sub(new Date(endDate), { days: 1 }),
    "dd-MM-yyyy"
  )

  return (
    <>
      {daysLeft <= 91 && (
        <NotificationContainer>
          <Notification
            description={translate(
              "portal.dashboard.notification.account.deleted",
              {
                amount: daysLeft,
                endDate: formattedDate
              }
            )}
            show
            variant="warning"
          />
        </NotificationContainer>
      )}
    </>
  )
}
export default NotificationBar
