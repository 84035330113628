import { RentedBuildingContract } from "app/types/rentedBuildingContract"
import State from "../state"

export const isLoadingRentedBuildingContracts = (state: State): boolean =>
  state.rentedBuildingContracts.loading

export const getRentedBuildingContracts = (
  state: State
): RentedBuildingContract[] => state.rentedBuildingContracts.data

export const getRentedBuildingContractNumbers = (state: State): string[] => {
  return state.rentedBuildingContracts.contractNumbers.map(
    c => c.split("/")[1] ?? c
  )
}

export const getRentedBuildingSanitizedContractIds = (
  state: State
): string[] => {
  const contracts = getRentedBuildingContracts(state)
  return contracts.map(c => {
    const contractSourceId = c.sourceId.split("/")[1]
    if (contractSourceId) {
      return contractSourceId
    }
    return c.sourceId
  })
}
