import { theme } from "@paudigital/wdp-components"
import { intl, Locales } from "app/i18n/config"
import { parse } from "date-fns"
import { format } from "date-fns-tz"
import { getLocaleNumberFormatter } from "../localeNumberFormatter.utils"
import {
  baseAreaSplineSerieOptions,
  baseColumnSerieOptions,
  generateTooltipLabel
} from "./baseGraphOptions"

export const getGasConsumptionChartOptions = (language: Locales) => {
  return {
    yAxis: {
      title: {
        text: intl[language].formatMessage({
          id: "portal.energy.graphs.gas.title"
        })
      },
      labels: {
        formatter: function () {
          const self: any = this
          const { decimalFormatter } = getLocaleNumberFormatter()
          return `<span style="display: block; text-align: center; color: ${
            theme.colors.neutral.darkGrey
          }; line-height: 14px;">${decimalFormatter(self.value, 0)} m³</span>`
        },
        useHTML: true
      },
      plotLines: []
    },
    xAxis: {
      startOnTick: true,
      endOnTick: true,
      width: "100%",
      maxPadding: 0,
      minPadding: 0,
      tickInterval: 3600,
      labels: {
        formatter: function () {
          const self: any = this
          const formattedDate = format(
            parse(self.value, "t", new Date()),
            "hh:mm yyyy-MM-dd"
          )
          const [time, date] = formattedDate.split(" ")
          return `<span style="display: block; text-align: center; color: ${theme.colors.neutral.darkGrey}; line-height: 14px;">${time}<br/>${date}</span>`
        },
        useHTML: true
      }
    },
    tooltip: {
      backgroundColor: theme.colors.neutral.white,
      borderColor: theme.colors.neutral.darkerGrey,
      style: {
        fontSize: "12px"
      },
      formatter: function () {
        const self: any = this
        return generateTooltipLabel(self, "m³", language)
      },
      useHTML: true
    },
    series: []
  }
}

export const gasConsumptionAreaSplineSerieOptions = [
  {
    ...baseAreaSplineSerieOptions,
    name: "gasConsumption"
  }
]

export const gasConsumptionStackedBarSerieOptions = [
  {
    ...baseColumnSerieOptions,
    name: "gasConsumption"
  }
]
