import * as api from "app/api/faq"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./faq.actions"

export function* getFaqFlow(action: actions.GetFaqAction): SagaIterator {
  try {
    const { language } = action.payload
    const response = yield call(api.getFaq, language)
    yield put(actions.getFaqSuccess(response))
  } catch (e: any) {
    yield put(actions.getFaqFail(e))
  }
}

export default function* meSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getFaqFlow)
}
