import { Button, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  margin: 3.2rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    min-width: 50rem;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StyledButton = styled(Button)`
  margin-right: 0.8rem;
  min-width: 22rem;
  width: auto;

  button {
    margin-bottom: 1.6rem;
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    button {
      margin-bottom: 0;
    }
  }
`

const BaseSpan = styled.span`
  display: block;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.light};
  line-height: ${theme.lineHeights.captionSmall};
  margin-bottom: 1.2rem;

  strong {
    font-weight: ${theme.fontWeights.bold};
  }
`

export const Detail = styled(BaseSpan)`
  margin-right: 1.6rem;
`

export const InvoiceDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0;
  }
`
