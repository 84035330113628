import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.8rem 0;

  & > div:nth-of-type(1) {
    width: auto;
    margin-right: 1.6rem;
  }
`

export const StatusText = styled.span`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.fontSizes.paragraph};
  cursor: default;
`
