import { getEnvVariable } from "../utils/getEnvVariable"

export const AUTH0_DOMAIN = getEnvVariable("REACT_APP_AUTH0_DOMAIN")
export const CLIENT_ID: string = getEnvVariable("REACT_APP_CLIENT_ID")
export const BASE_URL: string = getEnvVariable("REACT_APP_BASE_URL")
export const REDIRECT_URI: string = getEnvVariable("REACT_APP_REDIRECT_URI")
export const AUDIENCE: string = getEnvVariable("REACT_APP_AUTH0_AUDIENCE")
export const MFA_AUDIENCE: string = getEnvVariable("REACT_APP_AUTH0_MFA_AUDIENCE")
export const RENTAL_AUDIENCE: string = getEnvVariable("REACT_APP_AUTH0_RENTAL_AUDIENCE")
export const RENTAL_API_URL: string = getEnvVariable("REACT_APP_RENTAL_API_URL")
export const REACT_APP_NOTIFICATION_API_URL: string = getEnvVariable(
  "REACT_APP_NOTIFICATION_API_URL"
)

export const API_URLS = {
  rentalApi: RENTAL_API_URL,
  notificationApi: REACT_APP_NOTIFICATION_API_URL,
  auth0Api: `https://${AUTH0_DOMAIN}`
}
