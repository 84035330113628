import { Periods } from "app/redux/energyPeriod/energyPeriod.reducer"
import { EnergyUsageComparisonData, EnergyUsageTypes } from "app/types/energy"
import { differenceInDays } from "date-fns"

export type Period = "day" | "week" | "month" | "year" | "custom"

export const getUsageComparisonData = (
  energyUsageComparisonData: EnergyUsageComparisonData[] | undefined,
  energyUsageType: EnergyUsageTypes
): EnergyUsageComparisonData | undefined => {
  return energyUsageComparisonData?.find(
    usageComparison => usageComparison.name === energyUsageType
  )
}

export const getCustomEnergyPeriod = (
  dateStringFrom: string,
  dateStringTo: string
): Periods => {
  const dayDiff = differenceInDays(
    new Date(dateStringTo),
    new Date(dateStringFrom)
  )
  if (dayDiff >= 365) return Periods.plus_one_year
  else if (dayDiff >= 30 && dayDiff < 365) return Periods.one_month_one_year
  else if (dayDiff >= 7 && dayDiff < 30) return Periods.one_week_one_month
  else return Periods.less_one_week
}

export const isEnergyPeriodCustom = (period: Periods) => {
  return [
    Periods.custom,
    Periods.less_one_week,
    Periods.one_week_one_month,
    Periods.one_month_one_year,
    Periods.plus_one_year
  ].includes(period)
}

export const getNumberOfDays = (p: Period, days: number) =>
  ((
    {
      day: () => 1,
      week: () => 7,
      month: () => 30,
      year: () => 365,
      custom: () => days
    }[p] || "custom"
  )())
