import Hero from "./Hero.jpg"
import { ModulesEnum } from "app/types/banner"
import ModuleHeader from "../ModuleHeader"

const ProfilePageHeader = () => {
  return (
    <ModuleHeader page={"account"} module={ModulesEnum.ACCOUNT} Hero={Hero} />
  )
}

export default ProfilePageHeader
