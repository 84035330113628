import { AppEvent } from "./types"
import State from "../state"

export const getAppEvents = (state: State): AppEvent[] => state.appEvents.events

export const getLocalAppEvents = (state: State): AppEvent[] => {
  const appEvents = getAppEvents(state)
  return appEvents.filter((appEvent: AppEvent) => appEvent.local)
}

export const getGlobalAppEvents = (state: State): AppEvent[] => {
  const appEvents = getAppEvents(state)
  return appEvents.filter((appEvent: AppEvent) => !appEvent.local)
}
