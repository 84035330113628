import { CardWrapper } from "./style"
import { useTranslate } from "hooks/translate"
import { StatusCard } from "@paudigital/wdp-components"
import { CardStatus, FacilityTicketStatusInSummary } from "../TicketStatusList"
import { FacilityTicketStatus } from "app/enums/ticketStatus"

export type StatusCardWrapperProps = {
  cardStatus: CardStatus
  onClick: (status: FacilityTicketStatusInSummary[]) => void
}

type Props = StatusCardWrapperProps

const StatusCardWrapper = ({ cardStatus, onClick }: Props) => {
  const { translate } = useTranslate()

  if (
    cardStatus.amount === 0 ||
    (cardStatus.status !== "finishedIn48Hours" &&
      cardStatus.status !== FacilityTicketStatus.INFO_NEEDED)
  )
    return null

  const clickHandler = () => {
    onClick([
      cardStatus.status === "finishedIn48Hours"
        ? FacilityTicketStatus.FINISHED
        : cardStatus.status
    ])
  }

  return (
    <CardWrapper onClick={clickHandler}>
      <StatusCard
        title={translate(
          `portal.dashboard.widget.facilitymanagement.status.${cardStatus.text}`,
          {
            amount: cardStatus.amount
          }
        )}
        status={cardStatus.status}
      />
    </CardWrapper>
  )
}

export default StatusCardWrapper
