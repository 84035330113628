import { Button, Password } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useFormik } from "formik"
import { validationSchema, PasswordValue, initialValues } from "./config"
import { getError } from "app/redux/authorization/authorization.selectors"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import AppEventProvider from "app/components/AppEventsProvider"
import { clearHttpError } from "app/redux/httpErrors/httpErrors.actions"

export type EditPasswordProps = {
  handleOnPasswordChange: (newPassword: string) => void
}

const EditPassword = ({ handleOnPasswordChange }: EditPasswordProps) => {
  const { translate } = useTranslate()
  const serverError = useSelector(getError)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearHttpError())
  }, [dispatch])

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setSubmitting,
    isValid,
    dirty
  } = useFormik({
    initialValues,
    onSubmit: ({ newPassword }: PasswordValue) => {
      isValid && handleOnPasswordChange(newPassword)
    },
    validationSchema
  })

  const hasErrors = (name: string) => {
    return errors[name as keyof PasswordValue] &&
      touched[name as keyof PasswordValue]
      ? true
      : false
  }

  useEffect(() => {
    serverError && setSubmitting(false)
  }, [serverError, setSubmitting])

  const requirements = {
    characters: translate(
      "portal.account.account.password.requirement.characters"
    ),
    small: translate("portal.account.account.password.requirement.small"),
    number: translate("portal.account.account.password.requirement.number"),
    capital: translate("portal.account.account.password.requirement.capital"),
    special: translate("portal.account.account.password.requirement.special")
  }

  return (
    <form onSubmit={handleSubmit}>
      <AppEventProvider local />
      <Password
        create={true}
        requirementTranslations={requirements}
        label={translate("portal.account.account.password.input.label")}
        name="newPassword"
        errorMessage={translate("portal.account.account.password.update.error")}
        error={hasErrors("newPassword")}
        onChange={handleChange}
        onBlur={handleBlur}
        defaultType="password"
      />
      <Button
        label={translate("portal.account.account.password.modal.button.save")}
        disabled={!isValid || !dirty}
        fullWidth
        submit
      />
    </form>
  )
}

export default EditPassword
