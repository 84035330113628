import {
  InformationSection,
  InformationSectionContent,
  InformationSectionHeader
} from "@paudigital/wdp-components"
import InformationSectionTitle from "app/components/InformationSectionTitle"
import SpecificationItem from "app/components/SpecificationItem"
import {
  MeasurementType,
  MeasurementTypes,
  RentalSpaceMeasurement
} from "app/types/buildingSpecification"
import { useTranslate } from "hooks/translate"
import { HeaderWrapper, InformationSectionTotal } from "./../RentalSpace/style"

type Props = {
  rentalSpaceType: string
  rentalSpace: Partial<Record<MeasurementType, RentalSpaceMeasurement>>
}

const allowedMeasurementTypes = [
  MeasurementTypes.Z017,
  MeasurementTypes.Z124,
  MeasurementTypes.Z108,
  MeasurementTypes.Z107,
  MeasurementTypes.Z106,
  MeasurementTypes.Z109
]

const ParkingRentalSpace = ({ rentalSpaceType, rentalSpace }: Props) => {
  const { translate } = useTranslate()
  const keys = Object.keys(rentalSpace)
  const rsKeys = keys.filter(k =>
    allowedMeasurementTypes.includes(k as MeasurementTypes)
  )

  const totalLots = rsKeys.includes(MeasurementTypes.Z017)
    ? rentalSpace[MeasurementTypes.Z017]
    : undefined
  const totalArea = rsKeys.includes(MeasurementTypes.Z124)
    ? rentalSpace[MeasurementTypes.Z124]
    : undefined
  const disabilityLots = rsKeys.includes(MeasurementTypes.Z108)
    ? rentalSpace[MeasurementTypes.Z108]
    : undefined
  const chargingLots = rsKeys.includes(MeasurementTypes.Z107)
    ? rentalSpace[MeasurementTypes.Z107]
    : undefined
  const normalLots = rsKeys.includes(MeasurementTypes.Z106)
    ? rentalSpace[MeasurementTypes.Z106]
    : undefined
  const truckLots = rsKeys.includes(MeasurementTypes.Z109)
    ? rentalSpace[MeasurementTypes.Z109]
    : undefined
  const hasLots = disabilityLots || chargingLots || normalLots
  if (rsKeys.length === 0) return <></>
  return (
    <InformationSection>
      <InformationSectionHeader>
        <HeaderWrapper>
          <InformationSectionTitle>
            {translate(
              `portal.buildingpassport.rentalspace.${rentalSpaceType}`
            )}
          </InformationSectionTitle>
          <InformationSectionTotal>
            {totalLots &&
              translate(
                `portal.buildingpassport.rentalspace.0090.${
                  totalArea?.value ? "totalWithArea" : "total"
                }.value`,
                {
                  lots: totalLots.value,
                  area: totalArea?.value
                }
              )}
          </InformationSectionTotal>
        </HeaderWrapper>
      </InformationSectionHeader>
      {hasLots ? (
        <InformationSectionContent>
          <SpecificationItem
            label={translate(
              "portal.buildingpassport.rentalspace.0090.cars.label"
            )}
          >
            <>
              {disabilityLots && (
                <div>
                  {translate("portal.buildingpassport.specification.Z108", {
                    amount: disabilityLots.value
                  })}
                </div>
              )}
              {chargingLots && (
                <div>
                  {translate("portal.buildingpassport.specification.Z107", {
                    amount: chargingLots.value
                  })}
                </div>
              )}
              {normalLots && (
                <div>
                  {translate("portal.buildingpassport.specification.Z106", {
                    amount: normalLots.value
                  })}
                </div>
              )}
            </>
          </SpecificationItem>
          <>
            {truckLots && (
              <SpecificationItem
                label={translate(
                  "portal.buildingpassport.rentalspace.0090.trucks.label"
                )}
              >
                {translate("portal.buildingpassport.specification.Z109", {
                  amount: truckLots.value
                })}
              </SpecificationItem>
            )}
          </>
        </InformationSectionContent>
      ) : (
        <></>
      )}
    </InformationSection>
  )
}

export default ParkingRentalSpace
