import State from "../state"
import { RentedBuildingContactPerson } from "app/types/rentedBuildingContactPerson"

export const isLoading = (state: State): boolean =>
  state.rentedBuildings.loading
export const getAssignedPersonsForRentedBuilding = (
  state: State
): RentedBuildingContactPerson[] =>
  state.rentedBuildings.rentedBuildingContactPersons

