import { useTranslate } from "hooks/translate"
import { DeleteModalTextBold, DeleteModalTextRegular } from "../style"
import { BaseDeleteModal } from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import * as deleteCpSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { deleteContactPerson } from "app/redux/deleteContactPerson/deleteContactPerson.actions"
import { useApi } from "hooks/api"
import { useRentedBuilding } from "hooks/rentedBuilding"

type RevokeAccessSingleBuildingModalType = {
  onCancel: () => void
}

type Props = RevokeAccessSingleBuildingModalType

const RevokeAccessSingleBuildingModal = ({ onCancel }: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const { rentedBuilding } = useRentedBuilding()
  const contactPersonId = useSelector(deleteCpSelectors.getIdOfContactPerson)
  const email = useSelector(deleteCpSelectors.getEmailOfContactPerson)
  const isLoading = useSelector(deleteCpSelectors.isLoading)
  const buildingsIdsToDeleteCpFrom = useSelector(
    deleteCpSelectors.getBuildingIdsToDeleteContactPersonFrom
  )
  const buildingLocationString = useSelector(
    deleteCpSelectors.getBuildingLocationsCpCanBeDeletedFrom
  )
  const otherAdmins = useSelector(
    deleteCpSelectors.getBuildingAdminNamesAndEmailsFromNonManagedBuildings
  )

  const onDelete = () => {
    if (!contactPersonId || !rentedBuilding) return
    call(
      deleteContactPerson(
        contactPersonId,
        buildingsIdsToDeleteCpFrom,
        true
      )
    )
  }

  return (
    <BaseDeleteModal
      title={translate("portal.admin.delete.seat.restrict.access")}
      cancelBtnText={translate("portal.admin.delete.seat.cancel")}
      deleteBtnText={translate(
        "portal.admin.delete.seat.revoke.account.single"
      )}
      deleteDisabled={isLoading || contactPersonId === undefined}
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <>
        <DeleteModalTextBold marginBottom="2.4rem">
          {translate("portal.admin.delete.seat.restrict.access.single.action", {
            email,
            building: buildingLocationString
          })}
        </DeleteModalTextBold>
        <DeleteModalTextRegular marginBottom="3.2rem">
          {translate(
            "portal.admin.delete.seat.restrict.access.single.description",
            { admins: otherAdmins }
          )}
        </DeleteModalTextRegular>
      </>
    </BaseDeleteModal>
  )
}

export default RevokeAccessSingleBuildingModal
