import {
  theme,
  BaseCard,
  CardActions,
  Button
} from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)`
  width: 100%;
  background: ${theme.colors.neutral.white};
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StyledWrapper = styled.div`
  width: 80%;
  margin-left: 2.4rem;
`

export const StyledGridContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
  min-width: 25%;

  @media only screen and (${theme.breakpoints.desktop}) {
    min-width: 20%;
  }
`

export const StyledButton = styled(Button)`
  height: 100%;
  width: 100%;

  button {
    color: ${theme.colors.neutral.darkerGrey};
    font-weight: ${theme.fontWeights.bold};
    border: 0;
    border-radius: 0;
    border-top: 1px solid ${theme.colors.neutral.midGrey};
    padding: 1.6rem 0;

    @media only screen and (${theme.breakpoints.tablet}) {
      padding: 0;
      border-left: 1px solid ${theme.colors.neutral.midGrey};
      border-top: none;
    }
  }
`

export const DocumentCategoryType = styled.span`
  color: ${theme.colors.green.contrast};
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 0.2rem;
  text-transform: uppercase;
`

export const DocumentTitle = styled.h4`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  font-weight: ${theme.fontWeights.bold};
  overflow-wrap: normal;
  overflow: hidden;
`

export const DocumentUpload = styled.span`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.light};
  display: flex;
  flex-wrap: wrap;

  strong {
    font-weight: ${theme.fontWeights.bold};
    margin-left: 0.8rem;
    margin-right: 0.4rem;

    &:first-of-type {
      margin-left: 0;
    }
  }
`

export const StyledUploaded = styled.div`
  margin-right: 1rem;
`

export const DocumentCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.4rem 0;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`
export const DocumentIcon = styled.div`
  margin-right: 1.6rem;
  display: flex;
  align-items: center;
`
