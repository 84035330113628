import { Button, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledContainer = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StyledButton = styled(Button)`
  margin: 0 1rem;
`

export const ButtonContainer = styled.div`
  display: flex;
`

export const IllustrationWrapper = styled.div`
  margin-bottom: 3.6rem;
`

export const StyledTitle = styled.h2`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2rem;
  max-width: 80%;

  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: 40rem;
  }
`
