import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import {
  getNotificationsSettings,
  patchNotificationsSettings,
  postNotificationsSettings
} from "app/api/notificationSettings"
import * as actions from "./notificationSettings.actions"
import { actions as wizardActions } from "./../registrationWizard/registrationWizard.actions"
import { RetrieveNotificationSettingsResult } from "../../types/notificationSettings"
import { mapRetrievedNotificationSettingsToReducerData } from "../../utils/notificationSettings"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getNotificationsFlow(
  action: actions.GetNotificationsSettingsAction
): SagaIterator {
  try {
    const { contactPersonId } = action.payload
    const {
      data: settings
    }: { data: RetrieveNotificationSettingsResult[] } = yield call(
      getNotificationsSettings,
      contactPersonId
    )
    yield put(
      actions.getNotificationsSettingsSuccess(
        mapRetrievedNotificationSettingsToReducerData(settings)
      )
    )
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getNotificationsSettingsFail(e))
  }
}

export function* upsertNotificationsFlow(
  action: actions.UpsertNotificationsSettingsAction
): SagaIterator {
  try {
    const settingsToPost = action.payload.data.filter(setting => !setting.id)
    const settingsToPatch = action.payload.data.filter(setting => setting.id)
    if (settingsToPost.length !== 0) {
      const {
        data: postResult
      }: { data: RetrieveNotificationSettingsResult[] } = yield call(
        postNotificationsSettings,
        settingsToPost
      )
      yield put(
        actions.postNotificationsSuccess(
          mapRetrievedNotificationSettingsToReducerData(postResult)
        )
      )
    }
    if (settingsToPatch.length !== 0) {
      const {
        data: patchResult
      }: { data: RetrieveNotificationSettingsResult[] } = yield call(
        patchNotificationsSettings,
        settingsToPatch
      )
      yield put(
        actions.patchNotificationsSuccess(
          mapRetrievedNotificationSettingsToReducerData(patchResult)
        )
      )
    }
    yield put(actions.upsertNotificationsSuccess())
    yield put(wizardActions.increaseWizard())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.upsertNotificationsFailure(e))
  }
}

export default function* NotificationsSettingsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getNotificationsFlow)
  yield takeLatest(actions.Types.UPSERT, upsertNotificationsFlow)
}
