import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledLine = styled.div`
  grid-area: line;
  display: grid;
  grid-template-rows: 1.4rem 1.4rem auto;
  grid-template-areas:
    "line-top"
    "dot"
    "line-bottom";
  height: 100%;
  width: 1.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    justify-self: center;
    grid-template-rows: 2.8rem 1.4rem auto;
  }
`
export const LineComponent = styled.div`
  background: ${theme.colors.neutral.midGrey};
  align-self: center;
  justify-self: center;
`
export const LineSegment = styled(LineComponent)`
  width: 0.2rem;
  height: 100%;
`
export const LineTop = styled(LineSegment)`
  grid-area: line-top;
`
export const Dot = styled.div`
  grid-area: dot;
  align-self: start;
  justify-self: center;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  background: ${theme.colors.neutral.midGrey};
  z-index: 1;
`
export const LineBottom = styled(LineSegment)`
  grid-area: line-bottom;
`
