import { Action, Types } from "./buildingSpecifications.actions"
import { BuildingSpecification } from "app/types/buildingSpecification"

export type BuildingSpecificationState = {
  errors: object
  loading: boolean
  data: BuildingSpecification | undefined
}

const initialState: BuildingSpecificationState = {
  errors: {},
  loading: false,
  data: undefined
}

export default (
  state = initialState,
  action: Action
): BuildingSpecificationState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        data: undefined,
        loading: false
      }

    default:
      return state
  }
}
