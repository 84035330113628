import { QueryParams as MeterHistoryParams } from "app/redux/waterMeterHistory/waterMeterHistory.actions"
import { QueryParams as AvailableMeterParams } from "app/redux/graphs/graphs.actions"
import { EnergyUsageQueryParams as EnergyUsageComparisonParams } from "app/redux/energyUsageComparison/energyUsageComparison.actions"
import {
  EnergyUsageComparisonData,
  MeterHistoryData,
  RentedBuildingMeter
} from "app/types/energy"
import { AxiosPromise } from "axios"
import { rentalApi } from "./api"
import { GetEnergyCSVParams } from "app/redux/energyCSV/energyCSV.actions"

export const getWaterMeterHistoryForRentedBuilding = (
  rentedBuildingId: string,
  params: MeterHistoryParams
): AxiosPromise<MeterHistoryData[]> => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/water-meter-history`,
    {
      method: "GET",
      params: params
    }
  )
}

export const getGasMeterHistoryForRentedBuilding = (
  rentedBuildingId: string,
  params: MeterHistoryParams
): AxiosPromise<MeterHistoryData[]> => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/gas-meter-history`, {
    method: "GET",
    params: params
  })
}

export const getAvailableMetersForRentedBuilding = (
  rentedBuildingId: string,
  params: AvailableMeterParams
): AxiosPromise<RentedBuildingMeter[]> => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/meters`, {
    method: "GET",
    params: params
  })
}

export const getElectricityMeterHistoryForRentedBuilding = (
  rentedBuildingId: string,
  params: MeterHistoryParams
): AxiosPromise<MeterHistoryData[]> => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/electricity-meter-history`,
    {
      method: "GET",
      params: params
    }
  )
}

export const getSolarProductionHistoryForRentedBuilding = (
  rentedBuildingId: string,
  params: MeterHistoryParams
): AxiosPromise<MeterHistoryData[]> => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/electricity-meter-production-history`,
    {
      method: "GET",
      params: params
    }
  )
}

export const getEnergyUsageComparisonForRentedBuilding = (
  rentedBuildingId: string,
  params: EnergyUsageComparisonParams
): AxiosPromise<EnergyUsageComparisonData[]> => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/usage-comparison`, {
    method: "GET",
    params: params
  })
}

export const downloadEnergyCSV = (
  data: GetEnergyCSVParams,
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/meters.csv`, {
    method: "GET",
    params: { ...data }
  })
}
