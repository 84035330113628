import { AxiosPromise } from "axios"
import { rentalApi, unprotectedRentalApi } from "./api"
import {
  PatchContactPerson,
  InviteContactPerson,
  DeleteContactPersonOnRentedBuildings,
  ContactPerson
} from "app/types/contactPerson"
import { RentedbuildingResponsibilities } from "app/types/responsibilities"

export const patchContactPerson = (
  contactPersonId: string,
  data: PatchContactPerson
): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}`, {
    method: "PATCH",
    data
  })
}

export const putProfilePicture = (
  contactPersonId: string,
  data: FormData
): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}/profile_picture`, {
    method: "PUT",
    data,
    headers: { "Content-Type": "multipart/form-data" }
  })
}

export const getContactPerson = (
  entityId: string,
  q: string
): AxiosPromise<ContactPerson> => {
  return rentalApi(`/entities/${entityId}/contact_persons`, {
    method: "GET",
    params: { q }
  })
}

export const getContactPersonById = (contactPersonId: string): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}`, {
    method: "GET"
  })
}

export const getMe = (): AxiosPromise => {
  return rentalApi("/me", {
    method: "GET"
  })
}

export const patchContactPersonOnboarding = (
  contactPersonId: string
): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}/onboarded_at`, {
    method: "PATCH"
  })
}

export const inviteContactPersons = (
  data: InviteContactPerson
): AxiosPromise => {
  return rentalApi("/contact_persons/invite", {
    method: "POST",
    data
  })
}

export const deleteContactPerson = (id: string): AxiosPromise => {
  return rentalApi(`/contact_persons/${id}`, {
    method: "DELETE"
  })
}

export const deleteContactPersonOnRentedBuildings = (
  id: string,
  data: DeleteContactPersonOnRentedBuildings
): AxiosPromise => {
  return rentalApi(`/contact_persons/${id}/`, {
    method: "DELETE",
    data
  })
}

export const getContactPersonRentedBuildingsWithAvailableContactPersons = (
  id: string
): AxiosPromise => {
  return rentalApi(`/contact_persons/${id}/rented_buildings/contact_persons`, {
    method: "GET"
  })
}

export const putContactPersons = (data: InviteContactPerson): AxiosPromise => {
  return rentalApi("/contact_persons", {
    method: "PUT",
    data
  })
}

export const getContactPersonContactRoles = (
  rentedBuildingId: string,
  contactPersonId: string
): AxiosPromise => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/contact_persons/${contactPersonId}/contact_roles`,
    { method: "GET" }
  )
}

export const patchContactPersonPassword = ({
  newPassword,
  token
}: {
  token: string
  newPassword: string
}): AxiosPromise => {
  return unprotectedRentalApi("/authz/reset-password", {
    headers: { Authorization: `Bearer ${token}` },
    method: "PATCH",
    data: { newPassword }
  })
}

export const patchContactPersonResponsibilities = ({
  contactPersonId,
  data
}: {
  contactPersonId: string
  data: RentedbuildingResponsibilities[]
}): AxiosPromise => {
  return rentalApi(`/contact_persons/${contactPersonId}/responsibilities`, {
    method: "PATCH",
    data
  })
}

export const resendInvite = (contactPersonId: string): AxiosPromise<void> => {
  return rentalApi(`/contact_persons/resend`, {
    method: "POST",
    data: { contactPersonId }
  })
}
