import React from "react"
import { useSelector } from "react-redux"
import * as me from "app/redux/me/me.selectors"
import * as rentedBuildingContactPersons from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import * as contactPersonActions from "app/redux/contactPerson/contactPerson.actions"
import * as rentedBuildingContactPersonsActions from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.actions"
import { InputElement, Phone, Button, Link } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useFormik } from "formik"
import { validationSchema } from "./config"
import { PossibleContactRoles } from "app/enums/roles"
import {
  StyledContainer,
  ModalTitle,
  StyledInfo,
  AddPhoneWrapper
} from "./style"
import { useApi } from "hooks/api"
import AppEventProvider from "app/components/AppEventsProvider"
import MarkdownTranslation from "app/components/MarkdownTranslation"

type PhoneNumbers = {
  phone1: string
  phone2?: string
}

const EditContact = () => {
  const { call } = useApi()
  const isLoading = useSelector(me.loading)
  const { translate } = useTranslate()
  const meProfile = useSelector(me.getUser)
  const rentedBuildings = useSelector(me.getRentedBuildings)
  const contactPersons = useSelector(
    rentedBuildingContactPersons.getContactPersons
  )

  const admins = contactPersons
    .map(cp => cp.data)
    .flat()
    .filter(cp => cp.roles.find(r => r === PossibleContactRoles.ADMIN))
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return -1
      if (b.creationDate < a.creationDate) return 1
      return 0
    })

  const {
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isValid,
    touched,
    values: { phone1, phone2, hasExtraPhoneNumber },
    setFieldTouched
  } = useFormik({
    initialValues: {
      phone1: meProfile!.telephone!,
      phone2: meProfile?.mobilephone ?? "",
      hasExtraPhoneNumber: meProfile?.mobilephone ? true : false
    },
    onSubmit: (p: PhoneNumbers) => {
      if (!isValid) return
      if (meProfile) {
        const { id } = meProfile
        const updatedProfile = {
          firstName: meProfile.firstName,
          lastName: meProfile.lastName,
          function: meProfile.function,
          mobilephone: p.phone2 ? p.phone2 : null,
          telephone: p.phone1,
          termsAndConditionsAccepted: true,
          preferredBuilding: meProfile.preferredBuilding
        }
        call(contactPersonActions.patchContactPersonAdmin(id, updatedProfile))
      }
    },
    validationSchema
  })

  const hasErrors = (name: string): boolean => {
    return errors[name as keyof PhoneNumbers] &&
      touched[name as keyof PhoneNumbers]
      ? true
      : false
  }

  const handlePhoneChange = (val: string, name: string) => {
    setFieldTouched(name, true)
    setFieldValue(name, val)
  }

  const handleAddPhone = () => {
    setFieldValue("phone2", null)
    setFieldTouched("phone2", true)
    setFieldValue("hasExtraPhoneNumber", !hasExtraPhoneNumber)
  }

  React.useEffect(() => {
    const ids = rentedBuildings?.map(r => r.id)
    if (ids) {
      call(rentedBuildingContactPersonsActions.getContactPersons(ids))
    }
  }, [call, rentedBuildings])

  return (
    <StyledContainer data-testid='contact-details-pop-up'>
      <ModalTitle>
        {translate("portal.account.account.details.subtitle")}
      </ModalTitle>
      <form onSubmit={handleSubmit}>
        <AppEventProvider local />
        <InputElement
          disabled
          label={translate("portal.account.account.email.label")}
          name={translate("portal.account.account.email.label")}
          value={meProfile?.email}
        />
        <StyledInfo>
          {translate("portal.account.account.email.disabled", {
            adminName: `${admins[0]?.firstName} ${admins[0]?.lastName}`,
            adminEmail: admins[0]?.email
          })}
        </StyledInfo>
        <Phone
          label={translate("portal.account.account.phone.label")}
          guidanceText={
            <MarkdownTranslation label="portal.contactPerson.input.phone.guidanceText" />
          }
          name="phone1"
          onChange={handlePhoneChange}
          onBlur={handleBlur}
          value={phone1}
          error={hasErrors("phone1")}
          errorMessage={errors.phone1 && translate(errors.phone1)}
        />
        {hasExtraPhoneNumber && (
          <Phone
            label={translate("portal.account.account.phone2.label")}
            guidanceText={
              <MarkdownTranslation label="portal.contactPerson.input.phone.guidanceText" />
            }
            name="phone2"
            onChange={handlePhoneChange}
            onBlur={handleBlur}
            value={phone2}
            error={hasErrors("phone2")}
            errorMessage={errors.phone2 && translate(errors.phone2)}
          />
        )}
        <AddPhoneWrapper>
          <Link
            type="default"
            value={translate(
              `portal.account.contactDetails.input.phone.${
                !hasExtraPhoneNumber ? "add" : "remove"
              }`
            )}
            onClick={handleAddPhone}
            dataTestId="contact.add-phone"
          />
        </AddPhoneWrapper>
        <Button
          label={translate("portal.account.contactDetails.button.save")}
          disabled={!isValid && !isLoading}
          fullWidth
          submit
        />
      </form>
    </StyledContainer>
  )
}

export default EditContact
