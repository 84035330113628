import { PatchedNotification } from "app/types/notification"
import { Response } from "./notifications.reducer"

export enum Types {
  GET = "GET_NOTIFICATIONS",
  GET_SUCCESS = "GET_NOTIFICATIONS_SUCCESS",
  GET_MORE = "GET_MORE",
  GET_MORE_SUCCESS = "GET_MORE_SUCCESS",
  GET_FAIL = "GET_NOTIFICATIONS_FAIL",
  PATCH = "PATCH_NOTIFICATIONS",
  PATCH_FAIL = "PATCH_NOTIFICATIONS_FAIL",
  PATCH_SUCCESS = "PATCH_NOTIFICATIONS_SUCCESS",
  START_TIMER = "START_TIMER",
  RESET_TIMER = "RESET_TIMER",
  UPDATE_TIMER = "UPDATE_TIMER",
  GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT",
  GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS",
  GET_NOTIFICATION_COUNT_FAIL = "GET_NOTIFICATION_COUNT_FAIL"
}

export type Action =
  | GetNotificationsAction
  | GetNotificationsSuccessAction
  | GetMoreNotificationsSuccessAction
  | GetMoreNotificationsAction
  | GetNotificationsFailAction
  | PatchNotificationsAction
  | PatchNotificationsFailAction
  | PatchNotificationsSuccessAction
  | StartTimerAction
  | ResetTimerAction
  | UpdateTimerAction
  | GetNotificationsCountAction
  | GetNotificationCountSuccessAction
  | GetNotificationCountFailAction

export type GetNotificationsAction = {
  payload: {
    page?: number
    perPage?: number
  }
  type: Types.GET
}

export type GetMoreNotificationsAction = {
  payload: { page?: number; perPage?: number }
  type: Types.GET_MORE
}

export type GetMoreNotificationsSuccessAction = {
  payload: { data: Response }
  type: Types.GET_MORE_SUCCESS
}

export type GetNotificationsSuccessAction = {
  payload: { data: Response }
  type: Types.GET_SUCCESS
}

export type GetNotificationsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type PatchNotificationsAction = {
  type: Types.PATCH
}

export type PatchNotificationsFailAction = {
  type: Types.PATCH_FAIL
  payload: {
    errors: Record<string, unknown>
  }
}

export type PatchNotificationsSuccessAction = {
  type: Types.PATCH_SUCCESS
  payload: {
    patchedNotification: PatchedNotification
  }
}

export type StartTimerAction = { type: Types.START_TIMER }
export type ResetTimerAction = {
  type: Types.RESET_TIMER
}
export type UpdateTimerAction = { type: Types.UPDATE_TIMER }

export type GetNotificationsCountAction = {
  type: Types.GET_NOTIFICATION_COUNT
}

export type GetNotificationCountSuccessAction = {
  type: Types.GET_NOTIFICATION_COUNT_SUCCESS
  payload: { data: Record<string, number> }
}

export type GetNotificationCountFailAction = {
  type: Types.GET_NOTIFICATION_COUNT_FAIL
  payload: { errors: Record<string, unknown> }
}

export function getNotifications(
  page?: number,
  perPage?: number
): Action {
  return {
    payload: { page, perPage },
    type: Types.GET
  }
}

export function getMoreNotifications(page?: number, perPage?: number): Action {
  return {
    payload: { page, perPage },
    type: Types.GET_MORE
  }
}

export function getNotificationsSuccess(data: Response): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getMoreNotificationsSuccess(data: Response): Action {
  return {
    payload: { data },
    type: Types.GET_MORE_SUCCESS
  }
}

export function getNotificationsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function patchNotifications(): Action {
  return {
    type: Types.PATCH
  }
}

export function patchNotificationsFail(
  errors: Record<string, unknown>
): Action {
  return {
    type: Types.PATCH_FAIL,
    payload: { errors }
  }
}

export function patchNotificationsSuccess(
  patchedNotification: PatchedNotification
): Action {
  return {
    type: Types.PATCH_SUCCESS,
    payload: {
      patchedNotification
    }
  }
}

export function startTimer(): Action {
  return { type: Types.START_TIMER }
}
export function resetTimer(
): Action {
  return { type: Types.RESET_TIMER }
}
export function updateTimer(): Action {
  return { type: Types.UPDATE_TIMER }
}

export function getNotificationsCount(): Action {
  return {
    type: Types.GET_NOTIFICATION_COUNT,
  }
}

export function getNotificationsCountSuccess(
  data: Record<string, number>
): Action {
  return {
    type: Types.GET_NOTIFICATION_COUNT_SUCCESS,
    payload: { data }
  }
}

export function getNotificationsCountFail(errors: Record<string, any>): Action {
  return {
    type: Types.GET_NOTIFICATION_COUNT_FAIL,
    payload: { errors }
  }
}
