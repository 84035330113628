import axios, { AxiosInstance, AxiosStatic } from "axios"
import { API_URLS } from "./config"
import { createApiInstance } from "api"
import { requestInterceptor } from "./interceptors"

function createProtectedInstance(
  axiosInstance: AxiosStatic,
  url: string
): AxiosInstance {
  const instance = createApiInstance(axiosInstance, url)
  instance.interceptors.request.use(requestInterceptor)
  return instance
}

function createInstance(axiosInstance: AxiosStatic, url: string) {
  return createApiInstance(axiosInstance, url)
}

export const rentalApi = createProtectedInstance(axios, API_URLS.rentalApi)
export const unprotectedRentalApi = createInstance(axios, API_URLS.rentalApi)
export const notificationsApi = createProtectedInstance(
  axios,
  API_URLS.notificationApi
)
export const auth0Api = createInstance(axios, API_URLS.auth0Api)
