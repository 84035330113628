import {
  Button,
  PreferencesIcon,
  theme,
  Spinner,
  DownloadIcon
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import {
  TopBarMobile,
  StyledTotalTickets,
  TopButtons,
  TitleWrapper,
  ButtonWrapper
} from "./style"
import Sort from "../../InvoiceFilter/Sort"
import FilterTags from "../../FilterTags"
import { Filters } from "routes/Invoices"
import { useViewport } from "hooks/viewport"
import { useDispatch, useSelector } from "react-redux"
import {
  getInvoicesFilterIsOpen,
  getTotal,
  loading
} from "app/redux/invoices/invoices.selectors"
import {
  downloadInvoiceCSV,
  getSelectedInvoicesFilters
} from "app/redux/invoices/invoices.actions"
import { useApi } from "hooks/api"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  filters: Filters
  setUrlParams: (params: any) => void
}

const MobileTopBar = ({ filters, setUrlParams }: Props) => {
  const { call } = useApi()
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { width } = useViewport()
  const showFilters = useSelector(getInvoicesFilterIsOpen)
  const total = useSelector(getTotal)
  const isLoading = useSelector(loading)

  const toggleFilters = () => {
    dispatch(getSelectedInvoicesFilters(!showFilters))
  }

  const isFiltered =
    filters.invoiceType ||
    filters.contractNumber ||
    filters.invoicePeriodFrom ||
    filters.invoicePeriodTo ||
    filters.invoiceDateTo ||
    filters.invoiceDateFrom ||
    filters.invoiceNumber ||
    filters.reference

  const handleDownload = () => {
    dispatch(createGAEvent(PossibleGAEvents.DOWNLOAD_OVERVIEW))
    call(downloadInvoiceCSV(filters))
  }

  return (
    <TopBarMobile isOpen={showFilters}>
      <TitleWrapper>
        {isLoading && <Spinner />}
        <StyledTotalTickets loading={isLoading}>
          {isFiltered
            ? translate(`portal.invoices.total${isLoading ? ".loading" : ""}`, {
                total
              })
            : translate("portal.invoices.total.all", { total })}
        </StyledTotalTickets>
      </TitleWrapper>
      <TopButtons>
        <ButtonWrapper>
          <Button
            type="secondary"
            label={translate("portal.invoices.filter.button")}
            onClick={toggleFilters}
            startIcon={<PreferencesIcon fill={theme.colors.green.contrast} />}
          />
          <Button
            type="secondary"
            fullWidth
            label={translate("portal.invoices.download.button.label")}
            startIcon={
              <DownloadIcon
                height="3rem"
                width="2.5rem"
                fill={theme.colors.green.contrast}
              />
            }
            onClick={handleDownload}
          />
        </ButtonWrapper>
        {width >= theme.breakpointsValues.tablet && (
          <Sort setUrlParams={setUrlParams} {...filters} />
        )}
      </TopButtons>
      <FilterTags filters={filters} setUrlParams={setUrlParams} />
    </TopBarMobile>
  )
}

export default MobileTopBar
