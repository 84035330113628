import { Action, Types } from "./ticketCategories.actions"
import { TicketType } from "app/types/ticketCategory"

export type TicketCategoriesState = {
  errors: object
  loading: boolean
  data: TicketType[]
}

const initialState: TicketCategoriesState = {
  errors: {},
  loading: false,
  data: []
}

export default (
  state = initialState,
  action: Action
): TicketCategoriesState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        errors: {},
        loading: false,
        data: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
