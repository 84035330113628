import { Locales } from "app/i18n/config"

export enum ModulesEnum {
  DASHBOARD = "dashboard",
  ENERGY = "energy",
  DOCUMENTS = "documents",
  PROPERTY_MANAGEMENT = "property_management",
  INVOICES = "invoices",
  BUILDING_PASSPORT = "building_passport",
  ADMIN = "admin",
  ACCOUNT = "account",
  NOTIFICATION = "notification",
  FAQ = "faq"
}

export type LanguageSpecificInfo = {
  title: string
  label?: string
  url?: string
  hasLink?: boolean
}

export type HslColorType = {
  h: number
  s: number
  l: number
  a?: number | undefined
}

export type HsvColorType = {
  h: number
  s: number
  v: number
  a?: number | undefined
}

export type RgbColorType = {
  r: number
  g: number
  b: number
  a?: number | undefined
}

export type CampaignTitleColor = {
  hex: string
  hsl: HslColorType
  hsv?: HsvColorType
  oldHue?: number
  rgb: RgbColorType
  source?: string
}

export type Campaign = {
  languageData: {
    [Locales.NL_NL]: LanguageSpecificInfo | undefined
    [Locales.NL_BE]: LanguageSpecificInfo | undefined
    [Locales.FR_FR]: LanguageSpecificInfo | undefined
    [Locales.EN_GB]: LanguageSpecificInfo | undefined
    [Locales.RO_RO]: LanguageSpecificInfo | undefined
    [Locales.DE_DE]: LanguageSpecificInfo | undefined
  }
  bannerPictureUrl?: string | undefined
  color: CampaignTitleColor
  module: ModulesEnum
}
