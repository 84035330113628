import { ChevronIcon } from "@paudigital/wdp-components"
import { Modules } from "app/enums/modules"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { usePermission } from "hooks/permission"
import { useDispatch } from "react-redux"
import {
  Container,
  ChrevonWrapper,
  SectionTitle,
  SectionChildren,
  StyledLink,
  Count,
  ChevronCountContainer
} from "./style"

type SectionBaseProps = {
  children?: JSX.Element
  title: string
  name: string
  rentedBuildingId: string
  count?: number | undefined
}

type Props = SectionBaseProps

const Section = ({ children, title, name, rentedBuildingId, count }: Props) => {
  const dispatch = useDispatch()
  const { hasPermission } = usePermission()
  if (!hasPermission(name as Modules, rentedBuildingId)) return null
  const showCount = count ? count > 0 : false

  const handleClick = () => {
    dispatch(remove("building-switcher"))
  }
  return (
    <StyledLink to={`/${name}/${rentedBuildingId}`} onClick={handleClick}>
      <Container>
        <SectionTitle>{title}</SectionTitle>
        <ChevronCountContainer>
          {showCount && <Count>{count}</Count>}
          <ChrevonWrapper>
            <ChevronIcon />
          </ChrevonWrapper>
        </ChevronCountContainer>
        {children && <SectionChildren>{children}</SectionChildren>}
      </Container>
    </StyledLink>
  )
}

export default Section
