import { Document } from "./document"

export type InvoiceResponse = {
  total: number
  invoices: Invoice[]
  filters: InvoiceFilters
}

export enum InvoiceStatusTypes {
  OPEN = "open",
  PAID = "paid",
  OVERDUE = "overdue"
}

export type InvoiceFilters = {
  page: number
  pages?: number
  perPage: number
  invoiceTypes?: InvoiceType[]
  contractNumber?: string[]
  invoicedPeriodTo?: string
  invoicedPeriodFrom?: string
  invoicedDateTo?: string
  invoicedDateFrom?: string
  invoiceNumber?: string
  sort?: string[]
}

export type Invoice = Document & {
  invoiceType: InvoiceType
  purchaseId: string | null
  contractId: string | null
  metadata: InvoiceMetaData
  status: InvoiceStatusTypes
}

export type InvoiceMetaData = {
  Amount_x0020_in_x0020_local_x0020_currency: string
  Invoice_x0020_Document_x0020_ID: string
  Document_x0020_date: string
  Currency_x0020_Key: string
  Document_x0020_Date_x0020_in_x0020_Document: string
  Invoice_x0020_Number: string
  RE_x0020_Process: string
  Settlement_x0020_period_x0020_from: string
  Settlement_x0020_period_x0020_to: string
  TaskDueDate: string
}

export enum InvoiceType {
  CREDIT_NOTE = "credit-note",
  SALES_INVOICE = "sales-invoice"
}

export type GetInvoiceCSVParams = {
  invoiceType?: InvoiceType[]
  invoiceDateFrom?: string
  invoiceDateTo?: string
  invoicePeriodFrom?: string
  invoicePeriodTo?: string
  contractNumber?: string[]
}
