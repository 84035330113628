import { theme } from "@paudigital/wdp-components"
import { intl, Locales } from "app/i18n/config"
import { parse } from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"
import { getLocaleNumberFormatter } from "../localeNumberFormatter.utils"

export const generateXAxisLabel = (label: string) => {
  return label
}

export const generateTooltipLabel = (
  point: {
    x: string
    color: any
    series: { name: any }
    y: any
  },
  unit: string,
  language: Locales
): string => {
  const parsedDate = utcToZonedTime(parse(point.x, "t", new Date()), "UTC")
  const formattedDate = format(parsedDate, "HH:mm dd/MM/yyyy", {
    timeZone: "UTC"
  })
  const { decimalFormatter } = getLocaleNumberFormatter()
  return `
    <span style="display: block; color: ${
      theme.colors.neutral.darkGrey
    }; line-height: 14px; font-weight: 700; margin-bottom: 5px;">
      <span style="border-radius: 50%; width: 12px; height:12px; background-color: ${
        point.color
      }; margin-right: 4px; vertical-align: middle; display: inline-block;"></span>
      ${intl[language].formatMessage({
        id: `portal.energy.graphs.series.${point.series.name}`
      })}
    </span>
    <span style="display: block; margin-bottom: 4px; color: ${
      theme.colors.neutral.darkerGrey
    }; line-height: 14px; font-weight: 400;">${formattedDate}: ${decimalFormatter(point.y, 0)} ${unit}</span>`
}

export const getBaseGraphOptions = (language: Locales) => {
  return {
    chart: {
      style: {
        fontFamily: theme.fontFamily
      },
      spacingLeft: 0,
      spacingRight: 0
    },
    lang: {
      noData: intl[language].formatMessage({
        id: "portal.energy.graphs.nodata"
      }),
      loading: intl[language].formatMessage({
        id: "portal.energy.graphs.loading"
      })
    },
    loading: {
      style: { opacity: 1, color: theme.colors.neutral.darkerGrey }
    },
    title: { text: "" },
    subtitle: { text: "" },
    legend: { enabled: false },
    credits: { enabled: false },
    navigation: {
      buttonOptions: { enabled: false }
    },
    plotOptions: {
      series: { connectNulls: false }
    },
    yAxis: {
      labels: {
        formatter: function () {
          const self: any = this
          return `<span style="display: block; text-align: center; color: ${theme.colors.neutral.darkGrey}; font-size: 10px; line-height: 12px;">${self.value}</span>`
        },
        useHTML: true
      },
      title: { text: "" }
    },
    xAxis: {
      startOnTick: true,
      endOnTick: true,
      width: "100%",
      maxPadding: 0,
      minPadding: 0,
      labels: {
        formatter: function () {
          const self: any = this
          const formattedDate = format(
            parse(self.value, "t", new Date()),
            "hh:mm yyyy-MM-dd"
          )
          const [time, date] = formattedDate.split(" ")
          return `<span style="display: block; text-align: center; color: ${theme.colors.neutral.darkGrey}; font-size: 10px; line-height: 12px;">${time}<br/>${date}</span>`
        },
        useHTML: true
      }
    },
    tooltip: {
      formatter: function () {
        const self: any = this
        return `${format(
          parse(self.x, "t", new Date()),
          "hh:mm yyyy-MM-dd"
        )}: ${self.y}`
      },
      useHTML: true
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 540
          },
          chartOptions: {
            yAxis: {
              title: {
                enabled: false
              }
            }
          }
        }
      ]
    }
  }
}

export const baseAreaSplineSerieOptions = {
  data: [],
  type: "areaspline",
  color: theme.colors.green.contrast,
  lineColor: theme.colors.green.contrast,
  fillColor: theme.colors.graphs.wintergreenDream,
  marker: { enabled: false }
}

export const baseColumnSerieOptions = {
  data: [],
  type: "column",
  stacking: "normal",
  pointPadding: 0.1,
  groupPadding: 0,
  borderWidth: 0,
  color: theme.colors.graphs.wintergreenDream,
  marker: { enabled: false }
}
