import { Document, DocumentResponse, DocumentStatisticsDto, SetDocumentFieldType } from "app/types/document"
import { Action, Types } from "./document.actions"

export type DocumentState = {
  data: DocumentResponse
  errors?: Record<string, unknown>
  loading: boolean
  statistics: DocumentStatisticsDto[]
  summary: Document[]
  isFilterOpen: boolean
  document: Partial<SetDocumentFieldType>
}

const initialState: DocumentState = {
  data: {
    total: 0,
    documents: [],
    filters: {
      page: 1,
      pages: 1,
      perPage: 20
    },
    documentStatistics: []
  },
  errors: undefined,
  loading: false,
  statistics: [],
  summary: [],
  isFilterOpen: false,
  document: {}
}

export default (state = initialState, action: Action): DocumentState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.DOWNLOAD:
      return {
        ...state,
        loading: true
      }

    case Types.DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.DOWNLOAD_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.DOWNLOAD_CSV: {
      return {
        ...state,
        loading: true,
      }
    }

    case Types.DOWNLOAD_CSV_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case Types.DOWNLOAD_CSV_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }
    }

    case Types.GET_STATISTICS:
      return {
        ...state,
        loading: true
      }

    case Types.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload.data,
        loading: false
      }

    case Types.GET_STATISTICS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }
    case Types.GET_SUMMARY:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload.data,
        loading: false
      }

    case Types.GET_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_SELECTED_DOCUMENTS_FILTERS:
      return {
        ...state,
        isFilterOpen: action.payload
      }

    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.POST_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.SET_FIELD_VALUE:
      return {
        ...state,
        document: {
          ...state.document,
          [action.payload.key]: action.payload.value
        }
      }

    case Types.DISCARD_DOCUMENT:
      return {
        ...state,
        document: {}
      }

    default:
      return state
  }
}
