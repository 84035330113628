export type PvProject = {
  id: string
  buildingId: string
  createdOn: string
  updatedOn: string
  steps: PvProjecctStep[]
  documentCount: number
}

export enum StepEnum {
  PLANNED = "PLANNED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED"
}

export type PvProjecctStep = {
  step: number
  startDate?: string
  endDate?: string
  status: StepEnum
}
