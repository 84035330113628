import React from "react"
import { usePermission } from "hooks/permission"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useNavigate } from "react-router-dom"
import { getUser } from "app/redux/me/me.selectors"
import { useSelector } from "react-redux"
import MaintenancePage from "app/components/MaintenancePage"

const Root = () => {
  const navigate = useNavigate()
  const { isExternal } = usePermission()
  const { rentedBuilding } = useRentedBuilding()
  const user = useSelector(getUser)


  React.useEffect(() => {
    if (!user) return
    const decideNavigate = () => {
      if(user!.groups){
        return "/wdp-admin"
      }
      return isExternal()
          ? `/facility-management${rentedBuilding?.id}}`
          : `/dashboard/${rentedBuilding?.id}`
    }
    navigate(
      decideNavigate()
      ,
      {
        replace: true
      }
    )
  }, [user, navigate, isExternal, rentedBuilding?.id])

  return <MaintenancePage />
}

export default Root
