import { useTranslate } from "hooks/translate"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import React, { useRef } from "react"
import {
  StyledBaseCard,
  StyledContentWrapper,
  StyledRuler,
  HighchartsWrapper
} from "./style"
import exporting from "highcharts/modules/exporting"
import NoDataToDisplay from "highcharts/modules/no-data-to-display"
import { Notification } from "@paudigital/wdp-components"
import { MeterHistoryData } from "app/types/energy"
import {
  buildGraphOptions,
  GraphOptions,
  Graphs
} from "app/utils/graphs/graph.utils"
import { getBaseGraphOptions } from "app/utils/graphs/baseGraphOptions"
import { useSelector } from "react-redux"
import {
  getEnergyPeriod,
  getIntervalOfGraph
} from "app/redux/energyPeriod/energyPeriod.selectors"
import { getPreferredLanguage } from "app/redux/me/me.selectors"
import LegendWrapper from "./LegendWrapper"
import CardHeader from "./CardHeader"
import { Locales } from "app/i18n/config"

exporting(Highcharts)
NoDataToDisplay(Highcharts)

export type BaseGraphProps = {
  loading: boolean
  title: string
  graphType: Graphs
  lastUpdated?: Date
  refreshGraph: () => void
  graphSerieData: MeterHistoryData[] | undefined
  warning?: boolean
  className?: string
}

const BaseGraph = ({
  loading,
  title,
  graphType,
  lastUpdated,
  refreshGraph,
  graphSerieData,
  warning,
  className
}: BaseGraphProps) => {
  const { translate } = useTranslate()
  const preferredLanguage: Locales = useSelector(getPreferredLanguage)
  const chart = useRef<any>()
  const [allowChartUpdate, setAllowChartUpdate] = React.useState<boolean>(false)
  const [graphData, setGraphData] = React.useState<GraphOptions>({
    legendItems: [],
    graphOptions: getBaseGraphOptions(preferredLanguage)
  })
  const energyPeriod = useSelector(getEnergyPeriod)
  const graphInterval = useSelector(getIntervalOfGraph(graphType))

  React.useEffect(() => {
    if (!graphSerieData || !chart.current) return
    setAllowChartUpdate(true)
    setGraphData(
      buildGraphOptions(
        graphType,
        graphSerieData,
        chart.current.chart.plotWidth,
        graphInterval,
        preferredLanguage
      )
    )
  }, [
    energyPeriod,
    graphInterval,
    graphSerieData,
    graphType,
    preferredLanguage
  ])

  const chartCallback = () => {
    setAllowChartUpdate(false)
  }

  return (
    <StyledBaseCard
      className={className}
      backgroundColor="white"
      boxShadow="none"
    >
      <StyledContentWrapper>
        <CardHeader
          title={title}
          lastUpdated={lastUpdated}
          graphType={graphType}
          refreshGraph={refreshGraph}
        />
        <StyledRuler />
        {warning && (
          <Notification
            show
            description={translate(
              "portal.energy.graphs.notification.error.message"
            )}
            title=""
            variant="warning"
            delayInMs={0}
          />
        )}
        <LegendWrapper chartRef={chart} graphData={graphData} />
        <HighchartsWrapper>
          {!loading && (
            <HighchartsReact
              highcharts={Highcharts}
              ref={chart}
              options={graphData.graphOptions}
              containerProps={{ style: { height: "450px", width: "100%" } }}
              immutable={true}
              allowChartUpdate={allowChartUpdate}
              callback={chartCallback}
            />
          )}
        </HighchartsWrapper>
      </StyledContentWrapper>
    </StyledBaseCard>
  )
}

export default BaseGraph
