import { Locales } from "app/i18n/config"
import { getBrowserLanguage } from "./browserLanguage.utils"

export type QueryParam = {
  language: string
}

const defaultLanguage = Locales.EN_GB
const supportedLanguages = [...Object.values(Locales)]

const isLanguageSupported = (language: Locales) => {
  return supportedLanguages.includes(mapCodeToLanguage(language))
}

const getSupportedOrDefaultLanguage = (language: Locales) =>
  isLanguageSupported(language) ? language : defaultLanguage

const getUserLanguage = () =>
  getSupportedOrDefaultLanguage(getBrowserLanguage() as Locales)

const mapCodeToLanguage = (language: Locales): Locales => {
  const code = language.substr(0, 2)

  if (language.match(/^(en)/g)) return `${code}-GB` as Locales
  if (language.match(/^(fr)/g)) return `${code}-FR` as Locales
  if (language.match(/^(ro)/g)) return `${code}-RO` as Locales
  return language.match(/(NL|BE)$/g) ? language : (`${code}-NL` as Locales)
}

export const getLanguage = (paramLanguage?: Locales) => {
  const language = getSupportedOrDefaultLanguage(
       paramLanguage || (getUserLanguage() as Locales)
  )
  return mapCodeToLanguage(language)
}
