import { MyProfile } from "app/types/myProfile"

export enum Types {
  GET = "ME_GET",
  GET_SUCCESS = "ME_GET_SUCCESS",
  GET_FAIL = "ME_GET_FAIL",
  SET_VALUE = "ME_SET_VALUE"
}

export type Action =
  | GetMeAction
  | GetMeSuccessAction
  | GetMeFailAction
  | SetMeValueAction

export type GetMeAction = {
  type: Types.GET
}

export type GetMeSuccessAction = {
  payload: MyProfile
  type: Types.GET_SUCCESS
}

export type GetMeFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type SetMeValueAction = {
  payload: { key: keyof MyProfile; value: string | boolean }
  type: Types.SET_VALUE
}

export function getMe(): GetMeAction {
  return {
    type: Types.GET
  }
}

export function getMeSuccess(payload: MyProfile): GetMeSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getMeFail(errors: object): GetMeFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function setMeValue(
  key: keyof MyProfile,
  value: string | boolean
): SetMeValueAction {
  return {
    payload: { key, value },
    type: Types.SET_VALUE
  }
}
