import ListItem from "app/components/ListItem"
import { AnswerCodeType, AnswerCodeValueType } from "app/types/ticketCategory"

type Props = {
  onClick: (
    answerCode: AnswerCodeType,
    answerCodeValue: AnswerCodeValueType
  ) => void
  label: string
  answerCodeValue: AnswerCodeValueType
  answerCode: AnswerCodeType
  itemNumber: number
}

const SubCategoryItem = ({
  label,
  answerCode,
  answerCodeValue,
  onClick,
  itemNumber
}: Props) => {
  const handleClick = () => {
    onClick(answerCode, answerCodeValue)
  }
  return (
    <ListItem label={label} onClick={handleClick} itemNumber={itemNumber} />
  )
}
export default SubCategoryItem
