import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"
import createSagaMiddleware from "redux-saga"
import rootReducer, { createReduxHistory, routerMiddleware } from "./reducers"
import sagas from "./sagas"
import State from "app/redux/state"


export const prepareStore = (initialState: Partial<State> = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [routerMiddleware, sagaMiddleware]

  const composedMiddlewares = composeWithDevTools(
    applyMiddleware(...middlewares)
  )
  const store = createStore(
    rootReducer(),
    initialState as any,
    composedMiddlewares
  )
  sagaMiddleware.run(sagas)
  return store
}
const store = prepareStore()

export const history = createReduxHistory(store);

export default store
