import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const EmptyContainer = styled.div`
  height: 24rem;
  background: ${theme.colors.neutral.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  text-align: center;
  padding: 0 4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    height: 39rem;
  }
`
