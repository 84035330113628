import {
  BaseCard,
  Button,
  CardActions,
  theme
} from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${theme.colors.neutral.white};
  min-height: 8.3rem;
  margin: 1rem 0;

  &:hover {
    background-color: ${theme.colors.stromboliGreen.prc20};
  }
`

export const StyledDataIdDiv = styled.div`
  display: contents;
`

export const StyledButton = styled(Button)`
  height: 100%;
  button {
    font-weight: ${theme.fontWeights.regular};
    border: 0;
    border-left: 1px solid ${theme.colors.neutral.midGrey};
    border-radius: 0;
    height: 100%;
    min-width: 7.4rem;

    &:hover {
      background-color: initial;
    }
  }
`

export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
`

export const StyledContractdate = styled.p`
  font-size: ${theme.fontSizes.caption};
`

export const StyledTitle = styled.span`
  color: ${theme.colors.green.contrast};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.captionExtraSmall};
  text-transform: uppercase;
  margin-bottom: 0.4rem;
`

export const StyledInfo = styled.div`
  margin: 1.6rem;
`

export const StyledDescription = styled.div`
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.caption};
`
