import S from "string"

export const slugifyString = (valueToSlug: string) => {
  return valueToSlug.toLowerCase().replace(/ /g, "_")
}

export const slugifyForAnchorTag = (valueToSlug: string) => {
  return S(valueToSlug).slugify().s
}

export const deslugifyString = (valueToDeslug: string) => {
  return `${valueToDeslug[0].toUpperCase()}${valueToDeslug
    .slice(1)
    .replace(/_/g, " ")}`
}
