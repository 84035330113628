import { IntervalEnum } from "app/enums/interval"
import { Co2CalculationMethods } from "app/types/energy"
import { EnergyPeriodRange } from "app/types/energyPeriodRange"
import { Graphs } from "app/utils/graphs/graph.utils"
import State from "../state"
import { Periods } from "./energyPeriod.reducer"

export const getEnergyPeriodRange = (state: State): EnergyPeriodRange => ({
  from: state.energyPeriod.range.from,
  to: state.energyPeriod.range.to
})

export const needsToSyncEnergyPeriod = (state: State): boolean =>
  state.energyPeriod.synchronize

export const getEnergyPeriod = (state: State): Periods =>
  state.energyPeriod.period

export const getGranularityOptions = (state: State): IntervalEnum[] =>
  state.energyPeriod.options

export const getIntervalOfGraph = (graphType: Graphs) => (
  state: State
): IntervalEnum => state.energyPeriod[graphType]

export const getCo2CalculationMethod = (state: State): Co2CalculationMethods =>
  state.energyPeriod.co2CalculationMethod
