import {
  InformationSection,
  InformationSectionContent,
  InformationSectionHeader
} from "@paudigital/wdp-components"
import InformationSectionTitle from "app/components/InformationSectionTitle"
import SpecificationItem from "app/components/SpecificationItem"
import {
  MeasurementType,
  RentalSpaceMeasurement
} from "app/types/buildingSpecification"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"
import { useTranslate } from "hooks/translate"
import { HeaderWrapper, InformationSectionTotal } from "./style"

type Props = {
  rentalSpaceType: string
  rentalSpace: Partial<Record<MeasurementType, RentalSpaceMeasurement>>
}

type MappingType = {
  [key: string]: string
}

const mappings: MappingType = {
  "0004": "Z000",
  "0005": "Z001",
  "0006": "Z002",
  "0079": "Z007",
  "0080": "Z006",
  "0081": "Z011",
  "0082": "Z009",
  "0090": "Z017",
  "0091": "Z008",
  "0092": "Z010"
}

const hiddenFields = [
  "Z000",
  "Z001",
  "Z002",
  "Z006",
  "Z007",
  "Z008",
  "Z009",
  "Z010",
  "Z011",
  "Z017",
  "Z100",
  "Z101",
  "Z102",
  "Z137",
  "Z138",
  "Z201",
  "Z202"
]

const RentalSpace = ({ rentalSpaceType, rentalSpace }: Props) => {
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()

  const getRentalSpaceTotal = () => {
    const rentalSpaceTotalKey = mappings[rentalSpaceType] as MeasurementType
    const measurement = rentalSpace[rentalSpaceTotalKey]
    if (!measurement) return null
    return translate(
      `portal.buildingpassport.measurementunit.${measurement.unit}`,
      {
        value: decimalFormatter(measurement.value, 0)
      }
    )
  }

  const buildMeasurementData = () => {
    const items = []
    for (const [measurementType, measurementData] of Object.entries(
      rentalSpace
    )) {
      if (hiddenFields.includes(measurementType)) continue
      if (
        ["0005", "0079", "0082", "0091", "0092"].includes(rentalSpaceType) &&
        measurementType === "Z124"
      )
        continue
      if (!measurementData?.value || measurementData?.value === 0) continue
      items.push(
        <SpecificationItem
          key={`specification-${measurementType}`}
          label={`portal.buildingpassport.specification.${measurementType.toString()}`}
        >
          {translate(
            `portal.buildingpassport.measurementunit.${measurementData.unit}`,
            {
              value:  decimalFormatter(measurementData.value, 0)
            }
          )}
        </SpecificationItem>
      )
    }
    return items
  }
  const total = getRentalSpaceTotal()
  const measurementData = buildMeasurementData()
  if (measurementData.length === 0 || !total) return null
  return (
    <InformationSection>
      <InformationSectionHeader>
        <HeaderWrapper>
          <InformationSectionTitle>
            {translate(
              `portal.buildingpassport.rentalspace.${rentalSpaceType}`
            )}
          </InformationSectionTitle>
          <InformationSectionTotal>{total}</InformationSectionTotal>
        </HeaderWrapper>
      </InformationSectionHeader>
      <>
        {measurementData.length > 0 && (
          <InformationSectionContent>
            <>{measurementData}</>
          </InformationSectionContent>
        )}
      </>
    </InformationSection>
  )
}

export default RentalSpace
