import { theme } from "@paudigital/wdp-components"
import styled, { css } from "styled-components"

export const ChevronLeftWrapper = styled.div`
  margin-left: -1.8rem;
  margin-right: 0.4rem;
`

export const ChevronRightWrapper = styled.div`
  margin-left: 0.4rem;
`

export const DrillDownWrapper = styled.div`
  display: flex;
`

export const StyledDrilldown = styled.div<{
  isSelected?: boolean
}>`
  cursor: pointer;
  margin-bottom: 0.8rem;
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.captionChartNumber};
  ${props => {
    if (props.isSelected)
      return css`
        font-weight: ${theme.fontWeights.bold};
        cursor: unset;
      `
  }};
`
