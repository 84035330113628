import * as selectors from "app/redux/invoices/invoices.selectors"
import { useSelector } from "react-redux"
import InvoiceCard from "../InvoiceCard"
import { Pagination } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useViewport } from "hooks/viewport"
import PagePagination from "../../Page/PagePagination"
import { usePagination } from "hooks/pagination"
import { Filters } from "routes/Invoices"

type Props = {
  page: number
  perPage: number
  sort?: string[]
  setUrlParams: (params: Filters) => void
  queryParams?: Filters
}

const Invoices = ({
  page,
  perPage,
  sort,
  setUrlParams,
  queryParams
}: Props) => {
  const { translate } = useTranslate()
  const total = useSelector(selectors.getTotal)
  const params = useSelector(selectors.getInvoicesFilters)
  const invoices = useSelector(selectors.getInvoices)
  const { width } = useViewport()
  const { nextPage, previousPage, toPage } = usePagination({
    pages: params!.pages!,
    setUrlParams,
    page,
    perPage,
    sort,
    queryParams
  })

  return (
    <>
      {invoices?.map(i => (
        <InvoiceCard invoice={i} key={i.id} />
      ))}
      {total > perPage! && (
        <PagePagination>
          <Pagination
            page={page}
            perPage={perPage}
            pages={params!.pages!}
            nextPage={nextPage}
            previousPage={previousPage}
            goToPage={toPage}
            showPages={3}
            width={width}
            next={translate("portal.facilitymanagement.pagination.next")}
            previous={translate(
              "portal.facilitymanagement.pagination.previous"
            )}
          />
        </PagePagination>
      )}
    </>
  )
}

export default Invoices
