import { getEnergyUsageComparisonForRentedBuilding } from "app/api/energy"
import { getUTCIsoDate } from "app/utils/timeZone.utils"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./energyUsageComparison.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* energyUsageComparisonFlow(
  action: actions.GetEnergyUsageComparisonAction
): SagaIterator {
  try {
    const { rentedBuildingId, country, params } = action.payload
    params.previousPeriodFrom = getUTCIsoDate(
      params.previousPeriodFrom,
      country
    )
    params.previousPeriodTo = getUTCIsoDate(params.previousPeriodTo, country)
    params.currentPeriodFrom = getUTCIsoDate(params.currentPeriodFrom, country)
    params.currentPeriodTo = getUTCIsoDate(params.currentPeriodTo, country)
    const response = yield call(
      getEnergyUsageComparisonForRentedBuilding,
      rentedBuildingId,
      params
    )

    yield put(actions.getEnergyUsageComparisonSuccess(response.data))
  } catch (e: any) {
    if (e?.response?.status) {
      yield put(
        actions.getEnergyUsageComparisonFail({
          ...e,
          status: e.response.status
        })
      )
    }
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export default function* energyUsageComparisonSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, energyUsageComparisonFlow)
}
