import { useTranslate } from "hooks/translate"
import { useState } from "react"
import DatePicker from "../DatePicker"
import { StyledDateRangePicker } from "./style"

export type DateRangePickerProps = {
  id: string
  to?: string | undefined
  from?: string | undefined
  orientation?: "row" | "column"
  endDate?: string
  updateDate: (date: string, name: string, id: string) => void
}

type Props = DateRangePickerProps

const DateRangePicker = ({
  id,
  to,
  from,
  orientation = "column",
  endDate,
  updateDate
}: Props) => {
  const { translate } = useTranslate()
  const [dates, setDates] = useState({ from, to })

  const handleBlur = (date: string, name: string) => {
    setDates({ ...dates, [name]: date })
    updateDate(date, name, id)
  }

  const closeDate = (date: string, name: string) => {
    setDates({ ...dates, [name]: date })
    updateDate(date, name, id)
  }

  return (
    <StyledDateRangePicker orientation={orientation}>
      <div data-testid="facility-managment.filter.from">
        <DatePicker
          id={`date-filter-${id}-from`}
          name="from"
          placeholder={translate("portal.filter.date-range-picker.placeholder")}
          subLabel={translate("portal.filter.date-range-picker.from")}
          inputDate={dates.from}
          endDate={dates.to}
          onClose={closeDate}
          onBlur={handleBlur}
        />
      </div>
      <div data-testid={`facility-managment.filter.to`}>
        <DatePicker
          id={`date-filter-${id}-to`}
          name="to"
          placeholder={translate("portal.filter.date-range-picker.placeholder")}
          subLabel={translate("portal.filter.date-range-picker.until")}
          inputDate={dates.to}
          startDate={dates.from}
          endDate={endDate}
          onClose={closeDate}
          onBlur={handleBlur}
        />
      </div>
    </StyledDateRangePicker>
  )
}

export default DateRangePicker
