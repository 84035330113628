import {
  InformationSection,
  InformationSectionContent,
  InformationSectionHeader
} from "@paudigital/wdp-components"
import InformationSectionTitle from "app/components/InformationSectionTitle"
import { format } from "date-fns"
import { useTranslate } from "hooks/translate"
import { StyledContainer, StyledDate, StyledDescription } from "./style"

type IndexingProps = {
  date?: string
}

type Props = IndexingProps

const Indexing = ({ date }: Props) => {
  const { translate } = useTranslate()

  if (!date) return <></>
  return (
    <InformationSection>
      <InformationSectionHeader>
        <InformationSectionTitle>
          {translate("portal.buildingpassport.contract.section.indexing.title")}
        </InformationSectionTitle>
      </InformationSectionHeader>
      <InformationSectionContent>
        <StyledContainer>
          <StyledDescription>
            {translate(
              "portal.buildingpassport.contract.section.indexing.description"
            )}
          </StyledDescription>
          <StyledDate>{format(new Date(date), "dd/MM/yyyy")}</StyledDate>
        </StyledContainer>
      </InformationSectionContent>
    </InformationSection>
  )
}

export default Indexing
