import { useEffect, useState } from "react"
import { debounce } from "lodash"

export function useScroll() {
  const bodyOffset = document.body.getBoundingClientRect()
  const [scroll, setScroll] = useState({
    x: bodyOffset.left,
    y: bodyOffset.top
  })

  const delay = 10

  const listener = () => {
    setScroll({ x: window.scrollX, y: window.scrollY })
  }

  useEffect(() => {
    const debounceWrapper = debounce(listener, delay)
    window.addEventListener("scroll", debounceWrapper)
    return () => {
      window.removeEventListener("scroll", debounceWrapper)
    }
  }, [])

  return {
    ...scroll
  }
}
