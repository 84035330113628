import Highcharts from 'highcharts'
import exporting from "highcharts/modules/exporting";
import { LegendContainer, LegendItemWrapper, StyledIcon, StyledSpan } from './style';

exporting(Highcharts);

export type LegendItem = {
  color: string,
  name: string,
  type: "circle" | "line"
}

type LegendProps = {
  items: LegendItem[]
}

const Legend = ({ items }: LegendProps) => {
  return (
    <LegendContainer>
      {items.map((item: LegendItem) => {
        return (
        <LegendItemWrapper key={item.name}>
          <StyledIcon type={item.type} lineColor={item.color} backgroundColor={item.color}/>
          <StyledSpan color={item.color}>{item.name}</StyledSpan>
        </LegendItemWrapper>)
      })}
    </LegendContainer>
  )
}

export default Legend
