import { useTranslate } from "hooks/translate"
import React from "react"
import Icon from "./icon"
import { getDocumentStatistics } from "app/redux/document/document.selectors"
import * as selectors from "app/redux/rentedBuildingContracts/rentedBuildingContracts.selector"
import { useSelector } from "react-redux"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { getRentedBuildingContracts } from "app/redux/rentedBuildingContracts/rentedBuildingContracts.actions"
import { getDocumentsStatistics } from "app/redux/document/document.actions"
import ModuleWidget from "app/components/ModuleWidget"
import WidgetCard from "./WidgetCard"
import { useApi } from "hooks/api"
import { StyledBaseCard } from "./style"
import { DocumentStatisticsDto } from "app/types/document"
import { DocumentTypes } from "app/enums/documentTypes"
import { usePermission } from "hooks/permission"
import { Modules } from "app/enums/modules"

const BuildingPassport = () => {
  const { call } = useApi()
  const { hasPermission } = usePermission()
  const rentedBuilding = useSelector(getActiveBuilding)
  const contractPermission =
    rentedBuilding && hasPermission(Modules.CONTRACTS, rentedBuilding!)
  const documentPermission =
    rentedBuilding && hasPermission(Modules.DOCUMENTS, rentedBuilding!)
  const plansPermission =
    rentedBuilding && hasPermission(Modules.PLANS, rentedBuilding!)
  const activeRentedBuilding = useSelector(getActiveBuilding)
  const statistics = useSelector(getDocumentStatistics)

  const images = statistics.find(
    (statistic: DocumentStatisticsDto) =>
      statistic.document_type === DocumentTypes.Pictures
  )
  const plans = statistics
    .find(
      (statistic: DocumentStatisticsDto) =>
        statistic.document_type === DocumentTypes.Contracts
    )
    ?.children.find(
      (statistic: DocumentStatisticsDto) =>
        statistic.document_type === DocumentTypes.Contracts_appendixes
    )
    ?.children.find(
      (statistic: DocumentStatisticsDto) =>
        statistic.document_type === DocumentTypes.Plans
    )

  const { translate } = useTranslate()
  const rentedBuildingContracts = useSelector(
    selectors.getRentedBuildingContracts
  )

  React.useEffect(() => {
    if (!activeRentedBuilding || !contractPermission) return
    call(getRentedBuildingContracts(activeRentedBuilding))
  }, [call, activeRentedBuilding, contractPermission])

  React.useEffect(() => {
    if (!activeRentedBuilding || !documentPermission) return
    call(getDocumentsStatistics(activeRentedBuilding))
  }, [call, activeRentedBuilding, documentPermission])

  return (
    <>
      <ModuleWidget
        permissionModule={Modules.BUILDING}
        title={translate("portal.dashboard.widget.buildingPassport.title")}
        description={translate(
          "portal.dashboard.widget.buildingPassport.guidance"
        )}
        image={<Icon />}
        url={`/building-passport/${activeRentedBuilding}`}
      >
        <>
          {contractPermission && (
            <StyledBaseCard actionPlacement="right" boxShadow="dropShadow">
              <WidgetCard
                docType="contract"
                contracts={rentedBuildingContracts}
              />
            </StyledBaseCard>
          )}
          <StyledBaseCard actionPlacement="right" boxShadow="dropShadow">
            <WidgetCard docType="technicalDetails" amount={0} />
          </StyledBaseCard>
          <>
            {plansPermission && (
              <StyledBaseCard actionPlacement="right" boxShadow="dropShadow">
                <WidgetCard
                  docType={DocumentTypes.Plans}
                  amount={plans ? plans.count : 0}
                />
              </StyledBaseCard>
            )}
            <StyledBaseCard actionPlacement="right" boxShadow="dropShadow">
              <WidgetCard
                docType={DocumentTypes.Pictures}
                amount={images ? images.count : 0}
              />
            </StyledBaseCard>
          </>
        </>
      </ModuleWidget>
    </>
  )
}

export default BuildingPassport
