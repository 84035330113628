import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const FiltersWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.neutral.lightGrey};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2.4rem;
  border-radius: 4px;
`

export const FilterTitle = styled.h3`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 1.2rem;
`
