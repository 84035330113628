import { Locales } from "app/i18n/config"

export enum Types {
  PATCH = "PATCH_LANGUAGEPREFERENCE",
  PATCH_SUCCESS = "PATCH_LANGUAGEPREFERENCE_SUCCESS",
  PATCH_FAIL = "PATCH_LANGUAGEPREFERENCE_FAIL"
}

export type Action =
  | PatchLanguagePreferenceAction
  | PatchLanguagePreferenceSuccessAction
  | PatchLanguagePreferenceFailAction

export type PatchLanguagePreferenceAction = {
  payload: { languagePreference: Locales }
  type: Types.PATCH
}

export type PatchLanguagePreferenceSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchLanguagePreferenceFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_FAIL
}

export function patchLanguagePreference(languagePreference: Locales): Action {
  return {
    type: Types.PATCH,
    payload: { languagePreference }
  }
}

export function patchLanguagePreferenceSuccess(): Action {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchLanguagePreferenceFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}
