import { SpecificationExplanation } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import {
  SpecificationItemLabel,
  SpecificationItemContent,
  SpecificationItemContainer,
} from "./style"

type SpecificationItemProps = {
  label: string
  children?: JSX.Element | JSX.Element[] | string
}

const SpecificationItem = ({ label, children }: SpecificationItemProps) => {
  const { translate, hasTranslations } = useTranslate()
  const labelExplanation = `${label}.explanation`
  return (
    <SpecificationItemContainer>
      <div>
        {hasTranslations(labelExplanation) ? (
          <SpecificationExplanation value={translate(labelExplanation)}>
            <SpecificationItemLabel hasExplanation>
              {translate(label)}
            </SpecificationItemLabel>
          </SpecificationExplanation>
        ) : (
          <SpecificationItemLabel>{translate(label)}</SpecificationItemLabel>
        )}
      </div>
      <SpecificationItemContent>{children}</SpecificationItemContent>
    </SpecificationItemContainer>
  )
}

export default SpecificationItem
