import {
  InviteContactPerson,
  RentedBuildingContactRoles
} from "app/types/contactPerson"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  initialValues,
  removeBuildingsWithoutRolesFromContactPerson,
  validationSchema,
  getDomain
} from "./config"
import * as actions from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.actions"
import { PossibleContactRoles } from "app/enums/roles"
import {
  ButtonWrapper,
  DropDownWrapper,
  StyledFormWrapper,
  ModalTitle,
  Container,
  StyledButton
} from "./style"
import * as contactActions from "app/redux/contactPerson/contactPerson.actions"
import * as contactSelectors from "app/redux/contactPerson/contactPerson.selectors"
import * as selectors from "app/redux/me/me.selectors"
import * as rentedBuildingContactPersonSelectors from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import MultipleRentedBuildings from "./MultipleRentedBuilding"
import SingleRentedBuilding from "./SingleRentedBuilding"
import { InputElement, EmailAutoComplete } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import AppEventsProvider from "app/components/AppEventsProvider"
import * as contactPersonContactRolesActions from "app/redux/contactPersonContactRoles/contactPersonContactRoles.actions"
import * as contactPersonContactRolesSelectors from "app/redux/contactPersonContactRoles/contactPersonContactRoles.selectors"
import * as meSelectors from "app/redux/me/me.selectors"
import { useApi } from "hooks/api"
import LanguageSelect from "app/components/LanguageSelect"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { Locales } from "app/i18n/config"
import { useRentedBuilding } from "hooks/rentedBuilding"

type Props = {
  id?: string
  onClose: () => void
}

const AddColleagueToBuilding = ({ id, onClose }: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const { rentedBuilding } = useRentedBuilding()
  const preferredLanguage = useSelector(selectors.getPreferredLanguage)
  const hasSingleRentedBuilding = useSelector(selectors.hasSingleRentedBuilding)
  const contactPersons = useSelector(contactSelectors.getContactPerson)
  const [isEditing, setIsEditing] = useState(id ? true : false)
  const contactPersonContactRoles = useSelector(
    contactPersonContactRolesSelectors.getContactPersonContactRoles
  )
  const isLoading = useSelector(rentedBuildingContactPersonSelectors.isLoading)
  const user: InviteContactPerson | undefined = useSelector(
    rentedBuildingContactPersonSelectors.getContactPersonPerRentedBuilding(id)
  )
  const currentUser = useSelector(meSelectors.getProfile)
  const isSelf = currentUser?.id === id
  const [searchValue, setSearchValue] = React.useState("")
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    dirty,
    values,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: user
      ? {
          ...user,
          languagePreference: user.languagePreference ?? preferredLanguage
        }
      : { ...initialValues, languagePreference: preferredLanguage as Locales },
    onSubmit: (c: InviteContactPerson) => {
      if (isValid) {
        const user = removeBuildingsWithoutRolesFromContactPerson(c)
        call(
          isEditing
            ? actions.putContactPerson({
                ...user,
                contactRolesPerRentedBuilding: [
                  ...user.contactRolesPerRentedBuilding,
                  ...contactPersonContactRoles
                ]
              })
            : actions.postContactPerson(user)
        )
        if (!isEditing) {
          sessionStorage.setItem("colleaguesInvited", "true")
        }
      }
    },
    validationSchema
  })

  useEffect(() => {
    if (id && rentedBuilding?.id) {
      call(
        contactPersonContactRolesActions.getContactPersonContactRoles(
          rentedBuilding.id,
          id
        )
      )
    }
  }, [id, call, rentedBuilding?.id])

  const {
    email,
    firstName,
    lastName,
    contactRolesPerRentedBuilding,
    languagePreference
  } = values
  const hasErrors = (name: string): boolean => {
    return errors[name as keyof InviteContactPerson] &&
      touched[name as keyof InviteContactPerson]
      ? true
      : false
  }

  const handleCheckboxChange = (
    val: boolean,
    name: string,
    rentedBuildingId: string
  ) => {
    const existingRentedBuilding = contactRolesPerRentedBuilding.find(
      (c: RentedBuildingContactRoles) => c.rentedBuildingId === rentedBuildingId
    )
    if (existingRentedBuilding) {
      const newRoles = val
        ? [...existingRentedBuilding.roles, name as PossibleContactRoles]
        : existingRentedBuilding.roles.filter(
            (r: PossibleContactRoles) => r !== name
          )

      const filteredRentedBuildings = contactRolesPerRentedBuilding.filter(
        c => c.rentedBuildingId !== existingRentedBuilding.rentedBuildingId
      )

      setFieldValue("contactRolesPerRentedBuilding", [
        ...filteredRentedBuildings,
        {
          ...existingRentedBuilding,
          roles: newRoles
        }
      ])
    } else {
      setFieldValue("contactRolesPerRentedBuilding", [
        ...contactRolesPerRentedBuilding,
        {
          rentedBuildingId: rentedBuildingId,
          roles: [name]
        }
      ])
    }
  }

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const email: string = e.target.value
    setFieldTouched("email", true, true)
    handleBlur(e)
    setFieldValue("email", email)
    const contactPerson = contactPersons.find(c => c.email === email)
    if (contactPerson && rentedBuilding) {
      setIsEditing(true)
      call(
        contactPersonContactRolesActions.getContactPersonContactRoles(
          rentedBuilding.id,
          contactPerson.id
        )
      )
      setFieldValue("firstName", contactPerson.firstName)
      setFieldValue("lastName", contactPerson.lastName)
    }
  }

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
    if (searchValue) setFieldValue("email", searchValue)
  }
  React.useEffect(() => {
    if (!user) {
      setFieldValue("contactRolesPerRentedBuilding", contactPersonContactRoles)
    }
  }, [contactPersonContactRoles, setFieldValue, user])

  React.useEffect(() => {
    call(contactPersonContactRolesActions.clearContactPersonContactRoles())
  }, [call])

  React.useEffect(() => {
    if (searchValue && rentedBuilding?.entityId) {
      call(
        contactActions.getContactPerson(rentedBuilding.entityId, searchValue)
      )
    }
  }, [call, searchValue, rentedBuilding?.entityId])

  const handleCustomBlur = (e: React.FocusEvent<any>) => {
    setFieldValue(e.target.name, e.currentTarget.value)
    handleBlur(e)
  }

  const handleLanguageChange = (option: OnChangeValue<CustomOptionType, false>) => {
    const lang = (option as { value: string }).value
    setFieldValue("languagePreference", lang)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <StyledFormWrapper>
      <Container>
        <ModalTitle>
          {translate(
            `portal.admin.add.colleague.${isEditing ? "edit" : "add"}`
          )}
        </ModalTitle>
        <AppEventsProvider local />
        <form onSubmit={handleSubmit} noValidate>
          <DropDownWrapper>
            <EmailAutoComplete
              disabled={id ? true : false}
              width="100%"
              name="email"
              label={translate("portal.contactPerson.input.email.label")}
              options={contactPersons.map(c => c.email)}
              onChange={handleSearch}
              onBlur={handleEmailBlur}
              error={hasErrors("email")}
              errorMessage={
                errors.email &&
                (errors.email !==
                "portal.contactPerson.input.email.domain.error"
                  ? translate(errors.email)
                  : `${getDomain(email)} ${translate(errors.email)}`)
              }
              placeholder={id ? email : ""}
            />
          </DropDownWrapper>
          <InputElement
            label={translate("portal.contactPerson.input.firstName.label")}
            name="firstName"
            onChange={handleChange}
            onBlur={handleCustomBlur}
            value={firstName}
            error={hasErrors("firstName")}
            errorMessage={translate("portal.input.required")}
            dataTestId="portal.contactPerson.input.firstName.label"
          />
          <InputElement
            label={translate("portal.contactPerson.input.lastName.label")}
            name="lastName"
            onChange={handleChange}
            onBlur={handleCustomBlur}
            value={lastName}
            error={hasErrors("lastName")}
            errorMessage={translate("portal.input.required")}
            dataTestId="portal.contactPerson.input.lastName.label"
          />
          <LanguageSelect
            defaultLanguage={languagePreference}
            label={translate("portal.contactPerson.language.select.label")}
            isPreferredLanguage={false}
            onChange={handleLanguageChange}
          />
          {hasSingleRentedBuilding ? (
            <SingleRentedBuilding
              error={hasErrors("contactRolesPerRentedBuilding")}
              data={contactRolesPerRentedBuilding}
              onChange={handleCheckboxChange}
              isSelf={isSelf}
            />
          ) : (
            <MultipleRentedBuildings
              error={hasErrors("contactRolesPerRentedBuilding")}
              data={contactRolesPerRentedBuilding}
              onChange={handleCheckboxChange}
              isEditing={isEditing}
              isSelf={isSelf}
              id={id}
            />
          )}
          <ButtonWrapper>
            <StyledButton
              type="secondary"
              onClick={handleCancel}
              label={translate("portal.admin.add.colleague.cancel")}
            />
            <StyledButton
              dataTestId="portal.admin.add.colleague.save"
              label={translate("portal.admin.add.colleague.save")}
              disabled={isLoading || !dirty || !isValid}
              submit
            />
          </ButtonWrapper>
        </form>
      </Container>
    </StyledFormWrapper>
  )
}

export default AddColleagueToBuilding
