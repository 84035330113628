import { DropDown } from "@paudigital/wdp-components"
import { IntervalEnum } from "app/enums/interval"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { OnChangeValue } from "react-select"
import { useTranslate } from "hooks/translate"
import { differenceInCalendarDays } from "date-fns"
import { useEffect, useMemo } from "react"

type Props = {
  interval: IntervalEnum
  onIntervalChange: (interval: IntervalEnum) => void
  from: string
  to: string
}

export const GranularityDropdown = ({ interval, onIntervalChange, from, to }: Props) => {
  const { translate } = useTranslate()

  const handleChange = (o: OnChangeValue<CustomOptionType, false>) => {
    const value = (o as CustomOptionType).value as IntervalEnum
    onIntervalChange(value)
  }

  const options = useMemo(() => {
    const days = differenceInCalendarDays(new Date(to), new Date(from))
    if (isNaN(days)) return [IntervalEnum.FIFTEENMIN, IntervalEnum.HOUR, IntervalEnum.DAY, IntervalEnum.WEEK, IntervalEnum.MONTH]
    if (days < 7) return [IntervalEnum.FIFTEENMIN, IntervalEnum.HOUR, IntervalEnum.DAY]
    else if (days >= 7 && days < 30) return [IntervalEnum.HOUR, IntervalEnum.DAY]
    else if (days >= 30 && days < 90) return [IntervalEnum.DAY, IntervalEnum.WEEK]
    else if (days >= 90 && days < 365) return [IntervalEnum.DAY, IntervalEnum.WEEK, IntervalEnum.MONTH]
    else return [IntervalEnum.WEEK, IntervalEnum.MONTH]
  }, [from, to])

  const defaultOption = useMemo(() => {
    if(options.includes(interval))
      return interval
    else return options[options.length - 1]
  }, [interval, options])

  useEffect(() => {
    if(options && !options.includes(interval)) {
      onIntervalChange(options[options.length -1])
    }
  }, [interval, onIntervalChange, options])

  return (
    <DropDown
      key={defaultOption}
      options={options.map(o => ({
        label: translate(`portal.energy.granularity.option.${o}`),
        value: o
      }))}
      onChange={handleChange}
      hasDropdownIndicator
      defaultValue={translate(`portal.energy.granularity.option.${defaultOption}`)}
    />
  )
}

export default GranularityDropdown
