import { Action, Types } from "./faq.actions"

export type FaqState = {
  errors: object
  loading: boolean
  data?: string
}

const initialState: FaqState = {
  errors: {},
  loading: false,
  data: undefined
}

export default (state = initialState, action: Action): FaqState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
