import React from "react"
import {
  StyledCardHeader,
  TitleWrapper,
  StyledH3,
  StyledButton,
  OptionsWrapper,
  HiddenEmailAnchor
} from "./style"
import TimeLeft from "./TimeLeft"
import GranularityDropdown from "../../../../GranularityDropdown"
import { useDispatch, useSelector } from "react-redux"
import { setGraphInterval } from "app/redux/energyPeriod/energyPeriod.actions"
import { Graphs } from "app/utils/graphs/graph.utils"
import {
  getEnergyPeriodRange,
  getIntervalOfGraph
} from "app/redux/energyPeriod/energyPeriod.selectors"
import { selectAvailableMetersByTag } from "app/redux/graphs/graphs.selectors"
import { IntervalEnum } from "app/enums/interval"
import { ExportIcon, ErrorIcon, theme } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { getAppEvents } from "app/redux/appEvents/appEvents.selectors"
import * as actions from "app/redux/energyCSV/energyCSV.actions"
import { TagEnum } from "app/redux/graphs/graphs.actions"
import {
  getBuildingById,
  getFullName,
  getUserCompanyName
} from "app/redux/me/me.selectors"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { dateFormatShort } from "app/utils/date.utils"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"
type CardHeaderProps = {
  title: string
  lastUpdated?: Date
  graphType: Graphs
  refreshGraph: () => void
}

type Props = CardHeaderProps

const CardHeader = ({ title, lastUpdated, graphType, refreshGraph }: Props) => {
  const { translate } = useTranslate()
  const appEvents = useSelector(getAppEvents)
  const dispatch = useDispatch()

  const ref = React.createRef<HTMLAnchorElement>()

  const graphInterval = useSelector(getIntervalOfGraph(graphType))
  const energyPeriod = useSelector(getEnergyPeriodRange)
  const { from, to } = energyPeriod
  const fullName = useSelector(getFullName)
  const userCompanyName = useSelector(getUserCompanyName)
  const activeBuilding = useSelector(getActiveBuilding)
  const getAvailableMetersByTag = useSelector(selectAvailableMetersByTag)
  const rentedBuilding = useSelector(getBuildingById(activeBuilding))

  const email = translate("portal.contact.productowner.email")
  const subject = translate("portal.energy.report.problem.subject")
  const granularity = translate(
    `portal.energy.granularity.option.${graphInterval}`
  )

  const onChangeGranularity = React.useCallback(
    (interval: IntervalEnum) => {
      dispatch(setGraphInterval(graphType, interval))
    },
    [dispatch, graphType]
  )

  const getSelectedMeters = (): TagEnum[] => {
    const electricityInjection = getAvailableMetersByTag(
      TagEnum.ELECTRICITY_YIELD_INJECTION
    )

    const electricityConsumption = getAvailableMetersByTag(
      TagEnum.ELECTRICITY_CONSUMPTION
    )
    const waterConsumption = getAvailableMetersByTag(TagEnum.WATER_MAIN)
    const gasConsumption = getAvailableMetersByTag(TagEnum.GAS_MAIN)

    if (
      graphType === Graphs.SOLAR_PRODUCTION &&
      electricityInjection &&
      electricityConsumption
    ) {
      return [electricityInjection.tag, electricityConsumption.tag]
    }

    if (
      graphType === Graphs.ELECTRICITY_CONSUMPTION &&
      electricityConsumption
    ) {
      const tags = [electricityConsumption.tag]
      if (electricityInjection) {
        return [...tags, electricityInjection.tag]
      }
      return tags
    }

    if (graphType === Graphs.WATER_CONSUMPTION && waterConsumption) {
      return [waterConsumption.tag]
    }

    if (graphType === Graphs.GAS_CONSUMPTION && gasConsumption) {
      return [gasConsumption.tag]
    }

    return []
  }

  const exportData = () => {
    if (appEvents.length > 0) return
    dispatch(actions.setSelectedMeter(getSelectedMeters()))
    dispatch(
      fire({
        eventName: PossibleAppEvents.CSV_ENERGY,
        uniqueIdentifier: "csv-energy"
      })
    )
  }

  const handleButtonClick = () => {
    dispatch(createGAEvent(PossibleGAEvents.ENERGY_ISSUE, { Modal: graphType }))
    ref.current?.click()
  }

  return (
    <StyledCardHeader>
      <TitleWrapper>
        <StyledH3>{title}</StyledH3>
        <TimeLeft lastUpdated={lastUpdated} refreshGraph={refreshGraph} />
      </TitleWrapper>
      <OptionsWrapper>
        <GranularityDropdown
          selectedGranularity={graphInterval}
          onChange={onChangeGranularity}
        />
        <StyledButton
          startIcon={<ExportIcon />}
          type="secondary"
          onClick={exportData}
          label={translate("portal.energy.export.button")}
        />
        <StyledButton
          startIcon={<ErrorIcon fill={theme.colors.green.contrast} />}
          type="secondary"
          onClick={handleButtonClick}
          label={translate("portal.energy.report.issue.button")}
        />
        <HiddenEmailAnchor
          ref={ref}
          href={`mailto:${email}?subject=${subject}&body=“User:” ${fullName}%0D%0A“Customer:” ${userCompanyName}%0D%0A”Location:” ${
            rentedBuilding!.city
          } - ${rentedBuilding!.street} ${
            rentedBuilding!.houseNo
          }%0D%0A”Graph:” ${title}%0D%0A”Selected period:” ${dateFormatShort(
            from
          )} - ${dateFormatShort(
            to
          )}%0D%0A”Granularity:” ${granularity}%0D%0A”URL:” ${escape(
            window.location.toString()
          )}`}
        />
      </OptionsWrapper>
    </StyledCardHeader>
  )
}

export default CardHeader
