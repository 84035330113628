import { passedAllRequirements } from "@paudigital/wdp-components"
import { object, string } from "yup"

export type PasswordValue = {
  newPassword: string
}

export const validationSchema = object({
  newPassword: string()
    .required("error.required")
    .test("passwordStrength", "error.password.requirements", value => {
      return passedAllRequirements(value || "")
    })
})

export const initialValues = {
  newPassword: "",
}
