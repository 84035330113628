import { StyledTitle, StyledDescription, StyledHeader } from "./style"

type Props = {
  title: string
  description?: string
}

const TimelineHeader = ({ title, description }: Props) => {
  return (
    <StyledHeader>
      <StyledTitle>{title}</StyledTitle>
      {description && <StyledDescription>{description}</StyledDescription>}
    </StyledHeader>
  )
}

export default TimelineHeader
