export enum Types {
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"
}

export enum PasswordUpdateType {
  PASSWORD_CHANGE = "PASSWORD_CHANGE",
  PASSWORD_CHANGE_90 = "PASSWORD_CHANGE_90"
}

export type Action =
  | UpdatePasswordAction
  | UpdatePasswordSuccessAction
  | UpdatePasswordFailAction

export type UpdatePasswordAction = {
  type: Types.UPDATE_PASSWORD
  payload: {
    newPassword: string
    type: PasswordUpdateType
  }
}

export type UpdatePasswordSuccessAction = {
  type: Types.UPDATE_PASSWORD_SUCCESS
}

export type UpdatePasswordFailAction = {
  type: Types.UPDATE_PASSWORD_FAIL
}

export function updatePassword(
  newPassword: string,
  type: PasswordUpdateType
): UpdatePasswordAction {
  return {
    type: Types.UPDATE_PASSWORD,
    payload: { newPassword, type }
  }
}

export function updatePasswordSuccess(): UpdatePasswordSuccessAction {
  return {
    type: Types.UPDATE_PASSWORD_SUCCESS
  }
}

export function updatePasswordFail(): UpdatePasswordFailAction {
  return {
    type: Types.UPDATE_PASSWORD_FAIL
  }
}
