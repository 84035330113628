import * as api from "app/api/buildingSpecification"
import { BuildingSpecification } from "app/types/buildingSpecification"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./buildingSpecifications.actions"
import { getActiveBuilding } from "../buildingSwitcher/buildingSwitcher.selectors"

export function* getBuildingSpecificationFlow(): SagaIterator {
  try {
    const rentedBuildingId = yield select(getActiveBuilding)
    const response = yield call(api.getBuildingSpecification, rentedBuildingId)
    const data: BuildingSpecification = response.data
    yield put(actions.getBuildingSpecificationSuccess(data))
  } catch (e: any) {
    yield put(actions.getBuildingSpecificationFail(e))
  }
}

export default function* buildingSpecificationSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getBuildingSpecificationFlow)
}
