import { useAuth0 } from "@auth0/auth0-react"
import { LoginIcon, Page, Link } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { StyledLoggedOut, LoggedOutTitle, LoggedOutBody } from "./style"
const LoggedOut = () => {
  const { loginWithRedirect } = useAuth0()
  const image = <LoginIcon width="241" height="167" />
  const { translate } = useTranslate()

  const handleClick = () => {
    loginWithRedirect()
  }
  return (
    <Page image={image}>
      <StyledLoggedOut>
        <LoggedOutTitle>
          {translate("portal.logout.success.title")}
        </LoggedOutTitle>
        <LoggedOutBody>
          {translate("portal.logout.success.message")}
        </LoggedOutBody>
        <LoggedOutBody>
          {translate("portal.logout.success.login.cta")}&nbsp;
          <Link
            type="default"
            value={translate("portal.logout.success.login.page.link")}
            onClick={handleClick}
          />
        </LoggedOutBody>
      </StyledLoggedOut>
    </Page>
  )
}

export default LoggedOut
