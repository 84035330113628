import { Link, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  background: ${theme.colors.neutral.white};
  color: ${theme.colors.neutral.darkerGrey};
  border-radius: 4px;
  margin-top: 4.8rem;
`

export const StyledLink = styled(Link)`
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 4.8rem;
`

export const StyledList = styled.ul`
  font-size: ${theme.fontSizes.captionSmall};
  margin: 1.2rem 0 4.8rem 0;
  list-style: disc;
`

export const CurrentUrl = styled.div`
  font-size: ${theme.fontSizes.captionSmall};
  background: ${theme.colors.neutral.lightGrey};
  border-radius: 4px;
  width: 100%;
  max-width: 40rem;
  display: flex;
  align-items: center;
  margin: 2.4rem 0;

  span {
    margin: 0 1.6rem;
  }
`

export const ImageWrapper = styled.div`
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
`

export const ListHeader = styled.h3`
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  width: 100%;
  max-width: 40rem;
`

export const Title = styled.h2``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2.4rem;
`
