import { QueryParams } from "app/redux/ticket/ticket.actions"
import { TicketsForRentedBuilding, PostTicket } from "app/types/ticket"
import { TicketCommunicationPayload } from "app/types/ticketCommunication"
import { AxiosPromise } from "axios"
import { Filters } from "routes/FacilityManagement"
import { rentalApi } from "./api"

export const postTicket = (
  rentedBuildingId: string,
  data: PostTicket
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/tickets`, {
    method: "POST",
    data
  })
}

export const getTicketsById = (id: string): AxiosPromise => {
  return rentalApi(`/tickets/${id}`, {
    responseType: "blob",
    method: "GET"
  })
}

export const postTicketAttachments = ({
  rentedBuildingId,
  id,
  data
}: {
  rentedBuildingId: string
  id: string
  data: FormData
}): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/tickets/${id}`, {
    method: "POST",
    data,
    headers: { "Content-Type": "multipart/form-data" }
  })
}

export const getTicketSummary = (id: string): AxiosPromise => {
  return rentalApi(`/rented_buildings/${id}/tickets_summary`, {
    method: "GET"
  })
}

export const getTicketsForRentedBuilding = (
  data: QueryParams
): AxiosPromise<TicketsForRentedBuilding> => {
  return rentalApi(`/rented_buildings/${data.rentedBuildingId}/tickets`, {
    method: "GET",
    params: { ...data }
  })
}

export const getTicketAttachments = (
  rentedBuildingId: string,
  ticketId: string
): AxiosPromise => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/tickets/${ticketId}`,
    {
      responseType: "blob",
      method: "GET"
    }
  )
}

export const patchTicketCommunication = (
  payload: TicketCommunicationPayload
) => {
  const { data, id, rentedBuildingId } = payload
  return rentalApi(`/rented_buildings/${rentedBuildingId}/tickets/${id}`, {
    method: "PATCH",
    data
  })
}

export const cancelTicket = (rentedBuildingId: string, ticketId: string) => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/tickets/${ticketId}/cancel`,
    {
      method: "PATCH"
    }
  )
}

export const downloadTicketsCSV = (
  data: Filters,
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/tickets.csv`, {
    method: "GET",
    params: { ...data }
  })
}
