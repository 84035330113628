import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const BuildingName = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  text-align: center;
  word-break: break-all;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 2px ${theme.colors.neutral.black};

  span,
  h5 {
    color: ${theme.colors.neutral.white};
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    position: static;
    margin: 0 auto 1.6rem;
    transform: none;
    text-shadow: none;

    span  {
      color: ${theme.colors.oceanGreen.prc125};
    }
    h5 {
      color: ${theme.colors.neutral.darkerGrey};
    }
  }
`

export const BuildingCityAddress = styled.span`
  font-size: ${theme.fontSizes.h5};
  line-height: 2.6rem;
`

export const BuildingStreetAddress = styled.span`
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: 1.8rem;
  color: ${theme.colors.oceanGreen.prc125};
  text-transform: uppercase;
`
