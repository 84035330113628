import { SagaIterator } from "redux-saga"
import { put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./registrationWizard.actions"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import { getStep } from "./registrationWizard.selectors"
import {
  hasSingleRentedBuilding,
  isAdminInRentedBuilding,
  getPreferredLanguage
} from "../me/me.selectors"
import { intl, Locales } from "app/i18n/config"

export function* changeWizardFlow(): SagaIterator {
  try {
    let config = ["portal.route.title.register.profile"]
    const step = yield select(getStep)
    const singleRentedBuilding = yield select(hasSingleRentedBuilding)
    const isAdmin = yield select(isAdminInRentedBuilding)
    const languagePreference: Locales = yield select(getPreferredLanguage)
    if (!singleRentedBuilding) {
      config.push("portal.route.title.register.building")
    }
    config = [
      ...config,
      "portal.route.title.register.communication",
      "portal.route.title.register.responsibilites"
    ]
    if (isAdmin) config.push("portal.route.title.register.colleagues")
    document.title = intl[languagePreference].formatMessage({
      id: config[step - 1]
    })
    yield put(
      createGAEvent(PossibleGAEvents.PAGE_VIEW, {
        page_title: intl[languagePreference].formatMessage({
          id: config[step - 1]
        })
      })
    )
  } catch (e: any) {
    console.warn(e)
  }
}

export default function* registrationWizardSaga(): SagaIterator {
  yield takeLatest(
    [actions.Types.INCREASE, actions.Types.DECREASE],
    changeWizardFlow
  )
}
