import { PossibleResponsibilities } from "app/enums/responsibilities"
import { PossibleContactRoles } from "app/enums/roles"
import { RetrieveNotificationSettingsResult } from "./notificationSettings"

export enum NoEnergyReasons {
  INSTALLATION_ONGOING = "installation_ongoing",
  MULTI_TENANT_SITE = "multi_tenant_site",
  METERS_INSTALLED_BUT_NO_SIGNAL = "meters_installed_but_no_signal",
  NO_ACCESS_TO_METERS = "no_access_to_meters",
  NANOGRID_READY = "nanogrid_ready"
}

export type RentedBuilding = {
  infoNeeded: string,
  inProgress: string,
  awaitingProcessing: string,
  buildingId: string,
  contactPersonId?: string
  id: string
  country: string
  city: string
  postalCode: string
  entityId: string
  street: string
  houseNo: string
  entityName: string
  roles: PossibleContactRoles[]
  responsibilities: PossibleResponsibilities[]
  notifications: RetrieveNotificationSettingsResult
  endDate: string
  maxSeatCount: number
  noEnergyReason: NoEnergyReasons
  industrialParkId?: string | null
}

