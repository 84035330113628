import { NavigationList } from "@paudigital/wdp-components"
import { getFaq } from "app/redux/faq/faq.actions"
import { getFaqTokens } from "app/redux/faq/faq.selectors"
import { getPreferredLanguage } from "app/redux/me/me.selectors"
import { slugifyForAnchorTag } from "app/utils/slug.utils"
import MarkdownIt from "markdown-it"
import markdownItAnchor from "markdown-it-anchor"
import Token from "markdown-it/lib/token"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Section from "../Section"
import { Container, Content, SideBar } from "./style"

const Faq = () => {
  const md = new MarkdownIt({ html: true }).use(markdownItAnchor, {
    level: 2,
    slugify: slugifyForAnchorTag
  })
  const dispatch = useDispatch()
  const language = useSelector(getPreferredLanguage)
  const data = useSelector(getFaqTokens)

  useEffect(() => {
    dispatch(getFaq(language))
  }, [dispatch, language])

  useEffect(() => {
    const hash = window.location.hash
    if (!hash) return
    const element = document.querySelector(`${hash}`)
    if (!element) return
    element.scrollIntoView({ behavior: "smooth" })
  })

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const link = e.target as HTMLAnchorElement
    const hash = link.href.split("#").pop() as string
    if (!hash) return
    const element = document.querySelector(`#${hash}`)
    if (!element) return

    element.scrollIntoView({ behavior: "smooth" })
    window.location.hash = hash
  }

  const navigationItems = useMemo(() => {
    if (!data) return []
    const tokens = md.parse(data, {})
    const headingTokens = tokens.reduce(
      (result: Token[], token: Token, index: number) => {
        if (token.tag === "h2" && token.type === "heading_open") {
          return (result = [...result, tokens[index + 1]])
        }
        return result
      },
      []
    )

    return headingTokens.map(token => {
      return {
        label: token.content,
        to: "#" + slugifyForAnchorTag(token.content),
        onClick: handleClick
      }
    })
  }, [data, md])

  return (
    <Container>
      <Content data-testid='help-content'>
        <Section>
          <>
            {data && (
              <div
                dangerouslySetInnerHTML={{
                  __html: md.render(data)
                }}
              ></div>
            )}
          </>
        </Section>
      </Content>
      <SideBar data-testid='help-side-bar'>
        <NavigationList items={navigationItems} />
      </SideBar>
    </Container>
  )
}

export default Faq
