import {
  getRentedBuildingContracts,
  getRentedBuildingContractNumbers
} from "app/api/rentedBuilding"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./rentedBuildingContracts.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getRentedBuildingContractsFlow(
  action: actions.GetRentedBuildingContractsAction
): SagaIterator {
  try {
    const { rentedBuildingId } = action.payload
    const response = yield call(getRentedBuildingContracts, rentedBuildingId)
    yield put(actions.getRentedBuildingContractsSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getRentedBuildingContractsFail(e))
  }
}

export function* getRentedBuildingContractNumbersFlow(
  action: actions.GetRentedBuildingContractNumbersAction
): SagaIterator {
  try {
    const { rentedBuildingId } = action.payload
    const response = yield call(
      getRentedBuildingContractNumbers,
      rentedBuildingId
    )
    yield put(actions.getRentedBuildingContractNumbersSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getRentedBuildingContractNumbersFail(e))
  }
}

export default function* RentedBuildingContractsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getRentedBuildingContractsFlow)
  yield takeLatest(
    actions.Types.GET_CONTRACT_NUMBERS,
    getRentedBuildingContractNumbersFlow
  )
}
