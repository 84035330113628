import {
  GetDocumentsCSVParams,
  GetDocumentParams
} from "app/redux/document/document.actions"
import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getDocuments = (data: GetDocumentParams): AxiosPromise => {
  return rentalApi(`/rented_buildings/${data.rentedBuildingId}/documents`, {
    method: "GET",
    params: { ...data }
  })
}

export const getDocumentsById = (id: string): AxiosPromise => {
  return rentalApi(`/documents/${id}`, {
    responseType: "blob",
    method: "GET"
  })
}

export const getDocumentsStatistics = (
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/documents_statistics`,
    {
      method: "GET"
    }
  )
}

export const getDocumentsSummary = (rentedBuildingId: string): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/documents_summary`, {
    method: "GET"
  })
}

export const getDocumentTypes = (rentedBuildingId: string): AxiosPromise => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/documents/uploadable`,
    {
      method: "GET"
    }
  )
}

export const postDocument = (
  data: FormData,
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/documents`, {
    method: "POST",
    data,
    headers: { "Content-Type": "multipart/form-data" }
  })
}

export const downloadDocumentsCSV = (
  data: GetDocumentsCSVParams,
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/documents.csv`, {
    method: "GET",
    params: { ...data }
  })
}
