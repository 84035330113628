import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 2.8rem 0px;
  grid-template-areas:
    "account"
    "preferences"
    "logout";

  @media only screen and (${theme.breakpoints.tablet}) {
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 2.8rem;
    grid-template-areas:
      "account preferences"
      "logout preferences";
  }
`
