import { Button, Password } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useFormik } from "formik"
import { validationSchema, PasswordValue, initialValues } from "./config"
import { ButtonContainer } from "./style"
import { useDispatch } from "react-redux"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { useEffect } from "react"
import { Action as ReduxAction } from "redux"

export type VerifyIdentityProps = {
  onSubmit: (
    currentPassord: string,
    actionOnSuccess: () => ReduxAction<any>
  ) => void
  actionOnSuccess: () => ReduxAction<any>
}

const PasswordVerification = ({
  onSubmit,
  actionOnSuccess
}: VerifyIdentityProps) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    dirty
  } = useFormik({
    initialValues,
    onSubmit: ({ currentPassword }: PasswordValue) => {
      isValid && onSubmit(currentPassword, actionOnSuccess)
    },
    validationSchema
  })

  useEffect(() => {
    return () => {
      dispatch(remove("authentication-error"))
    }
  }, [dispatch])

  const hasErrors = (name: string) => {
    return errors[name as keyof PasswordValue] &&
      touched[name as keyof PasswordValue]
      ? true
      : false
  }

  return (
    <form onSubmit={handleSubmit}>
      <Password
        create={false}
        label={translate("portal.authentication.password")}
        name="currentPassword"
        error={hasErrors("currentPassword")}
        onChange={handleChange}
        onBlur={handleBlur}
        defaultType="password"
      />
      <ButtonContainer>
        <Button
          label={translate("portal.authentication.button.continue")}
          disabled={!isValid || !dirty}
          submit
        />
      </ButtonContainer>
    </form>
  )
}

export default PasswordVerification
