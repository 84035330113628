import { useTranslate } from "hooks/translate"
import {
  DisclaimerText,
  DisclaimerSubTitle
} from "./style"

const DisclaimerTooltip = () => {
  const { translate } = useTranslate()
  return (
    <>
      <DisclaimerText>
        {translate(
          "portal.energy.comparison.co2.disclaimer.info.what.is.grid.power"
        )}
      </DisclaimerText>
      <DisclaimerSubTitle>
        {translate(
          "portal.energy.comparison.co2.disclaimer.info.grey.grid.power"
        )}
      </DisclaimerSubTitle>
      <DisclaimerText>
        {translate(
          "portal.energy.comparison.co2.disclaimer.info.what.is.grey.grid.power"
        )}
      </DisclaimerText>
      <DisclaimerSubTitle>
        {translate(
          "portal.energy.comparison.co2.disclaimer.info.green.grid.power"
        )}
      </DisclaimerSubTitle>
      <DisclaimerText>
        {translate(
          "portal.energy.comparison.co2.disclaimer.info.what.is.green.grid.power"
        )}
      </DisclaimerText>
      <DisclaimerText>
        {translate("portal.energy.comparison.co2.disclaimer.info.more.info")}
      </DisclaimerText>
    </>
  )
}

export default DisclaimerTooltip
