import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Content = styled.div`
  background: ${theme.colors.neutral.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4.8rem;
  text-align: center;

  @media only screen and (${theme.breakpoints.tablet}) {
    text-align: left;
    flex-direction: row;
    margin-top: 0;
    height: 10rem;
  }
`

export const DropdownWrapper = styled.div`
  max-width: 35rem;
`

export const Wrapper = styled.section`
  display: block;
  grid-area: contactfooter;
`

export const StyledImage = styled.img`
  border-radius: 50%;
  height: 8.8rem;
  width: 8.8rem;
  margin-top: -6.4rem;
  display: block;

  @media only screen and (${theme.breakpoints.tablet}) {
    height: 5.6rem;
    width: 5.6rem;
    margin-right: 1.6rem;
    margin-top: 0;
  }
`

export const ExpertName = styled.span`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};
`

export const ExpertDescription = styled.p`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
`

export const Expert = styled.div`
  margin-bottom: 2.4rem;
  max-width: 40rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

export const ContactButtonWrapper = styled.div`
  @media only screen and (${theme.breakpoints.tablet}) {
    margin-left: auto;
  }
`

export const Details = styled.div`
  margin: 2.4rem 2.4rem 0 2.4rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    margin: 0;
  }
`

export const Container = styled.div`
  width: 100%;
  margin: 2.4rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    align-center: center;
  }
`
