import { theme } from "@paudigital/wdp-components";
import styled from "styled-components";

export const Option = styled.div<{ selected?: boolean }>`
  height: 100%;
  max-height: 4.8rem;
  font-size: ${props =>
    props.selected
      ? theme.fontSizes.captionExtraSmall
      : theme.fontSizes.caption};

  & strong {
    font-weight: ${theme.fontWeights.bold};
  }
`
