const Icon = () => {
  return (
    <svg
      width="139"
      height="102"
      viewBox="0 0 139 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M135.112 63.161H30.1291C29.0982 63.1598 28.1099 62.7496 27.381 62.0205C26.652 61.2913 26.242 60.3027 26.2408 59.2715V4.13343C26.242 3.10225 26.652 2.11364 27.381 1.38448C28.1099 0.655328 29.0982 0.245179 30.1291 0.244019H135.112C136.143 0.245179 137.131 0.655328 137.86 1.38448C138.589 2.11364 138.999 3.10225 139 4.13343V59.2715C138.999 60.3027 138.589 61.2913 137.86 62.0205C137.131 62.7496 136.143 63.1598 135.112 63.161ZM30.1291 0.701596C29.2195 0.702626 28.3475 1.06452 27.7043 1.70789C27.0611 2.35126 26.6993 3.22357 26.6983 4.13343V59.2715C26.6993 60.1814 27.0611 61.0537 27.7043 61.6971C28.3475 62.3405 29.2195 62.7024 30.1291 62.7034H135.112C136.021 62.7024 136.893 62.3405 137.537 61.6971C138.18 61.0537 138.542 60.1814 138.543 59.2715V4.13343C138.542 3.22357 138.18 2.35126 137.537 1.70789C136.893 1.06452 136.021 0.702626 135.112 0.701596H30.1291Z"
        fill="#3F3D56"
      />
      <path
        d="M65.8094 48.5185H38.8204C37.7896 48.5173 36.8012 48.1072 36.0723 47.378C35.3434 46.6488 34.9333 45.6602 34.9322 44.6291V17.632C34.9334 16.6008 35.3434 15.6122 36.0723 14.883C36.8012 14.1539 37.7896 13.7437 38.8204 13.7426H65.8094C66.8403 13.7437 67.8286 14.1539 68.5576 14.883C69.2865 15.6122 69.6965 16.6008 69.6977 17.632V44.6291C69.6965 45.6602 69.2865 46.6488 68.5576 47.378C67.8286 48.1072 66.8403 48.5173 65.8094 48.5185ZM38.8204 14.2001C37.9108 14.2012 37.0388 14.5631 36.3956 15.2064C35.7524 15.8498 35.3907 16.7221 35.3896 17.632V44.6291C35.3907 45.5389 35.7524 46.4112 36.3956 47.0546C37.0388 47.698 37.9108 48.0599 38.8204 48.0609H65.8094C66.719 48.0599 67.5911 47.698 68.2342 47.0546C68.8774 46.4112 69.2392 45.5389 69.2402 44.6291V17.632C69.2392 16.7221 68.8774 15.8498 68.2342 15.2064C67.5911 14.5631 66.719 14.2012 65.8094 14.2001H38.8204Z"
        fill="#CCCCCC"
      />
      <path
        d="M132.364 28.385H80.4448C79.9595 28.385 79.4941 28.1922 79.151 27.849C78.8078 27.5057 78.6151 27.0402 78.6151 26.5547C78.6151 26.0693 78.8078 25.6038 79.151 25.2605C79.4941 24.9173 79.9595 24.7244 80.4448 24.7244H132.364C132.85 24.7244 133.315 24.9173 133.658 25.2605C134.001 25.6038 134.194 26.0693 134.194 26.5547C134.194 27.0402 134.001 27.5057 133.658 27.849C133.315 28.1922 132.85 28.385 132.364 28.385Z"
        fill="#CCCCCC"
      />
      <path
        d="M106.061 20.6062H80.4448C79.9595 20.6062 79.4941 20.4134 79.151 20.0701C78.8078 19.7269 78.6151 19.2613 78.6151 18.7759C78.6151 18.2905 78.8078 17.8249 79.151 17.4817C79.4941 17.1384 79.9595 16.9456 80.4448 16.9456H106.061C106.547 16.9456 107.012 17.1384 107.355 17.4817C107.698 17.8249 107.891 18.2905 107.891 18.7759C107.891 19.2613 107.698 19.7269 107.355 20.0701C107.012 20.4134 106.547 20.6062 106.061 20.6062Z"
        fill="#CCCCCC"
      />
      <path
        d="M132.364 43.7139H80.4448C79.9595 43.7139 79.4941 43.5211 79.151 43.1778C78.8078 42.8346 78.6151 42.369 78.6151 41.8836C78.6151 41.3982 78.8078 40.9326 79.151 40.5894C79.4941 40.2461 79.9595 40.0533 80.4448 40.0533H132.364C132.85 40.0533 133.315 40.2461 133.658 40.5894C134.001 40.9326 134.194 41.3982 134.194 41.8836C134.194 42.369 134.001 42.8346 133.658 43.1778C133.315 43.5211 132.85 43.7139 132.364 43.7139Z"
        fill="#CCCCCC"
      />
      <path
        d="M106.061 35.9351H80.4448C79.9595 35.9351 79.4941 35.7422 79.151 35.399C78.8078 35.0557 78.6151 34.5902 78.6151 34.1048C78.6151 33.6193 78.8078 33.1538 79.151 32.8105C79.4941 32.4673 79.9595 32.2744 80.4448 32.2744H106.061C106.547 32.2744 107.012 32.4673 107.355 32.8105C107.698 33.1538 107.891 33.6193 107.891 34.1048C107.891 34.5902 107.698 35.0557 107.355 35.399C107.012 35.7422 106.547 35.9351 106.061 35.9351Z"
        fill="#CCCCCC"
      />
      <path
        d="M68.4545 47.3861C68.4242 47.3862 68.3942 47.3802 68.3662 47.3685C68.3382 47.3568 68.3129 47.3396 68.2916 47.318L36.3584 14.9447C36.3373 14.9233 36.3206 14.898 36.3093 14.8701C36.298 14.8423 36.2923 14.8125 36.2925 14.7825C36.2927 14.7524 36.2988 14.7227 36.3105 14.6951C36.3222 14.6674 36.3392 14.6423 36.3606 14.6212C36.3819 14.6001 36.4073 14.5834 36.4351 14.5721C36.4629 14.5608 36.4927 14.5551 36.5227 14.5553C36.5528 14.5555 36.5825 14.5616 36.6101 14.5733C36.6378 14.585 36.6629 14.602 36.684 14.6234L68.6173 46.9967C68.649 47.0288 68.6705 47.0696 68.679 47.1139C68.6876 47.1582 68.6829 47.204 68.6655 47.2456C68.6481 47.2873 68.6188 47.3228 68.5812 47.3478C68.5437 47.3728 68.4996 47.3861 68.4545 47.3861Z"
        fill="#CCCCCC"
      />
      <path
        d="M36.1756 47.3862C36.1305 47.3862 36.0864 47.3728 36.0488 47.3478C36.0113 47.3228 35.9819 47.2873 35.9645 47.2457C35.9471 47.204 35.9424 47.1582 35.951 47.1139C35.9596 47.0696 35.9811 47.0288 36.0128 46.9967L67.945 14.6234C67.9661 14.602 67.9912 14.5849 68.0189 14.5732C68.0466 14.5614 68.0763 14.5553 68.1064 14.5551C68.1364 14.5548 68.1662 14.5605 68.1941 14.5718C68.222 14.5831 68.2473 14.5998 68.2687 14.6209C68.2901 14.6421 68.3072 14.6672 68.3188 14.6949C68.3305 14.7226 68.3366 14.7524 68.3368 14.7824C68.337 14.8125 68.3313 14.8423 68.3199 14.8702C68.3086 14.898 68.2919 14.9233 68.2707 14.9447L36.3384 47.318C36.3171 47.3396 36.2918 47.3568 36.2638 47.3685C36.2359 47.3802 36.2059 47.3862 36.1756 47.3862Z"
        fill="#CCCCCC"
      />
      <path
        d="M130.766 56.4117H116.128C115.188 56.4117 114.286 56.0381 113.621 55.373C112.956 54.708 112.583 53.806 112.583 52.8654C112.583 51.9249 112.956 51.0229 113.621 50.3579C114.286 49.6928 115.188 49.3192 116.128 49.3192H130.766C131.706 49.3192 132.608 49.6928 133.273 50.3579C133.938 51.0229 134.311 51.9249 134.311 52.8654C134.311 53.806 133.938 54.708 133.273 55.373C132.608 56.0381 131.706 56.4117 130.766 56.4117Z"
        fill="#EE730A"
      />
      <path
        d="M9.04317 101.978H117.624C117.72 101.978 117.811 101.954 117.879 101.911C117.947 101.868 117.985 101.81 117.985 101.749C117.985 101.688 117.947 101.63 117.879 101.587C117.811 101.544 117.72 101.52 117.624 101.52H9.04317C8.9475 101.52 8.85574 101.544 8.78808 101.587C8.72043 101.63 8.68243 101.688 8.68243 101.749C8.68243 101.81 8.72043 101.868 8.78808 101.911C8.85574 101.954 8.9475 101.978 9.04317 101.978Z"
        fill="#3F3D56"
      />
      <path
        d="M43.8941 99.3577L46.6983 99.3575L48.0322 88.5385L43.8937 88.5388L43.8941 99.3577Z"
        fill="#A0616A"
      />
      <path
        d="M52.106 101.962L43.2933 101.962L43.2931 98.5563L48.7009 98.5561C49.604 98.5561 50.47 98.9149 51.1086 99.5536C51.7472 100.192 52.106 101.059 52.106 101.962Z"
        fill="#2F2E41"
      />
      <path
        d="M23.5482 96.1803L26.0987 97.3458L31.8062 88.0592L28.0417 86.3394L23.5482 96.1803Z"
        fill="#A0616A"
      />
      <path
        d="M29.936 101.962L21.9199 98.2998L23.3345 95.2017L28.2535 97.4492C29.0749 97.8245 29.7137 98.5108 30.0292 99.3572C30.3447 100.204 30.3112 101.141 29.936 101.962L29.936 101.962Z"
        fill="#2F2E41"
      />
      <path
        d="M31.7142 62.8789C31.4455 62.6766 31.2245 62.4179 31.0666 62.121C30.9087 61.824 30.8178 61.496 30.8004 61.1602C30.7829 60.8243 30.8393 60.4886 30.9655 60.1769C31.0917 59.8652 31.2847 59.5849 31.531 59.3559L28.2347 51.8745L32.4223 52.5843L34.9105 59.58C35.2677 60.0192 35.4515 60.5743 35.4269 61.1401C35.4023 61.7058 35.1711 62.2429 34.7772 62.6495C34.3832 63.0561 33.8538 63.3041 33.2892 63.3464C32.7247 63.3886 32.1643 63.2223 31.7142 62.8789Z"
        fill="#A0616A"
      />
      <path
        d="M43.0503 95.5924L40.3601 72.0904C40.3384 71.9013 40.2501 71.7261 40.111 71.5962C39.9719 71.4664 39.7911 71.3903 39.601 71.3817C39.4109 71.3731 39.2239 71.4325 39.0737 71.5493C38.9234 71.666 38.8196 71.8325 38.781 72.0189L37.4473 78.4464C37.4285 78.5374 37.3974 78.6253 37.3549 78.7079L29.5481 93.8924C29.4331 94.1158 29.2404 94.2895 29.0063 94.3807C28.7721 94.4719 28.5128 94.4743 28.277 94.3875L25.5624 93.387C25.4344 93.3398 25.317 93.2677 25.2171 93.1747C25.1172 93.0817 25.0367 92.9698 24.9805 92.8454C24.9242 92.7211 24.8933 92.5868 24.8894 92.4503C24.8855 92.3139 24.9088 92.178 24.9579 92.0507L31.3129 75.5658C31.3466 75.4786 31.3647 75.3862 31.3664 75.2928L31.6911 58.0798C31.6959 57.8275 31.7932 57.5857 31.9645 57.4004C32.1358 57.2151 32.3691 57.0992 32.6202 57.0746L49.4849 55.4288C49.634 55.4143 49.7846 55.4324 49.926 55.4821C50.0674 55.5318 50.1963 55.6118 50.3036 55.7165C50.4109 55.8212 50.494 55.9481 50.5472 56.0883C50.6003 56.2284 50.6222 56.3785 50.6114 56.5281L47.7782 95.55C47.7585 95.8091 47.6419 96.0513 47.4516 96.2283C47.2614 96.4052 47.0115 96.504 46.7517 96.5049H44.0729C43.8202 96.505 43.5763 96.412 43.3877 96.2437C43.1991 96.0754 43.079 95.8436 43.0503 95.5924Z"
        fill="#2F2E41"
      />
      <path
        d="M42.7388 26.0168C45.8413 26.0168 48.3564 23.5009 48.3564 20.3975C48.3564 17.294 45.8413 14.7782 42.7388 14.7782C39.6363 14.7782 37.1212 17.294 37.1212 20.3975C37.1212 23.5009 39.6363 26.0168 42.7388 26.0168Z"
        fill="#A0616A"
      />
      <path
        d="M47.6073 29.0866C47.4535 29.0131 47.3008 28.9424 47.1493 28.8746H47.1492C45.3046 28.0445 43.2721 27.7214 41.2612 27.9386C39.2502 28.1558 37.3335 28.9055 35.7086 30.1104C32.7167 32.3395 31.2745 35.6956 31.752 39.3182C32.7585 46.9536 31.6062 57.311 31.4865 58.3426C40.7213 62.5903 48.8231 57.2498 49.5422 56.7535L47.6073 29.0866Z"
        fill="#E28637"
      />
      <path
        d="M31.2349 58.2442C31.0666 58.2175 30.9076 58.1493 30.7723 58.0458C30.6369 57.9423 30.5295 57.8067 30.4596 57.6513L25.7457 47.2036C25.5691 46.8127 25.4911 46.3845 25.5186 45.9564C25.5461 45.5284 25.6782 45.1136 25.9034 44.7486L31.6922 35.3528C31.8744 35.0144 32.1548 34.7392 32.4965 34.5635C32.8383 34.3877 33.2252 34.3197 33.6064 34.3684C33.9875 34.4171 34.3449 34.5803 34.6315 34.8363C34.9181 35.0923 35.1203 35.4292 35.2116 35.8026C35.3151 36.2279 35.2691 36.676 35.0813 37.0714L30.7992 46.0666L33.6136 55.5951C33.6634 55.7654 33.6685 55.9457 33.6285 56.1186C33.5885 56.2915 33.5048 56.4512 33.3853 56.5824L32.1565 57.9234C32.0603 58.0287 31.9433 58.1127 31.8129 58.1702C31.6824 58.2277 31.5415 58.2574 31.3989 58.2574C31.344 58.2573 31.2891 58.2529 31.2349 58.2442Z"
        fill="#E28637"
      />
      <path
        opacity="0.2"
        d="M44.3571 56.0609L43.4857 38.6271L43.7117 38.5976L47.6293 57.0388H45.385C45.1209 57.039 44.8669 56.9375 44.6755 56.7555C44.4841 56.5734 44.3701 56.3247 44.3571 56.0609Z"
        fill="black"
      />
      <path
        d="M47.4615 61.6237C47.2406 61.3701 47.0781 61.0711 46.9853 60.7478C46.8925 60.4245 46.8716 60.0848 46.9243 59.7527C46.9769 59.4205 47.1017 59.1039 47.2899 58.8251C47.4781 58.5464 47.7251 58.3123 48.0135 58.1394L46.3419 50.1365L50.2909 51.7006L51.2728 59.0605C51.5311 59.5644 51.5956 60.1456 51.4541 60.6939C51.3127 61.2422 50.9751 61.7196 50.5053 62.0355C50.0355 62.3514 49.4661 62.484 48.9051 62.4081C48.3441 62.3322 47.8305 62.0531 47.4615 61.6237Z"
        fill="#A0616A"
      />
      <path
        d="M46.6725 56.1847L43.3723 34.1769L43.3692 34.1561L44.0071 31.2208C44.167 30.4854 44.5892 29.8335 45.1948 29.387C45.8004 28.9405 46.5479 28.73 47.2975 28.7949C48.047 28.8599 48.7472 29.1958 49.267 29.7398C49.7869 30.2838 50.0908 30.9986 50.1219 31.7505L51.1122 55.7235C51.1221 55.9838 51.0334 56.2382 50.8638 56.4358C50.6942 56.6335 50.4562 56.7597 50.1975 56.7893L47.804 57.0554C47.7654 57.0596 47.7267 57.0618 47.6879 57.0618C47.4416 57.0619 47.2034 56.9734 47.017 56.8123C46.8306 56.6513 46.7083 56.4285 46.6725 56.1847Z"
        fill="#E28637"
      />
      <path
        d="M40.43 25.5008C39.6659 25.4943 38.9193 25.2714 38.2765 24.8581C37.6338 24.4447 37.1212 23.8577 36.798 23.1651C36.2773 22.0633 35.988 21.1855 35.938 20.556C35.8037 18.8647 36.7591 16.2069 38.0242 14.7522C39.4122 13.1562 41.6091 12.9336 43.7337 12.7183C44.3141 12.6594 44.9143 12.5987 45.4825 12.5103C45.9699 12.4346 46.5766 12.3403 47.0923 12.5318C47.2413 12.5864 47.3777 12.6708 47.493 12.7799C47.6082 12.889 47.7001 13.0205 47.7629 13.1663C47.7832 13.2175 47.8137 13.264 47.8526 13.303C47.8915 13.342 47.938 13.3726 47.9891 13.393C48.0333 13.4099 48.0806 13.4175 48.1278 13.4152C48.1751 13.413 48.2214 13.4009 48.2638 13.3799C48.35 13.336 48.4454 13.3131 48.5422 13.3131C48.6389 13.3131 48.7343 13.3359 48.8206 13.3798C48.8968 13.4181 48.9628 13.4739 49.0133 13.5427C49.0638 13.6114 49.0973 13.6912 49.1111 13.7753C49.1202 13.8331 49.1381 13.8892 49.1642 13.9415C49.2087 14.0228 49.2699 14.0937 49.3438 14.1497C49.4177 14.2056 49.5027 14.2453 49.593 14.266C49.7308 14.2968 49.8527 14.3769 49.9357 14.4912C50.0186 14.6056 50.0569 14.7464 50.0434 14.887C50.0092 15.1757 49.9946 15.4871 49.9805 15.7884C49.9274 16.9221 49.8728 18.0941 48.7334 18.0752C48.4421 18.0631 48.1536 18.0121 47.8757 17.9237C47.463 17.8091 47.137 17.7181 46.6979 17.992C46.0228 18.4129 45.9126 19.2929 45.8154 20.0693L45.7982 20.205C45.6847 21.093 45.2155 22.1617 44.267 22.2511C43.9658 22.2609 43.6659 22.2089 43.3856 22.0982C43.0973 21.9706 42.7774 21.9322 42.4672 21.988C41.9462 22.1367 41.6955 22.759 41.717 23.297C41.7309 23.5173 41.7591 23.7365 41.8013 23.9531C41.8781 24.2962 41.9026 24.649 41.8742 24.9994C41.8471 25.2126 41.6023 25.3531 41.1038 25.4415C40.8813 25.4809 40.6559 25.5008 40.43 25.5008Z"
        fill="#2F2E41"
      />
    </svg>
  )
}

export default Icon
