import { InputElement, FileUpload } from "@paudigital/wdp-components"
import { useApi } from "hooks/api"
import { ExtraInformationContainer, ButtonWrapper, StyledButton } from "./style"
import {
  patchTickets,
  setTicketCommunication
} from "app/redux/ticketCommunication/ticketCommunication.actions"
import { useFormik } from "formik"
import { CustomerCommunication } from "app/types/ticketCommunication"
import { validationSchema } from "./config"
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "hooks/translate"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { getCustomerCommunication } from "app/redux/ticketCommunication/ticketCommunication.selectors"
import { getTicketById } from "app/redux/ticket/ticket.selectors"

type Props = {
  id: string
  location: string
  onCancel?: () => void
  communicationLessor: string
}

const Communication = ({ id, onCancel, communicationLessor }: Props) => {
  const ticket = useSelector(getTicketById(id))
  const communicationCustomer = useSelector(getCustomerCommunication)
  const { rentedBuilding } = useRentedBuilding()
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const { call } = useApi()

  const {
    errors,
    handleChange,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
    values
  } = useFormik({
    initialValues: {
      customerCommunication:
        communicationCustomer?.customerCommunication ||
        ticket?.communicationCustomer ||
        "",
      files: communicationCustomer?.files || []
    },
    onSubmit: (data: Partial<CustomerCommunication>) => {
      if (isValid && rentedBuilding && id) {
        call(
          patchTickets({
            id,
            rentedBuildingId: rentedBuilding.id,
            data: data as CustomerCommunication,
            uniqueIdentifier: "ticket-communication",
            event: PossibleAppEvents.TICKET_COMMUNICATION
          })
        )
      }
    },
    validationSchema
  })

  const hasErrors = (name: string): boolean =>
    errors[name as keyof Partial<CustomerCommunication>] ? true : false

  const handleCustomBlur = (e: React.FocusEvent<any>) => {
    const { value: val } = e.currentTarget
    const { name } = e.target
    handleBlur(e)
    setFieldValue(name, val)
  }

  const handleCommunicationChange = (e: React.ChangeEvent<any>) => {
    const { value: val, name } = e.currentTarget
    handleChange(e)
    handleBlur(e)
    setFieldValue(name, val)
    dispatch(
      setTicketCommunication({
        customerCommunication: val as string,
        files: values.files
      })
    )
  }

  const handleFileUpload = (files: File[]) => {
    setFieldValue("files", files)
    dispatch(
      setTicketCommunication({
        customerCommunication: values.customerCommunication,
        files
      })
    )
  }

  return (
    <ExtraInformationContainer onSubmit={handleSubmit}>
      <div>
        <InputElement
          name="customerCommunication"
          charLimit={2000}
          charCount
          charTranslation={translate(
            "portal.facilitymanagement.reportdetail.customerCommunication.characters"
          )}
          label={translate(
            "portal.facilitymanagement.reportdetail.customerCommunication.label"
          )}
          guidanceText={communicationLessor}
          type="textarea"
          fullWidth
          value={values.customerCommunication}
          onChange={handleCommunicationChange}
          onBlur={handleCustomBlur}
          error={hasErrors("customerCommunication")}
          errorMessage={
            errors.customerCommunication
              ? translate(errors.customerCommunication)
              : undefined
          }
        />
      </div>
      <FileUpload
        initialValue={values.files}
        label={translate(
          "portal.facilitymanagement.createticket.step2.upload.label"
        )}
        guidanceText={translate(
          "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.6"
        )}
        optionalText={translate(
          "portal.facilitymanagement.createticket.step2.upload.optional"
        )}
        optional
        required={false}
        onChange={handleFileUpload}
        inputLimit={15}
        placeholder={translate(
          "portal.facilitymanagement.createticket.step2.upload.placeholder"
        )}
        inputCountTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.uploads"
        )}
        fileTypeErrorTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.error.type"
        )}
        fileLimitErrorTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.error.limit"
        )}
        fileSizeErrorTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.error.size"
        )}
      />
      <ButtonWrapper>
        <StyledButton
          label={translate(
            "portal.facilitymanagement.reportdetail.customerCommunication.button.send"
          )}
          type="primary"
          onClick={handleSubmit}
          submit
        />
        <StyledButton
          label={translate(
            "portal.facilitymanagement.reportdetail.customerCommunication.button.cancel"
          )}
          type="secondary"
          onClick={onCancel}
        />
      </ButtonWrapper>
    </ExtraInformationContainer>
  )
}

export default Communication
