import { Survey, SurveyTarget } from "app/types/survey"
import State from "../state"

export const isLoading = (state: State): boolean => state.surveys.loading

export const getErrors = (state: State): Record<string, unknown> | undefined =>
  state.surveys.errors

export const hasDoneSurvey = (state: State): boolean => state.surveys.done

export const getSurveyByTarget =
  (target: SurveyTarget) =>
  (state: State): Survey | undefined =>
    state.surveys.data?.[target]
