import { InvoiceType } from "app/types/invoice"
import Type from "./Type"
import { Filters } from "routes/Invoices"

type Props = {
  setUrlParams: (params: any) => void
} & Filters

const TypeFilter = ({
  page,
  perPage,
  invoiceType,
  contractNumber,
  invoicePeriodTo,
  invoicePeriodFrom,
  invoiceDateTo,
  invoiceDateFrom,
  invoiceNumber,
  reference,
  sort,
  setUrlParams
}: Props) => {
  const selectedFilterType = invoiceType ? invoiceType : []
  const queryParams = {
    page,
    perPage,
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort
  }

  const types = Object.values(InvoiceType).map(type => ({
    checked: selectedFilterType.includes(type),
    type
  }))

  const clickHandler = (invoiceType: InvoiceType) => {
    if (selectedFilterType.includes(invoiceType)) {
      const filteredTypes = selectedFilterType.filter(it => it !== invoiceType)
      setUrlParams({
        ...queryParams,
        invoiceType: filteredTypes
      })
    } else {
      setUrlParams({
        ...queryParams,
        page: 1,
        invoiceType: [...selectedFilterType, invoiceType]
      })
    }
  }

  return (
    <>
      {types?.map((filterType, i) => (
        <Type
          key={`${i}_${filterType.type}`}
          checked={filterType.checked}
          type={filterType.type}
          onClick={clickHandler}
        />
      ))}
    </>
  )
}
export default TypeFilter
