import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const DashboardWrapper = styled.div`
  margin: -3.6rem auto 0;
`

export const WidgetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin-top: 2.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);

    & > #experts:nth-child(odd) {
      grid-column: span 2;
    }
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    width: initial;
    grid-template-columns: repeat(3, 1fr);

    & > #experts:nth-child(3n) {
      grid-column: span 1;
    }
    & > #experts:nth-child(3n-1) {
      grid-column: span 2;
    }
    & > #experts:nth-child(3n + 1) {
      grid-column: span 3;
    }
  }
`
