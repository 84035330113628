import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledTitle = styled.h2`
  font-size: ${theme.fontSizes.h6};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const StyledDescription = styled.span`
  font-size: ${theme.fontSizes.captionExtraSmall};
  color: ${theme.colors.neutral.darkerGrey};
  margin-top: 1.2rem;
`

export const StyledHeader = styled.div`
  margin: 4rem 2.4rem 4rem;
  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 8rem auto 4rem;
  }
`
