import { AccountCreated, PopUp } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { Container } from "./style"

type Props = {
  onClose: () => void
}

export const AccountCreatedPopUp = ({ onClose }: Props): JSX.Element => {
  const { translate } = useTranslate()
  return (
    <PopUp show delayInMs={4000} onClose={onClose}>
      <Container>
        <AccountCreated
          value={translate("portal.onboarding.step2.account.created")}
        />
      </Container>
    </PopUp>
  )
}
