import { useTranslate } from "hooks/translate"
import { ListItem, StyledSpan, StyledLink } from "./style"

const FileUploadGuidance = () => {
  const { translate } = useTranslate()
  return (
    <>
      <StyledSpan>
        {translate(
          "portal.facilitymanagement.createticket.step2.upload.guidance.include"
        )}
      </StyledSpan>
      <ul>
        <ListItem>
          {translate(
            "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.1"
          )}
          <StyledLink
            href="https://www.unigarant.nl/globalassets/media/formulieren/unigarant/saf_europees.pdf"
            type="default"
            value={translate(
              "portal.facilitymanagement.createticket.step2.upload.guidance.form"
            )}
          />
        </ListItem>
        <ListItem>
          {translate(
            "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.2"
          )}
        </ListItem>
        <ListItem>
          {translate(
            "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.3"
          )}
        </ListItem>
        <ListItem>
          {translate(
            "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.4"
          )}
        </ListItem>
        <ListItem>
          {translate(
            "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.5"
          )}
        </ListItem>
      </ul>
      <StyledSpan>
        {translate(
          "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.6"
        )}
      </StyledSpan>
    </>
  )
}

export default FileUploadGuidance
