import { StyledFilterTags } from "./style"
import { FilterTypes } from ".."
import Tag from "./Tag"
import { useTranslate } from "hooks/translate"
import { TicketType } from "app/types/ticketCategory"
import { FacilityTicketStatus } from "app/types/ticket"
import { Filters } from "routes/FacilityManagement"

export type FilterTagsProps = {
  filters: FilterTypes
  setUrlParams: (params: Filters) => void
}

export type FilterTag = {
  filterType: keyof FilterTypes
  text: string
  code?: any
}

type Props = FilterTagsProps

const FilterTags = ({ filters, setUrlParams }: Props) => {
  const { translate } = useTranslate()

  const mobileFilterBase = "portal.facilitymanagement.filter.mobile"

  const convertTypeToText = (type: TicketType[]) =>
    type.map(t => ({
      text: translate(`${mobileFilterBase}.type.${t}`),
      code: t
    }))

  const convertStatusToText = (type: FacilityTicketStatus[]) =>
    type.map(t => ({
      text: translate(`${mobileFilterBase}.status.${t}`),
      code: t
    }))

  const reorderFilters = (a: string, b: string) => {
    if (
      a.search("from") === 0 ||
      (a.search("to") === 0 && b.search("from") !== 0) ||
      a < b
    )
      return -1
    if (a > b) return 1
    return 0
  }

  const remove = (filters: FilterTypes, tag: FilterTag) => {
    if (tag.filterType === "status") {
      return {
        ...filters,
        status: [...filters.status!.filter(s => s !== tag.code)]
      }
    }
    if (tag.filterType === "type") {
      return {
        ...filters,
        type: [...filters.type!.filter(s => s !== tag.code)]
      }
    }
    delete filters[tag.filterType]
    return filters
  }

  const tags = Object.entries(filters)
    .sort((a, b) => reorderFilters(a[0], b[0]))
    .map((f): FilterTag | FilterTag[] | undefined => {
      if (!f[1]) return undefined
      else {
        switch (f[0]) {
          case "type":
            const types = convertTypeToText((f[1] as unknown) as TicketType[])
            return types.map(t => ({
              filterType: f[0] as keyof FilterTypes,
              text: t.text,
              code: t.code
            }))
          case "status":
            const statuses = convertStatusToText(
              (f[1] as unknown) as FacilityTicketStatus[]
            )
            return statuses.map(t => ({
              filterType: f[0] as keyof FilterTypes,
              text: t.text,
              code: t.code
            }))
          case "sort":
            return undefined
          case "from":
          case "to":
          case "description":
          case "ticket":
            return {
              filterType: f[0],
              text: `${translate(mobileFilterBase + "." + f[0])}: ${
                f[1] as string
              }`
            }
          default:
            return {
              filterType: f[0] as keyof FilterTypes,
              text: f[1] as string
            }
        }
      }
    })
    .flat()

  const removeTag = (tag: FilterTag) => {
    const newFilters = remove(filters, tag)

    setUrlParams({
      ...newFilters,
      page: 1,
      perPage: 30,
      sort: ["creation_date=desc"]
    })
  }

  return (
    <>
      {tags.length > 0 && (
        <StyledFilterTags>
          {tags.map(
            (tag, i) => tag && <Tag key={i} tag={tag} remove={removeTag} />
          )}
        </StyledFilterTags>
      )}
    </>
  )
}

export default FilterTags
