import { InputLabel } from "@paudigital/wdp-components"
import { useDispatch, useSelector } from "react-redux"
import { getCategories } from "app/redux/ticketCategories/ticketCategories.selectors"
import { LabelWrapper, Wrapper } from "./style"
import SubCategoryItem from "./SubCategory"
import CategoryItem from "./Category"
import { useState } from "react"
import {
  AnswerCodeType,
  AnswerCodeValueType,
  TicketType
} from "app/types/ticketCategory"
import { useTranslate } from "hooks/translate"
import { actions } from "app/redux/ticketWizard/ticketWizard.actions"
import BackButton from "./BackButton"
import { setTicketField } from "app/redux/ticket/ticket.actions"
import { getAnswerCodes } from "app/redux/ticket/ticket.selectors"

type HistoryAnswerCode = {
  answerCode: AnswerCodeType
  answerCodeValue: AnswerCodeValueType
}

const ProblemCategory = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const categories = useSelector(getCategories)
  const answerCodes = useSelector(getAnswerCodes)
  const [activeCategory, setActiveCategory] = useState<{
    id: string
    category: TicketType
  } | null>()

  const [activeSubCategory, setActiveSubCategory] = useState<{
    previousAnswerCode?: AnswerCodeType
    currentAnswerCode?: AnswerCodeValueType
    allAnswerCodes?: HistoryAnswerCode[]
    answercode?: AnswerCodeType
  } | null>()

  const subcategories =
    activeSubCategory?.allAnswerCodes &&
    activeSubCategory?.allAnswerCodes.length > 0 &&
    activeSubCategory.allAnswerCodes?.reduce(
      (result: string, key: HistoryAnswerCode) =>
        result + key.answerCodeValue.value + ".",
      ""
    )

  const handleLevelOneClick = (category: TicketType, id: string) => {
    if (!category.answerCode || !category.answerCode.values) {
      dispatch(setTicketField("type", id))
      dispatch(actions.increaseWizard())
    }
    if (category.answerCode?.values) {
      setActiveSubCategory({
        answercode: category.answerCode
      })
      setActiveCategory({
        id,
        category
      })
    }
  }

  const handleLevelXClick = (
    answerCode: AnswerCodeType,
    answerCodeValue: AnswerCodeValueType
  ) => {
    dispatch(
      setTicketField("answerCodes", [
        ...answerCodes,
        { id: answerCode.id, value: answerCodeValue.value }
      ])
    )
    if (
      !answerCodeValue ||
      !answerCodeValue.answerCode ||
      !answerCodeValue.answerCode.values
    ) {
      dispatch(setTicketField("type", activeCategory?.id))
      return dispatch(actions.increaseWizard())
    }
    if (answerCodeValue) {
      const answercodeValues = activeSubCategory?.allAnswerCodes || []
      setActiveSubCategory({
        currentAnswerCode: answerCodeValue,
        allAnswerCodes: [...answercodeValues, { answerCode, answerCodeValue }],
        answercode: answerCodeValue.answerCode
      })
    }
  }

  const handleGoBack = () => {
    // Level 3+
    if (
      activeSubCategory?.allAnswerCodes &&
      activeSubCategory?.allAnswerCodes?.length > 0
    ) {
      activeSubCategory?.allAnswerCodes?.pop()
      if (activeSubCategory?.allAnswerCodes?.length > 0) {
        return setActiveSubCategory({
          currentAnswerCode:
            activeSubCategory?.allAnswerCodes[
              activeSubCategory?.allAnswerCodes.length - 1
            ].answerCodeValue,
          answercode:
            activeSubCategory?.allAnswerCodes[
              activeSubCategory?.allAnswerCodes.length - 1
            ].answerCode
        })
      }
      return setActiveSubCategory({
        currentAnswerCode: undefined,
        answercode: activeCategory?.category.answerCode
      })
    }
    // Level 2
    if (activeCategory) {
      setActiveCategory(null)
      setActiveSubCategory(null)
    }
  }

  return (
    <Wrapper>
      <LabelWrapper>
        <InputLabel
          label={translate(
            "portal.facilitymanagement.createticket.step1.label"
          )}
        />
      </LabelWrapper>
      {/* Back Button */}
      {activeCategory && (
        <BackButton
          label={translate(
            `portal.facilitymanagement.${activeCategory.id}${
              activeSubCategory?.currentAnswerCode
                ? `.${activeSubCategory?.currentAnswerCode.value}`
                : ""
            }`
          )}
          onClick={handleGoBack}
        />
      )}
      {/* Fase 1 */}
      {categories &&
        !activeCategory &&
        categories.map((c, i) => (
          <CategoryItem
            id={c.type}
            label={translate(`portal.facilitymanagement.${c.type}`)}
            key={c.type}
            category={c}
            onClick={handleLevelOneClick}
            itemNumber={i}
          />
        ))}
      {/* Fase 2 */}
      {activeCategory &&
        activeSubCategory?.answercode &&
        activeSubCategory.answercode.values?.map(
          (answercode: AnswerCodeValueType, i) => (
            <SubCategoryItem
              answerCode={activeSubCategory.answercode!}
              key={`category-${answercode.value}`}
              answerCodeValue={answercode}
              label={translate(
                `portal.facilitymanagement.${activeCategory.category.type}.${
                  subcategories
                    ? subcategories + answercode.value
                    : answercode.value
                }`
              )}
              onClick={handleLevelXClick}
              itemNumber={i}
            />
          )
        )}
    </Wrapper>
  )
}

export default ProblemCategory
