import { useTranslate } from "hooks/translate"
import { formatRange } from "./dateFormat/dateFormat.util"
import { OneTimePosing, DateRange } from "./style"

type Props = {
  process?: string
  from: string
  to: string
}

const showDateRange = ["REPP", "RESC", "RESR", "RERV"]

const MetaTitle = ({ process, from, to }: Props) => {
  const { translate } = useTranslate()

  if (process && showDateRange.includes(process))
    return <DateRange>{formatRange(from, to)}</DateRange>

  if (!process || process === "REOP")
    return (
      <OneTimePosing>
        {translate("portal.invoices.invoice.onetimeposting")}
      </OneTimePosing>
    )
  return null
}

export default MetaTitle
