import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vetical-align: middle;
  height: 100vh;
`

export const StyledTitle = styled.h1`
  font-size: ${theme.fontSizes.h3};
  line-height: ${theme.lineHeights.h3};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.green.contrast};
`

export const StyledDescription = styled.p`
  max-width: 250px;
  text-align: center;
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.green.dark};
`
