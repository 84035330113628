import { SlideIn, LargeSpinner } from "@paudigital/wdp-components"
import { LoadingContainer, LoadingTitle, LoadingDescription } from "./style"
import { clear } from "app/redux/appEvents/appEvents.actions"
import { useTranslate } from "hooks/translate"

export const DocumentLoadingPopUp = () => {
  const { translate } = useTranslate()

  return (
    <SlideIn show showCloseIcon={false} onClose={() => clear()}>
      <LoadingContainer>
        <LargeSpinner />
        <LoadingTitle>{translate("portal.loading.patience")}</LoadingTitle>
        <LoadingDescription>
          {translate("portal.documents.loading-popup.description")}
        </LoadingDescription>
      </LoadingContainer>
    </SlideIn>
  )
}
