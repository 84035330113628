import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const DayContainer = styled.div`
  margin: 1rem 0;
`

export const DateText = styled.div`
  margin: 1.2rem 0;
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
`
