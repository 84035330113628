import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { fire } from "app/redux/appEvents/appEvents.actions"
import {
  Button,
  PlusIcon,
  theme,
  Typography,
  CrossIcon
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import Tickets from "./Tickets"
import {
  StyledFacilityManagement,
  MobileFilterContainer,
  CreateTicketContainer,
  SideBar,
  Content,
  CloseIconWrapper
} from "./style"
import { useViewport } from "hooks/viewport"
import ContactSidebar from "../ContactSidebar"
import { Modules } from "app/enums/modules"
import ContactFooter from "../ContactFooter"
import { getAppEvents } from "app/redux/appEvents/appEvents.selectors"
import Divider from "../Divider"
import FacilityFilter from "./FacilityFilter"
import { useApi } from "hooks/api"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import {
  getTickets,
  getSelectedTicketsFilters
} from "app/redux/ticket/ticket.actions"
import { getTicketsFilterIsOpen } from "app/redux/ticket/ticket.selectors"
import DesktopTopBar from "./TopBar/TopBar"
import MobileTopBar from "./TopBar/MobileTopBar"
import { Filters, FiltersAndModalOpened } from "routes/FacilityManagement"

export type FilterTypes = Omit<Filters, "page" | "perPage" | "sort">

type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const FacilityManagement = ({
  page,
  perPage,
  sort,
  status,
  type,
  from,
  to,
  ticket,
  description,
  modalOpen,
  setUrlParams
}: Props) => {
  const appEvents = useSelector(getAppEvents)
  const dispatch = useDispatch()
  const { width } = useViewport()
  const { call } = useApi()
  const { translate } = useTranslate()
  const queryParams: FiltersAndModalOpened = useMemo(() => {
    return {
      page,
      perPage,
      sort,
      status,
      type,
      from,
      to,
      ticket,
      description,
      modalOpen
    }
  }, [
    description,
    from,
    page,
    perPage,
    sort,
    status,
    ticket,
    to,
    type,
    modalOpen
  ])
  const showFilters = useSelector(getTicketsFilterIsOpen)
  const rentedBuildingId = useSelector(getActiveBuilding)
  const filters = { status, type, from, to, ticket, description }

  const handleClose = () => {
    dispatch(getSelectedTicketsFilters(false))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (rentedBuildingId) {
      call(
        getTickets({
          rentedBuildingId,
          ...queryParams
        })
      )
    }
  }, [call, rentedBuildingId, queryParams])

  const handleTicketCreation = () => {
    if (appEvents.length > 0) return
    dispatch(
      fire({
        eventName: PossibleAppEvents.CREATE_TICKET,
        uniqueIdentifier: "create-ticket"
      })
    )
  }

  const Container = React.forwardRef((props: any, ref: any) => (
    <div ref={ref}>{props.children}</div>
  ))

  return (
    <Container>
      {showFilters && width < theme.breakpointsValues.desktop ? (
        <MobileFilterContainer>
          <CloseIconWrapper onClick={handleClose}>
            <CrossIcon fill={theme.colors.neutral.darkerGrey} height="14px" />
          </CloseIconWrapper>
          <FacilityFilter
            setUrlParams={setUrlParams}
            closeFilters={handleClose}
            {...queryParams}
          />
        </MobileFilterContainer>
      ) : (
        <>
          <CreateTicketContainer>
            <Typography variant="h5" as="h2" fontStyle="bold">
              {translate("portal.facilitymanagement.createticket.title")}
            </Typography>
            <Button
              fullWidth
              label={translate(
                "portal.facilitymanagement.createticket.button.label"
              )}
              startIcon={<PlusIcon fill={theme.colors.neutral.white} />}
              onClick={handleTicketCreation}
              dataTestId="portal.facilitymanagement.createticket.button"
            />
          </CreateTicketContainer>
          <StyledFacilityManagement>
            {width < theme.breakpointsValues.desktop ? (
              <MobileTopBar
                filters={filters as Filters}
                setUrlParams={setUrlParams}
              />
            ) : (
              <>
                <DesktopTopBar setUrlParams={setUrlParams} {...queryParams} />
                <SideBar>
                  <FacilityFilter
                    setUrlParams={setUrlParams}
                    {...queryParams}
                  />
                  <ContactSidebar module={Modules.FACILITY_MANAGEMENT} />
                </SideBar>
              </>
            )}
            <Content>
              <Tickets
                page={page as number}
                perPage={perPage as number}
                sort={sort as string[]}
                setUrlParams={setUrlParams}
                queryParams={queryParams}
                modalOpen={modalOpen}
              />
              <Divider />
              <ContactFooter module={Modules.FACILITY_MANAGEMENT} />
            </Content>
          </StyledFacilityManagement>
        </>
      )}
    </Container>
  )
}

export default FacilityManagement
