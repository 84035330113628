import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const UploadDateFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 3rem 0;
`

export const FilterTitle = styled.h3`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.bold};
`

export const DatePickerWrapper = styled.div`
  > div { flex-direction: column; }
  @media only screen and (${theme.breakpoints.tablet}) {
    > div {
      flex-direction: row;
    }
  }
`