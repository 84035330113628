import { PossibleContactRoles } from "app/enums/roles"
import { AssignedPerson } from "app/types/assignedPerson"
import { InviteContactPerson } from "app/types/contactPerson"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.rentedBuildingContactPersons.loading

export const getContactPersons = (state: State): AssignedPerson[] =>
  state.rentedBuildingContactPersons?.data || []

export const hasErrors = (state: State): boolean =>
  state.rentedBuildingContactPersons.errors ? true : false

export const getRolesByContactPersonIdAndRentedBuildingId = (
  id: string,
  rentedBuildingId: string
) => (state: State): PossibleContactRoles[] => {
  const rentedBuildings = state.rentedBuildingContactPersons.data || []
  const rentedBuilding = rentedBuildings.find(e => e.id === rentedBuildingId)
  const contactPerson = rentedBuilding?.data.find(c => c.id === id)
  if (contactPerson?.roles) return contactPerson.roles
  return []
}

export const isUserAdmin = (id: string, rentedBuildingId: string) => (
  state: State
): boolean => {
  const roles = getRolesByContactPersonIdAndRentedBuildingId(
    id,
    rentedBuildingId
  )(state)
  return roles.includes(PossibleContactRoles.ADMIN)
}

export const getContactPersonPerRentedBuilding = (id?: string) => (
  state: State
): InviteContactPerson | undefined => {
  if (!id) return undefined

  const buildings = state.rentedBuildingContactPersons.data
  const contactPersons = buildings.map(b => b.data.filter(u => u.id === id))

  if (contactPersons.length === 0) return undefined

  const contact = contactPersons.flat().filter(Boolean)[0]
  const roles = buildings
    .map(b => {
      const user = b.data.find(u => u.id === id)
      return {
        rentedBuildingId: b.id,
        roles: user ? user.roles : []
      }
    })
    .flat()

  const {
    email,
    lastName,
    firstName,
    languagePreference,
    onboardedAt
  } = contact
  return {
    email,
    firstName,
    lastName,
    contactRolesPerRentedBuilding: roles,
    languagePreference,
    onboardedAt
  }
}
