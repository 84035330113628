import { mixed, string } from "yup"
import {
  isValidPhoneNumber,
  parsePhoneNumber
} from "react-phone-number-input/max"

export const phone1 = string()
  .required("portal.input.required")
  .test("phone2", "portal.input.required", (val: any) => (val ? true : false))
  .test(
    "phone2",
    "portal.contactPerson.input.phone.error.country",
    function (val: any) {
      if (val?.length < 5) return true
      const phoneNumber = val && parsePhoneNumber(val)
      if (phoneNumber?.countryCallingCode) return true
      return false
    }
  )
  .test("phone1", "portal.contactPerson.input.phone.error", (val: any) =>
    val ? isValidPhoneNumber(val) : false
  )

export const phone2 = mixed()
  .test("phone2", "portal.input.required", function (val: any) {
    if (!this.parent.hasExtraPhoneNumber) return true
    return val ? true : false
  })
  .test(
    "phone2",
    "portal.contactPerson.input.phone.error.country",
    function (val: any) {
      if (!this.parent.hasExtraPhoneNumber || val?.length < 5) return true
      const phoneNumber = val && parsePhoneNumber(val)
      if (phoneNumber?.countryCallingCode) return true
      return false
    }
  )
  .test(
    "phone2",
    "portal.contactPerson.input.phone.error",
    function (val: any) {
      if (!this.parent.hasExtraPhoneNumber) return true
      return val ? isValidPhoneNumber(val) : false
    }
  )
