import { TagEnum } from "app/redux/graphs/graphs.actions"

export enum MeterHistoryMessage {
  OK = "",
  GAP = "GAP",
  PEAK_GAP = "PEAK GAP",
  GAP_PEAK = "GAP_PEAK"
}

export enum EnergyUsageTypes {
  ELECTRICITY_CONSUMPTION = "electricityConsumption",
  ELECTRICITY_GRID_CONSUMPTION = "electricityGrid",
  SOLAR_CONSUMPTION = "solarConsumption",
  SOLAR_INJECTION = "solarInjection",
  GAS_CONSUMPTION = "gasConsumption",
  WATER_CONSUMPTION = "waterConsumption",
  CO2_EMISSION = "co2Emission"
}

export enum Co2CalculationMethods {
  GREY = "grey",
  GREEN = "green"
}

export type MeterHistoryData = {
  name: string
  msg: MeterHistoryMessage
  data: Array<[number, number | null]>
}

export type EnergyUsageComparisonData = {
  name: string
  percentageDifference: number | undefined
  data: Array<[number, number | null]>
  previousPeriodTotal: number | undefined
  currentPeriodTotal: number | undefined
}

export type RentedBuildingMeter = {
  id: number
  rentedBuildingId: string
  name: string
  unit: string
  tag: TagEnum
  createdOn: Date
  updatedOn: Date
}
