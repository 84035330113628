import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useApi } from "hooks/api"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import {
  getDocuments,
  getSelectedDocumentsFilters
} from "app/redux/document/document.actions"
import {
  CloseIconWrapper,
  CreateDocumentContainer,
  MobileFilterContainer,
  StyledDocumentManagement,
  SideBar,
  Content,
  StyledContainer
} from "./style"
import {
  Button,
  CrossIcon,
  PlusIcon,
  theme,
  Typography
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { useViewport } from "hooks/viewport"
import ContactSidebar from "../ContactSidebar"
import { Modules } from "app/enums/modules"
import Divider from "../Divider"
import ContactFooter from "../ContactFooter"
import { getDocumentsFilterIsOpen } from "app/redux/document/document.selectors"
import DocumentsFilter from "./DocumentsFilter"
import { getAppEvents } from "app/redux/appEvents/appEvents.selectors"
import Documents from "./Documents"
import MobileTopBar from "./TopBar/MobileTopBar"
import DesktopTopBar from "./TopBar/TopBar"
import { Filters } from "routes/Documents"
import { usePermission } from "hooks/permission"

type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const DocumentManagement = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { canCreateDocuments } = usePermission()
  const appEvents = useSelector(getAppEvents)
  const dispatch = useDispatch()
  const { width } = useViewport()
  const { call } = useApi()
  const { translate } = useTranslate()
  const queryParams = useMemo(() => {
    return {
      page,
      perPage,
      documentType,
      uploadDateFrom,
      uploadDateTo,
      uploadedBy,
      inspectionDateFrom,
      inspectionDateTo,
      contractId,
      sort
    }
  }, [
    contractId,
    documentType,
    inspectionDateFrom,
    inspectionDateTo,
    page,
    perPage,
    sort,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy
  ])

  const showFilters = useSelector(getDocumentsFilterIsOpen)
  const rentedBuildingId = useSelector(getActiveBuilding)

  const handleClose = () => {
    dispatch(getSelectedDocumentsFilters(false))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (rentedBuildingId) {
      call(
        getDocuments({
          rentedBuildingId,
          ...queryParams
        })
      )
    }
  }, [
    call,
    contractId,
    documentType,
    inspectionDateFrom,
    inspectionDateTo,
    page,
    perPage,
    queryParams,
    rentedBuildingId,
    sort,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy
  ])

  const handleDocumentCreation = () => {
    if (appEvents.length > 0) return
    dispatch(
      fire({
        eventName: PossibleAppEvents.CREATE_DOCUMENT,
        uniqueIdentifier: "create-document",
        props: queryParams
      })
    )
  }

  const createDocumentsAccess = canCreateDocuments()

  const Container = React.forwardRef((props: any, ref: any) => (
    <StyledContainer isFinancialManager={!createDocumentsAccess} ref={ref}>
      {props.children}
    </StyledContainer>
  ))

  return (
    <Container>
      {showFilters && width < theme.breakpointsValues.desktop ? (
        <MobileFilterContainer>
          <CloseIconWrapper onClick={handleClose}>
            <CrossIcon fill={theme.colors.neutral.darkerGrey} height="14px" />
          </CloseIconWrapper>
          <DocumentsFilter
            closeFilters={handleClose}
            setUrlParams={setUrlParams}
            {...queryParams}
          />
        </MobileFilterContainer>
      ) : (
        <>
          {createDocumentsAccess && (
            <CreateDocumentContainer>
              <Typography variant="h5" as="h2" fontStyle="bold">
                {translate("portal.documents.createdocument.title")}
              </Typography>
              <Button
                fullWidth
                label={translate(
                  "portal.documents.createdocument.button.label"
                )}
                startIcon={<PlusIcon fill={theme.colors.neutral.white} />}
                onClick={handleDocumentCreation}
              />
            </CreateDocumentContainer>
          )}
          <StyledDocumentManagement>
            {width < theme.breakpointsValues.desktop ? (
              <MobileTopBar setUrlParams={setUrlParams} {...queryParams} />
            ) : (
              <>
                <DesktopTopBar setUrlParams={setUrlParams} {...queryParams} />
                <SideBar>
                  <DocumentsFilter
                    setUrlParams={setUrlParams}
                    {...queryParams}
                  />
                  <ContactSidebar module={Modules.DOCUMENTS} />
                </SideBar>
              </>
            )}
            <Content>
              <Documents
                page={page}
                perPage={perPage}
                setUrlParams={setUrlParams}
                sort={sort}
                queryParams={queryParams}
              />
              <Divider />
              <ContactFooter module={Modules.DOCUMENTS} />
            </Content>
          </StyledDocumentManagement>
        </>
      )}
    </Container>
  )
}

export default DocumentManagement
