export type WizardActionTypes = {
  INCREASE: string
  DECREASE: string
  CLEAR: string
  SET: string
}

export function actionTypesFor(wizardName: string): WizardActionTypes {
  return {
    INCREASE: `${wizardName}_INCREASE_WIZARD`,
    DECREASE: `${wizardName}_DECREASE_WIZARD`,
    CLEAR: `${wizardName}CLEAR_WIZARD`,
    SET: `${wizardName}SET_WIZARD`
  }
}

export type WizardActionResult<P = undefined> = {
  type: string
  payload?: P
}

export type Actions = {
  increaseWizard: () => WizardActionResult
  decreaseWizard: () => WizardActionResult<{ columnOrder: any[] }>
  clearWizard: () => WizardActionResult<{ editingRowIds: any[] }>
  setWizard: (index: number) => WizardActionResult<{ index: number }>
}

export function actionsFor(tableName: string): Actions {
  const types = actionTypesFor(tableName)
  return {
    increaseWizard: () => ({
      type: types.INCREASE
    }),
    decreaseWizard: () => ({
      type: types.DECREASE
    }),
    clearWizard: () => ({
      type: types.CLEAR
    }),
    setWizard: (index: number) => ({
      type: types.SET,
      payload: { index }
    })
  }
}
