import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const NotificationContainer = styled.div`
  max-width: 32rem;
  margin: 1.2rem auto auto;

  @media only screen and (${theme.breakpoints.tablet}) {
    position: absolute;
    transform: translateY(-100%);
    margin-top: 0;
    min-width: ${theme.breakpointsValues.tablet}px;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    min-width: ${theme.breakpointsValues.desktop}px;
  }
`
