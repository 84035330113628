import { theme } from "@paudigital/wdp-components"
import { FacilityTicketStatus } from "app/enums/ticketStatus"
import styled from "styled-components"

export const StyledGridStatus = styled.span<{
  status: FacilityTicketStatus
  feedback?: boolean
}>`
  display: flex;

  background-color: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
        return `${theme.colors.system.moreInfo}0A`
      case FacilityTicketStatus.PLANNED:
        return `${theme.colors.system.planned}0A`
      case FacilityTicketStatus.FINISHED:
        return props.feedback
          ? `${theme.colors.system.success}0A`
          : "transparant"
      default:
        return "transparent"
    }
  }};
  color: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
        return `${theme.colors.system.moreInfo}`
      case FacilityTicketStatus.PLANNED:
        return `${theme.colors.system.planned}`
      case FacilityTicketStatus.FINISHED:
        return `${theme.colors.green.contrast}`
      default:
        return theme.colors.neutral.darkerGrey
    }
  }};
  border-radius: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
      case FacilityTicketStatus.PLANNED:
      case FacilityTicketStatus.FINISHED:
        return "44px"
      default:
        return "initial"
    }
  }};
  padding: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
      case FacilityTicketStatus.PLANNED:
      case FacilityTicketStatus.FINISHED:
        return "0.4rem 2.4rem 0.4rem 0.4rem"
      default:
        return "initial"
    }
  }};
`

export const StyledDot = styled.div<{ fill: string }>`
  background-color: ${props => props.fill};
  min-height: 1.6rem;
  max-height: 1.6rem;
  min-width: 1.6rem;
  max-width: 1.6rem;
  border-radius: 50%;
  margin-right: 1.6rem;
`

export const StyledStatusTitle = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
`
