import React, { useEffect } from "react"
import { DropDown, SlideIn, Typography } from "@paudigital/wdp-components"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { getFirstName } from "app/redux/me/me.selectors"
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "hooks/translate"
import { OnChangeValue } from "react-select"
import ExpertContactOptions from "../ExpertContactOptions"
import Placeholder from "./placeholder.jpeg"
import {
  ExpertDetails,
  ExpertDetailsContainer,
  ExpertDetailsContent,
  ExpertGreeting,
  ExpertSelection,
  StyledImage,
  Title
} from "./style"
import { mappings, useContactPerson } from "hooks/contactPerson"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { Modules } from "app/enums/modules"
import FaqLink from "../FaqLink"
import { useNavigate } from "react-router-dom"
import {
  createGAEvent,
  ExpertPopupButtonPosition,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  module: Modules
  option: string
  position: ExpertPopupButtonPosition
}

const options = [
  "energy",
  "finance",
  "facility",
  "portal",
  "commercial",
  "idea"
]

const ExpertPopUp = ({
  module = Modules.DASHBOARD,
  option,
  position
}: Props): JSX.Element => {
  const initialValue = mappings[module]
  const dispatch = useDispatch()
  const { contactMapping } = useContactPerson()
  const { translate } = useTranslate()
  const name = useSelector(getFirstName)
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = React.useState<string>(
    option || initialValue
  )

  const contact = contactMapping[selectedOption]

  useEffect(() => {
    dispatch(createGAEvent(PossibleGAEvents.CONTACT, { Modal: position }))
  }, [dispatch, position])

  const handleChange = (optionData: OnChangeValue<CustomOptionType, false>) => {
    const value = (optionData as CustomOptionType).value
    setSelectedOption(value)
  }
  const handleClose = () => {
    dispatch(remove("show-expert"))
  }

  return (
    <SlideIn show onClose={handleClose} showCloseIcon>
      <>
        <ExpertSelection data-testid="contact-modal-window">
          <Title>{translate("portal.contact.select.label")}</Title>
          <DropDown
            onChange={handleChange}
            options={options.map(o => ({
              value: o,
              label: translate(`portal.contact.option.${o}`)
            }))}
            hasDropdownIndicator
            placeholder={translate(
              `portal.contact.option.${option || initialValue}`
            )}
            className="portal-contact-select"
          />
        </ExpertSelection>
        <ExpertDetailsContainer>
          <ExpertDetails>
            <ExpertDetailsContent>
              <ExpertGreeting>
                <Typography variant="h6" textAlign="center">
                  {translate("portal.contact.greeting.expert", {
                    name,
                    role: contact?.role,
                    expertName: contact?.person?.firstName
                  })}
                </Typography>
              </ExpertGreeting>
              <StyledImage src={contact?.profilePicture ?? Placeholder} />
              {contact && (
                <ExpertContactOptions
                  phone={contact.person?.mobilephone}
                  email={contact.person?.email}
                />
              )}
            </ExpertDetailsContent>
            <FaqLink
              content="portal.dashboard.widget.expert.topic.faq.text"
              linkContent="portal.dashboard.widget.expert.topic.faq.link"
              onClick={() => {
                navigate("/help")
                handleClose()
              }}
            />
          </ExpertDetails>
        </ExpertDetailsContainer>
      </>
    </SlideIn>
  )
}

export default ExpertPopUp
