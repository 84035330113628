import {
  ToolTip,
  TrashBinIcon,
  CardActions,
  ShieldIcon,
  Button,
  CheckmarkIcon,
  theme
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { fire } from "app/redux/appEvents/appEvents.actions"
import {
  TitleContainer,
  ContentContainer,
  DescriptionContainer,
  RecommendedContainer,
  StyledBaseCard,
  StateContainer,
  StyledCardContent,
  IconContainer,
  StyledIcon
} from "./style"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { getHasOtpAuthenticator } from "app/redux/me/me.selectors"

const MfaOtpCard = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const hasOtpAuthenticator = useSelector(getHasOtpAuthenticator)

  const handleOnClick = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.MFA_SET_UP_OTP,
        uniqueIdentifier: "mfa-set-up"
      })
    )
  }

  const handleDeleteOnClick = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.MFA_DELETE_OTP_START,
        uniqueIdentifier: "mfa-delete-otp-start"
      })
    )
  }

  return (
    <StyledBaseCard backgroundColor="lightgrey">
      <StyledCardContent>
        <ContentContainer>
          <TitleContainer>
            {translate("portal.account.account.mfa.card.otp.title")}
          </TitleContainer>
          <DescriptionContainer>
            {translate("portal.account.account.mfa.card.otp.description")}
          </DescriptionContainer>
          <RecommendedContainer>
            {translate("portal.account.account.mfa.card.otp.recommended")}
          </RecommendedContainer>
        </ContentContainer>
        {hasOtpAuthenticator && (
          <StateContainer>
            <CheckmarkIcon />
            <span>{translate("portal.authentication.active")} </span>
          </StateContainer>
        )}
      </StyledCardContent>
      <CardActions alignment="horizontal" placement="right">
        <IconContainer>
          {!hasOtpAuthenticator ? (
            <Button
              onClick={handleOnClick}
              fullWidth
              label={translate("portal.account.account.mfa.card.button")}
              type="secondary"
              startIcon={<ShieldIcon />}
            />
          ) : (
            <ToolTip value={translate("portal.authentication.delete")}>
              <StyledIcon onClick={handleDeleteOnClick} active>
                <TrashBinIcon fill={theme.colors.system.error} />
              </StyledIcon>
            </ToolTip>
          )}
        </IconContainer>
      </CardActions>
    </StyledBaseCard>
  )
}

export default MfaOtpCard
