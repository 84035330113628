import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
`

export const StyledDot = styled.div<{ fill: string }>`
  background-color: ${props => props.fill};
  min-height: 1.1rem;
  max-height: 1.1rem;
  min-width: 1.1rem;
  max-width: 1.1rem;
  border-radius: 50%;
  margin-right: 0.6rem;
`

export const StyledStatusTitle = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
`
