import { SlideIn } from "@paudigital/wdp-components"
import { clear } from "app/redux/appEvents/appEvents.actions"
import { useDispatch } from "react-redux"
import AddColleagueToBuilding from "./AddColleagueToBuilding"

type Props = {
  id?: string
}

const AddColleagueToBuildingPopUp = ({ id }: Props) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(clear())
  }
  return (
    <SlideIn onClose={handleClose} show showCloseIcon>
      <AddColleagueToBuilding id={id} onClose={handleClose} />
    </SlideIn>
  )
}

export default AddColleagueToBuildingPopUp
