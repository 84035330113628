import {
  Button,
  DropDown,
  FileUpload,
  InputElement,
  Link
} from "@paudigital/wdp-components"
import Divider from "app/components/Divider"
import { DocumentLanguage } from "app/enums/documentLanguages"
import { getDocument, isLoading } from "app/redux/document/document.selectors"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { ButtonWrapper } from "./style"
import { actions } from "app/redux/documentWizard/documentWizard.actions"
import DatePicker from "app/components/DatePicker"
import { validationSchema } from "./config"
import { useFormik } from "formik"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { useEffect } from "react"
import * as documentActions from "app/redux/document/document.actions"
import { PostDocument, DocumentMetaData } from "app/types/document"
import { getCategory } from "../util"
import { getDocumentTypes } from "app/redux/documentTypes/documentTypes.selectors"
import { DocumentContentType } from "app/redux/documentTypes/documentTypes.reducer"
import { PostDocumentParams } from "app/redux/document/document.actions"
import { Filters } from "routes/Documents"
import { useState } from "react"
import { getBuildingById } from "app/redux/me/me.selectors"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = Filters

const DocumentUpload = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort
}: Props) => {
  const types = useSelector(getDocumentTypes)
  const document = useSelector(getDocument)
  const [isParkRelated, setIsParkRelated] = useState<"true" | "false">("false")
  const rentedBuildingId = useSelector(getActiveBuilding)
  const activeBuilding = useSelector(getBuildingById(rentedBuildingId))
  const industrialParkId = activeBuilding && activeBuilding.industrialParkId
  const { upfile } = document
  const dispatch = useDispatch()
  const loading = useSelector(isLoading)
  const { translate } = useTranslate()
  const category = getCategory(types, document.treeType)
  const documentCanBeParkRelated = Boolean(document.secondaryTypeIds?.length)
  const showInspectionDateInput =
    category?.documentContentType ===
    DocumentContentType.WDP_Contractor_Document
  const queryParams: PostDocumentParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }

  const handlePrevious = () => {
    dispatch(documentActions.setFieldValue("upfile", undefined))
    dispatch(documentActions.setFieldValue("metadata", {}))
    dispatch(actions.decreaseWizard())
  }

  const initialValues = {
    Title: "",
    Document_x0020_Language: "",
    Document_x0020_date: new Date().toISOString(),
    ...document.metadata!
  }

  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount
  } = useFormik({
    initialValues: { ...initialValues, showInspectionDateInput },
    onSubmit: (
      metadata: DocumentMetaData & { showInspectionDateInput?: boolean }
    ) => {
      if (isValid && upfile) {
        delete metadata.showInspectionDateInput
        dispatch(
          createGAEvent(PossibleGAEvents.DOCUMENT_UPLOAD, {
            document_type: document.treeType
          })
        )
        dispatch(
          documentActions.postDocuments(
            {
              ...document,
              metadata,
              isParkRelated
            } as PostDocument,
            { ...queryParams }
          )
        )
      }
    },
    validationSchema
  })

  const hasErrors = (name: string) => {
    return errors[name as keyof DocumentMetaData] &&
      touched[name as keyof DocumentMetaData]
      ? true
      : false
  }

  const handleDropDownChange = (
    optionData: OnChangeValue<CustomOptionType, false>
  ) => {
    const value = (optionData as CustomOptionType).value
    dispatch(
      documentActions.setFieldValue("metadata", {
        ...document.metadata,
        Document_x0020_Language: value
      })
    )
    setFieldValue("Document_x0020_Language", value)
  }

  const handleFileUpload = (files: File[]) => {
    if (files.length === 0) {
      dispatch(documentActions.setFieldValue("fileName", undefined))
      dispatch(documentActions.setFieldValue("upfile", undefined))
    }
    if (files && files[0]) {
      dispatch(documentActions.setFieldValue("fileName", files[0].name))
      dispatch(documentActions.setFieldValue("upfile", files))
    }
  }

  const getIsoDate = (d: string) => new Date(d).toISOString()

  const handleDatePickerClose = (date: string, name: string) => {
    setFieldTouched(name, true)
    if (!date || date === "") setFieldValue(name, "")
    else setFieldValue(name, getIsoDate(date))
  }

  const handleDatePickerBlur = (date: string, name: string) => {
    setFieldTouched(name, true)
    setFieldValue(name, date === "" ? "" : getIsoDate(date))
  }

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.currentTarget
    setFieldTouched(name, true)
    dispatch(
      documentActions.setFieldValue("metadata", {
        ...document.metadata,
        [name]: value
      })
    )
    handleChange(e)
  }

  const handleParkRelatedDropdown = (
    optionData: OnChangeValue<CustomOptionType, false>
  ) => {
    const value = (optionData as CustomOptionType).value as "true" | "false"
    setIsParkRelated(value)
  }

  useEffect(() => {
    if (showInspectionDateInput) {
      setFieldValue("Contractor_x0020_Number", "")
      setFieldValue("Contractor_x0020_Name", "")
    }
  }, [setFieldValue, showInspectionDateInput])

  return (
    <form onSubmit={handleSubmit}>
      <InputElement
        label={translate("portal.documents.createdocument.title.label")}
        name="Title"
        charCount
        charLimit={100}
        charTranslation={translate(
          "portal.facilitymanagement.createticket.step3.subject.characters.label"
        )}
        value={values?.Title}
        onChange={handleInputChange}
        error={hasErrors("Title")}
        errorMessage={hasErrors("Title") && translate(errors.Title!)}
        prefixValue={
          document?.treeType &&
          translate(`portal.documents.category.${document?.treeType}`)
        }
      />
      {showInspectionDateInput && (
        <DatePicker
          id="inspection-date"
          name="Document_x0020_date"
          label={translate("portal.documents.createdocument.inspection.label")}
          inputDate={values.Document_x0020_date}
          placeholder={translate(
            "portal.documents.createdocument.inspection.placeholder"
          )}
          onBlur={handleDatePickerBlur}
          onClose={handleDatePickerClose}
          error={hasErrors("Document_x0020_date")}
          errorMessage={
            hasErrors("Document_x0020_date")
              ? translate(errors.Document_x0020_date!)
              : undefined
          }
        />
      )}
      <DropDown
        options={Object.values(DocumentLanguage).map(language => ({
          label: translate(
            `portal.documents.createdocument.language.${language.toLowerCase()}`
          ),
          value: language
        }))}
        label={translate("portal.documents.createdocument.language.label")}
        placeholder={translate(
          "portal.documents.createdocument.language.placeholder"
        )}
        defaultValue={
          values.Document_x0020_Language &&
          translate(
            `portal.documents.createdocument.language.${values.Document_x0020_Language.toLowerCase()}`
          )
        }
        hasDropdownIndicator
        onChange={handleDropDownChange}
        error={hasErrors("Document_x0020_Language")}
        errorMessage={
          hasErrors("Document_x0020_Language")
            ? translate(errors.Document_x0020_Language!)
            : undefined
        }
      />
      {documentCanBeParkRelated && industrialParkId && (
        <DropDown
          options={[true, false].map(b => ({
            label: translate(
              `portal.documents.createdocument.isParkRelated.${b}`
            ),
            value: b.toString()
          }))}
          label={translate(
            "portal.documents.createdocument.isParkRelated.label"
          )}
          placeholder={translate(
            "portal.documents.createdocument.isParkRelated.placeholder"
          )}
          defaultValue={translate(
            `portal.documents.createdocument.isParkRelated.false`
          )}
          hasDropdownIndicator
          onChange={handleParkRelatedDropdown}
        />
      )}
      <Divider />
      <FileUpload
        label={translate("portal.documents.createdocument.upload.label")}
        guidanceText={translate(
          "portal.documents.createdocument.upload.guidance"
        )}
        placeholder={translate(
          "portal.documents.createdocument.upload.placeholder"
        )}
        onChange={handleFileUpload}
        initialError={submitCount > 0 && !upfile}
        initialErrorMessage={translate(
          "portal.documents.createdocument.upload.error.required"
        )}
        fileTypeErrorTranslation={translate(
          "portal.documents.createdocument.upload.error.type"
        )}
        fileSizeErrorTranslation={translate(
          "portal.documents.createdocument.upload.error.size"
        )}
        initialValue={upfile || []}
      />
      <ButtonWrapper>
        <Link
          type="default"
          value={translate("portal.documents.createdocument.button.previous")}
          onClick={handlePrevious}
        />
        <Button
          label={translate("portal.documents.createdocument.button.next")}
          disabled={loading}
          submit
        />
      </ButtonWrapper>
    </form>
  )
}

export default DocumentUpload
