import { ContactPerson } from "app/types/contactPerson"
import { Action, Types } from "./contactPerson.actions"

export type ContactPersonState = {
  contactPerson: ContactPerson | null
  data: ContactPerson[]
  errors?: Record<string, unknown>
  loading: boolean
  whitelistedDomains: string[]
}

const initialState: ContactPersonState = {
  contactPerson: null,
  data: [],
  errors: undefined,
  loading: false,
  whitelistedDomains: []
}

export default (state = initialState, action: Action): ContactPersonState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_BY_ID:
      return {
        ...state,
        loading: true
      }

    case Types.GET_BY_ID_SUCCESS:
      return {
        ...state,
        contactPerson: action.payload.data,
        loading: false
      }

    case Types.GET_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PATCH:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        contactPerson: action.payload.data,
        loading: false
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PUT_PROFILEPICTURE:
      return {
        ...state,
        loading: true
      }

    case Types.PUT_PROFILEPICTURE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PUT_PROFILEPICTURE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PATCH_ONBOARDING:
    case Types.PATCH_ADMIN:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_ONBOARDING_SUCCESS:
    case Types.PATCH_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PATCH_ONBOARDING_FAIL:
    case Types.PATCH_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_WHITELISTEDDOMAINS:
      return {
        ...state,
        loading: true
      }

    case Types.GET_WHITELISTEDDOMAINS_SUCCESS:
      return {
        ...state,
        whitelistedDomains: action.payload.data,
        loading: false
      }

    case Types.GET_WHITELISTEDDOMAINS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.RESEND_INVITE:
      return {
        ...state,
        loading: true
      }

    case Types.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.RESEND_INVITE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
