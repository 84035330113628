import styled from "styled-components"
import { Button, theme } from "@paudigital/wdp-components"
import { Link } from "react-router-dom"

export const NavigationContainer = styled.div<{
  isSticky: boolean
}>`
  background: ${theme.colors.neutral.white};
  box-shadow: ${theme.effects.menuShadow};
  top: 0;
  z-index: 9000;
`

export const NavigationWrapper = styled.div<{
  isActive: boolean
}>`
  position: relative;
  margin: 0 auto;
  width: ${props => (props.isActive ? "100vw" : "auto")};

  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: 768px;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    max-width: 1152px;

    &:after {
      content: "";
      display: none;
    }
  }
`

export const StyledNavigation = styled.nav<{
  active?: boolean
  multiRow: boolean
}>`
  z-index: 1000;
  overflow: visible;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  right: 0;
  bottom: ${props => props.active && "0"};
  left: 0;
  background: ${theme.colors.neutral.white};
  top: 0;
  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    flex-direction: row;
  }
`

export const NotificationsWrapper = styled.div<{
  isVisible: boolean
  active: boolean
}>`
  grid-area: messages;
  psition: sticky;
  font-size: ${theme.fontSizes.captionExtraSmall};
  display: flex;
  justify-content: flex-end;
  margin: 0;

  button {
    margin-left: 0.8rem;

    &:last-of-type {
      margin-right: 2.4rem;
    }
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    gap: 5.4rem;
    margin: 0;
    & > div:last-of-type {
      margin-right: 5.4rem;
    }
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    display: ${props => (props.isVisible ? "flex" : "none")};
    align-items: center;
    justify-content: space-between;
  }
`

export const LogoWrapper = styled(Link)<{ active: string }>`
  height: auto;
  display: flex;
  align-items: center;

  & svg {
    height: 129px;
    width: 132px;
    margin: 0;
  }
  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 1rem;;
  }
`


export const AdminSwitcherContainer = styled.div`
  text-decoration: none;
  color: ${theme.colors.neutral.darkerGrey};
  align-items: center;
  width: 100%;
  z-index: 900000000;
  overflow: visible;
`

export const LogoutButton = styled(Button)`
  width: 130px;
  margin-bottom: 2rem;
  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    margin: 0;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    flex-direction: row;
    margin: 0;
  }
`
