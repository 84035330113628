import styled from "styled-components"

export const TermsWrapper = styled.div`
  max-width: 75rem;
  margin: 3.2rem;

  &p {
    margin: 1.5rem 0;
    line-height: 2rem;
  }

  & button {
    margin: 3.2rem auto 0;
  }
`
