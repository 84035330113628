import { useApi } from "hooks/api"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { SpinnerIcon as Spinner } from "@paudigital/wdp-components"
import { startOfDay, sub } from "date-fns"
import { EnergyUsageTypes } from "app/types/energy"
import { formatISODateWithoutTimezone } from "app/utils/dateFormatter.utils"
import { getUsageComparisonData } from "app/utils/energy.utils"
import { useTranslate } from "hooks/translate"
import { getEnergyUsageComparison } from "app/redux/energyUsageComparison/energyUsageComparison.actions"
import {
  getCo2CalculationMethod,
  getEnergyPeriodRange
} from "app/redux/energyPeriod/energyPeriod.selectors"
import {
  getEnergyUsageComparisonData,
  isLoading
} from "app/redux/energyUsageComparison/energyUsageComparison.selectors"
import EnergyComparisonCard from "../EnergyComparisonCard"
import {
  EUCOverviewGrid,
  EUCOverviewFlex,
  LoadingInnerWrapper,
  LoadingOuterWrapper,
  LoadingText,
  Solar,
  NonSolar
} from "./style"
import Co2CalculationDisclaimer from "../Co2CalculationDisclaimer"
import NoMetersInstalled from "./NoMetersInstalled"
import NoEnergyReason from "./NoEnergyReason"
import { convertPeriodToDays } from "app/utils/periodInDays.utils"
import * as graphSelectors from "app/redux/graphs/graphs.selectors"
import { NoEnergyReasons } from "app/types/rentedBuilding"

const EnergyUsageComparisonOverview = () => {
  const { translate } = useTranslate()
  const { rentedBuilding } = useRentedBuilding()
  const { call } = useApi()
  const { from: periodFrom, to: periodTo } = useSelector(getEnergyPeriodRange)
  const rentedBuidingAvailableMeters = useSelector(
    graphSelectors.availableMeters
  )
  const energyUsageComparisonData = useSelector(getEnergyUsageComparisonData)
  const co2CalculationMethod = useSelector(getCo2CalculationMethod)
  const energyUsageComparisonIsLoading = useSelector(isLoading)
  const metersLoading = useSelector(graphSelectors.loading)
  const hasEnergyReason = rentedBuilding?.noEnergyReason && rentedBuilding.noEnergyReason !== NoEnergyReasons.NANOGRID_READY
  const mappedEnergyUsage = Object.values(EnergyUsageTypes).map(eut =>
    getUsageComparisonData(energyUsageComparisonData, eut)
  )
  const hasSolar = mappedEnergyUsage.some(e => e?.name.includes("solar"))
  const hasCO2 = mappedEnergyUsage.some(e =>
    e?.name.includes(EnergyUsageTypes.CO2_EMISSION)
  )

  const hasMeters = useMemo(() => {
    return rentedBuidingAvailableMeters.length > 0
  }, [rentedBuidingAvailableMeters.length])

  const periodInDays = useMemo(() => {
    return convertPeriodToDays(periodFrom, periodTo)
  }, [periodFrom, periodTo])

  useEffect(() => {
    if (hasMeters && rentedBuilding?.id && rentedBuilding?.country) {
      const currentPeriodFrom = startOfDay(new Date(periodFrom))
      const currentPeriodTo = startOfDay(new Date(periodTo))
      const previousPeriodFrom = sub(currentPeriodFrom, { days: periodInDays })
      const previousPeriodTo = sub(currentPeriodTo, { days: periodInDays })

      const params = {
        previousPeriodFrom: formatISODateWithoutTimezone(previousPeriodFrom),
        previousPeriodTo: formatISODateWithoutTimezone(previousPeriodTo),
        currentPeriodFrom: formatISODateWithoutTimezone(currentPeriodFrom),
        currentPeriodTo: formatISODateWithoutTimezone(currentPeriodTo),
        co2CalculationMethod: co2CalculationMethod
      }
      call(
        getEnergyUsageComparison(
          rentedBuilding.id,
          rentedBuilding.country,
          params
        )
      )
    }
  }, [
    call,
    periodInDays,
    periodTo,
    co2CalculationMethod,
    rentedBuilding?.id,
    rentedBuilding?.country,
    periodFrom,
    hasMeters
  ])

  const renderSolarCard = (() =>
    mappedEnergyUsage
      .filter(eu => eu?.name === EnergyUsageTypes.SOLAR_CONSUMPTION)
      .map(c =>
        c && c.name ? (
          <EnergyComparisonCard
            key={c.name}
            cardName={"electricityConsumption"}
            hasSolar={hasSolar}
            isSolar
          />
        ) : null
      ))()

  const renderCards = (() =>
    mappedEnergyUsage
      .filter(
        eu =>
          eu?.name === EnergyUsageTypes.GAS_CONSUMPTION ||
          eu?.name === EnergyUsageTypes.WATER_CONSUMPTION ||
          eu?.name === EnergyUsageTypes.CO2_EMISSION ||
          (!hasSolar && eu?.name === EnergyUsageTypes.ELECTRICITY_CONSUMPTION)
      )
      .map(c =>
        c && c.name ? (
          <EnergyComparisonCard
            key={c.name}
            cardName={c.name}
            hasSolar={hasSolar}
          />
        ) : null
      ))()

  if (hasEnergyReason && rentedBuilding?.noEnergyReason) {
    return <NoEnergyReason reason={rentedBuilding.noEnergyReason} />
  }

  if (metersLoading || energyUsageComparisonIsLoading) {
    return (
      <EUCOverviewGrid loading={Number(true)}>
        <LoadingOuterWrapper>
          <LoadingInnerWrapper>
            <Spinner width="36" height="36" />
            <LoadingText>
              {translate("portal.energy.comparison.overview.loading")}
            </LoadingText>
          </LoadingInnerWrapper>
        </LoadingOuterWrapper>
      </EUCOverviewGrid>
    )
  }

  if (
    !metersLoading &&
    !hasMeters &&
    !energyUsageComparisonIsLoading &&
    energyUsageComparisonData.length === 0
  ) {
    return <NoMetersInstalled />
  }

  if (mappedEnergyUsage.every(meu => meu === undefined)) return <></>

  return (
    <>
      {hasSolar ? (
        <EUCOverviewGrid>
          <Solar>{renderSolarCard}</Solar>
          <NonSolar>{renderCards}</NonSolar>
        </EUCOverviewGrid>
      ) : (
        <EUCOverviewFlex>{renderCards}</EUCOverviewFlex>
      )}
      {hasCO2 && <Co2CalculationDisclaimer />}
    </>
  )
}

export default EnergyUsageComparisonOverview
