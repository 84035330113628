import { Locales } from "app/i18n/config"

export const preferredLanguageBaseTranslation =
  "portal.preferred.language.select."

export const contactPersonBasetranslation =
  "portal.contactPerson.language.select."

export const languageCodes = [...Object.values(Locales)]

export const getLanguage = (code: string, isPreferredLanguage: boolean) =>
  `${
    isPreferredLanguage
      ? preferredLanguageBaseTranslation
      : contactPersonBasetranslation
  }${code}`
