import { DocumentCategory } from "app/redux/documentTypes/documentTypes.reducer"

export function getCategoryParent(array: DocumentCategory[], value?: string) {
  if (!value) return

  let result

  const recursiveSearch = (
    category: DocumentCategory,
    parent: DocumentCategory
  ): undefined | DocumentCategory => {
    if (category.treeType === value) result = parent
    if (category.children.length > 0) {
      return category.children.find(c => recursiveSearch(c, category))
    }
  }

  array.forEach(a => {
    if (a.treeType === value) return undefined
    recursiveSearch(a, a)
  })

  return result
}

export function getCategory(
  array: DocumentCategory[],
  value?: string
): DocumentCategory | undefined {
  if (!value) return

  let result

  const recursiveSearch = (
    category: DocumentCategory
  ): undefined | DocumentCategory => {
    if (category.treeType === value) result = category
    if (category.children.length > 0) {
      return category.children.find(c => recursiveSearch(c))
    }
  }

  array.forEach(a => recursiveSearch(a))

  return result
}
