import { Container, StyledSection, StyledContactSidebar } from "./style"
import BuildingContract from "./BuildingContract"
import BuildingSpecification from "./BuildingSpecification"
import { Modules } from "app/enums/modules"
import ContactFooter from "../ContactFooter"
import { useViewport } from "hooks/viewport"
import { theme } from "@paudigital/wdp-components"
import { useEffect } from "react"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { getDocumentsStatistics } from "app/redux/document/document.actions"
import { useSelector } from "react-redux"
import { useApi } from "hooks/api"
import { usePermission } from "hooks/permission"
import { useNavigate } from "react-router"

const BuildingPassport = () => {
  const { width } = useViewport()
  const { call } = useApi()
  const navigate = useNavigate()
  const rentedBuildingId = useSelector(getActiveBuilding)
  const { hasPermission, isExternal } = usePermission()
  const isUserExternal = isExternal()
  const contractPermission =
    rentedBuildingId && hasPermission(Modules.CONTRACTS, rentedBuildingId!)
  const documentsPermission =
    rentedBuildingId && hasPermission(Modules.DOCUMENTS, rentedBuildingId!)

  useEffect(() => {
    if (isUserExternal) navigate("/facility-management")
  }, [isUserExternal, navigate])

  useEffect(() => {
    if (document.location.hash === "#building-passport") {
      setTimeout(() => {
        document
          ?.querySelector("#building-passport")
          ?.scrollIntoView({ behavior: "smooth", block: "center" })
      }, 300)
    }
  }, [])

  useEffect(() => {
    if (!documentsPermission) return
    call(getDocumentsStatistics(rentedBuildingId!))
  }, [call, rentedBuildingId, documentsPermission])

  return (
    <Container>
      <StyledSection>
        <BuildingSpecification />
      </StyledSection>
      <StyledSection>
        <div id="building-passport">
          {contractPermission && <BuildingContract />}
          {width > theme.breakpointsValues.tablet && (
            <StyledContactSidebar
              addMargin={contractPermission === true}
              module={Modules.FACILITY_MANAGEMENT}
            />
          )}
        </div>
      </StyledSection>
      <ContactFooter module={Modules.FACILITY_MANAGEMENT} />
    </Container>
  )
}

export default BuildingPassport
