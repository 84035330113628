import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"
import { Link } from "react-router-dom"

export const Container = styled.div`
  background: ${theme.colors.neutral.white};
  box-shadow: ${theme.effects.floatingShadow};
  border-radius: 12px;
  margin-bottom: 0.8rem;
  padding: 1.6rem 2.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  display: none;

  @media only screen and (${theme.breakpoints.tablet}) {
    display: flex;
  }
`

export const SectionTitle = styled.span`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.caption};
  font-weight: bold;
`

export const SectionChildren = styled.div`
  border-top: 1px solid ${theme.colors.neutral.midGrey};
`

export const ChrevonWrapper = styled.div`
  transform: rotate(90deg);
`

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus {
    ${theme.outline}
  }
`
export const Count = styled.label`
  color: ${theme.colors.neutral.white};
  font-size: ${theme.fontSizes.paragraph};
  font-weight: ${theme.fontWeights.bold};
  padding: 0 1rem;
  margin-right: 1rem;
  border-radius: 2rem;
  background: ${theme.colors.system.error};
`

export const ChevronCountContainer = styled.div`
  display: flex;
  flex-direction: row;
`
