import { Locales } from "app/i18n/config"
import { Campaign } from "app/types/banner"
import { ButtonContainer, StyledLink } from "../style"

type HeaderButtonProps = {
  bannerInfo: Campaign
  preferredLanguage: Locales
}

export const HeaderButton = ({
  bannerInfo,
  preferredLanguage
}: HeaderButtonProps) => {
  return (
    <ButtonContainer>
      {bannerInfo?.languageData[preferredLanguage] &&
        bannerInfo?.languageData[preferredLanguage]?.url &&
        bannerInfo?.languageData[preferredLanguage]?.label && (
          <StyledLink
            type="default"
            href={bannerInfo?.languageData[preferredLanguage]!.url}
            value={bannerInfo?.languageData[preferredLanguage]!.label!}
          />
        )}
    </ButtonContainer>
  )
}

export default HeaderButton
