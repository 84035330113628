export const buildGraphOptions = (
  graphData: Array<[number, number | null]>
) => {
  return {
    ...baseGraphOptions,
    series: [
      {
        fillColor: "#f9f1eb",
        lineColor: "#e28442",
        connectNulls: false,
        states: {
          hover: {
            enabled: false
          }
        },
        marker: {
          enabled: false
        },
        data: graphData
      }
    ]
  }
}

const baseGraphOptions = {
  title: {
    text: ""
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  chart: {
    marginRight: -4,
    marginLeft: -4,
    spacing: [0, 0, 0, 0],
    type: "areaspline"
  },
  xAxis: {
    lineWidth: 0,
    tickWidth: 0,
    labels: {
      enabled: false
    }
  },
  yAxis: {
    maxPadding: 0,
    gridLineWidth: 0,
    labels: {
      enabled: false
    },
    title: {
      text: ""
    }
  }
}
