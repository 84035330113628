import { useEffect } from "react"
import { getMe } from "app/redux/me/me.actions"
import { useApi } from "hooks/api"
import * as meSelectors from "app/redux/me/me.selectors"
import * as surveysSelectors from "app/redux/surveys/surveys.selectors"
import { useSelector } from "react-redux"
import { LoadingScreen } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { getSurveys } from "app/redux/surveys/surveys.actions"

type Props = {
  children: JSX.Element
}

function CurrentUserProvider({ children }: Props) {
  const { translate } = useTranslate()
  const { call } = useApi()
  const user = useSelector(meSelectors.getUser)
  const isSurveysLoading = useSelector(surveysSelectors.isLoading)
  const isMeLoading = useSelector(meSelectors.loading)
  const isLoading = isSurveysLoading || isMeLoading

  useEffect(() => {
    call(getMe())
    call(getSurveys())
  }, [call])

  return (
    <>
      {!user && isLoading && (
        <LoadingScreen
          patienceText={translate("portal.loading.patience")}
          loadingText={translate("portal.loading.loading")}
        />
      )}
      {children}
    </>
  )
}

export default CurrentUserProvider
