import { PopUp } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { ModalDescription, StyledFormWrapper } from "./style"

export type Props = {
  onClose: () => {}
}

export const TicketCancelledModal = ({ onClose }: Props) => {
  const { translate } = useTranslate()

  return (
    <PopUp onClose={onClose} delayInMs={4000} show>
      <StyledFormWrapper>
        <ModalDescription>
          {translate(
            "portal.facilitymanagement.modal.ticket.cancelled.success"
          )}
        </ModalDescription>
      </StyledFormWrapper>
    </PopUp>
  )
}
