import { Button, NotFound, Page, Typography } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useNavigate } from "react-router-dom"
import MarkdownTranslation from "../MarkdownTranslation"
import {
  Container,
  ImageWrapper,
  List,
  ListContainer,
  ListTitle,
  StyledDescription
} from "./style"

const LinkExpiredPage = () => {
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const image = (
    <ImageWrapper>
      <NotFound />
    </ImageWrapper>
  )

  const goToLogin = () => {
    navigate("/")
  }

  return (
    <Page image={image}>
      <Container>
        <Typography as="h4" fontStyle="black" textAlign="center">
          {translate("portal.linkExpired.title")}
        </Typography>
        <StyledDescription>
          {translate("portal.linkExpired.description")}
        </StyledDescription>

        <ListContainer>
          <ListTitle>{translate("portal.linkExpired.list.title")}</ListTitle>
          <List>
            <li>
              <MarkdownTranslation label="portal.linkExpired.list.item.1" />
            </li>
            <li>
              <MarkdownTranslation label="portal.linkExpired.list.item.2" />
            </li>
          </List>
        </ListContainer>

        <Button
          label={translate("portal.linkExpired.cta")}
          onClick={goToLogin}
          fullWidth
        />
      </Container>
    </Page>
  )
}

export default LinkExpiredPage
