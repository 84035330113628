import { useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { theme, CrossIcon } from "@paudigital/wdp-components"
import { Modules } from "app/enums/modules"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import {
  getInvoices,
  getSelectedInvoicesFilters
} from "app/redux/invoices/invoices.actions"
import { getInvoicesFilterIsOpen } from "app/redux/invoices/invoices.selectors"
import { useApi } from "hooks/api"
import { useViewport } from "hooks/viewport"
import ContactFooter from "../ContactFooter"
import ContactSidebar from "../ContactSidebar"
import Divider from "../Divider"
import InvoiceFilter from "./InvoiceFilter"
import {
  MobileFilterContainer,
  CloseIconWrapper,
  StyledInvoices,
  SideBar,
  Content,
  StyledContainer
} from "./style"
import Invoices from "./Invoices"
import { getRentedBuildingContractNumbers } from "app/redux/rentedBuildingContracts/rentedBuildingContracts.actions"
import MobileTopBar from "./TopBar/MobileTopBar"
import DesktopTopBar from "./TopBar/TopBar"
import { Filters } from "routes/Invoices"

export type FilterTypes = Omit<Filters, "page" | "perPage" | "sort">

type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const InvoicesPage = ({
  page,
  perPage,
  invoiceType,
  contractNumber,
  invoicePeriodTo,
  invoicePeriodFrom,
  invoiceDateTo,
  invoiceDateFrom,
  invoiceNumber,
  reference,
  status,
  sort,
  setUrlParams
}: Props) => {
  const dispatch = useDispatch()
  const { width } = useViewport()
  const { call } = useApi()
  const rentedBuildingId = useSelector(getActiveBuilding)
  const queryParams = useMemo(() => {
    return {
      page,
      perPage,
      invoiceType,
      contractNumber,
      invoicePeriodTo,
      invoicePeriodFrom,
      invoiceDateTo,
      invoiceDateFrom,
      invoiceNumber,
      reference,
      status,
      sort
    }
  }, [
    contractNumber,
    invoiceDateFrom,
    invoiceDateTo,
    invoiceNumber,
    invoicePeriodFrom,
    invoicePeriodTo,
    invoiceType,
    page,
    perPage,
    reference,
    sort,
    status
  ])
  const filters = {
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort,
    status
  }
  const showFilters = useSelector(getInvoicesFilterIsOpen)

  const handleClose = () => {
    dispatch(getSelectedInvoicesFilters(false))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (rentedBuildingId) {
      call(
        getInvoices({
          rentedBuildingId,
          ...queryParams
        })
      )
      call(getRentedBuildingContractNumbers(rentedBuildingId))
    }
  }, [
    call,
    contractNumber,
    invoiceDateFrom,
    invoiceDateTo,
    invoiceNumber,
    invoicePeriodFrom,
    invoicePeriodTo,
    invoiceType,
    page,
    perPage,
    queryParams,
    reference,
    rentedBuildingId,
    sort,
    status
  ])

  return (
    <StyledContainer>
      {showFilters && width < theme.breakpointsValues.desktop ? (
        <MobileFilterContainer>
          <CloseIconWrapper onClick={handleClose}>
            <CrossIcon fill={theme.colors.neutral.darkerGrey} height="14px" />
          </CloseIconWrapper>
          <InvoiceFilter
            closeFilters={handleClose}
            setUrlParams={setUrlParams}
            {...queryParams}
          />
        </MobileFilterContainer>
      ) : (
        <StyledInvoices>
          {width < theme.breakpointsValues.desktop ? (
            <MobileTopBar
              filters={filters as Filters}
              setUrlParams={setUrlParams}
            />
          ) : (
            <>
              <DesktopTopBar setUrlParams={setUrlParams} {...queryParams} />
              <SideBar>
                <InvoiceFilter setUrlParams={setUrlParams} {...queryParams} />
                <ContactSidebar module={Modules.INVOICES} />
              </SideBar>
            </>
          )}
          <Content>
            <Invoices
              page={page}
              perPage={perPage}
              setUrlParams={setUrlParams}
              sort={sort}
              queryParams={queryParams}
            />
            <Divider />
            <ContactFooter module={Modules.INVOICES} />
          </Content>
        </StyledInvoices>
      )}
    </StyledContainer>
  )
}

export default InvoicesPage
