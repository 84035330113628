import { Pagination } from "@paudigital/wdp-components"
import DocumentCard from "app/components/DocumentCard"
import { getDocuments, getParams } from "app/redux/document/document.selectors"
import { usePagination } from "hooks/pagination"
import { useTranslate } from "hooks/translate"
import { useViewport } from "hooks/viewport"
import { useSelector } from "react-redux"
import { Filters } from "routes/Documents"
import {
  PaginationWrapper,
  StyledDocumentContainer,
  StyledDocumentsContainer
} from "./style"

type Props = {
  page: number
  perPage: number
  sort?: string[]
  setUrlParams: (params: Filters) => void
  queryParams?: Filters
}

const Documents = ({
  page,
  perPage,
  sort,
  setUrlParams,
  queryParams
}: Props) => {
  const { translate } = useTranslate()
  const { width } = useViewport()
  const params = useSelector(getParams)
  const { previousPage, nextPage, toPage } = usePagination({
    pages: params.pages!,
    page,
    perPage,
    sort,
    setUrlParams,
    queryParams
  })
  const documents = useSelector(getDocuments)

  return (
    <StyledDocumentContainer>
      {documents.length > 0 && (
        <StyledDocumentsContainer>
          {documents.map((document, i) => {
            return <DocumentCard document={document} key={i} />
          })}
          <PaginationWrapper>
            {
              <Pagination
                page={Number(page)}
                perPage={Number(perPage)}
                pages={params.pages}
                nextPage={nextPage}
                previousPage={previousPage}
                goToPage={toPage}
                showPages={3}
                width={width}
                next={translate("portal.facilitymanagement.pagination.next")}
                previous={translate(
                  "portal.facilitymanagement.pagination.previous"
                )}
              />
            }
          </PaginationWrapper>
        </StyledDocumentsContainer>
      )}
    </StyledDocumentContainer>
  )
}

export default Documents
