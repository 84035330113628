import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  color: ${theme.colors.neutral.darkerGrey};
`

export const ImageWrapper = styled.div`
  width: 24.1rem;

  > svg {
    height: auto;
  }
`

export const StyledDescription = styled.p`
  text-align: center;
  padding: 2rem 0;
  font-size: 1.3rem;
`

export const ListContainer = styled.div`
  margin: 0 auto 4.8rem;
  max-width: 36.6rem;
  font-size: 1.1rem;
  line-height: 1.27;
`

export const ListTitle = styled.p`
  line-height: 1.64;
  font-weight: 700;
`

export const List = styled.ul`
  list-style: disc;
  margin-left: 1.5rem;

  a {
    color: ${theme.colors.green.contrast};
  }
`
