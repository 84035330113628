import {
  BaseCard,
  Button,
  CardActions,
  CardContent,
  theme
} from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)`
  width: 100%;
  display: flex;
  background: ${theme.colors.neutral.white};
  min-height: 12rem;
  margin: 1rem 0;
  align-items: flex-start;

  &:hover {
    background-color: ${theme.colors.stromboliGreen.prc20};
  }
`

export const StyledDataIdDiv = styled.div`
  display: contents;
`

export const StyledButton = styled(Button)`
  height: 100%;

  button {
    font-weight: ${theme.fontWeights.regular};
    border: 0;
    border-left: 1px solid ${theme.colors.neutral.midGrey};
    border-radius: 0;
    height: 100%;
    min-width: 7.4rem;

    &:hover {
      background-color: initial;
    }
  }
`

export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
`

export const StyledTitle = styled.span`
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.caption};
  margin-bottom: 0.4rem;
`

export const StyledInfo = styled.div`
  margin-bottom: 1.6rem;
`

export const StyledCardContent = styled(CardContent)`
  flex-direction: column;
  align-items: flex-start;
  margin: 1.6rem;
`
