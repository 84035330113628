import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledInvoices = styled.div`
  margin: 4rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(4rem, auto) 1fr;
  gap: 1.2rem;
  grid-template-areas:
    "topbar-mobile"
    "content";

  @media only screen and (${theme.breakpoints.desktop}) {
    grid-template-columns: 0.3fr 1fr;
    grid-template-areas:
      "sidebar topbar"
      "sidebar content";
  }
`

export const StyledContainer = styled.div`
  margin-top: 3.6rem;
  display: flex;
  justify-content: center;
`

export const MobileFilterContainer = styled.div`
  width: 100%;
  position: relative;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 2.4rem;
`

export const SideBar = styled.aside`
  max-width: 25rem;
  grid-area: sidebar;
`

export const Content = styled.main`
  grid-area: content;
`
