import { StyledFooter } from "./style"
import { useTranslate } from "hooks/translate"

const Footer = () => {
    const { translate } = useTranslate()

    return (
      <StyledFooter data-testid="footer-container">
        <span>
          <a
            rel="noreferrer"
            target="_blank"
            href={translate("portal.footer.privacyPolicy.link")}
          >
            {translate("portal.footer.privacyPolicy")}
          </a>
        </span>
        <span>|</span>
        <span>
          <a
            rel="noreferrer"
            target="_blank"
            href={translate("portal.footer.termsAndConditions.link")}
          >
            {translate("portal.footer.termsAndConditions")}
          </a>
        </span>
      </StyledFooter>
    )
  }

  export default Footer
