import React from "react"
import {
  StyledFilterHeader,
  StyledFilterTitle,
  StyledClearFilters,
  StyledFilterSubTitle,
  FilterInput
} from "./style"
import { useTranslate } from "hooks/translate"
import PageFilter from "app/components/Page/PageFilter"
import DateRangePicker from "app/components/DateRangePicker"
import TypeFilter from "./TypeFilter"
import ContractNumberFilter from "./ContractNumberFilter"
import Sort from "./Sort"
import { useViewport } from "hooks/viewport"
import { theme } from "@paudigital/wdp-components"
import { trimEvent } from "app/utils/trimEvent.utils"
import { Filters } from "routes/Invoices"
import StatusFilter from "./StatusFilter"

export type FilterProps = {
  closeFilters?: () => void
  setUrlParams: (params: any) => void
} & Filters

type Props = FilterProps

const InvoiceFilter = ({
  closeFilters,
  page,
  perPage,
  invoiceType,
  contractNumber,
  invoicePeriodTo,
  invoicePeriodFrom,
  invoiceDateTo,
  invoiceDateFrom,
  invoiceNumber,
  reference,
  sort,
  status,
  setUrlParams
}: Props) => {
  const { translate } = useTranslate()
  const { width } = useViewport()
  const queryParams = {
    page,
    perPage,
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort,
    status
  }

  const updateDate = (date: string, name: string, id: string) => {
    const field = id + name.charAt(0).toUpperCase() + name.substring(1)

    setUrlParams({
      ...queryParams,
      page: 1,
      [field]: date === "Invalid date" ? null : date
    })
  }

  const changeInvoiceNumberOrReference = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const event = trimEvent(e)
    const { name } = e.currentTarget
    const { value } = event.currentTarget

    setUrlParams({
      ...queryParams,
      page: 1,
      [name]: value !== "" ? value : undefined
    })
  }

  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const event = trimEvent(e)
      const { name } = e.currentTarget
      const { value } = event.currentTarget

      setUrlParams({
        ...queryParams,
        page: 1,
        [name]: value !== "" ? value : undefined
      })
    }
  }

  const clear = () => setUrlParams({ page: 1, perPage: 20, sort })

  return (
    <PageFilter closeFilters={closeFilters}>
      <StyledFilterHeader>
        <StyledFilterTitle>
          {translate("portal.invoices.filter.description")}
        </StyledFilterTitle>
        <StyledClearFilters onClick={clear}>
          {translate("portal.invoices.filter.clear")}
        </StyledClearFilters>
      </StyledFilterHeader>
      <>
        {width < theme.breakpointsValues.tablet && (
          <Sort setUrlParams={setUrlParams} {...queryParams} />
        )}
      </>
      <StyledFilterSubTitle>
        {translate("portal.invoices.filter.invoiced-period")}
      </StyledFilterSubTitle>
      <DateRangePicker
        id="invoicePeriod"
        from={invoicePeriodFrom}
        to={invoicePeriodTo}
        updateDate={updateDate}
      />
      <StyledFilterSubTitle>
        {translate("portal.invoices.filter.invoice-date")}
      </StyledFilterSubTitle>
      <DateRangePicker
        id="invoiceDate"
        from={invoiceDateFrom as string}
        to={invoiceDateTo as string}
        updateDate={updateDate}
      />
      <StyledFilterSubTitle>
        {translate("portal.invoices.filter.type")}
      </StyledFilterSubTitle>
      <TypeFilter setUrlParams={setUrlParams} {...queryParams} />
      <StyledFilterSubTitle>
        {translate("portal.invoices.filter.status")}
      </StyledFilterSubTitle>
      <StatusFilter setUrlParams={setUrlParams} {...queryParams} />
      <StyledFilterSubTitle>
        {translate("portal.invoices.filter.invoice-number")}
      </StyledFilterSubTitle>
      <FilterInput
        name="invoiceNumber"
        defaultValue={invoiceNumber as string}
        onKeyDown={handleChange}
        onBlur={changeInvoiceNumberOrReference}
      />
      <StyledFilterSubTitle>
        {translate("portal.invoices.filter.contract-number")}
      </StyledFilterSubTitle>
      <ContractNumberFilter setUrlParams={setUrlParams} {...queryParams} />
    </PageFilter>
  )
}

export default InvoiceFilter
