import { useTranslate } from "hooks/translate"
import {
  TopBar,
  Wrapper,
  StyledTotalTickets,
  TitleWrapper,
  StyledButton
} from "./style"
import Sort from "../../DocumentsFilter/Sort"
import {
  getTotalDocuments,
  isLoading
} from "app/redux/document/document.selectors"
import { useDispatch, useSelector } from "react-redux"
import { Spinner, DownloadIcon, theme } from "@paudigital/wdp-components"
import { Filters } from "routes/Documents"
import { useApi } from "hooks/api"
import { downloadDocumentsCSV } from "app/redux/document/document.actions"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

export type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const DesktopTopBar = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const total = useSelector(getTotalDocuments)
  const loading = useSelector(isLoading)
  const dispatch = useDispatch()
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }
  const handleDocumentsDownload = () => {
    dispatch(createGAEvent(PossibleGAEvents.DOWNLOAD_OVERVIEW))
    call(downloadDocumentsCSV(queryParams))
  }

  const isFiltered =
    documentType ||
    uploadDateFrom ||
    uploadDateTo ||
    uploadedBy ||
    inspectionDateFrom ||
    inspectionDateTo ||
    contractId

  return (
    <TopBar>
      <Wrapper>
        <TitleWrapper>
          {loading && <Spinner />}
          <StyledTotalTickets loading={Number(loading)}>
            {isFiltered
              ? translate(
                  `portal.documentmanagement.total${loading ? ".loading" : ""}`,
                  {
                    total
                  }
                )
              : translate("portal.documentmanagement.total.all", { total })}
          </StyledTotalTickets>
        </TitleWrapper>
        <StyledButton
          type="secondary"
          label={translate("portal.documents.downloadOverview.button.label")}
          startIcon={
            <DownloadIcon
              height="3rem"
              width="2.5rem"
              fill={theme.colors.green.contrast}
            />
          }
          onClick={handleDocumentsDownload}
        />
      </Wrapper>
      <Sort setUrlParams={setUrlParams} {...queryParams} />
    </TopBar>
  )
}

export default DesktopTopBar
