export enum Types {
  SET = "SET_TOKEN"
}

export type Action = SetTokenAction

export type SetTokenAction = {
  payload: { token: string }
  type: Types.SET
}

export function setToken(token: string): SetTokenAction {
  return {
    payload: { token },
    type: Types.SET
  }
}
