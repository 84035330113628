import {
  Button,
  PreferencesIcon,
  theme,
  Spinner,
  DownloadIcon
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import {
  TopBarMobile,
  StyledTotalTickets,
  TopButtons,
  TitleWrapper,
  ButtonWrapper
} from "./style"
import Sort from "../../DocumentsFilter/Sort"
import { useViewport } from "hooks/viewport"
import { useDispatch, useSelector } from "react-redux"
import {
  getDocumentsFilterIsOpen,
  getTotalDocuments,
  isLoading
} from "app/redux/document/document.selectors"
import {
  downloadDocumentsCSV,
  getSelectedDocumentsFilters
} from "app/redux/document/document.actions"
import { Filters } from "routes/Documents"
import { useApi } from "hooks/api"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

export type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const MobileTopBar = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { call } = useApi()
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { width } = useViewport()
  const showFilters = useSelector(getDocumentsFilterIsOpen)
  const total = useSelector(getTotalDocuments)
  const loading = useSelector(isLoading)
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }

  const isFiltered =
    documentType ||
    uploadDateFrom ||
    uploadDateTo ||
    uploadedBy ||
    inspectionDateFrom ||
    inspectionDateTo ||
    contractId

  const toggleFilters = () => {
    dispatch(getSelectedDocumentsFilters(!showFilters))
  }

  const handleDocumentsDownload = () => {
    dispatch(createGAEvent(PossibleGAEvents.DOWNLOAD_OVERVIEW))
    call(downloadDocumentsCSV(queryParams))
  }

  return (
    <TopBarMobile isOpen={showFilters}>
      <TitleWrapper>
        {loading && <Spinner />}
        <StyledTotalTickets>
          {isFiltered
            ? translate(
                `portal.documentmanagement.total${loading ? ".loading" : ""}`,
                {
                  total
                }
              )
            : translate("portal.documentmanagement.total.all", { total })}
        </StyledTotalTickets>
      </TitleWrapper>
      <TopButtons>
        <ButtonWrapper>
          <Button
            type="secondary"
            label={translate("portal.documentmanagement.filter.button.label")}
            onClick={toggleFilters}
            startIcon={<PreferencesIcon fill={theme.colors.green.contrast} />}
          />
          <Button
            type="secondary"
            fullWidth
            label={translate("portal.documents.downloadOverview.button.label")}
            startIcon={
              <DownloadIcon
                height="3rem"
                width="2.5rem"
                fill={theme.colors.green.contrast}
              />
            }
            onClick={handleDocumentsDownload}
          />
        </ButtonWrapper>
        {width >= theme.breakpointsValues.tablet && (
          <Sort setUrlParams={setUrlParams} {...queryParams} />
        )}
      </TopButtons>
    </TopBarMobile>
  )
}

export default MobileTopBar
