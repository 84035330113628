import { useTranslate } from "hooks/translate"
import * as deleteCpSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { useSelector } from "react-redux"
import {
  DeleteModalTextBold,
  DeleteModalTextRegular,
  DeleteModalTitle,
  DeleteModalWrapper,
  FullWidthWrapper,
  IllustrationWrapper
} from "../style"
import { Button, TrashIllustration } from "@paudigital/wdp-components"

type AdminRequiredModalType = {
  onCancel: () => void
}

type Props = AdminRequiredModalType

const AdminRequiredModal = ({ onCancel }: Props) => {
  const { translate } = useTranslate()
  const email = useSelector(deleteCpSelectors.getEmailOfContactPerson)
  const buildingLocationString = useSelector(
    deleteCpSelectors.getBuildingLocationsCpCanBeDeletedFrom
  )

  return (
    <DeleteModalWrapper>
      <IllustrationWrapper>
        <TrashIllustration />
      </IllustrationWrapper>
      <DeleteModalTitle>
        {translate("portal.admin.delete.seat.admin.required")}
      </DeleteModalTitle>
      <DeleteModalTextBold marginBottom="2.4rem">
        {translate("portal.admin.delete.seat.admin.required.action", {
          buildingLocations: buildingLocationString,
          email
        })}
      </DeleteModalTextBold>
      <DeleteModalTextRegular marginBottom="3.2rem">
        {translate("portal.admin.delete.seat.admin.required.description", {
          email
        })}
      </DeleteModalTextRegular>
      <FullWidthWrapper>
        <Button
          type="primary"
          label={translate("portal.admin.delete.seat.cancel")}
          onClick={onCancel}
          fullWidth
        />
      </FullWidthWrapper>
    </DeleteModalWrapper>
  )
}

export default AdminRequiredModal
