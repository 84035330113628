import { TagEnum } from "../graphs/graphs.actions"
import { Action, Types } from "./energyCSV.actions"

export type EnergyCSVState = {
  errors?: Record<string, unknown>
  loading: boolean
  selected: TagEnum[]
}

const initialState: EnergyCSVState = {
  errors: undefined,
  loading: false,
  selected: []
}

export default (state = initialState, action: Action): EnergyCSVState => {
  switch (action.type) {
    case Types.DOWNLOAD_CSV: {
      return {
        ...state,
        loading: true
      }
    }

    case Types.DOWNLOAD_CSV_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }

    case Types.DOWNLOAD_CSV_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }
    }

    case Types.SET_SELECTED_METER: {
      return {
        ...state,
        selected: action.payload.selected
      }
    }

    default:
      return state
  }
}
