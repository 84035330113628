import enGbFaq from "./en-GB.faq.md"
import enGbTranslations from "./en-GB.translations.json"
import frFrFaq from "./fr-FR.faq.md"
import frFrTranslations from "./fr-FR.translations.json"
import nlBeFaq from "./nl-BE.faq.md"
import nlBeTranslations from "./nl-BE.translations.json"
import nlNlFaq from "./nl-NL.faq.md"
import nlNlTranslations from "./nl-NL.translations.json"
import roRoFaq from "./ro-RO.faq.md"
import roROTranslations from "./ro-RO.translations.json"
import deDeFaq from "./de-DE.faq.md"
import deDETranslations from "./de-DE.translations.json"
import { createIntl, createIntlCache, IntlShape } from "react-intl"

export enum Locales {
  EN_GB = "en-GB",
  NL_BE = "nl-BE",
  NL_NL = "nl-NL",
  FR_FR = "fr-FR",
  RO_RO = "ro-RO",
  DE_DE = "de-DE"
}

const locales = [...Object.values(Locales)]

const translations = {
  [Locales.EN_GB]: {
    ...enGbTranslations
  },
  [Locales.FR_FR]: {
    ...frFrTranslations
  },
  [Locales.NL_BE]: {
    ...nlBeTranslations
  },
  [Locales.NL_NL]: {
    ...nlNlTranslations
  },
  [Locales.RO_RO]: {
    ...roROTranslations
  },
  [Locales.DE_DE]: {
    ...deDETranslations
  }
}

const cache = createIntlCache()

const intl: Record<Locales, IntlShape> = {
  [Locales.EN_GB]: createIntl(
    {
      locale: Locales.EN_GB,
      messages: translations[Locales.EN_GB]
    },
    cache
  ),
  [Locales.NL_BE]: createIntl(
    {
      locale: Locales.NL_BE,
      messages: translations[Locales.NL_BE]
    },
    cache
  ),
  [Locales.NL_NL]: createIntl(
    {
      locale: Locales.NL_NL,
      messages: translations[Locales.NL_NL]
    },
    cache
  ),
  [Locales.FR_FR]: createIntl(
    {
      locale: Locales.FR_FR,
      messages: translations[Locales.FR_FR]
    },
    cache
  ),
  [Locales.RO_RO]: createIntl(
    {
      locale: Locales.RO_RO,
      messages: translations[Locales.RO_RO]
    },
    cache
  ),
  [Locales.DE_DE]: createIntl(
    {
      locale: Locales.DE_DE,
      messages: translations[Locales.DE_DE]
    },
    cache
  )
}

const faq: Record<Locales, string> = {
  [Locales.EN_GB]: enGbFaq,
  [Locales.NL_NL]: nlNlFaq,
  [Locales.NL_BE]: nlBeFaq,
  [Locales.FR_FR]: frFrFaq,
  [Locales.RO_RO]: roRoFaq,
  [Locales.DE_DE]: deDeFaq
}

const formats = {
  date: {
    full: {
      [Locales.EN_GB]: "MMMM do yyyy 'at' p",
      [Locales.NL_NL]: "d MMMM yyyy 'om' p",
      [Locales.NL_BE]: "d MMMM yyyy 'om' p",
      [Locales.FR_FR]: "d MMMM yyyy 'à' p",
      [Locales.RO_RO]: "MMMM do yyyy 'at' p",
      [Locales.DE_DE]: "d MMMM yyyy 'um' p"
    }
  }
}

export { locales, translations, formats, intl, faq }
