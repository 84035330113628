import { theme } from "@paudigital/wdp-components"
import { FacilityTicketStatus } from "app/types/ticket"

export type ColorMappingType = Record<FacilityTicketStatus, string>

export const colorMapping: ColorMappingType = {
  [FacilityTicketStatus.AWAITING_PROCESSING]: theme.colors.system.reported,
  [FacilityTicketStatus.INFO_NEEDED]: theme.colors.system.moreInfo,
  [FacilityTicketStatus.PROCESSING]: theme.colors.system.warning,
  [FacilityTicketStatus.PLANNED]: theme.colors.system.planned,
  [FacilityTicketStatus.FINISHED]: theme.colors.system.success,
  [FacilityTicketStatus.CANCELLED]: theme.colors.neutral.granite,
  [FacilityTicketStatus.CLOSED]: theme.colors.neutral.granite
}
