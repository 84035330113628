import {
  NotificationSettings,
  NotificationSettingsData
} from "app/types/notificationSettings"
import {
  getPreferredBuildingId,
  getRentedBuildingsOrderedByPreferredBuilding,
  getUserId,
  hasMultipleCompanies
} from "app/redux/me/me.selectors"
import { getContactPersons } from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import State from "../state"
import { mapReducerDataToNestedCheckboxDataStructure } from "app/utils/notificationSettings"
import { RentedBuilding } from "app/types/rentedBuilding"
import { AssignedPerson } from "app/types/assignedPerson"
import { Data } from "@paudigital/wdp-components"

export const isLoading = (state: State): boolean =>
  state.notificationsSettings.loading
export const getNotificationsSettings = (
  state: State
): NotificationSettings[] | null => state.notificationsSettings.data

export const getIsDirty = (state: State): boolean =>
  state.notificationsSettings.isDirty

export const getRentedBuildingNotificationsSettings = (
  state: State
): Record<string, Data> => {
  const notificationSettings = getNotificationsSettings(state) ?? []
  const preferredBuildingId = getPreferredBuildingId(state)
  const contactPersonId = getUserId(state)
  const isDirty = getIsDirty(state)
  const rentedBuildings =
    getRentedBuildingsOrderedByPreferredBuilding(state) ?? []
  const rentedBuildingsContactPersons = getContactPersons(state) ?? []
  return rentedBuildings.reduce(
    (
      preferenceObj: NotificationSettingsData,
      rentedBuilding: RentedBuilding
    ) => {
      const notificationSetting = notificationSettings.find(
        (setting: NotificationSettings) =>
          setting.rentedBuildingId === rentedBuilding.id
      )
      const contactPersons = rentedBuildingsContactPersons.find(
        (building: AssignedPerson) => building.id === rentedBuilding.id
      )
      if (!notificationSetting || !contactPersons) {
        return preferenceObj
      }
      const contactPersonData = contactPersons.data.find(
        person => person.id === contactPersonId
      )
      if (!contactPersonData) {
        return preferenceObj
      }

      if (!isDirty && rentedBuilding.id === preferredBuildingId) {
        if (typeof notificationSetting?.invoice !== "undefined") {
          notificationSetting.invoice = true
        }
        if (typeof notificationSetting?.documents !== "undefined") {
          notificationSetting.documents = true
        }
        if (typeof notificationSetting?.facility !== "undefined") {
          notificationSetting.facility = true
        }
      }

      return {
        ...preferenceObj,
        [rentedBuilding.id]: mapReducerDataToNestedCheckboxDataStructure(
          hasMultipleCompanies(state),
          rentedBuilding,
          notificationSetting,
          contactPersonData.roles
        )
      }
    },
    {}
  )
}

export const getAmountOfNotificationsSettings = (state: State): number =>
  state.notificationsSettings.data?.length || 0
