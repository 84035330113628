import { RentedBuildingContract } from "app/types/rentedBuildingContract"

export enum Types {
  GET = "GET_RENTED_BUILDING_CONTRACTS",
  GET_SUCCESS = "GET_RENTED_BUILDING_CONTRACTS_SUCCESS",
  GET_FAIL = "GET_RENTED_BUILDING_CONTRACTS_FAIL",
  GET_CONTRACT_NUMBERS = "GET_CONTRACT_NUMBERS",
  GET_CONTRACT_NUMBERS_SUCCESS = "GET_CONTRACT_NUMBERS_SUCCESS",
  GET_CONTRACT_NUMBERS_FAIL = "GET_CONTRACT_NUMBERS_FAIL"
}

export type Action =
  | GetRentedBuildingContractsAction
  | GetRentedBuildingContractsSuccessAction
  | GetRentedBuildingContractsFailAction
  | GetRentedBuildingContractNumbersAction
  | GetRentedBuildingContractNumbersSuccessAction
  | GetRentedBuildingContractNumbersFailAction

export type GetRentedBuildingContractsAction = {
  payload: { rentedBuildingId: string }
  type: Types.GET
}

export type GetRentedBuildingContractsSuccessAction = {
  payload: { data: RentedBuildingContract[] }
  type: Types.GET_SUCCESS
}

export type GetRentedBuildingContractsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type GetRentedBuildingContractNumbersAction = {
  payload: { rentedBuildingId: string }
  type: Types.GET_CONTRACT_NUMBERS
}

export type GetRentedBuildingContractNumbersSuccessAction = {
  payload: { data: string[] }
  type: Types.GET_CONTRACT_NUMBERS_SUCCESS
}

export type GetRentedBuildingContractNumbersFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_CONTRACT_NUMBERS_FAIL
}

export function getRentedBuildingContracts(rentedBuildingId: string): Action {
  return {
    type: Types.GET,
    payload: { rentedBuildingId }
  }
}

export function getRentedBuildingContractsSuccess(
  data: RentedBuildingContract[]
): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getRentedBuildingContractsFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function getRentedBuildingContractNumbers(
  rentedBuildingId: string
): Action {
  return {
    type: Types.GET_CONTRACT_NUMBERS,
    payload: { rentedBuildingId }
  }
}

export function getRentedBuildingContractNumbersSuccess(
  data: string[]
): Action {
  return {
    payload: { data },
    type: Types.GET_CONTRACT_NUMBERS_SUCCESS
  }
}

export function getRentedBuildingContractNumbersFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_CONTRACT_NUMBERS_FAIL
  }
}
