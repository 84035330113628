import { object, string } from "yup"

export const validationSchema = object({
  description: string()
    .required(
      "portal.facilitymanagement.createticket.step3.subject.error.required"
    )
    .test(
      "length under 100",
      "portal.facilitymanagement.createticket.step3.subject.error.max",
      val => (val && val?.length <= 100 ? true : false)
    ),

  communicationCustomer: string().test(
    "length under 30",
    "portal.facilitymanagement.createticket.step3.additional.error.max",
    val => {
      if (!val) return true
      if (val === "") return true
      return val && val?.length <= 2000 ? true : false
    }
  )
})
