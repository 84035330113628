export type BuildingSpecification = {
  id: string
  sourceId: string
  address: {
    country: string
    city: string
    postalCode: string
    street: string
    houseNo: string
    latitude: string
    longitude: string
  }
  buildYear?: string
  description?: string
  lastModernizationYear?: string
  lastReconstructionYear?: string
  validFrom?: string
  validTo?: string
  company?: string
  businessEntityNumber?: string
  breeam?: string
  breeamDescription?: string
  edge?: string
  edgeDescription?: string
  quality?: string
  qualityDescription?: string
  tapa?: string
  tapaDescription?: string
  sprinklered?: boolean
  sprinkleredDescription?: string
  floorFlatnessCategory?: string
  floorFlatnessStandardDescription?: string
  floorFlatnessStandard?: string
  measurements: Measurement[]
  created_on: string
  updatedOn: string
  freeHeight: SpecialSpecification
  floorLoad: SpecialSpecification
  depth: SpecialSpecification
  depthWarehouse: SpecialSpecification
  depthParking: SpecialSpecification
  solarPower: SpecialSpecification
  loadingDocksLevel: SpecialSpecification
  loadingDocksTrucks: SpecialSpecification
  loadingDocksVan: SpecialSpecification
  rentalSpaces: FlattenedRentalSpaces
}

export type BuildingInfo = {
  id: string,
  city: string,
  postalCode: string,
  street: string,
  houseNo: string,
  customer: string,
  rb_country?: string,
  rb_end_date?: string
}

export type SpecialSpecification = {
  unit: MeasurementUnit
  value: number[]
}

export type Measurement = {
  type: MeasurementTypes
  unit: MeasurementUnits
  value: number | string
  validFrom: string
  validTo: string
}

export type RentalSpaceType =
  "0004" | 
  "0005" | 
  "0006" | 
  "0079" | 
  "0080" | 
  "0081" | 
  "0082" | 
  "0090" | 
  "0091" | 
  "0092"

export type MeasurementType =
  "Z000" |
  "Z001" |
  "Z002" |
  "Z006" |
  "Z007" |
  "Z008" |
  "Z009" |
  "Z010" |
  "Z011" |
  "Z012" |
  "Z013" |
  "Z014" |
  "Z015" |
  "Z016" |
  "Z017" |
  "Z100" |
  "Z101" |
  "Z102" |
  "Z103" |
  "Z104" |
  "Z105" |
  "Z106" |
  "Z107" |
  "Z108" |
  "Z109" |
  "Z120" |
  "Z121" |
  "Z122" |
  "Z123" |
  "Z124" |
  "Z125" |
  "Z126" |
  "Z127" |
  "Z128" |
  "Z129" |
  "Z130" |
  "Z131" |
  "Z132" |
  "Z133" |
  "Z134" |
  "Z135" |
  "Z136" |
  "Z137" |
  "Z138" |
  "Z201" |
  "Z202" |
  "Z203" |
  "Z204" |
  "Z205"

export type MeasurementUnit =
  "M2" |
  "%" |
  "EA" |
  "PC" |
  "M" |
  "KGF" |
  "DEG" |
  "KWH"

export type RentalSpaceMeasurement = {
  unit: MeasurementUnit
  value: number
  validFrom: string
  validTo: string
}

export type FlattenedRentalSpaces = Partial<
  Record<
    RentalSpaceType,
    Partial<
      Record<
        MeasurementType,
        RentalSpaceMeasurement
      >
    >
  >
>

export enum RentalSpaceTypes {
  "0004" = "0004",
  "0005" = "0005",
  "0006" = "0006",
  "0079" = "0079",
  "0080" = "0080",
  "0081" = "0081",
  "0082" = "0082",
  "0090" = "0090",
  "0091" = "0091",
  "0092" = "0092"
}

export enum MeasurementTypes {
  "Z000" = "Z000",
  "Z001" = "Z001",
  "Z002" = "Z002",
  "Z006" = "Z006",
  "Z007" = "Z007",
  "Z008" = "Z008",
  "Z009" = "Z009",
  "Z010" = "Z010",
  "Z011" = "Z011",
  "Z012" = "Z012",
  "Z013" = "Z013",
  "Z014" = "Z014",
  "Z015" = "Z015",
  "Z016" = "Z016",
  "Z017" = "Z017",
  "Z100" = "Z100",
  "Z101" = "Z101",
  "Z102" = "Z102",
  "Z103" = "Z103",
  "Z104" = "Z104",
  "Z105" = "Z105",
  "Z106" = "Z106",
  "Z107" = "Z107",
  "Z108" = "Z108",
  "Z109" = "Z109",
  "Z120" = "Z120",
  "Z121" = "Z121",
  "Z122" = "Z122",
  "Z123" = "Z123",
  "Z124" = "Z124",
  "Z125" = "Z125",
  "Z126" = "Z126",
  "Z127" = "Z127",
  "Z128" = "Z128",
  "Z129" = "Z129",
  "Z130" = "Z130",
  "Z131" = "Z131",
  "Z132" = "Z132",
  "Z133" = "Z133",
  "Z134" = "Z134",
  "Z135" = "Z135",
  "Z136" = "Z126",
  "Z137" = "Z137",
  "Z138" = "Z138",
  "Z201" = "Z201",
  "Z202" = "Z202",
  "Z203" = "Z203",
  "Z204" = "Z204",
  "Z205" = "Z205"
}

export enum MeasurementUnits {
  "M2" = "M2",
  "%" = "%",
  "EA" = "EA",
  "PC" = "PC",
  "M" = "M",
  "KGF" = "KGF",
  "DEG" = "DEG",
  "KWH" = "KWH"
}
