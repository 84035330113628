import { SlideIn, TrashIllustration } from "@paudigital/wdp-components"
import { clear } from "app/redux/appEvents/appEvents.actions"
import { useApi } from "hooks/api"
import { useDispatch } from "react-redux"
import * as actions from "app/redux/ticket/ticket.actions"
import {
  IllustrationWrapper,
  StyledContainer,
  ButtonContainer,
  StyledButton,
  StyledTitle
} from "./style"
import { Ticket } from "app/types/ticket"
import { useTranslate } from "hooks/translate"

export type CancelTicketConfirmationProps = {
  ticket: Ticket
  notification: boolean
}

export const CancelTicketConfirmation = ({
  ticket,
  notification
}: CancelTicketConfirmationProps) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { call } = useApi()
  const handleClose = () => {
    dispatch(clear())
  }

  const onConfirm = () => {
    if (ticket) {
      dispatch(clear())
      call(actions.cancelTicket(ticket.ticketId, notification))
    }
  }

  return (
    <SlideIn onClose={handleClose} show showCloseIcon>
      <StyledContainer>
        <IllustrationWrapper>
          <TrashIllustration />
        </IllustrationWrapper>
        <StyledTitle>
          {translate(
            "portal.facilitymanagement.modal.ticket.confirmation-text",
            { description: ticket.description }
          )}
        </StyledTitle>
        <ButtonContainer>
          <StyledButton
            type="secondary"
            label={translate(
              "portal.facilitymanagement.modal.ticket.cancel.button"
            )}
            onClick={handleClose}
          />
          <StyledButton
            type="delete"
            label={translate(
              "portal.facilitymanagement.modal.ticket.confirm.button"
            )}
            onClick={onConfirm}
          />
        </ButtonContainer>
      </StyledContainer>
    </SlideIn>
  )
}
