import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Summary = styled.div`
  border-radius: 4px;
  background: ${theme.colors.neutral.lightGrey};
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 48rem;
  }
`

export const BaseSpan = styled.span`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
`

export const SummaryDetail = styled(BaseSpan)`
  width: calc(100% - 12rem);
`

export const SummaryItem = styled.div`
  display: flex;
  margin-bottom: 1.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const SummaryLabel = styled(BaseSpan)`
  font-weight: ${theme.fontWeights.bold};
  display: block;
  width: 12rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
`
