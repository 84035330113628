import { QueryParams } from "app/redux/invoices/invoices.actions"
import { getUTCIsoDate } from "./timeZone.utils"

export const castInvoiceParamsToUTC = (
  invoiceParams: QueryParams,
  country: string
): QueryParams => {
  const {
    invoicePeriodFrom,
    invoicePeriodTo,
    invoiceDateTo,
    invoiceDateFrom
  } = invoiceParams
  if (invoicePeriodFrom) {
    const dateInUTC = getUTCIsoDate(invoicePeriodFrom, country)
    invoiceParams.invoicePeriodFrom = dateInUTC
  }
  if (invoicePeriodTo) {
    const dateInUTC = getUTCIsoDate(invoicePeriodTo, country)
    invoiceParams.invoicePeriodTo = dateInUTC
  }
  if (invoiceDateFrom) {
    const dateInUTC = getUTCIsoDate(invoiceDateFrom, country)
    invoiceParams.invoiceDateFrom = dateInUTC
  }
  if (invoiceDateTo) {
    const dateInUTC = getUTCIsoDate(invoiceDateTo, country)
    invoiceParams.invoiceDateTo = dateInUTC
  }
  return invoiceParams
}
