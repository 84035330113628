import React from "react"
import {
  UploadImage,
  InputElement,
  Phone,
  Button,
  Checkbox,
  Link,
  InputError
} from "@paudigital/wdp-components"
import Terms from "../../../TermsAndConditions"
import {
  AddPhoneWrapper,
  ErrorWrapper,
  LinkWrapper,
  TermsWrapper
} from "./style"
import { useFormik } from "formik"
import { validationSchema, initialValues } from "./config"
import { PatchContactPerson } from "app/types/contactPerson"
import { useSelector } from "react-redux"
import * as actions from "app/redux/contactPerson/contactPerson.actions"
import { getUser, getUserId } from "app/redux/me/me.selectors"
import { useTranslate } from "hooks/translate"
import { useApi } from "hooks/api"
import MarkdownTranslation from "app/components/MarkdownTranslation"

const AccountCreation = () => {
  const { translate } = useTranslate()
  const { call } = useApi()
  const [showTerms, setShowTerms] = React.useState(false)
  const [profilePicture, setProfilePicture] = React.useState<File | null>(null)
  const initialContactPerson = useSelector(getUser)
  const contactPersonId = useSelector(getUserId)

  const handleFileUpload = (data: File) => {
    setProfilePicture(data)
  }

  const handleViewTerms = () => {
    setShowTerms(!showTerms)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (u: PatchContactPerson & { hasExtraPhoneNumber?: boolean }) => {
      if (isValid && contactPersonId) {
        delete u.hasExtraPhoneNumber
        call(actions.patchContactPerson(contactPersonId, u))
        if (profilePicture) {
          call(actions.putProfilePicture(contactPersonId, profilePicture))
        }
      }
    },
    validationSchema
  })

  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
    validateForm,
    values,
    setValues,
    setFieldTouched
  } = formik

  React.useEffect(() => {
    if (initialContactPerson) {
      setValues({
        ...initialContactPerson,
        hasExtraPhoneNumber: initialContactPerson?.mobilephone ? true : false,
        termsAndConditionsAccepted: initialContactPerson.termsAndConditionsAccepted
          ? true
          : false,
        privacyPolicyAccepted: initialContactPerson.privacyPolicyAccepted
          ? true
          : false
      })
    }
  }, [initialContactPerson, setValues])

  const {
    firstName,
    lastName,
    function: cFunction,
    telephone,
    mobilephone,
    termsAndConditionsAccepted,
    privacyPolicyAccepted,
    hasExtraPhoneNumber
  } = values

  const hasErrors = (name: string) => {
    return errors[name as keyof PatchContactPerson] &&
      touched[name as keyof PatchContactPerson]
      ? true
      : false
  }

  const handlePhoneChange = (val: string, name: string) => {
    setFieldTouched(name, true)
    setFieldValue(name, val)
  }

  const handleClick = () => {
    validateForm(values)
  }

  const handleTermsChange = (val: boolean, name: string) => {
    setFieldValue(name, val)
  }

  const handlePrivacyChange = (val: boolean, name: string) => {
    setFieldValue(name, val)
  }

  const handleAddPhone = () => {
    if (hasExtraPhoneNumber) {
      setFieldValue("mobilephone", null)
      setFieldTouched("mobilephone", false)
      setFieldValue("hasExtraPhoneNumber", false)
    } else {
      setFieldValue("hasExtraPhoneNumber", true)
    }
  }

  const handleCustomBlur = (e: React.FocusEvent<any>) => {
    setFieldValue(e.target.name, e.currentTarget.value)
    handleBlur(e)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputElement
          label={translate("portal.contactPerson.input.firstName.label")}
          name="firstName"
          onChange={handleChange}
          onBlur={handleCustomBlur}
          value={firstName}
          charLimit={127}
          error={hasErrors("firstName")}
          errorMessage={hasErrors("firstName") && translate(errors.firstName!)}
          hasSpacing={true}
          dataTestId="portal.contactPerson.firstName"
        />
        <InputElement
          label={translate("portal.contactPerson.input.lastName.label")}
          name="lastName"
          onChange={handleChange}
          onBlur={handleCustomBlur}
          value={lastName}
          charLimit={127}
          error={hasErrors("lastName")}
          errorMessage={hasErrors("lastName") && translate(errors.lastName!)}
          hasSpacing={true}
          dataTestId="portal.contactPerson.lastName"
        />
        <UploadImage
          fileSizeLimit={500000}
          fileSizeErrorMessage={translate(
            "portal.contactPerson.input.image.sizeError"
          )}
          fileTypeErrorMessage={translate(
            "portal.contactPerson.input.image.fileType"
          )}
          buttonLabel={translate("portal.contactPerson.input.image.button")}
          onFileUpload={handleFileUpload}
          initialImage={initialContactPerson?.profilePictureUrl}
          required={false}
          errorMessage={translate("portal.contactPerson.input.image.error")}
          optionalText={translate("portal.input.optional")}
          label={translate("portal.contactPerson.input.image.label")}
        />
        <InputElement
          label={translate("portal.contactPerson.input.function.label")}
          name="function"
          onChange={handleChange}
          onBlur={handleCustomBlur}
          value={cFunction}
          error={hasErrors("function")}
          hasSpacing={true}
          errorMessage={hasErrors("function") && translate(errors.function!)}
          dataTestId="portal.contactPerson.function"
        />
        <Phone
          label={translate("portal.contactPerson.input.phone.label", {
            index: "1"
          })}
          guidanceText={
            <MarkdownTranslation label="portal.contactPerson.input.phone.guidanceText" />
          }
          name="telephone"
          onChange={handlePhoneChange}
          onBlur={handleBlur}
          value={telephone}
          error={hasErrors("telephone")}
          hasSpacing={true}
          errorMessage={hasErrors("telephone") && translate(errors.telephone!)}
          dataTestId="portalContactPersonPhone"
        />
        {hasExtraPhoneNumber && (
          <Phone
            label={translate("portal.contactPerson.input.phone.label", {
              index: "2"
            })}
            optionalText={translate(
              "portal.contactPerson.input.phone.optional"
            )}
            guidanceText={
              <MarkdownTranslation label="portal.contactPerson.input.phone.guidanceText" />
            }
            required={false}
            name="mobilephone"
            onChange={handlePhoneChange}
            onBlur={handleCustomBlur}
            value={mobilephone}
            error={hasErrors("mobilephone")}
            hasSpacing={true}
            errorMessage={
              hasErrors("mobilephone") && translate(errors.mobilephone!)
            }
            dataTestId="portalContactPersonPhone.additional"
          />
        )}
        <AddPhoneWrapper>
          <Link
            type="default"
            value={translate(
              `portal.contactPerson.input.phone.${
                !hasExtraPhoneNumber ? "add" : "remove"
              }`
            )}
            onClick={handleAddPhone}
            dataTestId="portal.contactPerson.input.phone.add"
          />
        </AddPhoneWrapper>
        <TermsWrapper>
          <Checkbox
            name="termsAndConditionsAccepted"
            label={translate("portal.onboarding.terms.checkbox.label")}
            checked={termsAndConditionsAccepted}
            onClick={handleTermsChange}
            required
            error={hasErrors("termsAndConditionsAccepted")}
            hasSpacing={true}
          />
          <LinkWrapper>
            <Link
              type="default"
              value={translate("portal.onboarding.terms.link.label")}
              onClick={handleViewTerms}
            />
          </LinkWrapper>
        </TermsWrapper>
        <TermsWrapper>
          <Checkbox
            name="privacyPolicyAccepted"
            label={translate("portal.onboarding.privacy.checkbox.label")}
            checked={privacyPolicyAccepted}
            onClick={handlePrivacyChange}
            required
            error={hasErrors("privacyPolicyAccepted")}
            hasSpacing={true}
          />
          <LinkWrapper>
            <Link
              href={translate("portal.onboarding.privacy.link")}
              type="default"
              value={translate("portal.onboarding.privacy.link.label")}
            />
          </LinkWrapper>
        </TermsWrapper>
        {hasErrors("termsAndConditionsAccepted") && (
          <ErrorWrapper>
            <InputError
              dataTestId="portal.onboarding.terms.error"
              errorMessage={translate("portal.onboarding.terms.error")}
            />
          </ErrorWrapper>
        )}
        {hasErrors("privacyPolicyAccepted") && (
          <ErrorWrapper>
            <InputError
              dataTestId="portal.onboarding.privacy.error"
              errorMessage={translate("portal.onboarding.privacy.error")}
            />
          </ErrorWrapper>
        )}
        <Button
          label={translate("portal.onboarding.step1.button.next")}
          onClick={handleClick}
          dataTestId="portal.onboarding.step1.next"
          submit
        />
      </form>
      {showTerms && <Terms onClose={handleViewTerms} />}
    </>
  )
}

export default AccountCreation
