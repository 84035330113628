import { AccountCreated, PopUp } from "@paudigital/wdp-components"
import { useDispatch } from "react-redux"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { useTranslate } from "hooks/translate"

const TicketCommunicationPopup = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(remove("ticket-feedback"))
  }

  return (
    <PopUp show onClose={handleClose} delayInMs={4000}>
      <AccountCreated
        value={translate(
          "portal.facilitymanagement.reportdetail.customerFeedback.success"
        )}
      />
    </PopUp>
  )
}

export default TicketCommunicationPopup
