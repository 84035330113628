import { SagaIterator } from "redux-saga"
import { put, takeLatest } from "redux-saga/effects"
import { push } from "redux-first-history"
import * as actions from "./httpErrors.actions"

export function* handleHttpErrorFlow(
  action: actions.HandleHttpError
): SagaIterator {
  const { error, mapping } = action.payload
  const httpStatus = error?.response?.status
  const httpMessage = error?.response?.data?.message
  if (!httpStatus) {
    return
  }
  const match = mapping.find(m => m.expectedHttpStatus === httpStatus)
  if (match) {
    yield put(push(match.redirectTo))
    yield put(actions.handleHttpErrorSuccess())
  }
  yield put(actions.handleHttpErrorNoMatch(httpStatus, httpMessage))
}

export default function* httpErrorsSaga(): SagaIterator {
  yield takeLatest(actions.Types.HANDLE_HTTP_ERROR, handleHttpErrorFlow)
}
