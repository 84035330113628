import { DropDown } from "@paudigital/wdp-components"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { IntervalEnum } from "app/enums/interval"
import { getGranularityOptions } from "app/redux/energyPeriod/energyPeriod.selectors"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import { OnChangeValue } from "react-select"
import { Wrapper } from "./style"

type Props = {
  selectedGranularity: IntervalEnum
  onChange: (interval: IntervalEnum) => void
}

const GranularityDropdown = ({ selectedGranularity, onChange }: Props) => {
  const { translate } = useTranslate()
  const options = useSelector(getGranularityOptions)

  const handleChange = (o: OnChangeValue<CustomOptionType, false>) => {
    const value = (o as CustomOptionType).value as IntervalEnum
    onChange(value)
  }

  return (
    <Wrapper>
      <DropDown
        options={options.map(o => ({
          label: translate(`portal.energy.granularity.option.${o}`),
          value: o
        }))}
        onChange={handleChange}
        hasDropdownIndicator
        defaultValue={translate(
          `portal.energy.granularity.option.${selectedGranularity}`
        )}
      />
    </Wrapper>
  )
}

export default GranularityDropdown
