import {
  StyledNotificationWrapper,
  NotificationType,
  TimeStamp,
  StyledStatusWrapper,
  Content,
  TitleContainer,
  StyledTicketStatus
} from "./style"
import { ModifiedNotification } from "../../TimeLine"
import Line from "./Line"
import NotificationContainer from "./NotificationContainer"
import { format } from "date-fns"
import { NotificationTitles } from "app/types/notification"
import { useTranslate } from "hooks/translate"

export type NotificationWrapperProps = {
  notification: ModifiedNotification
}

type Props = NotificationWrapperProps

const NotificationWrapper = ({ notification }: Props) => {
  const { translate } = useTranslate()
  const getNoficationTitle = (type: NotificationTitles) =>
    translate(
      `portal.notifications.notification.title.${type.replaceAll("_", "-")}`
    )

  const getNotificationTime = (() => {
    const timestamp = format(notification.dateTime, "HH:mm")
    return <TimeStamp>{timestamp}</TimeStamp>
  })()

  if (
    notification.title === NotificationTitles.DOCUMENT_CREATED &&
    !notification.payload.metadata
  ) {
    return null
  }

  return (
    <StyledNotificationWrapper>
      <Line />
      {getNotificationTime}
      <Content>
        <TitleContainer>
          <NotificationType>
            {getNoficationTitle(notification.title)}
          </NotificationType>
          {notification.title ===
            NotificationTitles.FACILITY_TICKET_UPDATED && (
            <StyledStatusWrapper>
              <StyledTicketStatus ticket={notification.payload} />
            </StyledStatusWrapper>
          )}
        </TitleContainer>
        <NotificationContainer notification={notification} />
      </Content>
    </StyledNotificationWrapper>
  )
}

export default NotificationWrapper
