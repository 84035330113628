import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const UsageComparisonBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0;
  width: 100%;
`

export const UsageComparisonBarText = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 0.8rem;
`

export const UsageComparisonBarPercentage = styled.span<{ color: string }>`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  color: ${props => props.color};
`
