import { AccountCreated, PopUp } from "@paudigital/wdp-components"
import { useDispatch } from "react-redux"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { useTranslate } from "hooks/translate"
import Survey from "../Survey"
import { useState } from "react"
import { ModalContentWrapper } from "../Survey/style"
import { SuccessMessageWrapper } from "./style"
import { getSurveys } from "app/redux/surveys/surveys.actions"
import { useApi } from "hooks/api"

const DELAY_CLOSE_POPUP = 5000

const CreateTicketPopUp = () => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const [closeDisabled, setCloseDisabled] = useState(false)

  const handleClose = () => {
    dispatch(remove("ticket-created"))
    call(getSurveys())
  }

  const handleStartSurvey = () => {
    setCloseDisabled(true)
  }

  return (
    <PopUp
      show
      onClose={handleClose}
      disableClose={closeDisabled}
      delayInMs={DELAY_CLOSE_POPUP}
    >
      <ModalContentWrapper>
        <SuccessMessageWrapper data-testid="portal.facilitymanagement.createticket.success">
          <AccountCreated
            value={translate("portal.facilitymanagement.createticket.success")}
          />
        </SuccessMessageWrapper>
        <Survey onStart={handleStartSurvey} />
      </ModalContentWrapper>
    </PopUp>
  )
}

export default CreateTicketPopUp
