import { ModifiedNotification } from "../../TimeLine"
import { isToday, isYesterday } from "date-fns"
import NotificationWrapper from "../NotificationWrapper"
import { DayContainer, DateText } from "./style"
import { useTranslate } from "hooks/translate"
import TimelineHeader from "../../TimelineHeader"
import { formatDateToLocale } from "app/utils/dateFormatter.utils"
import { getPreferredLanguage } from "app/redux/me/me.selectors"
import { useSelector } from "react-redux"
import { Locales } from "app/i18n/config"
import React from "react"

export type DayProps = {
  notifications: ModifiedNotification[]
}

type Props = DayProps

const Day = ({ notifications }: Props) => {
  const { translate } = useTranslate()
  const preferredLanguage: Locales = useSelector(getPreferredLanguage)

  const formatDate = (() => {
    const date = notifications[0].dateTime
    const formattedDate = formatDateToLocale(date, preferredLanguage)

    return (
      <DateText>
        {isToday(date)
          ? translate("portal.notifications.timeline.today")
          : isYesterday(date)
          ? translate("portal.notifications.timeline.yesterday")
          : formattedDate}
      </DateText>
    )
  })()

  return (
    <DayContainer>
      {formatDate}
      {notifications.map((n, i) => {
        const previousNotification = i !== 0 && notifications[i - 1]
        const showReadHeader =
          previousNotification && !previousNotification.read && n.read
        return (
          <React.Fragment key={i}>
            {showReadHeader && (
              <TimelineHeader
                title={translate("portal.notifications.header.allread.title")}
                description={translate(
                  "portal.notifications.header.allread.guidance"
                )}
              />
            )}
            <NotificationWrapper key={n.id} notification={n} />
          </React.Fragment>
        )
      })}
    </DayContainer>
  )
}

export default Day
