import { AxiosPromise } from "axios"
import { auth0Api } from "./api"
import { CLIENT_ID, MFA_AUDIENCE, RENTAL_AUDIENCE } from "./config"
import { AssociationTypes } from "./mfa"

export const Audiences = {
  Rental: RENTAL_AUDIENCE,
  Mfa: MFA_AUDIENCE
}

export enum Scopes {
  Enroll = "enroll",
  UpdatePassword = "update:password",
  Delete = "remove:authenticators"
}

export const authenticate = ({
  username,
  password,
  audience,
  scope
}: {
  username: string
  password: string
  audience: string
  scope: Scopes
}): AxiosPromise => {
  return auth0Api(`/oauth/token`, {
    method: "POST",
    data: {
      grant_type: "password",
      username,
      password,
      client_id: CLIENT_ID,
      audience,
      scope
    }
  })
}

export const verifyMfaOtp = ({
  mfaToken,
  oneTimeCode,
  type,
  oobCode
}: {
  mfaToken: string
  oneTimeCode: string
  type: AssociationTypes
  oobCode?: string
}): AxiosPromise => {
  return auth0Api(`/oauth/token`, {
    method: "POST",
    data: {
      grant_type: `http://auth0.com/oauth/grant-type/mfa-${type}`,
      client_id: CLIENT_ID,
      mfa_token: mfaToken,
      ...(AssociationTypes.OTP && { otp: oneTimeCode })
    }
  })
}

// Pay attention here
export const challengeMfa = ({
  mfaToken,
  authenticatorId
}: {
  mfaToken: string
  authenticatorId: string
}): AxiosPromise => {
  return auth0Api(`/mfa/challenge`, {
    method: "POST",
    data: {
      client_id: CLIENT_ID,
      mfa_token: mfaToken,
      challenge_type: "oob",
      authenticator_id: authenticatorId
    }
  })
}
