import { NotificationSettings } from "app/types/notificationSettings"
import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getNotificationsSettings = (
  contactPersonId: string
): AxiosPromise => {
  return rentalApi(
    `/contact_persons/${contactPersonId}/notification_settings`,
    {
      method: "GET"
    }
  )
}

export const patchNotificationsSettings = (
  data: NotificationSettings[]
): AxiosPromise => {
  return rentalApi("/notification_settings", {
    method: "PATCH",
    data
  })
}

export const postNotificationsSettings = (
  data: NotificationSettings[]
): AxiosPromise => {
  return rentalApi("/notification_settings", {
    method: "POST",
    data
  })
}
