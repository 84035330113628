import { phone1 } from "app/utils/phoneValidation.utils"
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input"
import { boolean, object, mixed } from "yup"

const extraPhone = mixed()
  .test(
    "phone2",
    "portal.contactPerson.input.phone.error.country",
    function (val: any) {
      if (val) {
        if (val?.length < 5) return true
        const phoneNumber = parsePhoneNumber(val)
        if (phoneNumber?.countryCallingCode) return true
      }
      return true
    }
  )
  .test(
    "phone2",
    "portal.contactPerson.input.phone.error",
    function (val: any) {
      if (!this.parent.hasExtraPhoneNumber) return true
      return val ? isValidPhoneNumber(val) : true
    }
  )

export const validationSchema = object({
  hasExtraPhoneNumber: boolean(),
  phone1: phone1,
  phone2: mixed().when("hasExtraPhoneNumber", {
    is: true,
    then: extraPhone
  })
})
