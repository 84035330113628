import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const DisclaimerWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  margin: 1.2rem 0 0;
  justify-content: flex-end;
  align-items: center;
`

export const StyledDisclaimerText = styled.span`
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.regular};
  line-height: ${theme.lineHeights.caption};
  color: ${theme.colors.neutral.darkerGrey};
  padding: 0 0.4rem;
`

export const StyledDisclaimer = styled.sup`
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.regular};
  line-height: ${theme.lineHeights.caption};
  color: ${theme.colors.neutral.darkerGrey};
`
