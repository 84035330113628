import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledFilterHeader = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (${theme.breakpoints.desktop}) {
    justify-content: space-between;
  }
`

export const StyledFilterTitle = styled.h4`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-right: 1.2rem;

  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 0;
  }
`

export const StyledClearFilters = styled.h5`
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.green.contrast};
  margin: 0;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
`

export const StyledFilterSubTitle = styled.h5`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.fontSizes.captionChartNumber};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin: 1.6rem 0 0.5rem;
`

export const FilterInput = styled.input`
  border: 1px solid ${theme.colors.neutral.darkerGrey};
  border-radius: 4px;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.paragraph};
  padding: 1.2rem 1.6rem;
  box-sizing: border-box;
  width: 100%;

  ${theme.outline}
`
