import { useTranslate } from "hooks/translate"
import ServerIcon from "./ServerIcon"
import { StyledContainer, StyledDescription, StyledTitle } from "./style"

export const MaintenancePage = () => {
  const { translate } = useTranslate()
  return (
    <StyledContainer>
      <ServerIcon />
      <StyledTitle>{translate("server.down.title")}</StyledTitle>
      <StyledDescription>
        {translate("server.down.description")}
      </StyledDescription>
    </StyledContainer>
  )
}

export default MaintenancePage
