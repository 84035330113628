import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"
import { FacilityTicketStatus } from "app/enums/ticketStatus"
import { IndicatorSize } from "../StatusIndicator"

export const StyledStatusIndicator = styled.div<{ status: FacilityTicketStatus, size: IndicatorSize }>`
  min-height: ${props => props.size === IndicatorSize.SMALL ? '1.2rem' : '1.6rem'};
  min-width: ${props => props.size === IndicatorSize.SMALL ? '1.2rem' : '1.6rem'};
  margin: 0 0.8rem 0 1.2rem;
  border: ${props => props.size === IndicatorSize.SMALL ? '2px solid ' + theme.colors.neutral.white : 'none'};
  border-radius: 50%;
  background: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.AWAITING_PROCESSING:
        return theme.colors.system.reported
      case FacilityTicketStatus.CANCELLED:
        return theme.colors.neutral.granite
      case FacilityTicketStatus.INFO_NEEDED:
        return theme.colors.system.moreInfo
      case FacilityTicketStatus.PROCESSING:
        return theme.colors.system.warning
      case FacilityTicketStatus.PLANNED:
        return theme.colors.system.planned
      case FacilityTicketStatus.FINISHED:
        return theme.colors.system.success
    }
  }};
`
