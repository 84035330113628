import Hero from "./Hero.jpg"
import { ModulesEnum } from "app/types/banner"
import ModuleHeader from "../ModuleHeader"

const FacilityManagementPageHeader = () => {
  return (
    <ModuleHeader
      page={"facilitymanagement"}
      module={ModulesEnum.PROPERTY_MANAGEMENT}
      Hero={Hero}
    />
  )
}

export default FacilityManagementPageHeader
