import Hero from "./Hero.jpg"
import { ModulesEnum } from "app/types/banner"
import ModuleHeader from "../ModuleHeader"

const DocumentsPageHeader = () => {
  return (
    <ModuleHeader
      page={"documents"}
      module={ModulesEnum.DOCUMENTS}
      Hero={Hero}
    />
  )
}

export default DocumentsPageHeader
