import { Actions } from "../wizard/wizard.actions"
import createWizardReducer, { DEFAULT_STATE } from "../wizard/wizard.reducer"

const INITIAL_STATE = {
  ...DEFAULT_STATE,
  step: 1
}

const registrationWizardReducer = (state = INITIAL_STATE, action: Actions) => {
  return createWizardReducer("REGISTRATION")(state, action)
}

export default registrationWizardReducer
