import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.main<{ pullContent?: boolean }>`
  padding-bottom: 4.8rem;
  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: ${theme.breakpointsValues.tablet}px;
    margin: -3.6rem auto 0;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    max-width: ${theme.breakpointsValues.desktop}px;
    position: relative;
  }
`
