import Hero from "./Hero.jpg"
import { ModulesEnum } from "app/types/banner"
import ModuleHeader from "../ModuleHeader"

const InvoicesPageHeader = () => {
  return (
    <ModuleHeader page={"invoices"} module={ModulesEnum.INVOICES} Hero={Hero} />
  )
}

export default InvoicesPageHeader
