import { useTranslate } from "hooks/translate"
import PageFilter from "app/components/Page/PageFilter"
import DateRangePicker from "app/components/DateRangePicker"
import {
  StyledClearFilters,
  StyledFilterHeader,
  StyledFilterSubTitle,
  StyledFilterTitle
} from "./style"
import { theme } from "@paudigital/wdp-components"
import ContractFilter from "./ContractFilter"
import { useViewport } from "hooks/viewport"
import Sort from "./Sort"
import CategoryDrilldown from "app/components/CategoryDrilldown"
import UploadedByFilter from "./UploadedByFilter"
import { Filters } from "routes/Documents"

export type FilterProps = {
  setUrlParams: (params: Filters) => void
  closeFilters?: () => void
} & Filters

type Props = FilterProps

const DocumentsFilter = ({
  closeFilters,
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { translate } = useTranslate()
  const { width } = useViewport()
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }

  const updateDate = (date: string, name: string, id: string) => {
    const field = id + name.charAt(0).toUpperCase() + name.slice(1)
    const _date = date === "Invalid date" ? null : date

    return setUrlParams({
      ...queryParams,
      [field]: _date
    })
  }

  const clear = () => setUrlParams({ page: 1, perPage: 30, sort })

  return (
    <PageFilter closeFilters={closeFilters}>
      <StyledFilterHeader>
        <StyledFilterTitle>
          {translate("portal.facilitymanagement.filter")}
        </StyledFilterTitle>
        <StyledClearFilters onClick={clear}>
          {translate("portal.facilitymanagement.filter.clear")}
        </StyledClearFilters>
      </StyledFilterHeader>
      <StyledFilterSubTitle>
        {translate("portal.facilitymanagement.filter.category")}
      </StyledFilterSubTitle>
      <CategoryDrilldown setUrlParams={setUrlParams} {...queryParams} />
      <div>
        {width < theme.breakpointsValues.tablet && (
          <Sort setUrlParams={setUrlParams} {...queryParams} />
        )}
      </div>
      <StyledFilterSubTitle>
        {translate("portal.documentmanagement.filter.upload-date")}
      </StyledFilterSubTitle>
      <DateRangePicker
        id="uploadDate"
        to={uploadDateTo}
        from={uploadDateFrom}
        updateDate={updateDate}
      />
      <StyledFilterSubTitle>
        {translate("portal.documentmanagement.filter.inspection-date")}
      </StyledFilterSubTitle>
      <DateRangePicker
        id="inspectionDate"
        to={inspectionDateTo}
        from={inspectionDateFrom}
        updateDate={updateDate}
      />
      <StyledFilterSubTitle>
        {translate("portal.documentmanagement.filter.uploaded-by")}
      </StyledFilterSubTitle>
      <UploadedByFilter setUrlParams={setUrlParams} {...queryParams} />
      <StyledFilterSubTitle>
        {translate("portal.documentmanagement.filter.contract-number")}
      </StyledFilterSubTitle>
      <ContractFilter setUrlParams={setUrlParams} {...queryParams} />
    </PageFilter>
  )
}

export default DocumentsFilter
