import { useLogout } from "hooks/logout"
import React from "react"

const Logout = () => {
  const { logout } = useLogout()

  React.useEffect(() => {
    logout()
  }, [logout])
  return <></>
}

export default Logout
