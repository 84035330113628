import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledContactLink = styled.a`
  color: ${theme.colors.green.contrast};
`

export const StyledServerErrorContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 1.2rem);
  animation: fadeOut linear 4s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`
