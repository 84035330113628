// formatStringNumber function is based on the following documentation:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt#a_stricter_parse_function
export const formatStringNumber = (numberAsString: string): number | undefined => {
  if (/^[1-9]{1}\d*e?\d*$/.test(numberAsString)) {
    return Number(numberAsString)
  } 

  return undefined
}

export const isNumeric = (str: string) => {
  if (typeof str !== "string") return false
  return !isNaN(Number(str)) && !isNaN(parseFloat(str))
}
