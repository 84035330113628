import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  margin: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalTitle = styled.h4`
  font-size: ${theme.fontSizes.h4};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2.4rem;
`

export const ModalSubTitle = styled.p`
  font-size: ${theme.fontSizes.paragraph};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2.8rem;
`

export const StyledFormWrapper = styled.div`
  @media only screen and (${theme.breakpoints.tablet}),
    only screen and (${theme.breakpoints.lowHeight}) {
    margin-bottom: 3.2rem;
  }
`
