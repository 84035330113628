export enum PossibleContactRoles {
  ADMIN = "Admin",
  FINANCE = "Finance",
  GENERAL_MANAGER = "General manager",
  FACILITY_MANAGER = "Facility manager",
  EXTERNAL = "External"
}

export enum PossibleBuildingContactRoles {
  SALES_MANAGER = "Sales Manager",
  FINANCIAL_MANAGER = "Financial Manager",
  FACILITY_MANAGER = "Facility Manager"
}
