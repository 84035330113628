import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Title = styled.h3`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
  flex: 1 1 0px;
`
