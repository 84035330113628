import { Locales } from "app/i18n/config"
import { isValid } from "date-fns"
import { format } from "date-fns-tz"


export const formatISODateWithoutTimezone = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss")
}

export const formatDateToLocale = (date: Date, language: Locales) => {
  if (!isValid(date)) return
  const options: Intl.DateTimeFormatOptions = { dateStyle: "long" }
  return Intl.DateTimeFormat(language, options).format(date)
}
