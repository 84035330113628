import { PvProject } from "app/types/pvProject"
import { Action, Types } from "./pvProject.actions"

export type PvProjectState = {
  errors?: Record<string, unknown>
  loading: boolean
  pvProject: PvProject | undefined
}

const initialState: PvProjectState = {
  errors: undefined,
  loading: false,
  pvProject: undefined
}

export default (state = initialState, action: Action): PvProjectState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        pvProject: action.payload.data
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
