import { useTranslate } from "hooks/translate"
import {
  StyledBaseCard,
  StyledButton,
  StyledCardActions,
  StyledGridContainerDiv,
  StyledWrapper,
  DocumentCategoryType,
  DocumentTitle,
  DocumentCardWrapper,
  DocumentIcon,
  DocumentUpload,
  StyledUploaded
} from "./style"
import { DownloadIcon } from "@paudigital/wdp-components"
import { ContentTypes, Document, ModifiedByEnum } from "app/types/document"
import { format } from "date-fns"
import { iconMapping } from "./config"
import { DocumentTypes } from "app/enums/documentTypes"
import { downloadDocument } from "app/redux/document/document.actions"
import { useApi } from "hooks/api"
import { getUserCompanyName } from "app/redux/me/me.selectors"
import { useSelector } from "react-redux"
import { addZeroWidthSpaceAfterCharacters } from "app/utils/linebreaks.utils"

type DocumentCardProps = {
  document: Document
}

type Props = DocumentCardProps

const DocumentCard = ({ document }: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const companyName = useSelector(getUserCompanyName)
  const {
    uploadDate,
    uploadedBy,
    topDocumentType,
    metadata,
    contractStartDate,
    contractEndDate,
    treeType,
    personId,
    id,
    documentContentType
  } = document

  const determineCreatedBy = () => {
    if (uploadedBy === ModifiedByEnum.END_USER) {
      return `${companyName}${getInactive()}`
    }
    return "WDP"
  }

  const getInactive = () => {
    return !personId ? ` ${translate("portal.documents.inactive.account")}` : ""
  }

  const downloadFile = () => {
    if (id) call(downloadDocument(id))
  }

  const getContractNumber = (() => {
    if (!document.metadata || !document.metadata.Contract_x0020_number) {
      return ""
    }
    const number = document.metadata.Contract_x0020_number
    return number?.substr(number.search("/") + 1)
  })()

  return (
    <StyledBaseCard onContainerClick={downloadFile}>
      <StyledWrapper>
        <StyledGridContainerDiv>
          <DocumentCardWrapper>
            <DocumentIcon>{iconMapping[topDocumentType]}</DocumentIcon>
            <div>
              <DocumentCategoryType>
                {translate(`portal.documents.category.${topDocumentType}`)}
              </DocumentCategoryType>
              <DocumentTitle>
                {document.metadata.Title
                  ? `${translate(
                      `portal.documents.category.${treeType}`
                    )}: ${addZeroWidthSpaceAfterCharacters(
                      document.metadata.Title,
                      ["_"]
                    ).substring(0, 100)}`
                  : translate(`portal.documents.category.${treeType}`)}
              </DocumentTitle>
              <DocumentUpload>
                <StyledUploaded>
                  <strong>{translate("portal.documents.uploaded")}:</strong>
                  {translate("portal.documents.uploaded.by", {
                    uploadedDate: format(new Date(uploadDate), "dd-MM-yyyy"),
                    createdBy: determineCreatedBy()
                  })}
                </StyledUploaded>
                {documentContentType ===
                  ContentTypes.WDP_CONTRACT_RELATED_DOCUMENT && (
                  <div>
                    <strong>
                      {translate("portal.documents.contract.date")}:
                    </strong>
                    {getContractNumber}
                  </div>
                )}
                {topDocumentType ===
                  DocumentTypes.Maintenance_inspection_reports &&
                  metadata.Document_x0020_date && (
                    <div>
                      <strong>
                        {translate("portal.documents.inspection.date")}:
                      </strong>
                      {format(
                        new Date(metadata.Document_x0020_date),
                        "dd-MM-yyyy"
                      )}
                    </div>
                  )}
                {topDocumentType === DocumentTypes.Contracts &&
                  contractStartDate &&
                  contractEndDate && (
                    <div>
                      <strong>
                        {translate("portal.documents.rentalperiod")}:
                      </strong>
                      {format(new Date(contractStartDate), "dd-MM-yyyy")}
                      <span> - </span>
                      {format(new Date(contractEndDate), "dd-MM-yyyy")}
                    </div>
                  )}
              </DocumentUpload>
            </div>
          </DocumentCardWrapper>
        </StyledGridContainerDiv>
      </StyledWrapper>
      <StyledCardActions alignment="horizontal">
        <StyledButton
          startIcon={<DownloadIcon height="24px" width="24px" />}
          label={translate("portal.documents.download.button.label")}
          type="secondary"
        />
      </StyledCardActions>
    </StyledBaseCard>
  )
}

export default DocumentCard
