import { Checkbox } from "@paudigital/wdp-components"
import { TicketTypes } from "app/types/ticket"
import { useTranslate } from "hooks/translate"
import { StyledStatus, StatusText } from "./style"

export type TypeProps = {
  type: TicketTypes
  amount: number
  checked: boolean
  onClick: (types: TicketTypes) => void
  dataTestId?: string
}

type Props = TypeProps

const Type = ({ type, amount, checked, onClick, dataTestId }: Props) => {
  const { translate } = useTranslate()

  const handleClick = () => {
    onClick(type)
  }

  return (
    <StyledStatus onClick={handleClick}>
      <Checkbox
        checked={checked}
        onClick={handleClick}
        dataTestId={dataTestId}
      />
      <StatusText>
        {translate(`portal.facilitymanagement.${type}`) + ` (${amount})`}
      </StatusText>
    </StyledStatus>
  )
}

export default Type
