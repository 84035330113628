import {
  Container,
  CurrentUrl,
  StyledLink,
  StyledList,
  ImageWrapper,
  ListHeader,
  Title,
  Wrapper
} from "./style"
import { useNavigate } from "react-router"
import { useTranslate } from "hooks/translate"
import { NotFound as Image } from "@paudigital/wdp-components"
import { useMemo } from "react"
import { Modules } from "app/enums/modules"
import { usePermission } from "hooks/permission"
import { useSelector } from "react-redux"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"

const NotFound = () => {
  const { translate } = useTranslate()
  const { hasPermission } = usePermission()
  const rentedBuildingId = useSelector(getActiveBuilding)
  const navigate = useNavigate()
  const hasAccess = useMemo(() => {
    return (
      rentedBuildingId && hasPermission(Modules.DASHBOARD, rentedBuildingId)
    )
  }, [rentedBuildingId, hasPermission])

  const linkTranslation = useMemo(() => {
    if (hasAccess) {
      return translate("portal.notifications.noData.dashboard-link")
    }
    return translate("portal.notifications.noData.facility-management-link")
  }, [hasAccess, translate])

  const handleOnClick = () => {
    if (hasAccess) {
      return navigate(`/dashboard/${rentedBuildingId}`)
    }
    return navigate(`/facility-management/${rentedBuildingId}`)
  }
  return (
    <Container>
      <Wrapper>
        <ImageWrapper>
          <Image />
        </ImageWrapper>
        <Title>{translate("portal.404.title")}</Title>
        <CurrentUrl>
          <span>{window.location.href}</span>
        </CurrentUrl>
        <ListHeader>{translate("portal.404.suggestion.title")}</ListHeader>
        <StyledList>
          <li>{translate("portal.404.suggestion.option.removed")}</li>
          <li>{translate("portal.404.suggestion.option.changed")}</li>
          <li>{translate("portal.404.suggestion.option.spelling")}</li>
        </StyledList>
        <StyledLink
          value={linkTranslation}
          type="default"
          onClick={handleOnClick}
        />
      </Wrapper>
    </Container>
  )
}

export default NotFound
