import { useTranslate } from "hooks/translate"
import React from "react"
import BaseGraph from "../BaseGraph"
import {
  getGasMeterHistoryData,
  getLastUpdated,
  hasWarning,
  isLoading
} from "app/redux/gasMeterHistory/gasMeterHistory.selectors"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useSelector } from "react-redux"
import { useApi } from "hooks/api"
import { getGasMeterHistory } from "app/redux/gasMeterHistory/gasMeterHistory.actions"
import { Graphs } from "app/utils/graphs/graph.utils"
import { getEnergyPeriodRange, getIntervalOfGraph } from "app/redux/energyPeriod/energyPeriod.selectors"

const GasConsumptionGraph = () => {
  const { call } = useApi()
  const { rentedBuilding } = useRentedBuilding()
  const warning = useSelector(hasWarning)
  const gasMeterHistory = useSelector(getGasMeterHistoryData)
  const lastUpdated = useSelector(getLastUpdated)
  const interval = useSelector(getIntervalOfGraph(Graphs.GAS_CONSUMPTION))
  const energyPeriod = useSelector(getEnergyPeriodRange)
  const loading = useSelector(isLoading)
  const { translate } = useTranslate()

  React.useEffect(() => {
    if (rentedBuilding && rentedBuilding.id) {
      call(
        getGasMeterHistory(rentedBuilding, {
          interval: interval,
          from: energyPeriod.from,
          to: energyPeriod.to
        })
      )
    }
  }, [call, rentedBuilding, interval, energyPeriod.from, energyPeriod.to])

  const refreshGraph = () => {
    call(getGasMeterHistory(rentedBuilding!, {
      interval: interval,
      from: energyPeriod.from,
      to: energyPeriod.to
    }))
  }

  return (
    <BaseGraph
      lastUpdated={lastUpdated}
      refreshGraph={refreshGraph}
      loading={loading}
      title={translate("portal.energy.graphs.gas.title")}
      graphType={Graphs.GAS_CONSUMPTION}
      graphSerieData={gasMeterHistory}
      warning={warning}
    />
  )
}

export default GasConsumptionGraph
