import {
  DocumentIcon,
  SlideIn,
  Typography,
  theme,
  Link
} from "@paudigital/wdp-components"
import { useDispatch, useSelector } from "react-redux"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { useApi } from "hooks/api"
import { downloadDocument } from "app/redux/document/document.actions"
import { getSummary } from "app/redux/invoices/invoices.selectors"
import Divider from "../Divider"
import { useTranslate } from "hooks/translate"
import InvoiceCardMetaTitle from "../InvoiceMetaTitle"
import {
  Container,
  Detail,
  ButtonWrapper,
  StyledButton,
  InvoiceDetails
} from "./style"
import InvoiceValue from "../InvoiceValue"
import { format } from "date-fns"

type Props = {
  id: string
}

const InvoiceDetailPopUp = ({ id }: Props) => {
  const { translate } = useTranslate()
  const summary = useSelector(getSummary)
  const { call } = useApi()
  const dispatch = useDispatch()
  const invoice = summary?.find(s => s.id === id)
  if (!invoice) return <></>
  const { metadata, invoiceType } = invoice

  const handleClose = () => {
    dispatch(remove("invoice-detail"))
  }

  const handleDownload = () => {
    call(downloadDocument(id))
  }

  const handleSupplierDownload = () => {
    if (invoice) {
      call(downloadDocument(invoice.purchaseId!))
    }
  }

  return (
    <SlideIn show onClose={handleClose} showCloseIcon>
      <Container>
        <InvoiceCardMetaTitle
          process={metadata.RE_x0020_Process}
          from={metadata.Settlement_x0020_period_x0020_from}
          to={metadata.Settlement_x0020_period_x0020_to}
        />
        <Typography variant="h5" fontStyle="bold">
          {`${translate(
            `portal.invoices.invoice.invoicetype.${invoiceType}`
          )} ${metadata.Invoice_x0020_Document_x0020_ID}`}
        </Typography>
        <InvoiceDetails>
          <div>
            <Detail>
              <strong>{translate("portal.invoices.invoiced.label")}:</strong>{" "}
              {format(
                new Date(metadata.Document_x0020_Date_x0020_in_x0020_Document),
                "dd-MM-yyyy"
              )}
            </Detail>
            <Detail>
              <strong>{translate("portal.invoices.contract.label")}:</strong>{" "}
              {metadata.Contract_x0020_number}
            </Detail>
          </div>
          <InvoiceValue
            invoiceType={invoiceType}
            amount={metadata.Amount_x0020_in_x0020_local_x0020_currency!}
            currency={metadata.Currency_x0020_Key!}
            dueDate={metadata.TaskDueDate!}
          />
        </InvoiceDetails>
        <Divider />
        <ButtonWrapper>
          <StyledButton
            startIcon={<DocumentIcon fill={theme.colors.neutral.white} />}
            label={translate("portal.invoices.invoice.button.label")}
            onClick={handleDownload}
          />
          {invoice.purchaseId && (
            <Link
              type="default"
              value={translate("portal.invoices.supplier.link.label")}
              onClick={handleSupplierDownload}
            />
          )}
        </ButtonWrapper>
      </Container>
    </SlideIn>
  )
}

export default InvoiceDetailPopUp
