import { ContactPerson, PatchContactPerson } from "app/types/contactPerson"
import { RentedbuildingResponsibilities } from "app/types/responsibilities"

export enum Types {
  GET = "GET_CONTACTPERSON",
  GET_SUCCESS = "GET_CONTACTPERSON_SUCCESS",
  GET_FAIL = "GET_CONTACTPERSON_FAIL",
  GET_BY_ID = "GET_CONTACTPERSON_BY_ID",
  GET_BY_ID_SUCCESS = "GET_CONTACTPERSON_BY_ID_SUCCESS",
  GET_BY_ID_FAIL = "GET_CONTACTPERSON_BY_ID_FAIL",
  PATCH = "PATCH_CONTACTPERSON",
  PATCH_SUCCESS = "PATCH_CONTACTPERSON_SUCCESS",
  PATCH_FAIL = "PATCH_CONTACTPERSON_FAIL",
  PATCH_WITH_RESPONSIBILITIES = "PATCH_CONTACTPERSON_WITH_RESPONSIBILITIES",
  PATCH_WITH_RESPONSIBILITIES_SUCCESS = "PATCH_CONTACTPERSON_WITH_RESPONSIBILITIES_SUCCESS",
  PATCH_WITH_RESPONSIBILITIES_FAIL = "PATCH_CONTACTPERSON_WITH_RESPONSIBILITIES_FAIL",
  PATCH_ONBOARDING = "PATCH_CONTACTPERSON_ONBOARDING",
  PATCH_ONBOARDING_SUCCESS = "PATCH_CONTACTPERSON_ONBOARDING_SUCCESS",
  PATCH_ONBOARDING_FAIL = "PATCH_CONTACTPERSON_ONBOARDING_FAIL",
  PATCH_ADMIN = "PATCH_CONTACTPERSON_ADMIN",
  PATCH_ADMIN_SUCCESS = "PATCH_CONTACTPERSON_ADMIN_SUCCESS",
  PATCH_ADMIN_FAIL = "PATCH_CONTACTPERSON_ADMIN_FAIL",
  PUT_PROFILEPICTURE = "PUT_PROFILEPICTURE",
  PUT_PROFILEPICTURE_SUCCESS = "PUT_PROFILEPICTURE_SUCCESS",
  PUT_PROFILEPICTURE_FAIL = "PUT_PROFILEPICTURE_FAIL",
  GET_WHITELISTEDDOMAINS = "GET_WHITELISTEDDOMAINS",
  GET_WHITELISTEDDOMAINS_SUCCESS = "GET_WHITELISTEDDOMAINS_SUCCESS",
  GET_WHITELISTEDDOMAINS_FAIL = "GET_WHITELISTEDDOMAINS_FAIL",
  RESEND_INVITE = "RESEND_INVITE",
  RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS",
  RESEND_INVITE_FAIL= "RESEND_INVITE_FAIL"
}

export type Action =
  | GetContactPersonAction
  | GetContactPersonSuccessAction
  | GetContactPersonFailAction
  | GetContactPersonByIdAction
  | GetContactPersonByIdSuccessAction
  | GetContactPersonByIdFailAction
  | PatchContactPersonAction
  | PatchContactPersonSuccessAction
  | PatchContactPersonFailAction
  | PutProfilePictureAction
  | PutProfilePictureSuccessAction
  | PutProfilePictureFailAction
  | PatchContactPersonOnboardingAction
  | PatchContactPersonOnboardingSuccessAction
  | PatchContactPersonOnboardingFailAction
  | PatchContactPersonAdminAction
  | PatchContactPersonAdminSuccessAction
  | PatchContactPersonAdminFailAction
  | GetWhitelistedDomainsAction
  | GetWhitelistedDomainsSuccessAction
  | GetWhitelistedDomainsFailAction
  | PatchContactPersonWithResponsibilitiesAction
  | PatchContactPersonWithResponsibilitiesSuccessAction
  | PatchContactPersonWithResponsibilitiesFailAction
  | ResendInviteAction
  | ResendInviteSuccessAction
  | ResendInviteFailAction

export type PatchContactPersonAction = {
  type: Types.PATCH
  payload: {
    contactPersonId: string
    data: PatchContactPerson
  }
}

export type PatchContactPersonSuccessAction = {
  payload: { data: ContactPerson }
  type: Types.PATCH_SUCCESS
}

export type PatchContactPersonFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_FAIL
}

export type PutProfilePictureAction = {
  type: Types.PUT_PROFILEPICTURE
  payload: {
    contactPersonId: string
    data: File
  }
}

export type PutProfilePictureSuccessAction = {
  type: Types.PUT_PROFILEPICTURE_SUCCESS
}

export type PutProfilePictureFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PUT_PROFILEPICTURE_FAIL
}

export type GetContactPersonAction = {
  payload: { entityId: string; query: string }
  type: Types.GET
}

export type GetContactPersonSuccessAction = {
  payload: { data: ContactPerson[] }
  type: Types.GET_SUCCESS
}

export type GetContactPersonFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type GetContactPersonByIdAction = {
  payload: { contactPersonId: string }
  type: Types.GET_BY_ID
}

export type GetContactPersonByIdSuccessAction = {
  payload: { data: ContactPerson }
  type: Types.GET_BY_ID_SUCCESS
}

export type GetContactPersonByIdFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_BY_ID_FAIL
}

export type PatchContactPersonOnboardingAction = {
  type: Types.PATCH_ONBOARDING
  payload: {
    contactPersonId: string
  }
}

export type PatchContactPersonOnboardingSuccessAction = {
  type: Types.PATCH_ONBOARDING_SUCCESS
}

export type PatchContactPersonOnboardingFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_ONBOARDING_FAIL
}

export type PatchContactPersonAdminAction = {
  type: Types.PATCH_ADMIN
  payload: {
    contactPersonId: string
    data: PatchContactPerson
  }
}

export type PatchContactPersonAdminSuccessAction = {
  payload: { data: ContactPerson }
  type: Types.PATCH_ADMIN_SUCCESS
}

export type PatchContactPersonAdminFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_ADMIN_FAIL
}

export type PatchContactPersonWithResponsibilitiesAction = {
  type: Types.PATCH_WITH_RESPONSIBILITIES
  payload: {
    contactPersonId: string
    data: PatchContactPerson
    responsibilities: RentedbuildingResponsibilities[]
    profilePicture: File | null
  }
}

export type PatchContactPersonWithResponsibilitiesSuccessAction = {
  payload: { data: ContactPerson }
  type: Types.PATCH_WITH_RESPONSIBILITIES_SUCCESS
}

export type PatchContactPersonWithResponsibilitiesFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_WITH_RESPONSIBILITIES_FAIL
}

export type GetWhitelistedDomainsAction = {
  type: Types.GET_WHITELISTEDDOMAINS
}

export type GetWhitelistedDomainsSuccessAction = {
  payload: { data: string[] }
  type: Types.GET_WHITELISTEDDOMAINS_SUCCESS
}

export type GetWhitelistedDomainsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_WHITELISTEDDOMAINS_FAIL
}

export type ResendInviteAction = {
  type: Types.RESEND_INVITE,
  payload: { contactPersonId: string }
}

export type ResendInviteSuccessAction = {
  type: Types.RESEND_INVITE_SUCCESS
}

export type ResendInviteFailAction = {
  type: Types.RESEND_INVITE_FAIL
  payload: { errors: Record<string, unknown> }
}

export function patchContactPerson(
  contactPersonId: string,
  data: PatchContactPerson
): Action {
  return {
    type: Types.PATCH,
    payload: {
      contactPersonId,
      data
    }
  }
}

export function patchContactPersonSuccess(data: ContactPerson): Action {
  return {
    type: Types.PATCH_SUCCESS,
    payload: { data }
  }
}

export function patchContactPersonFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function patchContactPersonOnboarding(contactPersonId: string): Action {
  return {
    type: Types.PATCH_ONBOARDING,
    payload: { contactPersonId }
  }
}

export function patchContactPersonOnboardingSuccess(): Action {
  return {
    type: Types.PATCH_ONBOARDING_SUCCESS
  }
}

export function patchContactPersonOnboardingFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_ONBOARDING_FAIL
  }
}

export function patchContactPersonAdmin(
  contactPersonId: string,
  data: PatchContactPerson
): Action {
  return {
    type: Types.PATCH_ADMIN,
    payload: {
      contactPersonId,
      data
    }
  }
}

export function patchContactPersonAdminSuccess(data: ContactPerson): Action {
  return {
    type: Types.PATCH_ADMIN_SUCCESS,
    payload: { data }
  }
}

export function patchContactPersonAdminFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_ADMIN_FAIL
  }
}

export function patchContactPersonWithResponsibilities(
  contactPersonId: string,
  data: PatchContactPerson,
  responsibilities: RentedbuildingResponsibilities[],
  profilePicture: File | null
): Action {
  return {
    type: Types.PATCH_WITH_RESPONSIBILITIES,
    payload: {
      contactPersonId,
      data,
      responsibilities,
      profilePicture
    }
  }
}

export function patchContactPersonWithResponsibilitiesSuccess(
  data: ContactPerson
): Action {
  return {
    type: Types.PATCH_WITH_RESPONSIBILITIES_SUCCESS,
    payload: { data }
  }
}

export function patchContactPersonWithResponsibilitiesFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_WITH_RESPONSIBILITIES_FAIL
  }
}

export function putProfilePicture(contactPersonId: string, data: File): Action {
  return {
    type: Types.PUT_PROFILEPICTURE,
    payload: { contactPersonId, data }
  }
}

export function putProfilePictureSuccess(): Action {
  return {
    type: Types.PUT_PROFILEPICTURE_SUCCESS
  }
}

export function putProfilePictureFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.PUT_PROFILEPICTURE_FAIL
  }
}

export function getContactPerson(entityId: string, query: string): Action {
  return {
    type: Types.GET,
    payload: { entityId, query }
  }
}

export function getContactPersonSuccess(data: ContactPerson[]): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getContactPersonFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function getContactPersonById(contactPersonId: string): Action {
  return {
    type: Types.GET_BY_ID,
    payload: { contactPersonId }
  }
}

export function getContactPersonByIdSuccess(data: ContactPerson): Action {
  return {
    payload: { data },
    type: Types.GET_BY_ID_SUCCESS
  }
}

export function getContactPersonByIdFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_BY_ID_FAIL
  }
}

export function getWhitelistedDomains(): Action {
  return {
    type: Types.GET_WHITELISTEDDOMAINS
  }
}

export function getWhitelistedDomainsSuccess(data: string[]): Action {
  return {
    payload: { data },
    type: Types.GET_WHITELISTEDDOMAINS_SUCCESS
  }
}

export function getWhitelistedDomainsFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_WHITELISTEDDOMAINS_FAIL
  }
}

export function resendInvite(contactPersonId: string): Action {
  return {
    type: Types.RESEND_INVITE,
    payload: { contactPersonId }
  }
}

export function resendInviteSuccess(): Action {
  return {
    type: Types.RESEND_INVITE_SUCCESS
  }
}

export function resendInviteFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.RESEND_INVITE_FAIL
  }
}
