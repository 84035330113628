import { Action, Types } from "./buildingSwitcher.actions"

export type BuildingSwitcherState = {
  active: string | undefined
}

const initialState: BuildingSwitcherState = {
  active: undefined
}

export default (
  state = initialState,
  action: Action
): BuildingSwitcherState => {
  switch (action.type) {
    case Types.SET:
      return {
        ...state,
        active: action.payload.id
      }

    default:
      return state
  }
}
