import { PvProjecctStep, StepEnum } from "app/types/pvProject"
import { format } from "date-fns"
import { useTranslate } from "hooks/translate"
import { Dates, StepContainer, StepTitle, Container } from "./style"

export type StepProps = {
  step: PvProjecctStep
}

export const Step = ({ step }: StepProps) => {
  const { translate } = useTranslate()
  const startDate = step.startDate
    ? new Date(Date.parse(step.startDate))
    : undefined
  const endDate = step.endDate ? new Date(Date.parse(step.endDate)) : undefined

  const setDates = () => {
    if (step.status === StepEnum.COMPLETED && endDate) {
      return `${format(endDate, "MMM yyyy")} ${translate(
        "portal.energy.pvproject.completed"
      )}`
    }
    if (step.status !== StepEnum.COMPLETED && startDate && endDate) {
      return `${format(startDate, "MMM")} - ${format(endDate, "MMM yyyy")}`
    }
  }

  return (
    <Container>
      <StepContainer
        first={step.step === 1}
        last={step.step === 5}
        status={step.status}
      >
        <StepTitle first={step.step === 1}>
          {translate(`portal.energy.pvproject.step.${step.step}`)}
        </StepTitle>
      </StepContainer>
      <Dates>{setDates()}</Dates>
    </Container>
  )
}
