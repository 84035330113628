const dateToNum = (d: Date) => {
  const n = Number(d)
  return isNaN(n) ? 0 : n
}

export const calculateMinutesElapsed = (lastUpdated?: Date): number => {
  return lastUpdated && dateToNum(lastUpdated) > 0
    ? Math.floor((dateToNum(new Date()) - dateToNum(lastUpdated)) / 60000)
    : 0
}
