import { Locales, formats } from "app/i18n/config"
import { format, isValid, Locale } from "date-fns"
import { enGB, nl, nlBE, fr, ro, de } from "date-fns/locale"

const localesMap: Record<Locales, Locale> = {
  [Locales.EN_GB]: enGB,
  [Locales.NL_NL]: nl,
  [Locales.NL_BE]: nlBE,
  [Locales.FR_FR]: fr,
  [Locales.RO_RO]: ro,
  [Locales.DE_DE]: de
}

export const formatToLongDateWithTime = (
  date: Date | number,
  language: Locales
) => {
  return format(date, formats.date.full[language], {
    locale: localesMap[language]
  })
}

export const dateFormatShort = (date: Date | string) => {
  const datestringShort = new Date(date)
  if (!isValid(datestringShort)) return
  return format(datestringShort, "dd/MM/yyyy HH:mm")
}
