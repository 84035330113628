import styled from "styled-components"
import { Link, theme } from "@paudigital/wdp-components"

export const StyledLink = styled(Link)`
  margin: 4rem 0 2.4rem 0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const StyledSpan = styled.span`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.caption};
  margin-top: 1.2rem;
`
