import { Action, Types } from "./appEvents.actions"
import { AppEvent } from "./types"

export type AppEventState = {
  events: AppEvent[]
}

const initialState: AppEventState = {
  events: []
}

export default (state = initialState, action: Action): AppEventState => {
  switch (action.type) {
    case Types.FIRE:
      return {
        ...state,
        events: addAppEvent(state.events, action.payload.event)
      }

    case Types.REMOVE:
      return {
        ...state,
        events: state.events.filter(
          e => e.uniqueIdentifier !== action.payload.uniqueIdentifier
        )
      }

    case Types.CLEAR:
      return {
        ...state,
        events: []
      }

    default:
      return state
  }
}

function addAppEvent(
  existingAppEvents: AppEvent[],
  newAppEvent: AppEvent
): AppEvent[] {
  const alreadyExists = existingAppEvents.find(
    (ae: AppEvent) => ae.uniqueIdentifier === newAppEvent.uniqueIdentifier
  )
  if (alreadyExists) {
    return existingAppEvents
  }
  return [...existingAppEvents, newAppEvent]
}
