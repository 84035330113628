import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledTicketContainer = styled.div`
  margin: 0;

  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 0 1rem;
    flex: 1;
  }
`

export const StyledFacilityTicketsContainer = styled.main`
  & > div {
    margin: 1.2rem 0;
  }
`

export const PaginationWrapper = styled.div`
  margin: 2.4rem 0 0.6rem 0;
`

export const StyledStatusTitle = styled.h4`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.fontSizes.h6};
  margin: 2rem 0 1.6rem;
`

export const StyledStatusDescription = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
`
