import { theme, Link } from "@paudigital/wdp-components";
import styled from "styled-components"

export const StyledContent = styled.div`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall}
`

export const StyledQuotation = styled.div`
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
`

export const StyledFaqContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
`

export const StyledLink = styled(Link).attrs(({ value }: any) => ({
  value
}))`
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};
`
