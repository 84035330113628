import {
  InformationSectionContent,
  InformationSectionHeader,
  InputLabel,
  Link,
  SlideIn,
  StatusCard,
  TrashBinIcon,
  theme
} from "@paudigital/wdp-components"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { useApi } from "hooks/api"
import InformationSectionTitle from "../InformationSectionTitle"
import {
  StyledInformationSection,
  StatusIndicator,
  Title,
  Item,
  StatusCardWrapper,
  HeaderWrapper,
  InformationWrapper,
  ContentWrapper,
  CancelButton,
  CancelLabel
} from "./style"
import * as actions from "app/redux/ticket/ticket.actions"
import { useDispatch, useSelector } from "react-redux"
import * as selectors from "app/redux/ticket/ticket.selectors"
import { FacilityTicketStatus } from "app/enums/ticketStatus"
import { useTranslate } from "hooks/translate"
import ContactFooter from "../ReportDetailContactFooter"
import FacilityTicketCardHeader from "../FacilityTicketCardHeader"
import TicketCommunication from "../TicketCommunication"
import TicketFeedback from "../TicketFeedback"
import { WorkOrder } from "app/types/ticket"
import { addDays, isBefore } from "date-fns"
import { closeReportDetail } from "app/redux/ticketCommunication/ticketCommunication.actions"
import { setTicketField } from "app/redux/ticket/ticket.actions"
import { getUserCompanyName } from "app/redux/me/me.selectors"
import { ModifiedByEnum } from "app/types/document"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useQueryParams } from "hooks/queryParams"
import { Workorder } from "../WorkOrder"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { useEffect } from "react"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  id: string
}

const ReportDetail = ({ id }: Props) => {
  const { translate } = useTranslate()
  const { queryParams, setUrlParams } = useQueryParams()
  const modalClosedQueryParams = { ...(queryParams as any) }
  const ticket = useSelector(selectors.getTicketById(id))
  const companyName = useSelector(getUserCompanyName)
  const dispatch = useDispatch()
  const { rentedBuilding } = useRentedBuilding()
  const { call } = useApi()

  useEffect(() => {
    dispatch(createGAEvent(PossibleGAEvents.TICKET_DETAIL))
  }, [dispatch, id])

  const handleTicketDownload = () => {
    if (rentedBuilding && ticket) {
      call(actions.getTicketAttachments(rentedBuilding.id, ticket.ticketId!))
    }
  }

  const handleClose = () => {
    dispatch(setTicketField("communicationCustomer", undefined))
    delete modalClosedQueryParams["modalOpen"]
    setUrlParams({ ...modalClosedQueryParams })
    dispatch(closeReportDetail(id))
  }

  const handleCancelReport = () => {
    dispatch(setTicketField("communicationCustomer", undefined))
    delete modalClosedQueryParams["modalOpen"]
    setUrlParams({ ...modalClosedQueryParams })
    dispatch(closeReportDetail(id))
    dispatch(
      fire({
        eventName: PossibleAppEvents.CANCEL_TICKET,
        uniqueIdentifier: "cancel-ticket",
        props: {
          ticket
        }
      })
    )
  }

  if (!ticket) return <></>

  const dateFinished = new Date(ticket.modificationDate)
  const dateDeadlineFeedback = addDays(dateFinished, 2)
  const now = new Date()
  const deadlineIsBeforeNow = isBefore(now, dateDeadlineFeedback)
  const showFeedBackDeadline =
    deadlineIsBeforeNow && ticket.status === FacilityTicketStatus.FINISHED
  const determineUploadedBy = () => {
    if (ticket.uploadedBy === ModifiedByEnum.END_USER) return `${companyName}`
    return "WDP"
  }

  return (
    <SlideIn show onClose={handleClose} showCloseIcon>
      <>
        <ContentWrapper>
          <HeaderWrapper>
            <FacilityTicketCardHeader
              answerCodes={ticket.answerCodes}
              type={ticket.type}
              ticketNr={ticket.sourceId!}
              suppliers={ticket.suppliers}
              date={ticket.creationDate}
              title={ticket.description}
              reportedBy={determineUploadedBy()}
              workOrders={ticket.workOrders}
            />
          </HeaderWrapper>
          {ticket.status === FacilityTicketStatus.INFO_NEEDED && (
            <StatusCardWrapper>
              <StatusCard
                title={translate(
                  "portal.facilitymanagement.reportdetail.statuscard.infoNeeded"
                )}
                status={FacilityTicketStatus.INFO_NEEDED}
              />
            </StatusCardWrapper>
          )}
          <InformationWrapper>
            <StyledInformationSection status={ticket.status}>
              <InformationSectionHeader>
                <InformationSectionTitle>
                  <Title>
                    <StatusIndicator status={ticket.status} />
                    {translate(
                      `portal.facilitymanagement.reportdetail.status.${ticket.status}`
                    )}
                  </Title>
                </InformationSectionTitle>
              </InformationSectionHeader>
              <InformationSectionContent>
                <>
                  <Item>
                    <InputLabel
                      label={translate(
                        "portal.facilitymanagement.reportdetail.category"
                      )}
                    />
                    {translate(
                      `portal.facilitymanagement.reportdetail.category.${ticket.type}`
                    )}
                  </Item>
                  {ticket.problem && (
                    <Item>
                      <InputLabel
                        label={translate(
                          "portal.facilitymanagement.reportdetail.location"
                        )}
                      />
                      {ticket.problem}
                    </Item>
                  )}
                  <Item>
                    <InputLabel
                      label={translate(
                        "portal.facilitymanagement.reportdetail.appointment"
                      )}
                    />
                    {translate(
                      `portal.facilitymanagement.reportdetail.appointment.${
                        ticket.customerContact ? "yes" : "no"
                      }`
                    )}
                  </Item>
                  {ticket.attachmentCount > 0 && (
                    <Item>
                      <InputLabel
                        label={translate(
                          "portal.facilitymanagement.reportdetail.attachments"
                        )}
                      />
                      {ticket.attachmentCount}{" "}
                      {translate(
                        "portal.facilitymanagement.reportdetail.attachments.attachments"
                      )}{" "}
                      <Link
                        value={translate(
                          "portal.facilitymanagement.reportdetail.attachments.view"
                        )}
                        type="default"
                        onClick={handleTicketDownload}
                      />
                    </Item>
                  )}
                  {ticket.communicationCustomer && (
                    <Item>
                      <InputLabel
                        label={translate(
                          "portal.facilitymanagement.reportdetail.description"
                        )}
                      />
                      {ticket.communicationCustomer}
                    </Item>
                  )}
                  {ticket.workOrders.length > 0 && (
                    <Item>
                      <InputLabel
                        label={translate(
                          "portal.facilitymanagement.reportdetail.workorders"
                        )}
                      />
                      {ticket.workOrders.map((wo: WorkOrder) => {
                        return <Workorder workOrder={wo} key={wo.id} />
                      })}
                    </Item>
                  )}
                  {ticket.status ===
                    FacilityTicketStatus.AWAITING_PROCESSING && (
                    <Item>
                      <CancelButton onClick={handleCancelReport}>
                        <TrashBinIcon fill={theme.colors.system.moreInfo} />
                        <CancelLabel>
                          {translate(
                            "portal.facilitymanagement.reportdetail.cancelReport"
                          )}
                        </CancelLabel>
                      </CancelButton>
                    </Item>
                  )}
                </>
              </InformationSectionContent>
            </StyledInformationSection>
            {ticket.allowFeedback && !showFeedBackDeadline && (
              <TicketCommunication
                location={ticket.problem!}
                id={ticket.ticketId}
                onCancel={handleClose}
                communicationLessor={ticket.communicationLessor || ""}
              />
            )}
            {ticket.allowFeedback && showFeedBackDeadline && (
              <TicketFeedback id={ticket.ticketId} onCancel={handleClose} />
            )}
          </InformationWrapper>
        </ContentWrapper>
        <ContactFooter
          ticketId={ticket.sourceId}
          type={ticket.type}
          ticketTitle={ticket.description}
          answerCodes={ticket.answerCodes}
        />
      </>
    </SlideIn>
  )
}

export default ReportDetail
