import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 80%;
  }
`

export const ImageContainer = styled.div`
  margin: 4rem 0 1.6rem;
`

export const StyledH1 = styled.h1`
  font-size: ${theme.fontSizes.h4};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.neutral.darkerGrey};
  text-align: center;
  line-height: ${theme.lineHeights.h4};
  margin-bottom: 3.2rem;
`

export const StyledSubtitle = styled.p`
  font-size: ${theme.fontSizes.caption};
  color: ${theme.colors.neutral.darkGrey};
  text-align: center;
  margin-bottom: 3.2rem;
  max-width: 90%;
`

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.green.contrast};
`
