import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const DisclaimerText = styled.p`
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.lineHeights.captionExtraSmall};
  color: ${theme.colors.neutral.white};
  margin-bottom: 0.8rem;
`

export const DisclaimerSubTitle = styled.p`
  font-size: ${theme.fontSizes.captionExtraSmall};
  font-weight: ${theme.fontWeights.bold};
  line-height: ${theme.lineHeights.captionExtraSmall};
  color: ${theme.colors.neutral.white};
`
