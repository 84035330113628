import { getZonedDateFromUTCIsoDate } from "app/utils/timeZone.utils"
import { format } from "date-fns"

export const formatRange = (
  fromIsoDateString: string, 
  toIsoDateString: string
) => {
  const f = getZonedDateFromUTCIsoDate(fromIsoDateString, "Belgium")
  const t = getZonedDateFromUTCIsoDate(toIsoDateString, "Belgium")
  const _from = formatDate(f)

  const _to = formatDate(t)
  if (f.getFullYear() === t.getFullYear() && f.getMonth() === t.getMonth())
    return `${_from}`
  else return `${_from} - ${_to}`
}

export const formatDate = (d: Date) => format(d, "MMM yyyy")
