import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledFooter = styled.div`
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;

  span {
    margin: 0 0.2rem;
    cursor: default;
  }

  span,
  a {
    color: ${theme.colors.neutral.darkGrey};
    font-size: ${theme.fontSizes.caption};
  }
`
