import { IntervalEnum } from "app/enums/interval"
import { TagEnum } from "../graphs/graphs.actions"

export enum Types {
  DOWNLOAD_CSV = "DOWNLOAD_ENERGY_CSV",
  DOWNLOAD_CSV_SUCCESS = "DOWNLOAD_ENERGY_CSV_SUCCESS",
  DOWNLOAD_CSV_FAIL = "DOWNLOAD_ENERGY_CSV_FAIL",
  SET_SELECTED_METER = "SET_SELECTED_METER"
}

export type GetEnergyCSVParams = {
  to: string | undefined
  from: string | undefined
  interval: IntervalEnum
  tags: TagEnum[]
}

export type Action =
  | DownloadEnergyCSVAction
  | DownloadEnergyCSVSuccessAction
  | DownloadEnergyCSVFailAction
  | SetSelectedMeterAction

export type DownloadEnergyCSVAction = {
  payload: { data: GetEnergyCSVParams }
  type: Types.DOWNLOAD_CSV
}

export type DownloadEnergyCSVSuccessAction = {
  type: Types.DOWNLOAD_CSV_SUCCESS
}

export type DownloadEnergyCSVFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DOWNLOAD_CSV_FAIL
}

export type SetSelectedMeterAction = {
  payload: { selected: TagEnum[] }
  type: Types.SET_SELECTED_METER
}

export function downloadEnergyCSV(data: GetEnergyCSVParams): Action {
  return {
    payload: { data },
    type: Types.DOWNLOAD_CSV
  }
}

export function downloadEnergyCSVSuccess(): Action {
  return {
    type: Types.DOWNLOAD_CSV_SUCCESS
  }
}

export function downloadEnergyCSVFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.DOWNLOAD_CSV_FAIL
  }
}

export function setSelectedMeter(selected: TagEnum[]): Action {
  return {
    payload: { selected },
    type: Types.SET_SELECTED_METER
  }
}
