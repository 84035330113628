import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"
import ContactSidebar from "../ContactSidebar"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.4rem 1.6rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: initial;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    gap: 2.8rem;
    grid-template-areas:
      "specification contract"
      "specification contact"
      "contactfooter contactfooter";
  }
`

export const StyledSection = styled.section`
  width: 100%;
  margin-bottom: 2.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: initial;
    margin-bottom: initial;
  }
`

export const StyledContactSidebar = styled(ContactSidebar)<{ addMargin: boolean }>`
  ${props => Boolean(props.addMargin) && "margin-top: 5.6rem;"}
`
