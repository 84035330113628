import { getAvailableMetersForRentedBuilding } from "app/api/energy"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./graphs.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* availableMetersFlow(
  action: actions.GetAvailableMetersAction
): SagaIterator {
  try {
    const { rentedBuildingId, params } = action.payload
    yield put(actions.setRentedBuildingId(rentedBuildingId))
    const response = yield call(
      getAvailableMetersForRentedBuilding,
      rentedBuildingId,
      params
    )
    yield put(actions.getAvailableMetersSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getAvailableMetersFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export default function* availableMetersSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, availableMetersFlow)
}
