import React from "react"
import { useSelector } from "react-redux"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import * as selectors from "app/redux/rentedBuildingContracts/rentedBuildingContracts.selector"
import { getRentedBuildingContracts } from "app/redux/rentedBuildingContracts/rentedBuildingContracts.actions"
import MultiContractView from "./MultiContractView"
import SingleContractView from "./SingleContractView"
import { useApi } from "hooks/api"

const BuildingContract = () => {
  const { call } = useApi()
  const activeRentedBuilding = useSelector(getActiveBuilding)
  const rentedBuildingContracts = useSelector(
    selectors.getRentedBuildingContracts
  )

  const isLoading = useSelector(selectors.isLoadingRentedBuildingContracts)

  React.useEffect(() => {
    if (!activeRentedBuilding) return
    call(getRentedBuildingContracts(activeRentedBuilding))
  }, [call, activeRentedBuilding])

  if (
    !rentedBuildingContracts ||
    isLoading ||
    rentedBuildingContracts.length === 0
  )
    return null

  return rentedBuildingContracts.length === 1 ? (
    <SingleContractView contract={rentedBuildingContracts[0]} />
  ) : (
    <MultiContractView contracts={rentedBuildingContracts} />
  )
}

export default BuildingContract
