import { AssociationTypes } from "app/api/mfa"
import { MfaType } from "app/types/myProfile"

export enum Types {
  MFA_ASSOCIATE = "MFA_ASSOCIATE",
  MFA_ASSOCIATE_SUCCESS = "MFA_ASSOCIATE_SUCCESS",
  MFA_ASSOCIATE_FAIL = "MFA_ASSOCIATE_FAIL",
  MFA_ENROLL = "MFA_ENROLL",
  MFA_ENROLL_SUCCESS = "MFA_ENROLL_SUCCESS",
  MFA_ENROLL_FAIL = "MFA_ENROLL_FAIL",
  MFA_DELETE = "MFA_DELETE",
  MFA_DELETE_SUCCESS = "MFA_DELETE_SUCCESS",
  MFA_DELETE_FAIL = "MFA_DELETE_FAIL"
}

export type Action =
  | MfaAssociateAction
  | MfaAssociateSuccessAction
  | MfaAssociateFailAction
  | MfaEnrollAction
  | MfaEnrollSuccessAction
  | MfaEnrollFailAction
  | MfaDeleteAction
  | MfaDeleteSuccessAction
  | MfaDeleteFailAction

export type MfaAssociateAction = {
  type: Types.MFA_ASSOCIATE
  payload: { type: AssociationTypes; phoneNumber?: string }
}

export type MfaAssociateSuccessAction = {
  type: Types.MFA_ASSOCIATE_SUCCESS
  payload: {
    barcodeUri?: string
    oobCode?: string
  }
}

export type MfaAssociateFailAction = {
  type: Types.MFA_ASSOCIATE_FAIL
}

export type MfaEnrollAction = {
  type: Types.MFA_ENROLL
  payload: {
    code: string
    type: AssociationTypes
  }
}

export type MfaEnrollSuccessAction = {
  type: Types.MFA_ENROLL_SUCCESS
}

export type MfaEnrollFailAction = {
  type: Types.MFA_ENROLL_FAIL
}

export type MfaDeleteAction = {
  type: Types.MFA_DELETE
  payload: { type: MfaType }
}

export type MfaDeleteSuccessAction = {
  type: Types.MFA_DELETE_SUCCESS
}

export type MfaDeleteFailAction = {
  type: Types.MFA_DELETE_FAIL
}

export function associate(
  type: AssociationTypes
): MfaAssociateAction {
  return {
    type: Types.MFA_ASSOCIATE,
    payload: { type }
  }
}

export function associateSuccess({
  barcodeUri
}: {
  barcodeUri?: string
}): MfaAssociateSuccessAction {
  return {
    type: Types.MFA_ASSOCIATE_SUCCESS,
    payload: { barcodeUri }
  }
}

export function associateFail(): MfaAssociateFailAction {
  return {
    type: Types.MFA_ASSOCIATE_FAIL
  }
}

export function enroll(type: AssociationTypes, code: string): MfaEnrollAction {
  return {
    type: Types.MFA_ENROLL,
    payload: { code, type }
  }
}

export function enrollSuccess(): MfaEnrollSuccessAction {
  return {
    type: Types.MFA_ENROLL_SUCCESS
  }
}

export function enrollFail(): MfaEnrollFailAction {
  return {
    type: Types.MFA_ENROLL_FAIL
  }
}

export function deleteMfa(type: MfaType): MfaDeleteAction {
  return {
    type: Types.MFA_DELETE,
    payload: { type }
  }
}

export function deleteMfaSuccess(): MfaDeleteSuccessAction {
  return {
    type: Types.MFA_DELETE_SUCCESS
  }
}

export function deleteMfaFail(): MfaDeleteFailAction {
  return {
    type: Types.MFA_DELETE_FAIL
  }
}
