import { useTranslate } from "hooks/translate"
import React from "react"
import BaseGraph from "../BaseGraph"
import { Graphs } from "app/utils/graphs/graph.utils"
import { useSelector } from "react-redux"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useApi } from "hooks/api"
import { getSolarProductionHistory } from "app/redux/solarProductionHistory/solarProductionHistory.actions"
import {
  getLastUpdated,
  getSolarProductionHistoryData,
  hasWarning,
  isLoading
} from "app/redux/solarProductionHistory/solarProductionHistory.selectors"
import { getIntervalOfGraph, getEnergyPeriodRange } from "app/redux/energyPeriod/energyPeriod.selectors"

const SolarProductionGraph = () => {
  const { call } = useApi()
  const { rentedBuilding } = useRentedBuilding()
  const warning = useSelector(hasWarning)
  const solarProductionHistory = useSelector(getSolarProductionHistoryData)
  const lastUpdated = useSelector(getLastUpdated)
  const interval = useSelector(getIntervalOfGraph(Graphs.SOLAR_PRODUCTION))
  const energyPeriod = useSelector(getEnergyPeriodRange)
  const loading = useSelector(isLoading)
  const { translate } = useTranslate()

  React.useEffect(() => {
    if (
      rentedBuilding &&
      rentedBuilding.id
    ) {
      call(
        getSolarProductionHistory(rentedBuilding, {
          interval: interval,
          from: energyPeriod.from,
          to: energyPeriod.to
        })
      )
    }
  }, [call, energyPeriod.from, energyPeriod.to, interval, rentedBuilding])

  const refreshGraph = () => {
    call(getSolarProductionHistory(rentedBuilding!, {
      interval: interval,
      from: energyPeriod.from,
      to: energyPeriod.to
    }))
  }

  return (
    <BaseGraph
      lastUpdated={lastUpdated}
      refreshGraph={refreshGraph}
      loading={loading}
      title={translate("portal.energy.graphs.solar.production.title")}
      graphType={Graphs.SOLAR_PRODUCTION}
      graphSerieData={solarProductionHistory}
      warning={warning}
    />
  )
}

export default SolarProductionGraph

