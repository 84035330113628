import { MeterHistoryData } from "app/types/energy"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.electricityMeterHistory.loading
export const hasErrors = (state: State): boolean =>
  state.electricityMeterHistory.errors ? true : false
export const hasWarning = (state: State): boolean =>
  getElectricityMeterHistoryData(state)?.some(data => data.msg) || hasErrors(state)
export const getLastUpdated = (state: State): Date | undefined =>
  state.electricityMeterHistory.lastUpdated
export const getElectricityMeterHistoryData = (
  state: State
): MeterHistoryData[] | undefined => {
  return state.electricityMeterHistory.data
}
