import { ContractStatus } from "./types"
import {
  StyledGroupedLabel,
  StyledGroupedLabelContainer,
  StyledGroupLabelStatus
} from "./style"
import { useTranslate } from "hooks/translate"

type RestProps = {
  group: ContractStatus
  groupLength: number
}

type Props = RestProps

const ContractGroupLabel = ({ group, groupLength }: Props) => {
  const { translate } = useTranslate()
  return (
    <StyledGroupedLabelContainer>
      <StyledGroupLabelStatus status={group} />
      <StyledGroupedLabel>{`${translate(
        `portal.buildingpassport.contract.status.${group}`
      )} (${groupLength})`}</StyledGroupedLabel>
    </StyledGroupedLabelContainer>
  )
}

export default ContractGroupLabel
