import { ChevronIcon, Typography } from "@paudigital/wdp-components"
import { Modules } from "app/enums/modules"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { usePermission } from "hooks/permission"
import { useSelector } from "react-redux"
import {
  ChevronWrapper,
  Description,
  StyledContainer,
  TextWrapper,
  TitleWrapper,
  WidgetImage,
  WidgetHeader,
  Container
} from "./style"

export type ModuleWidgetProps = {
  children?: JSX.Element | JSX.Element[]
  title: string
  description: string
  image: JSX.Element
  url?: string
  permissionModule?: Modules
  isExperts?: boolean
  dataTestId?: string
}

type Props = ModuleWidgetProps

const ModuleWidget = ({
  permissionModule,
  children,
  description,
  title,
  image,
  url,
  isExperts = false,
  dataTestId
}: Props) => {
  const rentedBuildingId = useSelector(getActiveBuilding)
  const { hasPermission } = usePermission()

  if (!rentedBuildingId) {
    return null
  }

  if (permissionModule) {
    if (
      !hasPermission(permissionModule, rentedBuildingId) ||
      !rentedBuildingId
    ) {
      return null
    }
  }

  return (
    <StyledContainer id={isExperts ? "experts" : ""} data-testid={dataTestId}>
      <Container>
        <WidgetHeader to={url!} as={!url ? "div" : undefined}>
          <TextWrapper>
            <Typography variant="h5" fontStyle="bold">
              <TitleWrapper>
                {title}
                {url && (
                  <ChevronWrapper>
                    <ChevronIcon width="16px" height="10px" />
                  </ChevronWrapper>
                )}
              </TitleWrapper>
            </Typography>
            <Description>{description}</Description>
          </TextWrapper>
          <WidgetImage pullUp={permissionModule! === Modules.ENERGY}>
            {image}
          </WidgetImage>
        </WidgetHeader>
        {children}
      </Container>
    </StyledContainer>
  )
}

export default ModuleWidget
