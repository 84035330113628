import React from "react"
import {
  StyledFilterHeader,
  StyledFilterTitle,
  StyledClearFilters,
  StyledFilterSubTitle,
  FilterInput
} from "./style"
import StatusFilter from "./StatusFilter"
import TypeFilter from "./TypeFilter"
import { useTranslate } from "hooks/translate"
import { getTicketSummary } from "app/redux/ticket/ticket.actions"
import { useApi } from "hooks/api"
import { useSelector } from "react-redux"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import PageFilter from "app/components/Page/PageFilter"
import DateRangePicker from "app/components/DateRangePicker"
import Sort from "./Sort"
import { useViewport } from "hooks/viewport"
import { theme } from "@paudigital/wdp-components"
import { trimEvent } from "app/utils/trimEvent.utils"
import { FiltersAndModalOpened, Filters } from "routes/FacilityManagement"

export type FilterProps = {
  closeFilters?: () => void
  setUrlParams: (params: FiltersAndModalOpened) => void
} & Filters

type Props = FilterProps

const FacilityFilter = ({
  page,
  perPage,
  sort,
  status,
  type,
  from,
  to,
  ticket,
  description,
  setUrlParams,
  closeFilters
}: Props) => {
  const { translate } = useTranslate()
  const { width } = useViewport()
  const { call } = useApi()
  const rentedBuildingId = useSelector(getActiveBuilding)
  const queryParams = {
    page,
    perPage,
    sort,
    status,
    type,
    from,
    to,
    ticket,
    description
  }

  React.useEffect(() => {
    rentedBuildingId && call(getTicketSummary(rentedBuildingId))
  }, [call, rentedBuildingId])

  const updateDate = (date: string, name: string) => {
    if (date === "Invalid date" || date === "") {
      setUrlParams({ ...queryParams, [name]: null, page: 1 })
    } else {
      setUrlParams({ ...queryParams, [name]: date, page: 1 })
    }
  }

  const changeTicketOrDescription = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const event = trimEvent(e)
    const { name } = e.target
    const { value } = event.currentTarget
    if (value === "" && queryParams) {
      delete queryParams![name as keyof FiltersAndModalOpened]
      setUrlParams(queryParams)
    } else {
      setUrlParams({ ...queryParams, [name]: value, page: 1 })
    }
  }

  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const event = trimEvent(e)
      const { name } = e.currentTarget
      const { value } = event.currentTarget
      if (value === "" && queryParams) {
        delete queryParams![name as keyof FiltersAndModalOpened]
        setUrlParams(queryParams)
      } else {
        setUrlParams({ ...queryParams, [name]: value, page: 1 })
      }
    }
  }

  const clear = () => setUrlParams({ page: 1, perPage: 30, sort })

  return (
    <PageFilter closeFilters={closeFilters}>
      <StyledFilterHeader>
        <StyledFilterTitle>
          {translate("portal.facilitymanagement.filter")}
        </StyledFilterTitle>
        <StyledClearFilters
          onClick={clear}
          dataTestId={"facility-managment.filter.clear"}
        >
          {translate("portal.facilitymanagement.filter.clear")}
        </StyledClearFilters>
      </StyledFilterHeader>
      <>
        {width < theme.breakpointsValues.tablet && (
          <Sort setUrlParams={setUrlParams} {...queryParams} />
        )}
      </>
      <StyledFilterSubTitle>
        {translate("portal.facilitymanagement.filter.status")}
      </StyledFilterSubTitle>
      <StatusFilter setUrlParams={setUrlParams} {...queryParams} />
      <StyledFilterSubTitle>
        {translate("portal.facilitymanagement.filter.type")}
      </StyledFilterSubTitle>
      <TypeFilter setUrlParams={setUrlParams} {...queryParams} />
      <StyledFilterSubTitle>
        {translate("portal.facilitymanagement.filter.report-date")}
      </StyledFilterSubTitle>
      <DateRangePicker
        id="facility"
        to={to as string}
        from={from as string}
        updateDate={updateDate}
      />
      <StyledFilterSubTitle>
        {translate("portal.facilitymanagement.filter.ticket-number")}
      </StyledFilterSubTitle>
      <FilterInput
        name="ticket"
        defaultValue={ticket as string}
        onBlur={changeTicketOrDescription}
        onKeyDown={handleChange}
        dataTestId="facility-managment.filter.ticket"
      />
      <StyledFilterSubTitle>
        {translate("portal.facilitymanagement.filter.description")}
      </StyledFilterSubTitle>
      <FilterInput
        name="description"
        defaultValue={description as string}
        onBlur={changeTicketOrDescription}
        onKeyDown={handleChange}
        dataTestId="facility-managment.filter.description"
      />
    </PageFilter>
  )
}

export default FacilityFilter
