import * as api from "app/api/ticket"
import { SagaIterator } from "redux-saga"
import { call, put, select, take, takeLatest } from "redux-saga/effects"
import * as actions from "./ticketCommunication.actions"
import { fire, remove } from "../appEvents/appEvents.actions"
import { getTickets, discardTicket } from "../ticket/ticket.actions"
import { getActiveBuilding } from "./../buildingSwitcher/buildingSwitcher.selectors"
import { getParams } from "../ticket/ticket.selectors"
import { getCustomerCommunication } from "./ticketCommunication.selectors"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import * as meSelectors from "app/redux/me/me.selectors"
import * as ticketsActions from "app/redux/ticket/ticket.actions"
import { intl, Locales } from "app/i18n/config"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* patchTicketCommunicationFlow(
  action: actions.PatchTicketsAction
): SagaIterator {
  try {
    const {
      event,
      uniqueIdentifier,
      data: { files },
      rentedBuildingId,
      id
    } = action.payload
    const buildingId = yield select(getActiveBuilding)
    const params = yield select(getParams)
    yield call(api.patchTicketCommunication, action.payload)
    if (files && files.length !== 0) {
      yield put(
        ticketsActions.postTicketAttachments({
          rentedBuildingId,
          id,
          files: files!
        })
      )
      yield take(ticketsActions.postTicketAttachmentsSuccess)
    }
    yield put(getTickets({ ...params, rentedBuildingId: buildingId }))
    yield put(remove("report-detail"))
    yield put(fire({ eventName: event, uniqueIdentifier }))
    yield put(actions.patchTicketsSuccess())
    yield put(discardTicket())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.patchTicketsFail(e))
  }
}

export function* closeFlow(action: actions.CloseReportDetail): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )
  const communication = yield select(getCustomerCommunication)
  if (communication) {
    yield put(
      fire({
        eventName: PossibleAppEvents.CLOSE_REPORT,
        uniqueIdentifier: "close-report",
        props: {
          previousUniqueIdentifier: "report-detail",
          previousEvent: PossibleAppEvents.REPORT_DETAIL,
          previousEventProps: { id: action.payload.id },
          title: intl[preferredLanguage].formatMessage({
            id: "portal.facilitymanagement.reportdetail.close.title"
          })
        }
      })
    )
  }
  yield put(remove("report-detail"))
}

export default function* ticketCommunicationSaga(): SagaIterator {
  yield takeLatest(actions.Types.PATCH, patchTicketCommunicationFlow)
  yield takeLatest(actions.Types.CLOSE, closeFlow)
}
