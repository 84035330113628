import { useLocation, matchPath } from "react-router-dom"

export const useNavigation = () => {
  const location = useLocation()

  const isActive = (route: string): boolean => {
    const match = matchPath(location.pathname, route)
    return match ? true : false
  }

  return { isActive }
}
