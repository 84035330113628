import { RentedBuildingMeter } from "app/types/energy"

export enum Types {
  GET = "GET_AVAILABLE_METERS",
  GET_SUCCESS = "GET_AVAILABLE_METERS_SUCCESS",
  GET_FAIL = "GET_AVAILABLE_METERS_FAIL",
  SET = "SET_RENTED_BUILDING_ID"
}

export enum TagEnum {
  ELECTRICITY = "electricity",
  ELECTRICITY_CONSUMPTION = "electricity consumption",
  ELECTRICITY_YIELD_PV_MAIN = "electricity-yield PV main",
  ELECTRICITY_GRID = "electricity grid",
  ELECTRICITY_YIELD_INJECTION = "electricity injection",
  ELECTRICITY_YIELD_PV_CONSUMPTION = "electricity-yield PV consumption",
  TEMPERATURE = "temperature",
  QUANTITY = "quantity",
  WATER = "water",
  WATER_MAIN = "water portal wdp",
  WATER_MANUAL = "water manual",
  GAS = "gas",
  GAS_MAIN = "gas main",
  GAS_MANUAL = "gas manual"
}

export type QueryParams = {
  tags?: Array<TagEnum>
}

export type Action =
  | GetAvailableMetersAction
  | GetAvailableMetersSuccessAction
  | GetAvailableMetersFailAction
  | SetRentedBuildingId

export type GetAvailableMetersAction = {
  type: Types.GET
  payload: {
    rentedBuildingId: string
    params: QueryParams
  }
}

export type GetAvailableMetersSuccessAction = {
  type: Types.GET_SUCCESS
  payload: {
    data: RentedBuildingMeter[]
  }
}

export type GetAvailableMetersFailAction = {
  type: Types.GET_FAIL
  payload: {
    errors: Record<string, unknown>
  }
}

export type SetRentedBuildingId = {
  type: Types.SET
  payload: {
    rentedBuildingId: string
  }
}

export function getAvailableMeters(
  rendtedBuildingId: string,
  params: QueryParams
): Action {
  return {
    type: Types.GET,
    payload: {
      rentedBuildingId: rendtedBuildingId,
      params
    }
  }
}

export function getAvailableMetersSuccess(data: RentedBuildingMeter[]): Action {
  return {
    type: Types.GET_SUCCESS,
    payload: {
      data
    }
  }
}

export function getAvailableMetersFail(
  errors: Record<string, unknown>
): Action {
  return {
    type: Types.GET_FAIL,
    payload: {
      errors
    }
  }
}

export function setRentedBuildingId(rentedBuildingId: string): Action {
  return {
    type: Types.SET,
    payload: {
      rentedBuildingId
    }
  }
}
