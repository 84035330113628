import { Campaign } from "app/types/banner"

export enum Types {
  GET_CAMPAIGN = "GET_CAMPAIGN",
  GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS",
  GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL"
}

export type Action =
  | GetCampaignAction
  | GetCampaignSuccessAction
  | GetCampaignFailAction

export type GetCampaignAction = {
  type: Types.GET_CAMPAIGN
}

export type GetCampaignSuccessAction = {
  type: Types.GET_CAMPAIGN_SUCCESS
  payload: { data: Campaign[] }
}

export type GetCampaignFailAction = {
  type: Types.GET_CAMPAIGN_FAIL
  payload: { errors: Record<string, unknown> }
}

export function getCampaign(): Action {
  return {
    type: Types.GET_CAMPAIGN
  }
}

export function getCampaignSuccess(data: Campaign[]): Action {
  return {
    type: Types.GET_CAMPAIGN_SUCCESS,
    payload: { data }
  }
}

export function getCampaignFail(errors: Record<string, unknown>): Action {
  return {
    type: Types.GET_CAMPAIGN_FAIL,
    payload: { errors }
  }
}
