import { RentedBuildingMeter } from "app/types/energy"
import { Action, QueryParams, Types } from "./graphs.actions"

export type AvailableMetersState = {
  data: RentedBuildingMeter[]
  errors?: Record<string, unknown>
  params?: QueryParams
  loading: boolean
  rentedBuildingId?: string
}

const initialState: AvailableMetersState = {
  data: [],
  errors: undefined,
  params: undefined,
  loading: false
}

export default (state = initialState, action: Action): AvailableMetersState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true,
        errors: undefined,
        params: action.payload.params
      }
    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      }
    case Types.GET_FAIL:
      return {
        ...state,
        data: [],
        loading: false,
        errors: action.payload.errors
      }
    case Types.SET:
      return {
        ...state,
        rentedBuildingId: action.payload.rentedBuildingId
      }
    default:
      return {
        ...state
      }
  }
}
