import { Title } from "./style"

type BannerTitleProps = {
  children: JSX.Element | string
  color?: string | undefined
}

type Props = BannerTitleProps

const BannerTitle = ({ children, color }: Props) => (
  <Title color={color}>{children}</Title>
)

export default BannerTitle
