import { DocumentIcon, theme } from "@paudigital/wdp-components"
import { DocumentTypes } from "app/enums/documentTypes"
import { Modules } from "app/enums/modules"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { ExpertPopupButtonPosition } from "app/redux/gaEvents/gaEvents.actions"
import { PvProject } from "app/types/pvProject"
import { format } from "date-fns"
import { mappings } from "hooks/contactPerson"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { Step } from "./Step"
import {
  InfoContainer,
  LastUpdated,
  LastUpdatedContainer,
  LastUpdatedDate,
  LinkContainer,
  OverviewWrapper,
  StepContainer,
  StyledButton,
  StyledLink,
  Title,
  TitleContainer
} from "./style"

export type PvProjectProps = {
  project: PvProject
}

export const PvProjectOverview = ({ project }: PvProjectProps) => {
  const navigate = useNavigate()
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const activeRentedBuilding = useSelector(getActiveBuilding)

  const togglePopUp = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.SHOW_EXPERT,
        uniqueIdentifier: "show-expert",
        props: {
          option: mappings[Modules.ENERGY],
          position: ExpertPopupButtonPosition.BOTTOM
        }
      })
    )
  }

  const page = 1
  const perpage = 20
  const sort = "date=desc"

  const RedirectDocuments = (docType: string) => {
    navigate(
      `/documents/${activeRentedBuilding}?documentType=${docType}&page=${page}&perPage=${perpage}&sort[]=${sort}`
    )
  }

  return (
    <OverviewWrapper>
      <InfoContainer>
        <TitleContainer>
          <Title>{translate("portal.energy.pvproject.title")}</Title>
          <LastUpdatedContainer>
            <LastUpdated>
              {translate("portal.energy.pvproject.lastupdated")}
            </LastUpdated>
            <LastUpdatedDate>
              {format(new Date(project.updatedOn), "dd/MM/yyyy")}
            </LastUpdatedDate>
          </LastUpdatedContainer>
        </TitleContainer>
        <LinkContainer>
          <StyledLink
            type="default"
            value={translate("portal.energy.pvproject.help")}
            onClick={togglePopUp}
          />
          <StyledLink
            type="default"
            value={translate("portal.energy.pvproject.faq")}
            href="https://brains.wdp.eu/en/energy-solar-installations/blog"
          />
          <StyledButton
            startIcon={<DocumentIcon fill={theme.colors.green.contrast} />}
            label={translate("portal.energy.pvproject.related.documents", {
              amount: project.documentCount
            })}
            type="secondary"
            onClick={() => RedirectDocuments(DocumentTypes.Pv_Projects)}
          />
        </LinkContainer>
      </InfoContainer>
      <StepContainer>
        {project.steps.map(step => {
          return <Step step={step} />
        })}
      </StepContainer>
    </OverviewWrapper>
  )
}
