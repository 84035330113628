import { useTranslate } from "hooks/translate"
import { 
  ChartContainer, 
  ChartHeader, 
  ChartHeaderInnerWrapper, 
  StyledEnergyMeasurement, 
  StyledEnergyMeasurementType, 
  StyledTotalUsageWrapper,
  StyledPercentageDisplay
} from "./style"
import { EnergyIcon, GasIcon, WaterIcon } from "@paudigital/wdp-components"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { buildGraphOptions } from "./graphOptions"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"

type UsageComparisonGraphProps = {
  name: string
  data: Array<[number, number | null]>
  percentageDifference?: number
  currentPeriodTotal?: number
}

const IconMapping: { [key: string]: JSX.Element } = {
  "electricityConsumption": <EnergyIcon width="32" height="32"/>,
  "waterConsumption": <WaterIcon width="32" height="32"/>,
  "gasConsumption": <GasIcon width="32" height="32"/>
}

const MeasurementMapping: { [key: string]: string } = {
  "electricityConsumption": "kWh",
  "waterConsumption": "l",
  "gasConsumption": "m³"
}

const UsageComparisonGraph = ({ 
  name, 
  data, 
  percentageDifference,
  currentPeriodTotal
}: UsageComparisonGraphProps) => {
  const { translate } = useTranslate()
  const graphOptions = buildGraphOptions(data)
  const { integerFormatter, decimalFormatter } = getLocaleNumberFormatter()

  const getCurrentPeriodTotal = () => {
    if(currentPeriodTotal) return integerFormatter(currentPeriodTotal)
    else return <span>--</span>
  }

  return (
    <ChartContainer>
      <ChartHeader>
        <ChartHeaderInnerWrapper>
          {IconMapping[name]}
          <StyledTotalUsageWrapper>
              <StyledEnergyMeasurement>{getCurrentPeriodTotal()} {MeasurementMapping[name]}</StyledEnergyMeasurement>
              <StyledEnergyMeasurementType>{translate(`portal.dashboard.widget.energy.${name}`)}</StyledEnergyMeasurementType>
          </StyledTotalUsageWrapper>
        </ChartHeaderInnerWrapper>
        {percentageDifference && <StyledPercentageDisplay>
          <span>{percentageDifference > 0 && "+"}{decimalFormatter(percentageDifference, 0)}%</span> {translate("portal.dashboard.widget.energy.vsdays", { days: 30 })}
        </StyledPercentageDisplay>}
      </ChartHeader>
      {currentPeriodTotal && <HighchartsReact
        highcharts={Highcharts}
        options={graphOptions}
        containerProps={{ style: { height: "4rem", width: "100%" } }}
        immutable={true}
      />}
    </ChartContainer>
  )
}

export default UsageComparisonGraph
