import { WorkOrderStatusEnum } from "app/types/ticket"
import { dateFormatShort } from "app/utils/date.utils"
import { useTranslate } from "hooks/translate"
import { colorMapping } from "./config"
import { StatusContainer, StyledDot, StyledStatusTitle } from "./style"

export type WorkOrderStatusProps = {
  status: string
  plannedOn?: string
  count?: number
}

export const WorkOrderStatus = ({
  status,
  plannedOn,
  count
}: WorkOrderStatusProps) => {
  const { translate } = useTranslate()
  const getTranslation = () => {
    if (status === WorkOrderStatusEnum.WO42) {
      if (plannedOn && !count) {
        return translate(`portal.facilitymanagement.ticket.WO42-date`, {
          plannedOn: dateFormatShort(plannedOn)
        })
      }
    }
    return `${translate(`portal.facilitymanagement.ticket.${status}`)} ${
      count ? `(${count})` : ""
    }`
  }
  return (
    <StatusContainer>
      <StyledDot fill={colorMapping[status]} />
      <StyledStatusTitle>{getTranslation()}</StyledStatusTitle>
    </StatusContainer>
  )
}

export default WorkOrderStatus
