import { theme } from "@paudigital/wdp-components"
import { intl, Locales } from "app/i18n/config"
import { parse } from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"
import { getLocaleNumberFormatter } from "../localeNumberFormatter.utils"
import { generateTooltipLabel } from "./baseGraphOptions"

export const getElectricityConsumptionChartOptions = (language: Locales) => {
  return {
    yAxis: {
      title: {
        text: intl[language].formatMessage({
          id: "portal.energy.graphs.electricity.title"
        })
      },
      labels: {
        formatter: function () {
          const self: any = this
          const { decimalFormatter } = getLocaleNumberFormatter()
          return `<span style="display: block; text-align: center; color: ${
            theme.colors.neutral.darkGrey
          }; line-height: 14px;">${decimalFormatter(self.value, 0)} kWh</span>`
        },
        useHTML: true
      },
      plotLines: []
    },
    xAxis: {
      startOnTick: true,
      endOnTick: true,
      width: "100%",
      maxPadding: 0,
      minPadding: 0,
      tickInterval: 3600,
      labels: {
        formatter: function () {
          const self: any = this
          const parsedDate = utcToZonedTime(
            parse(self.value, "t", new Date()),
            "UTC"
          )
          const formattedDate = format(parsedDate, "HH:mm", { timeZone: "UTC" })
          return `<span style="display: block; text-align: center; color: ${theme.colors.neutral.darkGrey}; line-height: 14px;">${formattedDate}</span>`
        },
        useHTML: true
      }
    },
    tooltip: {
      shared: true,
      backgroundColor: theme.colors.neutral.white,
      borderColor: theme.colors.neutral.darkerGrey,
      style: {
        fontSize: "12px"
      },
      formatter: function () {
        const self: any = this
        self.points.reverse()
        return self.points
          ? self.points.map(
              (point: {
                x: string
                color: any
                series: { name: any }
                y: any
              }) =>
                generateTooltipLabel(point, "kWh", language)
            )
          : []
      },
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.05,
        groupPadding: 0.1
      }
    },
    series: []
  }
}

export const electricityConsumptionAreaSplineSerieOptions = [
  {
    data: [],
    type: "areaspline",
    name: "electricityGrid",
    color: theme.colors.graphs.wintergreenDream,
    lineColor: theme.colors.graphs.wintergreenDream,
    fillColor: theme.colors.graphs.wintergreenDream,
    marker: {
      enabled: false
    },
    states: {
      inactive: {
        enabled: false
      }
    },
    opacity: 1,
    index: 3,
    stack: 1,
    stacking: "overlap"
  },
  {
    data: [],
    type: "areaspline",
    name: "solarConsumption",
    color: theme.colors.graphs.morningBlue,
    lineColor: theme.colors.graphs.morningBlue,
    fillColor: theme.colors.graphs.morningBlue,
    marker: {
      enabled: false
    },
    states: {
      inactive: {
        enabled: false
      }
    },
    opacity: 1,
    index: 2,
    stack: 1,
    stacking: "overlap"
  },
  {
    data: [],
    type: "areaspline",
    name: "solarInjection",
    color: theme.colors.graphs.peachPuff,
    lineColor: theme.colors.graphs.peachPuff,
    fillColor: theme.colors.graphs.peachPuff,
    marker: {
      enabled: false
    },
    states: {
      inactive: {
        enabled: false
      }
    },
    opacity: 1,
    index: 1,
    stack: 1,
    stacking: "overlap"
  },
  {
    data: [],
    type: "areaspline",
    name: "electricityConsumption",
    color: theme.colors.green.contrast,
    lineColor: theme.colors.green.contrast,
    fillColor: `${theme.colors.neutral.black}00`,
    fillOpacity: 0,
    marker: {
      enabled: false
    },
    states: {
      inactive: {
        enabled: false
      }
    },
    opacity: 1,
    index: 4,
    stack: 0,
    stacking: "overlap"
  }
]

export const electricityConsumptionStackedBarSerieOptions = [
  {
    data: [],
    name: "electricityGrid",
    type: "column",
    stacking: "normal",
    borderWidth: 0,
    color: theme.colors.graphs.wintergreenDream,
    index: 3,
    states: {
      inactive: {
        enabled: false
      }
    },
    stack: 1,
    grouping: false,
    pointPlacement: 0
  },
  {
    data: [],
    name: "solarConsumption",
    type: "column",
    stacking: "normal",
    borderWidth: 0,
    color: theme.colors.graphs.morningBlue,
    index: 2,
    states: {
      inactive: {
        enabled: false
      }
    },
    stack: 1,
    grouping: false,
    pointPlacement: 0
  },
  {
    data: [],
    name: "solarInjection",
    type: "column",
    stacking: "normal",
    borderWidth: 0,
    color: theme.colors.graphs.peachPuff,
    index: 1,
    states: {
      inactive: {
        enabled: false
      }
    },
    stack: 1,
    grouping: false,
    pointPlacement: 0
  },
  {
    data: [],
    name: "electricityConsumption",
    type: "column",
    stacking: undefined,
    borderWidth: 0,
    color: theme.colors.green.contrast,
    index: 0,
    states: {
      inactive: {
        enabled: false
      }
    },
    stack: 0,
    grouping: false,
    pointPlacement: 0
  }
]
