import {
  Page,
  Typography,
  NotFound,
  DropDown,
  LoadingScreen
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import {
  StyledLink,
  StyledSpan,
  Container,
  DropDownWrapper,
  StyledButton
} from "./style"
import {
  getSortedRentedBuildings,
  getPreferredBuilding,
  loading
} from "app/redux/me/me.selectors"
import { useState } from "react"
import { useNavigate } from "react-router"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { useApi } from "hooks/api"
import { patchPreferedBuilding } from "app/redux/preferredBuilding/preferredBuilding.actions"
import { useLogout } from "hooks/logout"
import * as meSelectors from "app/redux/me/me.selectors"
import { Modules } from "app/enums/modules"
import { usePermission } from "hooks/permission"

const NoPermission = () => {
  const { call } = useApi()
  const [selectedBuilding, setSelectedBuilding] = useState<string | undefined>()
  const navigate = useNavigate()
  const { translate } = useTranslate()
  const { logout } = useLogout()
  const rentedBuildings = useSelector(getSortedRentedBuildings)
  const hasMultipleCompanies = useSelector(meSelectors.hasMultipleCompanies)
  const firstRentedbuilding = rentedBuildings && rentedBuildings[0]
  const preferredBuilding = useSelector(getPreferredBuilding)
  const isLoading = useSelector(loading)
  const defaultBuilding = preferredBuilding ?? firstRentedbuilding
  const defaultValue = hasMultipleCompanies
    ? `${defaultBuilding?.city} - ${defaultBuilding?.street} ${defaultBuilding?.houseNo} (${defaultBuilding?.entityName})`
    : `${defaultBuilding?.city} - ${defaultBuilding?.street} ${defaultBuilding?.houseNo}`
  const { hasPermission } = usePermission()

  const handleClick = () => {
    logout()
  }

  const handleNavigation = () => {
    const id = selectedBuilding || defaultBuilding!.id
    if (!preferredBuilding) {
      call(patchPreferedBuilding(id))
    }
    if (hasPermission(Modules.DASHBOARD, id)) {
      return navigate(`/dashboard/${id}`)
    }
    return navigate(`/facility-management/${id}`)
  }

  const handleDropDownChange = (optionData: OnChangeValue<CustomOptionType, false>) => {
    const value = (optionData as CustomOptionType).value
    setSelectedBuilding(value)
  }

  const hasNoRentedBuildings = !rentedBuildings || rentedBuildings.length === 0

  const determineBaseTranslation = () => {
    if (hasNoRentedBuildings) return "noaccess"
    if (!preferredBuilding) return "nopreferredbuilding"
    return "nopermission"
  }

  const base = determineBaseTranslation()
  if (isLoading) {
    return (
      <LoadingScreen
        patienceText={translate("portal.loading.patience")}
        loadingText={translate("portal.loading.loading")}
      />
    )
  }

  return (
    <Page image={<NotFound />}>
      <Container>
        <Typography variant="h5" as="h1" fontStyle="bold" textAlign="center">
          {translate(`portal.${base}.title`)}
        </Typography>
        <StyledSpan>{translate(`portal.${base}.description`)}</StyledSpan>
        {hasNoRentedBuildings ? (
          <StyledLink
            onClick={handleClick}
            type="default"
            value={translate("portal.noaccess.logout.label")}
          />
        ) : (
          <DropDownWrapper>
            <DropDown
              hasDropdownIndicator
              defaultValue={defaultValue}
              onChange={handleDropDownChange}
              label={translate(`portal.${base}.buildingselection.label`)}
              options={rentedBuildings!.map(r => {
                const label = hasMultipleCompanies
                  ? `${r.city} - ${r.street} ${r.houseNo} (${r.entityName})`
                  : `${r.city} - ${r.street} ${r.houseNo}`
                return {
                  label,
                  value: r.id
                }
              })}
            />
            <StyledButton
              label={translate(`portal.${base}.button.label`)}
              fullWidth
              onClick={handleNavigation}
            />
          </DropDownWrapper>
        )}
      </Container>
    </Page>
  )
}

export default NoPermission
