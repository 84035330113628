import { TicketType } from "app/types/ticketCategory"
import State from "../state"

export const loading = (state: State): boolean => state.ticketCategories.loading
export const getCategories = (state: State): TicketType[] =>
  state.ticketCategories.data
export const getCategoryByType = (type: string) => (
  state: State
): TicketType | undefined => {
  const categories = getCategories(state)
  return categories.find(category => category.type === type )
}
