import { BaseDeleteModal, PopUp } from "@paudigital/wdp-components"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { fire, remove } from "app/redux/appEvents/appEvents.actions"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "app/redux/ticketWizard/ticketWizard.actions"
import { useTranslate } from "hooks/translate"
import { discardTicket } from "app/redux/ticket/ticket.actions"
import { discardTicketCommunication } from "app/redux/ticketCommunication/ticketCommunication.actions"
import { actions as documentWizardActions } from "app/redux/documentWizard/documentWizard.actions"
import { discardDocument } from "app/redux/document/document.actions"
import { ModalContentWrapper } from "../Survey/style"
import Survey from "../Survey"
import { getSurveys } from "app/redux/surveys/surveys.actions"
import { useApi } from "hooks/api"
import { useFeatureFlags } from "hooks/featureFlags"
import { getUser } from "app/redux/me/me.selectors"

type Props = {
  previousUniqueIdentifier: string
  previousEvent: PossibleAppEvents
  previousEventProps: {}
  title: string
}

const DeleteReport = ({
  previousEvent,
  previousUniqueIdentifier,
  previousEventProps,
  title
}: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const { surveysEnabled } = useFeatureFlags()
  const user = useSelector(getUser)

  const handleDelete = () => {
    dispatch(remove("close-report"))
    if (previousUniqueIdentifier === "create-ticket") {
      dispatch(remove("create-ticket"))
      dispatch(actions.setWizard(1))
      dispatch(discardTicket())
    }
    if (previousUniqueIdentifier === "report-detail") {
      dispatch(discardTicketCommunication())
    }
    if (previousUniqueIdentifier === "create-document") {
      dispatch(remove("create-document"))
      dispatch(documentWizardActions.setWizard(1))
      dispatch(discardDocument())
    }
    if (previousUniqueIdentifier === "password-updated") {
      dispatch(remove("password-updated"))
    }
    if (surveysEnabled && user && user.receiveSurveys) {
      call(getSurveys())
    }
  }

  const handleCancel = () => {
    dispatch(remove("close-report"))
    dispatch(
      fire({
        uniqueIdentifier: previousUniqueIdentifier,
        eventName: previousEvent,
        props: {
          ...previousEventProps
        }
      })
    )
    if (surveysEnabled && user && user.receiveSurveys) {
      call(getSurveys())
    }
  }

  return (
    <PopUp show disableClose>
      <ModalContentWrapper>
        <BaseDeleteModal
          title={title}
          onDelete={handleDelete}
          deleteBtnText={translate(
            "portal.facilitymanagement.createticket.delete.delete.label"
          )}
          cancelBtnText={translate(
            "portal.facilitymanagement.createticket.delete.cancel.label"
          )}
          onCancel={handleCancel}
          deleteDisabled={false}
        >
          <></>
        </BaseDeleteModal>
        {surveysEnabled && user && user.receiveSurveys && <Survey />}
      </ModalContentWrapper>
    </PopUp>
  )
}

export default DeleteReport
