import React from "react"
import { Stepper, Logo, Spinner } from "@paudigital/wdp-components"
import {
  Content,
  Container,
  LogoContainer,
  Header,
  Wrapper,
  SpinnerContainer
} from "./style"
import AccountCreation from "./Steps/AccountCreation"
import { getStep } from "app/redux/registrationWizard/registrationWizard.selectors"
import { useSelector } from "react-redux"
import CommunicationPreferences from "./Steps/CommunicationPreferences"
import BuildingPreferences from "./Steps/BuildingPreferences"
import AddColleague from "./Steps/AddColleague"
import { getNotificationsSettings } from "app/redux/notificationSettings/notificationSettings.actions"
import { getContactPersons } from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.actions"
import * as meSelector from "app/redux/me/me.selectors"
import * as notificationSelector from "app/redux/notificationSettings/notificationSettings.selectors"
import { useTranslate } from "hooks/translate"
import { useApi } from "hooks/api"
import { useNavigate } from "react-router"
import Responsibilities from "./Steps/Responsibilities"
import AppEventsProvider from "../AppEventsProvider"

const Register = () => {
  const navigate = useNavigate()
  const user = useSelector(meSelector.getUser)
  const hasBeenOnboarded = useSelector(meSelector.hasBeenOnboarded)
  const { translate } = useTranslate()
  const step = useSelector(getStep)
  const contactPersonId = useSelector(meSelector.getUserId)
  const { call } = useApi()
  const contactPersonLoading = useSelector(meSelector.loading)
  const notificationLoading = useSelector(notificationSelector.isLoading)
  const isLoading = contactPersonLoading || notificationLoading
  const rentedBuildings = useSelector(meSelector.getRentedBuildings)
  const isAdminInABuilding = useSelector(meSelector.isAdminInRentedBuilding)
  const hasSingleRentedBuilding = useSelector(
    meSelector.hasSingleRentedBuilding
  )

  React.useEffect(() => {
    if (contactPersonId) {
      call(getNotificationsSettings(contactPersonId))
    }
  }, [contactPersonId, call])

  React.useEffect(() => {
    if (rentedBuildings && rentedBuildings.length > 0) {
      const ids = rentedBuildings.map(r => r.id)
      call(getContactPersons(ids))
    }
  }, [call, rentedBuildings])

  React.useEffect(() => {
    if (hasBeenOnboarded) {
      navigate("/")
    }
  }, [navigate, hasBeenOnboarded])

  const getConfig = () => {
    let config = [
      {
        title: translate("portal.onboarding.step1.title"),
        content: <AccountCreation />
      }
    ]

    if (!hasSingleRentedBuilding) {
      config.push({
        title: translate("portal.onboarding.step2.title"),
        content: <BuildingPreferences />
      })
    }

    config.push({
      title: translate("portal.onboarding.step3.title"),
      content: <CommunicationPreferences />
    })

    config.push({
      title: translate("portal.onboarding.responsibilities.title"),
      content: <Responsibilities />
    })

    if (isAdminInABuilding) {
      config.push({
        title: translate("portal.onboarding.step4.title"),
        content: <AddColleague />
      })
    }

    return config
  }

  return (
    <Container>
      <AppEventsProvider local={false} />
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Header>
      <Wrapper>
        <Content>
          {isLoading || !user ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <Stepper
              stepText={translate("portal.onboarding.step.label")}
              activeStep={step}
              config={getConfig()}
            />
          )}
        </Content>
      </Wrapper>
    </Container>
  )
}

export default Register
