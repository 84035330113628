import { theme } from "@paudigital/wdp-components"

const basePieChartOptions = {
  title: {
    text: ''
  },
  tooltip: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  chart: {
    spacing: [0, 0, 0, 0],
    renderTo: 'chart',
    type: 'pie'
  },
  plotOptions: {
    pie: {
      states: { 
        hover: { enabled: false },
        inactive: { enabled: false } 
      },
      borderWidth: 1,
    }
  }
}

export const buildPieChartOptions = (
  electricityGridTotal: number | null,
  solarConsumptionTotal: number | null
) => {
  return {
    ...basePieChartOptions,
    series: [{
      data: [electricityGridTotal, solarConsumptionTotal],
      colors: [theme.colors.concrete.prc50, theme.colors.green.contrast],
      size: '133%',
      innerSize: '40%',
      showInLegend: false,
      dataLabels: {
        enabled: false
      }
    }]
  }
}
