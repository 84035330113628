import { useTranslate } from "hooks/translate"
import Icon from "./icon"
import {
  StyledDiv as StyledNoMetersWrapper,
  StyledNoEnergyTitle as StyledNoMetersTitle,
  StyledNoEnergyText,
  StyledNoMetersContentWrapper
} from "./style"

type Props = {
  reason: string
}

const NoEnergyReason = ({ reason }: Props) => {
  const { translate } = useTranslate()

  return (
    <StyledNoMetersWrapper>
      <StyledNoMetersContentWrapper>
        <Icon />
        <StyledNoMetersTitle>
          {translate(`portal.dashboard.widget.energy.noEnergyReason.${reason}`)}
        </StyledNoMetersTitle>
        <StyledNoEnergyText>
          {translate(`portal.dashboard.widget.energy.noEnergyReason.${reason}.description`)}
        </StyledNoEnergyText>
      </StyledNoMetersContentWrapper>
    </StyledNoMetersWrapper>
  )
}

export default NoEnergyReason
