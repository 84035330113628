import { SagaIterator } from "redux-saga"
import { put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./ticketWizard.actions"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import { getStep } from "./ticketWizard.selectors"
import * as meSelectors from "../me/me.selectors"
import { intl, Locales } from "app/i18n/config"

const mapping: Record<number, string> = {
  1: "portal.route.title.facility-management.report.category",
  2: "portal.route.title.facility-management.report.details",
  3: "portal.route.title.facility-management.report.create"
}

export function* changeWizardFlow(): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )
  try {
    const step = yield select(getStep)
    yield put(
      createGAEvent(PossibleGAEvents.PAGE_VIEW, {
        page_title: intl[preferredLanguage].formatMessage({ id: mapping[step] })
      })
    )
  } catch (e: any) {
    console.warn(e)
  }
}

export default function* ticketWizardSaga(): SagaIterator {
  yield takeLatest(
    [actions.Types.INCREASE, actions.Types.DECREASE],
    changeWizardFlow
  )
}
