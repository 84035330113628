import { Button, InputElement } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useFormik } from "formik"
import { validationSchema, AssociateValue, initialValues } from "./config"
import { getError } from "app/redux/authorization/authorization.selectors"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { QRContainer } from "./style"
import { ButtonContainer, DescriptionContainer } from "../style"
import AppEventProvider from "app/components/AppEventsProvider"
import { clearHttpError } from "app/redux/httpErrors/httpErrors.actions"
import { getBarcodeUri } from "app/redux/mfa/mfa.selectors"
import { enroll } from "app/redux/mfa/mfa.actions"
import { AssociationTypes } from "app/api/mfa"

const Associate = () => {
  const { translate } = useTranslate()
  const serverError = useSelector(getError)
  const barcodeUri = useSelector(getBarcodeUri)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearHttpError())
  }, [dispatch])

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setSubmitting,
    isValid,
    dirty
  } = useFormik({
    initialValues,
    onSubmit: ({ oneTimeCode }: AssociateValue) => {
      isValid && dispatch(enroll(AssociationTypes.OTP, oneTimeCode))
    },
    validationSchema
  })

  const hasErrors = (name: string) => {
    return errors[name as keyof AssociateValue] &&
      touched[name as keyof AssociateValue]
      ? true
      : false
  }

  useEffect(() => {
    serverError && setSubmitting(false)
  }, [serverError, setSubmitting])

  return (
    <form onSubmit={handleSubmit}>
      <AppEventProvider local />
      <DescriptionContainer>
        {translate("portal.account.account.mfa.association.description")}
      </DescriptionContainer>
      {barcodeUri && (
        <QRContainer>
          <img src={barcodeUri} alt="qrcode" />
        </QRContainer>
      )}
      <InputElement
        label={translate("portal.account.account.mfa.otp.step2.input.label")}
        name="oneTimeCode"
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasErrors("oneTimeCode")}
        errorMessage={
          hasErrors("oneTimeCode") && translate(errors.oneTimeCode!)
        }
        dataTestId="portal.account.account.mfa.otp.step2.input.label"
      />
      <ButtonContainer>
        <Button
          label={translate("portal.account.account.mfa.otp.step2.button")}
          disabled={!isValid || !dirty}
          submit
        />
      </ButtonContainer>
    </form>
  )
}

export default Associate
