import { formatISODateWithoutTimezone } from "app/utils/dateFormatter.utils"
import { sub, add, differenceInDays, startOfDay, endOfDay } from "date-fns"
import { toDate } from "date-fns-tz"

const calcDate = (op: string, start: Date, days: number) =>
  op === "sub" ? sub(start, { days }) : add(start, { days })

export const calcPeriodRange = (from: string, to: string) =>
  differenceInDays(toDate(to), toDate(from))

export const formatDate = (op: string, start: Date, days: number) =>
  !days ? start.toISOString() : formatISODateWithoutTimezone(calcDate(op, start, days))

export const getDayStart = (to: string) => startOfDay(toDate(to))
export const getDayEnd = (to: string) => endOfDay(toDate(to))
