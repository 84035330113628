import { IntervalEnum } from "app/enums/interval"
import { Co2CalculationMethods } from "app/types/energy"
import { Graphs } from "app/utils/graphs/graph.utils"
import { Periods } from "./energyPeriod.reducer"

export enum Types {
  SET_ENERGY_PERIOD = "SET_ENERGY_PERIOD",
  SET_GRAPH_INTERVAL = "SET_GRAPH_INTERVAL",
  SET_CO2_CALCULATION = "SET_CO2_CALCULATION",
  RESET_ENERGY_PERIOD = "RESET_ENERGY_PERIOD"
}

export type Action =
  | SetEnergyPeriodAction
  | SetElectricityConsumptionIntervalAction
  | SetCo2CalculationMethodAction
  | ResetEnergyPeriod

export type SetEnergyPeriodAction = {
  type: Types.SET_ENERGY_PERIOD
  payload: {
    from: string
    to: string
    period: Periods
  }
}

export type SetElectricityConsumptionIntervalAction = {
  type: Types.SET_GRAPH_INTERVAL
  payload: {
    graph: Graphs
    interval: IntervalEnum
  }
}

export type SetCo2CalculationMethodAction = {
  type: Types.SET_CO2_CALCULATION
  payload: {
    method: Co2CalculationMethods
  }
}

export type ResetEnergyPeriod = {
  type: Types.RESET_ENERGY_PERIOD
}

export function setEnergyPeriodAction(
  from: string,
  to: string,
  period: Periods
): Action {
  return {
    type: Types.SET_ENERGY_PERIOD,
    payload: {
      from,
      to,
      period
    }
  }
}

export function setGraphInterval(
  graph: Graphs,
  interval: IntervalEnum
): Action {
  return {
    type: Types.SET_GRAPH_INTERVAL,
    payload: {
      graph,
      interval
    }
  }
}

export function setCo2CalculationMethod(method: Co2CalculationMethods): Action {
  return {
    type: Types.SET_CO2_CALCULATION,
    payload: { method }
  }
}

export function resetEnergyPeriod(): Action {
  return {
    type: Types.RESET_ENERGY_PERIOD
  }
}
