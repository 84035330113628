import {
  PicturesIcon,
  ContractIcon,
  FinanceIcon,
  InsuranceIcon,
  MaintenanceIcon,
  OtherIcon,
  theme
} from "@paudigital/wdp-components"
import { DocumentTypes } from "app/enums/documentTypes"

export type IconMappingType = {
  [key: string]: JSX.Element
}

export const iconMapping: IconMappingType = {
  [DocumentTypes.Pictures]: (
    <PicturesIcon
      fill={theme.colors.neutral.darkerGrey}
      width="44px"
      height="44px"
    />
  ),
  [DocumentTypes.Contracts]: (
    <ContractIcon
      fill={theme.colors.neutral.darkerGrey}
      width="44px"
      height="44px"
    />
  ),
  [DocumentTypes.Financial]: (
    <FinanceIcon
      fill={theme.colors.neutral.darkerGrey}
      width="44px"
      height="44px"
    />
  ),
  [DocumentTypes.Insurance]: (
    <InsuranceIcon
      fill={theme.colors.neutral.darkerGrey}
      width="44px"
      height="44px"
    />
  ),
  [DocumentTypes.Maintenance_inspection_reports]: (
    <MaintenanceIcon
      fill={theme.colors.neutral.darkerGrey}
      width="44px"
      height="44px"
    />
  ),
  [DocumentTypes.Other]: (
    <OtherIcon
      fill={theme.colors.neutral.darkerGrey}
      width="44px"
      height="44px"
    />
  )
}
