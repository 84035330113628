import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  background: ${theme.colors.neutral.white};
  border-radius: 4px;
  padding: 3.6rem 2.4rem;

  @media only screen and (${theme.breakpoints.desktop}) {
    padding: 4.4rem;
  }
`

export const ChildrenContainer = styled.div`
  * + & {
    margin-top: 2.4rem;
  }
`
