import { SlideIn, Typography } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { Container, TypographyWrapper } from "./style"
import { useDispatch } from "react-redux"
import { fire, remove } from "app/redux/appEvents/appEvents.actions"
import { authenticate, authenticateReset } from "app/redux/authentication/authentication.actions"
import VerifyIdentity from "app/components/VerifyIdentity"
import { Audiences, Scopes } from "app/api/auth0"
import { Action as ReduxAction } from "redux"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"

const MfaOtpDeletePopUp = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(remove("mfa-delete-otp-start"))
    dispatch(authenticateReset())
  }

  const handleOnSubmitVerification = (
    currentPassword: string,
    actionOnSuccess: () => ReduxAction<any>
  ) => {
    dispatch(
      authenticate({
        currentPassword,
        audience: Audiences.Mfa,
        scope: Scopes.Delete,
        actionOnSuccess
      })
    )
  }

  const handleOnSuccessAction = () => {
    dispatch(remove("mfa-delete-otp-start"))
    return fire({
      uniqueIdentifier: "mfa-delete",
      eventName: PossibleAppEvents.MFA_DELETE_OTP
    })
  }

  return (
    <SlideIn showCloseIcon show onClose={handleClose}>
      <>
        <Container>
          <TypographyWrapper>
            <Typography variant="h4" as="h1" fontStyle="bold">
              {translate("portal.authentication.title")}
            </Typography>
          </TypographyWrapper>
          <VerifyIdentity
            actionOnSuccess={handleOnSuccessAction}
            description={translate("portal.authentication.description")}
            onSubmit={handleOnSubmitVerification}
          />
        </Container>
      </>
    </SlideIn>
  )
}

export default MfaOtpDeletePopUp
