import routes, { NOT_FOUND_PATH } from "../routes"
import { Route, Routes, useMatch, matchPath } from "react-router-dom"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { ComponentType } from "react"
import { sec } from "app/utils/security"
import { Modules } from "app/enums/modules"
import Layout from "app/components/Layout"
import { useTranslate } from "hooks/translate"
import React from "react"

export type RouteObject = {
  path: string
  component: ComponentType
  module?: Modules
  pullContent?: boolean
  hasAuthentication?: boolean
  pageheader?: JSX.Element
  title?: string
}

function withLayout(
  Component: ComponentType,
  module?: Modules,
  pageheader?: JSX.Element,
  pullContent?: boolean
): ComponentType {
  if (module)
    return () => (
      <Layout module={module} pullContent={pullContent} pageheader={pageheader}>
        <Component />
      </Layout>
    )
  return Component
}

function withAuthentication(
  Component: ComponentType,
  hasAuthentication?: boolean
): ComponentType {
  if (!hasAuthentication) return Component
  const WrappedComponent = () => <Component />
  return withAuthenticationRequired(WrappedComponent)
}

function CreateRoute({
  path,
  component,
  module,
  hasAuthentication,
  title,
  pageheader,
  pullContent
}: RouteObject) {
  const { translate, hasTranslations } = useTranslate()
  const hasTitleTranslation = title && hasTranslations(title)
  const isMatch = useMatch(path)
  const pathName = window.location.pathname
  const isNotFound = routes.reduce((acc, curr) => {
    return matchPath(curr.path, pathName) && curr.path !== NOT_FOUND_PATH
      ? false
      : acc
  }, true)

  React.useEffect(() => {
    if (isMatch && path !== NOT_FOUND_PATH) {
      document.title = hasTitleTranslation
        ? translate(title!)
        : translate("portal.route.title.root")
    }
    if (isNotFound) document.title = translate("portal.route.title.not-found")
  }, [title, translate, hasTitleTranslation, isMatch, path, isNotFound])

  const WithLayout = withLayout(component, module, pageheader, pullContent)
  const WithAuthentication = withAuthentication(WithLayout, hasAuthentication)
  return <Route path={path} key={path} element={<WithAuthentication />} />
}

const Router = (): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0()
  sec.setAccessTokenSilently(getAccessTokenSilently)

  return (
    <Routes>
      {routes.map((routeObject: RouteObject) => CreateRoute(routeObject))}
    </Routes>
  )
}

export default Router
