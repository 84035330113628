import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Item = styled.button<{ isBackButton: boolean; bold: boolean }>`
  cursor: pointer;
  width: 100%;
  background: ${theme.colors.neutral.lightGrey};
  height: 4.7rem;
  text-align: left;
  padding: 0 1.8rem;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.captionExtraSmall};
  display: flex;
  justify-content: ${props =>
    props.isBackButton ? "flex-end" : "space-between"};
  flex-direction: ${props => props.isBackButton && "row-reverse"};
  align-items: center;
  border: 0;
  border-bottom: 1px solid ${theme.colors.neutral.midGrey};
  font-weight: ${props =>
    props.isBackButton || props.bold ? theme.fontWeights.black : "initial"};

  &:last-of-type {
    border: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  ~ button:nth-of-type(2) {
    ${props =>
      props.isBackButton &&
      " border-top-left-radius: 4px; border-top-right-radius: 4px;"}
  }

  ${props => props.isBackButton && "border-radius: 4px; margin-bottom: 0.8rem;"}
`
