import { EnergyUsageComparisonData } from "app/types/energy"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.energyUsageComparison.loading
export const hasErrors = (state: State): boolean =>
  state.energyUsageComparison.errors ? true : false

export const getErrorsStatus = (state: State): number | undefined =>
  state.energyUsageComparison.errors?.status

export const getEnergyUsageComparisonData = (
  state: State
): EnergyUsageComparisonData[] => {
  return state.energyUsageComparison.data
}
