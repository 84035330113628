import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ExpertWrapper = styled.div`
  margin-top: 1.8rem;
  border-radius: 4px;
  background: ${theme.colors.neutral.lightGrey};
  padding: 2.8rem;
`

export const Expert = styled.div`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  text-align: center;
  margin-bottom: 2.4rem;
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
`
