import { Action, Types } from "./authorization.actions"

export type AuthorizationState = {
  username: string
  password: string
  error: boolean
}

const initialState: AuthorizationState = {
  username: "",
  password: "",
  error: false
}

export default (state = initialState, action: Action): AuthorizationState => {
  switch (action.type) {
    case Types.UPDATE_PASSWORD:
      return {
        ...state,
        ...action.payload,
        error: false
      }
    case Types.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        error: true
      }
    case Types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: false
      }

    default:
      return state
  }
}
