import { SlideIn } from "@paudigital/wdp-components"
import { ProfileActions } from "app/enums/profile"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { useDispatch } from "react-redux"
import EditProfile from "./EditProfile"
import * as responsibilityActions from "app/redux/responsibilities/responsibilities.actions"

const EditProfileDetailsPopUp = () => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(responsibilityActions.clearResponsibilities())
    dispatch(remove(ProfileActions.PROFILE_EDIT))
  }

  return (
    <SlideIn showCloseIcon show onClose={handleClose}>
      <EditProfile />
    </SlideIn>
  )
}

export default EditProfileDetailsPopUp
