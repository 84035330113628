import { Response } from "app/redux/notifications/notifications.reducer"
import { AxiosPromise } from "axios"
import { notificationsApi } from "./api"

export const getNotifications = (
  rentedBuildingId: string,
  page?: number
): AxiosPromise<Response> => {
  return notificationsApi(
    `/notifications/${rentedBuildingId}?perPage=20&page=${page || 1}`
  )
}

export const getNotificationCount = (): AxiosPromise<Response> => {
  return notificationsApi("/notifications")
}

export const patchNotifications = (rentedBuildingId: string): AxiosPromise => {
  return notificationsApi(`/notifications/${rentedBuildingId}/last_visited`, {
    method: "PATCH"
  })
}
