import { BaseCustomSelectOption } from "@paudigital/wdp-components"
import { format, toDate } from "date-fns-tz"
import { Option } from "./style"
import { useTranslate } from "hooks/translate"

const PeriodOption = (option: string, from: string, to: string) => {
  const { translate } = useTranslate()

  return {
    option: (
      <BaseCustomSelectOption>
        <Option>{translate(`portal.energy.period.${option}`)}</Option>
      </BaseCustomSelectOption>
    ),
    selectedOption: (
      <BaseCustomSelectOption>
        <Option selected>
          <strong>{translate(`portal.energy.period.${option}`)}</strong>
          <div>
            {translate("portal.energy.period.range", {
              from: format(toDate(from), "dd-MM-yyyy"),
              to: format(toDate(to), "dd-MM-yyyy")
            })}
          </div>
        </Option>
      </BaseCustomSelectOption>
    ),
    value: option
  }
}

export default PeriodOption
