import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"
import { Link } from "react-router-dom"

export const NavigationContainer = styled.div<{
  isSticky: boolean
}>`
  position: sticky;
  background: ${theme.colors.neutral.white};
  box-shadow: ${theme.effects.menuShadow};
  top: 0;
  z-index: 2;

  @media only screen and (${theme.breakpoints.desktop}) {
    position: ${props => !props.isSticky && "sticky"};
  }
`

export const NavigationWrapper = styled.div<{
  isActive: boolean
}>`
  position: relative;
  margin: 0 auto;
  background: ${theme.colors.neutral.midGrey};
  height: ${props => (props.isActive ? "100vh" : "auto")};
  width: ${props => (props.isActive ? "100vw" : "auto")};

  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: 768px;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    max-width: 1152px;

    &:after {
      content: "";
      display: none;
    }
  }
`

export const StyledNavigation = styled.nav<{
  active?: boolean
  multiRow: boolean
}>`
  z-index: 1000;
  overflow: auto;
  position: fixed;
  right: 0;
  bottom: ${props => props.active && "0"};
  left: 0;
  top: 0;
  display: none;
  display: grid;
  grid-template-rows: ${props =>
    props.active ? "7rem 12rem auto 12rem 1fr" : "7rem"};
  grid-template-columns: ${props =>
    props.active ? "10rem 1fr" : "10rem minmax(0px, 1fr) max-content"};
  gap: 1px 0;
  grid-template-areas: ${props =>
    props.active
      ? `
      "logo messages"\n
      "address address"\n
      "menu-items menu-items"\n
      "settings-menu settings-menu"\n
      "logout logout"
    `
      : `"logo address messages"`};

  & > * {
    align-items: center;
    background: ${theme.colors.neutral.white};
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 768px;
    background: none;
    overflow: revert;
    position: relative;
    grid-template-columns: 9.8rem max-content minmax(1rem, auto) minmax(
        8.8rem,
        auto
      );
    grid-template-columns: 9.8rem minmax(0, 1fr) auto minmax(
        8.8rem,
        max-content
      );
    grid-template-rows: 7.6rem 8rem;
    grid-template-areas:
      "logo address messages logout"
      "menu-items menu-items menu-items menu-items";
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    overflow: revert;
    width: 1152px;
    gap: ${props => (props.multiRow ? "1px" : 0)} 0;
    grid-template-columns: 15.6rem 1fr minmax(min-content, 12rem) minmax(
        8.8rem,
        auto
      );
    grid-template-rows: 7.6rem;
    grid-template-areas:
      ${props => props.multiRow && '"logo address messages logout"'}
      "logo menu-items menu-items menu-items";
  }
`

export const NotificationsWrapper = styled.div<{
  isVisible: boolean
  active: boolean
}>`
  grid-area: messages;
  font-size: ${theme.fontSizes.captionExtraSmall};
  display: flex;
  justify-content: flex-end;
  margin: 0;

  button {
    margin-left: 0.8rem;

    &:last-of-type {
      margin-right: 2.4rem;
    }
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    gap: 5.4rem;
    margin: 0;
    & > div:last-of-type {
      margin-right: 5.4rem;
    }
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    display: ${props => (props.isVisible ? "flex" : "none")};
    align-items: center;
    justify-content: space-between;
  }
`

export const NotificationWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  &:focus {
    ${theme.outline}
  }
`

export const StyledNotifications = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.neutral.darkerGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NotificationsLabel = styled.label`
  margin-left: 1.5rem;
  cursor: pointer;
`

export const NavigationMenuItems = styled.ul`
  display: block;
  grid-area: menu-items;

  @media only screen and (${theme.breakpoints.tablet}) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0;
  }
`

export const MenuItem = styled.li<{ active?: boolean }>`
  border-left: 4px solid
    ${props => (props.active ? theme.colors.orange.prc100 : "transparent")};
  height: 5rem;
  display: flex;
  align-items: center;
  font-weight: ${props => props.active && theme.fontWeights.bold};
  text-decoration: none;

  @media only screen and (${theme.breakpoints.tablet}) {
    line-height: 1.6rem;
    margin: 3rem 0;
    height: 2rem;
    border: 0;
    position: relative;
    min-width: 14%;
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;

    &:after {
      background: ${theme.colors.orange.prc100};
      content: "";
      position: absolute;
      bottom: -3rem;
      border: 2px solid ${theme.colors.orange.prc100};
      border-radius: 5rem;
      width: 100%;
      display: ${props => (props.active ? "block" : "none")};
    }
  }
`

export const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.neutral.darkerGrey};
  height: 100%;
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  align-items: center;
  width: 100%;

  &:focus {
    ${theme.outline}
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0;
    text-align: center;
  }
`

export const LogoutButtonWrapper = styled.div`
  grid-area: logout;
  border: 0;
  margin-top: -1px; /* grid gap fix */

  & > div {
    margin: 0 2.4rem 2.4rem;
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div {
      margin: 0;
    }
  }
`

export const SettingMenuItems = styled.ul`
  grid-area: settings-menu;

  & li:last-of-type {
    height: 4rem;
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0.6rem 0;
    display: none;
  }
`

export const StyledContact = styled.div`
  margin-left: 1.6rem;
`

export const TextWrapper = styled.span`
  display: block;
  margin: 0.8rem 1.6rem;
  min-width: max-content;
`

export const LogoWrapper = styled(Link)<{ active: string }>`
  grid-area: logo;

  & svg {
    transform: scale(0.8);
    margin: 1.4rem;
    margin-left: 2.4rem;

    height: 43px;
    width: 66px;
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    height: auto;
    display: flex;
    align-items: center;

    & svg {
      height: 129px;
      width: 132px;
      margin: 0;
    }
  }
`

export const NotificationIndicator = styled.div`
  position: absolute;
  margin-bottom: 0.8rem;
  margin-left: 1.1rem;
  height: 0.6rem;
  width: 0.6rem;
  border: 1px solid ${theme.colors.neutral.white};
  border-radius: 50%;
  background: ${theme.colors.system.error};
`

export const Buffer = styled.div`
  background: ${theme.colors.neutral.white};
`
