import { useTranslate } from "hooks/translate"
import NoValue from "../../NoValue"
import { TitleComparisonContainer } from "../style"
import { StyledArrow, StyledSubText } from "../../style"
import { compareBaseText, createArrow } from "../../utils/energyHelper.utils"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"

export type TitleComparisonProps = {
  totalDifference?: number
  measuringUnit: string
  percentageDifference?: number
  numberOfDays: number
}

type Props = TitleComparisonProps

const TitleComparison = ({
  totalDifference,
  measuringUnit,
  percentageDifference,
  numberOfDays
}: Props) => {
  const { translate } = useTranslate()
  const { integerFormatter } = getLocaleNumberFormatter()

  const subText =
    numberOfDays === 1
      ? translate(`${compareBaseText}day`)
      : translate(`${compareBaseText}days`, {
          amount: numberOfDays
        })

  return (
    <TitleComparisonContainer>
      {totalDifference && totalDifference !== 0 && (
        <StyledArrow diff={totalDifference}>
          {createArrow(totalDifference)}
        </StyledArrow>
      )}
      <StyledSubText uppercased fontWeight="bold" diff={totalDifference}>
        {integerFormatter(totalDifference!) || <NoValue />}
        &nbsp;{measuringUnit}
        &nbsp;
        {percentageDifference && <>({percentageDifference}%)</>}
      </StyledSubText>
      <StyledSubText fontWeight="regular">{subText}</StyledSubText>
    </TitleComparisonContainer>
  )
}

export default TitleComparison
