import { BaseCard, CardContent, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)`
  width: 100%;
`

export const StyledCardContent = styled(CardContent)`
  flex-direction: row;
  justify-content: space-between;
`

export const ContentContainer = styled.div`
  padding: 1rem 2.4rem;
`
export const TitleContainer = styled.div`
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.bold};
  font-family: inherit;
  line-height: ${theme.lineHeights.captionChartNumber};
  color: ${theme.colors.neutral.darkerGrey};
`

export const DescriptionContainer = styled.div`
  font-size: ${theme.fontSizes.captionExtraSmall};
  font-weight: ${theme.fontWeights.light};
  font-family: inherit;
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
`

export const RecommendedContainer = styled.div`
  font-size: ${theme.fontSizes.captionExtraSmall};
  font-weight: ${theme.fontWeights.light};
  font-family: inherit;
  line-height: ${theme.lineHeights.paragraph};
  color: ${theme.colors.oceanGreen.prc125};
`

export const ButtonContainer = styled.div`
  margin: 1rem;
`

export const StateContainer = styled.div`
  margin-right: 2rem;
  margin-left: 2.5rem;
  flex-wrap: nowrap;
  display: flex;
  align-content: center;
  align-items: center;

  span {
    color: ${theme.colors.green.contrast};
    font-weight: ${theme.fontWeights.bold};
    margin-left: 1rem;
  }
`

export const IconContainer = styled.div`
  display: inline-flex;
  align-self: center;
`

export const StyledIcon = styled.button<{ active: boolean }>`
  height: 4.8rem;
  border-radius: 50%;
  width: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.neutral.white};
  box-shadow: ${theme.effects.floatingShadow};
  cursor: pointer;
  border: none;

  &:focus {
    ${theme.outline}
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    box-shadow: none;
    background: ${props => !props.active && theme.colors.neutral.lightGrey};
  }
`
