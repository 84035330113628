import { RentedBuildingWithContacRoles } from "app/types/rentedBuildingWithContacRoles"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.contactPersonContactRoles.loading

export const hasErrors = (state: State): boolean =>
  state.contactPersonContactRoles.errors ? true : false

export const getContactPersonContactRoles = (
  state: State
): RentedBuildingWithContacRoles[] => state.contactPersonContactRoles.data
