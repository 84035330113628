export enum DocumentTypes {
  Maintenance_inspection_reports = "01",
  Insurance = "02",
  Financial = "03",
  Index_letter = "03_01",
  Contracts = "04",
  Contracts_appendixes = "04_03",
  Plans = "04_03_01",
  Pictures = "05",
  Other = "06",
  Pv_Projects = "07"
}
