import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.actions"
import * as selectors from "app/redux/rentedBuildingContactPerson/rentedBuildingContactPerson.selectors"
import * as meSelector from "app/redux/me/me.selectors"
import ContactPersonCard from "../ContactPersonCard"
import { getRentedBuildings } from "app/redux/me/me.selectors"
import { InputLabel } from "@paudigital/wdp-components"
import { usePermission } from "hooks/permission"
import { Modules } from "app/enums/modules"
import {
  BuildingWrapper,
  InputLabelContainer,
  StyledCustomerNameTag
} from "./style"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { useApi } from "hooks/api"

const AddColleagues = () => {
  const dispatch = useDispatch()
  const { hasPermission } = usePermission()
  const { call } = useApi()
  const hasSingleRentedBuilding = useSelector(
    meSelector.hasSingleRentedBuilding
  )
  const hasMultipleCompanies = useSelector(meSelector.hasMultipleCompanies)
  const userId = useSelector(meSelector.getUserId)
  const contactPersons = useSelector(selectors.getContactPersons)
  const rentedBuildings = useSelector(getRentedBuildings)

  React.useEffect(() => {
    const ids = rentedBuildings?.map(r => r.id)
    if (ids) {
      call(actions.getContactPersons(ids))
    }
  }, [call, rentedBuildings])

  const handleOpen = (id?: string) => {
    dispatch(
      appEventActions.fire({
        eventName: PossibleAppEvents.ADD_COLLEAGUE,
        uniqueIdentifier: "add-colleague",
        props: {
          id
        }
      })
    )
  }

  const handleDelete = (id: string) => {
    dispatch(
      appEventActions.fire({
        eventName: PossibleAppEvents.DELETE_CONTACT_PERSON,
        uniqueIdentifier: "delete-contact-person",
        props: {
          contactPersonIdToDelete: id
        }
      })
    )
  }

  return (
    <>
      {contactPersons?.map(c => {
        const { id, data } = c
        const contactPersons = data.filter(cont => cont.id !== userId)
        const rentedBuilding = rentedBuildings?.find(r => r.id === id)
        if (
          rentedBuilding &&
          hasPermission(Modules.USER_MANAGEMENT, rentedBuilding.id)
        ) {
          return (
            <BuildingWrapper key={id}>
              {!hasSingleRentedBuilding && (
                <InputLabelContainer>
                  <InputLabel
                    fullWidth={false}
                    label={`${rentedBuilding?.street} ${rentedBuilding?.houseNo}, ${rentedBuilding?.city}`}
                  />
                  {hasMultipleCompanies && (
                    <StyledCustomerNameTag name={rentedBuilding.entityName} />
                  )}
                </InputLabelContainer>
              )}
              {[...Array(rentedBuilding.maxSeatCount - 1)].map((_, i) => (
                <ContactPersonCard
                  key={`${i}-${contactPersons[i]?.id || "empty"}`}
                  contactPerson={contactPersons[i]}
                  onAddContactPerson={handleOpen}
                  onEditContactPerson={handleOpen}
                  onDeleteContactPerson={handleDelete}
                  dataTestId={`contact.person.card.${rentedBuilding.entityName.toLowerCase()}.${i}`}
                />
              ))}
            </BuildingWrapper>
          )
        }
        return null
      })}
    </>
  )
}

export default AddColleagues
