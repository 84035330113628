import querystring from "query-string"
import { useMemo, useEffect, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"

export const useQueryParams = <T>(initialQueryParams?: T) => {
  const navigate = useNavigate()
  const { search } = useLocation()

  const memoizedInitialQueryParams: T = useMemo(() => {
    return initialQueryParams
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialQueryParams)]) as T

  const queryParams = useMemo(() => {
    const parsed = querystring.parse(search)
    if (Object.keys(parsed).length !== 0) {
      const paramsFromUrl = querystring.parse(search, {
        arrayFormat: "bracket"
      })
      if (memoizedInitialQueryParams) {
        return { ...memoizedInitialQueryParams, ...paramsFromUrl }
      }
      return paramsFromUrl
    }
    return memoizedInitialQueryParams
  }, [search, memoizedInitialQueryParams])

  const stringifiedQueryParams = useMemo(() => {
    return querystring.stringify(queryParams, {
      arrayFormat: "bracket",
      skipNull: true,
      encode: false
    })
  }, [queryParams])

  const setUrlParams = useCallback(
    (params: Record<string, unknown> | T) => {
      const query = querystring.stringify(params, {
        arrayFormat: "bracket",
        skipNull: true,
        encode: false
      })
      return navigate({ search: query })
    },
    [navigate]
  )

  useEffect(() => {
    const params = `?${stringifiedQueryParams}`
    if (search === "" && params !== "?") {
      return navigate(
        { search: `${stringifiedQueryParams}` },
        { replace: true }
      )
    }
    if (search && search !== params) {
      return navigate({ search: stringifiedQueryParams }, { replace: true })
    }
  }, [search, navigate, stringifiedQueryParams])

  return {
    queryParams,
    setUrlParams,
    memoizedInitialQueryParams: initialQueryParams
  }
}
