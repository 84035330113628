import { ContactCard, PasswordCard } from "@paudigital/wdp-components"
import styled from "styled-components"

export const AccountSection = styled.section`
  margin-bottom: 1.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`
export const StyledContactCard = styled(ContactCard)`
  width: 100%;
  margin: 0;
`
export const StyledPasswordCard = styled(PasswordCard)`
  width: 100%;
`
