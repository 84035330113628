export type PostTicket = {
  buildingId: string
  description: string
  communicationCustomer?: string
  problem?: string
  customerContact?: boolean
  priority: boolean
  explanationPriority?: string
  type: string
  answerCodes: AnswerCode[]
  files?: File[]
}

export type Ticket = {
  ticketId: string
  sourceId?: string
  entityId: string
  personId?: string
  reportedBy?: string
  uploadedBy: string
  buildingId: string
  workOrders: WorkOrder[]
  answerCodes: AnswerCode[]
  communicationCustomer?: string
  communicationLessor?: string
  customerContact?: boolean
  description: string
  executionDate: Date
  explanationPriority?: string
  priority: boolean
  problem?: string
  type: TicketTypes
  creationDate: Date
  modificationDate: Date
  uploadAttachmentYN: AttachmentYN
  attachmentCount: number
  status: FacilityTicketStatus
  allowFeedback: boolean
  suppliers: string[]
}

export type AnswerCode = {
  id: string
  value: string | number
}

export enum AnswerCodeIds {
  WDP_DAK = "_WDP_DAK",
  WDP_POORT_ETC = "_WDP_POORT_ETC",
  WDP_POORT_ETC2 = "_WDP_POORT_ETC2",
  WDP_BRAND = "_WDP_BRAND",
  WDP_ELEKTRICITEIT = "_WDP_ELEKTRICITEIT",
  WDP_ELEKTRICITEIT2 = "_WDP_ELEKTRICITEIT2",
  WDP_HVAC = "_WDP_HVAC",
  WDP_SANITAIR_01 = "_WDP_SANITAIR_01",
  WDP_SANITAIR_02 = "_WDP_SANITAIR_02",
  WDP_BUITENTERREIN = "_WDP_BUITENTERREIN",
  WDP_GEVELS = "_WDP_GEVELS",
  WDP_VLOEREN = "_WDP_VLOEREN"
}

export type TicketsForRentedBuilding = {
  total: number
  facilityTickets: Ticket[]
  filters: FacilityTicketFilters
  ticketStatistics: TicketStatistics
}

export type FacilityTicketFilters = {
  page: number
  perPage: number
  pages: number
  ticket?: string
  description?: string
  status?: FacilityTicketStatus
  type?: TicketTypes
  from?: string
  to?: string
}

export type WorkOrder = {
  id: string
  sourceId?: string
  ticketId: string
  description: string
  inventoryElement?: string
  orderGroup: string
  poNr?: string
  readyDate: Date
  status: string
  supplier?: string
  attachmentCount: number
  creationDate: Date
  modificationDate: Date
  deletedOn?: Date
  plannedOn?: string
  technicallyCompletedOn?: string
  administrativelyCompletedOn?: string
}

export enum WorkOrderStatusEnum {
  WO10 = "WO10",
  WO61 = "WO61",
  WO32 = "WO32",
  WO42 = "WO42",
  WO45 = "WO45",
  WO50 = "WO50"
}

export enum FacilityTicketStatus {
  AWAITING_PROCESSING = "awaitingProcessing",
  INFO_NEEDED = "infoNeeded",
  PROCESSING = "processing",
  PLANNED = "planned",
  FINISHED = "finished",
  CANCELLED = "cancelled",
  CLOSED = "closed"
}

export enum TicketTypes {
  WDP_01 = "_WDP_01",
  WDP_02 = "_WDP_02",
  WDP_03 = "_WDP_03",
  WDP_04 = "_WDP_04",
  WDP_05 = "_WDP_05",
  WDP_06 = "_WDP_06",
  WDP_07 = "_WDP_07",
  WDP_08 = "_WDP_08",
  WDP_09 = "_WDP_09",
  WDP_10 = "_WDP_10",
  WDP_11 = "_WDP_11",
  WDP_12 = "_WDP_12"
}

export enum AttachmentYN {
  YES = "Y",
  NO = "N"
}

export type TicketStatistics = {
  countPerStatus?: { [key in FacilityTicketStatus]: number }
  countPerType?: { [key in TicketTypes]: number }
}

export type GetTicketCSVParams = {
  type: string[]
  uploadDateFrom: string | undefined
  uploadDateTo: string | undefined
}
