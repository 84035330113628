import styled from "styled-components"
import { Button, theme } from "@paudigital/wdp-components"

export const Container = styled.div`
  margin: 3rem 2rem;
`

export const BuildingWrapper = styled.div`
  margin: 1.6rem 0;
`

export const DropDownWrapper = styled.div`
  margin-bottom: 1.6rem;
`

export const StyledFormWrapper = styled.div`
  @media only screen and (${theme.breakpoints.tablet}),
    only screen and (${theme.breakpoints.lowHeight}) {
    margin-bottom: 3.2rem;
  }
`

export const ModalTitle = styled.h4`
  font-size: ${theme.fontSizes.h4};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2.4rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
`

export const StyledButton = styled(Button)`
  width: 100%;

  &:first-of-type {
    margin-right: 1.6rem;
  }
`
