import { theme } from "@paudigital/wdp-components"
import TicketStatus from "app/components/FacilityManagement/TicketStatus"
import styled from "styled-components"

export const StyledNotificationWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2.2rem minmax(0px, 1fr);
  grid-template-rows: 1.4rem 1fr auto;
  grid-template-areas:
    "line ."
    "line timestamp"
    "line content";

  @media only screen and (${theme.breakpoints.tablet}) {
    grid-template-columns: 4.4rem 2.6rem auto;
    grid-template-rows: 4.4rem min-content;
    grid-template-areas:
      "timestamp line content"
      ". line content";
  }
`

export const TimeStamp = styled.div`
  grid-area: timestamp;
  align-self: end;
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.caption};
`

export const Content = styled.div`
  grid-area: content;
  margin: 4px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background: ${theme.colors.neutral.white};
  border: 1px solid ${theme.colors.neutral.midGrey};
  border-radius: 4px;
  margin-bottom: 2rem;
`

export const NotificationType = styled.h3`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.regular};
  margin-bottom: 1rem;
`

export const StyledStatusWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 0.5rem 2.5rem 0.5rem 0.6rem;
  background-color: ${theme.colors.neutral.lightGrey};
  margin: 0 0 1rem 1rem;

  > span {
    background-color: transparent;
  }
`

export const TitleContainer = styled.div`
  margin: 2.4rem 2.4rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const StyledTicketStatus = styled(TicketStatus)`
  font-weight: bold;
  font-size: ${theme.fontSizes.paragraph};
`
