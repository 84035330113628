import { Graphs } from "app/utils/graphs/graph.utils"

export enum Types {
  CREATE = "CREATE_GA_EVENT",
  CREATE_SUCCESS = "CREATE_GA_EVENT_SUCCESS",
  CREATE_FAIL = "CREATE_GA_EVENT_FAIL"
}

export enum PossibleGAEvents {
  SIGN_UP = "sign_up",
  DELETE_ACCOUNT = "delete_account",
  CREATE_ACCOUNT = "create_account",
  DOWNLOAD_DOCUMENT = "download_document",
  DOWNLOAD_INVOICE = "download_invoice",
  DOWNLOAD_OVERVIEW = "download_overview",
  PAGE_VIEW = "page_view",
  CONTACT = "Contact",
  ENERGY_ISSUE = "Report_issue",
  TICKET_STEP1 = "Ticket_step1",
  TICKET_STEP2 = "Ticket_step2",
  TICKET_STEP3 = "Ticket_step3",
  NEW_TICKET = "New_ticket",
  TICKET_DETAIL = "Ticket_detail",
  DOCUMENT_STEP1 = "Upload_step1",
  DOCUMENT_STEP2 = "Upload_step2",
  DOCUMENT_UPLOAD = "Document_upload",
  TIMEPERIOD = "timeperiod"
}

export enum ExpertPopupButtonPosition {
  TOP = "Contact_Top",
  MIDDLE = "Contact_Middle",
  BOTTOM = "Contact_Bottom"
}

export type ConfigParams = { page_location?: string; page_title?: string }

export type EventParams = {
  invoice_type?: string
  onboarding_status?: string
  Modal?: ExpertPopupButtonPosition | Graphs
  document_type?: string
  period?: string
}

type Params = ConfigParams | EventParams

export type Payload = {
  eventName: PossibleGAEvents
  params?: Params
}

export type Action =
  | CreateGAEventAction
  | CreateGAEventActionSuccessAction
  | CreateGAEventActionFailAction

export type CreateGAEventAction = {
  type: Types.CREATE
  payload: Payload
}

export type CreateGAEventActionSuccessAction = {
  type: Types.CREATE_SUCCESS
  payload: Payload
}

export type CreateGAEventActionFailAction = {
  type: Types.CREATE_FAIL
  payload: {
    errors: Record<string, unknown>
  }
}

export function createGAEvent(
  eventName: PossibleGAEvents,
  params?: Params
): Action {
  return {
    type: Types.CREATE,
    payload: {
      eventName,
      params
    }
  }
}

export function createGAEventSuccess(payload: Payload): Action {
  return {
    type: Types.CREATE_SUCCESS,
    payload
  }
}

export function createGAEventFail(errors: Record<string, unknown>): Action {
  return {
    type: Types.CREATE_FAIL,
    payload: {
      errors
    }
  }
}
