import { Data } from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import * as meSelectors from "app/redux/me/me.selectors"
import MultiBuildingNotificationSettings from "./MultiBuildingNotificationSettings"
import SingleBuildingNotificationSettings from "./SingleBuildingNotificationSettings"

type NotificationSettingProps = {
  rentedBuildingId: string
  settingsData: Data
  collapsed: boolean
}

type Props = NotificationSettingProps

const NotificationSetting = ({
  rentedBuildingId,
  settingsData,
  collapsed = false
}: Props) => {
  const hasSingleRentedBuilding = useSelector(meSelectors.hasSingleRentedBuilding)

  return hasSingleRentedBuilding
    ? <SingleBuildingNotificationSettings 
        rentedBuildingId={rentedBuildingId}
        settingsData={settingsData}
      />
    : <MultiBuildingNotificationSettings
        rentedBuildingId={rentedBuildingId}
        settingsData={settingsData}
        collapsed={collapsed}
      />
}

export default NotificationSetting
