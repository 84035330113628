import { Survey, SurveyAnswer } from "app/types/survey"

export enum Types {
  GET = "SURVEYS_GET",
  GET_SUCCESS = "SURVEYS_GET_SUCCESS",
  GET_FAIL = "SURVEYS_GET_FAIL",
  SET = "SURVEY_SET",
  SET_SUCCESS = "SURVEY_SET_SUCCESS",
  SET_FAIL = "SURVEY_SET_FAIL"
}

export type Actions =
  | GetSurveysAction
  | GetSurveysSuccessAction
  | GetSurveysFailedAction
  | SetSurveyAction
  | SetSurveySuccessAction
  | SetSurveyFailedAction

export type SurveyRecord = Record<Survey["target"], Survey>

export type GetSurveysAction = {
  type: Types.GET
}

export type GetSurveysSuccessAction = {
  payload: { data: SurveyRecord }
  type: Types.GET_SUCCESS
}

export type GetSurveysFailedAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type SetSurveyAction = {
  payload: { data: SurveyAnswer }
  type: Types.SET
}

export type SetSurveySuccessAction = {
  type: Types.SET_SUCCESS
}

export type SetSurveyFailedAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.SET_FAIL
}

export function getSurveys(): GetSurveysAction {
  return {
    type: Types.GET
  }
}

export function getSurveysSuccess(
  surveys: SurveyRecord
): GetSurveysSuccessAction {
  return {
    payload: { data: surveys },
    type: Types.GET_SUCCESS
  }
}

export function getSurveysFailed(
  errors: Record<string, unknown>
): GetSurveysFailedAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function setSurvey(answer: SurveyAnswer): SetSurveyAction {
  return {
    payload: { data: answer },
    type: Types.SET
  }
}

export function setSurveySuccess(): SetSurveySuccessAction {
  return {
    type: Types.SET_SUCCESS
  }
}

export function setSurveyFailed(
  errors: Record<string, unknown>
): SetSurveyFailedAction {
  return {
    payload: { errors },
    type: Types.SET_FAIL
  }
}
