import styled from "styled-components"

export const FiltersWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 2rem 3rem 2rem;
`

export const CategoryTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
