import { EmptyContainer } from "./style"

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

const EmptyWidgetContainer = ({ children }: Props) => {
  return <EmptyContainer>{children}</EmptyContainer>
}

export default EmptyWidgetContainer
