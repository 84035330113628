import { getElectricityMeterHistoryForRentedBuilding } from "app/api/energy"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { detectGapsInMeterHistoryData } from "app/utils/detectGapsInMeterHistoryData.utils"
import { getUTCIsoDate } from "app/utils/timeZone.utils"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as appEventActions from "../appEvents/appEvents.actions"
import * as actions from "./electricityMeterHistory.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* electricityMeterHistoryFlow(
  action: actions.GetMeterHistoryAction
): SagaIterator {
  try {
    const { rentedBuilding, params } = action.payload
    params.from = getUTCIsoDate(params.from, rentedBuilding.country)
    params.to = getUTCIsoDate(params.to, rentedBuilding.country)
    const response = yield call(
      getElectricityMeterHistoryForRentedBuilding,
      rentedBuilding.id,
      params
    )
    yield put(actions.getElectricityMeterHistorySuccess(response.data))
    if (detectGapsInMeterHistoryData(response.data)) {
      yield put(
        appEventActions.fire({
          local: true,
          uniqueIdentifier: "energy-incomplete-data-error",
          eventName: PossibleAppEvents.ENERGY_DATA_INCOMPLETE_NOTIFICATION
        })
      )
    }
  } catch (e: any) {
    yield put(actions.getElectricityMeterHistoryFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}

export default function* electricityMeterHistorySaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, electricityMeterHistoryFlow)
}
