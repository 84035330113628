import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const CertificationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CertificationLabel = styled.label`
  line-height: ${theme.lineHeights.captionExtraSmall};
  font-size: ${theme.fontSizes.captionExtraSmall};
  font-style: ${theme.fontStyles.italic};
  text-decoration: underline;
`

export const CertificationValue = styled(CertificationLabel)`
  font-style: ${theme.fontStyles.normal};
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`
