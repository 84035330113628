import { FiltersWrapper } from "./style"
import {
  getDayEnd,
  getDayStart
} from "app/components/EnergyPeriod/util/date.util"
import { IntervalEnum } from "app/enums/interval"
import { TagEnum } from "app/redux/graphs/graphs.actions"
import { formatISODateWithoutTimezone } from "app/utils/dateFormatter.utils"
import { DownloadFilters } from ".."
import GranularityFilter from "./GranularityFilter"
import { MeterTypeFilter } from "./MeterTypeFilter"
import PeriodFilter from "./PeriodFilter"

type Props = {
  downloadFilters: DownloadFilters
  setDownloadFilters: (downloadFilters: DownloadFilters) => void
}

export const Filters = ({ downloadFilters, setDownloadFilters }: Props) => {
  const handlePeriodChange = (date: string, name: string) => {
    if (date === "Invalid date") return
    const newDate = formatISODateWithoutTimezone(
      name === "from" ? getDayStart(date) : getDayEnd(date)
    )
    setDownloadFilters({ ...downloadFilters, [name]: newDate })
  }

  const handleSelectedMetersChange = (tags: TagEnum[]) => {
    setDownloadFilters({ ...downloadFilters, tags })
  }

  const handleSelectedIntervalChange = (interval: IntervalEnum) => {
    setDownloadFilters({ ...downloadFilters, interval })
  }

  return (
    <FiltersWrapper>
      <PeriodFilter
        from={downloadFilters.from}
        to={downloadFilters.to}
        onPeriodChange={handlePeriodChange}
      />
      <GranularityFilter
        interval={downloadFilters.interval}
        from={downloadFilters.from as string}
        to={downloadFilters.to as string}
        onIntervalChange={handleSelectedIntervalChange}
      />
      <MeterTypeFilter
        selectedMeters={downloadFilters.tags}
        onSelectedMetersChange={handleSelectedMetersChange}
      />
    </FiltersWrapper>
  )
}

export default Filters
