import { RentedBuildingContract } from "app/types/rentedBuildingContract"
import { BaseCustomSelectOption } from "@paudigital/wdp-components"
import { getContractNumberFromContractSourceId } from "app/utils/rentedBuildingContracts.utils"
import { ContractRentalPeriod, ContractRentalPeriodText } from "../style"
import { useTranslate } from "hooks/translate"
import { format } from "date-fns"

type RestProps = {
  contract: RentedBuildingContract
}

type Props = RestProps

const ContractLabel = ({ contract }: Props) => {
  const { translate } = useTranslate()

  return (
    <BaseCustomSelectOption>
      <ContractRentalPeriodText>
        {translate("portal.buildingpassport.contracts.single.title", {
          contractNumber: getContractNumberFromContractSourceId(
            contract.sourceId
          )
        })}
      </ContractRentalPeriodText>
      <br />
      <ContractRentalPeriod>
        {`${translate("portal.buildingpassport.contract.rental.period")}`}
        :&nbsp;
        {`${format(new Date(contract.startDate), "dd/MM/yyyy")} -
        ${format(new Date(contract.endDate), "dd/MM/yyyy")}
        `}
      </ContractRentalPeriod>
    </BaseCustomSelectOption>
  )
}

export default ContractLabel
