import { BaseCard, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StyledErrorTextWrapper = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
  @media only screen and (${theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const StyledErrorTitle = styled.label`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.medium};
`

export const StyledErrorText = styled.p`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  margin-top: 1rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const StyledBaseCardWrapper = styled(BaseCard)`
  width: 100%;
`
