import State from "../state"
import { Invoice, InvoiceFilters } from "app/types/invoice"

export const getInvoices = (state: State): Invoice[] | undefined =>
  state.invoices.data.invoices
export const getInvoicesFilters = (state: State): InvoiceFilters | undefined =>
  state.invoices.data.filters
export const getInvoicesContracts = (state: State): string[] =>
  Array.from(
    new Set(
      state.invoices.data.invoices.map(
        i => i.metadata.Contract_x0020_number
      ) as string[]
    )
  )
export const getInvoicesFilterIsOpen = (state: State): boolean =>
  state.invoices.isFilterOpen
export const getTotal = (state: State): number => state.invoices.data.total
export const getSummary = (state: State): Invoice[] => state.invoices.summary
export const loading = (state: State): boolean => state.invoices.loading
export const getInvoiceById = (id: string) => (
  state: State
): Invoice | undefined => {
  const invoices = getInvoices(state)
  return invoices?.find(invoice => invoice.id === id)
}
