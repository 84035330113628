import { MeterHistoryData } from "app/types/energy"
import { Action, QueryParams, Types } from "./solarProductionHistory.actions"

export type SolarProductionHistoryState = {
  data: MeterHistoryData[] | undefined
  errors?: Record<string, unknown>
  params?: QueryParams
  lastUpdated?: Date,
  loading: boolean
}

const initialState: SolarProductionHistoryState = {
  data: undefined,
  errors: undefined,
  loading: false,
  lastUpdated: undefined,
  params: undefined
}

export default (
  state = initialState,
  action: Action
): SolarProductionHistoryState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        params: action.payload.params,
        errors: undefined,
        lastUpdated: new Date(),
        loading: true
      }
    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        lastUpdated: new Date(),
        loading: false
      }
    case Types.GET_FAIL:
      return {
        ...state,
        data: [],
        errors: action.payload.errors,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}
