import { SagaIterator } from "redux-saga"
import { call, put, select, take, takeLatest } from "redux-saga/effects"
import {
  getContactPerson,
  getContactPersonById,
  patchContactPerson,
  putProfilePicture,
  patchContactPersonOnboarding,
  patchContactPersonResponsibilities,
  resendInvite
} from "app/api/contactPerson"
import * as actions from "./contactPerson.actions"
import { actions as wizardActions } from "./../registrationWizard/registrationWizard.actions"
import {
  getSingleRentedBuilding,
  getPreferredBuildingId
} from "./../me/me.selectors"
import * as meActions from "./../me/me.actions"
import { setBuildingSwitcher } from "../buildingSwitcher/buildingSwitcher.actions"
import { ProfileActions } from "app/enums/profile"
import { fire, clear } from "../appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import * as responsibilityActions from "../responsibilities/responsibilities.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import { patchNotifications } from "app/api/notifications"

export function* getContactPersonFlow(
  action: actions.GetContactPersonAction
): SagaIterator {
  try {
    const { query, entityId } = action.payload
    const response = yield call(getContactPerson, entityId, query)
    yield put(actions.getContactPersonSuccess(response && response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getContactPersonFail(e))
  }
}

export function* getContactPersonByIdFlow(
  action: actions.GetContactPersonByIdAction
): SagaIterator {
  try {
    const { contactPersonId } = action.payload
    const response = yield call(getContactPersonById, contactPersonId)
    yield put(actions.getContactPersonByIdSuccess(response && response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getContactPersonByIdFail(e))
  }
}

export function* patchContactPersonFlow(
  action: actions.PatchContactPersonAction
): SagaIterator {
  try {
    const { contactPersonId, data } = action.payload
    const { id: rentedBuildingId } = yield select(getSingleRentedBuilding)
    const preferredBuildingId = data.preferredBuilding
    const response = yield call(patchContactPerson, contactPersonId, {
      ...data,
      preferredBuilding: preferredBuildingId
        ? preferredBuildingId
        : rentedBuildingId
    })
    yield put(meActions.getMe())
    yield take(meActions.getMeSuccess)
    yield put(wizardActions.increaseWizard())
    yield put(actions.patchContactPersonSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.patchContactPersonFail(e))
  }
}

export function* putProfilePictureFlow(
  action: actions.PutProfilePictureAction
): SagaIterator {
  try {
    const { contactPersonId, data } = action.payload
    const formdata = new FormData()
    formdata.append("profilePicture", data)
    const response = yield call(putProfilePicture, contactPersonId, formdata)
    const imgUrl = response.data.url
    yield put(meActions.setMeValue("profilePictureUrl", imgUrl))
    yield put(actions.putProfilePictureSuccess())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.putProfilePictureFail(e))
  }
}

export function* patchContactPersonOnboardingFlow(
  action: actions.PatchContactPersonOnboardingAction
): SagaIterator {
  try {
    const preferedBuilding = yield select(getPreferredBuildingId)
    if (!preferedBuilding) yield put(meActions.getMe())
    yield put(setBuildingSwitcher(preferedBuilding))
    const id = action.payload.contactPersonId
    yield call(patchContactPersonOnboarding, id)
    yield put(createGAEvent(PossibleGAEvents.SIGN_UP))
    yield put(wizardActions.clearWizard())
    yield put(meActions.getMe())
    yield call(patchNotifications, preferedBuilding)
    yield put(actions.patchContactPersonOnboardingSuccess())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.patchContactPersonOnboardingFail(e))
  }
}

export function* patchContactPersonAdminFlow(
  action: actions.PatchContactPersonAdminAction
): SagaIterator {
  try {
    const { contactPersonId, data } = action.payload
    const { id: rentedBuildingId } = yield select(getSingleRentedBuilding)
    const preferredBuildingId = data.preferredBuilding
    const response = yield call(patchContactPerson, contactPersonId, {
      ...data,
      preferredBuilding: preferredBuildingId
        ? preferredBuildingId
        : rentedBuildingId
    })
    yield put(actions.patchContactPersonAdminSuccess(response.data))
    yield put(meActions.getMe())
    yield take(meActions.getMeSuccess)
    yield put(clear())
    yield put(responsibilityActions.clearResponsibilities())
    yield put(
      fire({
        eventName: PossibleAppEvents.INFO_UPDATED,
        uniqueIdentifier: ProfileActions.INFO_UPDATED
      })
    )
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.patchContactPersonAdminFail(e))
  }
}

export function* patchContactPersonAndResponsibilitiesFlow(
  action: actions.PatchContactPersonWithResponsibilitiesAction
): SagaIterator {
  try {
    const { contactPersonId, data, responsibilities, profilePicture } =
      action.payload
    const { id: rentedBuildingId } = yield select(getSingleRentedBuilding)
    const preferredBuildingId = data.preferredBuilding
    const response = yield call(patchContactPerson, contactPersonId, {
      ...data,
      preferredBuilding: preferredBuildingId
        ? preferredBuildingId
        : rentedBuildingId
    })
    yield call(patchContactPersonResponsibilities, {
      contactPersonId,
      data: responsibilities
    })
    if (profilePicture)
      yield put(actions.putProfilePicture(contactPersonId, profilePicture))
    yield put(actions.patchContactPersonAdminSuccess(response.data))
    yield put(meActions.getMe())
    yield take(meActions.getMeSuccess)
    yield put(clear())
    yield put(responsibilityActions.clearResponsibilities())
    yield put(
      fire({
        eventName: PossibleAppEvents.INFO_UPDATED,
        uniqueIdentifier: ProfileActions.INFO_UPDATED
      })
    )
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.patchContactPersonAdminFail(e))
  }
}

export function* resendInviteFlow(
  action: actions.ResendInviteAction
): SagaIterator {
  try {
    const { contactPersonId } = action.payload
    yield call(resendInvite, contactPersonId)
    yield put(actions.resendInviteSuccess())
    yield put(
      fire({
        uniqueIdentifier: "resend-invite-success",
        eventName: PossibleAppEvents.RESEND_INVITE_SUCCESS
      })
    )
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getContactPersonFail(e))
  }
}

export default function* ContactPersonSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getContactPersonFlow)
  yield takeLatest(actions.Types.GET_BY_ID, getContactPersonByIdFlow)
  yield takeLatest(actions.Types.PATCH, patchContactPersonFlow)
  yield takeLatest(actions.Types.PUT_PROFILEPICTURE, putProfilePictureFlow)
  yield takeLatest(
    actions.Types.PATCH_ONBOARDING,
    patchContactPersonOnboardingFlow
  )
  yield takeLatest(actions.Types.PATCH_ADMIN, patchContactPersonAdminFlow)
  yield takeLatest(
    actions.Types.PATCH_WITH_RESPONSIBILITIES,
    patchContactPersonAndResponsibilitiesFlow
  )
  yield takeLatest(actions.Types.RESEND_INVITE, resendInviteFlow)
}
