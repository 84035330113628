import { InformationSection, theme } from "@paudigital/wdp-components"
import { FacilityTicketStatus } from "app/enums/ticketStatus"
import styled from "styled-components"

export const colorStatusMapping = {
  [FacilityTicketStatus.AWAITING_PROCESSING]: theme.colors.system.reported,
  [FacilityTicketStatus.CANCELLED]: theme.colors.neutral.darkGrey,
  [FacilityTicketStatus.CLOSED]: theme.colors.neutral.darkGrey,
  [FacilityTicketStatus.INFO_NEEDED]: theme.colors.system.error,
  [FacilityTicketStatus.PROCESSING]: theme.colors.system.warning,
  [FacilityTicketStatus.PLANNED]: theme.colors.system.planned,
  [FacilityTicketStatus.FINISHED]: theme.colors.system.success
}

export const StyledInformationSection = styled(InformationSection)<{
  status: FacilityTicketStatus
}>`
  width: 100%;
  margin-top: ${props =>
    props.status !== FacilityTicketStatus.INFO_NEEDED && "3.2rem"};

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-top: 3.2rem;
  }
`

export const StatusIndicator = styled.div<{ status: FacilityTicketStatus }>`
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.2rem;
  background: ${props => colorStatusMapping[props.status]};
`

export const Title = styled.div`
  display: flex;
`

export const Item = styled.section`
  margin-top: 2.4rem;
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.light};
  font-size: ${theme.fontSizes.caption};
  flex-wrap: wrap;

  &:first-of-type {
    margin: 0;
  }
`

export const StatusCardWrapper = styled.div`
  margin: 1.6rem 0;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 3.2rem 0 0;
  }
`

export const ContentWrapper = styled.div`
  margin: 3.2rem;
`

export const HeaderWrapper = styled.div`
  color: ${theme.colors.neutral.darkerGrey};
  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: 55rem;
  }
`

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;
    min-width: 55rem;
  }
`

export const CancelButton = styled.button`
  display: flex;
  flex-direction: row;
  border: none;
  background: none;
  align-items: center;
  cursor: pointer;
`

export const CancelLabel = styled.label`
  color: ${theme.colors.system.moreInfo};
  text-decoration: underline;
  margin-left: 1.6rem;
  cursor: pointer;
  font-size: ${theme.fontSizes.captionSmall};
  lineheights: ${theme.lineHeights.captionSmall};
`
