import { AnswerCode, AnswerCodeIds, TicketTypes } from "app/types/ticket"
import { addZeroWidthSpaceAfterCharacters } from "./linebreaks.utils"

type Props = {
  answerCodes: AnswerCode[]
  type: string
}

const buildSubCategoryTranslation = ({ answerCodes, type }: Props) => {
  const answerCodeId = answerCodes?.filter(a => {
    if (
      a.id === AnswerCodeIds.WDP_ELEKTRICITEIT2 ||
      a.id === AnswerCodeIds.WDP_POORT_ETC2
    ) {
      return false
    }
    return a.value
  })

  const boolSubType = () => {
    if (
      type === TicketTypes.WDP_10 ||
      type === TicketTypes.WDP_11 ||
      type === TicketTypes.WDP_12
    ) {
      return false
    }
    return true
  }

  return boolSubType()
    ? addZeroWidthSpaceAfterCharacters(
        `.${
          answerCodeId.length > 1
            ? answerCodeId.flatMap(answer => answer.value).join(".")
            : answerCodes[0]?.value
        }`,
        ["_"]
      )
    : ""
}

export default buildSubCategoryTranslation
