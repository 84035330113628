import { Timeframe } from "app/enums/timeframe"
import { clear } from "app/redux/appEvents/appEvents.actions"
import React, { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { UNSAFE_NavigationContext, useNavigate } from "react-router-dom"
import { getUser } from "app/redux/me/me.selectors"
import { BrowserHistory } from "history"

type Props = {
  children: JSX.Element
}

const HistoryProvider = ({ children }: Props) => {
  const dispatch = useDispatch()
  const navigation = useContext(UNSAFE_NavigationContext)
    .navigator as BrowserHistory
  const navigate = useNavigate()
  const user = useSelector(getUser)

  React.useEffect(() => {
    sessionStorage.setItem("sessionEnd", `${Date.now() + Timeframe.MIN_30}`)
    return navigation.listen(() => {
      sessionStorage.setItem("sessionEnd", `${Date.now() + Timeframe.MIN_30}`)
      if (navigation.action === "POP") {
        dispatch(clear())
        if (!user?.onboardedAt) navigate("/register")
      }
    })
  }, [dispatch, navigate, navigation, user?.onboardedAt])

  return children
}

export default HistoryProvider
