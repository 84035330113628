import { ChevronLeft, ChevronRight } from "@paudigital/wdp-components"
import { DocumentStatisticsDto } from "app/types/document"
import { useTranslate } from "hooks/translate"
import { Filters } from "routes/Documents"
import {
  ChevronLeftWrapper,
  ChevronRightWrapper,
  DrillDownWrapper,
  StyledDrilldown
} from "./style"

type DrillDownOptionProps = {
  option: DocumentStatisticsDto
  isSelected?: boolean
  arrow?: "left" | "right"
  setUrlParams: (params: Filters) => void
} & Filters

export const DrillDownOption = ({
  option,
  isSelected,
  arrow,
  page,
  perPage,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: DrillDownOptionProps): JSX.Element => {
  const { translate } = useTranslate()
  const queryParams = {
    page,
    perPage,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }
  const handleClick = () =>
    !isSelected &&
    setUrlParams({
      ...queryParams,
      documentType: option.document_type
    })

  return (
    <DrillDownWrapper onClick={handleClick}>
      {arrow && arrow === "left" && (
        <ChevronLeftWrapper>
          <ChevronLeft height="14" width="14" />
        </ChevronLeftWrapper>
      )}
      <StyledDrilldown isSelected={isSelected}>
        <span>
          {translate(`portal.documents.category.${option.document_type}`)} (
          {option.count})
        </span>
      </StyledDrilldown>
      {arrow && arrow === "right" && (
        <ChevronRightWrapper>
          <ChevronRight height="14" width="14" />
        </ChevronRightWrapper>
      )}
    </DrillDownWrapper>
  )
}

