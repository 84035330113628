import { LanguageSelectWrapper } from "./style"
import { DropDown } from "@paudigital/wdp-components"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { useApi } from "hooks/api"
import { patchLanguagePreference } from "app/redux/languagePreference/languagePreference.actions"
import { useTranslate } from "hooks/translate"
import { getLanguage, languageCodes } from "./utils/languageTranslations.utils"
import { Locales } from "app/i18n/config"

type Option = {
  label: string
  value: string
}

type Props = {
  defaultLanguage?: string
  label: string
  isPreferredLanguage?: boolean
  onChange?: (option: OnChangeValue<CustomOptionType, false>) => void
}

const LanguageSelect = ({
  defaultLanguage,
  isPreferredLanguage = true,
  onChange,
  label
}: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()

  const buildTranslation = (value: string) =>
    translate(getLanguage(value, isPreferredLanguage))

  const options: Option[] = languageCodes
    .map(l => ({
      label: buildTranslation(l),
      value: l
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))

  const defaultValue = buildTranslation(
    options.find(o => o.value === defaultLanguage)?.value || Locales.EN_GB
  )

  const changeLanguage = (option: OnChangeValue<CustomOptionType, false>) => {
    const lang = (option as Option).value
    call(patchLanguagePreference(lang as Locales))
  }

  return (
    <LanguageSelectWrapper data-testid="language-dropdown">
      <DropDown
        hasDropdownIndicator
        label={label}
        options={options}
        defaultValue={defaultValue}
        onChange={isPreferredLanguage ? changeLanguage : onChange!}
      />
    </LanguageSelectWrapper>
  )
}

export default LanguageSelect
