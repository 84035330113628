import {
  InformationSection,
  InformationSectionContent,
  InformationSectionHeader,
  SecurityDeposit
} from "@paudigital/wdp-components"
import InformationSectionTitle from "app/components/InformationSectionTitle"
import { SecurityDeposit as SecurityDepositType } from "app/types/rentedBuildingContract"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"
import { format } from "date-fns"
import { useTranslate } from "hooks/translate"
import {
  ContractualGarantueeContainer,
  ContractualGarantueeContentContainer,
  ContractualGarantueeLabel,
  ContractualGarantueeValue
} from "./style"

type ContractualGuaranteeProps = {
  securityDeposit: SecurityDepositType
}

type Props = ContractualGuaranteeProps

const ContractualGuarantee = ({ securityDeposit }: Props) => {
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()
  return (
    <InformationSection>
      <InformationSectionHeader>
        <InformationSectionTitle>
          {translate(
            "portal.buildingpassport.contract.section.contractualguarantee.title"
          )}
        </InformationSectionTitle>
      </InformationSectionHeader>
      <InformationSectionContent>
        <ContractualGarantueeContainer>
          <ContractualGarantueeContentContainer>
            <ContractualGarantueeLabel>
              {translate(
                "portal.buildingpassport.contract.section.contractualguarantee.type"
              )}
            </ContractualGarantueeLabel>
          </ContractualGarantueeContentContainer>
          <ContractualGarantueeContentContainer>
            <ContractualGarantueeValue>
              {translate(
                `portal.buildingpassport.contract.section.contractualguarantee.type.${securityDeposit.type}`
              )}
            </ContractualGarantueeValue>
          </ContractualGarantueeContentContainer>
        </ContractualGarantueeContainer>
        <ContractualGarantueeContainer>
          <ContractualGarantueeContentContainer>
            <ContractualGarantueeLabel>
              {translate(
                "portal.buildingpassport.contract.section.contractualguarantee.amount"
              )}
            </ContractualGarantueeLabel>
          </ContractualGarantueeContentContainer>
          <ContractualGarantueeContentContainer>
            <SecurityDeposit
              amount={decimalFormatter(securityDeposit.agreed)}
              currency={securityDeposit.currency}
              depositPeriod="" // Deposit period not yet known due to missing information, check with Kenneth about this
            />
          </ContractualGarantueeContentContainer>
        </ContractualGarantueeContainer>
        <ContractualGarantueeContainer>
          <ContractualGarantueeContentContainer>
            <ContractualGarantueeLabel>
              {translate(
                "portal.buildingpassport.contract.section.contractualguarantee.validUntil"
              )}
            </ContractualGarantueeLabel>
          </ContractualGarantueeContentContainer>
          <ContractualGarantueeContentContainer>
            <ContractualGarantueeValue>
              {format(new Date(securityDeposit.validTo), "dd/MM/yyyy")}
            </ContractualGarantueeValue>
          </ContractualGarantueeContentContainer>
        </ContractualGarantueeContainer>
      </InformationSectionContent>
    </InformationSection>
  )
}

export default ContractualGuarantee
