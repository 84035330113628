import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(4rem, auto) 1fr;
  gap: 2.4rem;
  grid-template-areas:
    "sidebar"
    "content";

  @media only screen and (${theme.breakpoints.desktop}) {
    grid-template-columns: 0.3fr 1fr;
    gap: 1.2rem;
    grid-template-areas:
      "sidebar content"
      "sidebar content";
  }
`

export const SideBar = styled.aside`
  grid-area: sidebar;

  @media only screen and (${theme.breakpoints.desktop}) {
    max-width: 25rem;
  }
`

export const Content = styled.main`
  grid-area: content;

  *:first-child {
    margin-top: 0;
  }

  h2 {
    font-size: ${theme.fontSizes.h2};
    line-height: ${theme.lineHeights.h2};
    color: ${theme.colors.neutral.darkerGrey};
    font-weight: ${theme.fontWeights.bold};
    margin-top: 3.2rem;
  }
  h3 {
    font-size: ${theme.fontSizes.h3};
    line-height: ${theme.lineHeights.h3};
    color: ${theme.colors.neutral.darkerGrey};
    font-weight: ${theme.fontWeights.bold};
    margin-top: 2.88rem;
  }
  h4 {
    font-size: ${theme.fontSizes.h4};
    line-height: ${theme.lineHeights.h4};
    color: ${theme.colors.neutral.darkerGrey};
    font-weight: ${theme.fontWeights.bold};
    margin-top: 2.4rem;
  }
  h5 {
    font-size: ${theme.fontSizes.h5};
    line-height: ${theme.lineHeights.h5};
    color: ${theme.colors.neutral.darkerGrey};
    font-weight: ${theme.fontWeights.bold};
    margin-top: 1.92rem;
  }
  h6 {
    font-size: ${theme.fontSizes.h6};
    line-height: ${theme.lineHeights.h6};
    color: ${theme.colors.neutral.darkerGrey};
    font-weight: ${theme.fontWeights.bold};
    margin-top: 1.6rem;
  }

  p {
    display: block;
    margin-block-start: 1.6rem;
    margin-block-end: 1.6rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  iframe {
    width: 100%;
    margin: 1.6rem 0;
  }

  a {
    color: ${theme.colors.green.contrast};
    text-decoration: underline;
    cursor: pointer;

    &:focus {
      outline-style: solid;
      outline-color: ${theme.colors.oceanGreen.prc125};
      outline-width: 1px;
    }
    &:active {
      outline-style: solid;
      outline-color: ${theme.colors.oceanGreen.prc125};
      outline-width: 1px;
      background-color: ${theme.colors.background.green};
    }
  }

  a.button {
    color: ${theme.colors.green.contrast};
    margin: 2rem 0;
    padding: 1.5rem 2.3rem;
    border-radius: 4px;
    border: 1px solid ${theme.colors.green.contrast};
    text-decoration: none;
    font-weight: ${theme.fontWeights.bold};
    &:hover {
      background: rgba(168, 180, 176, 0.2);
    }
  }

  .dropdown {
    background: ${theme.colors.neutral.lightGrey};
    border-radius: 4px;
    margin: 2rem 0;
    display: block;
    details {
      padding: 1.6rem;
      border-bottom: 0.5px solid ${theme.colors.neutral.darkerGrey};

      summary {
        cursor: pointer;
        color: ${theme.colors.neutral.darkerGrey};
        font-weight: ${theme.fontWeights.regular};
        &::marker {
          list-style-type: square;
          content: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.58707 0.443111C2.31257 0.168854 1.93484 0.00961336 1.53699 0.000421127C1.13913 -0.0087711 0.753727 0.132838 0.46557 0.394095C0.177412 0.655353 0.0101006 1.01486 0.000442471 1.39352C-0.00921565 1.77219 0.13957 2.139 0.41407 2.41326L12.0061 14L23.5971 2.41231C23.8651 2.13709 24.0086 1.77249 23.9963 1.39739C23.9841 1.02229 23.8171 0.666861 23.5316 0.408018C23.2461 0.149175 22.865 0.00773478 22.4708 0.0143024C22.0765 0.02087 21.7009 0.174918 21.4251 0.443111L12.0051 9.85889L2.58707 0.443111Z' fill='black'/%3E%3C/svg%3E%0A") "  ";
        }
      }
    }
    details:last-child {
      border: none;
    }
  }

  details[open] {
    background: ${theme.colors.neutral.lightGrey};
    padding: 1.6rem;
    border-bottom: 0.5px solid ${theme.colors.neutral.darkerGrey};
    summary {
      color: ${theme.colors.neutral.darkerGrey};
      margin-bottom: 1rem;
      font-weight: ${theme.fontWeights.regular};
      &::before {
        padding-left: 2rem;
      }
      &::marker {
        content: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.4129 13.5569C21.6874 13.8311 22.0652 13.9904 22.463 13.9996C22.8609 14.0088 23.2463 13.8672 23.5344 13.6059C23.8226 13.3446 23.9899 12.9851 23.9996 12.6065C24.0092 12.2278 23.8604 11.861 23.5859 11.5867L11.9939 0L0.402929 11.5877C0.134865 11.8629 -0.00856209 12.2275 0.00368309 12.6026C0.0159283 12.9777 0.182863 13.3331 0.468365 13.592C0.753866 13.8508 1.13497 13.9923 1.52921 13.9857C1.92345 13.9791 2.29912 13.8251 2.57493 13.5569L11.9949 4.14111L21.4129 13.5569Z' fill='black'/%3E%3C/svg%3E%0A") "  ";
      }
    }
  }
`
