import { theme, Link } from "@paudigital/wdp-components"
import styled from "styled-components"
import { convertRgbObjectToString } from "app/utils/convertRgbObjectToString.utils"
import { RgbColorType } from "app/types/banner"

export const BackgroundContainer = styled.div<{ url?: string }>`
  background-image: url(${props => props.url});
  height: 15rem;
  margin-top: 8rem;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-size: cover;
  width: 100%;

  @media only screen and (${theme.breakpoints.tablet}) {
    height: 16.4rem;
    margin: 0 auto;
    padding-bottom: 3.6rem;
  }

  @media only screen and (${theme.breakpoints.desktopXL}) {
    max-width: 144rem;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`

export const StyledLink = styled(Link)`
  border-radius: 4px;
  padding: 0px 10px 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.neutral.white};
  min-width: 16rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  border: 0;
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.bold};

  background: ${theme.colors.oceanGreen.prc125};
  height: 44px;

  &:hover {
    background: ${theme.colors.stromboliGreen.prc100};
  }
`
export const ColoredTitle = styled.h1`
  color: ${({ rgbColor }: { rgbColor: RgbColorType }) =>
    convertRgbObjectToString(rgbColor)};
  width: 100%;
  margin-top: 2rem;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.h5};

  @media only screen and (${theme.breakpoints.tablet}) {
    font-size: ${theme.fontSizes.h4};
  }
`
