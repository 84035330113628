import React from "react"
import { Button, Typography } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useDispatch } from "react-redux"
import Illustration from "./icon"
import {
  ContactWidgetContainer,
  ContactWidgetWrapper,
  TypographyWrapper,
  Container,
  StyledActionsContainer,
  StyledButton,
  HiddenEmailAnchor
} from "./style"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { Modules } from "app/enums/modules"
import { ExpertPopupButtonPosition } from "app/redux/gaEvents/gaEvents.actions"

const ContactWidget = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const ref = React.createRef<HTMLAnchorElement>()

  const togglePopUp = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.SHOW_EXPERT,
        uniqueIdentifier: "show-expert",
        props: {
          module: Modules.COMMERCIAL,
          position: ExpertPopupButtonPosition.MIDDLE
        }
      })
    )
  }

  const handleButtonClick = () => {
    ref.current?.click()
  }

  return (
    <ContactWidgetContainer>
      <Container>
        <ContactWidgetWrapper>
          <Illustration />
          <TypographyWrapper>
            <Typography variant="h5" fontStyle="bold">
              {translate("portal.dashboard.widget.contact.sales")}
            </Typography>
          </TypographyWrapper>
        </ContactWidgetWrapper>
        <HiddenEmailAnchor
          ref={ref}
          href={translate(
            "portal.dashboard.widget.contact.sales.visit.website.link"
          )}
          target="_blank"
        />
        <StyledActionsContainer>
          <StyledButton
            type="secondary"
            onClick={togglePopUp}
            label={translate("portal.dashboard.widget.contact.sales.button")}
          />
          <Button
            onClick={handleButtonClick}
            label={translate(
              "portal.dashboard.widget.contact.sales.visit.website"
            )}
          />
        </StyledActionsContainer>
      </Container>
    </ContactWidgetContainer>
  )
}

export default ContactWidget
