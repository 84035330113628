import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const EnergyWidgetContainer = styled.div`
  background: ${theme.colors.neutral.white};
`

export const UsageComparisonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
