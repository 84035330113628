import { InputLabel, Toggle } from "@paudigital/wdp-components"
import { patchContactPerson } from "app/redux/contactPerson/contactPerson.actions"
import { getUser, hasMonthlyNewsletter } from "app/redux/me/me.selectors"
import { MyProfile } from "app/types/myProfile"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { ToggleWrapper } from "./style"

const MonthlyNewsLetterToggler = () => {
  const { translate } = useTranslate()
  const monthlyNewsletter = useSelector(hasMonthlyNewsletter)
  const user: MyProfile = useSelector(getUser) as MyProfile
  const dispatch = useDispatch()
  const handleClick = (name: string, value: boolean) => {
    dispatch(
      patchContactPerson(user!.id, {
        ...user,
        termsAndConditionsAccepted: true,
        privacyPolicyAccepted: true,
        [name]: value
      })
    )
  }
  return (
    <>
      <InputLabel
        label={translate("portal.account.preferences.newsletter.title")}
      />
      <ToggleWrapper>
        <Toggle
          name="monthlyNewsletter"
          toggled={monthlyNewsletter}
          onClick={handleClick}
          label={translate("portal.account.preferences.newsletter.label")}
          guidanceText={translate(
            "portal.account.preferences.newsletter.guidanceText"
          )}
        />
      </ToggleWrapper>
    </>
  )
}

export default MonthlyNewsLetterToggler
