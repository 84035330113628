import { FacilityTicketStatus } from "app/types/ticket"
import { getSummary } from "app/redux/ticket/ticket.selectors"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useSelector } from "react-redux"
import queryString from "query-string"
import { useNavigate } from "react-router-dom"
import { Container } from "./style"
import QuickLink, { QuickLinkStatuses } from "../QuickLink"
import StatusCardWrapper from "../StatusCardWrapper"

export type FacilityTicketStatusInSummary =
  | FacilityTicketStatus
  | "finishedIn48Hours"

export type CardStatus = {
  status: FacilityTicketStatusInSummary
  text: string
  amount: number | undefined
  facilityTicketStatuses: FacilityTicketStatusInSummary[]
}

const TicketStatusList = () => {
  const navigate = useNavigate()
  const summary = useSelector(getSummary)
  const { rentedBuilding } = useRentedBuilding()
  const baseFilter = { page: 1, perPage: 30, sort: ["creation_date=desc"] }
  const url = `/facility-management/${rentedBuilding?.id}`

  const handleRedirect = (filter: string) => navigate(`${url}?${filter}`)

  const buildFilter = (filter: Object) =>
    queryString.stringify(
      { ...baseFilter, ...filter },
      { arrayFormat: `bracket`, encode: false }
    )

  const handleQuickLinkRedirect = (statuses: QuickLinkStatuses) => {
    const filter = buildFilter({ ...statuses })
    handleRedirect(filter)
  }
  const handleCardRedirect = (statuses: FacilityTicketStatusInSummary[]) => {
    const filter = buildFilter({ status: statuses })
    handleRedirect(filter)
  }

  const createLabel = (
    facilityTicketStatuses: FacilityTicketStatusInSummary[]
  ) =>
    facilityTicketStatuses
      .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
      .join("_")

  const buildState = (
    facilityTicketStatuses: FacilityTicketStatusInSummary[]
  ) => {
    const status = summary?.filter(
      s => facilityTicketStatuses.includes(s.status) && s.status
    )
    const amount = status?.reduce((a, b) => a + +b.amount, 0) || 0
    const text = createLabel(facilityTicketStatuses)
    return { text, amount, facilityTicketStatuses }
  }

  const getCard = (status: FacilityTicketStatusInSummary): CardStatus => {
    return {
      ...buildState([status]),
      status
    }
  }
  const getCards = () =>
    cardStates.map((cardState: FacilityTicketStatusInSummary) =>
      getCard(cardState)
    )

  const getLinks = () => {
    const finished = buildState([
      FacilityTicketStatus.FINISHED,
      FacilityTicketStatus.CANCELLED
    ])
    const processingPlanned = buildState([
      FacilityTicketStatus.PROCESSING,
      FacilityTicketStatus.PLANNED
    ])
    const awaitingProcessing = buildState([
      FacilityTicketStatus.AWAITING_PROCESSING
    ])

    return [awaitingProcessing, processingPlanned, finished]
  }

  const cardStates: FacilityTicketStatusInSummary[] = [
    FacilityTicketStatus.INFO_NEEDED,
    "finishedIn48Hours"
  ]

  const cards = getCards()
  const links = getLinks()

  return (
    <Container>
      {cards.map(card => (
        <StatusCardWrapper
          key={card.status}
          cardStatus={card}
          onClick={handleCardRedirect}
        />
      ))}

      {links?.map(
        (ts, i) =>
          ts.amount > 0 && (
            <QuickLink
              key={i}
              label={ts.text}
              amount={ts.amount}
              statuses={ts.facilityTicketStatuses}
              onClick={handleQuickLinkRedirect}
            />
          )
      )}
    </Container>
  )
}

export default TicketStatusList
