import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { useTranslate } from "hooks/translate"
import { useMemo } from "react"
import { OnChangeValue } from "react-select"
import { StyledSort, StyledDropdown, StyledLabel } from "./style"
import { Filters } from "routes/Documents"

export type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const Sort = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { translate } = useTranslate()
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }
  const filterOptions = [
    { label: "date", value: "date" },
    { label: "status", value: "category" }
  ]

  const optionTranslation = useMemo(() => {
    const sortBy = (queryParams?.sort && queryParams?.sort[0]) || "date"
    const translationKey = sortBy.substring(0, sortBy.indexOf("="))
    return translationKey && translationKey.length > 0 ? translationKey : undefined
  }, [queryParams?.sort])

  const handleChange = (optionData: OnChangeValue<CustomOptionType, false>) => {
    const queryString = (optionData as CustomOptionType).value
    setUrlParams({
      ...queryParams,
      sort:
        queryString === "date"
          ? [`${queryString}=desc`]
          : [`${queryString}=asc`]
    })
  }

  return (
    <StyledSort>
      <StyledLabel>
        {translate(`portal.documentmanagement.filter.sortBy`)}
      </StyledLabel>
      <StyledDropdown
        onChange={handleChange}
        options={filterOptions.map(o => ({
          value: o.value,
          label: translate(`portal.documentmanagement.filter.option.${o.value}`)
        }))}
        hasDropdownIndicator
        defaultValue={translate(
          `portal.documentmanagement.filter.option.${
            optionTranslation ?? "date"
          }`
        )}
      />
    </StyledSort>
  )
}

export default Sort
