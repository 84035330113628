import { useTranslate } from "hooks/translate"
import React from "react"
import {
  getWaterMeterHistoryData,
  hasWarning,
  isLoading
} from "app/redux/waterMeterHistory/waterMeterHistory.selectors"
import { useSelector } from "react-redux"
import { useApi } from "hooks/api"
import { getWaterMeterHistory } from "app/redux/waterMeterHistory/waterMeterHistory.actions"
import { useRentedBuilding } from "hooks/rentedBuilding"
import BaseGraph from "../BaseGraph"
import { Graphs } from "app/utils/graphs/graph.utils"
import {
  getEnergyPeriodRange,
  getIntervalOfGraph
} from "app/redux/energyPeriod/energyPeriod.selectors"
import { getLastUpdated } from "app/redux/waterMeterHistory/waterMeterHistory.selectors"

const WaterConsumptionGraph = () => {
  const { call } = useApi()
  const { rentedBuilding } = useRentedBuilding()
  const warning = useSelector(hasWarning)
  const waterMeterHistory = useSelector(getWaterMeterHistoryData)
  const lastUpdated = useSelector(getLastUpdated)
  const interval = useSelector(getIntervalOfGraph(Graphs.WATER_CONSUMPTION))
  const energyPeriod = useSelector(getEnergyPeriodRange)
  const loading = useSelector(isLoading)
  const { translate } = useTranslate()

  React.useEffect(() => {
    if (rentedBuilding && rentedBuilding.id) {
      call(
        getWaterMeterHistory(rentedBuilding, {
          interval: interval,
          from: energyPeriod.from,
          to: energyPeriod.to
        })
      )
    }
  }, [call, energyPeriod.from, energyPeriod.to, interval, rentedBuilding])

  const refreshGraph = () => {
    call(
      getWaterMeterHistory(rentedBuilding!, {
        interval: interval,
        from: energyPeriod.from,
        to: energyPeriod.to
      })
    )
  }

  return (
    <BaseGraph
      refreshGraph={refreshGraph}
      lastUpdated={lastUpdated}
      loading={loading}
      title={translate("portal.energy.graphs.water.title")}
      graphType={Graphs.WATER_CONSUMPTION}
      graphSerieData={waterMeterHistory}
      warning={warning}
    />
  )
}

export default WaterConsumptionGraph
