import State from "../state"
import {
  Document,
  DocumentsFiltersDto,
  DocumentStatisticsDto,
  SetDocumentFieldType
} from "app/types/document"

export const isLoading = (state: State): boolean => state.document.loading
export const getDocuments = (state: State): Document[] =>
  state.document.data.documents
export const getParams = (state: State): DocumentsFiltersDto =>
  state.document.data.filters
export const getTotalDocuments = (state: State): number =>
  state.document.data.total
export const getDocumentsSummary = (state: State): Document[] | undefined =>
  state.document.summary

export const getDocumentStatistics = (state: State): DocumentStatisticsDto[] =>
  state.document.statistics

export const getDocumentStatisticsFromDocument = (
  state: State
): DocumentStatisticsDto[] => state.document.data.documentStatistics

export const getDocumentsFilterIsOpen = (state: State): boolean =>
  state.document.isFilterOpen
export const getDocument = (state: State): Partial<SetDocumentFieldType> =>
  state.document.document

export const getDocumentById = (id: string) => (
  state: State
): Document | undefined => {
  const documents = getDocuments(state)
  return documents.find(document => document.id === id)
}
