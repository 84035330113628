import { Action, Types } from "./ticket.actions"
import { TicketSummary } from "app/types/ticketSummary"
import { PostTicket, TicketsForRentedBuilding } from "app/types/ticket"

export type TicketState = {
  errors: object
  loading: boolean
  ticket: Partial<PostTicket>
  summary: TicketSummary | undefined
  tickets: TicketsForRentedBuilding
  isFilterOpen: boolean
}

const initialTicket = {
  total: 0,
  facilityTickets: [],
  filters: {
    page: 1,
    perPage: 30,
    pages: 1,
    ticket: undefined,
    description: undefined,
    status: undefined,
    type: undefined,
    from: undefined,
    to: undefined,
    sort: "creation_date"
  },
  ticketStatistics: {
    countPerStatus: undefined,
    countPerType: undefined
  }
}

const initialState: TicketState = {
  errors: {},
  loading: false,
  ticket: {
    customerContact: false,
    priority: false
  },
  summary: undefined,
  tickets: initialTicket,
  isFilterOpen: false
}

export default (state = initialState, action: Action): TicketState => {
  switch (action.type) {
    case Types.DISCARD:
      return {
        ...state,
        ticket: {
          customerContact: false,
          priority: false
        }
      }

    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        tickets: action.payload.data,
        errors: {},
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
        tickets: {
          ...state.tickets,
          facilityTickets: []
        }
      }

    case Types.DOWNLOAD:
      return {
        ...state,
        loading: true
      }

    case Types.DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.DOWNLOAD_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {}
      }

    case Types.POST_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.POST_ATTACHMENTS:
      return {
        ...state
      }

    case Types.POST_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        errors: {}
      }

    case Types.POST_ATTACHMENTS_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case Types.SET_FIELD:
      const { key, value } = action.payload
      return {
        ...state,
        ticket: {
          ...state.ticket,
          [key]: value
        }
      }

    case Types.SET_TICKET:
      return {
        ...state,
        ticket: action.payload
      }

    case Types.GET_SUMMARY:
      return {
        ...state
      }

    case Types.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        errors: {},
        summary: action.payload
      }

    case Types.GET_SUMMARY_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case Types.GET_TICKET_ATTACHMENTS:
      return {
        ...state
      }

    case Types.GET_TICKET_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        errors: {}
      }

    case Types.GET_TICKET_ATTACHMENTS_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case Types.GET_SELECTED_TICKETS_FILTERS:
      return {
        ...state,
        isFilterOpen: action.payload
      }

    case Types.DOWNLOAD_CSV: {
      return {
        ...state,
        loading: true
      }
    }

    case Types.DOWNLOAD_CSV_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }

    case Types.DOWNLOAD_CSV_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }
    }

    case Types.CANCEL_TICKET: {
      return {
        ...state,
        loading: true
      }
    }

    case Types.CANCEL_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }

    case Types.CANCEL_TICKET_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }
    }

    default:
      return state
  }
}
