import {
  Button,
  Logo,
  ChatIcon,
  BellIcon,
  HamburgerIcon,
  NavigationButton,
  User,
  theme
} from "@paudigital/wdp-components"
import React, { useEffect } from "react"
import {
  NavigationContainer,
  NavigationWrapper,
  StyledNavigation,
  NotificationsWrapper,
  NotificationWrapper,
  MenuItem,
  NavigationMenuItems,
  MenuItemLink,
  LogoutButtonWrapper,
  SettingMenuItems,
  TextWrapper,
  LogoWrapper,
  StyledContact,
  StyledNotifications,
  NotificationIndicator,
  NotificationsLabel,
  Buffer
} from "./style"
import NavigationSelectedBuilding from "../NavigationSelectedBuilding"
import { useNavigation } from "hooks/navigation"
import { useTranslate } from "hooks/translate"
import { useViewport } from "hooks/viewport"
import { useScroll } from "hooks/scroll"
import { mainRoutes } from "./config"
import { useDispatch, useSelector } from "react-redux"
import { getUser } from "app/redux/me/me.selectors"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { usePermission } from "hooks/permission"
import { Modules } from "app/enums/modules"
import NavigationPopUpContent from "../NavigationPopUpContent"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { useNavigate } from "react-router-dom"
import { useLogout } from "hooks/logout"
import { getNotificationsCount } from "app/redux/notifications/notifications.selectors"
import * as actions from "app/redux/notifications/notifications.actions"
import { ExpertPopupButtonPosition } from "app/redux/gaEvents/gaEvents.actions"
import WdpAdminBuildingSwitcher from "../WdpAdminBuildingSwitcher"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"

type Props = {
  module?: Modules
}

const Navigation = ({ module }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { y: scrollY } = useScroll()
  const { translate } = useTranslate()
  const { isActive } = useNavigation()
  const { logout } = useLogout()
  const { width } = useViewport()
  const { rentedBuilding } = useRentedBuilding()
  const { hasPermission } = usePermission()
  const navigate = useNavigate()

  const [active, setActive] = React.useState(true)
  const [pastScrollThreshold, setPastScrollThreshold] = React.useState(false)

  const user = useSelector(getUser)
  const amountOfUnreadNotifications = useSelector(getNotificationsCount)
  const activeBuilding = useSelector(getActiveBuilding)
  const tablet = theme.breakpointsValues.tablet
  const isDesktop = width >= theme.breakpointsValues.desktop
  const isMobile = width < theme.breakpointsValues.tablet
  const isWdpAdmin = user?.groups ? true : false

  useEffect(() => {
    dispatch(actions.getNotificationsCount())
  }, [dispatch])

  React.useEffect(() => {
    const height = pastScrollThreshold ? 80 : 160
    setPastScrollThreshold(Math.abs(scrollY) >= height)
  }, [pastScrollThreshold, scrollY])

  React.useEffect(() => {
    setActive(width >= tablet)
  }, [tablet, width])

  const handleMenuToggle = () => {
    setActive(!active)
  }

  const redirectNotifications = () => {
    if (rentedBuilding?.id) {
      return navigate(`/notifications/${rentedBuilding.id}`)
    }
  }

  const toggleContactPopUp = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.SHOW_EXPERT,
        uniqueIdentifier: "show-expert",
        props: {
          module: module,
          position: ExpertPopupButtonPosition.TOP
        }
      })
    )
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <NavigationContainer isSticky={!pastScrollThreshold}>
      <NavigationWrapper isActive={active}>
        <StyledNavigation active={active} multiRow={!pastScrollThreshold}>
          <LogoWrapper data-testid="wdp-logo" to="/" active={active.toString()}>
            <Logo
              hasByline={isDesktop && !pastScrollThreshold}
              showBorder={true}
            />
          </LogoWrapper>
          {(user && user.groups && user!.groups?.length > 0) ? (
            (width >= tablet || (width < tablet && active)) ?
            <WdpAdminBuildingSwitcher isVisible={!pastScrollThreshold}/>
            : <Buffer/>
          ) : (
            <NavigationSelectedBuilding
            isVisible={!pastScrollThreshold}
            isActive={active && isMobile}
          />
          )}
          <NotificationsWrapper
            isVisible={!pastScrollThreshold}
            active={active}
          >
            {width < tablet ? (
              <>
                <NavigationButton
                  onClick={toggleContactPopUp}
                  data-testid="header-contact"
                >
                  <TextWrapper>
                    {translate("portal.navigation.button.contact")}
                  </TextWrapper>
                </NavigationButton>
                <NavigationButton
                  onClick={redirectNotifications}
                  data-testid="header-contact"
                >
                  <>
                    <BellIcon />
                    {rentedBuilding &&
                      amountOfUnreadNotifications[rentedBuilding.id] > 0 && (
                        <NotificationIndicator />
                      )}
                  </>
                </NavigationButton>
                <NavigationButton onClick={handleMenuToggle}>
                  <HamburgerIcon />
                </NavigationButton>
              </>
            ) : (
              <>
                <NotificationWrapper
                  tabIndex={2}
                  onClick={toggleContactPopUp}
                  data-testid="header-contact"
                >
                  <ChatIcon />
                  <StyledContact>
                    {translate("portal.navigation.button.contact")}
                  </StyledContact>
                </NotificationWrapper>
                <NotificationWrapper
                  tabIndex={3}
                  data-testid="header-notifications"
                >
                  <StyledNotifications
                    to={`/notifications/${rentedBuilding?.id}`}
                  >
                    <BellIcon />
                    {rentedBuilding &&
                      amountOfUnreadNotifications[rentedBuilding?.id] > 0 && (
                        <NotificationIndicator />
                      )}
                    <NotificationsLabel>
                      {translate("portal.navigation.item.notifications")}
                    </NotificationsLabel>
                  </StyledNotifications>
                </NotificationWrapper>
              </>
            )}
          </NotificationsWrapper>
          {active && (
            <>
              <NavigationMenuItems>
                {mainRoutes.map(r => {
                  if (
                    rentedBuilding &&
                    hasPermission(r.route as Modules, rentedBuilding.id)
                  ) {
                    const url = `/${r.route}${
                      r.hasIdParam ? `/${rentedBuilding.id}` : ""
                    }`
                    return (
                      <MenuItem
                        data-testid={`navigation-${translate(
                          `portal.navigation.item.${r.route}`
                        )}`}
                        active={isActive(url)}
                        key={r.route}
                      >
                        <MenuItemLink to={url}>
                          {translate(`portal.navigation.item.${r.route}`)}
                        </MenuItemLink>
                      </MenuItem>
                    )
                  }
                  if(activeBuilding && user?.groups && user.groups.length > 0 && r.route !== "user-management") {
                    const url = `/${r.route}${
                      r.hasIdParam ? `/${activeBuilding}` : ""
                    }`
                    return (
                      <MenuItem
                        data-testid={`navigation-${translate(
                          `portal.navigation.item.${r.route}`
                        )}`}
                        active={isActive(url)}
                        key={r.route}
                      >
                        <MenuItemLink to={url}>
                          {translate(`portal.navigation.item.${r.route}`)}
                        </MenuItemLink>
                      </MenuItem>
                    )
                  }
                  return null
                })}
              </NavigationMenuItems>
              <SettingMenuItems>
                  <MenuItem active={isActive("/account")}>
                  <MenuItemLink to="/account">
                    {translate("portal.navigation.item.account")}
                  </MenuItemLink>
                </MenuItem>
                <MenuItem active={isActive("/help")}>
                  <MenuItemLink to="/help">
                    {translate("portal.navigation.item.faq")}
                  </MenuItemLink>
                </MenuItem>
              </SettingMenuItems>
              <LogoutButtonWrapper>
                {width < tablet && (
                  <Button
                    label={translate("portal.navigation.button.logout")}
                    onClick={handleLogout}
                    fullWidth
                  />
                )}
                {width >= tablet && user && (
                  <User
                    name={`${user.firstName} ${user.lastName}`}
                    company={`${user.companies[0].name}`}
                    role={user.function}
                    isVisible={!isDesktop || !pastScrollThreshold}
                    data-testid="account-menu"
                  >
                    <NavigationPopUpContent isWdpAdmin={isWdpAdmin}/>
                  </User>
                )}
              </LogoutButtonWrapper>
            </>
          )}
        </StyledNavigation>
      </NavigationWrapper>
    </NavigationContainer>
  )
}

export default Navigation
