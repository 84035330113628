import { actionTypesFor } from "./wizard.actions"

export type WizardState = {
  step: number
}

export const DEFAULT_STATE: WizardState = {
  step: 1
}

const createWizardReducer = (
  tableName: string = ""
): ((state: WizardState, action: any) => WizardState) => {
  return (state = DEFAULT_STATE, action: any) => {
    const { INCREASE, DECREASE, CLEAR, SET } = actionTypesFor(tableName)

    if (!action.type) {
      return state
    }

    switch (action.type) {
      case INCREASE:
        return {
          ...state,
          step: state.step + 1
        }

      case DECREASE:
        return {
          ...state,
          step: state.step - 1
        }

      case CLEAR:
        return DEFAULT_STATE

      case SET:
        return {
          ...state,
          step: action.payload.index
        }

      default:
        return state
    }
  }
}

export default createWizardReducer
