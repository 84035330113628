import { FileUpload, InputElement } from "@paudigital/wdp-components"
import { useApi } from "hooks/api"
import { ExtraInformationContainer, ButtonWrapper, StyledButton } from "./style"
import {
  patchTickets,
  setTicketCommunication
} from "app/redux/ticketCommunication/ticketCommunication.actions"
import { useFormik } from "formik"
import { CustomerCommunication } from "app/types/ticketCommunication"
import { validationSchema } from "./config"
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "hooks/translate"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { getCustomerCommunication } from "app/redux/ticketCommunication/ticketCommunication.selectors"
import React from "react"
import { useRentedBuilding } from "hooks/rentedBuilding"

type Props = {
  id: string
  onCancel?: () => void
}

const Feedback = ({ id, onCancel }: Props) => {
  const communicationCustomer = useSelector(getCustomerCommunication)
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const { call } = useApi()
  const { rentedBuilding } = useRentedBuilding()

  const {
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    handleBlur,
    values
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerCommunication: communicationCustomer?.customerCommunication || "",
      files: communicationCustomer?.files || []
    },
    onSubmit: (data: Partial<CustomerCommunication>) => {
      if (isValid && rentedBuilding && id) {
        call(
          patchTickets({
            id,
            rentedBuildingId: rentedBuilding.id,
            data: data as CustomerCommunication,
            uniqueIdentifier: "ticket-feedback",
            event: PossibleAppEvents.TICKET_FEEDBACK
          })
        )
      }
    },
    validationSchema
  })

  const hasErrors = (name: string): boolean =>
    errors[name as keyof Partial<CustomerCommunication>] ? true : false

  const handleFeedbackChange = (e: React.ChangeEvent<any>) => {
    const { value: val, name } = e.currentTarget
    handleChange(e)
    handleBlur(e)
    setFieldValue(name, val)
    dispatch(
      setTicketCommunication({
        customerCommunication: val as string,
        files: values.files
      })
    )
  }

  const handleCustomBlur = (e: React.FocusEvent<any>) => {
    const { value: val } = e.currentTarget
    const { name } = e.target
    handleBlur(e)
    setFieldValue(name, val)
  }

  const handleFileUpload = (files: File[]) => {
    setFieldValue("files", files)
    dispatch(
      setTicketCommunication({
        customerCommunication: values.customerCommunication,
        files
      })
    )
  }

  return (
    <ExtraInformationContainer onSubmit={handleSubmit}>
      <InputElement
        name="customerCommunication"
        charLimit={2000}
        charCount
        charTranslation={translate(
          "portal.facilitymanagement.reportdetail.customerFeedback.characters"
        )}
        label={translate(
          "portal.facilitymanagement.reportdetail.customerFeedback.label"
        )}
        type="textarea"
        fullWidth
        value={values.customerCommunication}
        onChange={handleFeedbackChange}
        onBlur={handleCustomBlur}
        error={hasErrors("customerCommunication")}
        errorMessage={
          errors.customerCommunication
            ? translate(errors.customerCommunication)
            : undefined
        }
      />
      <FileUpload
        initialValue={values.files}
        label={translate(
          "portal.facilitymanagement.createticket.step2.upload.label"
        )}
        guidanceText={translate(
          "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.6"
        )}
        optionalText={translate(
          "portal.facilitymanagement.createticket.step2.upload.optional"
        )}
        optional
        required={false}
        onChange={handleFileUpload}
        inputLimit={15}
        placeholder={translate(
          "portal.facilitymanagement.createticket.step2.upload.placeholder"
        )}
        inputCountTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.uploads"
        )}
        fileTypeErrorTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.error.type"
        )}
        fileLimitErrorTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.error.limit"
        )}
        fileSizeErrorTranslation={translate(
          "portal.facilitymanagement.createticket.step2.upload.error.size"
        )}
      />
      <ButtonWrapper>
        <StyledButton
          label={translate(
            "portal.facilitymanagement.reportdetail.customerFeedback.button.send"
          )}
          type="primary"
          onClick={handleSubmit}
          submit
        />
        <StyledButton
          label={translate(
            "portal.facilitymanagement.reportdetail.customerFeedback.button.cancel"
          )}
          type="secondary"
          onClick={onCancel}
        />
      </ButtonWrapper>
    </ExtraInformationContainer>
  )
}

export default Feedback
