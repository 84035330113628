import { DropDown } from "@paudigital/wdp-components"
import { useApi } from "hooks/api"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import {
  getSortedRentedBuildings,
  getPreferredBuilding,
  hasMultipleCompanies
} from "app/redux/me/me.selectors"
import { OnChangeValue } from "react-select"
import { patchPreferedBuilding } from "app/redux/preferredBuilding/preferredBuilding.actions"
import { getSuccess } from "app/redux/preferredBuilding/preferredBuilding.selectors"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"

type Props = {
  showSuccessMessage?: boolean
}

const PreferredbuildingDropDown = ({ showSuccessMessage }: Props) => {
  const rentedBuildings = useSelector(getSortedRentedBuildings)
  const preferredBuilding = useSelector(getPreferredBuilding)
  const hasMultipleCompaniesContext = useSelector(hasMultipleCompanies)
  const success = useSelector(getSuccess)
  const { call } = useApi()
  const { translate } = useTranslate()
  const defaultValue =
    preferredBuilding &&
    (hasMultipleCompaniesContext
      ? `${preferredBuilding.city} - ${preferredBuilding.street} ${preferredBuilding.houseNo} (${preferredBuilding.entityName})`
      : `${preferredBuilding.city} - ${preferredBuilding.street} ${preferredBuilding.houseNo}`)

  const handleChange = (option: OnChangeValue<CustomOptionType, false>) => {
    const buildingId = (option as CustomOptionType).value
    call(patchPreferedBuilding(buildingId))
  }

  if (!rentedBuildings) return <></>

  return (
    <DropDown
      hasDropdownIndicator
      defaultValue={defaultValue}
      onChange={handleChange}
      label={translate("portal.account.preferences.preferredBuilding.label")}
      options={rentedBuildings.map(r => {
        const label = hasMultipleCompaniesContext
          ? `${r.city} - ${r.street} ${r.houseNo} (${r.entityName})`
          : `${r.city} - ${r.street} ${r.houseNo}`
        return {
          label,
          value: r.id
        }
      })}
      success={showSuccessMessage && success}
      successLabel={translate(
        "portal.account.preferences.preferredBuilding.success.label"
      )}
    />
  )
}

export default PreferredbuildingDropDown
