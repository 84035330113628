import BannerTitle from "app/components/BannerTitle"
import { Locales } from "app/i18n/config"
import { Campaign } from "app/types/banner"
import { useTranslate } from "hooks/translate"

type HeaderTitleProps = {
  bannerInfo: Campaign | undefined
  preferredLanguage: Locales
  page: string
}

export const HeaderTitle = ({
  bannerInfo,
  preferredLanguage,
  page
}: HeaderTitleProps) => {
  const { translate } = useTranslate()

  return (
    <BannerTitle color={bannerInfo?.color.hex}>
      {bannerInfo?.languageData[preferredLanguage]?.title ||
        translate(`portal.${page}.pageheader.title`)}
    </BannerTitle>
  )
}

export default HeaderTitle
