import { RentedBuildingContact } from "app/types/rentedBuildingContact"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getContactPersons } from "app/redux/rentedBuildingContacts/rentedBuildingContacts.selectors"
import { useRentedBuilding } from "./rentedBuilding"
import { getRentedBuildingContacts } from "app/redux/rentedBuildingContacts/rentedBuildingContacts.actions"
import { PossibleBuildingContactRoles } from "app/enums/roles"
import { useTranslate } from "./translate"
import { Modules } from "app/enums/modules"
import { useApi } from "./api"

export type MappingType = {
  [key: string]: string
}

export type PartialContact = {
  person?: {
    firstName: string
    lastName: string
    mobilephone: string
    email: string
  }
  role: string
  profilePicture?: string
}
export type ContactMappingType = {
  [key: string]: RentedBuildingContact | PartialContact | undefined
}

export const mappings: MappingType = {
  [Modules.ENERGY]: "energy",
  [Modules.INVOICES]: "finance",
  [Modules.DOCUMENTS]: "facility",
  [Modules.FACILITY_MANAGEMENT]: "facility",
  [Modules.DASHBOARD]: "portal",
  [Modules.USER_MANAGEMENT]: "portal",
  [Modules.NOTIFICATIONS]: "portal",
  [Modules.COMMERCIAL]: "commercial",
  [Modules.ACCOUNT]: "portal",
  [Modules.FAQ]: "portal"
}

export const useContactPerson = () => {
  const { translate, hasTranslations } = useTranslate()
  const { rentedBuilding } = useRentedBuilding()

  const { call } = useApi()
  const contactPersons = useSelector(getContactPersons)

  const determineCountry = () => {
    const existingEmail = hasTranslations(
      `portal.contact.countrymanager.${rentedBuilding?.country}.email`
    )
    const existingPhone = hasTranslations(
      `portal.contact.countrymanager.${rentedBuilding?.country}.phone`
    )
    return existingEmail || existingPhone ? rentedBuilding?.country : "default"
  }

  const country = determineCountry()

  useEffect(() => {
    if (rentedBuilding?.id) {
      call(getRentedBuildingContacts(rentedBuilding.id))
    }
  }, [call, rentedBuilding?.id])

  const facilityManager = contactPersons?.find(
    c => c.role === PossibleBuildingContactRoles.FACILITY_MANAGER
  )
  const accountant = contactPersons?.find(
    c => c.role === PossibleBuildingContactRoles.FINANCIAL_MANAGER
  )
  const commercialManager = contactPersons?.find(
    c => c.role === PossibleBuildingContactRoles.SALES_MANAGER
  )

  const contactMapping: ContactMappingType = {
    energy: {
      person: facilityManager?.person,
      role: translate("portal.contact.roles.facilitymanager"),
      profilePicture: facilityManager?.profilePicture
    },
    finance: {
      person: accountant?.person,
      role: translate("portal.contact.roles.accountant"),
      profilePicture: accountant?.profilePicture
    },
    facility: {
      person: facilityManager?.person,
      role: translate("portal.contact.roles.facilitymanager"),
      profilePicture: facilityManager?.profilePicture
    },
    portal: {
      person: {
        firstName: translate("portal.contact.productowner.firstName"),
        lastName: translate("portal.contact.productowner.lastName"),
        mobilephone: translate("portal.contact.productowner.telephone"),
        email: translate("portal.contact.productowner.email")
      },
      role: translate("portal.contact.roles.productowner"),
      profilePicture: translate("portal.contact.productowner.profilePicture")
    },
    commercial: {
      person: commercialManager?.person,
      role: translate("portal.contact.roles.commercialmanager"),
      profilePicture: commercialManager?.profilePicture
    },
    idea: {
      person: {
        firstName: translate(
          `portal.contact.countrymanager.${country ?? "default"}.firstName`
        ),
        lastName: translate(
          `portal.contact.countrymanager.${country ?? "default"}.lastName`
        ),
        mobilephone: translate(
          `portal.contact.countrymanager.${country ?? "default"}.telephone`
        ),
        email: translate(
          `portal.contact.countrymanager.${country ?? "default"}.email`
        )
      },
      role: translate("portal.contact.roles.countrymanager"),
      profilePicture: translate(
        `portal.contact.countrymanager.${country ?? "default"}.profilePicture`
      )
    }
  }

  return { contactMapping }
}
