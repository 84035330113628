import { CardContent } from "@paudigital/wdp-components"
import {
  StyledButton,
  StyledCardActions,
  StyledDataIdDiv,
  StyledBaseCard,
  StyledTitle,
  StyledInfo,
  StyledDescription
} from "./style"
import { useTranslate } from "hooks/translate"
import { Document } from "app/types/document"
import { DocumentTypes } from "app/enums/documentTypes"
import { useApi } from "hooks/api"
import { downloadDocument } from "app/redux/document/document.actions"
import { useMemo } from "react"
import { addZeroWidthSpaceAfterCharacters } from "app/utils/linebreaks.utils"

type Props = {
  document: Document
}

const WidgetCardSingleContract = ({ document }: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const { id, topDocumentType, metadata, documentTreeType } = document

  const handleDownload = () => {
    call(downloadDocument(id))
  }

  const title = useMemo(() => {
    if (!metadata.Title) return
    return addZeroWidthSpaceAfterCharacters(metadata.Title, ["_"])
  }, [metadata.Title])

  return (
    <StyledBaseCard actionPlacement="right" boxShadow="dropShadow">
      <StyledDataIdDiv
        data-testid="documentWidgetItem"
        onClick={handleDownload}
      >
        <CardContent>
          <StyledInfo>
            <StyledTitle>
              {translate(`portal.documents.category.${topDocumentType}`)}
            </StyledTitle>
            <StyledDescription>
              {translate(`portal.documents.category.${documentTreeType}`)}
              {topDocumentType === DocumentTypes.Contracts &&
                metadata.Contract_x0020_number && (
                  <> {metadata.Contract_x0020_number}</>
                )}
              {title && (
                <>
                  :{" "}
                  {title.length > 100 ? `${title.substring(0, 100)}...` : title}
                </>
              )}
            </StyledDescription>
          </StyledInfo>
        </CardContent>
        <StyledCardActions alignment="horizontal">
          <StyledButton
            label={translate("portal.documents.download.button.label")}
            type="secondary"
          />
        </StyledCardActions>
      </StyledDataIdDiv>
    </StyledBaseCard>
  )
}

export default WidgetCardSingleContract
