import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const TitleContainer = styled.div`
  margin: 2.4rem;

  @media only screen and (${theme.breakpoints.desktop}) {
    display: block;
  }
`

export const TitleComparisonContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 2.4rem;
  flex-wrap: wrap;

  & > p:first-of-type {
    flex: 1;
    margin-right: 1.2rem;
  }
`
