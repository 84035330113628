import { getDocumentTypes } from "app/redux/documentTypes/documentTypes.actions"
import { DocumentCategory as DocumentCategoryTypes } from "app/redux/documentTypes/documentTypes.reducer"
import * as selectors from "app/redux/documentTypes/documentTypes.selectors"
import { actions } from "app/redux/documentWizard/documentWizard.actions"
import { useApi } from "hooks/api"
import { useTranslate } from "hooks/translate"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Category from "./Category"
import ListItem from "app/components/ListItem"
import { setFieldValue } from "app/redux/document/document.actions"
import { getDocument } from "app/redux/document/document.selectors"
import { getCategoryParent } from "./../util"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { usePermission } from "hooks/permission"

const DocumentCategory = (): JSX.Element => {
  const document = useSelector(getDocument)
  const types = useSelector(selectors.getDocumentTypes)
  const buildingId = useSelector(getActiveBuilding)
  const [activeCategory, setActiveCategory] = useState<
    DocumentCategoryTypes | undefined
  >(undefined)
  const { translate } = useTranslate()
  const { call } = useApi()
  const dispatch = useDispatch()
  const { isFacilityManager } = usePermission()
  const isFacilityMgr = isFacilityManager()

  useEffect(() => {
    if (buildingId) {
      call(getDocumentTypes(buildingId))
    }
  }, [buildingId, call])

  useEffect(() => {
    if (types && types.length === 1 && types[0]) {
      setActiveCategory(types[0])
      return
    }
    const category = getCategoryParent(types, document.treeType)
    setActiveCategory(category)
  }, [document, types])

  const handleClick = (category?: DocumentCategoryTypes) => {
    if (category?.children?.length === 0) {
      dispatch(setFieldValue("treeType", category.treeType))
      dispatch(setFieldValue("documentTypeId", category.documentTypeId))
      dispatch(setFieldValue("secondaryTypeIds", category.secondaryTypeIds))
      dispatch(actions.increaseWizard())
    }
    setActiveCategory(category)
  }

  const handleGoBack = () => {
    const result = getCategoryParent(types, activeCategory?.treeType!)
    setActiveCategory(result)
  }

  useEffect(() => {
    if (isFacilityMgr || types.length === 1) {
      const category = types[0]
      if (category?.children?.length === 0) {
        dispatch(setFieldValue("treeType", category.treeType))
        dispatch(setFieldValue("documentTypeId", category.documentTypeId))
        dispatch(actions.increaseWizard())
      }
      setActiveCategory(category)
    }
  }, [dispatch, isFacilityMgr, types])

  return (
    <>
      {activeCategory && (
        <ListItem
          label={translate(
            `portal.documents.category.${activeCategory.treeType}`
          )}
          isBackButton
          onClick={handleGoBack}
        />
      )}
      {!activeCategory &&
        types?.map((t, i) => (
          <Category
            key={`${i}_${t.treeType}`}
            label={translate(`portal.documents.category.${t.treeType}`)}
            category={t}
            onClick={handleClick}
          />
        ))}
      {activeCategory?.children?.map((t, i) => (
        <Category
          key={`${i}_${t.treeType}`}
          label={translate(`portal.documents.category.${t.treeType}`)}
          category={t}
          onClick={handleClick}
        />
      ))}
    </>
  )
}

export default DocumentCategory
