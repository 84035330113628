import { InputLabel, Separator, Button, Link } from "@paudigital/wdp-components"
import React from "react"
import { Description, ButtonWrapper } from "./style"
import { actions } from "app/redux/registrationWizard/registrationWizard.actions"
import * as selectors from "app/redux/notificationSettings/notificationSettings.selectors"
import { useDispatch, useSelector } from "react-redux"
import NotificationSetting from "../../../NotificationSetting"
import { useTranslate } from "hooks/translate"
import { upsertNotifications } from "app/redux/notificationSettings/notificationSettings.actions"
import { useApi } from "hooks/api"
import LanguageSelect from "app/components/LanguageSelect"
import { getPreferredLanguage } from "app/redux/me/me.selectors"

const CommunicationPreferences = () => {
  const { translate } = useTranslate()
  const { call } = useApi()
  const dispatch = useDispatch()
  const notificationSettings = useSelector(selectors.getNotificationsSettings)
  const rentedBuildingNotificationSettings = useSelector(
    selectors.getRentedBuildingNotificationsSettings
  )
  const preferredLanguage = useSelector(getPreferredLanguage)

  const handleNext = () => {
    if (rentedBuildingNotificationSettings && notificationSettings) {
      call(upsertNotifications(notificationSettings))
    }
  }

  const handlePrevious = () => {
    dispatch(actions.decreaseWizard())
  }

  const buildNotificationSettings = Object.keys(
    rentedBuildingNotificationSettings
  ).map((rentedBuildingId: string, i: number) => {
    const preference = rentedBuildingNotificationSettings[rentedBuildingId]
    return (
      <React.Fragment key={rentedBuildingId}>
        <NotificationSetting
          rentedBuildingId={rentedBuildingId}
          settingsData={preference}
          collapsed={i === 0}
        />
        {i !== Object.keys(rentedBuildingNotificationSettings).length - 1 && (
          <Separator />
        )}
      </React.Fragment>
    )
  })

  return (
    <>
      <Description dataTestId="portal.onboarding.step3.title.label">
        <InputLabel
          required
          name="header"
          label={translate("portal.onboarding.step3.title.label")}
          guidanceText={translate("portal.onboarding.step3.title.guidance")}
        />
      </Description>
      {rentedBuildingNotificationSettings && buildNotificationSettings}
      <LanguageSelect
        defaultLanguage={preferredLanguage}
        label={translate("portal.preferred.language.select.label")}
      />
      <ButtonWrapper>
        <Link
          type="default"
          onClick={handlePrevious}
          value={translate("portal.onboarding.step3.button.previous")}
        />
        <Button
          label={translate("portal.onboarding.step3.button.next")}
          onClick={handleNext}
          dataTestId="portal.onboarding.step3.button.next"
        />
      </ButtonWrapper>
    </>
  )
}

export default CommunicationPreferences
