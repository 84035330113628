import { StyledGridStatus, StyledDot, StyledStatusTitle } from "./style"
import { FacilityTicketStatus as FacilityTicketStatusEnum } from "app/enums/ticketStatus"
import { colorMapping } from "./config"
import { useTranslate } from "hooks/translate"
import { Ticket } from "app/types/ticket"
import { addDays, format, isValid } from "date-fns"

type Props = {
  ticket: Ticket
  className?: string
}

const TicketStatus = ({ ticket, className }: Props) => {
  const { translate } = useTranslate()
  const {
    status,
    executionDate,
    modificationDate,
    allowFeedback
  } = ticket

  const dateFormatShort = (date: Date) => {
    const datestringShort = new Date(date)
    if (!isValid(datestringShort)) return
    return format(datestringShort, "dd-MM-yyyy")
  }

  const feedbackTime = (date: Date) => {
    const dateFinished = new Date(date)
    const dateDeadlineFeedback = addDays(dateFinished, 2)
    if (!isValid(dateDeadlineFeedback)) return
    return format(dateDeadlineFeedback, "dd-MM-yyyy HH:mm")
  }

  const executionHour = (date: Date) => {
    const datestringLong = new Date(date)
    if (!isValid(datestringLong)) return
    return format(datestringLong, "hh:mm")
  }

  const getTranslation = () => {
    if (status === FacilityTicketStatusEnum.FINISHED) {
      if (!allowFeedback) {
        if (executionDate) {
          return translate(
            "portal.facilitymanagement.ticket.status.finished.execution",
            {
              executionDate: dateFormatShort(executionDate)
            }
          )
        }
        return translate("portal.facilitymanagement.ticket.status.finished")
      } else {
        return translate(
          `portal.facilitymanagement.ticket.status.${status}.feedback`,
          {
            dateShort: dateFormatShort(modificationDate),
            feedbackDeadline: feedbackTime(modificationDate)
          }
        )
      }
    }
    return translate(`portal.facilitymanagement.ticket.status.${status}`, {
      date: dateFormatShort(modificationDate),
      dateShort: dateFormatShort(modificationDate),
      dateTime: executionHour(modificationDate),
      feedbackDeadline: feedbackTime(modificationDate)
    })
  }
  return (
    <StyledGridStatus status={status} feedback={allowFeedback} className={className}>
      <StyledDot fill={colorMapping[status]} className={className}/>
      <StyledStatusTitle className={className}>{getTranslation()}</StyledStatusTitle>
    </StyledGridStatus>
  )
}
export default TicketStatus

