export enum ModifiedByEnum {
  INGESTION = "ingestion",
  END_USER = "endUser"
}

export type Document = {
  id: string
  sourceId?: string
  metadata: DocumentMetaData
  fileName: string
  type: string
  treeType: string
  documentType: string
  topDocumentType: string
  uploadDate: string
  createdBy: string
  uploadedBy: string
  creationDate: Date
  modificationDate: Date
  contractId?: string
  buildingId: string
  modifiedBy: ModifiedByEnum
  contractStartDate?: string
  contractEndDate?: string
  documentTreeType?: string
  topCategory?: string
  personId?: string
  firstName?: string
  lastName?: string
  documentContentType: ContentTypes
}

export enum ContentTypes {
  WDP_CONTRACT_RELATED_DOCUMENT = "WDP Contract Related Document",
  WDP_CONTRACTOR_DOCUMENT = "WDP Contractor Document",
  WDP_BUILDING_DOCUMENT = "WDP Building Document",
  WDP_BUILDING_PICTURE = "WDP Building Picture",
  WDP_DOCUMENT = "WDP Document",
  WDP_INDEX_LETTER = "WDP Index Letter",
  WDP_PURCHASE_INVOICE = "WDP Purchase Invoice",
  WDP_SALES_INVOICE = "WDP Sales Invoice"
}

export type DocumentResponse = {
  total: number
  documents: Document[]
  filters: DocumentsFiltersDto
  documentStatistics: DocumentStatisticsDto[]
}

export type DocumentsFiltersDto = {
  pages: number
  page: number
  perPage: number
  documentType?: string
  uploadDateFrom?: string
  uploadDateTo?: string
  inspectionDateFrom?: string
  inspectionDateTo?: string
}

export class DocumentStatisticsDto {
  document_type: string
  count: number
  children: DocumentStatisticsDto[]
}

export type PostDocument = {
  fileName: string
  metadata: DocumentMetaData
  documentTypeId: string
  upfile: File[]
  treeType?: string
  isParkRelated: "true" | "false"
}

export type SetDocumentFieldType = {
  fileName: string
  metadata: DocumentMetaData
  documentTypeId: string
  upfile: File[]
  treeType?: string
  secondaryTypeIds: string[]
}

export type DocumentMetaData = {
  Title?: string
  Document_x0020_Language?: string
  Document_x0020_date?: string
  Contract_x0020_number?: string
}
