import { useState } from "react"
import { SlideIn } from "@paudigital/wdp-components"
import { IntervalEnum } from "app/enums/interval"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { TagEnum } from "app/redux/graphs/graphs.actions"
import Filters from "./Filters"
import { useDispatch, useSelector } from "react-redux"
import {
  StyledFormWrapper,
  ModalTitle,
  Container,
  ModalSubTitle
} from "./style"
import { useTranslate } from "hooks/translate"
import { downloadEnergyCSV } from "app/redux/energyCSV/energyCSV.actions"
import { useApi } from "hooks/api"
import {
  isLoading,
  selectedMeters
} from "app/redux/energyCSV/energyCSV.selectors"
import ExportButton from "../DownloadFilters/ExportButton"
import { getEnergyPeriodRange } from "app/redux/energyPeriod/energyPeriod.selectors"

export type DownloadFilters = {
  to: string | undefined
  from: string | undefined
  interval: IntervalEnum
  tags: TagEnum[]
}

export const EnergyCSVDownload = () => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const loading = useSelector(isLoading)
  const selected = useSelector(selectedMeters)
  const energyPeriodRange = useSelector(getEnergyPeriodRange)

  const dispatch = useDispatch()
  const [downloadFilters, setDownloadFilters] = useState<DownloadFilters>({
    to: energyPeriodRange.to,
    from: energyPeriodRange.from,
    interval: IntervalEnum.DAY,
    tags: [...selected]
  })

  const handleClose = () => {
    dispatch(remove("csv-energy"))
  }

  const handleEnergyCSVDownload = () => {
    call(downloadEnergyCSV(downloadFilters))
  }

  const isButtonDisabled = () => {
    return (
      downloadFilters.tags.length === 0 ||
      !downloadFilters.to ||
      !downloadFilters.from ||
      loading
    )
  }

  return (
    <SlideIn show onClose={handleClose} showCloseIcon>
      <StyledFormWrapper>
        <Container>
          <ModalTitle>
            {translate("portal.energy.export.overview.title")}
          </ModalTitle>
          <ModalSubTitle>
            {translate("portal.energy.export.overview.subtitle")}
          </ModalSubTitle>
          <Filters
            downloadFilters={downloadFilters}
            setDownloadFilters={setDownloadFilters}
          />
          <ExportButton
            disabled={isButtonDisabled()}
            onClick={handleEnergyCSVDownload}
            label={translate("portal.energy.export.overview.button")}
          />
        </Container>
      </StyledFormWrapper>
    </SlideIn>
  )
}

export default EnergyCSVDownload
