import { StyledLegendWrapper } from "./style"
import { StyledAnchor } from "../style"
import Legend from "../../../../Legend"
import { Expand } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { GraphOptions } from "app/utils/graphs/graph.utils"

export type LegendWrapperProps = {
  chartRef: React.MutableRefObject<any>
  graphData: GraphOptions
}

type Props = LegendWrapperProps

const LegendWrapper = ({ chartRef, graphData }: Props) => {
  const { translate } = useTranslate()

  const showFullscreen = () => {
    chartRef.current.chart.fullscreen.toggle()
  }

  return (
    <StyledLegendWrapper>
      <Legend items={graphData.legendItems}></Legend>
      <StyledAnchor onClick={showFullscreen}>
        <Expand />
        {translate("portal.energy.graphs.fullscreen")}
      </StyledAnchor>
    </StyledLegendWrapper>
  )
}

export default LegendWrapper
