import { TicketSummary } from "app/types/ticketSummary"
import {
  PostTicket,
  AnswerCode,
  TicketsForRentedBuilding,
  FacilityTicketStatus,
  TicketTypes
} from "app/types/ticket"
import { Filters } from "routes/FacilityManagement"

export enum Types {
  GET = "GET_TICKETS",
  GET_SUCCESS = "GET_TICKETS_SUCCESS",
  GET_FAIL = "GET_TICKETS_FAIL",
  DISCARD = "TICKET_DISCARD",
  POST = "TICKET_POST",
  POST_SUCCESS = "TICKET_POST_SUCCESS",
  POST_FAIL = "TICKET_POST_FAIL",
  SET_FIELD = "TICKET_SET_FIELD",
  SET_TICKET = "TICKET_SET",
  POST_ATTACHMENTS = "TICKET_POST_ATTACHMENTS",
  POST_ATTACHMENTS_SUCCESS = "TICKET_POST_ATTACHMENTS_SUCCESS",
  POST_ATTACHMENTS_FAIL = "TICKET_POST_ATTACHMENTS_FAIL",
  GET_SUMMARY = "TICKET_GET_SUMMARY",
  GET_SUMMARY_SUCCESS = "TICKET_GET_SUMMARY_SUCCESS",
  GET_SUMMARY_FAIL = "TICKET_GET_SUMMARY_FAIL",
  GET_TICKET_ATTACHMENTS = "TICKET_GET_TICKET_ATTACHMENTS",
  GET_TICKET_ATTACHMENTS_SUCCESS = "TICKET_GET_TICKET_ATTACHMENTS_SUCCESS",
  GET_TICKET_ATTACHMENTS_FAIL = "TICKET_GET_TICKET_ATTACHMENTS_FAIL",
  GET_SELECTED_TICKETS_FILTERS = "GET_SELECTED_TICKETS_FILTERS",
  DOWNLOAD_CSV = "DOWNLOAD_TICKET_CSV",
  DOWNLOAD_CSV_SUCCESS = "DOWNLOAD_TICKET_CSV_SUCCESS",
  DOWNLOAD_CSV_FAIL = "DOWNLOAD_TICKET_CSV_FAIL",
  DOWNLOAD = "DOWNLOAD",
  DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS",
  DOWNLOAD_FAIL = "DOWNLOAD_FAIL",
  CANCEL_TICKET = "CANCEL_TICKET",
  CANCEL_TICKET_SUCCESS = "CANCEL_TICKET_SUCCESS",
  CANCEL_TICKET_FAIL = "CANCEL_TICKET_FAIL"
}

export type QueryParams = {
  rentedBuildingId: string
  page: number
  perPage: number
  status?: FacilityTicketStatus[]
  type?: TicketTypes[]
  from?: string
  to?: string
  ticket?: string
  description?: string
  sort?: string[]
  modalOpen?: string
}

export type Action =
  | GetTicketsAction
  | GetTicketsSuccessAction
  | GetTicketsFailAction
  | DiscardTicketAction
  | PostTicketAction
  | PostTicketSuccessAction
  | PostTicketFailAction
  | PostTicketAttachmentsAction
  | PostTicketAttachmentsSuccessAction
  | PostTicketAttachmentsFailAction
  | SetTicketFieldAction
  | SetTicketAction
  | GetTicketSummaryAction
  | GetTicketSummarySuccessAction
  | GetTicketSummaryFailAction
  | GetTicketAttachmentsAction
  | GetTicketAttachmentsSuccessAction
  | GetTicketAttachmentsFailAction
  | GetSelectedTicketsFiltersAction
  | DownloadTicketCSVAction
  | DownloadTicketCSVSuccessAction
  | DownloadTicketCSVFailAction
  | DownloadTicketAction
  | DownloadTicketSuccessAction
  | DownloadTicketFailAction
  | CancelTicketAction
  | CancelTicketSuccessAction
  | CancelTicketFailAction

export type GetTicketsAction = {
  payload: QueryParams
  type: Types.GET
}

export type GetTicketsSuccessAction = {
  payload: { data: TicketsForRentedBuilding }
  type: Types.GET_SUCCESS
}

export type GetTicketsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type DiscardTicketAction = {
  type: Types.DISCARD
}

export type PostTicketAction = {
  payload: { rentedBuildingId: string; data: Partial<PostTicket> }
  type: Types.POST
}

export type PostTicketSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostTicketFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.POST_FAIL
}

export type PostTicketAttachmentsAction = {
  payload: {
    rentedBuildingId: string
    id: string
    files: File[]
  }
  type: Types.POST_ATTACHMENTS
}

export type PostTicketAttachmentsSuccessAction = {
  type: Types.POST_ATTACHMENTS_SUCCESS
}

export type PostTicketAttachmentsFailAction = {
  payload: { errors: object }
  type: Types.POST_ATTACHMENTS_FAIL
}

export type DiscardTicket = {
  payload: { errors: object }
  type: Types.DISCARD
}

export type SetTicketFieldAction = {
  payload: {
    key: string
    value: string | number | boolean | AnswerCode[] | undefined | File[]
  }
  type: Types.SET_FIELD
}

export type SetTicketAction = {
  payload: Partial<PostTicket>
  type: Types.SET_TICKET
}

export type GetTicketSummaryAction = {
  payload: { id: string }
  type: Types.GET_SUMMARY
}

export type GetTicketSummarySuccessAction = {
  payload: TicketSummary
  type: Types.GET_SUMMARY_SUCCESS
}

export type GetTicketSummaryFailAction = {
  payload: { errors: object }
  type: Types.GET_SUMMARY_FAIL
}

export type GetTicketAttachmentsAction = {
  payload: { rentedBuildingId: string; ticketId: string }
  type: Types.GET_TICKET_ATTACHMENTS
}

export type GetTicketAttachmentsSuccessAction = {
  type: Types.GET_TICKET_ATTACHMENTS_SUCCESS
}

export type GetTicketAttachmentsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_TICKET_ATTACHMENTS_FAIL
}

export type GetSelectedTicketsFiltersAction = {
  payload: boolean
  type: Types.GET_SELECTED_TICKETS_FILTERS
}

export type DownloadTicketCSVAction = {
  payload: { data: Filters }
  type: Types.DOWNLOAD_CSV
}

export type DownloadTicketCSVSuccessAction = {
  type: Types.DOWNLOAD_CSV_SUCCESS
}

export type DownloadTicketCSVFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DOWNLOAD_CSV_FAIL
}

export type DownloadTicketAction = {
  payload: string
  type: Types.DOWNLOAD
}

export type DownloadTicketSuccessAction = {
  type: Types.DOWNLOAD_SUCCESS
}

export type DownloadTicketFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DOWNLOAD_FAIL
}

export type CancelTicketAction = {
  payload: { ticketId: string; notification: boolean }
  type: Types.CANCEL_TICKET
}

export type CancelTicketSuccessAction = {
  type: Types.CANCEL_TICKET_SUCCESS
}

export type CancelTicketFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.CANCEL_TICKET_FAIL
}

export function getTickets(payload: QueryParams): Action {
  return {
    type: Types.GET,
    payload
  }
}

export function getTicketsSuccess(data: TicketsForRentedBuilding): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getTicketsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function discardTicket(): DiscardTicketAction {
  return {
    type: Types.DISCARD
  }
}

export function postTicket(
  rentedBuildingId: string,
  data: Partial<PostTicket>
): PostTicketAction {
  return {
    payload: { rentedBuildingId, data },
    type: Types.POST
  }
}

export function postTicketSuccess(): PostTicketSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postTicketFail(
  errors: Record<string, unknown>
): PostTicketFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function downloadTicket(id: string): Action {
  return {
    type: Types.DOWNLOAD,
    payload: id
  }
}

export function downloadTicketSuccess(): Action {
  return {
    type: Types.DOWNLOAD_SUCCESS
  }
}

export function downloadTicketFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.DOWNLOAD_FAIL
  }
}

export function getTicketAttachments(
  rentedBuildingId: string,
  ticketId: string
): GetTicketAttachmentsAction {
  return {
    payload: { rentedBuildingId, ticketId },
    type: Types.GET_TICKET_ATTACHMENTS
  }
}

export function getTicketAttachmentsSuccess(): GetTicketAttachmentsSuccessAction {
  return {
    type: Types.GET_TICKET_ATTACHMENTS_SUCCESS
  }
}

export function getTicketAttachmentsFail(
  errors: Record<string, unknown>
): GetTicketAttachmentsFailAction {
  return {
    payload: { errors },
    type: Types.GET_TICKET_ATTACHMENTS_FAIL
  }
}

export function postTicketAttachments(payload: {
  rentedBuildingId: string
  id: string
  files: File[]
}): PostTicketAttachmentsAction {
  return {
    payload,
    type: Types.POST_ATTACHMENTS
  }
}

export function postTicketAttachmentsSuccess(): PostTicketAttachmentsSuccessAction {
  return {
    type: Types.POST_ATTACHMENTS_SUCCESS
  }
}

export function postTicketAttachmentsFail(
  errors: object
): PostTicketAttachmentsFailAction {
  return {
    payload: { errors },
    type: Types.POST_ATTACHMENTS_FAIL
  }
}

export function setTicketField(
  key: keyof PostTicket,
  value: string | number | boolean | AnswerCode[] | undefined | File[]
): SetTicketFieldAction {
  return {
    payload: { key, value },
    type: Types.SET_FIELD
  }
}

export function setTicket(ticket: Partial<PostTicket>): SetTicketAction {
  return {
    payload: ticket,
    type: Types.SET_TICKET
  }
}

export function getTicketSummary(id: string): GetTicketSummaryAction {
  return {
    payload: { id },
    type: Types.GET_SUMMARY
  }
}

export function getTicketSummarySuccess(
  payload: TicketSummary
): GetTicketSummarySuccessAction {
  return {
    payload,
    type: Types.GET_SUMMARY_SUCCESS
  }
}

export function getTicketSummaryFail(
  errors: Record<string, unknown>
): GetTicketSummaryFailAction {
  return {
    payload: { errors },
    type: Types.GET_SUMMARY_FAIL
  }
}

export function getSelectedTicketsFilters(
  isOpen: boolean
): GetSelectedTicketsFiltersAction {
  return {
    payload: isOpen,
    type: Types.GET_SELECTED_TICKETS_FILTERS
  }
}

export function downloadTicketsCSV(data: Filters): Action {
  return {
    payload: { data },
    type: Types.DOWNLOAD_CSV
  }
}

export function downloadTicketsCSVSuccess(): Action {
  return {
    type: Types.DOWNLOAD_CSV_SUCCESS
  }
}

export function downloadTicketsCSVFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.DOWNLOAD_CSV_FAIL
  }
}

export function cancelTicket(ticketId: string, notification: boolean): Action {
  return {
    payload: { ticketId, notification },
    type: Types.CANCEL_TICKET
  }
}

export function cancelTicketSuccess(): Action {
  return {
    type: Types.CANCEL_TICKET_SUCCESS
  }
}

export function cancelTicketFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.CANCEL_TICKET_FAIL
  }
}
