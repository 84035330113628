import { LoginIcon, Page } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useNavigate } from "react-router"
import { StyledWrapper, Styledtitle, StyledBody, StyledButton } from "./style"

const UnblockPage = () => {
  const image = <LoginIcon width="241" height="167" />
  const navigate = useNavigate()
  const { translate } = useTranslate()

  const redirectLogin = () => {
    navigate("/login")
  }

  return (
    <Page image={image}>
      <StyledWrapper>
        <Styledtitle>{translate("portal.account.unblocked.title")}</Styledtitle>
        <StyledBody>{translate("portal.account.unblocked.message")}</StyledBody>
        <StyledBody>
          {translate("portal.account.unblocked.link.message")}
        </StyledBody>
      </StyledWrapper>

      <StyledButton
        type="primary"
        label={translate("portal.account.unblocked.button")}
        onClick={redirectLogin}
        fullWidth
      />
    </Page>
  )
}

export default UnblockPage
