import React from "react"
import { useTranslate } from "hooks/translate"
import {
  BuildingCheckboxWrapper,
  BuildingSelectWrapper,
  DeleteModalTextBold,
  DeleteModalTextLight,
  FullWidthWrapper
} from "../style"
import {
  BaseDeleteModal,
  Checkbox,
  StaticNotification
} from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import * as deleteCpSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { deleteContactPerson } from "app/redux/deleteContactPerson/deleteContactPerson.actions"
import { isCpSoleAdminOnAssignedBuilding } from "app/utils/deleteContactPerson.utils"
import { useApi } from "hooks/api"
import { useRentedBuilding } from "hooks/rentedBuilding"

type CheckBoxOptionProps = {
  key: string
  name: string
  checked: boolean
  label: string
  guidanceText: string
  disabled: boolean
}

type RevokeAccessMultiBuildingModalProps = {
  onCancel: () => void
}

type Props = RevokeAccessMultiBuildingModalProps

const RevokeAccessMultiBuildingModal = ({ onCancel }: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const [buildingsToDeleteFrom, setBuildingsToDeleteFrom] = React.useState<
    string[]
  >([])
  const { rentedBuilding } = useRentedBuilding()
  const email = useSelector(deleteCpSelectors.getEmailOfContactPerson)
  const contactPersonId = useSelector(deleteCpSelectors.getIdOfContactPerson)
  const isLoading = useSelector(deleteCpSelectors.isLoading)
  const cpBuildingsToDeleteFrom = useSelector(
    deleteCpSelectors.getBuildingsCpCanBeDeletedFrom
  )
  const otherAdmins = useSelector(
    deleteCpSelectors.getBuildingAdminNamesAndEmailsFromNonManagedBuildings
  )

  const checkboxData = cpBuildingsToDeleteFrom.reduce((data, building) => {
    const contactPerson = building.contactPersons.find(
      cp => cp.id === contactPersonId
    )
    if (!contactPerson) return data
    return [
      ...data,
      {
        key: building.id,
        name: building.id,
        checked: buildingsToDeleteFrom.includes(building.id),
        label: `${building.street} ${building.houseNo}, ${building.city}`,
        guidanceText: contactPerson.roles.join(", "),
        disabled: isCpSoleAdminOnAssignedBuilding(contactPerson.id, building)
      }
    ]
  }, [] as CheckBoxOptionProps[])

  const onDelete = () => {
    if (
      !contactPersonId ||
      !rentedBuilding ||
      buildingsToDeleteFrom.length === 0
    )
      return
    call(
      deleteContactPerson(
        contactPersonId,
        buildingsToDeleteFrom,
        true
      )
    )
  }

  const handleCheckboxClick = (val: boolean, name: string) => {
    setBuildingsToDeleteFrom(
      buildingsToDeleteFrom.includes(name)
        ? buildingsToDeleteFrom.filter(building => building !== name)
        : [...buildingsToDeleteFrom, name]
    )
  }

  return (
    <BaseDeleteModal
      title={translate("portal.admin.delete.seat.restrict.access")}
      cancelBtnText={translate("portal.admin.delete.seat.cancel")}
      deleteBtnText={
        buildingsToDeleteFrom.length > 1 || buildingsToDeleteFrom.length === 0
          ? translate("portal.admin.delete.seat.revoke.account.multi", {
              amount: buildingsToDeleteFrom.length
            })
          : translate("portal.admin.delete.seat.revoke.account.single")
      }
      deleteDisabled={
        isLoading ||
        contactPersonId === undefined ||
        buildingsToDeleteFrom.length === 0
      }
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <>
        <DeleteModalTextBold marginBottom="1.6rem">
          {translate("portal.admin.delete.seat.restrict.access.multi.action", {
            email
          })}
        </DeleteModalTextBold>
        <DeleteModalTextLight marginBottom="2.4rem">
          {translate(
            "portal.admin.delete.seat.restrict.access.multi.description"
          )}
        </DeleteModalTextLight>
        <BuildingSelectWrapper>
          {checkboxData.map(data => {
            return (
              <BuildingCheckboxWrapper key={data.key}>
                <Checkbox
                  key={data.key}
                  name={data.name}
                  checked={data.checked}
                  label={data.label}
                  guidanceText={data.guidanceText}
                  disabled={data.disabled}
                  onClick={handleCheckboxClick}
                  tabIndex={0}
                  hasSpacing={true}
                />
              </BuildingCheckboxWrapper>
            )
          })}
        </BuildingSelectWrapper>
        <FullWidthWrapper>
          <StaticNotification
            description={translate(
              "portal.admin.delete.seat.restrict.access.multi.notice",
              { admins: otherAdmins }
            )}
            variant="warning"
          />
        </FullWidthWrapper>
      </>
    </BaseDeleteModal>
  )
}

export default RevokeAccessMultiBuildingModal
