import { PhoneIcon, EmailIcon } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import {
  Option,
  ContactOptions,
  ContactButton,
  OptionCaption,
  OptionCaptionTitle,
  OptionCaptionText
} from "./style"

type Props = {
  phone?: string
  email?: string
}

const ExpertContactOptions = ({ phone, email }: Props) => {
  const { translate } = useTranslate()
  const splitEmail = email ? email.split("@") : null

  return (
    <ContactOptions hasPhone={phone && phone.toString()}>
      {phone && (
        <Option href={`tel:${phone}`}>
          <ContactButton data-testid="contact-phone">
            <PhoneIcon />
          </ContactButton>
          <OptionCaption>
            <OptionCaptionTitle>
              {translate(
                "portal.dashboard.widget.expert.contact.phone.caption"
              )}
            </OptionCaptionTitle>
            <OptionCaptionText>
              {formatPhoneNumberIntl(phone)}
            </OptionCaptionText>
          </OptionCaption>
        </Option>
      )}
      {email && (
        <Option href={`mailto:${email}`}>
          <ContactButton data-testid="contact-email">
            <EmailIcon />
          </ContactButton>
          <OptionCaption>
            <OptionCaptionTitle>
              {translate(
                "portal.dashboard.widget.expert.contact.email.caption"
              )}
            </OptionCaptionTitle>
            {splitEmail?.map((part, i) => (
              <OptionCaptionText key={i}>
                {(++i === splitEmail.length ? "@" : "") + part}
              </OptionCaptionText>
            ))}
          </OptionCaption>
        </Option>
      )}
    </ContactOptions>
  )
}

export default ExpertContactOptions
