import { Button, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 2.8rem 0px;
  grid-template-areas:
    "specification"
    "contract"
    "location"
    "contact";

  align-items: flex-start;
  margin-bottom: 7rem;
`

export const ContractGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "specification"
    "contract"
    "location"
    "contact";

  align-items: flex-start;
  margin-bottom: 1.8rem;

  & > section {
    margin-bottom: 2.4rem;

    &:last-child {
      margin-bottom: 0rem;
    }
  }
`

export const ContractRentalPeriodWrapper = styled.div`
  padding-top: 0.4rem;
`

export const ContractRentalPeriodText = styled.span`
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
  padding-right: 0.4rem;
`

export const ContractRentalPeriod = styled.span`
  font-size: ${theme.fontSizes.captionExtraSmall};
  font-weight: ${theme.fontWeights.regular};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
`

export const SelectContractText = styled.h3`
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 1.2rem;
`

export const HorizontalRule = styled.div`
  background: ${theme.colors.concrete.prc25};
  height: 1px;
  margin: 1.6rem 0;
`

export const StyledButton = styled(Button)`
  margin: 1.6rem 0;
`
