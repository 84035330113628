import { RentedBuildingContactPerson } from "app/types/rentedBuildingContactPerson"

export enum Types {
  GET = "GET_CONTACT_PERSONS_FOR_RENTED_BUILDINGS",
  GET_SUCCESS = "GET_CONTACT_PERSONS_FOR_RENTED_BUILDINGS_SUCCESS",
  GET_FAIL = "GET_CONTACT_PERSONS_FOR_RENTED_BUILDINGS_FAIL"
}

export type Action =
  | GetContactPersonsForRentedBuildingAction
  | GetContactPersonsForRentedBuildingSuccessAction
  | GetContactPersonsForRentedBuildingFailAction

export type GetContactPersonsForRentedBuildingAction = {
  payload: { rentedBuildingId: string }
  type: Types.GET
}

export type GetContactPersonsForRentedBuildingSuccessAction = {
  payload: { data: RentedBuildingContactPerson[] }
  type: Types.GET_SUCCESS
}

export type GetContactPersonsForRentedBuildingFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export function getContactpersons(rentedBuildingId: string): Action {
  return {
    type: Types.GET,
    payload: { rentedBuildingId }
  }
}

export function getRentedBuildingContactPersonsSuccess(data: RentedBuildingContactPerson[]): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getRentedBuildingContactpersonsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
