import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const TimeLineWrapper = styled.div`
  margin: 2rem 2.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 2rem auto;
  }
`
