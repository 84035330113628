import { Checkbox } from "@paudigital/wdp-components"
import { InvoiceType } from "app/types/invoice"
import { useTranslate } from "hooks/translate"
import { StyledStatus, StatusText } from "./style"

export type TypeProps = {
  type: InvoiceType
  checked: boolean
  onClick: (types: InvoiceType) => void
}

type Props = TypeProps

const Type = ({ type, checked, onClick }: Props) => {
  const { translate } = useTranslate()

  const handleClick = () => {
    onClick(type)
  }

  return (
    <StyledStatus onClick={handleClick}>
      <Checkbox checked={checked} onClick={handleClick} />
      <StatusText>
        {translate(`portal.invoices.filter.type.${type}`)}
      </StatusText>
    </StyledStatus>
  )
}

export default Type
