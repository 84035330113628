import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  margin: 3.2rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
`

export const DescriptionContainer = styled.div`
  font-size: ${theme.fontSizes.paragraph};
  line-heights: ${theme.lineHeights.paragraph};
  color: ${theme.colors.neutral.darkerGrey};
`

export const TypographyWrapper = styled.div`
  margin-bottom: 2.4rem;
`
