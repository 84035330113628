import { useDispatch } from "react-redux"
import { AnyAction } from "redux"
import React, { useCallback } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Timeframe } from "app/enums/timeframe"

export const useApi = () => {
  const { logout, isAuthenticated } = useAuth0()
  const dispatch = useDispatch()

  const resetSession = useCallback(() => {
    sessionStorage.setItem("sessionEnd", `${Date.now() + Timeframe.MIN_30}`)
    return
  }, [])

  React.useEffect(() => {
    if (process.env.NODE_ENV === "test") return
    const checkSessionEnd = setInterval(() => {
      const sessionEndTimestamp = sessionStorage.getItem("sessionEnd")
      if (!sessionEndTimestamp) {
        resetSession()
        return
      }
      if (Date.now() > parseInt(sessionEndTimestamp)) {
        logout()
      }
    }, Timeframe.ONE_MINUTE)
    return () => clearInterval(checkSessionEnd)
  }, [logout, resetSession])

  const call = React.useCallback(
    (action: AnyAction) => {
      if (process.env.NODE_ENV === "test") return dispatch(action)
      if (isAuthenticated) {
        resetSession()
        return dispatch(action)
      }
    },
    [resetSession, dispatch, isAuthenticated]
  )

  return { call }
}
