import Root from "./Root"
import Register from "./Register"
import RegisterSuccess from "./RegisterSuccess"
import Logout from "./Logout"
import Dashboard from "./Dashboard"
import { RouteObject } from "./router"
import { Modules } from "app/enums/modules"
import OverviewPageHeader from "app/components/PageHeaders/OverviewPageHeader"
import AdminPageHeader from "app/components/PageHeaders/AdminPageHeader"
import Admin from "./Admin"
import Profile from "./Profile"
import ProfilePageHeader from "app/components/PageHeaders/ProfilePageHeader"
import BuildingPassport from "./BuildingPassport"
import BuildingPassportPageHeader from "app/components/PageHeaders/BuildingPassportPageHeader"
import NotFound from "./NotFound"
import FacilityManagement from "./FacilityManagement"
import Energy from "./Energy"
import EnergyPageHeader from "app/components/PageHeaders/EnergyPageHeader"
import FacilityManagementPageHeader from "app/components/PageHeaders/FacilityManagementPageHeader"
import LoggedOut from "./LoggedOut"
import Invoices from "./Invoices"
import InvoicesPageHeader from "app/components/PageHeaders/InvoicesPageHeader"
import DocumentsPageHeader from "app/components/PageHeaders/DocumentsPageHeader"
import Documents from "./Documents"
import DocumentDownload from "./DocumentDownload"
import NoAccess from "./NoAccess"
import NoPermission from "./NoPermission"
import AccountUnblocked from "./AccountUnblocked"
import TicketDownload from "./TicketDownload"
import NotificationsPageHeader from "app/components/PageHeaders/NotificationsPageHeader"
import Notifications from "app/components/Notifications"
import Error from "./Error"
import Faq from "./Faq"
import FaqPageHeader from "app/components/PageHeaders/FaqPageHeader"
import PasswordChange from "./PasswordChange"
import WdpAdminRoot from "./WdpAdminRoot"
import AdminNotFound from "./AdminNotFound"

export const NOT_FOUND_PATH = "*"

const routes: RouteObject[] = [
  {
    component: Root,
    hasAuthentication: true,
    path: "/",
    title: "portal.route.title.root"
  },
  {
    component: WdpAdminRoot,
    hasAuthentication: true,
    path: "/wdp-admin",
    title: "portal.route.title.admin"
  },
  {
    component: AdminNotFound,
    hasAuthentication: false,
    path: "/wdp-admin-not-found",
    title: "portal.route.title.admin.not.found"
  },
  {
    component: Register,
    hasAuthentication: true,
    path: "/register",
    title: "portal.route.title.register.profile"
  },
  {
    component: RegisterSuccess,
    hasAuthentication: true,
    path: "/register/success",
    title: "portal.route.title.register-success"
  },
  {
    component: Logout,
    hasAuthentication: false,
    path: "/logout"
  },
  {
    component: LoggedOut,
    hasAuthentication: false,
    path: "/logged-out"
  },
  {
    component: AccountUnblocked,
    hasAuthentication: false,
    path: "/account-unblocked",
    title: "portal.route.title.account-unblocked"
  },
  {
    component: Dashboard,
    hasAuthentication: true,
    path: "/dashboard/:id",
    pageheader: <OverviewPageHeader />,
    module: Modules.DASHBOARD,
    title: "portal.route.title.dashboard"
  },
  {
    component: Admin,
    hasAuthentication: true,
    path: "/user-management",
    pageheader: <AdminPageHeader />,
    pullContent: true,
    module: Modules.USER_MANAGEMENT,
    title: "portal.route.title.user-management"
  },
  {
    component: Profile,
    hasAuthentication: true,
    path: "/account",
    pullContent: true,
    pageheader: <ProfilePageHeader />,
    module: Modules.ACCOUNT,
    title: "portal.route.title.account"
  },
  {
    component: BuildingPassport,
    hasAuthentication: true,
    path: "/building-passport/:id",
    pullContent: true,
    pageheader: <BuildingPassportPageHeader />,
    module: Modules.FACILITY_MANAGEMENT,
    title: "portal.route.title.building-passport"
  },
  {
    component: FacilityManagement,
    hasAuthentication: true,
    path: "/facility-management/:id",
    pullContent: true,
    pageheader: <FacilityManagementPageHeader />,
    module: Modules.FACILITY_MANAGEMENT,
    title: "portal.route.title.facility-management"
  },
  {
    component: Energy,
    hasAuthentication: true,
    path: "/energy/:id",
    pullContent: false,
    pageheader: <EnergyPageHeader />,
    module: Modules.ENERGY,
    title: "portal.route.title.energy"
  },
  {
    component: Invoices,
    hasAuthentication: true,
    path: "/invoices/:id",
    pullContent: false,
    pageheader: <InvoicesPageHeader />,
    module: Modules.INVOICES,
    title: "portal.route.title.invoices"
  },
  {
    component: Documents,
    hasAuthentication: true,
    path: "/documents/:id",
    pullContent: true,
    pageheader: <DocumentsPageHeader />,
    module: Modules.DOCUMENTS,
    title: "portal.route.title.documents"
  },
  {
    component: Notifications,
    hasAuthentication: true,
    path: "/notifications/:id",
    pullContent: true,
    pageheader: <NotificationsPageHeader />,
    module: Modules.NOTIFICATIONS,
    title: "portal.route.title.notifications"
  },
  {
    component: DocumentDownload,
    hasAuthentication: true,
    path: "/documents/:id/download",
    module: Modules.DOCUMENTS,
    title: "portal.route.title.documents"
  },
  {
    component: TicketDownload,
    hasAuthentication: true,
    path: "/tickets/:id/download",
    module: Modules.FACILITY_MANAGEMENT
  },
  {
    component: Faq,
    hasAuthentication: true,
    path: "/help",
    pageheader: <FaqPageHeader />,
    pullContent: true,
    module: Modules.FAQ,
    title: "portal.route.title.faq"
  },
  {
    component: NoAccess,
    hasAuthentication: true,
    path: "/no-access",
    title: "portal.route.title.no-access"
  },
  {
    component: NoPermission,
    hasAuthentication: true,
    path: "/no-permission",
    title: "portal.route.title.no-permission"
  },
  {
    component: Error,
    hasAuthentication: false,
    path: "/error"
  },
  {
    component: PasswordChange,
    hasAuthentication: true,
    path: "/password-change",
    title: "portal.route.title.password-change"
  },
  {
    component: NotFound,
    hasAuthentication: true,
    path: NOT_FOUND_PATH,
    title: "portal.route.title.not-found"
  }
]

export default routes
