import React from "react"
import { BodyWrapper } from "../style"
import {
  TotalConsumptionGraphWrapper,
  GraphWrapper,
  LegendWrapper,
  LegendLabelWrapper,
  LegendIconWrapper,
  LegendItemWrapper,
  LegendIcon,
  LegendLabel,
  LegendLabelSubText,
  SolarInjectionGraphWrapper
} from "./style"
import { EnergyUsageComparisonData } from "app/types/energy"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { buildPieChartOptions } from "./pieChartOptions"
import { theme } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import {
  getEnergyPeriod,
  getEnergyPeriodRange
} from "app/redux/energyPeriod/energyPeriod.selectors"
import UsageComparisonBar from "app/components/DashboardWidgets/Energy/UsageComparisonBar"
import NoValue from "../NoValue"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"
import { getComparisonText } from "../utils/energyHelper.utils"
import { convertPeriodToDays } from "app/utils/periodInDays.utils"

type TotalConsumptionCardBodyProps = {
  totalConsumption: EnergyUsageComparisonData
  electricityGridConsumption?: EnergyUsageComparisonData
  solarConsumption?: EnergyUsageComparisonData
  solarInjection?: EnergyUsageComparisonData
}

type Props = TotalConsumptionCardBodyProps

const TotalConsumptionCardBody = ({
  totalConsumption,
  electricityGridConsumption,
  solarConsumption,
  solarInjection
}: Props) => {
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()
  const energyPeriod = useSelector(getEnergyPeriod)
  const { from, to } = useSelector(getEnergyPeriodRange)

  const solarConsump = solarConsumption?.currentPeriodTotal
  const elGridConsump = electricityGridConsumption?.currentPeriodTotal
  const totalConsump = totalConsumption.currentPeriodTotal

  const pieChartOptions = buildPieChartOptions(
    elGridConsump ?? null,
    solarConsump ? Math.abs(solarConsump) : null
  )

  const percentageGridUsage = React.useMemo(() => {
    return !totalConsump || !elGridConsump ? (
      <NoValue />
    ) : (
      decimalFormatter((elGridConsump / totalConsump) * 100)
    )
  }, [decimalFormatter, elGridConsump, totalConsump])

  const percentageSolarUsage = React.useMemo(() => {
    return !totalConsump || !solarConsump ? (
      <NoValue />
    ) : (
      decimalFormatter((Math.abs(solarConsump) / totalConsump) * 100)
    )
  }, [decimalFormatter, solarConsump, totalConsump])

  const percentageDifferenceSolar = React.useMemo(() => {
    return !solarConsumption?.percentageDifference ? (
      <NoValue />
    ) : (
      decimalFormatter(solarConsumption.percentageDifference)
    )
  }, [decimalFormatter, solarConsumption?.percentageDifference])

  const percentageDifferenceElectricyGridConsumption = React.useMemo(() => {
    return !electricityGridConsumption?.percentageDifference ? (
      <NoValue />
    ) : (
      decimalFormatter(electricityGridConsumption.percentageDifference)
    )
  }, [decimalFormatter, electricityGridConsumption?.percentageDifference])

  const unusedSolarEnergyPercentage = React.useMemo(() => {
    if (
      !solarConsumption?.currentPeriodTotal ||
      !solarInjection?.currentPeriodTotal
    ) {
      return undefined
    }
    return Number(
      (
        (solarInjection?.currentPeriodTotal /
          (solarConsumption?.currentPeriodTotal +
            solarInjection?.currentPeriodTotal)) *
        100
      ).toFixed(2)
    )
  }, [solarConsumption?.currentPeriodTotal, solarInjection?.currentPeriodTotal])

  const days = convertPeriodToDays(from, to)

  const subtext = (diff?: number) => {
    const { text, value } = getComparisonText(energyPeriod, diff, days)
    return translate(text, value)
  }

  return (
    <BodyWrapper>
      <TotalConsumptionGraphWrapper>
        <GraphWrapper>
          <HighchartsReact
            highcharts={Highcharts}
            options={pieChartOptions}
            containerProps={{ style: { height: "8rem", width: "8rem" } }}
            immutable={true}
          />
        </GraphWrapper>
        <LegendWrapper>
          <LegendItemWrapper>
            <LegendIconWrapper>
              <LegendIcon color={theme.colors.green.contrast} />
            </LegendIconWrapper>
            <LegendLabelWrapper>
              <LegendLabel>
                <span>{percentageSolarUsage}%</span>&nbsp;
                {translate("portal.energy.comparison.type.solar")}
              </LegendLabel>
              <LegendLabelSubText>
                {solarConsumption &&
                  solarConsumption.percentageDifference &&
                  solarConsumption.percentageDifference > 0 &&
                  "+"}
                {percentageDifferenceSolar}% &nbsp;
                {subtext(solarConsumption?.percentageDifference)}
              </LegendLabelSubText>
            </LegendLabelWrapper>
          </LegendItemWrapper>
          <LegendItemWrapper>
            <LegendIconWrapper>
              <LegendIcon color={theme.colors.concrete.prc50} />
            </LegendIconWrapper>
            <LegendLabelWrapper>
              <LegendLabel>
                <span>{percentageGridUsage}%</span>&nbsp;
                {translate("portal.energy.comparison.type.grid")}
              </LegendLabel>
              <LegendLabelSubText>
                {electricityGridConsumption?.percentageDifference &&
                  electricityGridConsumption?.percentageDifference > 0 &&
                  "+"}
                {percentageDifferenceElectricyGridConsumption}% &nbsp;
                {subtext(electricityGridConsumption?.percentageDifference)}
              </LegendLabelSubText>
            </LegendLabelWrapper>
          </LegendItemWrapper>
        </LegendWrapper>
      </TotalConsumptionGraphWrapper>
      <SolarInjectionGraphWrapper>
        <UsageComparisonBar
          name="solarInjection"
          percentageDifference={unusedSolarEnergyPercentage}
          color={
            unusedSolarEnergyPercentage
              ? theme.colors.orange.prc75
              : theme.colors.neutral.darkerGrey
          }
        />
      </SolarInjectionGraphWrapper>
    </BodyWrapper>
  )
}

export default TotalConsumptionCardBody
