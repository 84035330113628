import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const InformationSectionTotal = styled.label`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
