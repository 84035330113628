import * as api from "app/api/ticketCategories"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./ticketCategories.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getTicketCategoriesFlow(): SagaIterator {
  try {
    const response = yield call(api.getTicketCategories)
    yield put(actions.getTicketCategoriesSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getTicketCategoriesFail(e))
  }
}

export default function* ticketCategoriesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getTicketCategoriesFlow)
}
