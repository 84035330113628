import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getWorkOrderAttachments = (
  rentedBuildingId: string,
  workOrderId: string
): AxiosPromise => {
  return rentalApi(
    `/rented_buildings/${rentedBuildingId}/work_orders/${workOrderId}/attachments`,
    {
      responseType: "blob",
      method: "GET"
    }
  )
}
