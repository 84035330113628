const BuildingIllustration = () => {
  return (
    <svg
      width="206"
      height="70"
      viewBox="0 0 166 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M150.369 67.7969C150.062 68.9149 149.01 69.6073 149.01 69.6073C149.01 69.6073 148.457 68.487 148.764 67.369C149.071 66.251 150.122 65.5586 150.122 65.5586C150.122 65.5586 150.675 66.6788 150.369 67.7969Z"
        fill="#3F3D56"
      />
      <path
        d="M149.917 67.5565C149.075 68.3644 148.972 69.6063 148.972 69.6063C148.972 69.6063 150.235 69.5383 151.077 68.7304C151.919 67.9226 152.022 66.6807 152.022 66.6807C152.022 66.6807 150.759 66.7486 149.917 67.5565Z"
        fill="#629282"
      />
      <path
        d="M126.997 67.7969C126.69 68.9149 125.639 69.6073 125.639 69.6073C125.639 69.6073 125.085 68.487 125.392 67.369C125.699 66.251 126.75 65.5586 126.75 65.5586C126.75 65.5586 127.304 66.6788 126.997 67.7969Z"
        fill="#3F3D56"
      />
      <path
        d="M126.545 67.5565C125.703 68.3644 125.6 69.6063 125.6 69.6063C125.6 69.6063 126.863 69.5383 127.705 68.7304C128.547 67.9226 128.65 66.6807 128.65 66.6807C128.65 66.6807 127.388 66.7486 126.545 67.5565Z"
        fill="#629282"
      />
      <path d="M1.54785 69H165.548V69.36H1.54785V69Z" fill="#3F3D56" />
      <path
        d="M147.987 27.3108H149.051V68.7422H147.987V27.3108Z"
        fill="#3F3D56"
      />
      <path
        d="M138.163 32.5908C138.096 47.0783 148.439 58.87 148.439 58.87C148.439 58.87 158.892 47.1728 158.96 32.6852C159.027 18.1977 148.684 6.40601 148.684 6.40601C148.684 6.40601 138.231 18.1033 138.163 32.5908Z"
        fill="#629282"
      />
      <path
        d="M9.82356 27.3108H10.8879V68.7422H9.82356V27.3108Z"
        fill="#3F3D56"
      />
      <path
        d="M0.000232697 32.5908C-0.0675278 47.0783 10.2757 58.87 10.2757 58.87C10.2757 58.87 20.7288 47.1728 20.7965 32.6852C20.8643 18.1977 10.5211 6.40601 10.5211 6.40601C10.5211 6.40601 0.0679874 18.1033 0.000232697 32.5908Z"
        fill="#629282"
      />
      <path
        d="M98.0249 63.7187C100.701 63.7187 102.87 59.3193 102.87 53.8923C102.87 48.4653 100.701 44.0659 98.0249 44.0659C95.3488 44.0659 93.1794 48.4653 93.1794 53.8923C93.1794 59.3193 95.3488 63.7187 98.0249 63.7187Z"
        fill="#3F3D56"
      />
      <path
        d="M97.5776 69.9766C99.5183 58.4041 97.5972 46.8733 97.5775 46.7583L97.2 46.8219C97.2196 46.9363 99.1297 58.4065 97.2 69.9142L97.5776 69.9766Z"
        fill="#CACACA"
      />
      <path
        d="M22.3933 63.7187C19.7173 63.7187 17.5479 59.3193 17.5479 53.8923C17.5479 48.4653 19.7173 44.0659 22.3933 44.0659C25.0694 44.0659 27.2388 48.4653 27.2388 53.8923C27.2388 59.3193 25.0694 63.7187 22.3933 63.7187Z"
        fill="#3F3D56"
      />
      <path
        d="M22.8406 69.9766C20.8999 58.4041 22.821 46.8733 22.8407 46.7583L23.2182 46.8219C23.1986 46.9363 21.2885 58.4065 23.2183 69.9142L22.8406 69.9766Z"
        fill="#CACACA"
      />
      <path
        d="M93.759 51.7794L93.5784 52.115L97.96 54.4444L98.1406 54.1087L93.759 51.7794Z"
        fill="#CACACA"
      />
      <path
        d="M102.584 52.9462L98.2034 55.2769L98.3841 55.6124L102.765 53.2817L102.584 52.9462Z"
        fill="#CACACA"
      />
      <path
        d="M109.667 57.5601C114.901 57.5601 119.144 48.9554 119.144 38.3408C119.144 27.7263 114.901 19.1216 109.667 19.1216C104.433 19.1216 100.19 27.7263 100.19 38.3408C100.19 48.9554 104.433 57.5601 109.667 57.5601Z"
        fill="#E6E6E6"
      />
      <path
        d="M108.612 69.7699C112.402 47.1652 108.65 24.6428 108.611 24.418L108.234 24.4815C108.272 24.7058 112.014 47.1676 108.234 69.7075L108.612 69.7699Z"
        fill="#CACACA"
      />
      <path
        d="M101.237 34.3682L101.056 34.7039L109.626 39.2599L109.807 38.9243L101.237 34.3682Z"
        fill="#CACACA"
      />
      <path
        d="M118.671 36.6506L110.102 41.209L110.283 41.5445L118.852 36.9861L118.671 36.6506Z"
        fill="#CACACA"
      />
      <path
        d="M87.6565 68.8302H37.2472C37.0618 68.83 36.884 68.7567 36.7529 68.6264C36.6218 68.4961 36.5481 68.3194 36.5479 68.1352V36.4765C36.5481 36.2923 36.6218 36.1156 36.7529 35.9853C36.884 35.855 37.0618 35.7817 37.2472 35.7815H87.6565C87.8419 35.7817 88.0196 35.855 88.1507 35.9853C88.2818 36.1156 88.3556 36.2923 88.3558 36.4765V68.1352C88.3556 68.3194 88.2818 68.4961 88.1507 68.6264C88.0196 68.7567 87.8419 68.83 87.6565 68.8302Z"
        fill="#E4E4E4"
      />
      <path
        d="M41.883 40.6177C41.8035 40.6178 41.7273 40.6492 41.6711 40.705C41.6149 40.7609 41.5833 40.8366 41.5833 40.9155V65.6958C41.5833 65.7748 41.6149 65.8505 41.6711 65.9064C41.7273 65.9622 41.8035 65.9936 41.883 65.9937H83.0207C83.1001 65.9936 83.1763 65.9622 83.2325 65.9064C83.2887 65.8505 83.3203 65.7748 83.3204 65.6958V40.9155C83.3203 40.8366 83.2887 40.7609 83.2325 40.705C83.1763 40.6492 83.1001 40.6178 83.0207 40.6177H41.883Z"
        fill="white"
      />
      <path
        d="M83.4204 47.0003V45.43H77.6028V40.5186H76.0229V45.43H65.6657V40.5186H64.0858V45.43H48.1112V40.5186H46.5313V45.43H41.0073V47.0003H46.5313V53.1069H41.0073V54.6772H46.5313V61.22H41.0073V62.7903H55.0452V66.0931H56.6251V62.7903H64.0858V66.0931H65.6657V62.7903H83.4204V61.22H65.6657V54.6772H83.4204V53.1069H77.6028V47.0003H83.4204ZM64.0858 47.0003V48.9195H48.1112V47.0003H64.0858ZM48.1112 50.4898H64.0858V53.1069H48.1112V50.4898ZM48.1112 61.22V54.6772H55.0452V61.22H48.1112ZM64.0858 61.22H56.6251V54.6772H64.0858V61.22ZM76.0229 53.1069H65.6657V47.0003H76.0229V53.1069Z"
        fill="#E6E6E6"
      />
      <path
        d="M64.8757 56.0969C65.9906 56.0969 66.8945 55.1986 66.8945 54.0904C66.8945 52.9823 65.9906 52.084 64.8757 52.084C63.7608 52.084 62.8569 52.9823 62.8569 54.0904C62.8569 55.1986 63.7608 56.0969 64.8757 56.0969Z"
        fill="#EF7F1D"
      />
      <path
        d="M65.1177 42.1714C71.9953 42.1714 77.5708 36.6299 77.5708 29.7942C77.5708 22.9585 71.9953 17.417 65.1177 17.417C58.24 17.417 52.6646 22.9585 52.6646 29.7942C52.6646 36.6299 58.24 42.1714 65.1177 42.1714Z"
        fill="#EF7F1D"
      />
      <path
        d="M65.2394 49.9145L61.3051 43.2348L57.3708 36.555L65.1583 36.5085L72.9457 36.4619L69.0926 43.1882L65.2394 49.9145Z"
        fill="#EF7F1D"
      />
      <path
        d="M65.2177 34.1569C67.6853 34.1569 69.6857 32.1687 69.6857 29.7161C69.6857 27.2636 67.6853 25.2754 65.2177 25.2754C62.7501 25.2754 60.7498 27.2636 60.7498 29.7161C60.7498 32.1687 62.7501 34.1569 65.2177 34.1569Z"
        fill="white"
      />
      <path
        d="M64.8298 0.0635984C64.8298 0.0635984 62.165 -0.682448 61.3769 2.93587C60.5887 6.55419 61.0893 6.0983 61.0893 6.0983L67.038 5.40821C67.038 5.40821 68.8268 1.16401 64.8298 0.0635984Z"
        fill="#2F2E41"
      />
      <path
        d="M60.9545 15.9373C60.9545 15.9373 56.9344 16.1222 57.6306 17.6906C57.6798 17.8015 57.7256 17.9054 57.768 18.0022C58.2656 19.1382 58.8385 20.2403 59.4827 21.301L61.9535 25.3699L62.8526 24.8735L60.3225 18.3364L62.5967 18.1519L60.9545 15.9373Z"
        fill="#FFB6B6"
      />
      <path
        d="M71.5541 17.5983L72.8536 20.4122C72.8536 20.4122 73.8283 21.3809 74.8958 25.2558C75.9633 29.1307 76.1953 28.9923 76.1953 28.9923L75.1402 29.3416L70.8115 21.5193L68.6765 18.8438L69.3263 17.4138L71.5541 17.5983Z"
        fill="#FFB6B6"
      />
      <path
        d="M69.9763 14.3693L71.8437 17.8238C71.8437 17.8238 76.0775 26.0925 75.6399 26.065C75.148 26.0341 73.7664 26.604 73.3422 27.1572C73.1374 27.4242 70.1454 20.9019 69.0944 19.4436L66.8202 17.6907C66.8202 17.6907 60.363 18.7708 60.3551 18.4196C60.3513 18.2475 62.493 22.3756 62.0534 22.1927C61.5527 21.9843 60.3749 22.9983 59.8556 22.7884C59.6414 22.7018 58.1276 19.0539 57.5148 17.5591C57.4379 17.3725 57.4339 17.1641 57.5036 16.9747C57.5733 16.7854 57.7116 16.6287 57.8914 16.5354C58.3519 16.2973 64.7507 14.8451 64.7507 14.8451L64.6388 14.2771L65.103 13.3545L69.9763 14.3693Z"
        fill="#2F2E41"
      />
      <path
        d="M66.1468 4.18578C66.3069 3.20502 65.6368 2.28094 64.65 2.12178C63.6633 1.96263 62.7335 2.62867 62.5734 3.60943C62.4133 4.59019 63.0834 5.51427 64.0702 5.67342C65.0569 5.83258 65.9867 5.16653 66.1468 4.18578Z"
        fill="#FFB6B6"
      />
      <path
        d="M70.1453 14.6465C70.2452 14.9445 69.5979 15.1157 68.6957 14.9483C67.915 14.8034 67.0606 14.6314 66.3867 14.4921C65.6649 14.3426 65.1493 14.231 65.1493 14.231C65.1493 14.231 64.9637 15.3842 64.3603 15.2458C63.8405 15.1268 62.2871 9.73381 61.867 8.24337C61.8363 8.13454 61.8422 8.01872 61.8839 7.91355C61.9255 7.80838 62.0006 7.7196 62.0977 7.66075L64.2313 6.3659C64.2313 6.3659 65.7016 5.86401 66.2377 5.84971C66.7737 5.83541 67.702 5.69702 67.702 5.69702C67.702 5.69702 68.7695 5.78928 68.9551 6.57348C69.1408 7.35768 69.9155 12.0073 69.9298 13.1239C69.9455 14.3487 69.8456 13.7529 70.1453 14.6465Z"
        fill="#CACACA"
      />
      <path
        d="M65.0369 1.58691C65.0369 1.58691 64.2862 3.56393 62.4472 4.38458L62.147 2.63138L65.0369 1.58691Z"
        fill="#2F2E41"
      />
      <path
        d="M66.1239 3.6904C66.2649 3.6904 66.3792 3.47354 66.3792 3.20604C66.3792 2.93853 66.2649 2.72168 66.1239 2.72168C65.9829 2.72168 65.8687 2.93853 65.8687 3.20604C65.8687 3.47354 65.9829 3.6904 66.1239 3.6904Z"
        fill="#FFB6B6"
      />
      <path
        d="M63.6006 25.8312L60.0124 27.4707L59.9916 27.4256C59.8213 27.0575 59.8051 26.6372 59.9466 26.2572C60.0881 25.8772 60.3757 25.5686 60.746 25.3994L60.7461 25.3993L62.9376 24.3979L63.6006 25.8312Z"
        fill="#2F2E41"
      />
      <path
        d="M74.6055 29.1067L76.8064 28.1261L76.8065 28.126C77.1785 27.9603 77.6015 27.9483 77.9825 28.0925C78.3634 28.2368 78.6711 28.5255 78.8379 28.8952L78.8583 28.9406L75.2547 30.5461L74.6055 29.1067Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.2"
        d="M68.6467 10.377L68.2266 14.9703L68.9735 15.0053L68.6467 10.377Z"
        fill="black"
      />
      <path
        d="M65.7178 18.958C65.8068 18.8592 65.873 18.7423 65.9116 18.6153C65.9502 18.4884 65.9604 18.3546 65.9413 18.2233C65.9223 18.0921 65.8745 17.9666 65.8014 17.8556C65.7283 17.7447 65.6316 17.6511 65.5181 17.5813L63.9038 9.47656L62.05 10.1815L64.2197 17.9265C64.1145 18.1237 64.0856 18.3525 64.1387 18.5695C64.1918 18.7864 64.3231 18.9765 64.5078 19.1036C64.6924 19.2308 64.9176 19.2862 65.1406 19.2593C65.3636 19.2325 65.569 19.1253 65.7178 18.958Z"
        fill="#FFB6B6"
      />
      <path
        d="M64.3123 10.8424L62.2768 11.1987C62.2188 11.2088 62.1594 11.2064 62.1025 11.1917C62.0456 11.1769 61.9926 11.1502 61.947 11.1132C61.9014 11.0763 61.8644 11.03 61.8385 10.9775C61.8126 10.9251 61.7983 10.8677 61.7967 10.8092L61.7405 8.76197C61.69 8.46715 61.7591 8.16444 61.9328 7.92022C62.1065 7.676 62.3705 7.5102 62.667 7.45919C62.9635 7.40817 63.2683 7.4761 63.5144 7.64809C63.7606 7.82007 63.9281 8.08206 63.9802 8.37661L64.6296 10.3133C64.6481 10.3688 64.6545 10.4276 64.6481 10.4857C64.6418 10.5438 64.6229 10.5998 64.5928 10.65C64.5627 10.7003 64.522 10.7434 64.4736 10.7766C64.4252 10.8098 64.3702 10.8322 64.3123 10.8424H64.3123Z"
        fill="#CACACA"
      />
      <path
        d="M67.2784 18.8153C67.3946 18.7501 67.495 18.6604 67.5725 18.5524C67.6501 18.4445 67.7029 18.321 67.7272 18.1906C67.7515 18.0602 67.7467 17.9261 67.7132 17.7977C67.6798 17.6693 67.6184 17.5498 67.5334 17.4476L68.6192 9.25635L66.6359 9.33321L66.1922 17.3608C66.0289 17.514 65.9278 17.7215 65.9081 17.944C65.8884 18.1664 65.9514 18.3882 66.0853 18.5675C66.2192 18.7468 66.4146 18.871 66.6345 18.9167C66.8544 18.9624 67.0835 18.9263 67.2784 18.8153Z"
        fill="#FFB6B6"
      />
      <path
        d="M68.5653 10.6804L66.5223 10.3692C66.4641 10.3603 66.4086 10.3391 66.3595 10.307C66.3103 10.2749 66.2687 10.2326 66.2375 10.1831C66.2063 10.1336 66.1862 10.0779 66.1785 10.02C66.1709 9.96204 66.1759 9.90313 66.1932 9.84728L66.8005 7.89022C66.8477 7.59486 67.0109 7.33018 67.2542 7.15422C67.4975 6.97825 67.8011 6.90536 68.0984 6.95154C68.3957 6.99771 68.6624 7.15918 68.8401 7.40053C69.0178 7.64188 69.0919 7.94342 69.0463 8.23902L69.0365 10.2804C69.0362 10.3388 69.0232 10.3965 68.9985 10.4496C68.9737 10.5026 68.9377 10.5497 68.893 10.5876C68.8483 10.6256 68.7959 10.6535 68.7393 10.6695C68.6828 10.6855 68.6234 10.6892 68.5653 10.6804Z"
        fill="#CACACA"
      />
    </svg>
  )
}

export default BuildingIllustration
