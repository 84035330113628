import { BaseDeleteModal, PopUp } from "@paudigital/wdp-components"
import { remove } from "app/redux/appEvents/appEvents.actions"
import * as authenticationActions from "app/redux/authentication/authentication.actions"
import * as mfaActions from "app/redux/mfa/mfa.actions"
import { DescriptionContainer } from "./style"
import { useDispatch } from "react-redux"
import { useTranslate } from "hooks/translate"
import { MfaType } from "app/types/myProfile"

const DeleteMfa = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(mfaActions.deleteMfa(MfaType.OTP))
  }

  const handleCancel = () => {
    dispatch(remove("mfa-delete"))
    dispatch(authenticationActions.authenticateReset())
  }

  return (
    <PopUp show disableClose>
      <BaseDeleteModal
        title={translate("portal.account.account.mfa.delete.title")}
        onDelete={handleDelete}
        deleteBtnText={translate("portal.account.account.mfa.delete.button")}
        cancelBtnText={translate("portal.account.account.mfa.delete.cancel")}
        onCancel={handleCancel}
        deleteDisabled={false}
      >
        <DescriptionContainer>
          {translate("portal.account.account.mfa.delete.description")}
        </DescriptionContainer>
      </BaseDeleteModal>
    </PopUp>
  )
}

export default DeleteMfa
