import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  margin: 3.2rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
`

export const DescriptionContainer = styled.div`
  font-size: ${theme.fontSizes.paragraph};
  line-heights: ${theme.lineHeights.paragraph};
  color: ${theme.colors.neutral.darkerGrey};
`

export const HelpContainer = styled.div`
  bottom: 0;
  height: 4.5rem;
  width: 100%;
  display: flex;
  background-color: ${theme.colors.neutral.lightGrey};
`

export const HelpText = styled.div`
  margin: auto;
  cursor: pointer;
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.green.contrast};
  text-decoration-line: underline;
  justifiy-content: center;
  text-align: center;
`
