import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  margin: 3.2rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    min-width: 50rem;
  }
`
