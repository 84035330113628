import State from "../state"
import { Notification } from "app/types/notification"
import { isBefore } from "date-fns"

const filterByRead = (notifications: Notification[], read: boolean) =>
  notifications.filter(n => n.read === read)

const sortNotifications = (notifications: Notification[]) => [
  ...filterByRead(notifications, true).sort(sortByDate),
  ...filterByRead(notifications, false).sort(sortByDate)
]

const sortByDate = (a: Notification, b: Notification) => {
  const dtA = new Date(a.dateTime)
  const dtB = new Date(b.dateTime)
  return dtA === dtB ? 0 : isBefore(dtA, dtB) ? -1 : 1
}

export const isLoading = (state: State): boolean => state.notifications.loading

export const getIsDirty = (state: State): boolean => state.notifications.isDirty

export const getNotifications = (state: State): Notification[] | null => {
  const notifications = state.notifications.data?.notifications

  return notifications ? sortNotifications(notifications) : null
}

export const getAmountOfNotifications = (state: State): number =>
  state.notificationsSettings.data?.length || 0

export const getAmountOfUnreadNotifications = (state: State): number =>
  state.notifications.data?.notifications.filter(n => !n.read).length || 0

export const isRunning = (state: State): boolean => state.notifications.running

export const getElapsedTime = (state: State): number =>
  state.notifications.elapsedTime

export const getLastVisitedTime = (state: State): string | undefined =>
  state.notifications.patchedNotification?.lastFetched

export const hasUnreadNotifications = (state: State): boolean =>
  state.notifications.data?.notifications.some(n => !n.read) || false

export const getPage = (state: State): number | undefined =>
  Number(state.notifications.data?.params.page)

export const hasMoreNotifications = (state: State): boolean => {
  const page = Number(state.notifications.data?.params.page)
  const pages = Number(state.notifications.data?.params.pages)
  return page < pages
}

export const getNotificationsCount = (state: State): Record<string, number> => {
  return state.notifications.notificationsCount
}
