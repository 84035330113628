import { theme } from "@paudigital/wdp-components"
import { InvoiceType } from "app/types/invoice"
import styled from "styled-components"

export const InvoiceValue = styled.div<{
  invoiceType: InvoiceType
  size: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: ${props => (props.size === "normal" ? "4" : "2.8")}rem;
  border-radius: 10rem;
  background: ${props =>
    props.invoiceType === InvoiceType.CREDIT_NOTE
      ? theme.colors.neutral.lightGrey
      : theme.colors.celestial.prc50};
  border: ${props =>
    props.invoiceType === InvoiceType.CREDIT_NOTE &&
    `1px solid ${theme.colors.neutral.darkGrey}`};
`

export const InvoiceValueAmount = styled.span<{
  size: string
}>`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${props => (props.size === "normal" ? theme.fontSizes.caption : theme.fontSizes.captionSmall)};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.bold};
  margin-left: 1.2rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-left: ${props => (props.size === "normal" ? "4.4rem" : "1.2rem")};
    font-size: ${props => (props.size === "normal" ? theme.fontSizes.paragraph : theme.fontSizes.captionSmall)};
  }
`

export const InvoiceValueCurrency = styled.span`
  margin: 0 1.2rem 0 0.4rem;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionChartNumber};
  font-weight: ${theme.fontWeights.medium};

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0 1.6rem 0 0.4rem;
  }
`

export const InvoiceDueDate = styled.span`
  display: block;
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.light};
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  margin: 0.8rem 1.2rem 0;
  text-align: right;

  strong {
    font-weight: ${theme.fontWeights.bold};
    margin-right: 0.4rem;
  }
`

export const Container = styled.div<{ size: string }>`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${props => props.size === "normal" && "2.4rem"};

  @media only screen and (${theme.breakpoints.tablet}) {
    display: block;
    margin-bottom: 0;
    margin-top: ${props => props.size === "normal" && "2.4rem"};
  }
`
