import { Fragment } from "react"
import {
  getTickets,
  getParams,
  getTicketStatistics
} from "app/redux/ticket/ticket.selectors"
import { Pagination } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import FacilityTicketCard from "../../FacilityTicketCard"
import { useViewport } from "hooks/viewport"
import { FacilityTicketStatus } from "app/types/ticket"
import {
  StyledTicketContainer,
  StyledFacilityTicketsContainer,
  PaginationWrapper,
  StyledStatusTitle,
  StyledStatusDescription
} from "./style"
import { usePagination } from "hooks/pagination"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { getAppEvents } from "app/redux/appEvents/appEvents.selectors"
import { Filters, FiltersAndModalOpened } from "routes/FacilityManagement"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { useEffect } from "react"

type TicketsProps = {
  page: number
  perPage: number
  sort: string[]
  queryParams: FiltersAndModalOpened
  setUrlParams: (params: Filters) => void
  modalOpen?: string
}

const Tickets = ({
  page,
  perPage,
  sort,
  setUrlParams,
  queryParams,
  modalOpen
}: TicketsProps) => {
  const { translate } = useTranslate()
  const { width } = useViewport()
  const params = useSelector(getParams)
  const dispatch = useDispatch()
  const activeEvents = useSelector(getAppEvents)
  const { previousPage, nextPage, toPage } = usePagination({
    pages: params.pages!,
    page,
    perPage,
    sort,
    setUrlParams,
    queryParams
  })
  const facilityTickets = useSelector(getTickets)
  const stats = useSelector(getTicketStatistics)

  const countPerStatus = stats?.countPerStatus! || []
  const showTitle = sort?.find(s => s.includes("category"))

  useEffect(() => {
    if (modalOpen && facilityTickets.length) {
      const ticket = facilityTickets.find(t => t.ticketId === modalOpen)
      if (ticket) {
        const { ticketId } = ticket
        dispatch(
          fire({
            eventName: PossibleAppEvents.REPORT_DETAIL,
            uniqueIdentifier: "report-detail",
            props: {
              id: ticketId
            }
          })
        )
      }
    }
  }, [modalOpen, facilityTickets, dispatch, setUrlParams, params])

  const filteredStatuses = Object.values(FacilityTicketStatus)
    .map(status => {
      const amount = facilityTickets.reduce(
        (acc, cur) => (cur.status === status ? ++acc : acc),
        0
      )
      return { status, amount }
    })
    .filter(({ status, amount }) => (amount > 0 ? { status, amount } : {}))

  const getStatusTitles = () => {
    return filteredStatuses
      .filter(status => status.amount !== 0)
      .map(status => buildStatusTitle(status.status))
  }

  const buildStatusTitle = (status: FacilityTicketStatus) => {
    const amount = countPerStatus[status] || 0

    return (
      <Fragment key={status}>
        <StyledStatusTitle>
          {translate(`portal.facilitymanagement.overview.status.${status}`, {
            amount
          })}
        </StyledStatusTitle>
        {status === FacilityTicketStatus.CLOSED && (
          <StyledStatusDescription>
            {translate(
              `portal.facilitymanagement.overview.status.${status}.description`
            )}
          </StyledStatusDescription>
        )}
        {status && buildFacilityTickets(status)}
      </Fragment>
    )
  }
  const handleOnClick = (ticketId: string) => {
    if (activeEvents.length > 0) return
    dispatch(
      fire({
        eventName: PossibleAppEvents.REPORT_DETAIL,
        uniqueIdentifier: "report-detail",
        props: {
          id: ticketId
        }
      })
    )
    setUrlParams({ ...queryParams, modalOpen: ticketId } as Filters)
  }

  const buildFacilityTickets = (status?: FacilityTicketStatus) => {
    const _facilityTickets = status
      ? facilityTickets.filter(ticket => ticket.status === status)
      : facilityTickets

    return _facilityTickets.map(ft => (
      <FacilityTicketCard
        key={ft.ticketId}
        ticket={ft}
        onClick={() => handleOnClick(ft.ticketId)}
        dataTestId={`facility-ticket.${ft.ticketId}`}
        notification={false}
      />
    ))
  }

  return (
    <StyledTicketContainer>
      {facilityTickets.length > 0 && (
        <StyledFacilityTicketsContainer data-testid="facility-ticket-list-container">
          {showTitle ? getStatusTitles() : buildFacilityTickets()}
          <PaginationWrapper>
            <Pagination
              page={Number(page)}
              perPage={Number(perPage)}
              pages={params.pages}
              nextPage={nextPage}
              previousPage={previousPage}
              goToPage={toPage}
              showPages={3}
              width={width}
              next={translate("portal.facilitymanagement.pagination.next")}
              previous={translate(
                "portal.facilitymanagement.pagination.previous"
              )}
            />
          </PaginationWrapper>
        </StyledFacilityTicketsContainer>
      )}
    </StyledTicketContainer>
  )
}

export default Tickets
