import { SlideIn, Stepper } from "@paudigital/wdp-components"
import { useEffect } from "react"
import { fire, remove } from "app/redux/appEvents/appEvents.actions"
import { getStep } from "app/redux/documentWizard/documentWizard.selectors"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import DocumentCategory from "./DocumentCategory"
import DocumentUpload from "./DocumentUpload"
import { Container } from "./style"
import { Filters } from "routes/Documents"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = Filters

const CreateDocumentPopUp = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort
}: Props) => {
  const step = useSelector(getStep)
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }

  useEffect(() => {
    switch (step) {
      case 1:
        dispatch(createGAEvent(PossibleGAEvents.DOCUMENT_STEP1))
        break
      case 2:
        dispatch(createGAEvent(PossibleGAEvents.DOCUMENT_STEP2))
        break
      default:
        break
    }
  }, [dispatch, step])

  const handleClose = () => {
    dispatch(remove("create-document"))
    dispatch(
      fire({
        eventName: PossibleAppEvents.CLOSE_REPORT,
        uniqueIdentifier: "close-report",
        props: {
          previousUniqueIdentifier: "create-document",
          previousEvent: PossibleAppEvents.CREATE_DOCUMENT,
          title: translate("portal.documents.createdocument.delete.title"),
          props: queryParams
        }
      })
    )
  }

  return (
    <SlideIn show onClose={handleClose} showCloseIcon>
      <Container>
        <Stepper
          stepText={translate("portal.documents.createdocument.step.label")}
          activeStep={step}
          config={[
            {
              title: translate("portal.documents.createdocument.step1"),
              content: <DocumentCategory />
            },
            {
              title: translate("portal.documents.createdocument.step2"),
              content: <DocumentUpload {...queryParams} />
            }
          ]}
        />
      </Container>
    </SlideIn>
  )
}

export default CreateDocumentPopUp
