import { getBrowserLanguage } from "./browserLanguage.utils"

export const getLocaleNumberFormatter = () => {
  const getLanguage = () => {
    const userLanguage = getBrowserLanguage()
    const mappedLanguages = ["en-US", "en-GB"]
    return mappedLanguages.includes(userLanguage) ? "de-DE" : userLanguage
  }

  const getValue = (number: string | number) => {
    return typeof number === "string" ? parseFloat(number) : number
  }

  const integerFormatter = (number: number | string) => {
    return decimalFormatter(number, 0, 0)
  }

  const decimalFormatter = (
    number: number | string,
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 2
  ) => {
    const formatter = new Intl.NumberFormat(getLanguage(), {
      minimumFractionDigits,
      maximumFractionDigits,
      localeMatcher: "lookup"
    })
    return formatter.format(getValue(number))
  }

  return { integerFormatter, decimalFormatter }
}
