import { ContactPerson } from "app/types/contactPerson"
import State from "../state"

export const isLoading = (state: State): boolean => state.contactPerson.loading

export const hasErrors = (state: State): boolean =>
  state.contactPerson.errors ? true : false

export const getInitialContactPerson = (state: State): ContactPerson | null =>
  state.contactPerson.contactPerson

export const getContactPerson = (state: State): ContactPerson[] =>
  state.contactPerson.data

export const getContactPersonPreferredBuilding = (state: State): string | undefined =>
  state.contactPerson.contactPerson?.preferredBuilding
