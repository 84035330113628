import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import { getAssignedPersons } from "app/api/rentedBuilding"
import * as actions from "./rentedBuildings.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getAssignedPersonsFlow(
  action: actions.GetContactPersonsForRentedBuildingAction
): SagaIterator {
  try {
    const { rentedBuildingId } = action.payload
    const response = yield call(getAssignedPersons, rentedBuildingId)
    yield put(actions.getRentedBuildingContactPersonsSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getRentedBuildingContactpersonsFail(e))
  }
}

export default function* RentedBuildingSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getAssignedPersonsFlow)
}
