import { Filters as DocumentFilters } from "routes/Documents"
import { Filters as FacilityManagementFilters } from "routes/FacilityManagement"
import { Filters as InvoicesFilter } from "routes/Invoices"

type PageParams = {
  pages: number
  page: number
  perPage: number
  sort?: string[]
  setUrlParams: (params: any) => void,
  queryParams?: FacilityManagementFilters | DocumentFilters | InvoicesFilter | {}
}

export const usePagination = ({
  pages,
  page,
  perPage,
  sort,
  setUrlParams,
  queryParams = {}
}: PageParams) => {
  const previousPage = () => {
    const previousPage = page > 1 ? page - 1 : 1
    goToPage(previousPage)
  }

  const nextPage = () => {
    const nextPage = page < pages! ? page + 1 : page
    goToPage(nextPage)
  }

  const toPage = (e: React.MouseEvent<HTMLLIElement>) => {
    goToPage(e.currentTarget.value)
  }

  const goToPage = (page: number) => {
    setUrlParams({ ...queryParams, perPage, sort, page })
    window.scrollTo(0, 0)
  }

  return { goToPage, toPage, nextPage, previousPage }
}
