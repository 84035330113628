import { FacilityTicketStatus } from "app/types/ticket"
import Status from "./Status"
import { getTicketStatistics } from "app/redux/ticket/ticket.selectors"
import { useSelector } from "react-redux"
import { Filters } from "routes/FacilityManagement"

type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const StatusFilter = ({
  page,
  perPage,
  sort,
  status,
  type,
  from,
  to,
  ticket,
  description,
  setUrlParams
}: Props) => {
  const queryParams = {
    page,
    perPage,
    sort,
    status,
    type,
    from,
    to,
    ticket,
    description
  }
  const selectedFilterStatus = queryParams?.status
    ? (queryParams?.status as FacilityTicketStatus[])
    : []
  const stats = useSelector(getTicketStatistics)
  const countPerStatus = stats?.countPerStatus! || []

  const statuses = Object.values(FacilityTicketStatus)
    .filter(status => status !== FacilityTicketStatus.CLOSED)
    .map(status => ({
      amount: countPerStatus[status] || 0,
      checked: selectedFilterStatus.includes(status),
      status
    }))

  const clickHandler = (ticketStatus: FacilityTicketStatus) => {
    if (selectedFilterStatus.includes(ticketStatus)) {
      const filteredStatuses = selectedFilterStatus.filter(
        s => s !== ticketStatus
      )
      setUrlParams({ ...queryParams, status: filteredStatuses, page: 1 })
    } else {
      setUrlParams({
        ...queryParams,
        status: [...selectedFilterStatus, ticketStatus],
        page: 1
      })
    }
  }

  return (
    <>
      {statuses?.map(filterStatus => (
        <Status
          key={filterStatus.status}
          amount={filterStatus.amount}
          checked={filterStatus.checked}
          status={filterStatus.status}
          onClick={clickHandler}
        />
      ))}
    </>
  )
}

export default StatusFilter
