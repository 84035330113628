import { ChevronIcon, Toggle } from "@paudigital/wdp-components"
import { NotificationSettings } from "app/types/notificationSettings"
import React from "react"
import { useSelector } from "react-redux"
import * as selectors from "app/redux/me/me.selectors"
import {
  Container,
  Collapsable,
  BuildingName,
  ToggleWrapper,
  TogglesContainer,
  StyledCustomerNameTag,
  Address,
  StyledBuildingInfoContainer
} from "./style"
import { useTranslate } from "hooks/translate"
import { getAmountOfNotificationsSettings } from "app/redux/notificationSettings/notificationSettings.selectors"
import { usePermission } from "hooks/permission"
import { Modules } from "app/enums/modules"

type Props = {
  notification: NotificationSettings
  onNotificationSettingChange: (notification: NotificationSettings) => void
}

const NotificationToggles = ({
  notification,
  onNotificationSettingChange
}: Props) => {
  const { invoice, facility, documents, rentedBuildingId } = notification
  const { translate } = useTranslate()
  const { hasPermission } = usePermission()
  const [collapsed, setCollapsed] = React.useState(false)
  const amountOfNotifications = useSelector(getAmountOfNotificationsSettings)
  const hasMultipleCompanies = useSelector(selectors.hasMultipleCompanies)
  const rentedBuilding = useSelector(
    selectors.getBuildingById(rentedBuildingId)
  )

  const handleToggle = (name: string, value: boolean) => {
    onNotificationSettingChange({
      ...notification,
      [name]: value
    })
  }

  const toggleCollapse = () => setCollapsed(!collapsed)

  React.useEffect(() => {
    if (amountOfNotifications === 1) {
      setCollapsed(true)
    }
  }, [amountOfNotifications])

  if (!rentedBuilding) {
    return null
  }
  const isAvailable = (setting: boolean | undefined) =>
    typeof setting !== "undefined"

  return (
    <Container collapsed={collapsed}>
      {amountOfNotifications !== 1 && (
        <BuildingName onClick={toggleCollapse}>
          <StyledBuildingInfoContainer>
            <Address>
              <strong>{rentedBuilding?.city}</strong> - {rentedBuilding?.street}{" "}
              {rentedBuilding?.houseNo}
            </Address>
            {hasMultipleCompanies && (
              <StyledCustomerNameTag name={rentedBuilding.entityName} />
            )}
          </StyledBuildingInfoContainer>
          <Collapsable
            aria-label={collapsed ? "open" : "closed"}
            type="button"
            collapsed={collapsed}
            onClick={toggleCollapse}
          >
            <ChevronIcon />
          </Collapsable>
        </BuildingName>
      )}
      <TogglesContainer collapsed={collapsed} data-testid="email-notification-block">
        {hasPermission(Modules.INVOICES, rentedBuilding.id) &&
          isAvailable(invoice) && (
            <ToggleWrapper>
              <Toggle
                name="invoice"
                toggled={invoice}
                onClick={handleToggle}
                label={translate(
                  "portal.account.preferences.notifications.invoice.label"
                )}
                guidanceText={translate(
                  "portal.account.preferences.notifications.invoice.guidance"
                )}
              />
            </ToggleWrapper>
          )}
        {hasPermission(Modules.FACILITY_MANAGEMENT, rentedBuilding.id) &&
          isAvailable(facility) && (
            <ToggleWrapper>
              <Toggle
                name="facility"
                toggled={facility}
                onClick={handleToggle}
                label={translate(
                  "portal.account.preferences.notifications.facility.label"
                )}
                guidanceText={translate(
                  "portal.account.preferences.notifications.facility.guidance"
                )}
              />
            </ToggleWrapper>
          )}
        {hasPermission(Modules.DOCUMENTS, rentedBuilding.id) &&
          isAvailable(documents) && (
            <ToggleWrapper>
              <Toggle
                name="documents"
                toggled={documents}
                onClick={handleToggle}
                label={translate(
                  "portal.account.preferences.notifications.documents.label"
                )}
                guidanceText={translate(
                  "portal.account.preferences.notifications.documents.guidance"
                )}
              />
            </ToggleWrapper>
          )}
      </TogglesContainer>
    </Container>
  )
}

export default NotificationToggles
