import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./workOrder.actions"
import * as api from "app/api/workOrder"
import { getFileNameFromHeaders } from "app/utils/getFileName"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getWorkOrderAttachmentsFlow(
  action: actions.GetWorkOrderAttachmentsAction
): SagaIterator {
  try {
    const { rentedBuildingId, workOrderId } = action.payload
    const response = yield call(
      api.getWorkOrderAttachments,
      rentedBuildingId,
      workOrderId
    )
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(actions.getWorkOrderAttachmentsSuccess())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getWorkOrderAttachmentsFail(e))
  }
}

export default function* workOrderSaga(): SagaIterator {
  yield takeLatest(
    actions.Types.GET_WORK_ORDER_ATTACHMENT,
    getWorkOrderAttachmentsFlow
  )
}
