import { FacilityTicketStatus } from "app/types/ticket"
import { useTranslate } from "hooks/translate"
import {
  StyledButton,
  StyledBaseCard,
  StyledCardActions,
  StyledContainer,
  StyledStatusInfo,
  StyledWrapper,
  CancelButton,
  CancelLabel
} from "./style"
import { useDispatch, useSelector } from "react-redux"
import FacilityTicketCardHeader from "../FacilityTicketCardHeader"
import { Ticket } from "app/types/ticket"
import { getUserCompanyName } from "app/redux/me/me.selectors"
import TicketStatus from "app/components/FacilityManagement/TicketStatus"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"

type FacilityTicketProps = {
  ticket: Ticket
  onClick: () => void
  dataTestId?: string
  notification: boolean
}

type Props = FacilityTicketProps

const FacilityTicketCard = ({
  ticket,
  onClick,
  dataTestId,
  notification
}: Props) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const companyName = useSelector(getUserCompanyName)
  const {
    answerCodes,
    type,
    sourceId,
    suppliers,
    status,
    creationDate,
    description,
    allowFeedback,
    reportedBy,
    workOrders
  } = ticket

  const handleCancelReport = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.CANCEL_TICKET,
        uniqueIdentifier: "cancel-ticket",
        props: {
          ticket,
          notification
        }
      })
    )
  }

  const getButtonTranslation = () => {
    if (status === FacilityTicketStatus.INFO_NEEDED)
      return "portal.facilitymanagement.ticket.button.infoNeeded"
    if (!allowFeedback) {
      return "portal.facilitymanagement.ticket.button.details"
    } else {
      return "portal.facilitymanagement.ticket.button.feedback"
    }
  }

  if (status === FacilityTicketStatus.CLOSED) return null

  return (
    <StyledBaseCard dataTestId={dataTestId}>
      <StyledWrapper>
        <StyledContainer>
          <FacilityTicketCardHeader
            type={type}
            answerCodes={answerCodes}
            date={creationDate}
            ticketNr={sourceId}
            stringFormat="date-reporter"
            suppliers={suppliers}
            title={description}
            reportedBy={reportedBy ? companyName : "WDP"}
            workOrders={workOrders}
          />
          <StyledStatusInfo>
            <TicketStatus ticket={ticket} />
            {ticket.status === FacilityTicketStatus.AWAITING_PROCESSING &&
              !notification && (
                <CancelButton onClick={handleCancelReport}>
                  <CancelLabel>
                    {translate(
                      "portal.facilitymanagement.reportdetail.cancelReport"
                    )}
                  </CancelLabel>
                </CancelButton>
              )}
          </StyledStatusInfo>
        </StyledContainer>
      </StyledWrapper>
      <StyledCardActions alignment="horizontal">
        <StyledButton
          label={translate(getButtonTranslation())}
          type="secondary"
          onClick={onClick}
        />
      </StyledCardActions>
    </StyledBaseCard>
  )
}

export default FacilityTicketCard
