import { AccountCreated, PopUp } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"

type Props = {
  onClose: () => void
}

const DocumentCreatedPopup = ({ onClose }: Props): JSX.Element => {
  const { translate } = useTranslate()

  return (
    <PopUp show onClose={onClose} delayInMs={4000}>
      <AccountCreated value={translate("portal.documents.document.success")} />
    </PopUp>
  )
}

export default DocumentCreatedPopup
