import { getPreferredLanguage } from "app/redux/me/me.selectors"
import * as selectors from "app/redux/campaign/campaign.selectors"
import { useApi } from "hooks/api"
import { useSelector } from "react-redux"
import { ModulesEnum } from "app/types/banner"
import React from "react"
import { getCampaign } from "app/redux/campaign/campaign.actions"
import { Banner } from "@paudigital/wdp-components"
import HeaderTitle from "../HeaderTitle"
import HeaderButton from "../HeaderButton"

type ModuleHeaderProps = {
  page: string
  module: ModulesEnum
  Hero: any
}

export const ModuleHeader = ({ page, module, Hero }: ModuleHeaderProps) => {
  const { call } = useApi()
  const bannerInfo = useSelector(selectors.getBannerInfoForModule(module))
  const preferredLanguage = useSelector(getPreferredLanguage)

  React.useEffect(() => {
    call(getCampaign())
  }, [call])

  return (
    <Banner
      hero={bannerInfo?.bannerPictureUrl || Hero}
      pullContent
      campaign={bannerInfo ? true : false}
    >
      <>
        <HeaderTitle
          page={page}
          bannerInfo={bannerInfo!}
          preferredLanguage={preferredLanguage}
        />
        {bannerInfo?.languageData[preferredLanguage]?.hasLink && (
          <HeaderButton
            bannerInfo={bannerInfo}
            preferredLanguage={preferredLanguage}
          />
        )}
      </>
    </Banner>
  )
}

export default ModuleHeader
