import {
  Spinner,
  BuildingPassportEmpty,
  Button,
  DocumentIcon
} from "@paudigital/wdp-components"
import { useEffect } from "react"
import Section from "app/components/Section"
import { useTranslate } from "hooks/translate"
import {
  Container,
  Column,
  StyledBuildingEmpty,
  StyledBuildingEmptyContent,
  StyledBuildingEmptyContainer
} from "./style"
import Specifications from "./Specifications"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { getBuildingSpecification } from "app/redux/buildingSpecifications/buildingSpecifications.actions"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import * as selectors from "app/redux/buildingSpecifications/buildingSpecifications.selectors"
import Energy from "./Energy"
import RentalSpace from "./RentalSpace"
import ParkingRentalSpace from "./ParkingRentalSpace"
import {
  RentalSpaceType,
  RentalSpaceTypes
} from "app/types/buildingSpecification"
import { useApi } from "hooks/api"
import { getDocumentStatistics } from "app/redux/document/document.selectors"
import { DocumentTypes } from "app/enums/documentTypes"
import { Modules } from "app/enums/modules"
import { usePermission } from "hooks/permission"

const BuildingSpecification = () => {
  const { call } = useApi()
  const navigate = useNavigate()
  const { translate } = useTranslate()
  const { hasPermission } = usePermission()
  const activeRentedBuilding = useSelector(getActiveBuilding)
  const buildingSpecifications = useSelector(selectors.getBuildingSpecification)
  const isLoading = useSelector(selectors.loading)
  const documentStatistics = useSelector(getDocumentStatistics)
  const planPermission =
    activeRentedBuilding && hasPermission(Modules.PLANS, activeRentedBuilding!)

  const picturesTotal = documentStatistics?.find(
    d => d.document_type === DocumentTypes.Pictures
  )
  const documentsTotal = documentStatistics?.find(
    d => d.document_type === DocumentTypes.Maintenance_inspection_reports
  )

  const docStatsPlans = documentStatistics
    .find(ds => ds.document_type === DocumentTypes.Contracts)
    ?.children.find(
      ds => ds.document_type === DocumentTypes.Contracts_appendixes
    )
    ?.children.find(ds => ds.document_type === DocumentTypes.Plans)?.count

  const page = 1
  const perpage = 20
  const sort = "date=desc"

  const RedirectDocuments = (docType: string) => {
    navigate(
      `/documents/${activeRentedBuilding}?documentType=${docType}&page=${page}&perPage=${perpage}&sort[]=${sort}`
    )
  }

  useEffect(() => {
    if (activeRentedBuilding) {
      call(getBuildingSpecification())
    }
  }, [activeRentedBuilding, call])

  if (isLoading) {
    return (
      <StyledBuildingEmptyContainer>
        <Spinner />
      </StyledBuildingEmptyContainer>
    )
  }

  if (!buildingSpecifications) {
    return (
      <StyledBuildingEmptyContainer>
        <StyledBuildingEmpty>
          <div>
            <BuildingPassportEmpty />
          </div>
          <StyledBuildingEmptyContent>
            {translate("portal.buildingpassport.empty.content")}
          </StyledBuildingEmptyContent>
        </StyledBuildingEmpty>
      </StyledBuildingEmptyContainer>
    )
  }

  const { rentalSpaces } = buildingSpecifications
  const buildRentalSpaceComponents = () => {
    const items = []
    for (const rsKey of Object.keys(rentalSpaces)) {
      switch (rsKey) {
        case RentalSpaceTypes["0090"]:
          items.push(
            <ParkingRentalSpace
              key={rsKey}
              rentalSpaceType={rsKey}
              rentalSpace={rentalSpaces[rsKey]!}
            />
          )
          break
        default:
          items.push(
            <RentalSpace
              key={rsKey}
              rentalSpaceType={rsKey}
              rentalSpace={rentalSpaces[rsKey as RentalSpaceType]!}
            />
          )
          break
      }
    }
    return items
  }

  return (
    <Section title={translate("portal.buildingpassport.specification.title")}>
      <Container>
        <Column>
          <Specifications />
          {documentsTotal?.count && (
            <Button
              startIcon={<DocumentIcon />}
              label={translate("portal.buildingpassport.button.documents", {
                amount: documentsTotal.count
              })}
              type="secondary"
              fullWidth
              onClick={() =>
                RedirectDocuments(DocumentTypes.Maintenance_inspection_reports)
              }
            />
          )}
          <Energy />
        </Column>
        <Column>
          {buildRentalSpaceComponents()}
          {docStatsPlans && planPermission && (
            <Button
              startIcon={<DocumentIcon />}
              label={translate("portal.buildingpassport.button.plans", {
                amount: docStatsPlans
              })}
              type="secondary"
              fullWidth
              onClick={() => RedirectDocuments(DocumentTypes.Plans)}
            />
          )}
          {picturesTotal && (
            <Button
              startIcon={<DocumentIcon />}
              label={translate("portal.buildingpassport.button.pictures", {
                amount: picturesTotal.count
              })}
              type="secondary"
              fullWidth
              onClick={() => RedirectDocuments(DocumentTypes.Pictures)}
            />
          )}
        </Column>
      </Container>
    </Section>
  )
}

export default BuildingSpecification
