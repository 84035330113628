import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const SurveyTitle = styled.h4`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 2rem;
`

export const SurveyCompletedTitle = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`

export const SurveyCompletedText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`

export const SurveyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  min-height: 9rem;
  padding: 4rem;
  margin: 4rem -3.2rem -3.2rem;
  background-color: ${theme.colors.neutral.lightGrey};
`
export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 55rem;
`

export const FormWrapper = styled.form`
  width: 100%;
`

export const LinkWrapper = styled.div`
  margin-top: 2rem;
`
