import { getSurveys, setSurvey } from "app/api/surveys"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import {
  getSurveysFailed,
  getSurveysSuccess,
  SetSurveyAction,
  setSurveyFailed,
  setSurveySuccess,
  Types
} from "./surveys.actions"

export function* getSurveysFlow(): SagaIterator {
  try {
    const surveys = yield call(getSurveys)
    yield put(getSurveysSuccess(surveys.data))
  } catch (e: any) {
    yield put(getSurveysFailed(e))
  }
}

export function* setSurveysFlow(action: SetSurveyAction): SagaIterator {
  try {
    yield call(setSurvey, action.payload.data)
    yield put(setSurveySuccess())
  } catch (e: any) {
    yield put(setSurveyFailed(e))
  }
}

export function* surveysSaga(): SagaIterator {
  yield takeLatest(Types.GET, getSurveysFlow)
  yield takeLatest(Types.SET, setSurveysFlow)
}
