export enum Types {
  HANDLE_HTTP_ERROR = "HANDLE_HTTP_ERROR",
  HANDLE_HTTP_ERROR_SUCCESS = "HANDLE_HTTP_ERROR_SUCCESS",
  HANDLE_HTTP_ERROR_NO_MATCH = "HANDLE_HTTP_ERROR_NO_MATCH",
  CLEAR_HTTP_ERROR = "CLEAR_HTTP_ERROR"
}

export type Action =
  | HandleHttpError
  | HandleHttpErrorSuccess
  | HandleHttpErrorNoMatch
  | ClearHttpError
export type HttpErrorMapping = Array<{
  expectedHttpStatus: number
  redirectTo: string
}>
export type HttpErrorResponse = { response?: { status?: number, data?: { message?: string } } }

export type HandleHttpError = {
  payload: {
    error: HttpErrorResponse
    mapping: HttpErrorMapping
  }
  type: Types.HANDLE_HTTP_ERROR
}

export type HandleHttpErrorSuccess = {
  type: Types.HANDLE_HTTP_ERROR_SUCCESS
}

export type HandleHttpErrorNoMatch = {
  type: Types.HANDLE_HTTP_ERROR_NO_MATCH
  httpStatus: number
  httpMessage?: string
}

export type ClearHttpError = {
  type: Types.CLEAR_HTTP_ERROR
}

export function handleHttpError(payload: {
  error: HttpErrorResponse
  mapping: HttpErrorMapping
}): Action {
  return {
    type: Types.HANDLE_HTTP_ERROR,
    payload
  }
}

export function handleHttpErrorSuccess(): Action {
  return {
    type: Types.HANDLE_HTTP_ERROR_SUCCESS
  }
}

export function handleHttpErrorNoMatch(httpStatus: number, httpMessage?: string): Action {
  return {
    type: Types.HANDLE_HTTP_ERROR_NO_MATCH,
    httpStatus,
    httpMessage
  }
}

export function clearHttpError(): Action {
  return {
    type: Types.CLEAR_HTTP_ERROR
  }
}
