import { MeterHistoryData } from "app/types/energy"
import { IntervalEnum } from "app/enums/interval"
import { RentedBuilding } from "app/types/rentedBuilding"

export enum Types {
  GET = "GET_WATER_METER_HISTORY",
  GET_SUCCESS = "GET_WATER_METER_HISTORY_SUCCESS",
  GET_FAIL = "GET_WATER_METER_HISTORY_FAIL"
}

export type QueryParams = {
  from: string
  to: string
  interval: IntervalEnum
}

export type Action =
  | GetMeterHistoryAction
  | GetMeterHistorySuccessAction
  | GetMeterHistoryFailAction

export type GetMeterHistoryAction = {
  type: Types.GET
  payload: {
    rentedBuilding: RentedBuilding
    params: QueryParams
  }
}

export type GetMeterHistorySuccessAction = {
  type: Types.GET_SUCCESS
  payload: {
    data: MeterHistoryData[]
  }
}

export type GetMeterHistoryFailAction = {
  type: Types.GET_FAIL
  payload: {
    errors: Record<string, unknown>
  }
}

export function getWaterMeterHistory(
  rentedBuilding: RentedBuilding,
  params: QueryParams
): Action {
  return {
    type: Types.GET,
    payload: {
      rentedBuilding,
      params
    }
  }
}

export function getWaterMeterHistorySuccess(data: MeterHistoryData[]): Action {
  return {
    type: Types.GET_SUCCESS,
    payload: {
      data
    }
  }
}

export function getWaterMeterHistoryFail(
  errors: Record<string, unknown>
): Action {
  return {
    type: Types.GET_FAIL,
    payload: {
      errors
    }
  }
}
