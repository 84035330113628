import { DropDown } from "@paudigital/wdp-components"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { useApi } from "hooks/api"
import { getContactpersons } from "app/redux/rentedBuildings/rentedBuildings.actions"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { getUserCompany } from "app/redux/me/me.selectors"
import { ModifiedByEnum } from "app/types/document"
import { useTranslate } from "hooks/translate"
import { Filters } from "routes/Documents"

export type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const UploadedByFilter = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { translate } = useTranslate()
  const { call } = useApi()
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }
  const rentedBuildingId = useSelector(getActiveBuilding)
  const company = useSelector(getUserCompany)

  const selectedValue = queryParams?.uploadedBy
    ? (queryParams?.uploadedBy as string)
    : undefined

  React.useEffect(() => {
    rentedBuildingId && call(getContactpersons(rentedBuildingId))
  }, [call, rentedBuildingId])

  const options = [
    {
      label: translate("portal.documentmanagement.filter.option.any"),
      value: ""
    },
    {
      label: "WDP",
      value: ModifiedByEnum.INGESTION
    },
    {
      label: company && company.name ? company.name : "",
      value: ModifiedByEnum.END_USER
    }
  ]

  const selectedUploadedBy = useMemo(() => {
    if (!selectedValue)
      return translate("portal.documentmanagement.filter.option.any")
    if (selectedValue === ModifiedByEnum.INGESTION) {
      return "WDP"
    }
    return company?.name
  }, [company?.name, selectedValue, translate])

  const handleSelect = (optionData: OnChangeValue<CustomOptionType, false> | null) => {
    const value = optionData as CustomOptionType
    setUrlParams({
      ...queryParams,
      uploadedBy: value.value ? value.value : undefined
    })
  }

  return (
    <DropDown
      isClearable={false}
      defaultValue={selectedUploadedBy}
      options={options}
      onChange={handleSelect}
      hasDropdownIndicator
    />
  )
}
export default UploadedByFilter
