import Footer from "../Footer"
import { Container } from "./style"

type Props = {
  children: JSX.Element
  pullContent?: boolean
}

const Content = ({ children, pullContent }: Props) => (
  <>
    <Container pullContent={pullContent}>
      {children}
    </Container>
    <Footer/>
  </>
)

export default Content
