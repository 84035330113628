import { RentedBuilding } from "app/types/rentedBuilding"

export type Building = Pick<
  RentedBuilding,
  "country" | "city" | "postalCode" | "street" | "houseNo"
>

export enum AddressType {
  full,
  short,
  street,
  city
}

export function getAddress(
  building: Building,
  addressType?: AddressType
): string {
  switch (addressType) {
    case AddressType.street:
      return `${building.street} ${building.houseNo}`
    case AddressType.city:
      return `${building.postalCode} ${building.city}`
    case AddressType.full:
      return `${building.street} ${building.houseNo}, ${building.postalCode} ${building.city} ${building.country}`
    case AddressType.short:
    default:
      return `${building.street} ${building.houseNo}, ${building.city}`
  }
}
