import { patchContactPersonPassword } from "app/api/contactPerson"
import { SagaIterator } from "redux-saga"
import { call, put, select, take, takeLatest } from "redux-saga/effects"
import * as actions from "./authorization.actions"
import { fire, remove } from "../appEvents/appEvents.actions"
import * as meSelectors from "./../me/me.selectors"
import * as meActions from "./../me/me.actions"
import * as appEventActions from "../appEvents/appEvents.actions"
import * as authenticationActions from "../authentication/authentication.actions"
import * as authenticationSelectors from "../authentication/authentication.selectors"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { intl, Locales } from "app/i18n/config"
import { ProfileActions } from "app/enums/profile"
import { isPasswordHistoryError } from "app/utils/passwordAuth0ErrorIdentifier"
import { history } from "store"

export function* updatePassword(
  action: actions.UpdatePasswordAction
): SagaIterator {
  const preferredLanguage: Locales = yield select(
    meSelectors.getPreferredLanguage
  )

  const firePasswordErrorEvent = (titleId: string, descriptionId: string) =>
    appEventActions.fire({
      local: true,
      uniqueIdentifier: "renew-password-error",
      eventName: PossibleAppEvents.ERROR,
      props: {
        title: intl[preferredLanguage].formatMessage({
          id: titleId
        }),
        description: intl[preferredLanguage].formatMessage({
          id: descriptionId
        }),
        variant: "error",
        show: true
      }
    })

  try {
    const { newPassword } = action.payload
    const token = yield select(authenticationSelectors.getToken)
    yield call(patchContactPersonPassword, { newPassword, token })
    yield put(meActions.getMe())
    yield take([meActions.Types.GET_FAIL, meActions.Types.GET_SUCCESS])
    yield put(remove(ProfileActions.PASSWORD_EDIT))
    yield put(
      fire({
        eventName: PossibleAppEvents.PASSWORD_UPDATED,
        uniqueIdentifier: ProfileActions.PASSWORD_UPDATED
      })
    )
    yield put(actions.updatePasswordSuccess())
    yield put(authenticationActions.authenticateReset())
    if (action.payload.type === actions.PasswordUpdateType.PASSWORD_CHANGE_90) {
      history.push("/")
    }
  } catch (e: any) {
    yield put(remove("renew-password-error"))
    const httpStatus = e?.response?.status
    if (httpStatus === 400) {
      const httpMesssage = e?.response?.data.message
      if (isPasswordHistoryError(httpMesssage)) {
        yield put(
          firePasswordErrorEvent(
            "portal.account.account.password.edit.history.title",
            "portal.account.account.password.edit.history.content"
          )
        )
      } else {
        yield put(
          firePasswordErrorEvent(
            "portal.account.account.password.error.already-used.title",
            "portal.account.account.password.error.already-used.description"
          )
        )
      }
    }
    yield put(actions.updatePasswordFail())
  }
}

export default function* authorizationSaga(): SagaIterator {
  yield takeLatest(actions.Types.UPDATE_PASSWORD, updatePassword)
}
