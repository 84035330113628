import { SagaIterator } from "redux-saga"
import * as actions from "./adminBuildingSelect.actions"
import { call, put, takeLatest } from "redux-saga/effects"
import { getRentedBuildings } from "app/api/rentedBuilding"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getBuildingsFlow(
  action: actions.GetBuildingsAction
): SagaIterator {
  try {
    const response = yield call(getRentedBuildings)
    yield put(actions.getBuildingsSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getBuildingsFail(e))
  }
}

export default function* documentsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET_BUILDINGS, getBuildingsFlow)
}