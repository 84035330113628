import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import { getContactPersonContactRoles } from "app/api/contactPerson"
import * as actions from "./contactPersonContactRoles.actions"
import * as httpErrorActions from "app/redux/httpErrors/httpErrors.actions"

export function* getContactPersonContactRolesFlow(
  action: actions.GetContactPersonContactRolesAction
): SagaIterator {
  try {
    const { rentedBuildingId, contactPersonId } = action.payload
    const response = yield call(
      getContactPersonContactRoles,
      rentedBuildingId,
      contactPersonId
    )
    yield put(
      actions.getContactPersonContactRolesSuccess(response && response.data)
    )
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getContactPersonContactRolesFail(e))
  }
}

export default function* contactPersonContactRolesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getContactPersonContactRolesFlow)
}
