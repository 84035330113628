import { theme } from "@paudigital/wdp-components"
import styled, { css } from "styled-components"

export const LegendContainer = styled.div`
  align-items: center;
  
  @media only screen and (${theme.breakpoints.tablet}) {
    display: flex;
  }
`

export const LegendItemWrapper = styled.div`
  vertical-align: middle;
  margin-right: 3.2rem;
`
export const StyledSpan = styled.span`
  font-size: 12px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const StyledIcon = styled.span<{
  type: "circle" | "line"
  lineColor: string
  backgroundColor: string
}>`
  vertical-align: middle;
  margin-right: 1.6rem;
  ${props => {
    switch (props.type) {
      case "line":
        return css`
          margin-top: 1px;
          height: 0.4rem;
          width: 3rem;
          background-color: #bbb;
          border-radius: 3px;
          display: inline-block;
        `
      default:
        return css`
          height: 1.6rem;
          width: 1.6rem;
          background-color: #bbb;
          border-radius: 50%;
          display: inline-block;
          border: 1px solid ${theme.colors.neutral.darkGrey};
        `
    }
  }}
  ${props => {
    switch (props.type) {
      case "circle":
        return css`
          background: ${props.backgroundColor};
        `
      case "line":
        return css`
          background: ${props.lineColor};
        `
    }
  }}
`
