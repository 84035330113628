import ListItem from "app/components/ListItem"
import { useTranslate } from "hooks/translate"
import { FacilityTicketStatusInSummary } from "../TicketStatusList"

export type QuickLinkStatuses = {
  status: FacilityTicketStatusInSummary[]
}

export type QuickLinkProps = {
  label: string
  amount: number
  statuses: FacilityTicketStatusInSummary[]
  onClick: (statuses: QuickLinkStatuses) => void
}

type Props = QuickLinkProps

const QuickLink = ({ label, amount, statuses, onClick }: Props) => {
  const { translate } = useTranslate()

  const clickHandler = () => {
    onClick({ status: statuses.map(s => s) })
  }

  return (
    <ListItem
      label={translate(
        `portal.dashboard.widget.facilitymanagement.status.${label}`,
        { amount }
      )}
      onClick={clickHandler}
    />
  )
}

export default QuickLink
