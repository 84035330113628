import { Data } from "@paudigital/wdp-components"
import { PossibleContactRoles } from "app/enums/roles"
import {
  NotificationSettings,
  RetrieveNotificationSettingsResult,
  UpdateNotificationSettingsDto
} from "app/types/notificationSettings"
import { RentedBuilding } from "app/types/rentedBuilding"

export function mapRetrievedNotificationSettingsToReducerData(
  retrievedNotificationSettings: RetrieveNotificationSettingsResult[]
): NotificationSettings[] {
  return retrievedNotificationSettings.map(retrievedSettings => {
    return {
      id: retrievedSettings.id,
      rentedBuildingId: retrievedSettings.rentedBuildingId,
      facility: retrievedSettings.notifyOnFacilityManagementUpdates,
      invoice: retrievedSettings.notifyOnFacilityInvoiceUpdates,
      documents: retrievedSettings.notifyOnFacilityDocumentUpdates
    }
  }) as NotificationSettings[]
}

function applyRolesToNotificationSettings(
  notificationSetting: NotificationSettings,
  contactPersonRoles: PossibleContactRoles[]
): string[] {
  const allowedRolesFacility = [
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.EXTERNAL
  ]
  const allowedRolesInvoice = [
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FINANCE
  ]
  const allowedRolesDocuments = [
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.GENERAL_MANAGER
  ]
  return Object.keys(notificationSetting).filter(key => {
    switch (key) {
      case "id":
      case "rentedBuildingId":
        return false
      case "facility":
        return allowedRolesFacility.some(allowedRole =>
          contactPersonRoles.includes(allowedRole)
        )
      case "invoice":
        return allowedRolesInvoice.some(allowedRole =>
          contactPersonRoles.includes(allowedRole)
        )
      case "documents":
        return allowedRolesDocuments.some(allowedRole =>
          contactPersonRoles.includes(allowedRole)
        )
      default:
        return true
    }
  })
}

export function mapReducerDataToNestedCheckboxDataStructure(
  hasMultipleCompanies: boolean,
  rentedBuilding: RentedBuilding,
  notificationSetting: NotificationSettings,
  contactPersonRoles: PossibleContactRoles[]
): Data {
  const notifications = applyRolesToNotificationSettings(
    notificationSetting,
    contactPersonRoles
  )
  const parent = {
    label: `${rentedBuilding.street} ${rentedBuilding.houseNo}, ${rentedBuilding.city}`,
    customerName: hasMultipleCompanies ? rentedBuilding.entityName : undefined,
    selected: notifications.every(
      n => notificationSetting[n as keyof NotificationSettings] === true
    )
  }
  const children = notifications.map((name: string) => ({
    label: `portal.onboarding.step3.notifications.${name}`,
    name,
    guidanceText: `portal.onboarding.step3.notifications.${name}.guidance`,
    selected: notificationSetting[name as keyof NotificationSettings] as boolean
  }))

  return { parent, children }
}

export function reduceNestedCheckboxDataToUpdateDto(
  rentedBuildingId: string,
  checkboxData: Data
): UpdateNotificationSettingsDto {
  const { children } = checkboxData
  return children.reduce(
    (updateObj, currentChild) => {
      (updateObj[
        currentChild.name as keyof UpdateNotificationSettingsDto
      ] as boolean) = currentChild.selected
      return updateObj
    },
    {
      rentedBuildingId,
      facility: false,
      invoice: false,
      documents: false
    } as UpdateNotificationSettingsDto
  )
}
