import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ContactOptions = styled.div<{ hasPhone?: string }>`
  display: grid;
  grid-template-columns: repeat(${props => (props.hasPhone ? 2 : 1)}, 1fr);
  gap: 0 4rem;
  text-align: center;
  margin: 4rem 2rem 3.2rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 4rem auto 3.2rem;
  }
`

export const Option = styled.a`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.caption};
  word-break: break-all;
  text-decoration: none;
`

export const OptionCaption = styled.p`
  min-height: 4rem;
  white-space: pre-line;

  & > span:first-child {
    color: ${theme.colors.neutral.darkerGrey};
    font-size: ${theme.fontSizes.caption};
    line-height: ${theme.lineHeights.captionSmall};
    font-weight: ${theme.fontWeights.bold};
  }

  & > span:nth-child(2) {
    margin-top: 0.4rem;
  }
`

export const OptionCaptionTitle = styled.span`
  display: block;
  line-height: ${theme.lineHeights.captionSmall};
`

export const OptionCaptionText = styled.span`
  line-height: ${theme.lineHeights.captionSmall};
  display: inline-block;
`

export const ContactButton = styled.div`
  display: block;
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  background: ${theme.colors.oceanGreen.prc125};
  margin: 0 auto 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
