import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const PaginationBar = styled.div<{ hasShadow: boolean }>`
  background: ${theme.colors.neutral.white};
  height: 7rem;
  grid-area: energy;
  border-top: 1px solid ${theme.colors.neutral.midGrey};
  position: sticky;
  top: 7rem;
  z-index: 1;
  box-shadow: ${props => (props.hasShadow ? theme.effects.dropShadow : "none")};

  @media only screen and (${theme.breakpoints.tablet}) {
    height: 9.4rem;
    max-width: 144rem;
    margin: 0 auto 2.4rem;
    top: 15.7rem;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    height: 12rem;
    top: 8rem;
  }
`

export const SelectWrapper = styled.div`
  flex: 1;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 25rem;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const PeriodButton = styled.button`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.green.contrast};
  background: transparent;
  border: 0;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & strong {
    font-weight: ${theme.fontWeights.bold};
  }
`

export const PreviousPeriodButton = styled(PeriodButton)`
  text-align: right;
  margin-right: 0.8rem;
`

export const NextPeriodButton = styled(PeriodButton)`
  text-align: left;
  margin-left: 0.8rem;
`

export const StyledLabel = styled.h2`
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.caption};
  display: none;

  @media only screen and (${theme.breakpoints.tablet}) {
    display: block;
    font-size: ${theme.fontSizes.paragraph};
    margin-right: 3.2rem;
  }
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;

    justify-content: flex-start;
    width: ${theme.breakpointsValues.tablet}px;
    margin: 0 auto;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    width: ${theme.breakpointsValues.desktop}px;
  }
`
