import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const BuildingSwitcherContainer = styled.div<{ columns?: number }>`
  margin: 3.2rem;
  display: flex;
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 0 3.2rem;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    justify-content: flex-start;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 6rem;
`
