import { WarningIcon } from "@paudigital/wdp-components"
import {
  StyledBaseCardWrapper,
  StyledErrorContent,
  StyledErrorText,
  StyledErrorTextWrapper,
  StyledErrorTitle
} from "./style"

type RestProps = {
  title: string
  content: string | JSX.Element
}

type Props = RestProps

export const ServerError = ({ title, content }: Props) => {
  return (
    <StyledBaseCardWrapper
      backgroundColor="white"
      boxShadow="dropShadow"
      borderStyle="solid"
      statusLine="error"
    >
      <StyledErrorContent>
        <WarningIcon height="2.4rem" width="2.4rem" fill="red" />
        <StyledErrorTextWrapper>
          <StyledErrorTitle>{title}</StyledErrorTitle>
          <StyledErrorText>{content}</StyledErrorText>
        </StyledErrorTextWrapper>
      </StyledErrorContent>
    </StyledBaseCardWrapper>
  )
}

export default ServerError
