import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const SpecificationItemLabel = styled.label<{
  hasExplanation?: boolean
}>`
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.bold};
  max-width: 20rem;
  display: block;
  ${props =>
    props.hasExplanation && "text-decoration: underline; cursor: pointer;"}
`

export const SpecificationItemContent = styled.div`
  color: ${theme.colors.neutral.darkerGrey};
`

export const SpecificationItemContainer = styled.div`
  display: flex;
  margin-bottom: 0.8rem;
  font-size: ${theme.fontSizes.captionSmall};
  flex-direction: column;
`