import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  background: ${theme.colors.neutral.white};
  border-radius: 4px;
  padding: 8rem 1.6rem 1.6rem 1.6rem;
  position: relative;
  margin-top: 7rem;
  grid-area: contact;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const StyledImage = styled.img`
  border-radius: 50%;
  height: 8.9rem;
  width: 8.9rem;
  border: 4px solid ${theme.colors.neutral.white};
  position: absolute;
  top: -4.8rem;
`

export const StyledDescription = styled.span`
  margin: 1.2rem 0 3.2rem 0;
  color: ${theme.colors.neutral.darkGrey};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
`

export const ExpertSelection = styled.div`
  max-width: 32.5rem;
  margin: 3.2rem auto 8rem;
`

export const ExpertDetailsContainer = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.colors.neutral.lightGrey};
  margin: 2.4rem;

  @media only screen and (${theme.breakpoints.desktop}) {
    position: sticky;
  }
`

export const ExpertDetails = styled.div`
  margin: 3rem 2rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0 12rem;
  }
`

export const ExpertDetailsContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  span {
    text-align: center;
  }
`
export const ExpertGreeting = styled.div`
  margin: 7.6rem auto 0;
  max-width: 36rem;
`

export const Title = styled.h3`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.h5};
  font-weight: ${theme.fontWeights.bold};
  line-height: ${theme.lineHeights.h5};
  text-align: center;
`
