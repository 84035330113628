import { SurveyAnswer } from "app/types/survey"
import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export function getSurveys(): AxiosPromise {
  return rentalApi("/surveys", {
    method: "GET"
  })
}

export function setSurvey({ surveyId, ...data }: SurveyAnswer): AxiosPromise {
  return rentalApi(`/survey_questions/${surveyId}`, {
    method: "POST",
    data
  })
}
