import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const TimeLeftWrapper = styled.span`
  margin-left: -0.6rem;
  align-items: center;
  display: inline-flex;
`

export const StyledTimeLeft = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  margin-right: 0.6rem;
`

export const RefreshContainer = styled.div<{ enabled: boolean }>`
  cursor: ${props => props.enabled && "pointer"};
`
