import styled from "styled-components"

export const StyledFilterTags = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 2.4rem 0;
  gap: 1.6rem 1.6rem;
  max-width: 100vw;
`
