import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz"

const timeZoneMapping: Record<string, string> = {
  "Belgium": "Europe/Brussels",
  "Netherlands": "Europe/Amsterdam",
  "Luxembourg": "Europe/Luxembourg",
  "France": "Europe/Paris",
  "Romania": "Europe/Bucharest",
  "Czech Republic": "Europe/Prague"
}

export const getUTCIsoDate = (
  dateString: string,
  country: string
): string => {
  return zonedTimeToUtc(dateString, timeZoneMapping[country]).toISOString()
}

export const getZonedDateFromUTCIsoDate = (
  utcIsoDateString: string,
  country: string
): Date => {
  return utcToZonedTime(utcIsoDateString, timeZoneMapping[country])
}
