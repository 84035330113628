import { Button, InputElement, Link, Rating } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { ChangeEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  FormWrapper,
  LinkWrapper,
  SurveyCompletedText,
  SurveyCompletedTitle,
  SurveyTitle,
  SurveyWrapper
} from "./style"
import * as surveysSelectors from "app/redux/surveys/surveys.selectors"
import * as meSelectors from "app/redux/me/me.selectors"
import { SurveyTarget } from "app/types/survey"
import { delay } from "lodash"
import { useFormik } from "formik"
import { setSurvey, setSurveySuccess } from "app/redux/surveys/surveys.actions"
import { patchContactPerson } from "app/redux/contactPerson/contactPerson.actions"
import { MyProfile } from "app/types/myProfile"
import { getUser } from "app/redux/me/me.selectors"

const DELAY_AFTER_RATING_AMOUNT = 200

type SurveyRatingProps = {
  onClick: (rating: number) => void
}

const SurveyRating = ({ onClick }: SurveyRatingProps) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const user: MyProfile = useSelector(getUser) as MyProfile

  const removeSurveys = () => {
    dispatch(
      patchContactPerson(user!.id, {
        ...user,
        receiveSurveys: false
      })
    )
    dispatch(setSurveySuccess())
  }
  return (
    <SurveyWrapper>
      <SurveyTitle>
        {translate(
          "portal.facilitymanagement.createticket.delete.cancel.survey.rating"
        )}
      </SurveyTitle>
      <Rating onUserRating={onClick} />
      <LinkWrapper>
        <Link
          onClick={removeSurveys}
          type="default"
          value="Don't  ask  again"
        />
      </LinkWrapper>
    </SurveyWrapper>
  )
}

type SurveyQuestionProps = {
  question?: string
  response: string
  onSubmit: () => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const SurveyQuestion = ({
  question,
  response,
  onChange,
  onSubmit
}: SurveyQuestionProps) => {
  const { translate } = useTranslate()
  return (
    <SurveyWrapper>
      <FormWrapper onSubmit={onSubmit}>
        <InputElement
          type="textarea"
          onChange={onChange}
          label={question}
          value={response}
        />
        <Button
          submit
          align="right"
          label={translate(
            "portal.facilitymanagement.createticket.delete.cancel.survey.question.submit"
          )}
        />
      </FormWrapper>
    </SurveyWrapper>
  )
}

const SurveyCompleted = () => {
  const { translate } = useTranslate()

  return (
    <SurveyWrapper>
      <SurveyCompletedTitle>
        {translate(
          "portal.facilitymanagement.createticket.delete.cancel.survey.completed.title"
        )}
      </SurveyCompletedTitle>
      <SurveyCompletedText>
        {translate(
          "portal.facilitymanagement.createticket.delete.cancel.survey.completed.text"
        )}
      </SurveyCompletedText>
    </SurveyWrapper>
  )
}

type SurveyProps = {
  onStart?: () => void
}

const Survey = ({ onStart }: SurveyProps) => {
  const dispatch = useDispatch()
  const survey = useSelector(
    surveysSelectors.getSurveyByTarget(SurveyTarget.TICKET_CREATION)
  )
  const isDone = useSelector(surveysSelectors.hasDoneSurvey)

  const {
    handleSubmit,
    setFieldValue,
    values: { response }
  } = useFormik({
    initialValues: {
      rating: 0,
      response: ""
    },
    onSubmit: values => {
      if (survey?.surveyId) {
        dispatch(setSurvey({ surveyId: survey.surveyId, ...values }))
      }
    }
  })

  const language = useSelector(meSelectors.getPreferredLanguage)
  const [hasRated, setHasRated] = useState(false)

  const handleUserRating = (rating: number) => {
    onStart?.()
    setFieldValue("rating", rating)
    delay(() => {
      setHasRated(true)
    }, DELAY_AFTER_RATING_AMOUNT)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue("response", e.currentTarget.value)
  }

  if (!survey) {
    // survey already completed so we should render nothing
    return null
  }

  if (isDone) {
    return <SurveyCompleted />
  }

  if (hasRated) {
    return (
      <SurveyQuestion
        question={survey?.question?.[language]}
        response={response}
        onSubmit={handleSubmit}
        onChange={handleChange}
      />
    )
  }

  return <SurveyRating onClick={handleUserRating} />
}

export default Survey
