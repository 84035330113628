import {
  Logo,
  theme
} from "@paudigital/wdp-components"
import React, { useEffect } from "react"
import {
  NavigationContainer,
  NavigationWrapper,
  StyledNavigation,
  LogoWrapper,
  AdminSwitcherContainer,
  LogoutButton
} from "./style"
import { useViewport } from "hooks/viewport"
import { useScroll } from "hooks/scroll"
import { useDispatch } from "react-redux"
import { Modules } from "app/enums/modules"
import * as actions from "app/redux/notifications/notifications.actions"
import WdpAdminBuildingSwitcher from "../WdpAdminBuildingSwitcher"
import { useTranslate } from "hooks/translate"
import { useLogout } from "hooks/logout"

type Props = {
  module?: Modules
}

const WdpAdminNavigation = ({ module }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { y: scrollY } = useScroll()
  const { width } = useViewport()
  const { logout } = useLogout()
  const { translate } = useTranslate()
  const [active, setActive] = React.useState(true)
  const [pastScrollThreshold, setPastScrollThreshold] = React.useState(false)

  const tablet = theme.breakpointsValues.tablet
  const isDesktop = width >= theme.breakpointsValues.desktop

  useEffect(() => {
    dispatch(actions.getNotificationsCount())
  }, [dispatch])

  React.useEffect(() => {
    const height = pastScrollThreshold ? 80 : 160
    setPastScrollThreshold(Math.abs(scrollY) >= height)
  }, [pastScrollThreshold, scrollY])

  React.useEffect(() => {
    setActive(width >= tablet)
  }, [tablet, width])

  const handleLogout = () => {
    logout()
  }

  return (
    <NavigationContainer isSticky={!pastScrollThreshold}>
      <NavigationWrapper isActive={active}>
        <StyledNavigation active={active} multiRow={!pastScrollThreshold}>
          <LogoWrapper data-testid="wdp-logo" to="/" active={active.toString()}>
            <Logo
              hasByline={isDesktop && !pastScrollThreshold}
              showBorder={true}
            />
          </LogoWrapper>
          <AdminSwitcherContainer>
            <WdpAdminBuildingSwitcher isVisible={true}/>
          </AdminSwitcherContainer>
          <LogoutButton
            label={translate("portal.navigation.button.logout")}
            onClick={handleLogout}
            fullWidth
          />
        </StyledNavigation>
      </NavigationWrapper>
    </NavigationContainer>
  )
}

export default WdpAdminNavigation
