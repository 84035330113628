import { Button } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import React from "react"
import Icon from "./icon"
import { Locales } from "app/i18n/config"
import {
    StyledDiv as StyledNoMetersWrapper,
    StyledNoEnergyTitle as StyledNoMetersTitle,
    StyledNoEnergyText,
    StyledLink,
    HiddenEmailAnchor,
    StyledNoMetersContentWrapper
} from "./style"
import { useSelector } from "react-redux"
import * as selectors from "app/redux/me/me.selectors"

const NoMetersInstalled = () => {
  const { translate } = useTranslate()
  const ref = React.createRef<HTMLAnchorElement>()
  const preferredLanguage: Locales = useSelector(selectors.getPreferredLanguage)

  const handleButtonClick = () => {
    ref.current?.click()
  }

  const emailContact = "frank.ceusters@wdp.eu"

  const urlMapping: { [key: string]: string } = {
    [Locales.EN_GB]: "https://www.wdp.eu/wdp-smart-energy-platform-0",
    [Locales.FR_FR]: "https://www.wdp.eu/fr/wdp-smart-energy-platform-0",
    [Locales.NL_BE]: "https://www.wdp.eu/nl/wdp-smart-energy-platform-0",
    [Locales.NL_NL]: "https://www.wdp.eu/nl/wdp-smart-energy-platform-0"
  }

  return (
    <StyledNoMetersWrapper>
      <StyledNoMetersContentWrapper>
        <Icon/>
        <StyledNoMetersTitle>{translate("portal.dashboard.widget.energy.nodata.startMonitoring")}</StyledNoMetersTitle>
        <StyledNoEnergyText>{translate("portal.dashboard.widget.energy.nodata.installNanogrid")}</StyledNoEnergyText>
        <Button onClick={handleButtonClick} label={translate("portal.dashboard.widget.energy.nodata.contact")}/>
        <HiddenEmailAnchor ref={ref} href={`mailto:${emailContact}`}/>
        <StyledLink target="_blank" href={urlMapping[preferredLanguage]}>{translate("portal.dashboard.widget.energy.nodata.moreInfo")}</StyledLink>
      </StyledNoMetersContentWrapper>
    </StyledNoMetersWrapper>
  )
}

export default NoMetersInstalled
