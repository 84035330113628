import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const TypographyWrapper = styled.div`
  margin-top: 2.4rem;
  text-align: center;
`
