import { Periods } from "app/redux/energyPeriod/energyPeriod.reducer"
import { EnergyUsageComparisonData } from "app/types/energy"
import { useTranslate } from "hooks/translate"
import NoValue from "../NoValue"
import { StyledH5, StyledText, StyledSubText, StyledDisclaimer } from "../style"
import {
  convertPeriod,
  convertToTons,
  unitMapping
} from "../utils/energyHelper.utils"
import { TitleContainer } from "./style"
import TitleComparison from "./TitleComparison"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"
import { getNumberOfDays, Period } from "app/utils/energy.utils"
import { useSelector } from "react-redux"
import { getEnergyPeriodRange } from "app/redux/energyPeriod/energyPeriod.selectors"
import { convertPeriodToDays } from "app/utils/periodInDays.utils"

type EnergyComparisonCardTitleProps = {
  comparisonData: EnergyUsageComparisonData
  period: Periods
  isCO2: boolean
  hasSolar: boolean
}

type Props = EnergyComparisonCardTitleProps

const EnergyComparisonCardTitle = ({
  comparisonData,
  period,
  isCO2 = false,
  hasSolar = false
}: Props) => {
  const { translate } = useTranslate()
  const { from, to } = useSelector(getEnergyPeriodRange)
  const { integerFormatter } = getLocaleNumberFormatter()
  const {
    name,
    currentPeriodTotal,
    previousPeriodTotal,
    percentageDifference
  } = comparisonData
  const unit = unitMapping[comparisonData.name]
  const currTotal = isCO2
    ? convertToTons(currentPeriodTotal!)
    : currentPeriodTotal!
  const totalDifference =
    currentPeriodTotal && previousPeriodTotal
      ? isCO2
        ? convertToTons(currentPeriodTotal - previousPeriodTotal)
        : currentPeriodTotal - previousPeriodTotal
      : undefined

  const days = convertPeriodToDays(from, to)

  const periodSubTitle = (inline = false) => (
    <StyledSubText uppercased fontWeight="medium" inline={inline}>
      / {getPeriodText}
    </StyledSubText>
  )
  const convertedPeriod = convertPeriod(period) as Period

  const getPeriodText = (() =>
    convertedPeriod === Periods.custom
      ? translate(`portal.energy.period.custom.days`, { days })
      : translate(`portal.energy.period.${convertedPeriod}`))()

  return (
    <TitleContainer>
      <StyledText>
        {translate(`portal.energy.comparison.overview.${name}`)}
      </StyledText>
      <StyledH5>
        {currTotal ? integerFormatter(currTotal) : <NoValue />} {unit}
        {name === "electricityConsumption" && hasSolar && periodSubTitle(true)}
        {isCO2 && <StyledDisclaimer>*</StyledDisclaimer>}
      </StyledH5>
      {name === "electricityConsumption" && hasSolar ? (
        <TitleComparison
          measuringUnit={unit}
          numberOfDays={getNumberOfDays(convertedPeriod, days)}
          percentageDifference={percentageDifference}
          totalDifference={totalDifference}
        />
      ) : (
        periodSubTitle()
      )}
    </TitleContainer>
  )
}

export default EnergyComparisonCardTitle
