// import React from "react"
import { NoNotificationsFound } from "@paudigital/wdp-components"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { usePermission } from "hooks/permission"
import {
  StyledContainer,
  ImageContainer,
  StyledH1,
  StyledSubtitle,
  StyledLink
} from "./style"
import { Modules } from "app/enums/modules"
import { useMemo } from "react"

const NoNotifications = () => {
  const rentedBuildingId = useSelector(getActiveBuilding)
  const { hasPermission } = usePermission()
  const navigate = useNavigate()
  const { translate } = useTranslate()
  const hasAccess = useMemo(() => {
    return (
      rentedBuildingId && hasPermission(Modules.DASHBOARD, rentedBuildingId)
    )
  }, [rentedBuildingId, hasPermission])

  const linkTranslation = useMemo(() => {
    if (hasAccess) {
      return translate("portal.notifications.noData.dashboard-link")
    }
    return translate("portal.notifications.noData.facility-management-link")
  }, [hasAccess, translate])

  const handleOnClick = () => {
    if (hasAccess) {
      return navigate(`/dashboard/${rentedBuildingId}`)
    }
    return navigate(`/facility-management/${rentedBuildingId}`)
  }

  return (
    <StyledContainer>
      <ImageContainer>
        <NoNotificationsFound />
      </ImageContainer>
      <StyledH1>{translate("portal.notifications.noData.title")}</StyledH1>
      <StyledSubtitle>
        {translate("portal.notifications.noData.subtitle")}
      </StyledSubtitle>
      <StyledLink onClick={handleOnClick}>{linkTranslation}</StyledLink>
    </StyledContainer>
  )
}

export default NoNotifications
