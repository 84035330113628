import { Auth0Provider } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import { AUDIENCE, AUTH0_DOMAIN, CLIENT_ID } from "app/api/config"

function Auth0ProviderWithHistory({ children }: any) {
  const domain = AUTH0_DOMAIN
  const clientId = CLIENT_ID
  const audience = AUDIENCE
  const navigate = useNavigate()

  const onRedirectCallback = (appState: any) => {
    if (appState) {
      navigate(appState?.returnTo)
    }
  }

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      scope="offline_access"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
