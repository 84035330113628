import {
  Data,
  GroupedCheckbox,
  changeChildValue
} from "@paudigital/wdp-components"
import { updateNotifications } from "app/redux/notificationSettings/notificationSettings.actions"
import { reduceNestedCheckboxDataToUpdateDto } from "app/utils/notificationSettings"
import { useApi } from "hooks/api"
import { useTranslate } from "hooks/translate"

type SingleBuildingNotificationSettingsProps = {
  rentedBuildingId: string
  settingsData: Data
}

type Props = SingleBuildingNotificationSettingsProps

const SingleBuildingNotificationSettings = ({
  rentedBuildingId,
  settingsData
}: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()

  const translatedSettingsData = {
    parent: settingsData.parent,
    children: settingsData.children.map(currentChild => {
      return {
        label: translate(currentChild.label),
        name: currentChild.name ?? "",
        guidanceText: translate(currentChild.guidanceText ?? ""),
        selected: currentChild.selected
      }
    })
  }

  const handleChange = (val: boolean, name: string) => {
    const updateData = reduceNestedCheckboxDataToUpdateDto(
      rentedBuildingId,
      changeChildValue(settingsData, val, name)
    )
    call(updateNotifications(updateData))
  }

  return (
    <GroupedCheckbox
      onChange={handleChange}
      data={translatedSettingsData.children}
    />
  )
}

export default SingleBuildingNotificationSettings
