import { useTranslate } from "hooks/translate"
import React from "react"
import BaseGraph from "../BaseGraph"
import { Graphs } from "app/utils/graphs/graph.utils"
import { useSelector } from "react-redux"
import {
  getElectricityMeterHistoryData,
  getLastUpdated,
  hasWarning,
  isLoading
} from "app/redux/electricityMeterHistory/electricityMeterHistory.selectors"
import {
  getIntervalOfGraph,
  getEnergyPeriodRange
} from "app/redux/energyPeriod/energyPeriod.selectors"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useApi } from "hooks/api"
import { getElectricityMeterHistory } from "app/redux/electricityMeterHistory/electricityMeterHistory.actions"

const ElectricityConsumptionGraph = () => {
  const { call } = useApi()
  const { rentedBuilding } = useRentedBuilding()
  const warning = useSelector(hasWarning)
  const electricityMeterHistory = useSelector(getElectricityMeterHistoryData)
  const interval = useSelector(
    getIntervalOfGraph(Graphs.ELECTRICITY_CONSUMPTION)
  )
  const lastUpdated = useSelector(getLastUpdated)
  const energyPeriod = useSelector(getEnergyPeriodRange)
  const loading = useSelector(isLoading)
  const { translate } = useTranslate()

  React.useEffect(() => {
    if (rentedBuilding && rentedBuilding.id) {
      call(
        getElectricityMeterHistory(rentedBuilding, {
          interval: interval,
          from: energyPeriod.from,
          to: energyPeriod.to
        })
      )
    }
  }, [call, energyPeriod.from, energyPeriod.to, interval, rentedBuilding])

  const refreshGraph = () => {
    call(
      getElectricityMeterHistory(rentedBuilding!, {
        interval: interval,
        from: energyPeriod.from,
        to: energyPeriod.to
      })
    )
  }

  const getTitle = () => {
    const noSolar = !electricityMeterHistory?.find(
      d => d.name === "solarInjection"
    )
    return noSolar
      ? translate("portal.energy.graphs.electricity.title.nosolar")
      : translate("portal.energy.graphs.electricity.title")
  }

  return (
    <BaseGraph
      lastUpdated={lastUpdated}
      refreshGraph={refreshGraph}
      loading={loading}
      title={getTitle()}
      graphType={Graphs.ELECTRICITY_CONSUMPTION}
      graphSerieData={electricityMeterHistory}
      warning={warning}
    />
  )
}

export default ElectricityConsumptionGraph
