import { BaseCard, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ChartContainer = styled(BaseCard)`
  cursor: default;
  width: inherit;
  margin: 0.8rem 0;
  padding: 0.8rem 1.2rem;
  flex-direction: column;
  align-items: initial;
`

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
`
export const ChartHeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const StyledTotalUsageWrapper = styled.div`
  margin-left: 1.6rem;
`
export const StyledEnergyMeasurement = styled.div`
  display: block;
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};

  & > span {
    color: ${theme.colors.concrete.prc75};
  }
`
export const StyledEnergyMeasurementType = styled.div`
  display: block;
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.light};
  color: ${theme.colors.neutral.darkerGrey};
`
export const StyledPercentageDisplay = styled.div`
  padding: 0.8rem 1.2rem;
  background: ${theme.colors.celestial.prc50};
  border-radius: 64px;
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  font-weight: ${theme.fontWeights.light};
  color: ${theme.colors.neutral.black};

  & > span {
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.neutral.darkerGrey};
  }
`
