import { TicketSummary } from "app/types/ticketSummary"
import {
  AnswerCode,
  Ticket,
  PostTicket,
  FacilityTicketFilters,
  TicketStatistics
} from "app/types/ticket"
import State from "../state"

export const loading = (state: State): boolean => state.ticket.loading
export const getTicket = (state: State): Partial<PostTicket> =>
  state.ticket.ticket
export const getAnswerCodes = (state: State): AnswerCode[] =>
  state.ticket.ticket.answerCodes || []
export const getSummary = (state: State): TicketSummary | undefined =>
  state.ticket.summary
export const getTickets = (state: State): Ticket[] =>
  state.ticket.tickets.facilityTickets
export const getTotalTickets = (state: State): number =>
  state.ticket.tickets.total
export const getTicketsFilterIsOpen = (state: State): boolean =>
  state.ticket.isFilterOpen
export const getErrors = (state: State): Record<string, any> =>
  state.ticket.errors
export const getParams = (state: State): FacilityTicketFilters =>
  state.ticket.tickets.filters
export const getTicketStatistics = (
  state: State
): TicketStatistics | undefined => state.ticket.tickets.ticketStatistics

export const getTicketById = (id: string) => (
  state: State
): Ticket | undefined => {
  const tickets = getTickets(state)
  return tickets.find(ticket => ticket.ticketId === id)
}
