import { Link, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ListItem = styled.li`
  list-style-type: disc;
  list-style-position: inside;
  font-weight: ${theme.fontWeights.light};
`

export const StyledSpan = styled.span`
  font-weight: ${theme.fontWeights.light};
  display: block;

  &:first-of-type {
    margin-bottom: 1.2rem;
  }

  &:last-of-type {
    margin-top: 1.2rem;
  }
`

export const StyledLink = styled(Link)`
  margin-left: 0.4rem;
  font-size: inherit;
`
