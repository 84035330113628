import { Checkbox } from "@paudigital/wdp-components"
import { StyledStatus, StatusText } from "./style"

export type TypeProps = {
  contractNumber: string
  checked: boolean
  onClick: (contractNumber: string) => void
}

type Props = TypeProps

const ContractNumber = ({ contractNumber, checked, onClick }: Props) => {
  const handleClick = () => {
    onClick(contractNumber)
  }

  return (
    <StyledStatus onClick={handleClick}>
      <Checkbox checked={checked} onClick={handleClick} />
      <StatusText>{contractNumber}</StatusText>
    </StyledStatus>
  )
}

export default ContractNumber
