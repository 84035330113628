import { PreferredBuildingCard } from "@paudigital/wdp-components"
import { RentedBuilding } from "app/types/rentedBuilding"
import * as meSelectors from "app/redux/me/me.selectors"
import { useSelector } from "react-redux"
import image from "./warehouse.jpg"

type PreferedBuildingProps = {
  rentedBuilding: RentedBuilding
  onClick: (id: string) => void
  selected: boolean
}

type Props = PreferedBuildingProps

const PreferedBuilding = ({ rentedBuilding, onClick, selected }: Props) => {
  const hasMultipleCompanies = useSelector(meSelectors.hasMultipleCompanies)
  const handleClick = () => onClick(rentedBuilding.id)
  const city = hasMultipleCompanies
    ? `${rentedBuilding.city} (${rentedBuilding.entityName})`
    : rentedBuilding.city
  return (
    <PreferredBuildingCard
      avatar={image}
      onClick={handleClick}
      active={selected}
      address={`${rentedBuilding.street} ${rentedBuilding.houseNo}`}
      city={city}
      dataTestId={`building.preference.${city}`}
    />
  )
}

export default PreferedBuilding
