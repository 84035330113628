import { ExportIcon, SpinnerIcon, theme } from "@paudigital/wdp-components"
import { WideButton } from "./style"

type Props = {
  disabled?: boolean
  loading?: boolean
  label: string
  onClick: () => void
}

const ExportButton = ({ disabled, loading, onClick, label }: Props) => {
  const Icon = () => {
    return loading ? (
      <SpinnerIcon
        width={"2.4rem"}
        height={"2.4rem"}
        fill={theme.colors.neutral.white}
      />
    ) : (
      <ExportIcon fill={theme.colors.neutral.white} />
    )
  }

  return (
    <WideButton
      fullWidth
      label={label}
      onClick={onClick}
      startIcon={<Icon />}
      disabled={disabled}
    />
  )
}

export default ExportButton
