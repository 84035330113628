import { getCampaign } from "app/api/campaign"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { getActiveBuilding } from "../buildingSwitcher/buildingSwitcher.selectors"
import * as actions from "./campaign.actions"

export function* getCampaignFlow(
  action: actions.GetCampaignAction
): SagaIterator {
  try {
    const rentedBuildingId = yield select(getActiveBuilding)
    const response = yield call(getCampaign, rentedBuildingId)
    yield put(actions.getCampaignSuccess(response.data))
  } catch (e: any) {
    yield put(actions.getCampaignFail(e))
  }
}

export default function* campaignSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET_CAMPAIGN, getCampaignFlow)
}
