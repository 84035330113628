import { theme } from "@paudigital/wdp-components"
import styled, { css } from "styled-components"

export const ContentWrapper = styled.div`
  border: 1.5px solid ${theme.colors.neutral.midGrey};
  border-radius: 4px;
  margin: 2rem 0;
  &:last-of-type {
    margin: 2rem 0 0;
  }
`

export const WorkOrderInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 2rem;
  max-width: 80%;
  @media only screen and (${theme.breakpoints.tablet}) {
    width: 60%;
  }
  @media only screen and (${theme.breakpoints.desktop}) {
    width: 60%;
  }
`

export const InfoWrapper = styled.div<{ alignment?: string }>`
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${props =>
    props.alignment === "vertical" &&
    css`
      margin-top: 1.8rem;
    `}

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    ${props =>
      props.alignment === "vertical" &&
      css`
        flex-direction: column;
        &:last-child p {
          margin-top: 0.6rem;
        }
      `}
`

export const InfoTitle = styled.h6`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const InfoValue = styled.p`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.light};
  color: ${theme.colors.neutral.darkerGrey};
`
