import Navigation from "../Navigation"
import ContentContainer from "../ContentContainer"
import AppEventsProvider from "../AppEventsProvider"
import { Modules } from "app/enums/modules"
import NotFound from "app/components/NotFound"
import { LoadingScreen } from "@paudigital/wdp-components"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getNotificationsCount } from "app/redux/notifications/notifications.actions"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { isRunning } from "app/redux/notifications/notifications.selectors"
import { getHttpErrorStatus } from "app/redux/httpErrors/httpErrors.selectors"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { usePermission } from "hooks/permission"
import {
  getLastPasswordChange,
  getProfile,
  loading
} from "app/redux/me/me.selectors"
import { useTranslate } from "hooks/translate"
import ServerError from "../ServerError"
import { clearHttpError } from "app/redux/httpErrors/httpErrors.actions"
import isServerBasedError from "app/utils/isServerBasedError.utils"
import { StyledContactLink, StyledServerErrorContainer } from "./style"
import { sub } from "date-fns"
import { olderThen } from "app/utils/olderThen.utils"
import { useNavigate } from "react-router-dom"

type Props = {
  children: JSX.Element
  pageheader?: JSX.Element
  pullContent?: boolean
  module?: Modules
}

const Layout = ({
  children,
  pageheader,
  pullContent,
  module
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const buildingId = useSelector(getActiveBuilding)
  const { translate } = useTranslate()
  const isLoading = useSelector(loading)
  const profile = useSelector(getProfile)
  const running = useSelector(isRunning)
  const hasError = useSelector(getHttpErrorStatus)
  const { rentedBuilding } = useRentedBuilding()
  const { hasPermission } = usePermission()
  const userLastPasswordChange = useSelector(getLastPasswordChange)
  const navigate = useNavigate()

  const hasAccess = useMemo(() => {
    if (rentedBuilding?.id && module) {
      return hasPermission(module, rentedBuilding.id)
    }
    return true
  }, [module, rentedBuilding?.id, hasPermission])

  useEffect(() => {
    if (
      userLastPasswordChange &&
      olderThen(new Date(userLastPasswordChange), sub(new Date(), { days: 90 }))
    ) {
      navigate("/password-change")
    }
  }, [userLastPasswordChange, navigate])

  useEffect(() => {
    if (!buildingId) return

    if (!running) dispatch(getNotificationsCount())
  }, [dispatch, buildingId, module, running])

  useEffect(() => {
    dispatch(clearHttpError())
  }, [dispatch])

  if ((isLoading && !profile) || (!isLoading && !profile)) {
    return (
      <LoadingScreen
        patienceText={translate("portal.loading.patience")}
        loadingText={translate("portal.loading.loading")}
      />
    )
  }

  return (
    <>
      <Navigation module={module} />
      {hasError ? (
        isServerBasedError(hasError) && (
          <StyledServerErrorContainer>
            <ServerError
              title={translate("portal.error.server.connection-problem")}
              content={
                <>
                  {translate("portal.error.server.persist")}
                  <StyledContactLink href="mailto:customerportal@wdp.eu">
                    {translate("portal.error.server.helpdesk-contact")}
                  </StyledContactLink>
                </>
              }
            />
          </StyledServerErrorContainer>
        )
      ) : (
        <></>
      )}
      {pageheader && <>{pageheader}</>}
      <ContentContainer pullContent={pullContent}>
        {hasAccess ? children : <NotFound />}
      </ContentContainer>
      <AppEventsProvider local={false} />
    </>
  )
}
export default Layout
