import { Stepper, SlideIn } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { Container, HelpContainer, HelpText } from "./style"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { clearHttpError } from "app/redux/httpErrors/httpErrors.actions"
import { getStep } from "app/redux/mfaOtpWizard/mfaOtpWizard.selectors"
import { associate } from "app/redux/mfa/mfa.actions"
import {
  authenticate,
  authenticateReset
} from "app/redux/authentication/authentication.actions"
import { actions as wizardActions } from "app/redux/mfaOtpWizard/mfaOtpWizard.actions"
import VerifyIdentity from "app/components/VerifyIdentity"
import Associate from "./Associate"
import { useNavigate } from "react-router-dom"
import { Audiences, Scopes } from "app/api/auth0"
import { Action as ReduxAction } from "redux"
import { AssociationTypes } from "app/api/mfa"

const MfaOtpSetupPopUp = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const step = useSelector(getStep)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(clearHttpError())
    return () => {
      dispatch(remove("mfa-error"))
      dispatch(authenticateReset())
    }
  }, [dispatch])

  const handleClose = () => {
    dispatch(remove("mfa-set-up"))
    dispatch(wizardActions.clearWizard())
  }

  const handleOnSubmitVerification = (
    currentPassword: string,
    actionOnSuccess: () => ReduxAction<any>
  ) => {
    dispatch(
      authenticate({
        currentPassword,
        audience: Audiences.Mfa,
        scope: Scopes.Enroll,
        actionOnSuccess
      })
    )
  }

  const handleOnFaqClick = () => {
    navigate("/help")
    handleClose()
  }

  const handleOnSuccessAction = () => {
    return associate(AssociationTypes.OTP)
  }

  return (
    <SlideIn showCloseIcon show onClose={handleClose}>
      <>
        <Container>
          <Stepper
            stepText={translate("portal.account.account.mfa.otp.step.label")}
            activeStep={step}
            config={[
              {
                title: translate("portal.authentication.title"),
                content: (
                  <VerifyIdentity
                    actionOnSuccess={handleOnSuccessAction}
                    description={translate("portal.authentication.description")}
                    onSubmit={handleOnSubmitVerification}
                  />
                )
              },
              {
                title: translate("portal.account.account.mfa.otp.step2"),
                content: <Associate />
              }
            ]}
          />
        </Container>
        <HelpContainer>
          <HelpText onClick={handleOnFaqClick}>
            {translate("portal.navigation.item.faq")}
          </HelpText>
        </HelpContainer>
      </>
    </SlideIn>
  )
}

export default MfaOtpSetupPopUp
