const Icon = () => {
  return (
    <svg
      width="107"
      height="98"
      viewBox="0 0 107 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.8989 35.9642H55.6864V36.7757H56.8989V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M58.5154 35.9642H57.3029V36.7757H58.5154V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M60.1327 35.9642H58.9202V36.7757H60.1327V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M61.7491 35.9642H60.5366V36.7757H61.7491V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M63.3666 35.9642H62.1541V36.7757H63.3666V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M64.984 35.9642H63.7715V36.7757H64.984V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M66.6005 35.9642H65.3879V36.7757H66.6005V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M68.2179 35.9642H67.0054V36.7757H68.2179V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M69.8342 35.9642H68.6217V36.7757H69.8342V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M71.4517 35.9642H70.2391V36.7757H71.4517V35.9642Z"
        fill="#B0BEC5"
      />
      <path
        d="M97.9999 36.5275H45.83V94.9532H97.9999V36.5275Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M97.1386 75.5358H46.6925V93.6813H97.1386V75.5358Z"
        fill="#ECEFF1"
      />
      <path
        d="M52.7873 79.8404H50.8326V89.1375H52.7873V79.8404Z"
        fill="#EE730A"
      />
      <g opacity="0.7">
        <path
          opacity="0.7"
          d="M56.6465 79.8404H54.6919V89.1375H56.6465V79.8404Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M60.5057 79.8404H58.551V89.1375H60.5057V79.8404Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M64.365 79.8404H62.4104V89.1375H64.365V79.8404Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M68.2244 79.8404H66.2698V89.1375H68.2244V79.8404Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M72.0836 79.8404H70.1289V89.1375H72.0836V79.8404Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M75.9414 79.8404H73.9868V89.1375H75.9414V79.8404Z"
          fill="#EE730A"
        />
      </g>
      <path
        d="M89.9957 89.162C92.6007 89.162 94.7124 87.0431 94.7124 84.4292C94.7124 81.8154 92.6007 79.6964 89.9957 79.6964C87.3907 79.6964 85.2789 81.8154 85.2789 84.4292C85.2789 87.0431 87.3907 89.162 89.9957 89.162Z"
        fill="#1A635A"
      />
      <path
        d="M97.1386 56.6844H46.6925V74.8298H97.1386V56.6844Z"
        fill="#ECEFF1"
      />
      <path
        d="M52.7873 60.989H50.8326V70.2861H52.7873V60.989Z"
        fill="#EE730A"
      />
      <g opacity="0.7">
        <path
          opacity="0.7"
          d="M56.6465 60.989H54.6919V70.2861H56.6465V60.989Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M60.5057 60.989H58.551V70.2861H60.5057V60.989Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M64.365 60.989H62.4104V70.2861H64.365V60.989Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M68.2244 60.989H66.2698V70.2861H68.2244V60.989Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M72.0836 60.989H70.1289V70.2861H72.0836V60.989Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M75.9414 60.989H73.9868V70.2861H75.9414V60.989Z"
          fill="#EE730A"
        />
      </g>
      <path
        d="M89.9957 70.3118C92.6007 70.3118 94.7124 68.1928 94.7124 65.579C94.7124 62.9651 92.6007 60.8462 89.9957 60.8462C87.3907 60.8462 85.2789 62.9651 85.2789 65.579C85.2789 68.1928 87.3907 70.3118 89.9957 70.3118Z"
        fill="#1A635A"
      />
      <path
        d="M97.1386 37.8342H46.6925V55.9796H97.1386V37.8342Z"
        fill="#ECEFF1"
      />
      <path
        d="M52.7873 42.1374H50.8326V51.4346H52.7873V42.1374Z"
        fill="#EE730A"
      />
      <g opacity="0.7">
        <path
          opacity="0.7"
          d="M56.6465 42.1374H54.6919V51.4346H56.6465V42.1374Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M60.5057 42.1374H58.551V51.4346H60.5057V42.1374Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M64.365 42.1374H62.4104V51.4346H64.365V42.1374Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M68.2244 42.1374H66.2698V51.4346H68.2244V42.1374Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M72.0836 42.1374H70.1289V51.4346H72.0836V42.1374Z"
          fill="#EE730A"
        />
      </g>
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M75.9414 42.1374H73.9868V51.4346H75.9414V42.1374Z"
          fill="#EE730A"
        />
      </g>
      <path
        d="M89.9956 51.8294C92.8037 51.8294 95.0802 49.5452 95.0802 46.7274C95.0802 43.9097 92.8037 41.6255 89.9956 41.6255C87.1875 41.6255 84.911 43.9097 84.911 46.7274C84.911 49.5452 87.1875 51.8294 89.9956 51.8294Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M89.9956 70.807C92.8037 70.807 95.0802 68.5228 95.0802 65.7051C95.0802 62.8873 92.8037 60.6031 89.9956 60.6031C87.1875 60.6031 84.911 62.8873 84.911 65.7051C84.911 68.5228 87.1875 70.807 89.9956 70.807Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M89.9956 89.6571C92.8037 89.6571 95.0802 87.3729 95.0802 84.5552C95.0802 81.7375 92.8037 79.4533 89.9956 79.4533C87.1875 79.4533 84.911 81.7375 84.911 84.5552C84.911 87.3729 87.1875 89.6571 89.9956 89.6571Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M89.9957 51.4602C92.6007 51.4602 94.7124 49.3413 94.7124 46.7274C94.7124 44.1136 92.6007 41.9946 89.9957 41.9946C87.3907 41.9946 85.2789 44.1136 85.2789 46.7274C85.2789 49.3413 87.3907 51.4602 89.9957 51.4602Z"
        fill="#1A635A"
      />
      <path
        d="M73.473 38.6688H54.8419C54.6719 38.6688 54.5089 38.6011 54.3888 38.4805C54.2686 38.3599 54.201 38.1963 54.201 38.0258V37.0072C54.201 36.8367 54.2686 36.6731 54.3888 36.5525C54.5089 36.4319 54.6719 36.3642 54.8419 36.3642H73.473V38.6688Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M73.4729 38.3975H55.1788C55.0089 38.3975 54.8459 38.3297 54.7257 38.2091C54.6055 38.0885 54.538 37.925 54.538 37.7544V37.1409C54.538 36.9704 54.6055 36.8068 54.7257 36.6862C54.8459 36.5656 55.0089 36.4979 55.1788 36.4979H73.4729V38.3975Z"
        fill="#B0BEC5"
      />
      <path
        d="M25.9954 26.1359L39.4535 38.0811L55.0905 34.7669L53.8793 31.2314L41.7619 32.6667L29.638 21.9921C29.3479 21.7367 29.0076 21.5452 28.639 21.4302C28.2704 21.3152 27.8819 21.2792 27.4985 21.3245C27.1152 21.3699 26.7456 21.4956 26.4138 21.6934C26.082 21.8913 25.7953 22.157 25.5724 22.4731C25.1813 23.0289 25.0081 23.7098 25.0862 24.3857C25.1642 25.0615 25.488 25.6847 25.9954 26.1359Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M55.0829 34.7669C55.0829 34.7669 59.0717 34.8775 60.5456 32.668C62.0196 30.4585 53.8717 31.2314 53.8717 31.2314L55.0829 34.7669Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M24.2305 18.35C28.0615 18.35 31.1672 15.2337 31.1672 11.3897C31.1672 7.54558 28.0615 4.42934 24.2305 4.42934C20.3995 4.42934 17.2938 7.54558 17.2938 11.3897C17.2938 15.2337 20.3995 18.35 24.2305 18.35Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M21.3684 19.0123C21.3684 19.0123 12.4604 25.0312 14.7226 43.6383C14.9981 45.8861 14.8034 48.1669 14.151 50.335L12.9974 54.1418C12.9974 54.1418 32.595 54.5842 33.1448 54.1418C33.4858 53.8679 33.2205 46.8561 32.2643 37.1294C31.6785 31.171 33.5153 22.8821 27.2002 18.3525L21.3684 19.0123Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M33.1474 53.9218L41.8452 72.3708L42.8359 91.1502H36.6709L34.0279 71.7071L28.7434 62.3174L23.4589 91.1502H17.2938L19.1651 69.7188L13 54.143L33.1474 53.9218Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M42.8372 91.1503L44.7085 94.0221C44.7085 94.0221 50.989 96.4528 48.232 97.9987H36.2312V91.1503H42.8372Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M23.3499 91.1503L25.2212 94.0221C25.2212 94.0221 31.5017 96.4528 28.7447 97.9987H16.7439V91.1503H23.3499Z"
        fill="url(#paint11_linear)"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M24.3407 4.31992C23.1431 4.31747 21.9655 4.62772 20.9236 5.22018C22.5536 4.50781 24.3941 4.45079 26.0648 5.06092C27.7356 5.67104 29.109 6.9017 29.9014 8.49872C30.6939 10.0957 30.8448 11.9371 30.323 13.6426C29.8012 15.348 28.6466 16.7873 27.0976 17.6631C28.5614 17.0268 29.7618 15.9037 30.4968 14.4831C31.2317 13.0625 31.4562 11.4311 31.1324 9.86377C30.8087 8.29645 29.9565 6.88899 28.7194 5.87848C27.4823 4.86798 25.9359 4.31619 24.3407 4.31606V4.31992Z"
          fill="url(#paint12_linear)"
        />
      </g>
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M25.5084 4.08204C25.9711 4.45114 26.4338 4.87041 27.0144 4.9823C27.2502 5.02922 27.4933 5.02439 27.7271 4.96815C27.1747 4.83954 26.7273 4.43442 26.28 4.07946C24.7663 2.87311 22.8937 2.05516 20.9609 2.04358C20.825 2.04358 20.6904 2.04358 20.5546 2.05516C22.3618 2.1619 24.0985 2.95799 25.5084 4.08204Z"
          fill="url(#paint13_linear)"
        />
        <path
          opacity="0.2"
          d="M32.663 0L31.5492 1.70921C31.6556 3.11491 32.2708 4.66722 30.966 5.97903C29.6945 7.26512 27.4028 7.26512 25.8289 8.02005C22.2567 9.73055 20.6084 13.783 17.6464 16.2729C17.5448 16.3621 17.4349 16.4413 17.3182 16.5095C17.4535 16.5838 17.6063 16.6203 17.7604 16.615C18.0168 16.5983 18.2244 16.4349 18.4167 16.2729C21.38 13.7817 23.0309 9.73055 26.5992 8.02005C28.1744 7.2664 30.4661 7.25997 31.7363 5.97903C33.6038 4.09619 31.5325 1.73236 32.663 0Z"
          fill="url(#paint14_linear)"
        />
      </g>
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M32.2682 37.1307C31.6825 31.1723 33.5192 22.8834 27.2042 18.3538L25.8673 18.5056C31.9363 23.0686 30.1483 31.2379 30.7276 37.1333C31.6838 46.86 31.9504 53.8718 31.6082 54.1457C31.4402 54.2743 29.501 54.3335 26.8876 54.3412C30.2495 54.3502 32.9501 54.3065 33.1501 54.1457C33.4897 53.8692 33.2231 46.8574 32.2682 37.1307Z"
          fill="url(#paint15_linear)"
        />
      </g>
      <path
        d="M22.1168 28.4985L35.5749 40.4437L51.2119 37.1294L49.9891 33.5952L37.8782 35.0318L25.7543 24.3573C25.4642 24.1018 25.1239 23.9103 24.7553 23.7953C24.3867 23.6803 23.9982 23.6443 23.6148 23.6896C23.2315 23.735 22.8619 23.8607 22.5301 24.0586C22.1983 24.2564 21.9116 24.5221 21.6887 24.8383C21.2989 25.3944 21.1271 26.0751 21.206 26.7504C21.285 27.4257 21.6093 28.048 22.1168 28.4985Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M51.2042 37.1306C51.2042 37.1306 55.1929 37.2412 56.6669 35.0317C58.1409 32.8222 49.9891 33.5952 49.9891 33.5952L51.2042 37.1306Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M24.5779 15.3675H23.8806C22.4316 15.3675 21.257 16.5462 21.257 18.0001V18.6997C21.257 20.1537 22.4316 21.3324 23.8806 21.3324H24.5779C26.0269 21.3324 27.2016 20.1537 27.2016 18.6997V18.0001C27.2016 16.5462 26.0269 15.3675 24.5779 15.3675Z"
        fill="url(#paint18_linear)"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M27.5322 62.3174L22.2477 91.1502H23.4589L28.4461 63.9404L27.5322 62.3174Z"
          fill="url(#paint19_linear)"
        />
        <path
          opacity="0.2"
          d="M41.8453 72.3708L33.1476 53.9218L31.9427 53.9347L40.6341 72.3708L41.6249 91.1502H42.8361L41.8453 72.3708Z"
          fill="url(#paint20_linear)"
        />
      </g>
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M44.7086 94.0234L42.8373 91.1516H42.2861L44.1575 94.0234C44.1575 94.0234 50.4341 96.4567 47.6822 98H48.2321C50.9839 96.4567 44.7086 94.0234 44.7086 94.0234Z"
          fill="url(#paint21_linear)"
        />
      </g>
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M25.7724 94.0234L23.9011 91.1516H23.3512L25.2225 94.0234C25.2225 94.0234 31.5029 96.4541 28.746 98H29.2958C32.0451 96.4567 25.7724 94.0234 25.7724 94.0234Z"
          fill="url(#paint22_linear)"
        />
      </g>
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M51.9887 34.1688L51.7542 33.4846L40.5199 34.817L51.9887 34.1688Z"
          fill="url(#paint23_linear)"
        />
      </g>
      <path
        d="M18.5333 28.3686L22.2747 21.2784L28.509 23.6422L22.2747 32.6783L18.5333 28.3686Z"
        fill="url(#paint24_linear)"
      />
      <path
        d="M26.2942 26.7622L39.3793 38.3807L54.5843 35.1578L53.4064 31.7201L41.6274 33.1168L29.8356 22.7303C29.5534 22.4818 29.2224 22.2956 28.8639 22.1838C28.5054 22.072 28.1275 22.037 27.7546 22.0811C27.3818 22.1253 27.0224 22.2475 26.6997 22.44C26.377 22.6325 26.0982 22.8909 25.8815 23.1984C25.5008 23.7392 25.3324 24.4019 25.4085 25.0595C25.4847 25.7172 25.8001 26.3235 26.2942 26.7622Z"
        fill="#FFB9B9"
      />
      <path
        d="M54.5842 35.1565C54.5842 35.1565 58.4639 35.2645 59.8969 33.1155C61.3299 30.9664 53.4062 31.7188 53.4062 31.7188L54.5842 35.1565Z"
        fill="#FFB9B9"
      />
      <path
        d="M24.5765 19.1898C28.3021 19.1898 31.3222 16.1593 31.3222 12.4211C31.3222 8.68285 28.3021 5.6524 24.5765 5.6524C20.851 5.6524 17.8308 8.68285 17.8308 12.4211C17.8308 16.1593 20.851 19.1898 24.5765 19.1898Z"
        fill="#FFB9B9"
      />
      <path
        d="M21.7912 19.8341C21.7912 19.8341 13.1268 25.6884 15.3275 43.7862C15.5954 45.9727 15.406 48.1913 14.7712 50.3002L13.6536 54.0029C13.6536 54.0029 32.7141 54.4324 33.2498 54.0029C33.5818 53.7367 33.3229 46.9165 32.3936 37.4561C31.8245 31.661 33.61 23.5985 27.468 19.1936L21.7912 19.8341Z"
        fill="#E28637"
      />
      <path
        d="M33.2498 53.7868L41.7092 71.7304L42.6718 89.9954H36.6759L34.106 71.086L28.9663 61.9522L23.8266 89.9954H17.8294L19.6495 69.1518L13.6536 54.0016L33.2498 53.7868Z"
        fill="#2F2E41"
      />
      <path
        d="M42.6732 89.9954L44.4933 92.7888C44.4933 92.7888 50.5968 95.1526 47.9193 96.6573H36.2479V89.9954H42.6732Z"
        fill="#263238"
      />
      <path
        d="M23.7139 89.9954L25.534 92.7888C25.534 92.7888 31.6375 95.1526 28.96 96.6573H17.295V89.9954H23.7139Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M24.6842 5.54438C23.5191 5.5432 22.3737 5.84637 21.3607 6.42406C22.9428 5.74816 24.7223 5.70397 26.3358 6.30053C27.9492 6.8971 29.2747 8.08937 30.0414 9.63372C30.8081 11.1781 30.9581 12.9579 30.4607 14.6095C29.9634 16.2611 28.8563 17.6598 27.3656 18.5197C28.7883 17.9003 29.955 16.8079 30.6691 15.4265C31.3833 14.0451 31.6013 12.459 31.2865 10.9352C30.9717 9.41137 30.1434 8.04289 28.9409 7.0601C27.7384 6.07731 26.2351 5.54022 24.6842 5.53923V5.54438Z"
        fill="white"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M25.8237 5.31291C26.2736 5.67173 26.7209 6.08457 27.2874 6.19131C27.5168 6.2371 27.7533 6.23271 27.9808 6.17845C27.4438 6.04984 27.008 5.66402 26.5709 5.3142C25.1008 4.14 23.2807 3.34391 21.4069 3.33234C21.2787 3.33234 21.1441 3.33234 21.0121 3.34391C22.7591 3.44551 24.4445 4.21331 25.8237 5.31291Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M32.7769 1.34273L31.6926 3.00564C31.7951 4.37276 32.395 5.87877 31.1248 7.15843C29.8879 8.40336 27.6641 8.40979 26.1261 9.14286C22.6513 10.8058 21.0492 14.7476 18.1666 17.1693C18.0678 17.2562 17.9609 17.3332 17.8474 17.3996C17.9788 17.4718 18.1271 17.5074 18.2768 17.5024C18.5203 17.4857 18.728 17.3275 18.9176 17.1693C21.799 14.7476 23.4037 10.8058 26.8771 9.14286C28.4088 8.40979 30.6377 8.40336 31.8759 7.15843C33.6933 5.32704 31.6785 3.02751 32.7769 1.34273Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.2"
        d="M32.3938 37.4548C31.8247 31.6597 33.6101 23.5972 27.4681 19.1923L26.1685 19.3402C32.0708 23.7785 30.3315 31.7266 30.8955 37.4587C31.8247 46.9191 32.0836 53.7393 31.7516 54.0055C31.5889 54.1341 29.7009 54.1881 27.1605 54.1958C30.4315 54.2048 33.0564 54.1611 33.2513 54.0055C33.5832 53.7354 33.3269 46.9166 32.3938 37.4548Z"
        fill="white"
      />
      <path
        d="M22.5206 29.0656L35.6058 40.679L50.8108 37.456L49.6329 34.0183L37.8539 35.415L26.062 25.0286C25.7799 24.7801 25.4488 24.5939 25.0904 24.482C24.7319 24.3702 24.354 24.3352 23.9811 24.3794C23.6083 24.4235 23.2489 24.5458 22.9262 24.7383C22.6035 24.9307 22.3247 25.1891 22.1079 25.4967C21.7258 26.0379 21.5565 26.7017 21.6327 27.3605C21.7089 28.0193 22.0252 28.6266 22.5206 29.0656Z"
        fill="#FFB9B9"
      />
      <path
        d="M50.8107 37.4548C50.8107 37.4548 54.6905 37.5628 56.1235 35.4137C57.5564 33.2647 49.6328 34.0171 49.6328 34.0171L50.8107 37.4548Z"
        fill="#FFB9B9"
      />
      <path
        d="M24.8431 16.2883H24.3086C22.8596 16.2883 21.6849 17.467 21.6849 18.9209V19.4572C21.6849 20.9112 22.8596 22.0899 24.3086 22.0899H24.8431C26.2921 22.0899 27.4668 20.9112 27.4668 19.4572V18.9209C27.4668 17.467 26.2921 16.2883 24.8431 16.2883Z"
        fill="#FFB9B9"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M27.7885 61.9522L22.6488 89.9954H23.8267L28.6768 63.5316L27.7885 61.9522Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M41.7093 71.7304L33.2499 53.7868L32.0771 53.7997L40.5314 71.7304L41.494 89.9954H42.6719L41.7093 71.7304Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.2"
        d="M44.4931 92.7901L42.6731 89.9967H42.1373L43.9574 92.7901C43.9574 92.7901 50.0609 95.1539 47.3834 96.6586H47.9192C50.5967 95.1539 44.4931 92.7901 44.4931 92.7901Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M26.0761 92.7901L24.256 89.9967H23.7139L25.5339 92.7901C25.5339 92.7901 31.6375 95.1539 28.9599 96.6586H29.4957C32.1732 95.1539 26.0761 92.7901 26.0761 92.7901Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M51.5735 34.5752L51.3466 33.9103L40.4199 35.2054L51.5735 34.5752Z"
        fill="white"
      />
      <path
        d="M19.0355 28.9332L22.673 22.0384L28.7369 24.3367L22.673 33.1245L19.0355 28.9332Z"
        fill="#E28637"
      />
      <path
        d="M72.1937 36.6998L83.2166 21.6603C83.2665 21.5922 83.3293 21.5346 83.4015 21.4908C83.4736 21.4471 83.5536 21.418 83.637 21.4054C83.7203 21.3927 83.8053 21.3967 83.8872 21.417C83.969 21.4374 84.046 21.4737 84.1138 21.524L84.6265 21.9034C84.6944 21.9535 84.7518 22.0165 84.7954 22.0889C84.839 22.1613 84.8679 22.2416 84.8805 22.3252C84.8932 22.4089 84.8892 22.4942 84.8689 22.5763C84.8486 22.6583 84.8124 22.7356 84.7623 22.8036L73.7395 37.8432L72.1937 36.6998Z"
        fill="url(#paint25_linear)"
      />
      <path
        d="M72.413 36.8606L83.2487 22.0706C83.2986 22.0025 83.3614 21.9449 83.4336 21.9011C83.5057 21.8574 83.5857 21.8284 83.6691 21.8157C83.7524 21.803 83.8374 21.807 83.9193 21.8273C84.0011 21.8477 84.0781 21.884 84.1459 21.9343L84.1984 21.9729C84.2663 22.023 84.3237 22.086 84.3673 22.1584C84.4109 22.2308 84.4399 22.3111 84.4525 22.3947C84.4651 22.4784 84.4612 22.5637 84.4409 22.6458C84.4206 22.7278 84.3844 22.8051 84.3343 22.8731L73.4986 37.6632L72.413 36.8606Z"
        fill="#B0BEC5"
      />
      <path
        d="M21.5046 17.0817C20.7405 17.0752 19.9939 16.8524 19.3511 16.439C18.7084 16.0257 18.1957 15.4387 17.8726 14.746C17.3519 13.6442 17.0626 12.7665 17.0126 12.137C16.8783 10.4456 17.8337 7.78785 19.0988 6.33312C20.4868 4.73718 22.6837 4.51453 24.8083 4.29926C25.3887 4.24039 25.9889 4.17961 26.5571 4.09125C27.0445 4.01552 25.9843 4.45327 26.5 4.64475C26.649 4.69938 26.7853 4.78383 26.9006 4.89292C27.0159 5.00202 27.1078 5.13349 27.1706 5.27928C27.1908 5.3305 27.2214 5.37703 27.2603 5.41602C27.2992 5.455 27.3456 5.4856 27.3968 5.50594C27.441 5.52288 27.4882 5.53047 27.5355 5.52822C27.5828 5.52597 27.6291 5.51393 27.6715 5.49287C27.7577 5.449 27.8531 5.42612 27.9499 5.4261C28.0466 5.42608 28.142 5.44892 28.2283 5.49276C28.3045 5.53109 28.3705 5.58692 28.421 5.65567C28.4715 5.72442 28.505 5.80415 28.5188 5.88834C28.5278 5.94612 28.5457 6.00216 28.5719 6.05446C28.6164 6.13577 28.6776 6.20673 28.7515 6.26267C28.8254 6.31861 28.9103 6.35825 29.0007 6.37898C29.1385 6.40976 29.2604 6.48987 29.3433 6.60424C29.4263 6.71861 29.4646 6.85935 29.4511 7C29.4168 7.28867 29.9449 8.63061 31 8C30.9469 9.13367 30.9474 9.67504 29.808 9.65616C29.5166 9.64404 29.2282 9.5931 28.9503 9.50468C28.5376 9.39005 28.2116 9.29901 27.7725 9.57293C27.0974 9.99387 26.9872 10.8738 26.8899 11.6502L26.8728 11.786C26.7592 12.674 26.2901 13.7426 25.3415 13.832C25.0404 13.8418 24.7404 13.7898 24.4602 13.6792C24.1719 13.5515 23.852 13.5131 23.5417 13.5689C23.0208 13.7176 22.7701 14.34 22.7916 14.878C22.8055 15.0983 22.8337 15.3174 22.8759 15.5341C22.9527 15.8772 22.9772 16.2299 22.9488 16.5804C22.9217 16.7935 22.6769 16.9341 22.1784 17.0224C21.9559 17.0619 21.7305 17.0817 21.5046 17.0817Z"
        fill="#2F2E41"
      />
      <path
        d="M33.9887 7.63743C27.4671 9.41065 23.6659 11.2292 17 13C17 13 15.9999 11 16.4999 9C16.9999 7 18.7952 5.23491 22.5 4C28.4999 2 29.5238 5.72273 30.5 8L33.8278 7.04916C33.934 7.01882 34.0484 7.04843 34.1265 7.12653C34.2923 7.2923 34.2149 7.57592 33.9887 7.63743Z"
        fill="#FFCD39"
      />
      <path
        d="M27.5 4C27.215 4.02568 23.8911 4.4901 22.8935 4.89001L23.3896 3.4711C23.3691 3.4089 25.5095 3.06421 25.5 3C27.0649 2.61331 27.5 4 27.5 4Z"
        fill="#FFCD39"
      />
      <path
        d="M27.5 4C27.215 4.02568 23.8911 4.4901 22.8935 4.89001L23.3896 3.4711C23.3691 3.4089 25.5095 3.06421 25.5 3C27.0649 2.61331 27.5 4 27.5 4Z"
        fill="#FFCD39"
      />
      <path
        opacity="0.05"
        d="M27.5 4C27.215 4.02568 23.8911 4.4901 22.8935 4.89001L23.3896 3.4711C23.3691 3.4089 25.5095 3.06421 25.5 3C27.0649 2.61331 27.5 4 27.5 4Z"
        fill="#4D4D4D"
      />
      <path d="M107 94.3315H0.0147705V94.742H107V94.3315Z" fill="#3F3D56" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="71.9143"
          y1="94.9545"
          x2="71.9143"
          y2="36.5275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="6193.75"
          y1="4153.77"
          x2="6193.75"
          y2="3344.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="6193.75"
          y1="5678.44"
          x2="6193.75"
          y2="4868.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="6193.75"
          y1="7192.85"
          x2="6193.75"
          y2="6383.28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="12871.2"
          y1="888.332"
          x2="12871.2"
          y2="847.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="14425.4"
          y1="6146.08"
          x2="14425.4"
          y2="3957.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="4261.3"
          y1="1275.58"
          x2="4261.3"
          y2="1170.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="1232.88"
          y1="1990.62"
          x2="1232.88"
          y2="483.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="7064.97"
          y1="17705.8"
          x2="7064.97"
          y2="7635.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="3485.61"
          y1="26439.2"
          x2="3485.61"
          y2="15662.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="6376.29"
          y1="5782.2"
          x2="6376.29"
          y2="5417.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="4425.83"
          y1="5782.2"
          x2="4425.83"
          y2="5417.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="3858.04"
          y1="2758.74"
          x2="3858.04"
          y2="1373.59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="2569.38"
          y1="322.904"
          x2="2569.38"
          y2="254.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="5571.36"
          y1="3293.19"
          x2="5571.36"
          y2="1146.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="2978.65"
          y1="17707.1"
          x2="2978.65"
          y2="7636.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="13513.7"
          y1="6455.62"
          x2="13513.7"
          y2="4267.41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="4052.26"
          y1="1345.31"
          x2="4052.26"
          y2="1240.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="542.125"
          y1="1004.76"
          x2="542.125"
          y2="728.112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="619.34"
          y1="20497.3"
          x2="619.34"
          y2="14033.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="2104.84"
          y1="26439.2"
          x2="2104.84"
          y2="15662.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="3515.76"
          y1="5782.19"
          x2="3515.76"
          y2="5417.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="2514.38"
          y1="5782.19"
          x2="2514.38"
          y2="5417.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="3016.11"
          y1="394.189"
          x2="3016.11"
          y2="380.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="837.334"
          y1="2917.87"
          x2="837.334"
          y2="1907.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="9969.99"
          y1="5994.24"
          x2="9969.99"
          y2="3892.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
