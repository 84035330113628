import { theme } from "@paudigital/wdp-components"
import styled, { css } from "styled-components"

export const EUCOverviewBase = styled.section<{
  loading?: number
}>`
  margin: 3.2rem 0;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0;
  }
`

export const EUCOverviewFlex = styled(EUCOverviewBase)`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem 0;

  ${props => {
    if (!props.loading) return
    return css`
      height: 34rem;
      justify-content: center;
      background: ${theme.colors.neutral.midGrey};
    `
  }}

  @media only screen and (${theme.breakpoints.tablet}) {
    grid-gap: 0 2.8rem;
    margin: 0;
    flex-direction: row;
  }
`

export const EUCOverviewGrid = styled(EUCOverviewBase)`
  border-radius: 10px;
  margin: 3.2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem 2.4rem;
  align-items: flex-start;

  ${props => {
    if (!props.loading) return
    return css`
      height: 34rem;
      justify-content: center;
      background: ${theme.colors.neutral.midGrey};
    `
  }}

  @media only screen and (${theme.breakpoints.desktop}) {
    grid-template-columns: ${props => !props.loading && "1fr 2fr"};
  }
`


export const NonSolar = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem 0;
`

export const Solar = styled.div`
  min-height: 18rem;
  width: 100%;
  height: 100%;

  @media only screen and (${theme.breakpoints.desktop}) {
    min-height: 34rem;
  }
`

export const LoadingOuterWrapper = styled.div`
  align-self: center;
  background: white;
  border-radius: 10px;
  margin: auto;
`

export const LoadingInnerWrapper = styled.div`
  margin: 1.6rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 3.6rem 6rem;
  }
`

export const LoadingText = styled.div`
  margin-left: 1.6rem;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.paragraph};
  font-weight: ${theme.fontWeights.bold};
`

export const FlexWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
`
