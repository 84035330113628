import React from "react"
import { UsagePercentageBar } from "@paudigital/wdp-components"
import {
  UsageComparisonBarPercentage,
  UsageComparisonBarText,
  UsageComparisonBarWrapper
} from "./style"
import { useTranslate } from "hooks/translate"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"

type UsageComparisonBarProps = {
  name: string
  percentageDifference?: number
  color: string
}

const UsageComparisonBar = ({
  name,
  percentageDifference,
  color
}: UsageComparisonBarProps) => {
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()
  const percentageDifferenceMemo = React.useMemo(() => {
    if (!percentageDifference) return 0
    if (percentageDifference > 100) return 100
    return percentageDifference
  }, [percentageDifference])

  return (
    <UsageComparisonBarWrapper>
      <UsageComparisonBarText>
        <UsageComparisonBarPercentage color={color}>
          {percentageDifference ? decimalFormatter(percentageDifference) : "--"}%
        </UsageComparisonBarPercentage>
        &nbsp;
        {translate(`portal.dashboard.widget.energy.${name}`)}
      </UsageComparisonBarText>
      <UsagePercentageBar percentage={percentageDifferenceMemo} color={color} />
    </UsageComparisonBarWrapper>
  )
}

export default UsageComparisonBar
