import {
  StyledButton,
  StyledCardActions,
  StyledCardContent,
  StyledDataIdDiv,
  StyledBaseCard,
  StyledTitle,
  StyledInfo
} from "./style"
import { useTranslate } from "hooks/translate"
import { Invoice } from "app/types/invoice"
import MetaTitle from "app/components/InvoiceMetaTitle"
import InvoiceValue from "app/components/InvoiceValue"
import { useDispatch } from "react-redux"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"

type Props = {
  invoice: Invoice
}

const WidgetCardSingleContract = ({ invoice }: Props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { metadata, id, invoiceType } = invoice
  const value = metadata.Amount_x0020_in_x0020_local_x0020_currency
  const process = metadata.RE_x0020_Process
  const from = metadata.Settlement_x0020_period_x0020_from
  const to = metadata.Settlement_x0020_period_x0020_to

  const handleClick = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.INVOICE_DETAIL,
        uniqueIdentifier: "invoice-detail",
        props: {
          id
        }
      })
    )
  }

  return (
    <StyledBaseCard actionPlacement="right" boxShadow="dropShadow">
      <StyledDataIdDiv data-testid="invoiceWidgetItem" onClick={handleClick}>
        <StyledCardContent>
          <StyledInfo>
            <MetaTitle process={process} from={from!} to={to!} />
            <StyledTitle>
              {`${translate(
                `portal.invoices.invoice.invoicetype.${invoiceType}`
              )} ${metadata.Invoice_x0020_Document_x0020_ID}`}
            </StyledTitle>
          </StyledInfo>
          <InvoiceValue
            size="compact"
            invoiceType={invoiceType}
            amount={value!}
            currency={metadata.Currency_x0020_Key!}
            dueDate={metadata.TaskDueDate!}
          />
        </StyledCardContent>
        <StyledCardActions alignment="horizontal">
          <StyledButton
            label={translate("portal.dashboard.widget.button.detail")}
            type="secondary"
          />
        </StyledCardActions>
      </StyledDataIdDiv>
    </StyledBaseCard>
  )
}

export default WidgetCardSingleContract
