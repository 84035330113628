import { NoEnergyReasons } from "app/types/rentedBuilding"
import { useTranslate } from "hooks/translate"
import { EmptyContainer } from "./style"

export type NoDataProps = {
    message: "nodata" | "noconnection" | `noEnergyReason.${NoEnergyReasons}`
}

const NoData = ({ message }: NoDataProps) => {
  const { translate } = useTranslate()
  return (
    <EmptyContainer>
      {translate(`portal.dashboard.widget.energy.${message}`)}
    </EmptyContainer>
  )
}

export default NoData
