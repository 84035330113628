import React from "react"
import { featureFlags } from "featureFlags"

export const FeatureFlagContext = React.createContext<typeof featureFlags | undefined >(undefined)

type Props = {
  children: React.ReactNode
}

export const FeatureFlagProvider = ({ children }: Props) => {

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
