import { PossibleContactRoles } from "app/enums/roles"
import { RentedBuildingContactRoles } from "app/types/contactPerson"
import { useSelector } from "react-redux"
import * as selectors from "app/redux/me/me.selectors"
import { BuildingWrapper } from "./style"
import { GroupedCheckbox, InputError } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"

type Props = {
  data: RentedBuildingContactRoles[]
  error: boolean
  onChange: (val: boolean, name: string, rentedBuildingId: string) => void
  isSelf?: boolean
}

const SingleRentedBuilding = ({ error, onChange, data, isSelf }: Props) => {
  const { translate } = useTranslate()
  const rentedBuilding = useSelector(selectors.getSingleRentedBuilding)
  if (!rentedBuilding) return null

  const handleChange = (val: boolean, name: string) => {
    onChange(val, name, rentedBuilding?.id)
  }

  return (
    <>
      <BuildingWrapper>
        <GroupedCheckbox
          onChange={handleChange}
          data={Object.values(PossibleContactRoles).map(r => ({
            label: r,
            name: r,
            guidanceText: translate(`portal.guidanceText.${r}`),
            selected: data && data[0] && data[0].roles.includes(r)
          }))}
          title={translate("portal.onboarding.step4.roles.title")}
          isSelf={isSelf}
          editSelfWarningDescription={translate(
            "portal.warning.edit.self.text"
          )}
          editSelfWarningTitle={translate("portal.warning.edit.self.title")}
        />
      </BuildingWrapper>
      {error && (
        <InputError
          errorMessage={translate("portal.onboarding.step4.roles.label")}
        />
      )}
    </>
  )
}

export default SingleRentedBuilding
