import { theme } from "@paudigital/wdp-components"
import { WorkOrderStatusEnum } from "app/types/ticket"

export type ColorMappingType = Record<string, string>

export const colorMapping: ColorMappingType = {
  [WorkOrderStatusEnum.WO10]: theme.colors.system.reported,
  [WorkOrderStatusEnum.WO61]: theme.colors.system.moreInfo,
  [WorkOrderStatusEnum.WO32]: theme.colors.system.warning,
  [WorkOrderStatusEnum.WO42]: theme.colors.system.planned,
  [WorkOrderStatusEnum.WO45]: theme.colors.system.success,
  [WorkOrderStatusEnum.WO50]: theme.colors.system.success
}
