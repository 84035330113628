import { QuotationIcon } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import {
  StyledContent,
  StyledFaqContent,
  StyledLink,
  StyledQuotation
} from "./style"

type Props = {
  content?: string
  linkContent: string
  onClick: () => void
}

const FaqLink = ({ linkContent, content, onClick }: Props): JSX.Element => {
  const { translate } = useTranslate()
  return (
    <StyledFaqContent>
      <StyledContent>{content && translate(content)}</StyledContent>
      <StyledQuotation>
        <QuotationIcon width="16" height="16" />
      </StyledQuotation>
      <StyledLink
        type="default"
        value={translate(linkContent)}
        onClick={onClick}
        dataTestId="faq.link"
      />
    </StyledFaqContent>
  )
}

export default FaqLink
