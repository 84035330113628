import {
  InviteContactPerson,
  RentedBuildingContactRoles
} from "app/types/contactPerson"
import { array, object, string } from "yup"
import store from "store"

export const getDomain = (email: string): string =>
  email.substring(email.indexOf("@") + 1)

const checkIfDomainIsWhitelisted = async (email: string): Promise<boolean> => {
  const state = store.getState()
  const whitelistedDomains = state.me.data!.companies.map(c => c.allowedDomains).flat()

  if (!whitelistedDomains) return false
  return (
    whitelistedDomains.length !== 0 &&
    whitelistedDomains.some(d => d.toLowerCase() === getDomain(email.toLowerCase()))
  )
}

export const validationSchema = object({
  firstName: string().required("portal.input.required"),
  lastName: string().required("portal.input.required"),
  email: string()
    .email("portal.contactPerson.input.email.error")
    .required("portal.input.required")
    .test(
      "portal.contactPerson.input.email.domain.label",
      "portal.contactPerson.input.email.domain.error",
      (val: any) => {
        if (val) return checkIfDomainIsWhitelisted(val)
        return false
      }
    ),
  contactRolesPerRentedBuilding: array().test(
    "roles",
    "Must have at least one role",
    (contactRolesPerRentedBuilding: any) => {
      return contactRolesPerRentedBuilding.some((c: any) => {
        return c.roles.length > 0
      })
    }
  )
})

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contactRolesPerRentedBuilding: [] as RentedBuildingContactRoles[]
}

export const removeBuildingsWithoutRolesFromContactPerson = (
  user: InviteContactPerson
) => {
  const contactRolesPerRentedBuilding = user.contactRolesPerRentedBuilding.filter(
    r => r.roles.length !== 0
  )
  return {
    ...user,
    contactRolesPerRentedBuilding
  }
}
