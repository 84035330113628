import { SagaIterator } from "redux-saga"
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { getAssignedPersons } from "app/api/rentedBuilding"
import { getMe } from "app/api/contactPerson"
import {
  inviteContactPersons,
  putContactPersons,
  deleteContactPerson
} from "app/api/contactPerson"
import * as actions from "./rentedBuildingContactPerson.actions"
import * as selectors from "./../me/me.selectors"
import { ContactPerson } from "app/types/contactPerson"
import { RentedBuilding } from "app/types/rentedBuilding"
import * as appEventActions from "../appEvents/appEvents.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { intl, Locales } from "app/i18n/config"
import { createGAEvent, PossibleGAEvents } from "../gaEvents/gaEvents.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* postContactPersonsFlow(
  action: actions.PostContactPersonAction
): SagaIterator {
  const preferredLanguage: Locales = yield select(
    selectors.getPreferredLanguage
  )
  try {
    yield put(appEventActions.remove("error"))
    const { data } = action.payload
    yield call(inviteContactPersons, data)
    yield put(createGAEvent(PossibleGAEvents.CREATE_ACCOUNT))
    const rentedBuildings = yield select(selectors.getRentedBuildings)
    const ids = rentedBuildings.map((r: RentedBuilding) => r.id)
    yield put(actions.getContactPersons(ids))
    yield put(appEventActions.remove("add-colleague"))
    yield put(
      appEventActions.fire({
        uniqueIdentifier: "account-created",
        eventName: PossibleAppEvents.ACCOUNT_CREATED
      })
    )
    yield put(actions.postContactPersonSuccess())
  } catch (e: any) {
    yield put(
      appEventActions.fire({
        local: true,
        uniqueIdentifier: "error",
        eventName: PossibleAppEvents.ERROR,
        props: {
          title: intl[preferredLanguage].formatMessage({
            id: "portal.onboarding.step2.modal.error.title"
          }),
          description: intl[preferredLanguage].formatMessage({
            id: "portal.onboarding.step2.modal.error.description"
          }),
          variant: "error",
          show: true
        }
      })
    )
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.putContactPersonFail(e))
  }
}

export function* putContactPersonsFlow(
  action: actions.PutContactPersonAction
): SagaIterator {
  try {
    const { data } = action.payload
    yield call(putContactPersons, data)
    const response = yield call(getMe)
    const rentedBuildings = response.data.rentedBuildings
    const ids = rentedBuildings.map((r: RentedBuilding) => r.id)
    yield put(actions.getContactPersons(ids))
    yield put(appEventActions.remove("add-colleague"))
    yield put(actions.putContactPersonSuccess())
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.putContactPersonFail(e))
  }
}

export function* getContactPersonFlow(
  action: actions.GetContactPersonsAction
): SagaIterator {
  try {
    const { rentedBuildingIds } = action.payload
    const assignedPersons = rentedBuildingIds.map(r =>
      call(getAssignedPersons, r)
    )
    const response = yield all(assignedPersons)
    const data = response.map(
      ({ data }: { data: ContactPerson[] }, i: number) => {
        return {
          id: rentedBuildingIds[i],
          data
        }
      }
    )
    yield put(actions.getContactPersonsSuccess(data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getContactPersonsFail(e))
  }
}

export function* deleteContactPersonsFlow(
  action: actions.DeleteContactPersonsAction
): SagaIterator {
  try {
    const { id } = action.payload
    yield call(deleteContactPerson, id)
    yield put(actions.deleteContactPersonsSuccess(id))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.deleteContactPersonsFail(e))
  }
}

export default function* RentedBuildingContactPersonSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getContactPersonFlow)
  yield takeLatest(actions.Types.POST, postContactPersonsFlow)
  yield takeLatest(actions.Types.PUT, putContactPersonsFlow)
  yield takeLatest(actions.Types.DELETE, deleteContactPersonsFlow)
}
