import { Button } from "@paudigital/wdp-components"
import { Container } from "./style"
import { getProfile } from "app/redux/me/me.selectors"
import { useSelector } from "react-redux"
import { useTranslate } from "hooks/translate"
import Account from "./Account"
import Preferences from "./Preferences"
import { useLogout } from "hooks/logout"

const Profile = () => {
  const { translate } = useTranslate()
  const { logout } = useLogout()
  const profile = useSelector(getProfile)

  if (!profile) return null

  const handleLogout = () => {
    logout()
  }
  return (
    <Container>
      <Account />
      <Preferences />
      <Button
        label={translate("portal.account.button.logout")}
        fullWidth
        onClick={handleLogout}
      />
    </Container>
  )
}

export default Profile
