import { isValid } from "date-fns"
import { string, object, mixed } from "yup"

export const validationSchema = object({
  Title: string().required(
    "portal.documents.createdocument.title.error.required"
  ),
  Document_x0020_date: mixed().test(
    "Document_x0020_date",
    "portal.documents.createdocument.title.error.required",
    function (val: any) {
      if (!this.parent.showInspectionDateInput) return true
      const date = new Date(val)
      return isValid(date)
    }
  ),
  Document_x0020_Language: string().required(
    "portal.documents.createdocument.date.error.required"
  )
})
