import { resendInvite } from "app/redux/contactPerson/contactPerson.actions"
import { RentedBuildingContactPerson } from "app/types/rentedBuildingContactPerson"
import { useTranslate } from "hooks/translate"
import { useDispatch } from "react-redux"
import { StyledProfileCard } from "./style"

type ContactPersonCardProps = {
  contactPerson?: RentedBuildingContactPerson
  onAddContactPerson: () => void
  onEditContactPerson: (contactPersonId: string) => void
  onDeleteContactPerson: (contactPersonId: string) => void
  dataTestId?: string
}

type Props = ContactPersonCardProps

const ContactPersonCard = ({
  contactPerson,
  onAddContactPerson,
  onEditContactPerson,
  onDeleteContactPerson,
  dataTestId
}: Props) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleAddContactPerson = () => {
    onAddContactPerson()
  }

  const handleEditContactPerson = () => {
    onEditContactPerson(contactPerson!.id)
  }

  const handleDeleteContactPerson = () => {
    onDeleteContactPerson(contactPerson!.id)
  }

  const handleOnResend = () => {
    dispatch(resendInvite(contactPerson!.id))
  }

  if (!contactPerson) {
    return (
      <StyledProfileCard
        label={translate("portal.admin.card.empty.name")}
        roles={translate("portal.admin.card.empty.roles")}
        functionDescription={translate("portal.admin.card.empty.function")}
        active={false}
        onAdd={handleAddContactPerson}
        translations={{ add: translate("portal.admin.card.button.add") }}
        dataTestId={dataTestId}
      />
    )
  }
  const { firstName, lastName, profilePictureUrl, onboardedAt } = contactPerson
  return (
    <StyledProfileCard
      label={`${firstName} ${lastName}`}
      roles={contactPerson.roles.join(", ")}
      avatar={profilePictureUrl}
      active
      onEdit={handleEditContactPerson}
      onDelete={handleDeleteContactPerson}
      onResend={!onboardedAt ? handleOnResend : undefined}
      dataTestId={dataTestId}
      translations={{
        delete: translate("portal.admin.card.button.delete"),
        edit: translate("portal.admin.card.button.edit"),
        resend: translate("portal.admin.card.button.resend")
      }}
    />
  )
}

export default ContactPersonCard
