import { TicketCommunicationPayload } from "app/types/ticketCommunication"

export enum Types {
  PATCH = "PATCH_TICKETS",
  PATCH_SUCCESS = "PATCH_TICKETS_SUCCESS",
  PATCH_FAIL = "PATCH_TICKETS_FAIL",
  SET = "SET_TICKET_COMMUNICATION",
  DISCARD = "DISCARD_TICKET_COMMUNICATION",
  CLOSE = "REPORT_DETAIL_CLOSE"
}

export type Action =
  | PatchTicketsAction
  | PatchTicketsSuccessAction
  | PatchTicketsFailAction
  | SetTicketCommunicationAction
  | DiscardTicketCommunicationAction
  | CloseReportDetail

export type PatchTicketsAction = {
  payload: TicketCommunicationPayload
  type: Types.PATCH
}

export type PatchTicketsSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchTicketsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_FAIL
}

export type SetTicketCommunicationAction = {
  payload: { customerCommunication: string; files: File[] }
  type: Types.SET
}

export type DiscardTicketCommunicationAction = {
  type: Types.DISCARD
}

export type CloseReportDetail = {
  payload: { id: string }
  type: Types.CLOSE
}

export function patchTickets(payload: TicketCommunicationPayload): Action {
  return {
    type: Types.PATCH,
    payload
  }
}

export function patchTicketsSuccess(): Action {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchTicketsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function setTicketCommunication(payload: {
  customerCommunication: string
  files: File[]
}): Action {
  return {
    payload,
    type: Types.SET
  }
}

export function discardTicketCommunication(): Action {
  return {
    type: Types.DISCARD
  }
}

export function closeReportDetail(id: string): Action {
  return {
    payload: { id },
    type: Types.CLOSE
  }
}
