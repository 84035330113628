import ExportFilterTitle from "app/components/DownloadFilters/ExportFilterTitle"
import { IntervalEnum } from "app/enums/interval"
import { useTranslate } from "hooks/translate"
import GranularityDropdown from "./GranularityDropdown/index"
import { FiltersWrapper } from "./style"

type Props = {
  interval: IntervalEnum
  onIntervalChange: (interval: IntervalEnum) => void
  from: string
  to: string
}

export const GranularityFilter = ({
  interval,
  onIntervalChange,
  from,
  to
}: Props) => {
  const { translate } = useTranslate()

  return (
    <FiltersWrapper>
      <ExportFilterTitle>
        {translate("portal.energy.export.overview.filters.granularity.title")}
      </ExportFilterTitle>
      <GranularityDropdown
        interval={interval}
        onIntervalChange={onIntervalChange}
        from={from}
        to={to}
      />
    </FiltersWrapper>
  )
}

export default GranularityFilter
