import { Scopes } from "app/api/auth0"
import { AssociationTypes } from "app/api/mfa"
import { Action as ReduxAction } from "redux"

export enum Types {
  AUTHENTICATE = "AUTHENTICATE",
  AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS",
  AUTHENTICATE_FAIL = "AUTHENTICATE_FAIL",
  AUTHENTICATE_RESET = "AUTHENTICATE_RESET",
  START_MFA = "START_MFA",
  SET_MFA_TYPE= "SET_MFA_TYPE",
  SET_MFA_TYPE_SUCCESS= "SET_MFA_TYPE_SUCCESS",
  SET_MFA_TYPE_FAIL= "SET_MFA_TYPE_FAIL",
  CHALLENGE_MFA = "CHALLENGE_MFA",
  CHALLENGE_MFA_SUCCESS= "CHALLENGE_MFA_SUCCESS",
  CHALLENGE_MFA_FAIL = "CHALLENGE_MFA_FAIL",
  VERIFY_MFA = "VERIFY_MFA",
  VERIFY_MFA_SUCCESS = "VERIFY_MFA_SUCCESS",
  VERIFY_MFA_FAIL = "VERIFY_MFA_FAIL"
}

export enum PasswordUpdateType {
  PASSWORD_CHANGE = "PASSWORD_CHANGE",
  PASSWORD_CHANGE_90 = "PASSWORD_CHANGE_90"
}

export type Action =
  | AuthenticateAction
  | AuthenticateSuccessAction
  | AuthenticateFailAction
  | AuthenticateResetAction
  | StartMfaAction
  | SetMfaType
  | SetMfaTypeFail
  | SetMfaTypeSuccess
  | ChallengeMfaAction
  | ChallengeMfaSuccessAction
  | ChallengeMfaFailAction
  | VerifyMfaAction
  | VerifyMfaSuccessAction
  | VerifyMfaFailAction

export type AuthenticateAction = {
  type: Types.AUTHENTICATE
  payload: {
    currentPassword: string
    audience: string
    scope: Scopes
    actionOnSuccess?: () => ReduxAction<any>
  }
}

export type AuthenticateSuccessAction = {
  type: Types.AUTHENTICATE_SUCCESS
  payload: { token: string }
}

export type AuthenticateFailAction = {
  type: Types.AUTHENTICATE_FAIL
  payload: { error: unknown }
}

export type AuthenticateResetAction = {
  type: Types.AUTHENTICATE_RESET
}

export type StartMfaAction = {
  type: Types.START_MFA
  payload: {
    mfaToken: string
  }
}

export type SetMfaType = {
  type: Types.SET_MFA_TYPE
  payload: {
    type: AssociationTypes
  }
}

export type SetMfaTypeSuccess = {
  type: Types.SET_MFA_TYPE_SUCCESS
}

export type SetMfaTypeFail = {
  type: Types.SET_MFA_TYPE_FAIL
  payload: {
    error: unknown
  }
}

export type ChallengeMfaAction = {
  type: Types.CHALLENGE_MFA
}

export type ChallengeMfaSuccessAction = {
  type: Types.CHALLENGE_MFA_SUCCESS
  payload: {
    oobCode: string
  }
}

export type ChallengeMfaFailAction = {
  type: Types.CHALLENGE_MFA_FAIL
  payload: {
    error: unknown
  }
}

export type VerifyMfaAction = {
  type: Types.VERIFY_MFA
  payload: {
    oneTimeCode: string
    type: AssociationTypes
    actionOnSuccess: () => ReduxAction<any>
  }
}

export type VerifyMfaSuccessAction = {
  type: Types.VERIFY_MFA_SUCCESS
  payload: { token: string }
}

export type VerifyMfaFailAction = {
  type: Types.VERIFY_MFA_FAIL
  payload: { error: unknown }
}

export function authenticate({
  currentPassword,
  audience,
  scope,
  actionOnSuccess
}: {
  currentPassword: string
  audience: string
  scope: Scopes
  actionOnSuccess?: () => ReduxAction<any>
}): AuthenticateAction {
  return {
    type: Types.AUTHENTICATE,
    payload: { currentPassword, audience, scope, actionOnSuccess }
  }
}

export function authenticateSuccess(token: string): AuthenticateSuccessAction {
  return {
    type: Types.AUTHENTICATE_SUCCESS,
    payload: { token }
  }
}

export function authenticateFail(error: unknown): AuthenticateFailAction {
  return {
    type: Types.AUTHENTICATE_FAIL,
    payload: { error }
  }
}

export function authenticateReset(): AuthenticateResetAction {
  return {
    type: Types.AUTHENTICATE_RESET
  }
}

export function startMfa({
  mfaToken,
}: {
  mfaToken: string
}): StartMfaAction {
  return {
    type: Types.START_MFA,
    payload: { mfaToken }
  }
}

export function setMfaType(type: AssociationTypes): SetMfaType {
  return {
    type: Types.SET_MFA_TYPE,
    payload: { type }
  }
}

export function setMfaTypeSuccess(): SetMfaTypeSuccess {
  return {
    type: Types.SET_MFA_TYPE_SUCCESS
  }
}

export function setMfaTypeFail(error: unknown): SetMfaTypeFail {
  return {
    type: Types.SET_MFA_TYPE_FAIL,
    payload: { error }
  }
}

export function challengeMfa(): ChallengeMfaAction {
  return {
    type: Types.CHALLENGE_MFA
  }
}

export function challengeMfaSuccess({
  oobCode,
}: {
  oobCode: string
}): ChallengeMfaSuccessAction {
  return {
    type: Types.CHALLENGE_MFA_SUCCESS,
    payload: { oobCode }
  }
}

export function challengeMfaFail(error: unknown): ChallengeMfaFailAction {
  return {
    type: Types.CHALLENGE_MFA_FAIL,
    payload: { error }
  }
}

export function verifyMfa({
  oneTimeCode,
  type,
  actionOnSuccess,
}: {
  oneTimeCode: string
  type: AssociationTypes,
  actionOnSuccess: () => ReduxAction<any>
}): VerifyMfaAction {
  return {
    type: Types.VERIFY_MFA,
    payload: { oneTimeCode, actionOnSuccess, type }
  }
}

export function verifyMfaSuccess(token: string): VerifyMfaSuccessAction {
  return {
    type: Types.VERIFY_MFA_SUCCESS,
    payload: { token }
  }
}

export function verifyMfaFail(error: unknown): VerifyMfaFailAction {
  return {
    type: Types.VERIFY_MFA_FAIL,
    payload: { error }
  }
}
