import { DocumentStatisticsDto } from "app/types/document"
import { DrillDownOption } from "../DrilldownOption"
import { DrillDownChildWrapper } from "./style"
import { Filters } from "routes/Documents"

type DrillDownWithChildrenProps = {
  children: DocumentStatisticsDto[]
  setUrlParams: (params: Filters) => void
  indent?: boolean
} & Filters

export const DrillDownWithChildren = ({
  children,
  indent = false,
  documentType,
  page,
  perPage,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: DrillDownWithChildrenProps): JSX.Element => {
  const selected = documentType as string
  const queryParams = {
    documentType,
    page,
    perPage,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }

  const visibleChildren = children.filter(child => {
    return (
      child.document_type === selected ||
      (selected.length > child.document_type.length &&
        selected.startsWith(child.document_type)) ||
      (selected.length < child.document_type.length &&
        child.document_type.startsWith(selected))
    )
  })
  return (
    <>
      {visibleChildren.map(child => {
        // Don't show more than one level deeper than the currently selected category
        if (child.document_type.length > selected.length + 3) return <></>
        const isSelected = selected === child.document_type
        const arrow = isSelected
          ? undefined
          : child.document_type.length < selected.length
          ? "left"
          : "right"
        return (
          <DrillDownChildWrapper key={child.document_type} indent={indent}>
            <DrillDownOption
              arrow={arrow}
              isSelected={isSelected}
              option={child}
              setUrlParams={setUrlParams}
              {...queryParams}
            />
            <DrillDownWithChildren
              children={child.children}
              indent={indent || isSelected}
              setUrlParams={setUrlParams}
              {...queryParams}
            />
          </DrillDownChildWrapper>
        )
      })}
    </>
  )
}
