import State from "../state"
import { RentedBuildingContact } from "app/types/rentedBuildingContact"

export const loading = (state: State): boolean =>
  state.rentedBuildingContacts.loading
export const getContactPersons = (state: State): RentedBuildingContact[] =>
  state.rentedBuildingContacts.data || []
export const hasContactPersons = (state: State): boolean | undefined =>
  state.rentedBuildingContacts.data &&
  state.rentedBuildingContacts.data.length > 0
