import { theme } from "@paudigital/wdp-components"
import styled, { css } from "styled-components"

export const StyledDateRangePicker = styled.div<{ orientation: "row" | "column" }>`
  display: flex;

  @media screen and (${theme.breakpoints.tablet}) {
    flex-direction: ${props => props.orientation};
    ${props => (props.orientation === "row"
    && css`
        justify-content: space-between;

        & > div{
          width: calc(50% - 0.8rem);
        }
      `)}
  }
`
