import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const Caption = styled.span`
  font-size: ${theme.fontSizes.caption};
  color: ${theme.colors.neutral.darkerGrey};
  margin-top: 1.2rem;
  margin-bottom: 3.6rem;
  max-width: 40rem;
  text-align: center;
  line-height: 2rem;
`

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 12rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const TypographyWrapper = styled.div`
  margin-top: 2.4rem;
`

export const LinkWrapper = styled.div`
  margin-top: 1.6rem;
`
