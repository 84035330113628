import { Notification, PatchedNotification } from "app/types/notification"
import { Action, Types } from "./notifications.actions"

type ResponseParams = {
  page: number
  pages: number
  perPage: number
  total: number
}

export type Response = {
  notifications: Notification[]
  params: ResponseParams
}

export type NotificationsState = {
  data: Response | null
  patchedNotification: PatchedNotification | null
  notificationsCount: Record<string, number>
  isDirty: boolean
  errors?: Record<string, unknown>
  loading: boolean
  running: boolean
  elapsedTime: number
}

const initialState: NotificationsState = {
  data: null,
  patchedNotification: null,
  notificationsCount: {},
  isDirty: false,
  errors: undefined,
  loading: false,
  running: false,
  elapsedTime: 0
}

export default (state = initialState, action: Action): NotificationsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.data,
          notifications: [...action.payload.data.notifications]
        },
        loading: false
      }

    case Types.GET_MORE:
      return {
        ...state,
        loading: true
      }

    case Types.GET_MORE_SUCCESS:
      const notifications = state.data?.notifications || []
      return {
        ...state,
        data: {
          ...action.payload.data,
          notifications: [
            ...notifications,
            ...action.payload.data.notifications
          ]
        },
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PATCH:
      return {
        ...state,
        isDirty: true,
        loading: true
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        isDirty: true,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        isDirty: true,
        loading: false,
        patchedNotification: action.payload.patchedNotification
      }
    case Types.START_TIMER:
      return {
        ...state,
        running: true
      }
    case Types.RESET_TIMER:
      return {
        ...state,
        elapsedTime: 0,
        running: false
      }
    case Types.UPDATE_TIMER:
      return {
        ...state,
        elapsedTime: state.elapsedTime + 1
      }
    case Types.GET_NOTIFICATION_COUNT:
      return {
        ...state,
        loading: false
      }
    case Types.GET_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationsCount: action.payload.data
      }
    case Types.GET_NOTIFICATION_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    default:
      return state
  }
}
