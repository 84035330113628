import { useParams } from "react-router-dom"
import { useApi } from "hooks/api"
import { useEffect } from "react"
import { downloadTicket } from "app/redux/ticket/ticket.actions"
import { useTranslate } from "hooks/translate"

const TicketDownload = () => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const params = useParams()
  const { id } = params
  useEffect(() => {
    if (id) {
      call(downloadTicket(id))
    }
  }, [call, id])
  return <div>{translate("portal.download.start")}</div>
}

export default TicketDownload
