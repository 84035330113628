import { CardActions, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledDefectTitle = styled.h6`
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  margin-right: 2rem;
  overflow: hidden;
`
export const StyledDefectInfo = styled.span`
  margin-right: 1rem;
`

export const StyledDefectInfoProperty = styled.span`
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  margin-right: 0.3rem;
`

export const StyledDefectInfoPropertyValue = styled.span`
  font-size: ${theme.fontSizes.captionSmall};
`

export const StyledStatusTitle = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
`

export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
  min-width: 20%;
`

export const StyledGridIcon = styled.div`
  margin-right: 1.6rem;
`

export const StyledGridInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title"
    "description";
  grid-area: info;
  overflow-wrap: normal;
`

export const StyledGridDescription = styled.div`
  grid-area: description;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`

export const StyledTicketInfo = styled.div`
  display: flex;
  margin-top: 1.6rem;
`

export const StyledStatusInfo = styled.div`
  display: flex;
  margin: 1.6rem 0 2.4rem;
`

export const StyledWorkOrderCounts = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`
export const StyledWorkOrderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
