import ListItem from "app/components/ListItem"
import { TicketType } from "app/types/ticketCategory"

type Props = {
  id: string
  category: TicketType
  label: string
  onClick: (c: TicketType, id: string) => void
  itemNumber: number
}

const CategoryItem = ({ category, onClick, label, id, itemNumber }: Props) => {
  const handleClick = () => {
    onClick(category, id)
  }
  return (
    <ListItem label={label} onClick={handleClick} itemNumber={itemNumber} />
  )
}
export default CategoryItem
