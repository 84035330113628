import { InvoiceType } from "app/types/invoice"
import { useTranslate } from "hooks/translate"
import {
  Container,
  InvoiceValue,
  InvoiceValueAmount,
  InvoiceDueDate,
  InvoiceValueCurrency
} from "./style"
import { format } from "date-fns"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"

type Props = {
  invoiceType: InvoiceType
  dueDate: string
  currency: string
  amount: string
  size?: "normal" | "compact"
}

const InvoiceCardValue = ({
  amount,
  currency,
  invoiceType,
  dueDate,
  size = "normal"
}: Props) => {
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()
  return (
    <Container size={size}>
      <InvoiceValue size={size} invoiceType={invoiceType}>
        <InvoiceValueAmount size={size}>
          {invoiceType === InvoiceType.CREDIT_NOTE && "-"}
          {decimalFormatter(amount)}
        </InvoiceValueAmount>
        <InvoiceValueCurrency>{currency}</InvoiceValueCurrency>
      </InvoiceValue>
      {size === "normal" && (
        <InvoiceDueDate>
          <strong>{translate("portal.invoices.due.label")}:</strong>
          {format(new Date(dueDate), "dd-MM-yyyy")}
        </InvoiceDueDate>
      )}
    </Container>
  )
}

export default InvoiceCardValue
