import * as api from "app/api/pvProject"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./pvProject.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getPvProjectFlow(
  action: actions.GetPvProjectAction
): SagaIterator {
  try {
    const { rentedBuildingId } = action.payload
    const response = yield call(api.getPvProject, rentedBuildingId)
    yield put(actions.getPvProjectSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getPvProjectFail(e))
  }
}

export default function* pvProjectSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getPvProjectFlow)
}
