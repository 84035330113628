import { StyledLine, LineTop, Dot, LineBottom } from "./style"

const Line = () => {
  return (
    <StyledLine>
      <LineTop />
      <Dot />
      <LineBottom />
    </StyledLine>
  )
}

export default Line
