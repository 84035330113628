import styled from "styled-components"

export const ButtonWrapper = styled.div`
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
`

export const AppointmentWrapper = styled.div`
  margin-bottom: 1.6rem;
`

export const FileUploadWrapper = styled.div`
  margin-top: 1.6rem;
`
