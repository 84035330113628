import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Title = styled.h1<{ color?: string }>`
  color: ${props => props.color || theme.colors.neutral.white};
  width: 100%;
  margin-top: 2rem;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.h5};

  @media only screen and (${theme.breakpoints.tablet}) {
    font-size: ${theme.fontSizes.h4};
  }
`
