import { theme } from "@paudigital/wdp-components"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledContainer = styled.div`
  background: ${theme.colors.neutral.white};
  width: 100%;
  max-width: 32rem;
  justify-self: center;
  border-radius: 4px;

  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: initial;
  }
`

export const Container = styled.div`
  margin: 2.4rem;
`

export const Description = styled.span`
  font-size: ${theme.fontSizes.captionExtraSmall};
  font-weight: ${theme.fontWeights.light};
  margin-top: 0.8rem;
  display: block;
  line-height: 1.4rem;
  margin-bottom: 1.6rem;
  color: ${theme.colors.neutral.darkerGrey};
`

export const TextWrapper = styled.div`
  width: 17rem;
  line-height: 2.6rem;
`

export const TitleWrapper = styled.div`
  position: relative;
  max-width: 85%;

  @media only screen and (${theme.breakpoints.tablet}) {
    max-width: initial;
  }
`

export const WidgetImage = styled.div<{ pullUp: boolean }>`
  position: absolute;
  top: ${props => (props.pullUp ? -2.4 : 0)}rem;
  right: 0;
`

export const WidgetHeader = styled(Link)<{ as?: string; to: string }>`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  cursor: ${props => (props.to ? "pointer" : "default")};
  min-height: 12rem;
  position: relative;
`

export const ChevronWrapper = styled.span`
  position: absolute;
  transform: rotate(90deg);
  margin-left: 1.2rem;
  display: inline-block;
`
