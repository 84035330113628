import { Invoice, InvoiceResponse } from "app/types/invoice"
import { Action, Types } from "./invoices.actions"

export type InvoiceState = {
  data: InvoiceResponse
  isFilterOpen: boolean
  errors?: Record<string, unknown>
  loading: boolean
  summary: Invoice[]
}

const initialState: InvoiceState = {
  data: {
    total: 0,
    invoices: [],
    filters: {
      page: 1,
      pages: 1,
      perPage: 20,
      sort: ["invoiceDate=desc"]
    }
  },
  isFilterOpen: false,
  errors: undefined,
  loading: false,
  summary: []
}

export default (state = initialState, action: Action): InvoiceState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        errors: undefined
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_SUMMARY:
      return {
        ...state,
        loading: true,
        errors: undefined
      }

    case Types.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload.data,
        loading: false
      }

    case Types.GET_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.GET_SELECTED_INVOICES_FILTERS:
      return {
        ...state,
        isFilterOpen: action.payload
      }

    default:
      return state
  }
}
