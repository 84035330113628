import { CustomerNameTag, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const BuildingName = styled.div`
  cursor: pointer;
  font-size: ${theme.fontSizes.caption};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: space-between;

  strong {
    margin-right: 0.4rem;
    font-weight: ${theme.fontWeights.bold};
  }
`

export const Address = styled.h4`
  margin: 0;
  padding: 0;
`

export const StyledCustomerNameTag = styled(CustomerNameTag)`
  margin: 0 0.5rem;
  font-size: ${theme.fontSizes.captionExtraSmall};
  font-height: ${theme.fontSizes.captionExtraSmall};
`

export const ToggleWrapper = styled.div`
`

export const TogglesContainer = styled.div<{ collapsed: boolean }>`
  display: ${props => !props.collapsed && "none"};
`

export const Container = styled.div<{ collapsed: boolean }>`
  min-height: 2rem;
  background: ${theme.colors.neutral.lightGrey};
  border-radius: 4px;
  padding: 1.6rem;
  max-height: ${props => {
    if (props.collapsed) return "60rem"
    return "0"
  }};
  overflow: hidden;
  transition: all 0.5s ease;
`
export const StyledBuildingInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content:space-between;
  align-items: center;
`

export const Collapsable = styled.button<{ collapsed: boolean }>`
  transform: ${props => {
    if (!props.collapsed) return "rotate(180deg)"
    return "rotate(0deg)"
  }};
  transition: transform 0.3s;
  border: 0;
  background: none;
  cursor: pointer;

  &:focus {
    ${theme.outline}
  }
`
