import * as api from "app/api/invoices"
import { getInvoices, getInvoicesSummary } from "app/api/invoices"
import { castInvoiceParamsToUTC } from "app/utils/castInvoiceParamsToUTC.utils"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./invoices.actions"
import { getActiveBuilding } from "../buildingSwitcher/buildingSwitcher.selectors"
import { getFileNameFromHeaders } from "app/utils/getFileName"
import { fire, remove } from "../appEvents/appEvents.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"
import { QueryParams } from "app/redux/invoices/invoices.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"

export function* getInvoicesFlow(
  action: actions.GetInvoicesAction
): SagaIterator {
  try {
    const payload = castInvoiceParamsToUTC(action.payload, "Belgium")
    const response = yield call(getInvoices, payload)
    yield put(actions.getInvoicesSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getInvoicesFail(e))
  }
}

export function* getInvoicesSummaryFlow(
  action: actions.GetInvoicesSummaryAction
): SagaIterator {
  try {
    const { rentedBuildingId } = action.payload
    const response = yield call(getInvoicesSummary, rentedBuildingId)
    yield put(actions.getInvoicesSummarySuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [{ expectedHttpStatus: 403, redirectTo: "/no-permission" }]
      })
    )
    yield put(actions.getInvoicesSummaryFail(e))
  }
}
export function* downloadInvoiceCSVFlow(
  action: actions.DownloadInvoiceCSVAction
): SagaIterator {
  try {
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_LOADING,
        uniqueIdentifier: "document-loading-popup"
      })
    )
    const rentedBuildingId = yield select(getActiveBuilding)
    const payload = castInvoiceParamsToUTC(
      action.payload.data as QueryParams,
      "Belgium"
    )
    const response = yield call(
      api.downloadInvoiceCSV,
      payload,
      rentedBuildingId
    )
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(actions.downloadInvoiceCSVSuccess())
    yield put(remove("download-invoices"))
    yield put(remove("document-loading-popup"))
  } catch (e: any) {
    yield put(remove("document-loading-popup"))
    yield put(actions.downloadInvoiceCSVFail(e))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
  }
}
export default function* invoicesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getInvoicesFlow)
  yield takeLatest(actions.Types.GET_SUMMARY, getInvoicesSummaryFlow)
  yield takeLatest(actions.Types.DOWNLOAD_CSV, downloadInvoiceCSVFlow)
}
