import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledButton = styled.button`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: minmax(3rem, 90%) 3rem;
  justify-content: center;
  align-items: center;
  gap: 0 1rem;
  min-height: 44px;
  background: ${theme.colors.oceanGreen.prc125};
  color: ${theme.colors.neutral.white};
  font-family: inherit;
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.bold};
  border: 0;
  border-radius: 4px;
  text-overflow: ellipsis;
  cursor: pointer;

  ${theme.outline}

  &:hover {
    background: ${theme.colors.stromboliGreen.prc100};
  }
`

export const StyledLabel = styled.span`
  margin: 1rem auto 1rem 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`

export const RemoveIcon = styled.div``
