import { AxiosPromise } from "axios"
import { rentalApi } from "./api"

export const getAssignedPersons = (rentedBuildingId: string): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/contact_persons`, {
    method: "GET"
  })
}

export const getRentedBuildings = (): AxiosPromise => {
  return rentalApi(`/rented_buildings`,{
    method: "GET"
  })
}

export const getRentedBuildingContracts = (
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/contracts`, {
    method: "GET"
  })
}

export const getRentedBuildingContractNumbers = (
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/contract_numbers`, {
    method: "GET"
  })
}

export const getRentedBuildingContacts = (
  rentedBuildingId: string
): AxiosPromise => {
  return rentalApi(`/rented_buildings/${rentedBuildingId}/building_contacts`, {
    method: "GET"
  })
}
