import { determineDeleteFlow } from "app/redux/deleteContactPerson/deleteContactPerson.actions"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as deleteFlowSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import AdminRequiredModal from "./flows/AdminRequiredModal"
import AdminIsCurrentUserModal from "./flows/AdminIsCurrentUser"
import RevokeAccessSingleBuildingModal from "./flows/RevokeAccessSingleBuildingModal"
import RevokeAccessMultiBuildingModal from "./flows/RevokeAccessMultiBuildingModal"
import DeleteContactPersonSingleBuildingModal from "./flows/DeleteContactPersonSingleBuildingModal"
import DeleteContactPersonMultiBuildingModal from "./flows/DeleteContactPersonMultiBuildingModal"
import { PopUp } from "@paudigital/wdp-components"

type DeleteContactPersonProps = {
  contactPersonIdToDelete: string
  onClose: () => void
}

type Props = DeleteContactPersonProps

const DeleteContactPerson = ({
  contactPersonIdToDelete,
  onClose
}: Props) => {
  const dispatch = useDispatch()
  const deleteFlow = useSelector(deleteFlowSelectors.getDeleteFlow)
  const deleteFlowIsLoading = useSelector(deleteFlowSelectors.isLoading)

  React.useEffect(() => {
    dispatch(determineDeleteFlow(contactPersonIdToDelete))
  }, [dispatch, deleteFlow, contactPersonIdToDelete])

  return (
    <PopUp onClose={onClose} show disableClose>
      <>
        {deleteFlowIsLoading && <></>}
        {deleteFlow === DeleteContactPersonFlows.ADMIN_NEEDED && <AdminRequiredModal onCancel={onClose} />}
        {deleteFlow === DeleteContactPersonFlows.ADMIN_IS_CURRENT_USER && <AdminIsCurrentUserModal onCancel={onClose} />}
        {deleteFlow === DeleteContactPersonFlows.REVOKE_ACCESS_SINGLE_BUILDING && <RevokeAccessSingleBuildingModal onCancel={onClose} />}
        {deleteFlow === DeleteContactPersonFlows.REVOKE_ACCESS_MULTI_BUILDING && <RevokeAccessMultiBuildingModal onCancel={onClose} />}
        {deleteFlow === DeleteContactPersonFlows.DELETE_SINGLE_BUILDING && <DeleteContactPersonSingleBuildingModal onCancel={onClose} />}
        {deleteFlow === DeleteContactPersonFlows.DELETE_MULTI_BUILDING && <DeleteContactPersonMultiBuildingModal onCancel={onClose} />}
      </>
    </PopUp>
  )
}

export default DeleteContactPerson
