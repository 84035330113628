import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const Container = styled.div<{
  isVisible: boolean
}>`
  grid-area: address;
  background: ${theme.colors.neutral.white};

  @media only screen and (${theme.breakpoints.desktop}) {
    display: ${props => (props.isVisible ? "flex" : "none")};
    align-items: center;
    line-height: 1.6rem;
  }
`

export const SelectedBuilding = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-size: ${theme.fontSizes.caption};
  color: ${theme.colors.neutral.darkerGrey};
  display: flex;
  margin: 2.8rem ${props => (props.isActive ? "2.4rem" : 0)};
  align-items: center;
  -webkit-touch-callout: none;
  user-select: none;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 2.8rem 0.8rem;
  }

  strong {
    margin-right: 0.4rem;
    font-weight: bold;
    white-space: nowrap;

    @media only screen and (min-width: 60rem) {
      max-width: initial;
    }
  }

  &:focus {
    ${theme.outline}
  }
`

export const BuildingAddress = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: 60rem) {
    max-width: initial;
  }
`

export const ChevronWrapper = styled.div`
  margin-left: 1.2rem;
  display: flex;

  & svg {
    transform: rotate(180deg);
  }
`
