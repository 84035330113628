import { sec } from "app/utils/security"
import { AxiosRequestConfig } from "axios"

export async function requestInterceptor(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  try {
    const getAccessTokenSilently = sec.getAccessTokenSilently()
    if (!getAccessTokenSilently) return config
    const token = await getAccessTokenSilently()
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    return config
  } catch (error) {
    console.log("ERROR: ", error)
  } finally {
    return config
  }
}
