import { CrossIcon, theme } from "@paudigital/wdp-components"
import { FilterTag } from ".."
import { StyledButton, StyledLabel, RemoveIcon } from "./style"

export type TagProps = {
  tag: FilterTag
  remove: (tag: FilterTag) => void
}

type Props = TagProps

const Tag = ({ tag, remove }: Props) => {
  const removeTag = () => remove(tag)

  return (
    <StyledButton onClick={removeTag}>
      <StyledLabel>{tag.text}</StyledLabel>
      <RemoveIcon>
        <CrossIcon fill={theme.colors.neutral.white} width="1rem" height="1rem" />
      </RemoveIcon>
    </StyledButton>
  )
}

export default Tag
