import React from "react"
import { Description, ButtonWrapper } from "./style"
import { InputLabel, Link, Button } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "app/redux/registrationWizard/registrationWizard.actions"
import UserResponsibilities from "app/components/UserResponsibilities"
import * as responsibilitySelectors from "app/redux/responsibilities/responsibilities.selectors"
import * as meSelectors from "app/redux/me/me.selectors"
import * as responsibilityActions from "app/redux/responsibilities/responsibilities.actions"
import { RentedbuildingResponsibilities } from "app/types/responsibilities"
import { useApi } from "hooks/api"

const Responsibilities = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { call } = useApi()
  const currentUser = useSelector(meSelectors.getUserId)
  const rentedBuildings = useSelector(meSelectors.getRentedBuildings)
  const rbResponsibilities = useSelector(
    responsibilitySelectors.getResponsibilities
  )
  const responsibilitiesArr = rbResponsibilities?.map(
    rb => rb.responsibilities.length !== 0
  )
  const formChecked = responsibilitiesArr?.find(rb => rb === true)

  React.useEffect(() => {
    if (!rentedBuildings || rbResponsibilities !== undefined) return
    dispatch(
      responsibilityActions.setResponsibilities(
        rentedBuildings.reduce((reducerData, building) => {
          return [
            ...reducerData,
            {
              rentedBuildingId: building.id,
              country: building.country,
              city: building.city,
              postalCode: building.postalCode,
              street: building.street,
              houseNo: building.houseNo,
              entityName: building.entityName,
              responsibilities: building.responsibilities
            }
          ]
        }, [] as RentedbuildingResponsibilities[])
      )
    )
  }, [dispatch, rentedBuildings, rbResponsibilities])

  const handleNext = () => {
    call(
      responsibilityActions.patchInOnboardingResponsibilities(
        currentUser!,
        rbResponsibilities!
      )
    )
  }

  const handlePrevious = () => {
    dispatch(actions.decreaseWizard())
  }

  if (!rentedBuildings || !rbResponsibilities) return <></>
  return (
    <>
      <Description dataTestId="portal.onboarding.responsibilities.subtitle.label">
        <InputLabel
          required
          name="header"
          label={translate("portal.onboarding.responsibilities.subtitle.label")}
          guidanceText={translate(
            "portal.onboarding.responsibilities.subtitle.guidanceText"
          )}
        />
      </Description>
      <UserResponsibilities
        rentedbuildingResponsibilities={rbResponsibilities}
      />
      <ButtonWrapper>
        <Link
          type="default"
          onClick={handlePrevious}
          value={translate("portal.onboarding.step4.button.previous")}
        />
        <Button
          dataTestId="portal.onboarding.step4.button.next"
          label={translate("portal.onboarding.step4.button.next")}
          onClick={handleNext}
          disabled={!formChecked}
        />
      </ButtonWrapper>
    </>
  )
}

export default Responsibilities
