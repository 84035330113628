import { CategoryDrilldownWrapper } from "./style"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import * as selectors from "app/redux/document/document.selectors"
import { ChevronLeft } from "@paudigital/wdp-components"
import { DrillDownOption } from "./DrilldownOption"
import {
  ChevronLeftWrapper,
  DrillDownWrapper,
  StyledDrilldown
} from "./DrilldownOption/style"
import { DrillDownWithChildren } from "./DrilldownWithChildren"
import { Filters } from "routes/Documents"

type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const CategoryDrilldown = ({
  page,
  perPage,
  documentType,
  uploadDateFrom,
  uploadDateTo,
  uploadedBy,
  inspectionDateFrom,
  inspectionDateTo,
  contractId,
  sort,
  setUrlParams
}: Props) => {
  const { translate } = useTranslate()
  const documentStatistics = useSelector(
    selectors.getDocumentStatisticsFromDocument
  )
  const queryParams = {
    page,
    perPage,
    documentType,
    uploadDateFrom,
    uploadDateTo,
    uploadedBy,
    inspectionDateFrom,
    inspectionDateTo,
    contractId,
    sort
  }

  if (!documentStatistics) return <></>

  const reset = () =>
    setUrlParams({
      ...queryParams,
      documentType: undefined
    })

  if (documentType) {
    return (
      <CategoryDrilldownWrapper>
        <DrillDownWrapper>
          <ChevronLeftWrapper>
            <ChevronLeft height="14" width="14" />
          </ChevronLeftWrapper>
          <StyledDrilldown onClick={reset}>
            {translate("portal.facilitymanagement.filter.category_all")}
          </StyledDrilldown>
        </DrillDownWrapper>
        <DrillDownWithChildren
          children={documentStatistics}
          setUrlParams={setUrlParams}
          {...queryParams}
        />
      </CategoryDrilldownWrapper>
    )
  }

  return (
    <CategoryDrilldownWrapper>
      {documentStatistics.map(option => (
        <DrillDownOption
          arrow="right"
          key={option.document_type}
          option={option}
          setUrlParams={setUrlParams}
          {...queryParams}
        ></DrillDownOption>
      ))}
    </CategoryDrilldownWrapper>
  )
}

export default CategoryDrilldown
