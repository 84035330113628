import { ChevronIcon } from "@paudigital/wdp-components"
import React from "react"
import {
  ChevronWrapper,
  Container,
  SelectedBuilding,
  BuildingAddress
} from "./style"
import { useDispatch, useSelector } from "react-redux"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import {
  getBuildingById,
  hasSingleRentedBuilding
} from "app/redux/me/me.selectors"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"

type NavigationSelectedBuildingProps = {
  isVisible: boolean
  isActive: boolean
}

type Props = NavigationSelectedBuildingProps

const NavigationSelectedBuilding = ({ isVisible, isActive }: Props) => {
  const activeBuilding = useSelector(getActiveBuilding)
  const rentedBuilding = useSelector(getBuildingById(activeBuilding))
  const hasSingleBuilding = useSelector(hasSingleRentedBuilding)
  const dispatch = useDispatch()

  const switchBuilding = () => {
    if (!hasSingleBuilding)
      dispatch(
        fire({
          eventName: PossibleAppEvents.BUILDINGSWITCHER,
          uniqueIdentifier: "building-switcher"
        })
      )
  }

  const handleClick = () => {
    switchBuilding()
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === " " || e.key === "Enter") switchBuilding()
  }

  return (
    <Container
      isVisible={isVisible}
      onKeyPress={handleKeyPress}
      data-testid="building-switcher"
    >
      {rentedBuilding && (
        <SelectedBuilding
          onClick={handleClick}
          tabIndex={hasSingleBuilding ? undefined : 1}
          isActive={isActive}
        >
          <BuildingAddress>
            <strong>{rentedBuilding?.city}</strong>{" "}
            <span>
              - {rentedBuilding.street} {rentedBuilding.houseNo}
            </span>
          </BuildingAddress>
          {!hasSingleBuilding && (
            <ChevronWrapper>
              <ChevronIcon height="6" width="10" />
            </ChevronWrapper>
          )}
        </SelectedBuilding>
      )}
    </Container>
  )
}

export default NavigationSelectedBuilding
