import ModuleWidget from "app/components/ModuleWidget"
import { Modules } from "app/enums/modules"
import { useApi } from "hooks/api"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useTranslate } from "hooks/translate"
import React from "react"
import Icon from "./icon"
import { getEnergyUsageComparison } from "app/redux/energyUsageComparison/energyUsageComparison.actions"
import {
  getEnergyUsageComparisonData,
  getErrorsStatus,
  hasErrors,
  isLoading
} from "app/redux/energyUsageComparison/energyUsageComparison.selectors"
import { useSelector } from "react-redux"
import UsageComparisonGraph from "./UsageComparisonGraph"
import { Co2CalculationMethods, EnergyUsageTypes } from "app/types/energy"
import NoData, { NoDataProps } from "./NoData"
import { UsageComparisonWrapper } from "./style"
import UsageComparisonBar from "./UsageComparisonBar"
import { theme } from "@paudigital/wdp-components"
import { getUsageComparisonData } from "app/utils/energy.utils"
import { startOfDay, sub } from "date-fns"
import { formatISODateWithoutTimezone } from "app/utils/dateFormatter.utils"
import { usePermission } from "hooks/permission"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { NoEnergyReasons } from "app/types/rentedBuilding"

const Energy = () => {
  const { rentedBuilding, hasContractExpired } = useRentedBuilding()
  const rentedBuildingId = useSelector(getActiveBuilding)
  const { call } = useApi()
  const { translate } = useTranslate()
  const { hasPermission } = usePermission()
  const noEnergyReason = rentedBuilding?.noEnergyReason
  const energyUsageComparisonData = useSelector(getEnergyUsageComparisonData)
  const energyUsageComparisonDataLoading = useSelector(isLoading)
  const energyUsageComparisonErrorStatus = useSelector(getErrorsStatus)
  const energyUsageComparisonErrors = useSelector(hasErrors)
  const energyPermission =
    rentedBuildingId && hasPermission(Modules.ENERGY, rentedBuildingId!)

  const electricityConsumption = getUsageComparisonData(
    energyUsageComparisonData,
    EnergyUsageTypes.ELECTRICITY_CONSUMPTION
  )
  const solarConsumption = getUsageComparisonData(
    energyUsageComparisonData,
    EnergyUsageTypes.SOLAR_CONSUMPTION
  )
  const solarInjection = getUsageComparisonData(
    energyUsageComparisonData,
    EnergyUsageTypes.SOLAR_INJECTION
  )
  const gasConsumption = getUsageComparisonData(
    energyUsageComparisonData,
    EnergyUsageTypes.GAS_CONSUMPTION
  )
  const waterConsumption = getUsageComparisonData(
    energyUsageComparisonData,
    EnergyUsageTypes.WATER_CONSUMPTION
  )

  const hasEnergyReason = noEnergyReason !== NoEnergyReasons.NANOGRID_READY

  const solarConsumptionPercentageOfTotal = React.useMemo(() => {
    if (
      electricityConsumption?.currentPeriodTotal === undefined ||
      solarConsumption?.currentPeriodTotal === undefined
    ) {
      return undefined
    }
    return Number(
      (
        (solarConsumption?.currentPeriodTotal /
          electricityConsumption?.currentPeriodTotal) *
        100
      ).toFixed(2)
    )
  }, [
    electricityConsumption?.currentPeriodTotal,
    solarConsumption?.currentPeriodTotal
  ])

  const unusedSolarEnergyPercentage = React.useMemo(() => {
    if (
      solarConsumption?.currentPeriodTotal === undefined ||
      solarInjection?.currentPeriodTotal === undefined
    ) {
      return undefined
    }
    return Number(
      (
        (solarInjection?.currentPeriodTotal /
          (solarConsumption?.currentPeriodTotal +
            solarInjection?.currentPeriodTotal)) *
        100
      ).toFixed(2)
    )
  }, [solarConsumption?.currentPeriodTotal, solarInjection?.currentPeriodTotal])

  React.useEffect(() => {
    if (
      !hasEnergyReason &&
      rentedBuilding?.id &&
      rentedBuilding?.country &&
      energyPermission &&
      !hasContractExpired
    ) {
      const currentPeriodTo = startOfDay(new Date())
      const currentPeriodFrom = sub(currentPeriodTo, { days: 30 })
      const previousPeriodFrom = sub(currentPeriodFrom, { days: 30 })
      call(
        getEnergyUsageComparison(rentedBuilding?.id, rentedBuilding?.country, {
          previousPeriodFrom: formatISODateWithoutTimezone(previousPeriodFrom),
          previousPeriodTo: formatISODateWithoutTimezone(currentPeriodFrom),
          currentPeriodFrom: formatISODateWithoutTimezone(currentPeriodFrom),
          currentPeriodTo: formatISODateWithoutTimezone(currentPeriodTo),
          co2CalculationMethod: Co2CalculationMethods.GREY
        })
      )
    }
  }, [
    call,
    hasEnergyReason,
    energyPermission,
    hasContractExpired,
    rentedBuilding?.id,
    rentedBuilding?.country
  ])

  const isNoData =
    !energyUsageComparisonDataLoading &&
    energyUsageComparisonErrors &&
    [400, 404].includes(energyUsageComparisonErrorStatus!)
  const isNoConnection =
    !energyUsageComparisonDataLoading &&
    energyUsageComparisonErrors &&
    energyUsageComparisonErrorStatus! > 404

  if (energyUsageComparisonErrorStatus === 400 || hasContractExpired) {
    return <></>
  }

  return (
    <ModuleWidget
      permissionModule={Modules.ENERGY}
      title={translate("portal.dashboard.widget.energy.title")}
      description={translate("portal.dashboard.widget.energy.guidance")}
      image={<Icon />}
      url={`/energy/${rentedBuilding?.id}`}
      dataTestId="dashboard-energy-widget"
    >
      <>
        {hasEnergyReason && <NoData message={`noEnergyReason.${noEnergyReason}` as NoDataProps["message"]} />}
        {isNoData && !hasEnergyReason && <NoData message="nodata" />}
        {isNoConnection && !hasEnergyReason && <NoData message="noconnection" />}
        {!energyUsageComparisonDataLoading &&
          (electricityConsumption || gasConsumption || waterConsumption) && (
            <UsageComparisonWrapper>
              {electricityConsumption && (
                <UsageComparisonGraph {...electricityConsumption} />
              )}
              {gasConsumption && <UsageComparisonGraph {...gasConsumption} />}
              {waterConsumption && (
                <UsageComparisonGraph {...waterConsumption} />
              )}
              {solarConsumption && (
                <UsageComparisonBar
                  name={solarConsumption.name}
                  percentageDifference={solarConsumptionPercentageOfTotal}
                  color={
                    solarConsumptionPercentageOfTotal
                      ? theme.colors.green.contrast
                      : theme.colors.neutral.darkerGrey
                  }
                />
              )}
              {solarInjection && (
                <UsageComparisonBar
                  name={solarInjection.name}
                  percentageDifference={unusedSolarEnergyPercentage}
                  color={
                    unusedSolarEnergyPercentage
                      ? theme.colors.orange.prc75
                      : theme.colors.neutral.darkerGrey
                  }
                />
              )}
            </UsageComparisonWrapper>
          )}
      </>
    </ModuleWidget>
  )
}

export default Energy
