import { FilterButton } from "./style"

export type Props = {
  selectAll: () => void
  deselectAll: () => void
  selectText: string
  deselectText: string
}

export const FilterFunctions = ({
  selectAll,
  deselectAll,
  selectText,
  deselectText
}: Props) => {
  return (
    <div>
      <FilterButton onClick={selectAll}>{selectText}</FilterButton>
      <FilterButton onClick={deselectAll}>{deselectText}</FilterButton>
    </div>
  )
}

export default FilterFunctions
