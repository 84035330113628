import { Action, Types } from "./rentedBuildings.actions"
import { RentedBuildingContactPerson } from "app/types/rentedBuildingContactPerson"

export type RentedBuildingsState = {
  rentedBuildingContactPersons: RentedBuildingContactPerson[]
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: RentedBuildingsState = {
  rentedBuildingContactPersons: [],
  errors: undefined,
  loading: false
}

export default (state = initialState, action: Action): RentedBuildingsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        rentedBuildingContactPersons: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
