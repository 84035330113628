import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const PageWrapper = styled.div`
  margin: 0 2.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 5.2rem 0 0;
  }
`

export const GraphWrapper = styled.div`
  margin: 3.2rem 0;
`
export const StyledNoticationWrapper = styled.div`
  margin-top: 1.6rem;
`
