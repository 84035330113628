import {
  TitleWrapper,
  CardContainer,
  StyledEnergyComparisonCardBody
} from "./style"
import { getEnergyUsageComparisonData } from "app/redux/energyUsageComparison/energyUsageComparison.selectors"
import { useSelector } from "react-redux"
import { getEnergyPeriod } from "app/redux/energyPeriod/energyPeriod.selectors"
import EnergyComparisonCardBody from "../EnergyComparisonCard/EnergyComparisonBody"
import EnergyComparisonCardTitle from "./EnergyComparisonCardTitle"
import { EnergyUsageTypes } from "app/types/energy"
import TotalConsumptionCardBody from "./TotalConsumptionCardBody"

type EnergyComparisonCardProps = {
  cardName: string
  hasSolar: boolean
  isSolar?: boolean
}

const EnergyComparisonCard = ({
  cardName,
  hasSolar,
  isSolar
}: EnergyComparisonCardProps) => {
  const energyUsageComparisonData = useSelector(getEnergyUsageComparisonData)
  const energyPeriod = useSelector(getEnergyPeriod)

  const solarData = isSolar
    ? energyUsageComparisonData?.filter(
        eu =>
          eu?.name !== EnergyUsageTypes.GAS_CONSUMPTION &&
          eu?.name !== EnergyUsageTypes.WATER_CONSUMPTION &&
          eu?.name !== EnergyUsageTypes.CO2_EMISSION
      )
    : undefined
  const compData = energyUsageComparisonData?.find(d => d.name === cardName)
  const isCO2 = compData?.name === EnergyUsageTypes.CO2_EMISSION

  const getSolarData = (type: string) => solarData?.find(d => d.name === type)!

  return (
    <CardContainer boxShadow="none" hasSolar={hasSolar} isSolar={!!isSolar}>
      {compData && (
        <>
          <TitleWrapper hasSolar={hasSolar} isSolar={!!isSolar}>
            <EnergyComparisonCardTitle
              comparisonData={compData}
              period={energyPeriod}
              isCO2={isCO2}
              hasSolar={hasSolar}
            />
          </TitleWrapper>

          <StyledEnergyComparisonCardBody>
            {isSolar && solarData ? (
              <TotalConsumptionCardBody
                totalConsumption={getSolarData("electricityConsumption")}
                electricityGridConsumption={getSolarData("electricityGrid")}
                solarConsumption={getSolarData("solarConsumption")}
                solarInjection={getSolarData("solarInjection")}
              />
            ) : (
              <EnergyComparisonCardBody
                comparisonData={compData}
                period={energyPeriod}
                isCO2={isCO2}
              />
            )}
          </StyledEnergyComparisonCardBody>
        </>
      )}
    </CardContainer>
  )
}

export default EnergyComparisonCard
