import { DropDown, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledSort = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 3.2rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }
`

export const StyledLabel = styled.label`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.caption};
  font-weight: ${theme.fontWeights.bold};
  margin-right: 1.6rem;
`

export const StyledDropdown = styled(DropDown)`
  width: 100%;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 18rem;
  }
`
