import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledPageFilter = styled.div`
  background: ${theme.colors.neutral.white};
  overflow-y: hidden;
  z-index: 2;

  @media only screen and (${theme.breakpoints.desktop}) {
    position: relative;
    background: none;
    overflow-y: revert;
    z-index: auto;
    margin-bottom: 5rem;
  }
`

export const PageFiltersWrapper = styled.div`
  margin: 2.4rem;

  @media only screen and (${theme.breakpoints.desktop}) {
    margin-top: 0;
  }
`
export const ApplyFiltersButton = styled.div`
  margin: 2.4rem auto;
  display: flex;
  justify-content: center;
`
