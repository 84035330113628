import { Button, InputElement, InputLabel } from "@paudigital/wdp-components"
import { useDispatch, useSelector } from "react-redux"
import {
  ButtonWrapper,
  Summary,
  SummaryItem,
  SummaryDetail,
  SummaryLabel
} from "./style"
import {
  getAnswerCodes,
  getTicket,
  loading
} from "app/redux/ticket/ticket.selectors"
import { useTranslate } from "hooks/translate"
import { actions } from "app/redux/ticketWizard/ticketWizard.actions"
import { getStep } from "app/redux/ticketWizard/ticketWizard.selectors"
import { useFormik } from "formik"
import { validationSchema } from "./config"
import { setTicketField } from "app/redux/ticket/ticket.actions"
import { AnswerCodeIds, PostTicket } from "app/types/ticket"
import { postTicket } from "app/redux/ticket/ticket.actions"
import AppEventProvider from "app/components/AppEventsProvider"
import { useApi } from "hooks/api"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"
import { useRentedBuilding } from "hooks/rentedBuilding"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

const ProblemSummary = () => {
  const { call } = useApi()
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { rentedBuilding } = useRentedBuilding()
  const { integerFormatter } = getLocaleNumberFormatter()
  const step = useSelector(getStep)
  const ticket = useSelector(getTicket)
  const answerCodes = useSelector(getAnswerCodes)
  const isLoading = useSelector(loading)
  const answerCodeId = answerCodes?.filter(a => {
    if (
      a.id === AnswerCodeIds.WDP_ELEKTRICITEIT2 ||
      a.id === AnswerCodeIds.WDP_POORT_ETC2
    ) {
      return false
    }
    return a.value
  })
  const lightbulbs = answerCodes?.find(
    e => e.id === AnswerCodeIds.WDP_ELEKTRICITEIT2
  )
  const gateNumber = answerCodes?.find(
    e => e.id === AnswerCodeIds.WDP_POORT_ETC2
  )

  const handlePrevious = () => {
    dispatch(setTicketField("description", undefined))
    dispatch(setTicketField("communicationCustomer", undefined))
    dispatch(actions.decreaseWizard())
  }

  const {
    errors,
    handleChange,
    handleSubmit,
    isValid,
    handleBlur,
    values,
    setFieldValue
  } = useFormik({
    initialValues: ticket,
    onSubmit: (ticket: Partial<PostTicket>) => {
      if (isValid && rentedBuilding) {
        dispatch(createGAEvent(PossibleGAEvents.NEW_TICKET))
        call(postTicket(rentedBuilding.id, ticket))
      }
    },
    validationSchema
  })

  const hasErrors = (name: string): boolean =>
    errors[name as keyof Partial<PostTicket>] ? true : false

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value: val } = e.currentTarget
    const name = e.target.name
    handleBlur(e)
    setFieldValue(name, val)
    dispatch(setTicketField(name as keyof PostTicket, val))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: val, name } = e.currentTarget
    handleChange(e)
    dispatch(setTicketField(name as keyof PostTicket, val))
  }

  if (step !== 3) return null

  return (
    <form onSubmit={handleSubmit}>
      <AppEventProvider local />
      <InputElement
        label={translate(
          "portal.facilitymanagement.createticket.step3.subject.label"
        )}
        name="description"
        charCount
        charLimit={100}
        charTranslation={translate(
          "portal.facilitymanagement.createticket.step3.subject.characters.label"
        )}
        value={values.description}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        error={hasErrors("description")}
        errorMessage={errors.description && translate(errors.description)}
        prefixValue={translate(
          `portal.facilitymanagement.${ticket.type}${
            answerCodeId.length > 1
              ? "." + answerCodeId.flatMap(answer => answer.value).join(".")
              : answerCodes[0]?.value
              ? "." + answerCodes[0]?.value
              : ""
          }`
        )}
        dataTestId="portal.facilitymanagement.createticket.step3.subject"
      />
      <InputLabel
        label={translate(
          "portal.facilitymanagement.createticket.step3.details.label"
        )}
      />
      <Summary>
        {gateNumber && (
          <SummaryItem>
            <SummaryLabel>
              {translate(
                "portal.facilitymanagement.createticket.step3.gatenumber.label"
              )}
            </SummaryLabel>
            <SummaryDetail>{gateNumber.value}</SummaryDetail>
          </SummaryItem>
        )}
        {lightbulbs && (
          <SummaryItem>
            <SummaryLabel>
              {translate(
                "portal.facilitymanagement.createticket.step3.lightbulbs.label"
              )}
            </SummaryLabel>
            <SummaryDetail>{integerFormatter(lightbulbs.value)}</SummaryDetail>
          </SummaryItem>
        )}
        {ticket.problem && (
          <SummaryItem>
            <SummaryLabel>
              {translate(
                "portal.facilitymanagement.createticket.step3.problem.label"
              )}
            </SummaryLabel>
            <SummaryDetail>{ticket!.problem}</SummaryDetail>
          </SummaryItem>
        )}
        <SummaryItem>
          <SummaryLabel>
            {translate(
              "portal.facilitymanagement.createticket.step3.contact.label"
            )}
          </SummaryLabel>
          <SummaryDetail>
            {translate(
              `portal.facilitymanagement.createticket.step3.contact.${
                ticket!.customerContact ? "yes" : "no"
              }`
            )}
          </SummaryDetail>
        </SummaryItem>
        <SummaryItem>
          <SummaryLabel>
            {translate(
              "portal.facilitymanagement.createticket.step3.priority.label"
            )}
          </SummaryLabel>
          <SummaryDetail>
            {translate(
              `portal.facilitymanagement.createticket.step3.priority.${
                ticket.priority ? "yes" : "no"
              }`
            )}{" "}
            {ticket?.explanationPriority}
          </SummaryDetail>
        </SummaryItem>
      </Summary>
      <InputElement
        label={translate(
          "portal.facilitymanagement.createticket.step3.additional.label"
        )}
        charTranslation={translate(
          "portal.facilitymanagement.createticket.step3.additional.characters.label"
        )}
        optional
        required={false}
        onChange={handleInputChange}
        optionalText={translate("portal.input.optional")}
        type="textarea"
        charCount
        name="communicationCustomer"
        charLimit={2000}
        value={values.communicationCustomer}
        error={hasErrors("communicationCustomer")}
        onBlur={handleInputBlur}
        errorMessage={
          errors.communicationCustomer &&
          translate(errors.communicationCustomer)
        }
        dataTestId="portal.facilitymanagement.createticket.step3.additional"
      />
      <ButtonWrapper>
        <Button
          label={translate(
            "portal.facilitymanagement.createticket.step3.button.previous"
          )}
          type="secondary"
          onClick={handlePrevious}
        />
        <Button
          label={translate(
            "portal.facilitymanagement.createticket.step3.button.report"
          )}
          submit
          disabled={isLoading}
          dataTestId="portal.facilitymanagement.createticket.step3.button.report"
        />
      </ButtonWrapper>
    </form>
  )
}

export default ProblemSummary
