import { AssociationTypes } from "app/api/mfa"
import State from "../state"

export const getToken = ({ authentication }: State): string | undefined => {
  return authentication.token
}
export const getMfaToken = ({ authentication }: State): string | undefined => {
  return authentication.mfaToken
}
export const getOobCode = ({ authentication }: State): string | undefined => {
  return authentication.oobCode
}
export const getMfaType = ({ authentication }: State): AssociationTypes => {
  return authentication.type
}
export const getLoading = ({ authentication }: State): boolean => {
  return authentication.loading
}
export const getError = ({ authentication }: State) => authentication.error
