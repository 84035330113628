import { BaseCard, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const CardContainer = styled(BaseCard)<{
  hasSolar: boolean
  isSolar: boolean
}>`
  cursor: default;
  display: flex;
  flex-direction: column;
  flex: 100%;
  background: ${theme.colors.neutral.white};
  border: 1px solid ${theme.colors.neutral.midGrey};
  border-radius: 10px;
  width: ${props => (props.hasSolar ? "auto" : "100%")};
  overflow: hidden;

  @media only screen and (${theme.breakpoints.desktop}) {
    flex-direction: ${props => props.hasSolar && !props.isSolar && "row"};
    align-items: stretch;
    height: ${props => props.isSolar && "99.5%"};
    justify-content: normal;
  }
`

export const TitleWrapper = styled(BaseCard)<{
  hasSolar: boolean
  isSolar: boolean
}>`
  cursor: default;
  background: ${theme.colors.neutral.midGrey};
  border-radius: 0;
  width: 100%;

  @media only screen and (${theme.breakpoints.desktop}) {
    width: ${props => (props.hasSolar && !props.isSolar ? 35 : 100)}%;
  }
`

export const StyledEnergyComparisonCardHeader = styled.div<{
  fillPercentage: number
}>`
  background: ${theme.colors.neutral.midGrey};
  flex: 1 1 100%;
  border-radius: 0.8rem;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;

  @media only screen and (${theme.breakpoints.desktop}) {
    flex: 1 1 ${props => props.fillPercentage}%;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: ${props =>
      props.fillPercentage === 100 ? "0.8rem" : "0rem"};
    border-bottom-left-radius: ${props =>
      props.fillPercentage === 100 ? "0rem" : "0.8rem"};
    border-bottom-right-radius: 0rem;
  }
`

export const StyledEnergyComparisonCardBody = styled.div`
  width: 100%;
`

export const StyledText = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 1.2rem;
`

export const StyledH5 = styled.h5`
  font-size: ${theme.fontSizes.h6};
  font-weight: ${theme.fontWeights.bold};
  line-height: ${theme.lineHeights.h6};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 0.4rem;
`

export const StyledDisclaimer = styled.sup`
  font-size: ${theme.fontSizes.h6};
  font-weight: ${theme.fontWeights.bold};
  line-height: ${theme.lineHeights.h6};
  color: ${theme.colors.neutral.darkerGrey};
`

export const StyledSubText = styled.p<{
  uppercased?: boolean
  fontSize?: "h6" | "captionExtraSmall"
  fontWeight: "regular" | "bold" | "medium"
  diff?: number
  inline?: boolean
}>`
  ${props => props.inline && "display: inline"};
  ${props => props.inline && "margin-left: .3rem"};
  text-transform: ${props => (props.uppercased ? "uppercase" : "initial")};
  font-size: ${props => {
    switch (props.fontSize) {
      case "h6":
        return theme.fontSizes.h6
      default:
        return theme.fontSizes.captionExtraSmall
    }
  }};
  line-height: ${props => {
    switch (props.fontSize) {
      case "h6":
        return theme.lineHeights.h6
      default:
        return theme.lineHeights.captionExtraSmall
    }
  }};
  font-weight: ${props => {
    switch (props.fontWeight) {
      case "bold":
        return theme.fontWeights.bold
      case "medium":
        return theme.fontWeights.medium
      default:
        return theme.fontWeights.regular
    }
  }};
  color: ${props => {
    if (props.diff && props.diff !== 0) {
      return props.diff > 0
        ? theme.colors.system.moreInfo
        : theme.colors.green.contrast
    }
    return theme.colors.neutral.darkerGrey
  }};
`

export const StyledArrow = styled.span<{ diff: number }>`
  display: inline-block;
  font-size: ${theme.fontSizes.caption};
  color: ${props =>
    props.diff <= 0
      ? theme.colors.green.contrast
      : theme.colors.system.moreInfo};
  transform-origin: 0.7rem 1.1rem;
  margin-right: 1.2rem;
`

export const Percentage = styled.span`
  display: inline-block;
`

export const BodyWrapper = styled.div`
  margin: 1.6rem;

  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 2.4rem;
  }
`
