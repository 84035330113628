import { Auth0ContextInterface } from "@auth0/auth0-react"

type GetAccessTokenSilently = Auth0ContextInterface["getAccessTokenSilently"]
let getAccessTokenSilently: GetAccessTokenSilently | null = null

export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: GetAccessTokenSilently) =>
    (getAccessTokenSilently = func)
}
