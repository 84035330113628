import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"
import { ContractStatus } from "./types"

const StatusColor = {
  [ContractStatus.ACTIVE]: theme.colors.system.success,
  [ContractStatus.INACTIVE]: theme.colors.system.error,
  [ContractStatus.UPCOMING]: theme.colors.system.planned,
}

export const StyledGroupedLabelContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  background-color:${theme.colors.neutral.lightGrey}
`

export const StyledGroupedLabel = styled.div`
  flex-grow: 1;
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionExtraSmall};
`

export const StyledGroupLabelStatus = styled.div<{ status: ContractStatus }>`
  margin: 1rem 1rem 1rem 1.6rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${({ status }) => `${StatusColor[status]}`};
`
