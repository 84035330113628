import { useDispatch, useSelector } from "react-redux"
import * as selectors from "app/redux/me/me.selectors"
import { useNavigate, generatePath, useParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { setBuildingSwitcher } from "app/redux/buildingSwitcher/buildingSwitcher.actions"

export const useRentedBuilding = () => {
  const user = useSelector(selectors.getProfile)
  const dispatch = useDispatch()
  const path = window.location.pathname
  const params = useParams()
  const navigate = useNavigate()
  const preferredBuildingId = useSelector(selectors.getPreferredBuildingId)
  const activeBuilding = useSelector(getActiveBuilding)

  const { id } = params
  const rentedBuildingId = id || activeBuilding || preferredBuildingId

  const rentedBuilding = useSelector(
    selectors.getBuildingById(rentedBuildingId)
  )

  const hasContractExpired = useMemo(() => {
    return !rentedBuilding || new Date(rentedBuilding.endDate) < new Date()
  }, [rentedBuilding])

  useEffect(() => {
    if (!user) return
    if (!activeBuilding && id) {
      dispatch(setBuildingSwitcher(id))
      return
    }
    if (id && activeBuilding !== id) {
      dispatch(setBuildingSwitcher(id))
      return
    }
    if (!activeBuilding && !id && preferredBuildingId) {
      dispatch(setBuildingSwitcher(preferredBuildingId))
      return
    }
    if ("id" in params && !id && activeBuilding) {
      navigate(generatePath(path, { id: activeBuilding }), { replace: true })
    }
  }, [
    user,
    activeBuilding,
    dispatch,
    path,
    preferredBuildingId,
    params,
    id,
    navigate
  ])

  return { rentedBuilding, hasContractExpired }
}
