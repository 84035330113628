import { useSelector } from "react-redux"
import * as selectors from "app/redux/me/me.selectors"
import { Modules } from "app/enums/modules"
import { PossibleContactRoles } from "app/enums/roles"
import { useCallback } from "react"
import { useRentedBuilding } from "./rentedBuilding"
import { RentedBuilding } from "app/types/rentedBuilding"

export const permissions = {
  [Modules.DASHBOARD]: [
    PossibleContactRoles.FINANCE,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN
  ],
  [Modules.ENERGY]: [
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN
  ],
  [Modules.INVOICES]: [
    PossibleContactRoles.FINANCE,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.ADMIN
  ],
  [Modules.FACILITY_MANAGEMENT]: [
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.EXTERNAL
  ],
  [Modules.DOCUMENTS]: [
    PossibleContactRoles.FINANCE,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN
  ],
  [Modules.BUILDING]: [
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN
  ],
  [Modules.USER_MANAGEMENT]: [PossibleContactRoles.ADMIN],
  [Modules.CONTRACTS]: [
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.GENERAL_MANAGER
  ],
  [Modules.PLANS]: [
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.GENERAL_MANAGER
  ],
  [Modules.NOTIFICATIONS]: [
    PossibleContactRoles.FINANCE,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.EXTERNAL
  ],
  [Modules.COMMERCIAL]: [
    PossibleContactRoles.FINANCE,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.EXTERNAL
  ],
  [Modules.ACCOUNT]: [
    PossibleContactRoles.FINANCE,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.EXTERNAL
  ],
  [Modules.FAQ]: [
    PossibleContactRoles.FINANCE,
    PossibleContactRoles.GENERAL_MANAGER,
    PossibleContactRoles.FACILITY_MANAGER,
    PossibleContactRoles.ADMIN,
    PossibleContactRoles.EXTERNAL
  ]
}

export const usePermission = () => {
  const { rentedBuilding } = useRentedBuilding()
  const rentedBuildings = useSelector(selectors.getRentedBuildings)
  const groups = useSelector(selectors.getGroups)

  const hasPermission = (
    module: Modules,
    rentedBuildingId: string
  ): boolean => {
    if(groups && groups?.length > 0){
      return true
    }
    const building = rentedBuildings?.find(r => r.id === rentedBuildingId)
    if (!building) return false
    const { roles } = building
    const activeModule = permissions[module]
    if (!activeModule) return true
    if (module === Modules.ENERGY && hasContractExpiredForBuilding(building))
      return false
    return activeModule.some((k: PossibleContactRoles) => roles.includes(k))
  }

  const hasContractExpiredForBuilding = (building: RentedBuilding) => {
    return new Date(building.endDate) < new Date()
  }

  const isExternal = useCallback(() => {
    if (!rentedBuilding) return false
    return (
      rentedBuilding.roles.length === 1 &&
      rentedBuilding.roles.includes(PossibleContactRoles.EXTERNAL)
    )
  }, [rentedBuilding])

  const isFinanceManager = useCallback(() => {
    if (!rentedBuilding) return false
    return (
      rentedBuilding.roles.length === 1 &&
      rentedBuilding.roles.includes(PossibleContactRoles.FINANCE)
    )
  }, [rentedBuilding])
  const isFacilityManager = useCallback(() => {
    if (!rentedBuilding) return false
    return (
      rentedBuilding.roles.length === 1 &&
      rentedBuilding.roles.includes(PossibleContactRoles.FACILITY_MANAGER)
    )
  }, [rentedBuilding])

  const canCreateDocuments = useCallback(() => {
    if (!rentedBuilding) return false
    return (
      rentedBuilding.roles.includes(PossibleContactRoles.FACILITY_MANAGER) ||
      rentedBuilding.roles.includes(PossibleContactRoles.GENERAL_MANAGER) ||
      rentedBuilding.roles.includes(PossibleContactRoles.ADMIN)
    )
  }, [rentedBuilding])

  return {
    hasPermission,
    isExternal,
    isFinanceManager,
    isFacilityManager,
    canCreateDocuments
  }
}
