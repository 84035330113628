import {
  CardActions,
  DocumentIcon,
  theme,
  Link,
  Typography
} from "@paudigital/wdp-components"
import { Invoice, InvoiceStatusTypes } from "app/types/invoice"
import {
  Container,
  CardContent,
  StyledCard,
  InvoiceDate,
  ContractNumber,
  InvoiceDetails,
  InvoiceLinks,
  StyledInvoiceButton,
  StyledInvoiceTitle,
  StyledInvoiceHeader,
  StyledInvoiceStatus,
  StyledInvoiceStatusTitle
} from "./style"
import { format } from "date-fns"
import { useTranslate } from "hooks/translate"
import { useViewport } from "hooks/viewport"
import InvoiceValue from "../../InvoiceValue"
import { downloadDocument } from "app/redux/document/document.actions"
import { useApi } from "hooks/api"
import InvoiceCardMetaTitle from "app/components/InvoiceMetaTitle"
import { addZeroWidthSpaceAfterCharacters } from "app/utils/linebreaks.utils"

type Props = {
  invoice: Invoice
  className?: string
}

const InvoiceCard = ({ invoice, className }: Props) => {
  const { call } = useApi()
  const { width } = useViewport()
  const { translate } = useTranslate()
  const { metadata, invoiceType, status } = invoice
  const value = metadata.Amount_x0020_in_x0020_local_x0020_currency
  const process = metadata.RE_x0020_Process
  const from = metadata.Settlement_x0020_period_x0020_from
  const to = metadata.Settlement_x0020_period_x0020_to

  const handleSupplierDownload = () => {
    if (invoice.purchaseId) {
      call(downloadDocument(invoice.purchaseId))
    }
  }

  const handleDownload = () => {
    if (invoice) {
      call(downloadDocument(invoice.id))
    }
  }

  return (
    <StyledCard className={className}>
      <Container>
        <CardContent>
          {from && to && (
            <InvoiceCardMetaTitle process={process} from={from} to={to} />
          )}
          <StyledInvoiceHeader>
            <Typography fontStyle="bold" variant="h6" as="h4">
              <StyledInvoiceTitle>
                {`${translate(
                  `portal.invoices.invoice.invoicetype.${invoiceType}`
                )} ${
                  metadata.Invoice_x0020_Document_x0020_ID &&
                  addZeroWidthSpaceAfterCharacters(
                    metadata.Invoice_x0020_Document_x0020_ID,
                    ["_"]
                  )
                }`}
              </StyledInvoiceTitle>
            </Typography>
            {status === InvoiceStatusTypes.OPEN ? null : (
              <StyledInvoiceStatus status={status}>
                <StyledInvoiceStatusTitle>
                  {translate(`portal.invoices.status.${status}`)}
                </StyledInvoiceStatusTitle>
              </StyledInvoiceStatus>
            )}
          </StyledInvoiceHeader>
          <InvoiceDetails>
            <InvoiceDate>
              <strong>{translate("portal.invoices.invoiced.label")}:</strong>{" "}
              {metadata.Document_x0020_Date_x0020_in_x0020_Document &&
                format(
                  new Date(
                    metadata.Document_x0020_Date_x0020_in_x0020_Document
                  ),
                  "dd-MM-yyyy"
                )}
            </InvoiceDate>
            <ContractNumber>
              <strong>{translate("portal.invoices.contract.label")}:</strong>{" "}
              {metadata.Contract_x0020_number}
            </ContractNumber>
          </InvoiceDetails>
          <>
            {width < theme.breakpointsValues.tablet && (
              <InvoiceValue
                invoiceType={invoiceType}
                amount={value}
                currency={metadata.Currency_x0020_Key}
                dueDate={metadata.TaskDueDate}
              />
            )}
          </>
          <InvoiceLinks>
            <StyledInvoiceButton
              startIcon={<DocumentIcon fill={theme.colors.green.contrast} />}
              label={translate("portal.invoices.invoice.button.label")}
              type="secondary"
              onClick={handleDownload}
            />
            {invoice.purchaseId && (
              <Link
                type="default"
                value={translate("portal.invoices.supplier.link.label")}
                onClick={handleSupplierDownload}
              />
            )}
          </InvoiceLinks>
        </CardContent>
        <>
          {width >= theme.breakpointsValues.tablet && (
            <CardActions alignment="horizontal">
              <>
                {value && (
                  <InvoiceValue
                    invoiceType={invoiceType}
                    amount={value}
                    currency={metadata.Currency_x0020_Key}
                    dueDate={metadata.TaskDueDate!}
                  />
                )}
              </>
            </CardActions>
          )}
        </>
      </Container>
    </StyledCard>
  )
}

export default InvoiceCard
