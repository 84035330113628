import { Campaign, ModulesEnum } from "app/types/banner"
import State from "../state"

export const isLoading = (state: State): boolean => state.campaign.loading

export const getBannerInfoForModules = (state: State): Campaign[] =>
  state.campaign.data

export const getBannerInfoForModule =
  (module: ModulesEnum) =>
  (state: State): Campaign | undefined => {
    const bannerInfo = getBannerInfoForModules(state)
    return bannerInfo.find(b => b.module === module)
  }
