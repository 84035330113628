import { BaseCard, Button, theme } from "@paudigital/wdp-components"
import { InvoiceStatusTypes } from "app/types/invoice"
import styled from "styled-components"


const InvoiceStatusColors = {
  [InvoiceStatusTypes.OPEN]: "#AFAFAE",
  [InvoiceStatusTypes.OVERDUE]: theme.colors.system.moreInfo,
  [InvoiceStatusTypes.PAID]: "#009760",
}

export const StyledCard = styled(BaseCard)`
  width: 100%;
  background: ${theme.colors.neutral.white};
  margin: 1.2rem 0;
  cursor: default;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const CardContent = styled.div`
  flex: 1;
  margin: 2rem 2.4rem;
`

const BaseSpan = styled.span`
  display: block;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.light};
  line-height: ${theme.lineHeights.captionSmall};

  strong {
    font-weight: ${theme.fontWeights.bold};
  }
`

export const StyledInvoiceTitle = styled.div`
  overflow-wrap: normal;
  overflow: hidden;
`

export const StyledInvoiceHeader = styled.div`
  display: flex;
`

export const StyledInvoiceStatus = styled.div<{
  status: InvoiceStatusTypes
}>`
  margin-left: 0.6rem;
  background-color: ${({ status }) => InvoiceStatusColors[status]};
  color: white;
  padding: 0.2rem 0.5rem 0 0.5rem;
  display: flex;
  border-radius: 0.4rem;
`

export const StyledInvoiceStatusTitle = styled.span`
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.bold};
`

export const InvoiceDate = styled(BaseSpan)`
  margin-right: 1.6rem;
`

export const ContractNumber = styled(BaseSpan)``

export const Reference = styled(BaseSpan)`
  margin-right: 1.6rem;
`

export const InvoiceDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.4rem;
  margin-bottom: 2.4rem;
`
export const InvoiceLinks = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StyledInvoiceButton = styled(Button)`
  width: 100%;
  margin-right: 1.6rem;

  button {
    margin-bottom: 1.6rem;
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    min-width: 17rem;
    width: auto;

    button {
      margin-bottom: 0;
    }
  }
`
