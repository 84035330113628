import { MeterHistoryData } from "app/types/energy"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.waterMeterHistory.loading
export const hasErrors = (state: State): boolean =>
  state.waterMeterHistory.errors ? true : false
export const hasWarning = (state: State): boolean =>
  getWaterMeterHistoryData(state)?.some(h => h.msg) || hasErrors(state)
export const getLastUpdated = (state: State): Date | undefined =>
  state.waterMeterHistory.lastUpdated
export const getWaterMeterHistoryData = (
  state: State
): MeterHistoryData[] | undefined => {
  return state.waterMeterHistory.data
}
