import { Button, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ExtraInformationContainer = styled.form`
  width: 100%;
  margin: 3.2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (${theme.breakpoints.tablet}) {
    min-width: 31rem;
    max-width: 31rem;
    margin: 3.2rem;
    margin-right: 0;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

export const StyledButton = styled(Button)`
  width: 14rem;
`
