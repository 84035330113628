import ListItem from "app/components/ListItem"
import { DocumentCategory } from "app/redux/documentTypes/documentTypes.reducer"

type Props = {
  label: string
  category?: DocumentCategory
  onClick: (category?: DocumentCategory) => void
}

const Category = ({ label, category, onClick }: Props) => {
  const handleClick = () => {
    onClick(category)
  }
  return <ListItem label={label} onClick={handleClick} />
}

export default Category
