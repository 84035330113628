import { CustomerNameTag, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const BuildingWrapper = styled.section`
  margin-bottom: 3.2rem;

  &:last-of-type{
    margin-bottom: 0;
  }
`

export const AddressContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`


export const StyledCustomerNameTag = styled(CustomerNameTag)`
  font-size: ${theme.fontSizes.captionExtraSmall};
  margin-left: 1rem;
`
