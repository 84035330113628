import { AssignedPerson } from "app/types/assignedPerson"
import { InviteContactPerson } from "app/types/contactPerson"

export enum Types {
  CLEAR = "CLEAR_CONTACTPERSON",
  POST = "POST_CONTACTPERSON",
  POST_SUCCESS = "POST_CONTACTPERSON_SUCCESS",
  POST_FAIL = "POST_CONTACTPERSON_FAIL",
  PUT = "PUT_CONTACTPERSON",
  PUT_SUCCESS = "PUT_CONTACTPERSON_SUCCESS",
  PUT_FAIL = "PUT_CONTACTPERSON_FAIL",
  GET = "GET_RENTED_BUILDING_CONTACTPERSON",
  GET_SUCCESS = "GET_RENTED_BUILDING_CONTACTPERSON_SUCCESS",
  GET_FAIL = "GET_RENTED_BUILDING_CONTACTPERSON_FAIL",
  DELETE = "DELETE_RENTED_BUILDING_CONTACTPERSON",
  DELETE_SUCCESS = "DELETE_RENTED_BUILDING_CONTACTPERSON_SUCCESS",
  DELETE_FAIL = "DELETE_RENTED_BUILDING_CONTACTPERSON_FAIL"
}

export type Action =
  | ClearAction
  | PostContactPersonAction
  | PostContactPersonSuccessAction
  | PostContactPersonFailAction
  | PutContactPersonAction
  | PutContactPersonSuccessAction
  | PutContactPersonFailAction
  | GetContactPersonsAction
  | GetContactPersonsSuccessAction
  | GetContactPersonsFailAction
  | DeleteContactPersonsAction
  | DeleteContactPersonsSuccessAction
  | DeleteContactPersonsFailAction

export type ClearAction = {
  type: Types.CLEAR
}

export type GetContactPersonsAction = {
  payload: { rentedBuildingIds: string[] }
  type: Types.GET
}

export type GetContactPersonsSuccessAction = {
  payload: { data: AssignedPerson[] }
  type: Types.GET_SUCCESS
}

export type GetContactPersonsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type PostContactPersonAction = {
  type: Types.POST
  payload: {
    data: InviteContactPerson
  }
}

export type PostContactPersonSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostContactPersonFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.POST_FAIL
}

export type PutContactPersonAction = {
  type: Types.PUT
  payload: {
    data: InviteContactPerson
  }
}

export type PutContactPersonSuccessAction = {
  type: Types.PUT_SUCCESS
}

export type PutContactPersonFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PUT_FAIL
}

export type DeleteContactPersonsAction = {
  payload: { id: string }
  type: Types.DELETE
}

export type DeleteContactPersonsSuccessAction = {
  payload: { id: string }
  type: Types.DELETE_SUCCESS
}

export type DeleteContactPersonsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DELETE_FAIL
}

export function postContactPerson(data: InviteContactPerson): Action {
  return {
    type: Types.POST,
    payload: { data }
  }
}

export function postContactPersonSuccess(): Action {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postContactPersonFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function putContactPerson(data: InviteContactPerson): Action {
  return {
    type: Types.PUT,
    payload: { data }
  }
}

export function putContactPersonSuccess(): Action {
  return {
    type: Types.PUT_SUCCESS
  }
}

export function putContactPersonFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.PUT_FAIL
  }
}

export function getContactPersons(rentedBuildingIds: string[]): Action {
  return {
    type: Types.GET,
    payload: { rentedBuildingIds }
  }
}

export function getContactPersonsSuccess(data: AssignedPerson[]): Action {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getContactPersonsFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function deleteContactPersons(id: string): Action {
  return {
    type: Types.DELETE,
    payload: { id }
  }
}

export function deleteContactPersonsSuccess(id: string): Action {
  return {
    payload: { id },
    type: Types.DELETE_SUCCESS
  }
}

export function deleteContactPersonsFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function clearSubmit(): Action {
  return {
    type: Types.CLEAR
  }
}
