import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import * as meSelectors from "app/redux/me/me.selectors"
import { getEmailOfContactPerson } from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import {
  DeleteModalTextBold,
  DeleteModalTextRegular,
  DeleteModalTitle,
  DeleteModalWrapper,
  FullWidthWrapper,
  IllustrationWrapper
} from "../style"
import { Button, TrashIllustration } from "@paudigital/wdp-components"

type AdminIsCurrentUserProps = {
  onCancel: () => void
}

type Props = AdminIsCurrentUserProps

const AdminIsCurrentUserModal = ({
  onCancel
}: Props) => {
  const { translate } = useTranslate()
  const companyName = useSelector(meSelectors.getUserCompanyName)
  const userEmail = useSelector(getEmailOfContactPerson)

  return (
    <DeleteModalWrapper>
      <IllustrationWrapper>
        <TrashIllustration />
      </IllustrationWrapper>
      <DeleteModalTitle>{translate("portal.admin.delete.seat.admin.isCurrentUser.title")}</DeleteModalTitle>
      <DeleteModalTextBold marginBottom="2.4rem">{translate("portal.admin.delete.seat.admin.isCurrentUser.action")}</DeleteModalTextBold>
      <DeleteModalTextRegular marginBottom="3.2rem">{translate("portal.admin.delete.seat.admin.isCurrentUser.description", { companyName, userEmail })}</DeleteModalTextRegular>
      <FullWidthWrapper>
        <Button type="primary" label={translate("portal.admin.delete.seat.cancel")} onClick={onCancel} fullWidth />
      </FullWidthWrapper>
    </DeleteModalWrapper>
  )
}

export default AdminIsCurrentUserModal
