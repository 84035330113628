import Hero from "./Hero.jpg"
import {
  getEnergyPeriodRange,
  getEnergyPeriod
} from "app/redux/energyPeriod/energyPeriod.selectors"
import EnergyPeriod from "app/components/EnergyPeriod"
import { useSelector } from "react-redux"
import { Periods } from "app/redux/energyPeriod/energyPeriod.reducer"
import { useQueryParams } from "hooks/queryParams"
import * as graphSelectors from "app/redux/graphs/graphs.selectors"
import { useMemo } from "react"
import { ModulesEnum } from "app/types/banner"
import ModuleHeader from "../ModuleHeader"

export type EnergyPeriodQueryParams = {
  start_period: string
  end_period: string
  period: Periods
}

const EnergyPageHeader = () => {
  const energyPeriod = useSelector(getEnergyPeriod)
  const energyPeriodRange = useSelector(getEnergyPeriodRange)
  const rentedBuidingAvailableMeters = useSelector(
    graphSelectors.availableMeters
  )
  const { queryParams, setUrlParams } = useQueryParams<EnergyPeriodQueryParams>(
    {
      start_period: energyPeriodRange.from,
      end_period: energyPeriodRange.to,
      period: energyPeriod
    }
  )

  const hasMeters = useMemo(() => {
    return rentedBuidingAvailableMeters.length > 0
  }, [rentedBuidingAvailableMeters.length])

  const { start_period, end_period, period } =
    queryParams! as EnergyPeriodQueryParams
  return (
    <>
      <ModuleHeader page={"energy"} module={ModulesEnum.ENERGY} Hero={Hero} />
      {hasMeters && (
        <EnergyPeriod
          from={start_period}
          to={end_period}
          period={period}
          setUrlParams={setUrlParams}
        />
      )}
    </>
  )
}

export default EnergyPageHeader
