import {
  BaseCard,
  Button,
  CardActions,
  theme
} from "@paudigital/wdp-components"
import { FacilityTicketStatus } from "app/types/ticket"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)<{ dataTestId?: string }>`
  width: 100%;
  background: ${theme.colors.neutral.white};
  flex-direction: column;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StyledWrapper = styled.div`
  width: 80%;
  margin-top: 1rem;
  margin-left: 2.4rem;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledStatusTitle = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
`

export const StyledButton = styled(Button)`
  height: 100%;
  width: 100%;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};

  button {
    border: 0;
    border-top: 1px solid ${theme.colors.neutral.midGrey};
    border-radius: 0;
    height: 100%;
    width: 100%;

    @media only screen and (${theme.breakpoints.tablet}) {
      border-top: 0;
      border-left: 1px solid ${theme.colors.neutral.midGrey};
    }
  }
`

export const StyledCardActions = styled(CardActions)`
  margin-right: 0;
  min-width: 20%;
`

export const StyledGridStatus = styled.span<{
  status: FacilityTicketStatus
  feedback?: boolean
}>`
  display: flex;

  background-color: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
        return `${theme.colors.system.moreInfo}0A`
      case FacilityTicketStatus.PLANNED:
        return `${theme.colors.system.planned}0A`
      case FacilityTicketStatus.FINISHED:
        return props.feedback
          ? `${theme.colors.system.success}0A`
          : "transparant"
      default:
        return "transparent"
    }
  }};
  color: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
        return `${theme.colors.system.moreInfo}`
      case FacilityTicketStatus.PLANNED:
        return `${theme.colors.system.planned}`
      case FacilityTicketStatus.FINISHED:
        return `${theme.colors.green.contrast}`
      default:
        return theme.colors.neutral.darkerGrey
    }
  }};
  border-radius: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
      case FacilityTicketStatus.PLANNED:
      case FacilityTicketStatus.FINISHED:
        return "44px"
      default:
        return "initial"
    }
  }};
  padding: ${props => {
    switch (props.status) {
      case FacilityTicketStatus.INFO_NEEDED:
      case FacilityTicketStatus.PLANNED:
      case FacilityTicketStatus.FINISHED:
        return "0.4rem 2.4rem 0.4rem 0.4rem"
      default:
        return "initial"
    }
  }};
`

export const StyledStatusInfo = styled.div`
  display: flex;
  margin: 1.6rem 0 2.4rem;
`

export const StyledDot = styled.div<{ fill: string }>`
  background-color: ${props => props.fill};
  min-height: 1.6rem;
  max-height: 1.6rem;
  min-width: 1.6rem;
  max-width: 1.6rem;
  border-radius: 50%;
  margin-right: 1.6rem;
`
export const CancelButton = styled.button`
  display: flex;
  flex-direction: row;
  border: none;
  background: none;
  align-items: center;
  cursor: pointer;
`

export const CancelLabel = styled.label`
  color: ${theme.colors.system.moreInfo};
  text-decoration: underline;
  margin-left: 1.6rem;
  cursor: pointer;
  font-size: ${theme.fontSizes.captionSmall};
  lineheights: ${theme.lineHeights.captionSmall};
`
