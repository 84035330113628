import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { useTranslate } from "hooks/translate"
import { useMemo } from "react"
import { OnChangeValue } from "react-select"
import { Filters } from "routes/FacilityManagement"
import { StyledSort, StyledDropdown, StyledLabel } from "./style"

type Props = {
  setUrlParams: (params: Filters) => void
} & Filters

const Sort = ({
  page,
  perPage,
  sort,
  status,
  type,
  from,
  to,
  ticket,
  description,
  setUrlParams
}: Props) => {
  const { translate } = useTranslate()
  const queryParams = {
    page,
    perPage,
    sort,
    status,
    type,
    from,
    to,
    ticket,
    description
  }
  const filterOptions = [
    { label: "creation_date", value: "creation_date" },
    { label: "status", value: "category" }
  ]
  const sortBy = (queryParams?.sort && queryParams?.sort[0]) || "creation_date"
  const optionTranslation = sortBy.substring(0, sortBy.indexOf("="))

  const handleChange = (optionData: OnChangeValue<CustomOptionType, false>) => {
    const queryString = (optionData as CustomOptionType).value
    setUrlParams({
      ...queryParams,
      sort:
        queryString === "creation_date"
          ? [`${queryString}=desc`]
          : [`${queryString}=asc`]
    })
  }

  const defaultValue = useMemo(() => {
    const filterKey = (optionTranslation && optionTranslation.length > 0) ? optionTranslation : "creation_date"
    return translate(`portal.facilitymanagement.filter.option.${filterKey}`)
  }, [optionTranslation, translate])

  return (
    <StyledSort>
      <StyledLabel>
        {translate(`portal.facilitymanagement.filter.sortBy`)}
      </StyledLabel>
      <StyledDropdown
        onChange={handleChange}
        options={filterOptions.map(o => ({
          value: o.value,
          label: translate(`portal.facilitymanagement.filter.option.${o.value}`)
        }))}
        hasDropdownIndicator
        defaultValue={defaultValue}
      />
    </StyledSort>
  )
}

export default Sort
