import { TicketType } from "app/types/ticketCategory"

export enum Types {
  GET = "TICKETCATEGORIES_GET",
  GET_SUCCESS = "TICKETCATEGORIES_GET_SUCCESS",
  GET_FAIL = "TICKETCATEGORIES_GET_FAIL"
}

export type Action =
  | GetTicketCategoriesAction
  | GetTicketCategoriesSuccessAction
  | GetTicketCategoriesFailAction

export type GetTicketCategoriesAction = {
  type: Types.GET
}

export type GetTicketCategoriesSuccessAction = {
  payload: TicketType[]
  type: Types.GET_SUCCESS
}

export type GetTicketCategoriesFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export function getTicketCategories(): GetTicketCategoriesAction {
  return {
    type: Types.GET
  }
}

export function getTicketCategoriesSuccess(
  payload: TicketType[]
): GetTicketCategoriesSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getTicketCategoriesFail(
  errors: object
): GetTicketCategoriesFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
