import DateRangePicker from "app/components/DateRangePicker"
import ExportFilterTitle from "app/components/DownloadFilters/ExportFilterTitle"
import { useTranslate } from "hooks/translate"
import { UploadDateFilterWrapper, DatePickerWrapper } from "./style"

type Props = {
  from: string | undefined
  to: string | undefined
  onPeriodChange: (date: string, name: string) => void
}

export const PeriodFilter = ({ from, to, onPeriodChange }: Props) => {
  const { translate } = useTranslate()
  return (
    <UploadDateFilterWrapper>
      <ExportFilterTitle>
        {translate("portal.energy.export.overview.filters.period.title")}
      </ExportFilterTitle>
      <DatePickerWrapper>
        <DateRangePicker
          id="energyDateRange"
          to={to}
          from={from}
          updateDate={onPeriodChange}
          orientation="row"
        />
      </DatePickerWrapper>
    </UploadDateFilterWrapper>
  )
}

export default PeriodFilter
