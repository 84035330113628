import { Page, Typography, NotFound } from "@paudigital/wdp-components"
import { useLogout } from "hooks/logout"
import { useTranslate } from "hooks/translate"
import { StyledLink, StyledSpan, Container } from "./style"

const NoAccess = () => {
  const { translate } = useTranslate()
  const { logout } = useLogout()

  const handleClick = () => {
    logout()
  }

  return (
    <Page image={<NotFound />}>
      <Container>
        <Typography variant="h5" as="h1" fontStyle="bold" textAlign="center">
          {translate("portal.noaccess.title")}
        </Typography>
        <StyledSpan>{translate("portal.noaccess.description")}</StyledSpan>
        <StyledLink
          onClick={handleClick}
          type="default"
          value={translate("portal.noaccess.logout.label")}
        />
      </Container>
    </Page>
  )
}

export default NoAccess
