export enum Types {
  PATCH = "PATCH_PREFEREDBUILDINGS",
  PATCH_SUCCESS = "PATCH_PREFEREDBUILDINGS_SUCCESS",
  PATCH_FAIL = "PATCH_PREFEREDBUILDINGS_FAIL"
}

export type Action =
  | PatchPreferedBuildingsAction
  | PatchPreferedBuildingsSuccessAction
  | PatchPreferedBuildingsFailAction

export type PatchPreferedBuildingsAction = {
  payload: { preferredBuildingId: string }
  type: Types.PATCH
}

export type PatchPreferedBuildingsSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchPreferedBuildingsFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.PATCH_FAIL
}

export function patchPreferedBuilding(preferredBuildingId: string): Action {
  return {
    type: Types.PATCH,
    payload: { preferredBuildingId }
  }
}

export function patchPreferedBuildingSuccess(): Action {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchPreferedBuildingFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}
