import { theme } from "@paudigital/wdp-components"
import { intl, Locales } from "app/i18n/config"
import { parse } from "date-fns"
import { format } from "date-fns-tz"
import { getLocaleNumberFormatter } from "../localeNumberFormatter.utils"
import { generateTooltipLabel } from "./baseGraphOptions"

export const getSolarProductionChartOptions = (language: Locales) => {
  return {
    yAxis: {
      title: {
        text: intl[language].formatMessage({
          id: "portal.energy.graphs.solar.production.title"
        })
      },
      labels: {
        formatter: function () {
          const self: any = this
          const { decimalFormatter } = getLocaleNumberFormatter()
          return `<span style="display: block; text-align: center; color: ${theme.colors.neutral.darkGrey
            }; line-height: 14px;">${decimalFormatter(self.value, 0)} kWh</span>`
        },
        useHTML: true
      },
      plotLines: []
    },
    xAxis: {
      startOnTick: true,
      endOnTick: true,
      width: "100%",
      maxPadding: 0,
      minPadding: 0,
      tickInterval: 3600,
      labels: {
        formatter: function () {
          const self: any = this
          const formattedDate = format(
            parse(self.value, "t", new Date()),
            "hh:mm yyyy-MM-dd"
          )
          const [time, date] = formattedDate.split(" ")
          return `<span style="display: block; text-align: center; color: ${theme.colors.neutral.darkGrey}; line-height: 14px;">${time}<br/>${date}</span>`
        },
        useHTML: true
      }
    },
    tooltip: {
      shared: true,
      backgroundColor: theme.colors.neutral.white,
      borderColor: theme.colors.neutral.darkerGrey,
      style: {
        fontSize: "12px"
      },
      formatter: function () {
        const self: any = this
        return self.points
          ? self.points.map(
            (point: {
              x: string
              color: any
              series: { name: any }
              y: any
            }) =>
              generateTooltipLabel(point, "kWh", language)
          )
          : []
      },
      useHTML: true
    },
    plotOptions: {
      series: {
        stacking: "overlap"
      }
    },
    series: []
  }
}

export const solarProductionAreaSplineSerieOptions = [
  {
    data: [],
    type: "areaspline",
    name: "solarUnderproduction",
    color: theme.colors.graphs.wintergreenDream,
    lineColor: theme.colors.graphs.wintergreenDream,
    fillColor: theme.colors.graphs.wintergreenDream,
    marker: {
      enabled: false
    },
    states: {
      inactive: {
        enabled: false
      }
    },
    opacity: 1,
    stack: 1
  },
  {
    data: [],
    type: "areaspline",
    name: "solarOverproduction",
    color: theme.colors.graphs.peachPuff,
    lineColor: theme.colors.graphs.peachPuff,
    fillColor: theme.colors.graphs.peachPuff,
    marker: {
      enabled: false
    },
    states: {
      inactive: {
        enabled: false
      }
    },
    opacity: 1,
    stack: 0
  }
]

export const solarProductionStackedBarSerieOptions = [
  {
    data: [],
    name: "solarUnderproduction",
    type: "column",
    stacking: "normal",
    pointPadding: 0.1,
    groupPadding: 0,
    borderWidth: 0,
    color: theme.colors.graphs.wintergreenDream,
    index: 3,
    states: {
      inactive: {
        enabled: false
      }
    },
    stack: 1,
    grouping: false
  },
  {
    data: [],
    name: "solarOverproduction",
    type: "column",
    stacking: "normal",
    pointPadding: 0.1,
    groupPadding: 0,
    borderWidth: 0,
    color: theme.colors.graphs.peachPuff,
    index: 1,
    states: {
      inactive: {
        enabled: false
      }
    },
    stack: 0,
    grouping: false
  }
]
