import { Periods } from "app/redux/energyPeriod/energyPeriod.reducer"
import { isEnergyPeriodCustom, Period } from "app/utils/energy.utils"

export const convertToTons = (val: number, precision = 2) =>
  Number.parseFloat((val / 1000).toFixed(precision))

export const unitMapping: Record<string, string> = {
  electricityConsumption: "kWh",
  waterConsumption: "L",
  gasConsumption: "m³",
  co2Emission: "ton"
}

export const compareBaseText = "portal.energy.comparison.days.compared."

const periodText = (period: Periods, amount?: number) =>
  isEnergyPeriodCustom(period)
    ? amount && amount === 1
      ? "day"
      : "days"
    : period

export const periodTranslation = (
  period: Periods,
  percentageDifference?: number,
  amount?: number
) => (percentageDifference ? periodText(period, amount) : "unavailable")

export const convertPeriod = (period: Periods) =>
  (isEnergyPeriodCustom(period) ? Periods.custom : period) as Period

export const getComparisonText = (
  period: Periods,
  percentageDifference?: number,
  amount?: number
) => ({
  text: `${compareBaseText}${periodTranslation(
    period,
    percentageDifference,
    amount
  )}`,
  value: { amount }
})

export const createArrow = (difference?: number) => {
  if (!difference) return
  if (difference > 0) return "\u25B2"
  if (difference < 0) return "\u25BC"
}
