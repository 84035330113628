const Icon = () => {
  return (
    <svg
      width="130"
      height="75"
      viewBox="0 0 165 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="inherit">
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M165 0H98.4512V65.1815H165V0Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <path
          d="M102.785 0.751511H160.666C162.737 0.751511 164.416 2.43044 164.416 4.50151V60.68C164.416 62.7511 162.737 64.43 160.666 64.43H102.785C100.714 64.43 99.0351 62.7511 99.0351 60.68V4.50151C99.0351 2.43044 100.714 0.751511 102.785 0.751511Z"
          stroke="#555555"
          strokeWidth="0.5"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M157.977 5.83052H105.474V46.5711H157.977V5.83052Z"
            fill="#CBAC92"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M141.67 33.8857C141.642 32.2786 140.985 30.7466 139.84 29.6176C138.809 28.6054 137.423 27.4439 135.921 26.7412H135.912C135.276 26.4316 134.594 26.2258 133.893 26.1315V23.6377C134.405 23.1789 134.828 22.6296 135.142 22.0173V22.217C135.142 22.217 137.534 19.8009 137.479 16.9657C137.515 15.263 136.696 13.3897 133.949 11.783C127.571 8.06367 125.128 13.472 124.663 14.7234C124.633 14.784 124.611 14.848 124.596 14.914L124.578 14.9719C124.57 15.0306 124.57 15.09 124.578 15.1487V15.167C124.576 15.1985 124.576 15.23 124.578 15.2615C124.578 15.6792 124.933 16.0633 125.535 16.3773C125.062 17.0839 124.756 17.8894 124.642 18.7322C124.528 19.5749 124.608 20.4327 124.877 21.2397C125.145 22.0467 125.595 22.7816 126.191 23.3883C126.787 23.9949 127.515 24.4572 128.317 24.7397V26.3403L128.194 26.1071C128.194 26.1071 127.341 26.6177 126.242 27.4012C124.399 28.7136 121.862 30.7928 121.48 32.5107C120.87 35.2545 121.48 46.8668 121.48 46.8668L141.925 47.1717L141.67 33.8857ZM135.172 21.9975L135.18 21.9899L135.172 21.9975ZM124.631 15.3484V15.3575V15.3484ZM124.669 15.4383V15.4505V15.4383ZM124.718 15.5267L124.727 15.542L124.718 15.5267ZM124.778 15.6136L124.79 15.6304L124.778 15.6136ZM124.848 15.699L124.864 15.7173L124.848 15.699ZM124.928 15.7828L124.948 15.8027L124.928 15.7828ZM125.621 16.2401L125.642 16.2508L125.621 16.2401ZM125.02 15.8606L125.044 15.8819L125.02 15.8606ZM125.121 15.9414L125.15 15.9627L125.121 15.9414ZM125.232 16.0191L125.266 16.042L125.232 16.0191ZM125.352 16.0953L125.392 16.1182L125.352 16.0953ZM125.482 16.1685L125.526 16.1929L125.482 16.1685Z"
            fill="url(#paint1_linear)"
          />
        </g>
        <path
          d="M133.833 23.7139H128.405V31.6708H133.833V23.7139Z"
          fill="#FDA57D"
        />
        <path
          opacity="0.05"
          d="M128.585 24.9791C129.153 25.1803 129.397 25.3403 130.033 25.3403C131.37 25.3405 132.659 24.8452 133.652 23.9501V23.8937H128.585V24.9791Z"
          fill="black"
        />
        <path
          d="M130.214 25.1605C133.212 25.1605 135.642 22.7316 135.642 19.7354C135.642 16.7392 133.212 14.3103 130.214 14.3103C127.217 14.3103 124.787 16.7392 124.787 19.7354C124.787 22.7316 127.217 25.1605 130.214 25.1605Z"
          fill="#FDA57D"
        />
        <path
          d="M129.616 17.2904C132.295 17.2904 134.467 16.4653 134.467 15.4475C134.467 14.4297 132.295 13.6046 129.616 13.6046C126.937 13.6046 124.765 14.4297 124.765 15.4475C124.765 16.4653 126.937 17.2904 129.616 17.2904Z"
          fill="#9C9C9C"
        />
        <path
          d="M130.586 16.1273L135.048 18.8421V22.1956C135.048 22.1956 140.869 16.3148 133.885 12.2434C126.9 8.17189 124.765 15.3469 124.765 15.3469C124.765 15.3469 126.705 16.9032 130.586 16.1273Z"
          fill="#A8A8A8"
        />
        <path
          d="M135.255 20.6393C135.554 20.6393 135.797 20.2346 135.797 19.7354C135.797 19.2362 135.554 18.8315 135.255 18.8315C134.955 18.8315 134.712 19.2362 134.712 19.7354C134.712 20.2346 134.955 20.6393 135.255 20.6393Z"
          fill="#FDA57D"
        />
        <g opacity="0.05">
          <path
            d="M135.048 18.8421L132.078 17.0358C131.271 17.2093 130.448 17.2947 129.622 17.2904C127.015 17.2904 124.895 16.5084 124.777 15.5283V15.545C124.776 15.5755 124.776 15.606 124.777 15.6365C124.777 16.6547 126.949 17.4794 129.629 17.4794C130.456 17.4842 131.282 17.3989 132.091 17.2248L135.061 19.0312L135.048 18.8421Z"
            fill="#A8A8A8"
          />
          <path
            opacity="0.05"
            d="M135.049 22.2017V22.3953C135.049 22.3953 137.374 20.0448 137.321 17.2858C137.266 19.9594 135.049 22.2017 135.049 22.2017Z"
            fill="black"
          />
        </g>
        <path
          d="M128.284 26.1802C128.284 26.1802 122.345 29.741 121.752 32.4117C121.159 35.0823 121.752 46.3805 121.752 46.3805L141.644 46.6778L141.391 33.7485C141.363 32.184 140.722 30.693 139.607 29.5947C138.122 28.1359 135.875 26.354 133.627 26.1802L130.956 31.2257L128.284 26.1802Z"
          fill="#1B3C73"
        />
        <path
          opacity="0.05"
          d="M125.542 32.2241C125.542 32.2241 123.989 40.3686 125.348 42.8944L125.542 32.2241Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M136.79 32.2241C136.79 32.2241 138.343 40.3686 136.984 42.8944L136.79 32.2241Z"
          fill="black"
        />
        <path
          d="M128.258 26.2107L133.896 26.1314L130.199 35.5197L128.258 26.2107Z"
          fill="white"
        />
        <path
          d="M128.258 25.6299V26.2107H133.885L133.896 25.6299H128.258Z"
          fill="white"
        />
        <path
          d="M131.363 25.6299H130.586V26.4058H131.363V25.6299Z"
          fill="black"
        />
        <path
          d="M128.258 26.2107L127.094 27.3753L129.616 31.6708L128.258 26.2107Z"
          fill="#4D8AF0"
        />
        <path
          d="M133.69 26.5994L134.661 27.5689L131.75 31.4468L133.69 26.5994Z"
          fill="#4D8AF0"
        />
        <path
          d="M128.258 26.2107L127.094 27.3753L129.616 31.6708L128.258 26.2107Z"
          fill="#0D2040"
        />
        <path
          d="M133.69 26.5994L134.661 27.5689L131.75 31.4468L133.69 26.5994Z"
          fill="#0D2040"
        />
        <path
          d="M130.974 26.0171L127.87 39.0958L129.81 42.0042L132.332 39.0958L130.974 26.0171Z"
          fill="black"
        />
        <path
          d="M154.478 52.2934H110.251C109.662 52.2934 109.184 52.7711 109.184 53.3604C109.184 53.9497 109.662 54.4274 110.251 54.4274H154.478C155.067 54.4274 155.545 53.9497 155.545 53.3604C155.545 52.7711 155.067 52.2934 154.478 52.2934Z"
          fill="#CCCCCC"
        />
        <path
          d="M147.081 56.5614H117.647C117.058 56.5614 116.58 57.0392 116.58 57.6285C116.58 58.2178 117.058 58.6955 117.647 58.6955H147.081C147.671 58.6955 148.148 58.2178 148.148 57.6285C148.148 57.0392 147.671 56.5614 147.081 56.5614Z"
          fill="#CCCCCC"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M66.549 17.4565H0V82.638H66.549V17.4565Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <path
          d="M4.33411 18.208H62.2152C64.2862 18.208 65.9652 19.887 65.9652 21.958V78.1365C65.9652 80.2076 64.2862 81.8865 62.2152 81.8865H4.33411C2.26304 81.8865 0.584106 80.2076 0.584106 78.1365V21.958C0.584106 19.887 2.26304 18.208 4.33411 18.208Z"
          stroke="#555555"
          strokeWidth="0.5"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M59.5264 23.287H7.02289V64.0276H59.5264V23.287Z"
            fill="#CBAC92"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M45.4685 56.4228H46.3164L46.0511 53.7095H46.0221C46.1837 52.051 46.4262 44.7022 38.522 41.774L37.8159 41.3091L37.7626 41.3564V39.3732C38.2842 38.9093 38.7159 38.3533 39.036 37.733V37.9358C39.7313 37.1862 40.3074 36.3344 40.744 35.41C40.3345 36.1793 39.8259 36.8916 39.2312 37.5288V37.5105C40.2239 36.3779 44.2836 31.1205 37.8144 27.3478C30.5278 23.0996 29.1523 30.2273 29.1523 30.2273L29.7333 31.0047C29.7333 31.0047 28.3227 33.8277 28.3227 35.16C28.323 36.3301 28.6862 37.4714 29.3621 38.4268C30.038 39.3822 30.9935 40.1048 32.0971 40.4951V42.1871C31.571 42.3792 24.2585 45.2068 24.2585 53.7079L23.2397 56.4212L23.5798 56.5737V64.1358H40.2026C40.2026 64.1358 41.2213 56.0798 40.8812 53.3665L41.9 54.0448V56.4197H42.1257V56.4792H42.0601L42.1257 56.6987V63.9666H45.4624V56.7017L45.5005 56.4822H45.4624L45.4685 56.4228ZM26.6101 57.0447L26.6345 56.4228L26.6101 57.0447Z"
            fill="url(#paint3_linear)"
          />
        </g>
        <path
          d="M37.6376 39.5607H32.2101V47.5176H37.6376V39.5607Z"
          fill="#FDA57D"
        />
        <path
          opacity="0.05"
          d="M32.3916 40.8259C32.9589 41.0271 33.2029 41.1872 33.8389 41.1872C35.1757 41.1875 36.4652 40.6922 37.4577 39.797V39.7406H32.3916V40.8259Z"
          fill="black"
        />
        <path
          d="M34.0201 41.0073C37.0177 41.0073 39.4477 38.5784 39.4477 35.5822C39.4477 32.5861 37.0177 30.1572 34.0201 30.1572C31.0225 30.1572 28.5925 32.5861 28.5925 35.5822C28.5925 38.5784 31.0225 41.0073 34.0201 41.0073Z"
          fill="#FDA57D"
        />
        <path
          d="M34.3907 32.9436L38.8529 34.689V38.0425C38.8529 38.0425 44.6785 32.1693 37.6893 28.0932C31.4092 24.4349 30.2716 28.8417 29.4816 29.6892C29.4632 29.7089 29.4489 29.732 29.4394 29.7572C29.43 29.7824 29.4257 29.8093 29.4267 29.8362C29.4276 29.8631 29.434 29.8896 29.4452 29.9141C29.4565 29.9385 29.4725 29.9605 29.4923 29.9788C30.2685 30.709 30.9319 33.6357 34.3907 32.9436Z"
          fill="#4D4545"
        />
        <path
          d="M45.0248 55.9624H41.8268V63.927H45.0248V55.9624Z"
          fill="#FDA57D"
        />
        <path
          d="M41.6102 53.6866V55.9624H45.8376L45.5799 53.3619L41.6102 53.6866Z"
          fill="white"
        />
        <path
          d="M24.0465 55.9624V64.0901H26.6482L26.9502 56.5584L24.0465 55.9624Z"
          fill="#FDA57D"
        />
        <path
          d="M34.1284 44.5849L32.2404 42.3091C32.2404 42.3091 24.7007 44.9095 24.7007 53.3619L26.9775 55.9624L26.6527 64.0901H39.9875C39.9875 64.0901 40.9636 55.6377 40.6387 53.0372L41.6147 53.6881H45.5173C45.5173 53.6881 47.0179 44.5849 37.5871 41.6597L34.1284 44.5849Z"
          fill="#235067"
        />
        <path
          d="M24.6964 53.3619L23.7219 55.9624L26.9153 57.4258L26.9733 55.9624L24.6964 53.3619Z"
          fill="white"
        />
        <path
          opacity="0.05"
          d="M30.9 43.2206L32.1779 46.0787L34.0034 44.5788L32.2405 42.5027L30.9 43.2206Z"
          fill="black"
        />
        <path
          d="M30.9 43.027L32.1779 45.8836L34.0034 44.3852L32.2405 42.3091L30.9 43.027Z"
          fill="white"
        />
        <path
          opacity="0.05"
          d="M37.6894 41.6704L38.853 42.4462L35.5559 45.5482L34.4014 44.5468L37.6894 41.6704Z"
          fill="black"
        />
        <path
          d="M37.6894 41.4768L38.853 42.2512L35.5559 45.3547L34.4014 44.3532L37.6894 41.4768Z"
          fill="white"
        />
        <path
          opacity="0.05"
          d="M27.2125 55.2444C27.209 52.7262 28.0265 50.2754 29.5413 48.2631L27.2125 55.2444Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M40.2118 53.3055V52.141L41.3754 52.9168L40.5991 52.7232L40.2118 53.3055Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M41.7644 56.0203L41.8269 56.2291H45.0249L45.0615 56.0203H41.7644Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M24.0465 56.1118V56.3343L26.8983 57.5584L26.8251 57.377L24.0465 56.1118Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M40.497 35.8231C40.1049 36.5602 39.6178 37.2429 39.0483 37.8535V34.4954L34.586 32.7501C31.2417 33.4192 30.714 30.7303 29.7761 29.8691C29.736 29.8325 29.683 29.8133 29.6287 29.8155C29.5745 29.8178 29.5233 29.8415 29.4864 29.8813C29.468 29.901 29.4536 29.9241 29.4442 29.9493C29.4348 29.9745 29.4304 30.0014 29.4314 30.0283C29.4324 30.0552 29.4387 30.0817 29.45 30.1062C29.4612 30.1306 29.4772 30.1526 29.497 30.1709C30.2748 30.8995 30.9367 33.8293 34.3969 33.1342L38.8592 34.8795V38.233C39.525 37.518 40.0774 36.7053 40.497 35.8231Z"
          fill="black"
        />
        <path
          d="M39.0588 36.4862C39.3587 36.4862 39.6017 36.0815 39.6017 35.5822C39.6017 35.083 39.3587 34.6783 39.0588 34.6783C38.759 34.6783 38.5159 35.083 38.5159 35.5822C38.5159 36.0815 38.759 36.4862 39.0588 36.4862Z"
          fill="#FDA57D"
        />
        <path
          d="M54.2836 68.9084H10.057C9.46765 68.9084 8.98993 69.3862 8.98993 69.9755C8.98993 70.5648 9.46765 71.0425 10.057 71.0425H54.2836C54.8729 71.0425 55.3507 70.5648 55.3507 69.9755C55.3507 69.3862 54.8729 68.9084 54.2836 68.9084Z"
          fill="#CCCCCC"
        />
        <path
          d="M46.8872 73.1766H17.4532C16.8639 73.1766 16.3862 73.6543 16.3862 74.2436C16.3862 74.8329 16.8639 75.3106 17.4532 75.3106H46.8872C47.4765 75.3106 47.9542 74.8329 47.9542 74.2436C47.9542 73.6543 47.4765 73.1766 46.8872 73.1766Z"
          fill="#CCCCCC"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M116.105 35.8185H49.5555V101H116.105V35.8185Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <path
          d="M53.8896 36.57H111.771C113.842 36.57 115.521 38.2489 115.521 40.32V96.4985C115.521 98.5695 113.842 100.248 111.771 100.248H53.8896C51.8185 100.248 50.1396 98.5695 50.1396 96.4985V40.32C50.1396 38.2489 51.8185 36.57 53.8896 36.57Z"
          stroke="#555555"
          strokeWidth="0.5"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M109.082 41.6505H56.5784V82.3911H109.082V41.6505Z"
            fill="#CBAC92"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M87.4571 67.0823L87.6096 66.968L87.8276 66.8064L98.0087 59.2473L94.5088 45.2556C94.5088 45.2556 94.5088 45.2388 94.5088 45.2098C94.5378 44.783 94.7009 41.4798 92.9151 42.0758C91.1705 42.6566 91.8171 45.097 91.9422 45.5117L91.962 45.5726L94.1885 57.3389L87.1124 61.4637L86.1303 61.3539C87.2495 59.8194 87.8437 57.965 87.8246 56.0661C87.8246 51.676 84.834 48.1167 81.1435 48.1167C77.4529 48.1167 74.4623 51.676 74.4623 56.0661C74.4623 59.354 76.1399 62.1771 78.5326 63.3828L75.9996 64.6495V64.6632C75.2736 64.5108 72.5195 64.5565 73.6358 74.6597C73.9098 77.1073 73.3272 79.5743 71.9872 81.6411L71.2888 82.6121L88.7274 82.7188H88.7808C88.7808 82.7188 86.3118 78.7632 87.4571 67.0823Z"
            fill="url(#paint5_linear)"
          />
        </g>
        <path
          d="M76.3076 64.7333C76.3076 64.7333 72.7085 63.1801 73.7578 73.8549C74.0427 76.7008 73.3505 79.559 71.795 81.9597L71.5739 82.2996H74.9198L77.4392 79.632L76.3076 64.7333Z"
          fill="#2F2E41"
        />
        <path
          d="M94.2802 45.6565C94.2802 45.6565 94.2802 45.6412 94.2802 45.6123L91.7639 45.908L91.7822 45.9689L93.9645 57.502L86.4797 61.8661L86.7847 67.4771L97.6993 59.3723L94.2802 45.6565Z"
          fill="#2F2E41"
        />
        <path
          d="M81.1832 64.0459C84.7998 64.0459 87.7317 60.5571 87.7317 56.2535C87.7317 51.95 84.7998 48.4612 81.1832 48.4612C77.5666 48.4612 74.6348 51.95 74.6348 56.2535C74.6348 60.5571 77.5666 64.0459 81.1832 64.0459Z"
          fill="#8C672F"
        />
        <path
          d="M84.4573 58.9028H79.78V65.7607H84.4573V58.9028Z"
          fill="#FDA57D"
        />
        <path
          d="M81.3731 64.4267L80.1256 62.5563L76.3832 64.4267V67.521C76.3832 67.521 75.0244 69.9142 76.1316 72.6702C75.8266 76.7219 75 82.0326 75 82.0326L88.864 82.1347C88.864 82.1347 86.3691 78.1364 87.6165 66.2924L87.3115 61.3049L84.5055 61L81.3731 64.4267Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.05"
          d="M87.1079 61.5536L87.4205 61.8646L87.7316 66.7805L87.3687 67.0503L87.1079 61.5536Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M79.9356 59.9942C80.4236 60.1665 80.6356 60.2991 81.1831 60.2991C82.3352 60.2993 83.4464 59.8724 84.3018 59.101V59.0522H79.9356V59.9942Z"
          fill="black"
        />
        <path
          d="M81.3387 60.1497C83.9219 60.1497 86.0159 58.0566 86.0159 55.4746C86.0159 52.8926 83.9219 50.7995 81.3387 50.7995C78.7555 50.7995 76.6614 52.8926 76.6614 55.4746C76.6614 58.0566 78.7555 60.1497 81.3387 60.1497Z"
          fill="#FDA57D"
        />
        <path
          d="M86.0176 56.2535C86.2762 56.2535 86.4858 55.9048 86.4858 55.4746C86.4858 55.0444 86.2762 54.6957 86.0176 54.6957C85.7591 54.6957 85.5494 55.0444 85.5494 55.4746C85.5494 55.9048 85.7591 56.2535 86.0176 56.2535Z"
          fill="#FDA57D"
        />
        <path
          opacity="0.05"
          d="M77.2548 54.0113V54.2308H85.284V54.4503H77.145L77.2548 54.0113Z"
          fill="black"
        />
        <path
          d="M85.2383 50.3316H77.1298V54.3832H85.2383V50.3316Z"
          fill="#8C672F"
        />
        <path
          d="M92.7169 42.5392C91.0073 43.1093 91.6402 45.501 91.7622 45.908L94.2785 45.6123C94.312 45.1931 94.4722 41.9554 92.7169 42.5392Z"
          fill="#FDA57D"
        />
        <g opacity="0.05">
          <path
            opacity="0.05"
            d="M75.0862 82.2828C75.0862 82.2828 75.9066 76.9721 76.2177 72.9205C75.1182 70.1767 76.4755 67.7713 76.4755 67.7713V64.6769L79.9663 62.9316L79.8824 62.8066L76.1415 64.6769V67.7713C76.1415 67.7713 74.7842 70.1721 75.8838 72.9205C75.5788 76.9721 74.7522 82.2828 74.7522 82.2828L88.6162 82.385L75.0862 82.2828Z"
            fill="black"
          />
          <path
            opacity="0.05"
            d="M81.4654 64.6693L84.5536 61.2747L84.2501 61.2411L81.3236 64.4575L81.4654 64.6693Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.05"
          d="M78.7615 63.5627L81.1573 69.0777L85.5494 61.546L84.3354 61.3905L81.325 64.398L79.9876 62.895L78.7615 63.5627Z"
          fill="black"
        />
        <path
          d="M78.7615 63.395L81.1573 68.9115L85.5494 61.3798L84.3354 61.2228L81.325 64.2318L79.9876 62.7273L78.7615 63.395Z"
          fill="white"
        />
        <path
          d="M105.067 86.743H60.8402C60.2509 86.743 59.7732 87.2207 59.7732 87.81C59.7732 88.3993 60.2509 88.877 60.8402 88.877H105.067C105.656 88.877 106.134 88.3993 106.134 87.81C106.134 87.2207 105.656 86.743 105.067 86.743Z"
          fill="#CCCCCC"
        />
        <path
          d="M97.6706 91.0111H68.2366C67.6473 91.0111 67.1696 91.4888 67.1696 92.0781C67.1696 92.6674 67.6473 93.1451 68.2366 93.1451H97.6706C98.2599 93.1451 98.7376 92.6674 98.7376 92.0781C98.7376 91.4888 98.2599 91.0111 97.6706 91.0111Z"
          fill="#CCCCCC"
        />
        <circle cx="84" cy="65" r="29.5" stroke="#EE730A" strokeWidth="5" />
        <rect
          x="112.513"
          y="74.5186"
          width="48"
          height="7"
          transform="rotate(24.0624 112.513 74.5186)"
          fill="#EE730A"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="131.726"
          y1="65.1815"
          x2="131.726"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="17808.8"
          y1="11301.8"
          x2="20622.8"
          y2="11301.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="14520.3"
          y1="35354.3"
          x2="14520.3"
          y2="7482.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="4902.34"
          y1="15829.7"
          x2="8394.07"
          y2="15829.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="36195"
          y1="43224.4"
          x2="36195"
          y2="15352.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="14139.6"
          y1="21532"
          x2="18820.7"
          y2="21532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="165" height="101" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon
