import {
  Button,
  InformationSection,
  InformationSectionContent,
  InformationSectionHeader
} from "@paudigital/wdp-components"
import InformationSectionTitle from "app/components/InformationSectionTitle"
import { useTranslate } from "hooks/translate"
import * as selectors from "app/redux/buildingSpecifications/buildingSpecifications.selectors"
import { useSelector } from "react-redux"
import SpecificationItem from "app/components/SpecificationItem"
import { MeasurementTypes } from "app/types/buildingSpecification"
import { useNavigate } from "react-router-dom"
import { getLocaleNumberFormatter } from "app/utils/localeNumberFormatter.utils"

const BuildingSpecification = () => {
  const navigate = useNavigate()
  const { translate } = useTranslate()
  const { decimalFormatter } = getLocaleNumberFormatter()
  const measurements = useSelector(selectors.getBuildingMeasurements)

  if (!measurements) return null

  const solarPowerMeasurements = measurements.filter(
    r => r.type === MeasurementTypes.Z203 && r.value !== 0
  )
  const solarPowerValues = solarPowerMeasurements.map(m => m.value)
  const sortedSolarPowerValues = solarPowerValues.sort(
    (a, b) => Number(b) - Number(a)
  )

  if (sortedSolarPowerValues.length === 0) return null

  const handleEnergyClick = () => {
    navigate("/energy")
  }
  return (
    <>
      <InformationSection>
        <InformationSectionHeader>
          <InformationSectionTitle>
            {translate("portal.buildingpassport.energy.title")}
          </InformationSectionTitle>
        </InformationSectionHeader>
        <InformationSectionContent>
          <SpecificationItem label="portal.buildingpassport.specification.Z203">
            <>
              {sortedSolarPowerValues.map(
                (value, i: number) =>
                  `${translate(
                    "portal.buildingpassport.specification.Z203.value",
                    {
                      value: decimalFormatter(value, 0)
                    }
                  )}${i !== sortedSolarPowerValues.length - 1 ? ", " : ""}`
              )}
            </>
          </SpecificationItem>
        </InformationSectionContent>
      </InformationSection>
      <Button
        label={translate("portal.buildingpassport.button.energy")}
        type="secondary"
        fullWidth
        onClick={handleEnergyClick}
      />
    </>
  )
}

export default BuildingSpecification
