import {
  GetInvoiceCSVParams,
  Invoice,
  InvoiceResponse,
  InvoiceStatusTypes,
  InvoiceType
} from "app/types/invoice"

export enum Types {
  GET = "GET_INVOICES",
  GET_SUCCESS = "GET_INVOICES_SUCCESS",
  GET_FAIL = "GET_INVOICES_FAIL",
  GET_SUMMARY = "GET_INVOICES_SUMMARY",
  GET_SUMMARY_SUCCESS = "GET_INVOICES_SUMMARY_SUCCESS",
  GET_SUMMARY_FAIL = "GET_INVOICES_SUMMARY_FAIL",
  GET_SELECTED_INVOICES_FILTERS = "GET_SELECTED_INVOICES_FILTERS",
  DOWNLOAD_CSV = "DOWNLOAD_INVOICE_CSV",
  DOWNLOAD_CSV_SUCCESS = "DOWNLOAD_INVOICE_CSV_SUCCESS",
  DOWNLOAD_CSV_FAIL = "DOWNLOAD_INVOICE_CSV_FAIL"
}

export type QueryParams = {
  rentedBuildingId: string
  page: number
  perPage: number
  invoiceType?: InvoiceType[]
  contractNumber?: string[]
  invoicePeriodTo?: string
  invoicePeriodFrom?: string
  invoiceDateTo?: string
  invoiceDateFrom?: string
  invoiceNumber?: string
  reference?: string
  sort?: string[]
  status?: InvoiceStatusTypes[]
}

export type GetInvoicesParams = {
  queryParams: QueryParams
  summary?: Invoice[]
}

export type Action =
  | GetInvoicesAction
  | GetInvoicesSuccessAction
  | GetInvoicesFailAction
  | GetInvoicesSummaryAction
  | GetInvoicesSummarySuccessAction
  | GetInvoicesSummaryFailAction
  | GetSelectedInvoicesFiltersAction
  | DownloadInvoiceCSVAction
  | DownloadInvoiceCSVSuccessAction
  | DownloadInvoiceCSVFailAction

export type GetInvoicesAction = {
  payload: QueryParams
  type: Types.GET
}

export type GetInvoicesSuccessAction = {
  payload: { data: InvoiceResponse }
  type: Types.GET_SUCCESS
}

export type GetInvoicesFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_FAIL
}

export type GetInvoicesSummaryAction = {
  payload: {
    rentedBuildingId: string
  }
  type: Types.GET_SUMMARY
}

export type GetInvoicesSummarySuccessAction = {
  payload: { data: Invoice[] }
  type: Types.GET_SUMMARY_SUCCESS
}

export type GetInvoicesSummaryFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.GET_SUMMARY_FAIL
}

export type DownloadInvoiceCSVAction = {
  payload: { data: GetInvoiceCSVParams }
  type: Types.DOWNLOAD_CSV
}

export type DownloadInvoiceCSVSuccessAction = {
  type: Types.DOWNLOAD_CSV_SUCCESS
}

export type DownloadInvoiceCSVFailAction = {
  payload: { errors: Record<string, unknown> }
  type: Types.DOWNLOAD_CSV_FAIL
}

export type GetSelectedInvoicesFiltersAction = {
  payload: boolean
  type: Types.GET_SELECTED_INVOICES_FILTERS
}

export function getInvoices(payload: QueryParams): Action {
  return {
    type: Types.GET,
    payload
  }
}

export function getInvoicesSuccess(data: InvoiceResponse): Action {
  return {
    type: Types.GET_SUCCESS,
    payload: {
      data
    }
  }
}

export function getInvoicesFail(errors: Record<string, unknown>): Action {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function getInvoicesSummary(rentedBuildingId: string): Action {
  return {
    type: Types.GET_SUMMARY,
    payload: {
      rentedBuildingId
    }
  }
}

export function getInvoicesSummarySuccess(data: Invoice[]): Action {
  return {
    type: Types.GET_SUMMARY_SUCCESS,
    payload: {
      data
    }
  }
}

export function getInvoicesSummaryFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.GET_SUMMARY_FAIL
  }
}

export function getSelectedInvoicesFilters(
  isOpen: boolean
): GetSelectedInvoicesFiltersAction {
  return {
    payload: isOpen,
    type: Types.GET_SELECTED_INVOICES_FILTERS
  }
}
export function downloadInvoiceCSV(data: GetInvoiceCSVParams): Action {
  return {
    type: Types.DOWNLOAD_CSV,
    payload: { data }
  }
}

export function downloadInvoiceCSVSuccess(): Action {
  return {
    type: Types.DOWNLOAD_CSV_SUCCESS
  }
}

export function downloadInvoiceCSVFail(
  errors: Record<string, unknown>
): Action {
  return {
    payload: { errors },
    type: Types.DOWNLOAD_CSV_FAIL
  }
}
