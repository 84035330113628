import { MeterHistoryData, MeterHistoryMessage } from "app/types/energy"

export function detectGapsInMeterHistoryData(
  data: MeterHistoryData[]
): boolean {
  return data.some((meterHistoryData: MeterHistoryData) =>
    [
      MeterHistoryMessage.GAP,
      MeterHistoryMessage.GAP_PEAK,
      MeterHistoryMessage.PEAK_GAP
    ].includes(meterHistoryData.msg)
  )
}
