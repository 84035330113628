import { useApi } from "hooks/api"
import { useEffect } from "react"
import { Container } from "./style"
import Select from "react-select"
import {
  getBuildings
} from "app/redux/adminBuildingSelect/adminBuildingSelect.actions"
import * as actions from "app/redux/adminBuildingSelect/adminBuildingSelect.actions"
import { getAdminBuildings, getSearchStringValue } from "app/redux/adminBuildingSelect/adminBuildingSelect.selectors"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslate } from "hooks/translate"
import { setBuildingSwitcher } from "app/redux/buildingSwitcher/buildingSwitcher.actions"

type Props = {
  isVisible: boolean
}

const WdpAdminBuildingSwitcher = ({isVisible}: Props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { call } = useApi()
  const buildings = useSelector(getAdminBuildings)
  const searchString = useSelector(getSearchStringValue)
  const navigate = useNavigate()
  useEffect(() => {
    call(
      getBuildings()
    )
  }, [
    call,
  ])

  const navigateToBuilding = (value: string, label: string) => {
    dispatch(actions.setSearchString(label))
    dispatch(setBuildingSwitcher(value))
    navigate(`/dashboard/${value}`)
  }
  const clearPlaceholder = () => {
    dispatch(actions.setSearchString(undefined))
  }
  const setPlaceholder = () => {
    dispatch(actions.setSearchString(translate("portal.wdpadmin.navigation")))
  }

  return (
    <Container isVisible={isVisible}>
      <Select
        options={buildings}
        placeholder={searchString ?? translate("portal.wdpadmin.navigation")}
        onChange={opt => navigateToBuilding(opt!.value, opt!.label)}
        onFocus={clearPlaceholder}
        onBlur={setPlaceholder}
      />
    </Container>
  )
}

export default WdpAdminBuildingSwitcher