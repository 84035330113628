import { Action, Types } from "./me.actions"
import { MyProfile } from "app/types/myProfile"

export type MeState = {
  errors: object
  loading: boolean
  data: MyProfile | undefined
}

const initialState: MeState = {
  errors: {},
  loading: false,
  data: undefined
}

export default (state = initialState, action: Action): MeState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.SET_VALUE:
      const { key, value } = action.payload
      return {
        ...state,
        data: { ...state.data!, [key]: value }
      }

    default:
      return state
  }
}
