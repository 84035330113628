import {
  AnswerCode,
  TicketTypes,
  WorkOrder,
  WorkOrderStatusEnum
} from "app/types/ticket"
import { useTranslate } from "hooks/translate"
import { iconMapping } from "./config"
import {
  StyledTicketInfo,
  StyledGridIcon,
  StyledDefectTitle,
  StyledGridInfo,
  StyledGridDescription,
  StyledDefectInfo,
  StyledDefectInfoProperty,
  StyledDefectInfoPropertyValue,
  StyledWorkOrderCounts,
  StyledWorkOrderContainer
} from "./style"
import { addZeroWidthSpaceAfterCharacters } from "app/utils/linebreaks.utils"
import buildSubCategoryTranslation from "app/utils/buildSubCategoryTranslation.util"
import { dateFormatShort } from "app/utils/date.utils"
import WorkOrderStatus from "../WorkOrderStatus"

type FacilityTicketCardHeaderProps = {
  type: TicketTypes
  description?: string
  ticketNr?: string
  suppliers: string[]
  date: Date
  stringFormat?: "date-reporter" | "reporter-date"
  title: string
  answerCodes: AnswerCode[]
  reportedBy: string
  workOrders: WorkOrder[]
}

type Props = FacilityTicketCardHeaderProps

const FacilityTicketCardHeader = ({
  date,
  suppliers,
  ticketNr,
  type,
  stringFormat,
  title,
  answerCodes,
  reportedBy,
  workOrders
}: Props) => {
  const { translate } = useTranslate()

  const generateWorkOrderCounts = () => {
    return Object.keys(WorkOrderStatusEnum).map(key => {
      const count = workOrders.filter(w => w.status === key).length
      if (count > 0) {
        return <WorkOrderStatus status={key} count={count}></WorkOrderStatus>
      }
      return null
    })
  }
  return (
    <StyledTicketInfo>
      <StyledGridIcon>{iconMapping[type]}</StyledGridIcon>
      <StyledGridInfo>
        <StyledDefectTitle>
          {translate(
            `portal.facilitymanagement.${type}` +
              buildSubCategoryTranslation({ answerCodes, type })
          )}
          :&nbsp;
          {addZeroWidthSpaceAfterCharacters(title, ["_"])}
        </StyledDefectTitle>
        <StyledGridDescription>
          <StyledDefectInfo>
            <StyledDefectInfoProperty>
              {translate("portal.facilitymanagement.ticket.ticket")}
            </StyledDefectInfoProperty>
            <StyledDefectInfoPropertyValue>
              {ticketNr
                ? ticketNr
                : translate(
                    "portal.facilitymanagement.ticket.number.undefined"
                  )}
            </StyledDefectInfoPropertyValue>
          </StyledDefectInfo>
          {stringFormat === "date-reporter" ? (
            <>
              {suppliers && (
                <StyledDefectInfo>
                  <StyledDefectInfoProperty>
                    {suppliers.length > 1
                      ? translate(
                          "portal.facilitymanagement.ticket.supplier.plual"
                        )
                      : translate("portal.facilitymanagement.ticket.supplier")}
                  </StyledDefectInfoProperty>
                  <StyledDefectInfoPropertyValue>
                    {suppliers.length > 0
                      ? suppliers.join(", ")
                      : translate(
                          "portal.facilitymanagement.ticket.supplier.na"
                        )}
                  </StyledDefectInfoPropertyValue>
                </StyledDefectInfo>
              )}
              <StyledDefectInfo>
                <StyledDefectInfoProperty>
                  {translate("portal.facilitymanagement.ticket.reported")}
                </StyledDefectInfoProperty>
                <StyledDefectInfoPropertyValue>
                  {dateFormatShort(date)}
                </StyledDefectInfoPropertyValue>
              </StyledDefectInfo>
            </>
          ) : (
            <StyledDefectInfo>
              {suppliers ? (
                <>
                  <StyledDefectInfoProperty>
                    {translate("portal.facilitymanagement.ticket.reported")}
                  </StyledDefectInfoProperty>
                  <StyledDefectInfoPropertyValue>
                    {translate(
                      "portal.facilitymanagement.ticket.header.date-reporter",
                      {
                        date: dateFormatShort(date),
                        reporter: reportedBy
                      }
                    )}
                  </StyledDefectInfoPropertyValue>
                </>
              ) : (
                <>
                  <StyledDefectInfoProperty>
                    {translate("portal.facilitymanagement.ticket.reported")}
                  </StyledDefectInfoProperty>
                  <StyledDefectInfoPropertyValue>
                    {dateFormatShort(date)}
                  </StyledDefectInfoPropertyValue>
                </>
              )}
            </StyledDefectInfo>
          )}
        </StyledGridDescription>
        {workOrders && workOrders.length > 0 && (
          <StyledWorkOrderCounts>
            <StyledDefectInfoProperty>
              {translate("portal.facilitymanagement.workorders")}
            </StyledDefectInfoProperty>
            <StyledWorkOrderContainer>
              {generateWorkOrderCounts()}
            </StyledWorkOrderContainer>
          </StyledWorkOrderCounts>
        )}
      </StyledGridInfo>
    </StyledTicketInfo>
  )
}

export default FacilityTicketCardHeader
