import { StyledStatus, StatusText } from "./style"
import StatusIndicator from "../../../StatusIndicator"
import { FacilityTicketStatus } from "app/enums/ticketStatus"
import { useTranslate } from "hooks/translate"
import { Checkbox } from "@paudigital/wdp-components"

export type StatusProps = {
  amount: number
  checked: boolean
  status: FacilityTicketStatus
  onClick: (status: FacilityTicketStatus) => void
}

type Props = StatusProps

const Status = ({ status, amount, checked, onClick }: Props) => {
  const { translate } = useTranslate()

  const handleClick = () => {
    onClick(status)
  }

  return (
    <StyledStatus onClick={handleClick}>
      <Checkbox
        checked={checked}
        onClick={handleClick}
        dataTestId={`facility-managment.filter.${status}`}
      />
      <StatusIndicator status={status} />
      <StatusText>
        {translate(`portal.facilitymanagement.filter.status.${status}`)} (
        {amount})
      </StatusText>
    </StyledStatus>
  )
}

export default Status
