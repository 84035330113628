import { Checkbox } from "@paudigital/wdp-components"
import { InvoiceStatusTypes } from "app/types/invoice"
import { useTranslate } from "hooks/translate"
import { StyledStatus, StatusText } from "./style"

export type StatusProps = {
  status: InvoiceStatusTypes
  checked: boolean
  onClick: (status: InvoiceStatusTypes) => void
}

type Props = StatusProps

const Status = ({ status, checked, onClick }: Props) => {
  const { translate } = useTranslate()

  const handleClick = () => {
    onClick(status)
  }

  return (
    <StyledStatus onClick={handleClick}>
      <Checkbox checked={checked} onClick={handleClick} />
      <StatusText>
        {translate(`portal.invoices.filter.status.${status}`)}
      </StatusText>
    </StyledStatus>
  )
}

export default Status
