import { Action, Types } from "./token.actions"

export type TokenState = {
  token: string | undefined
}

const initialState: TokenState = {
  token: undefined
}

export default (state = initialState, action: Action): TokenState => {
  switch (action.type) {
    case Types.SET:
      return {
        ...state,
        token: action.payload.token
      }

    default:
      return state
  }
}
