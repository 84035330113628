import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledFormWrapper = styled.div`
  width: 100vw;
  margin: 2.8rem auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 2.8rem 0 3.2rem;
    max-width: 75rem;
  }
  @media only screen and (${theme.breakpoints.lowHeight}) {
    margin: 2.8rem 0 3.2rem;
    max-width: 75rem;
  }
`

export const ModalDescription = styled.p`
  font-size: ${theme.fontSizes.h4};
  line-height: ${theme.lineHeights.h4};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  width: 70%;
  padding: 1.6rem 0;
`
