import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import {
  LogoutButton,
  ProfilePicture,
  UserDetail
} from "@paudigital/wdp-components"
import { getUser } from "app/redux/me/me.selectors"
import {
  Container,
  LogoutButtonContainer,
  StyledLink,
  UserContainer,
  LinkWrapper,
  ProfilePictureWrapper
} from "./style"
import { useNavigate } from "react-router-dom"

type props = {
  isWdpAdmin: boolean
} 
const NavigationPopUpContent = ({ isWdpAdmin}: props) => {
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const user = useSelector(getUser)

  const handleLogout = () => {
    navigate("/logout")
  }

  if (!user) return null

  return (
    <Container data-testid="profile-menu">
      <UserContainer>
        <ProfilePictureWrapper>
          <ProfilePicture
            src={user.profilePictureUrl}
            height="7.5rem"
            width="7.5rem"
            alt={`Profile picture of ${user.firstName} ${user.lastName}`}
          />
        </ProfilePictureWrapper>
        <UserDetail
          align="center"
          name={`${user.firstName} ${user.lastName}`}
          role={user.function}
          hasSpacing={false}
        />
      </UserContainer>
      {!isWdpAdmin && (
        <>
        <LinkWrapper data-testid="account">
        <StyledLink to="/account">
          {translate("portal.navigation.item.account")}
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper data-testid="help">
        <StyledLink to="/help">
          {translate("portal.navigation.item.faq")}
        </StyledLink>
      </LinkWrapper>
      </>
      )}
      <LogoutButtonContainer data-testid="log-out">
        <LogoutButton
          label={translate("portal.navigation.button.logout")}
          fullWidth
          onClick={handleLogout}
        />
      </LogoutButtonContainer>
    </Container>
  )
}

export default NavigationPopUpContent
