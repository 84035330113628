import { Button, RolesCard, theme } from "@paudigital/wdp-components"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const Title = styled.h1`
  margin: 3.2rem;
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  color: ${theme.colors.neutral.darkerGrey};
`

export const StyledFormWrapper = styled.div`
  margin: 3.2rem;
`

export const StyledSubtitle = styled.h3`
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  margin: 1.6rem 0 0.8rem;
`
export const StyledRolesCard = styled(RolesCard)`
  width: 100%;
  margin: 1rem 0;
`

export const StyledLink = styled(Link)`
  color: ${theme.colors.green.contrast};
`

export const StyledButton = styled(Button)`
  margin-top: 1.6rem;
`
