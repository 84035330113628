import React from "react"
import { UploadImage, InputElement } from "@paudigital/wdp-components"
import { useDispatch, useSelector } from "react-redux"
import * as me from "app/redux/me/me.selectors"
import * as rentedBuilding from "app/redux/rentedBuildings/rentedBuildings.selectors"
import * as contactPersonActions from "app/redux/contactPerson/contactPerson.actions"
import * as rentedBuildingActions from "app/redux/rentedBuildings/rentedBuildings.actions"
import {
  StyledFormWrapper,
  Title,
  StyledRolesCard,
  StyledSubtitle,
  StyledLink,
  StyledButton
} from "./style"
import { useTranslate } from "hooks/translate"
import { validationSchema } from "./config"
import { useFormik } from "formik"
import { PatchContactPerson } from "app/types/contactPerson"
import { PossibleContactRoles } from "app/enums/roles"
import { useApi } from "hooks/api"
import * as responsibilitySelectors from "app/redux/responsibilities/responsibilities.selectors"
import * as responsibilityActions from "app/redux/responsibilities/responsibilities.actions"
import { RentedbuildingResponsibilities } from "app/types/responsibilities"
import UserResponsibilities from "app/components/UserResponsibilities"
import { remove } from "app/redux/appEvents/appEvents.actions"
import { ProfileActions } from "app/enums/profile"
import { setBuildingSwitcher } from "app/redux/buildingSwitcher/buildingSwitcher.actions"

const EditProfile = () => {
  const dispatch = useDispatch()
  const { call } = useApi()
  const { translate } = useTranslate()
  const profile = useSelector(me.getUser)
  const hasMultipleCompanies = useSelector(me.hasMultipleCompanies)
  const assignedPersons = useSelector(
    rentedBuilding.getAssignedPersonsForRentedBuilding
  )
  const contactPersonId = useSelector(me.getUserId)
  const rbResponsibilities = useSelector(
    responsibilitySelectors.getResponsibilities
  )
  const [profilePicture, setProfilePicture] = React.useState<File | null>(null)
  const { rentedBuildings, profilePictureUrl } = profile!
  const [buildingIds, setTempBuildingIds] = React.useState<string[]>([])

  const handleFileUpload = (data: File) => {
    setProfilePicture(data)
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values
  } = useFormik({
    initialValues: {
      ...profile!,
      termsAndConditionsAccepted: true
    },
    onSubmit: (updatedProfile: PatchContactPerson) => {
      if (isValid && contactPersonId && profile) {
        call(
          contactPersonActions.patchContactPersonWithResponsibilities(
            contactPersonId,
            updatedProfile,
            rbResponsibilities!,
            profilePicture
          )
        )
      }
    },
    validationSchema
  })

  const hasErrors = (name: string): boolean => {
    return errors[name as keyof PatchContactPerson] &&
      touched[name as keyof PatchContactPerson]
      ? true
      : false
  }

  const redirectUserManagement = (id: string) => {
    dispatch(setBuildingSwitcher(id))
    dispatch(remove(ProfileActions.PROFILE_EDIT))
  }

  const { firstName, lastName, function: userFunction } = values

  const getInfoMessage = (
    id: string,
    roles: PossibleContactRoles[]
  ): JSX.Element => {
    if (roles.includes(PossibleContactRoles.ADMIN)) {
      return (
        <p>
          {`${translate(
            "portal.account.account.profile.edit.modal.roles.userIsAdmin.text"
          )} `}
          <StyledLink
            to="/user-management"
            onClick={() => redirectUserManagement(id)}
          >
            {translate(
              "portal.account.account.profile.edit.modal.roles.userIsAdmin.link"
            )}
          </StyledLink>
        </p>
      )
    }
    if (!buildingIds.includes(id)) setTempBuildingIds([...buildingIds, id])

    if (assignedPersons) {
      const ap = assignedPersons.find(ap =>
        ap.roles.includes(PossibleContactRoles.ADMIN)
      )
      if (ap) {
        const adminName = `${ap.firstName} ${ap.lastName}`
        return (
          <p>
            {translate(
              "portal.account.account.profile.edit.modal.roles.admin",
              { adminName }
            )}
            <a href={`mailto:${ap.email}`}>{ap.email}</a>
          </p>
        )
      }
    }
    // TODO check email productowner: get from DB/generic email should come from translation?
    const productOwnerEmail = "customerportal@wdp.eu"
    return (
      <p>
        {`${translate(
          "portal.account.account.profile.edit.modal.roles.productOwner"
        )} `}
        <a href={`mailto:${productOwnerEmail}`}>{productOwnerEmail}</a>
      </p>
    )
  }

  React.useEffect(() => {
    buildingIds.forEach(b => {
      call(rentedBuildingActions.getContactpersons(b))
    })
  }, [call, buildingIds])

  React.useEffect(() => {
    if (!rentedBuildings || rbResponsibilities !== undefined) return
    dispatch(
      responsibilityActions.setResponsibilities(
        rentedBuildings.reduce((reducerData, building) => {
          return [
            ...reducerData,
            {
              rentedBuildingId: building.id,
              country: building.country,
              city: building.city,
              postalCode: building.postalCode,
              street: building.street,
              houseNo: building.houseNo,
              entityName: building.entityName,
              responsibilities: building.responsibilities
            }
          ]
        }, [] as RentedbuildingResponsibilities[])
      )
    )
  }, [dispatch, rentedBuildings, rbResponsibilities])

  return (
    <StyledFormWrapper data-testid='profile-edit-window'>
      <Title>
        {translate("portal.account.account.profile.edit.modal.title")}
      </Title>
      <form onSubmit={handleSubmit} noValidate>
        <InputElement
          label={translate(
            "portal.account.account.profile.edit.modal.lastName.label"
          )}
          name="lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={lastName}
          charLimit={127}
          error={hasErrors("lastName")}
          errorMessage={errors["lastName"] && translate(errors["lastName"])}
        />
        <InputElement
          label={translate(
            "portal.account.account.profile.edit.modal.firstName.label"
          )}
          name="firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={firstName}
          charLimit={127}
          error={hasErrors("firstName")}
          errorMessage={errors["firstName"] && translate(errors["firstName"])}
        />
        <InputElement
          label={translate(
            "portal.account.account.profile.edit.modal.function.label"
          )}
          name="function"
          onChange={handleChange}
          onBlur={handleBlur}
          value={userFunction}
          error={hasErrors("function")}
          errorMessage={errors["function"] && translate(errors["function"])}
        />
        <UploadImage
          fileSizeLimit={500000}
          fileSizeErrorMessage={translate(
            "portal.contactPerson.input.image.sizeError"
          )}
          fileTypeErrorMessage={translate(
            "portal.contactPerson.input.image.fileType"
          )}
          buttonLabel={translate(
            "portal.account.account.profile.edit.modal.uploadImage"
          )}
          onFileUpload={handleFileUpload}
          required={true}
          fullWidth={true}
          initialImage={profilePictureUrl}
        />
        <StyledSubtitle>
          {translate("portal.account.account.profile.edit.modal.roles")}
        </StyledSubtitle>
        {rentedBuildings.map((rentedBuilding, i) => {
          return (
            <StyledRolesCard
              key={i}
              city={rentedBuilding.city}
              street={rentedBuilding.street}
              entityName={
                hasMultipleCompanies ? rentedBuilding.entityName : undefined
              }
              housenumber={rentedBuilding.houseNo}
              role={rentedBuilding.roles.join(", ")}
              info={getInfoMessage(rentedBuilding.id, rentedBuilding.roles)}
            />
          )
        })}
        <StyledSubtitle>
          {translate(
            "portal.account.account.profile.edit.modal.responsibilities"
          )}
        </StyledSubtitle>
        <UserResponsibilities
          rentedbuildingResponsibilities={rbResponsibilities ?? []}
        />
        <StyledButton
          label={translate("portal.account.account.profile.edit.modal.save")}
          fullWidth
          submit
          disabled={
            !isValid ||
            !rbResponsibilities ||
            !rbResponsibilities.every(rb => rb.responsibilities.length > 0)
          }
        />
      </form>
    </StyledFormWrapper>
  )
}

export default EditProfile
