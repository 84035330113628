import { useTranslate } from "hooks/translate"
import { ProfileActions } from "app/enums/profile"
import {
  OnboardingSuccessImage,
  PopUp,
  Typography
} from "@paudigital/wdp-components"
import { Container, TypographyWrapper } from "./style"
import { useDispatch } from "react-redux"
import { remove } from "app/redux/appEvents/appEvents.actions"

const DetailUpdatePopup = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(remove(ProfileActions.INFO_UPDATED))
  }

  return (
    <PopUp show onClose={handleClose} delayInMs={4000}>
      <Container data-testid="pop-up-changes">
        <OnboardingSuccessImage />
        <TypographyWrapper>
          <Typography variant="h4" fontStyle="bold">
            {translate("portal.account.account.details.update.confirmation")}
          </Typography>
        </TypographyWrapper>
      </Container>
    </PopUp>
  )
}

export default DetailUpdatePopup
