import { theme } from "@paudigital/wdp-components"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const StyledLink = styled(Link)`
  padding: 1.6rem;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
`

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 1.2rem;
  height: 4.6rem;
  border-top: 1px solid ${theme.colors.neutral.midGrey};

  & + & {
    margin-top: 0;
  }
`

export const LogoutButtonContainer = styled.div`
  padding: 1.6rem;
  border-top: 1px solid ${theme.colors.neutral.midGrey};
`

export const UserContainer = styled.div`
  border-radius: 50%;
  margin: 0.8rem auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProfilePictureWrapper = styled.div`
  margin-bottom: 1.2rem;
`
