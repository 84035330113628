import { ChevronRight, ChevronLeft, theme } from "@paudigital/wdp-components"
import { Item } from "./style"

type Props = {
  label: string | JSX.Element
  itemNumber?: number
  onClick: () => void
  isBackButton?: boolean
  bold?: boolean
}

const ListItem = ({
  label,
  onClick,
  itemNumber,
  isBackButton = false,
  bold = false
}: Props) => {
  return (
    <Item
      onClick={onClick}
      isBackButton={isBackButton}
      bold={bold}
      data-testid={`list-item-container${
        Number.isInteger(itemNumber) ? `-${itemNumber}` : ""
      }`}
    >
      {label}{" "}
      {isBackButton ? (
        <ChevronLeft
          width="2rem"
          height="2rem"
          fill={theme.colors.neutral.darkerGrey}
        />
      ) : (
        <ChevronRight
          width="2rem"
          height="2rem"
          fill={theme.colors.neutral.darkerGrey}
        />
      )}
    </Item>
  )
}

export default ListItem
