import { DocumentCategories } from "./documentTypes.reducer"

export enum Types {
  GET = "DOCUMENT_TYPES_GET",
  GET_SUCCESS = "DOCUMENT_TYPES_GET_SUCCESS",
  GET_FAIL = "DOCUMENT_TYPES_GET_FAIL"
}

export type Action =
  | GetDocumentTypesAction
  | GetDocumentTypesSuccessAction
  | GetDocumentTypesFailAction

export type GetDocumentTypesAction = {
  payload: {
    rentedBuildingId: string
  }
  type: Types.GET
}

export type GetDocumentTypesSuccessAction = {
  payload: DocumentCategories
  type: Types.GET_SUCCESS
}

export type GetDocumentTypesFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export function getDocumentTypes(rentedBuildingId: string): GetDocumentTypesAction {
  return {
    type: Types.GET,
    payload: {
      rentedBuildingId
    }
  }
}

export function getDocumentTypesSuccess(
  payload: DocumentCategories
): GetDocumentTypesSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getDocumentTypesFail(
  errors: object
): GetDocumentTypesFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
