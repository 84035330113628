import { Action, Types } from "./documentTypes.actions"

export enum DocumentContentType {
  WDP_Contractor_Document = "WDP Contractor Document",
  WDP_Contract_Related_Document = "WDP Contract Related Document"
}

export type DocumentCategory = {
  treeType: string
  documentTypeId: string
  secondaryTypeIds: string[]
  documentContentType: DocumentContentType
  children: DocumentCategory[]
}

export type DocumentCategories = DocumentCategory[]

export type DocumentTypesState = {
  errors: object
  loading: boolean
  data: DocumentCategories
}

const initialState: DocumentTypesState = {
  errors: {},
  loading: false,
  data: []
}

export default (state = initialState, action: Action): DocumentTypesState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        errors: {},
        loading: false,
        data: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
