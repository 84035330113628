import contactPerson from "./redux/contactPerson/contactPerson.saga"
import rentedBuildingsSaga from "./redux/rentedBuildings/rentedBuildings.saga"
import notificationsSaga from "./redux/notifications/notifications.saga"
import notificationSettingsSaga from "./redux/notificationSettings/notificationSettings.saga"
import rentedBuildingContactPersonsSaga from "./redux/rentedBuildingContactPerson/rentedBuildingContactPerson.saga"
import meSaga from "./redux/me/me.saga"
import preferredBuildingSaga from "./redux/preferredBuilding/preferredBuilding.saga"
import deleteContactPersonSaga from "./redux/deleteContactPerson/deleteContactPerson.saga"
import contactPersonContactRolesSaga from "./redux/contactPersonContactRoles/contactPersonContactRoles.saga"
import buildingSpecificationSaga from "./redux/buildingSpecifications/buildingSpecifications.saga"
import rentedBuildingContracts from "./redux/rentedBuildingContracts/rentedBuildingContracts.saga"
import rentedBuildingContactsSaga from "./redux/rentedBuildingContacts/rentedBuildingContacts.saga"
import ticketCategoriesSaga from "./redux/ticketCategories/ticketCategories.saga"
import ticketSaga from "./redux/ticket/ticket.saga"
import ticketCommunicationSaga from "./redux/ticketCommunication/ticketCommunication.saga"
import documentSaga from "./redux/document/document.saga"
import waterMeterHistorySaga from "./redux/waterMeterHistory/waterMeterHistory.saga"
import gasMeterHistorySaga from "./redux/gasMeterHistory/gasMeterHistory.saga"
import availableMetersSaga from "./redux/graphs/graphs.saga"
import electricityMeterHistorySaga from "./redux/electricityMeterHistory/electricityMeterHistory.saga"
import solarProductionHistorySaga from "./redux/solarProductionHistory/solarProductionHistory.saga"
import invoicesSaga from "./redux/invoices/invoices.saga"
import energyUsageComparisonSaga from "./redux/energyUsageComparison/energyUsageComparison.saga"
import documentTypesSaga from "./redux/documentTypes/documentTypes.saga"
import authorizationSaga from "./redux/authorization/authorization.saga"
import responsibilitiesSaga from "./redux/responsibilities/responsibilities.saga"
import buildingSwitcherSaga from "./redux/buildingSwitcher/buildingSwitcher.saga"
import energyCSVSaga from "./redux/energyCSV/energyCSV.saga"
import languagePreference from "./redux/languagePreference/languagePreference.saga"
import gaEventsSaga from "./redux/gaEvents/gaEvents.saga"
import appEventsSaga from "./redux/appEvents/appEvents.saga"
import registrationWizardSaga from "./redux/registrationWizard/registrationWizard.saga"
import ticketWizardSaga from "./redux/ticketWizard/ticketWizard.saga"
import documentWizardSaga from "./redux/documentWizard/documentWizard.saga"
import httpErrorsSaga from "./redux/httpErrors/httpErrors.saga"
import faqSaga from "./redux/faq/faq.saga"
import campaignSaga from "./redux/campaign/campaign.saga"
import mfaSaga from "./redux/mfa/mfa.saga"
import authenticationSaga from "./redux/authentication/authentication.saga"
import workOrderSaga from "./redux/workOrder/workOrder.saga"
import { surveysSaga } from "./redux/surveys/surveys.saga"
import pvProjectSaga from "./redux/pvProject/pvProject.saga"
import adminBuildingsSaga from "./redux/adminBuildingSelect/adminBuildingSelect.saga"

export default [
  appEventsSaga,
  authorizationSaga,
  availableMetersSaga,
  buildingSpecificationSaga,
  buildingSwitcherSaga,
  contactPerson,
  contactPersonContactRolesSaga,
  deleteContactPersonSaga,
  documentSaga,
  documentTypesSaga,
  documentWizardSaga,
  electricityMeterHistorySaga,
  energyCSVSaga,
  energyUsageComparisonSaga,
  faqSaga,
  gaEventsSaga,
  gasMeterHistorySaga,
  invoicesSaga,
  languagePreference,
  meSaga,
  notificationSettingsSaga,
  notificationsSaga,
  preferredBuildingSaga,
  registrationWizardSaga,
  rentedBuildingContactPersonsSaga,
  rentedBuildingContactsSaga,
  rentedBuildingContracts,
  rentedBuildingsSaga,
  responsibilitiesSaga,
  solarProductionHistorySaga,
  ticketCategoriesSaga,
  ticketCommunicationSaga,
  ticketSaga,
  ticketWizardSaga,
  waterMeterHistorySaga,
  httpErrorsSaga,
  campaignSaga,
  mfaSaga,
  authenticationSaga,
  workOrderSaga,
  surveysSaga,
  pvProjectSaga,
  adminBuildingsSaga
]
