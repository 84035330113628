import {
  Button,
  PreferencesIcon,
  theme,
  Spinner,
  DownloadIcon
} from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import {
  TopBarMobile,
  StyledTotalTickets,
  TopButtons,
  TitleWrapper,
  ButtonWrapper
} from "./style"
import Sort from "../../FacilityFilter/Sort"
import FilterTags from "../../FilterTags"
import { useViewport } from "hooks/viewport"
import { useDispatch, useSelector } from "react-redux"
import {
  getTicketsFilterIsOpen,
  getTotalTickets,
  loading
} from "app/redux/ticket/ticket.selectors"
import {
  downloadTicketsCSV,
  getSelectedTicketsFilters
} from "app/redux/ticket/ticket.actions"
import { Filters } from "routes/FacilityManagement"
import { useApi } from "hooks/api"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  filters: Filters
  setUrlParams: (params: Filters) => void
}

const MobileTopBar = ({ filters, setUrlParams }: Props) => {
  const { call } = useApi()
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { width } = useViewport()
  const showFilters = useSelector(getTicketsFilterIsOpen)
  const total = useSelector(getTotalTickets)
  const isLoading = useSelector(loading)

  const toggleFilters = () => {
    dispatch(getSelectedTicketsFilters(!showFilters))
  }

  const isFiltered =
    filters.type ||
    filters.status ||
    filters.ticket ||
    filters.to ||
    filters.from ||
    filters.description

  const handleDownloadPopUp = () => {
    dispatch(createGAEvent(PossibleGAEvents.DOWNLOAD_OVERVIEW))
    call(downloadTicketsCSV(filters))
  }

  return (
    <TopBarMobile isOpen={showFilters}>
      <TitleWrapper>
        {isLoading && <Spinner />}
        <StyledTotalTickets>
          {isFiltered
            ? translate(
                `portal.facilitymanagement.reports${
                  isLoading ? ".loading" : ""
                }`,
                {
                  amount: total
                }
              )
            : translate("portal.facilitymanagement.reports.all", {
                amount: total
              })}
        </StyledTotalTickets>
      </TitleWrapper>
      <TopButtons>
        <ButtonWrapper>
          <Button
            type="secondary"
            label={translate("portal.facilitymanagement.filter.button.label")}
            onClick={toggleFilters}
            startIcon={<PreferencesIcon fill={theme.colors.green.contrast} />}
          />
          <Button
            type="secondary"
            fullWidth
            label={translate("portal.facilitymanagement.download.button.label")}
            startIcon={
              <DownloadIcon
                height="3rem"
                width="2.5rem"
                fill={theme.colors.green.contrast}
              />
            }
            onClick={handleDownloadPopUp}
          />
        </ButtonWrapper>
        {width >= theme.breakpointsValues.tablet && (
          <Sort setUrlParams={setUrlParams} {...filters} />
        )}
      </TopButtons>
      <FilterTags filters={filters} setUrlParams={setUrlParams} />
    </TopBarMobile>
  )
}

export default MobileTopBar
