import { ProfileCard, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledProfileCard = styled(ProfileCard)`
  margin: 3.2rem 0 0;
  cursor: default;

  > :nth-child(2) {
    align-self: auto;
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 1.6rem 0;
  }
`
