const serverErrors = [
  500,
  501,
  502,
  503,
  504,
  505,
  506,
  507,
  508,
  509,
  510,
  511,
  529,
  530,
  598,
  599
]

export const isServerBasedError = (error: number) => {
  return serverErrors.includes(error)
}

export default isServerBasedError
