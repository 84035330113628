import ModuleWidget from "app/components/ModuleWidget"
import { Modules } from "app/enums/modules"
import { useApi } from "hooks/api"
import { useTranslate } from "hooks/translate"
import { useEffect } from "react"
import Icon from "./icon"
import * as selectors from "app/redux/document/document.selectors"
import { getDocumentsSummary } from "app/redux/document/document.actions"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { useSelector } from "react-redux"
import WidgetCard from "./WidgetCard"
import EmptyWidgetContainer from "app/components/EmptyWidgetContainer"
import { usePermission } from "hooks/permission"

const Documents = () => {
  const id = useSelector(getActiveBuilding)
  const { call } = useApi()
  const { translate } = useTranslate()

  const summary = useSelector(selectors.getDocumentsSummary)
  const { hasPermission } = usePermission()
  const documentsPermission =
    id && hasPermission(Modules.DOCUMENTS, id!)

  useEffect(() => {
    if (documentsPermission && id) {
      call(getDocumentsSummary(id))
    }
  }, [call, documentsPermission, id])

  return (
    <ModuleWidget
      permissionModule={Modules.DOCUMENTS}
      title={translate("portal.dashboard.widget.documents.title")}
      description={translate("portal.dashboard.widget.documents.guidance")}
      image={<Icon />}
      url={`/documents/${id}`}
    >
      {summary && summary.length > 0 ? (
        summary.map(document => (
          <WidgetCard key={document.id} document={document} />
        ))
      ) : (
        <EmptyWidgetContainer>
          {translate("portal.dashboard.widget.documents.nodocuments")}
        </EmptyWidgetContainer>
      )}
    </ModuleWidget>
  )
}

export default Documents
