import { MeterHistoryData } from "app/types/energy"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.gasMeterHistory.loading
export const hasErrors = (state: State): boolean =>
  state.gasMeterHistory.errors ? true : false
export const hasWarning = (state: State): boolean =>
  getGasMeterHistoryData(state)?.some(h => h.msg) || hasErrors(state)
export const getLastUpdated = (state: State): Date | undefined =>
  state.gasMeterHistory.lastUpdated
export const getGasMeterHistoryData = (
  state: State
): MeterHistoryData[] | undefined => {
  return state.gasMeterHistory.data
}
