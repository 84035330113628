import { useEffect } from "react"
import { CustomSelect } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import {
  Container,
  SelectWrapper,
  PreviousPeriodButton,
  NextPeriodButton,
  PaginationBar,
  StyledLabel,
  Wrapper
} from "./style"
import { useDispatch, useSelector } from "react-redux"
import {
  getEnergyPeriodRange,
  getEnergyPeriod,
  needsToSyncEnergyPeriod
} from "app/redux/energyPeriod/energyPeriod.selectors"
import * as energyPeriodActions from "app/redux/energyPeriod/energyPeriod.actions"
import * as appEventActions from "app/redux/appEvents/appEvents.actions"
import {
  periodMapping,
  Periods
} from "app/redux/energyPeriod/energyPeriod.reducer"
import { formatISODateWithoutTimezone } from "app/utils/dateFormatter.utils"
import PeriodOption from "./PeriodOption"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { getDayStart, formatDate } from "./util/date.util"
import { useScroll } from "hooks/scroll"
import { isEnergyPeriodCustom } from "app/utils/energy.utils"
import { convertPeriodToDays } from "app/utils/periodInDays.utils"
import { EnergyPeriodQueryParams } from "../PageHeaders/EnergyPageHeader"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  from: string
  to: string
  period: Periods
  setUrlParams: (params: EnergyPeriodQueryParams) => void
}

const EnergyPeriod = ({ from, to, period, setUrlParams }: Props) => {
  const { y: scrollY } = useScroll()
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const energyPeriod = useSelector(getEnergyPeriod)
  const energyPeriodRange = useSelector(getEnergyPeriodRange)
  const needsToSync = useSelector(needsToSyncEnergyPeriod)

  const periodInDays = (() =>
    convertPeriodToDays(energyPeriodRange.from, energyPeriodRange.to))()

  useEffect(() => {
    if (needsToSync && from && to && period) {
      dispatch(
        energyPeriodActions.setEnergyPeriodAction(from, to, period as Periods)
      )
    }
  }, [dispatch, needsToSync, from, period, to])

  useEffect(() => {
    return () => {
      dispatch(energyPeriodActions.resetEnergyPeriod())
      dispatch(appEventActions.remove("energy-incomplete-data-error"))
    }
  }, [dispatch])

  const options = [
    Periods.day,
    Periods.week,
    Periods.month,
    Periods.year,
    Periods.custom
  ].map(p => PeriodOption(p, energyPeriodRange.from, energyPeriodRange.to))

  const changePeriod = (
    start_period: string,
    end_period: string,
    period: Periods
  ) => {
    setUrlParams({ start_period, end_period, period })
    dispatch(energyPeriodActions.setEnergyPeriodAction(from, to, period))
  }

  const buttonText = translate(
    `portal.energy.period.${
      isEnergyPeriodCustom(energyPeriod)
        ? periodInDays === 1
          ? Periods.day
          : "custom.days"
        : energyPeriod
    }`,
    { days: periodInDays }
  )

  const dayStart = getDayStart(to)

  const handleChange = (p: Periods) => {
    if (p === Periods.custom) {
      dispatch(
        fire({
          eventName: PossibleAppEvents.CUSTOM_PERIOD,
          uniqueIdentifier: "custom-period",
          props: { start_period: from, end_period: to, period, setUrlParams }
        })
      )
    } else {
      const fromDate = formatDate("sub", dayStart, periodMapping[p])
      const toDate = formatISODateWithoutTimezone(dayStart)
      changePeriod(fromDate, toDate, Periods[p])
      dispatch(
        createGAEvent(PossibleGAEvents.TIMEPERIOD, {
          period: p
        })
      )
    }
  }

  const handlePrevious = () => {
    const newToDate = formatDate("sub", dayStart, periodInDays)
    const newFromDate = formatDate("sub", new Date(newToDate), periodInDays)
    changePeriod(newFromDate, newToDate, period)
    dispatch(
      createGAEvent(PossibleGAEvents.TIMEPERIOD, {
        period: `Previous ${buttonText}`
      })
    )
  }

  const handleNext = () => {
    const newFromDate = formatISODateWithoutTimezone(dayStart)
    const newToDate = formatDate("add", dayStart, periodInDays)
    changePeriod(newFromDate, newToDate, period)
    dispatch(
      createGAEvent(PossibleGAEvents.TIMEPERIOD, {
        period: `Next ${buttonText}`
      })
    )
  }

  const hasShadow = Math.abs(scrollY) >= 75

  return (
    <PaginationBar hasShadow={hasShadow}>
      <Wrapper>
        <StyledLabel>{translate("portal.energy.period.label")}</StyledLabel>
        <Container>
          <PreviousPeriodButton onClick={handlePrevious}>
            <strong>{translate("portal.energy.period.button.previous")}</strong>
            <span>{buttonText}</span>
          </PreviousPeriodButton>
          <SelectWrapper>
            <CustomSelect
              options={options}
              value={
                isEnergyPeriodCustom(energyPeriod) ? "custom" : energyPeriod
              }
              onChange={handleChange}
            />
          </SelectWrapper>
          <NextPeriodButton onClick={handleNext}>
            <strong>{translate("portal.energy.period.button.next")}</strong>
            <span>{buttonText}</span>
          </NextPeriodButton>
        </Container>
      </Wrapper>
    </PaginationBar>
  )
}

export default EnergyPeriod
