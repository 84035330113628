import { Button, Typography } from "@paudigital/wdp-components"
import { Modules } from "app/enums/modules"
import { useTranslate } from "hooks/translate"
import Placeholder from "./placeholder.jpeg"
import { Container, StyledDescription, StyledImage } from "./style"
import { useDispatch } from "react-redux"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { mappings, useContactPerson } from "hooks/contactPerson"
import { ExpertPopupButtonPosition } from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  module: Modules
  className?: string
}

const ContactSidebar = ({ module, className }: Props): JSX.Element => {
  const { contactMapping } = useContactPerson()
  const option = mappings[module]
  const contact = contactMapping[option]
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const togglePopUp = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.SHOW_EXPERT,
        uniqueIdentifier: "show-expert",
        props: {
          module,
          position: ExpertPopupButtonPosition.MIDDLE
        }
      })
    )
  }

  const person = contact?.person

  const profilePicture = (): string => {
    if (contact && contact.profilePicture) {
      return contact.profilePicture
    }
    return Placeholder
  }

  return (
    <Container className={className}>
      <StyledImage src={profilePicture()} />
      <Typography variant="h6" fontStyle="bold" as="h2">
        {translate("portal.contactfooter.expert.title", {
          name: person?.firstName
        })}
      </Typography>
      <StyledDescription>
        {translate(`portal.contactfooter.expert.${option}.description`, {
          name: person?.firstName
        })}
      </StyledDescription>
      <Button
        label={translate("portal.contact.button.label")}
        onClick={togglePopUp}
      />
    </Container>
  )
}

export default ContactSidebar
