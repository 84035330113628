import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledLoggedOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoggedOutTitle = styled.h1`
  margin-bottom: 1.2rem;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.black};
  text-align: center;
`

export const LoggedOutBody = styled.p`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.light};
  text-align: center;
`
