import React from "react"
import { useTranslate } from "hooks/translate"
import {
  DeleteModalTextBold,
  DeleteModalTextLight,
  RadioGroupWrapper
} from "../style"
import { BaseDeleteModal, Radio, RadioGroup } from "@paudigital/wdp-components"
import { useSelector } from "react-redux"
import * as deleteCpSelectors from "app/redux/deleteContactPerson/deleteContactPerson.selectors"
import * as meSelectors from "app/redux/me/me.selectors"
import { deleteContactPerson } from "app/redux/deleteContactPerson/deleteContactPerson.actions"
import { useApi } from "hooks/api"
import { useRentedBuilding } from "hooks/rentedBuilding"

type DeleteContactPersonSingleBuildingModalProps = {
  onCancel: () => void
}

type Props = DeleteContactPersonSingleBuildingModalProps

const DeleteContactPersonSingleBuildingModal = ({ onCancel }: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const [stillWorksAtCompany, setStillWorksAtCompany] = React.useState<
    string | undefined
  >(undefined)
  const { rentedBuilding } = useRentedBuilding()
  const companyName = useSelector(meSelectors.getUserCompanyName)
  const email = useSelector(deleteCpSelectors.getEmailOfContactPerson)
  const isLoading = useSelector(deleteCpSelectors.isLoading)
  const contactPersonId = useSelector(deleteCpSelectors.getIdOfContactPerson)
  const buildingsIdsToDeleteCpFrom = useSelector(
    deleteCpSelectors.getBuildingIdsToDeleteContactPersonFrom
  )

  const onDelete = () => {
    if (!contactPersonId || !rentedBuilding || !stillWorksAtCompany) return
    call(
      deleteContactPerson(
        contactPersonId,
        buildingsIdsToDeleteCpFrom,
        stillWorksAtCompany === "available" ? true : false
      )
    )
  }

  const handleRadioClick = (name: string) => {
    setStillWorksAtCompany(name)
  }

  return (
    <BaseDeleteModal
      title={translate("portal.admin.delete.seat.delete.contact")}
      cancelBtnText={translate("portal.admin.delete.seat.cancel")}
      deleteBtnText={translate("portal.admin.delete.seat.delete.account")}
      deleteDisabled={
        isLoading ||
        stillWorksAtCompany === undefined ||
        contactPersonId === undefined
      }
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <>
        <DeleteModalTextLight marginBottom="2.4rem">
          {translate("portal.admin.delete.seat.delete.contact.description", {
            email
          })}
        </DeleteModalTextLight>
        <DeleteModalTextBold marginBottom="1.6rem">
          {translate("portal.admin.delete.seat.delete.contact.action", {
            companyName
          })}
        </DeleteModalTextBold>
        <RadioGroupWrapper>
          <RadioGroup contentCentered={true}>
            <Radio
              checked={stillWorksAtCompany === "available"}
              disabled={false}
              onClick={handleRadioClick}
              label={translate("portal.admin.delete.seat.delete.contact.yes")}
              name={"available"}
            />
            <Radio
              checked={stillWorksAtCompany === "notAvailable"}
              disabled={false}
              onClick={handleRadioClick}
              label={translate("portal.admin.delete.seat.delete.contact.no")}
              name={"notAvailable"}
            />
          </RadioGroup>
        </RadioGroupWrapper>
      </>
    </BaseDeleteModal>
  )
}

export default DeleteContactPersonSingleBuildingModal
