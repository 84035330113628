import ContractNumber from "./ContractNumber"
import { useSelector } from "react-redux"
import { getRentedBuildingContractNumbers } from "app/redux/rentedBuildingContracts/rentedBuildingContracts.selector"
import { Filters } from "routes/Invoices"

type Props = {
  setUrlParams: (params: any) => void
} & Filters

const ContractNumberFilter = ({
  page,
  perPage,
  invoiceType,
  contractNumber,
  invoicePeriodTo,
  invoicePeriodFrom,
  invoiceDateTo,
  invoiceDateFrom,
  invoiceNumber,
  reference,
  sort,
  setUrlParams
}: Props) => {
  const contractsNumbers = useSelector(getRentedBuildingContractNumbers)
  const queryParams = {
    page,
    perPage,
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort
  }

  const selectedFilterContractNumber = contractNumber
    ? (contractNumber as string[])
    : []

  const clickHandler = (contractNumber: string) => {
    if (selectedFilterContractNumber.includes(contractNumber)) {
      const filteredTypes = selectedFilterContractNumber.filter(
        it => it !== contractNumber
      )
      setUrlParams({
        ...queryParams,
        page: 1,
        contractNumber: filteredTypes
      })
    } else {
      setUrlParams({
        ...queryParams,
        page: 1,
        contractNumber: [...selectedFilterContractNumber, contractNumber]
      })
    }
  }

  return (
    <>
      {contractsNumbers.map(cn => (
        <ContractNumber
          key={cn}
          checked={selectedFilterContractNumber.includes(cn)}
          contractNumber={cn}
          onClick={clickHandler}
        />
      ))}
    </>
  )
}
export default ContractNumberFilter
