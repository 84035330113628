import { Button } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledButton = styled(Button)`
  min-width: 24rem;
`

export const Wrapper = styled.div`
  margin-top: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
`
