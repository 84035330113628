import { DocumentIcon } from "@paudigital/wdp-components"
import Section from "app/components/Section"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { RentedBuildingContract } from "app/types/rentedBuildingContract"
import { getContractNumberFromContractSourceId } from "app/utils/rentedBuildingContracts.utils"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import ContractualGuaranteeList from "../ContractualGuaranteeList"
import Indexing from "../Indexing"
import { useNavigate } from "react-router"
import {
  ContractGridContainer,
  ContractRentalPeriod,
  ContractRentalPeriodText,
  ContractRentalPeriodWrapper,
  StyledButton
} from "../style"
import { format } from "date-fns"
import { getDocumentStatistics } from "app/redux/document/document.selectors"
import { DocumentTypes } from "app/enums/documentTypes"

type SingleContractViewProps = {
  contract: RentedBuildingContract
}

type Props = SingleContractViewProps

const SingleContractView = ({ contract }: Props) => {
  const rentedBuildingId = useSelector(getActiveBuilding)
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const docStats = useSelector(getDocumentStatistics)

  const docStatsIndex = docStats
    ?.filter(ds => ds.document_type === DocumentTypes.Financial)
    .filter(ds =>
      ds.children.filter(ds => ds.document_type === DocumentTypes.Index_letter)
    )
    .find(ds => ds.count >= 0)

  const docStatsContracts = docStats?.find(
    ds => ds.document_type === DocumentTypes.Contracts
  )

  const page = 1
  const perpage = 20
  const sort = "date=desc"

  const RedirectDocuments = (docType: string) => {
    navigate(
      `/documents/${rentedBuildingId}?documentType=${docType}&page=${page}&perPage=${perpage}&sort[]=${sort}`
    )
  }

  return (
    <Section
      title={translate("portal.buildingpassport.contracts.single.title", {
        contractNumber: getContractNumberFromContractSourceId(contract.sourceId)
      })}
      subTitle={
        <ContractRentalPeriodWrapper>
          <ContractRentalPeriodText>
            {`${translate("portal.buildingpassport.contract.rental.period")}`}:
          </ContractRentalPeriodText>
          <ContractRentalPeriod>
            {`${format(new Date(contract.startDate), "dd/MM/yyyy")} - 
            ${format(new Date(contract.endDate), "dd/MM/yyyy")}
            `}
          </ContractRentalPeriod>
        </ContractRentalPeriodWrapper>
      }
    >
      <ContractGridContainer>
        <ContractualGuaranteeList
          securityDeposits={contract.securityDeposits}
        />
      </ContractGridContainer>
      {contract.indexFirstDate !== null || contract.indexFirstDate ? (
        <Indexing date={contract.indexFirstDate} />
      ) : (
        <></>
      )}
      <>
        {docStatsIndex?.count && (
          <StyledButton
            type="secondary"
            label={translate(
              "portal.buildingpassport.contract.section.indexing.button"
            )}
            onClick={() => RedirectDocuments(DocumentTypes.Index_letter)}
            fullWidth
            startIcon={<DocumentIcon />}
          />
        )}
      </>
      <>
        {docStatsContracts?.count && (
          <StyledButton
            type="secondary"
            label={translate(
              "portal.buildingpassport.contract.section.contract.button",
              {
                amount: docStatsContracts.count
              }
            )}
            onClick={() => RedirectDocuments(DocumentTypes.Contracts)}
            fullWidth
            startIcon={<DocumentIcon />}
          />
        )}
      </>
    </Section>
  )
}

export default SingleContractView
