import styled from "styled-components"

export const NotificationsContainer = styled.div`
  margin-top: 4rem;
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
`
