import { getDocumentTypes } from "app/api/documents"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./documentTypes.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"

export function* getDocumentTypesFlow(
  action: actions.GetDocumentTypesAction
): SagaIterator {
  const { rentedBuildingId } = action.payload
  try {
    const response = yield call(getDocumentTypes, rentedBuildingId)
    yield put(actions.getDocumentTypesSuccess(response.data))
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.getDocumentTypesFail(e))
  }
}

export default function* documentTypesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getDocumentTypesFlow)
}
