import { useIntl } from "react-intl"

type Props = {
  label: string
}

const MarkdownTranslation = ({ label }: Props) => {
  const { messages } = useIntl()
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: messages[label] as string
      }}
    />
  )
}

export default MarkdownTranslation
