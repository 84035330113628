import { Data } from "@paudigital/wdp-components"
import { PossibleResponsibilities } from "app/enums/responsibilities"
import { RentedbuildingResponsibilities, UpdateRbResponsibilities } from "app/types/responsibilities"
import { deslugifyString, slugifyString } from "./slug.utils"

const allResponsibilities = Object.values(PossibleResponsibilities)

export function mapResponsibilityReducerDataToNestedCheckboxDataStructure(
  rbResponsibilities: RentedbuildingResponsibilities,
  hasMultipleCompanies: boolean
): Data {
  const parent = {
    label: `${rbResponsibilities.street} ${rbResponsibilities.houseNo}, ${rbResponsibilities.city}`,
    customerName: hasMultipleCompanies ? rbResponsibilities.entityName : undefined,
    selected: allResponsibilities.every(resp => rbResponsibilities.responsibilities.includes(resp))
  }
  const children = allResponsibilities.map((name: string) => ({
    label: `portal.onboarding.responsibilities.${slugifyString(name)}`,
    name,
    guidanceText: `portal.onboarding.responsibilities.${slugifyString(name)}.guidance`,
    selected: rbResponsibilities.responsibilities.find(resp => resp === name) ? true : false
  }))

  return { parent, children }
}

export function reduceNestedCheckboxDataToResponsibilityReducerData(
  rentedBuildingId: string,
  checkboxData: Data
): UpdateRbResponsibilities {
  const { children } = checkboxData
  const responsibilities = children.reduce((respArr, currentChild) => {
    if (!currentChild.selected) return respArr
    return [
      ...respArr,
      deslugifyString(currentChild.name!) as PossibleResponsibilities
    ]
  }, [] as PossibleResponsibilities[])

  return {
    rentedBuildingId,
    responsibilities
  }
}
