import {
  DoorIcon,
  ElectricityIcon,
  PipeIcon,
  WindowIcon,
  FloorIcon,
  FireIcon,
  GatesIcon,
  HvacIcon,
  RoofIcon,
  LiftIcon,
  HouseIcon,
  SlideIcon,
  theme
} from "@paudigital/wdp-components"
import { TicketTypes } from "app/types/ticket"

export type IconMappingType = {
  [key: string]: JSX.Element
}

export const iconMapping: IconMappingType = {
  [TicketTypes.WDP_01]: <RoofIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_02]: <DoorIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_03]: <FireIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_04]: (
    <ElectricityIcon fill={theme.colors.neutral.darkerGrey} />
  ),
  [TicketTypes.WDP_05]: <HvacIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_06]: <PipeIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_07]: <GatesIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_08]: <WindowIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_09]: <FloorIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_10]: <HouseIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_11]: <LiftIcon fill={theme.colors.neutral.darkerGrey} />,
  [TicketTypes.WDP_12]: <SlideIcon fill={theme.colors.neutral.darkerGrey} />
}
