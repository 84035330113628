import { useTranslate } from "hooks/translate"
import { StyledStaticNotification } from "./style"

const EnergyIncompleteDataNotification = () => {
  const { translate } = useTranslate()
  return (
    <StyledStaticNotification
      description={translate("portal.energy.incomplete.error")}
      variant="error"
    />
  )
}

export default EnergyIncompleteDataNotification
