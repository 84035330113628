import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const CustomPeriodWrapper = styled.div`
  margin: 3.2rem;
`

export const Title = styled.p`
  text-align: center;
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
`

export const CustomPeriodContainer = styled.div`
  background: ${theme.colors.neutral.lightGrey};
  margin-bottom: 20rem;
`

export const DateRangeWrapper = styled.div`
  background: ${theme.colors.neutral.lightGrey};
  padding: 2.4rem 0 3.2rem;
  margin: 2.4rem 3.2rem;
`

export const ErrorWrapper = styled.div`
  margin: 1rem 0;
`
