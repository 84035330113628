import {
  CertificationContainer,
  CertificationValue,
  CertificationLabel
} from "./style"
import SpecificationItem from "app/components/SpecificationItem"
import { useTranslate } from "hooks/translate"

export type CertificationProps = {
  quality?: string
  edge?: string
  breeam?: string
  tapa?: string
  qualityDescription?: string
  edgeDescription?: string
  breeamDescription?: string
  tapaDescription?: string
}

type Props = CertificationProps

const Certification = ({
  quality,
  edge,
  breeam,
  tapa,
  qualityDescription,
  edgeDescription,
  breeamDescription,
  tapaDescription
}: Props) => {
  const { translate } = useTranslate()

  // !! Regarding issue:
  // !! https://paudigital.atlassian.net/browse/WDP-2236
  // !!
  // !! Master data is not correct in WDP’s back offices
  // !! therefore we don't show certification at the moment
  // !! Remove showCertification boolean when this needs
  // !! to be re-enabled ↓
  const showQuality = false
  const hasValues = edge || breeam || tapa
  if (!hasValues) return null

  return (
    <SpecificationItem label="portal.buildingpassport.specification.certificationsList">
      <CertificationContainer>
        {breeam && (
          <>
            <CertificationLabel>
              {translate("portal.buildingpassport.specification.BREEAM")}
            </CertificationLabel>
            <CertificationValue>{breeamDescription}</CertificationValue>
          </>
        )}
        {edge && (
          <>
            <CertificationLabel>
              {translate("portal.buildingpassport.specification.EDGE")}
            </CertificationLabel>
            <CertificationValue>{edgeDescription}</CertificationValue>
          </>
        )}
        {showQuality && quality && (
          <>
            <CertificationLabel>
              {translate("portal.buildingpassport.specification.QUALITY")}
            </CertificationLabel>
            <CertificationValue>{qualityDescription}</CertificationValue>
          </>
        )}
        {tapa && (
          <>
            <CertificationLabel>
              {translate("portal.buildingpassport.specification.TAPA")}
            </CertificationLabel>
            <CertificationValue>{tapaDescription}</CertificationValue>
          </>
        )}
      </CertificationContainer>
    </SpecificationItem>
  )
}

export default Certification
