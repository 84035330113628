import { ThemeProvider } from "styled-components"
import { theme } from "@paudigital/wdp-components"
import { Provider } from "react-redux"
import store, { history } from "./store"
import Router from "./routes/router"
import Auth0ProviderWithHistory from "providers/auth0ProviderWithHistory"
import { GlobalStyle } from "@paudigital/wdp-components"
import "reset-css"
import CurrentUserProvider from "providers/currentUserPovider"
import HistoryProvider from "providers/historyProvider"
import I18nProvider from "providers/I18nProvider"
import { FeatureFlagProvider } from "providers/featureFlagProvider"
import { HistoryRouter } from "redux-first-history/rr6";

const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Provider store={store}>
        <FeatureFlagProvider>
          <I18nProvider>
            <HistoryRouter history={history}>
              <HistoryProvider>
                <Auth0ProviderWithHistory>
                  <CurrentUserProvider>
                    <Router />
                  </CurrentUserProvider>
                </Auth0ProviderWithHistory>
              </HistoryProvider>
            </HistoryRouter>
          </I18nProvider>
        </FeatureFlagProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
