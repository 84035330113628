import DocumentCard from "app/components/DocumentCard"
import FacilityTicketCard from "app/components/FacilityTicketCard"
import { Document } from "app/types/document"
import { Invoice } from "app/types/invoice"
import { NotificationTitles } from "app/types/notification"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { ModifiedNotification } from "../../../TimeLine"
import { StyledNotification, StyledInvoiceCard } from "./style"

export type NotificationContainerProps = {
  notification: ModifiedNotification
}

type Props = NotificationContainerProps

const NotificationContainer = ({ notification }: Props): JSX.Element => {
  const navigate = useNavigate()
  const { rentedBuilding } = useRentedBuilding()

  const ticket = useMemo(() => {
    return {
      ...notification.payload,
      ticketId: notification.payload.ticketId
    }
  }, [notification.payload])

  const handleOnClick = () => {
    const link = `/facility-management/${
      rentedBuilding?.id
    }?page=1&perPage=30&sort[]=creation_date=desc&ticket=${
      ticket.sourceId as string
    }&modalOpen=${ticket.ticketId}`
    navigate(link)
  }

  const buildNoficationContent = (type: NotificationTitles) => {
    if (type === NotificationTitles.INVOICE_CREATED) {
      return <StyledInvoiceCard invoice={notification.payload as Invoice} />
    }
    if (
      type === NotificationTitles.FACILITY_TICKET_CREATED ||
      type === NotificationTitles.FACILITY_TICKET_UPDATED
    ) {
      return (
        <FacilityTicketCard
          ticket={ticket}
          onClick={handleOnClick}
          notification={true}
        />
      )
    }
    return <DocumentCard document={notification.payload as Document} />
  }

  return (
    <StyledNotification>
      {buildNoficationContent(notification.title)}
    </StyledNotification>
  )
}

export default NotificationContainer
