import { EnergyUsageComparisonData } from "app/types/energy"
import { Action, EnergyUsageQueryParams, Types } from "./energyUsageComparison.actions"

export type EnergyUsageComparisonState = {
  data: EnergyUsageComparisonData[]
  errors?: { status: number } & Record<string, unknown>
  params?: EnergyUsageQueryParams
  loading: boolean
}

const initialState: EnergyUsageComparisonState = {
  data: [],
  errors: undefined,
  loading: false,
  params: undefined
}

export default (
  state = initialState,
  action: Action
): EnergyUsageComparisonState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        data: [],
        errors: undefined,
        params: action.payload.params,
        loading: true
      }
    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }
    case Types.GET_FAIL:
      return {
        ...state,
        data: [],
        errors: action.payload.errors,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}
