import {
  NestedCheckbox,
  Data,
  changeChildValue
} from "@paudigital/wdp-components"
import * as responsibilityActions from "app/redux/responsibilities/responsibilities.actions"
import * as responsibilitySelectors from "app/redux/responsibilities/responsibilities.selectors"
import { reduceNestedCheckboxDataToResponsibilityReducerData } from "app/utils/responsibilities.utils"
import { useTranslate } from "hooks/translate"
import { useDispatch, useSelector } from "react-redux"

type MultiBuildingUserResponsibilitiesProps = {
  rentedBuildingId: string
  settingsData: Data
  collapsed: boolean
}

type Props = MultiBuildingUserResponsibilitiesProps

const MultiBuildingUserResponsibilities = ({
  rentedBuildingId,
  settingsData,
  collapsed
}: Props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const errors = useSelector(responsibilitySelectors.getErrors)
  const isAnyChildChecked = settingsData.children.some(child => child.selected)

  const translatedSettingsData = {
    parent: settingsData.parent,
    children: settingsData.children.map(currentChild => {
      return {
        label: translate(currentChild.label),
        name: currentChild.name,
        guidanceText: translate(currentChild.guidanceText ?? ""),
        selected: currentChild.selected
      }
    })
  }

  const handleChildChange = (val: boolean, name: string) => {
    const updateData = reduceNestedCheckboxDataToResponsibilityReducerData(
      rentedBuildingId,
      changeChildValue(settingsData, val, name)
    )
    dispatch(responsibilityActions.updateResponsibilities(updateData))
  }

  return (
    <NestedCheckbox
      onChildSelect={handleChildChange}
      data={translatedSettingsData}
      defaultCollapsed={collapsed}
      error={errors && !isAnyChildChecked}
    />
  )
}

export default MultiBuildingUserResponsibilities
