import styled from "styled-components"
import { theme } from "@paudigital/wdp-components"

export const StyledContainer = styled.div`
  margin: 3.2rem;
`

export const ModalTitle = styled.h1`
  font-size: ${theme.fontSizes.h4};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-bottom: 2.4rem;
  text-align: center;
`

export const StyledInfo = styled.p`
  margin: 0.8rem 0 1.6rem;
  color: ${theme.colors.neutral.darkerGrey};
  font-weight: ${theme.fontWeights.light};
  font-size: ${theme.fontSizes.captionExtraSmall};
  line-height: ${theme.fontSizes.captionSmall};
`

export const AddPhoneWrapper = styled.div`
  margin: -0.6rem 0 1.8rem;
`
