import MarkdownTranslation from "app/components/MarkdownTranslation"
import { StyledSpan, StyledStatusCard } from "./style"

const InsuranceClaimStatusCard = () => {
  return (
    <StyledStatusCard
      status="planned"
      content={
        <>
          <StyledSpan>
            <MarkdownTranslation label="portal.facilitymanagement.createticket.step2.insuranceclaim.infoMessage.1" />
          </StyledSpan>
          <StyledSpan>
            <MarkdownTranslation label="portal.facilitymanagement.createticket.step2.insuranceclaim.infoMessage.2" />
          </StyledSpan>
        </>
      }
    />
  )
}

export default InsuranceClaimStatusCard
