import { PossibleContactRoles } from "app/enums/roles"
import { DeleteContactPersonFlows } from "app/types/deleteContactPersonFlow"
import State from "../state"

export const isLoading = (state: State): boolean => state.deleteContactPerson.loading
export const hasErrors = (state: State): boolean =>
  state.deleteContactPerson.errors ? true : false
export const getDeleteFlow = (state: State): DeleteContactPersonFlows | null => state.deleteContactPerson.data.flow
export const getBuildingsCpCanBeDeletedFrom = (state: State) => state.deleteContactPerson.data.buildings.canDeleteFrom
export const getBuildingsCpCannotBeDeleteFrom = (state: State) => state.deleteContactPerson.data.buildings.cannotDeleteFrom
export const getIdOfContactPerson = (state: State): string | undefined => {
  if (!state.deleteContactPerson.data.contactPersonId) return undefined
  return state.deleteContactPerson.data.contactPersonId
}
export const getEmailOfContactPerson = (state: State): string => {
  if (!state.deleteContactPerson.data.contactPersonId) return ""
  const contactToDelete = state.deleteContactPerson.data.buildings.canDeleteFrom[0].contactPersons.find(cp => {
    return cp.id === state.deleteContactPerson.data.contactPersonId
  })
  if (!contactToDelete) return ""
  return contactToDelete.email
}
export const getBuildingLocationsCpCanBeDeletedFrom = (state: State): string => {
  return state.deleteContactPerson.data.buildings.canDeleteFrom.reduce((locations, building) => {
    return [
      ...locations,
      `${building.street} ${building.houseNo} - ${building.city}`
    ]
  }, [] as string[]).join(", ")
}
export const getBuildingAdminNamesAndEmailsFromNonManagedBuildings = (state: State): string => {
  return state.deleteContactPerson.data.buildings.cannotDeleteFrom.map(building => {
    return building.contactPersons.reduce((admins, cp) => {
      if (!cp.roles.includes(PossibleContactRoles.ADMIN)) return admins
      return [
        ...admins,
        `${cp.firstName} ${cp.lastName} (${cp.email})`
      ]
    }, [] as string[]).join(", ")
  }, [] as string[]).join(", ")
}
export const getBuildingIdsToDeleteContactPersonFrom = (state: State): string[] => {
  return state.deleteContactPerson.data.buildings.canDeleteFrom.map(building => building.id)
}
