import * as api from "app/api/contactPerson"
import { MyProfile } from "app/types/myProfile"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./me.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"
import { sub } from "date-fns"
import { olderThen } from "app/utils/olderThen.utils"
import { push } from "redux-first-history"

export function* getMeFlow(): SagaIterator {
  try {
    const response = yield call(api.getMe)
    const data: MyProfile = response.data
    const {
      rentedBuildings,
      onboardedAt,
      preferredBuilding,
      lastPasswordChange,
      groups
    } = data
    if(!groups){
      if (!onboardedAt) yield put(push("/register"))
      if (onboardedAt && (rentedBuildings.length === 0 || !preferredBuilding)) {
        yield put(actions.getMeFail(new Error("no rented buildings found")))
        yield put(push("/no-permission"))
      }
    }

    yield put(actions.getMeSuccess(response.data))

    if (
      lastPasswordChange &&
      olderThen(new Date(lastPasswordChange), sub(new Date(), { days: 90 }))
    ) {
      yield put(push("/password-change"))
    }
  } catch (e: any) {
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-access" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" },
          { expectedHttpStatus: 404, redirectTo: "/wdp-admin-not-found"},
          { expectedHttpStatus: 500, redirectTo: "/server-maintenance" }
        ]
      })
    )
    yield put(actions.getMeFail(e))
  }
}

export default function* meSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getMeFlow)
}
