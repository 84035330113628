import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, InputLabel, PlusIcon, theme } from "@paudigital/wdp-components"
import ModuleWidget from "app/components/ModuleWidget"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { Modules } from "app/enums/modules"
import { useTranslate } from "hooks/translate"
import Icon from "./icon"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"
import { useApi } from "hooks/api"
import { getTicketSummary } from "app/redux/ticket/ticket.actions"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { getSummary } from "app/redux/ticket/ticket.selectors"
import { ButtonWrapper } from "./style"
import { FacilityTicketStatus } from "app/enums/ticketStatus"
import TicketStatus from "./TicketStatusList"
import { useNavigate } from "react-router-dom"
import EmptyWidgetContainer from "app/components/EmptyWidgetContainer"
import { usePermission } from "hooks/permission"

const FacilityManagement = () => {
  const navigate = useNavigate()
  const { rentedBuilding } = useRentedBuilding()
  const { call } = useApi()
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { hasPermission } = usePermission()

  const summaryPermission =
    rentedBuilding &&
    hasPermission(Modules.FACILITY_MANAGEMENT, rentedBuilding!.id)
  const summary = useSelector(getSummary)

  const filteredStatuses = summary?.filter(
    s =>
      s.status !== FacilityTicketStatus.FINISHED || FacilityTicketStatus.CLOSED
  )
  const amount = filteredStatuses?.reduce((a, b) => a + Number(b.amount), 0)

  const handleTicketCreation = () => {
    navigate(`/facility-management/${rentedBuilding?.id}`)
    dispatch(
      fire({
        eventName: PossibleAppEvents.CREATE_TICKET,
        uniqueIdentifier: "create-ticket"
      })
    )
  }

  const handleClick = () => {
    navigate(`/facility-management/${rentedBuilding?.id}`)
  }

  useEffect(() => {
    if (rentedBuilding && summaryPermission)
      call(getTicketSummary(rentedBuilding.id))
  }, [call, rentedBuilding, summaryPermission])

  return (
    <ModuleWidget
      permissionModule={Modules.FACILITY_MANAGEMENT}
      title={translate("portal.dashboard.widget.facilitymanagement.title")}
      description={translate(
        "portal.dashboard.widget.facilitymanagement.guidance",
        {
          amount
        }
      )}
      image={<Icon />}
      url={`/facility-management/${rentedBuilding?.id}`}
    >
      <>
        <Button
          fullWidth
          label={translate(
            "portal.dashboard.widget.facilitymanagement.create.button.label"
          )}
          startIcon={<PlusIcon fill={theme.colors.neutral.white} />}
          onClick={handleTicketCreation}
        />
        <InputLabel
          label={translate(
            "portal.dashboard.widget.facilitymanagement.reports.label"
          )}
        />
        {summary?.length === 0 ? (
          <EmptyWidgetContainer>
            {translate("portal.dashboard.widget.facilitymanagement.noreports")}
          </EmptyWidgetContainer>
        ) : (
          <TicketStatus />
        )}
        <ButtonWrapper>
          <Button
            fullWidth
            label={translate(
              "portal.dashboard.widget.facilitymanagement.allreports.button.label"
            )}
            onClick={handleClick}
            type="secondary"
          />
        </ButtonWrapper>
      </>
    </ModuleWidget>
  )
}

export default FacilityManagement
