import { BaseCard, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledBaseCard = styled(BaseCard)`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${theme.colors.neutral.white};
  height: 8.3rem;
  margin: 1rem 0;

  &:hover {
    background-color: ${theme.colors.stromboliGreen.prc20};
  }
`
