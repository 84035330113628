import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledDocumentContainer = styled.div`
  margin: 0;

  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 0 1rem;
    flex: 1;
  }
`

export const StyledDocumentsContainer = styled.main`
  & > div  {
    margin: 1.2rem 0;
  }
`

export const PaginationWrapper = styled.div`
  margin: 2.4rem 0 0.6rem 0;
`
