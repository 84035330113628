import { Description, ButtonWrapper } from "./style"
import { actions } from "app/redux/registrationWizard/registrationWizard.actions"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, InputLabel, Link } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import AddColleagues from "../../../AddColleague"

const AddColleague = () => {
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const hasInvitedUsers = sessionStorage.getItem("colleaguesInvited") ?? "false"

  const handleNext = () => {
    navigate("/register/success")
  }

  const handlePrevious = () => {
    dispatch(actions.decreaseWizard())
  }

  const handleSkip = () => {
    sessionStorage.setItem("colleaguesInvited", "false")
    navigate("/register/success")
  }

  return (
    <>
      <Description dataTestId="portal.onboarding.step4.subtitle.label">
        <InputLabel
          required
          name="header"
          label={translate("portal.onboarding.step4.subtitle.label")}
          guidanceText={translate(
            "portal.onboarding.step4.subtitle.guidanceText"
          )}
        />
      </Description>
      <AddColleagues />
      <ButtonWrapper>
        <Link
          type="default"
          onClick={handlePrevious}
          value={translate("portal.onboarding.step4.button.previous")}
        />
        {hasInvitedUsers === "true" ? (
          <Button
            label={translate("portal.onboarding.step4.button.next")}
            onClick={handleNext}
          />
        ) : (
          <Button
            dataTestId="portal.onboarding.step4.button.skip"
            label={translate("portal.onboarding.step4.button.skip")}
            onClick={handleSkip}
          />
        )}
      </ButtonWrapper>
    </>
  )
}

export default AddColleague
