import { Locales, translations } from "app/i18n/config"
import { IntlProvider } from "react-intl"
import { useSelector } from "react-redux"
import { getPreferredLanguage } from "app/redux/me/me.selectors"

type Props = {
  children: JSX.Element
}

const I18nProvider = ({ children }: Props) => {
  const language = useSelector(getPreferredLanguage)

  return (
    <IntlProvider
      messages={translations[language as Locales]}
      locale={language}
    >
      {children}
    </IntlProvider>
  )
}

export default I18nProvider
