import { LegendItem } from "app/components/Legend"
import {
  waterConsumptionAreaSplineSerieOptions,
  getWaterConsumptionChartOptions,
  waterConsumptionStackedBarSerieOptions
} from "./waterConsumptionGraphOptions"
import {
  gasConsumptionAreaSplineSerieOptions,
  getGasConsumptionChartOptions,
  gasConsumptionStackedBarSerieOptions
} from "./gasConsumptionGraphOptions"
import { getBaseGraphOptions, generateXAxisLabel } from "./baseGraphOptions"
import {
  electricityConsumptionAreaSplineSerieOptions,
  getElectricityConsumptionChartOptions,
  electricityConsumptionStackedBarSerieOptions
} from "./electricityConsumptionGraphOptions"
import { MeterHistoryData } from "app/types/energy"
import {
  solarProductionAreaSplineSerieOptions,
  getSolarProductionChartOptions,
  solarProductionStackedBarSerieOptions
} from "./solarProductionGraphOptions"
import { intl, Locales } from "app/i18n/config"
import { IntervalEnum } from "app/enums/interval"
import { parse } from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"

export enum Graphs {
  WATER_CONSUMPTION = "waterConsumption",
  GAS_CONSUMPTION = "gasConsumption",
  ELECTRICITY_CONSUMPTION = "electricityConsumption",
  SOLAR_PRODUCTION = "solarProduction"
}

export enum SerieTypes {
  WATER_CONSUMPTION = "waterConsumption",
  GAS_CONSUMPTION = "gasConsumption",
  ELECTRICITY_CONSUMPTION = "electricityConsumption"
}

export type GraphOptions = {
  legendItems: LegendItem[]
  graphOptions: any
}

enum GraphSerieTypes {
  AREA_SPLINE = "areaspline",
  COLUMN = "column"
}

const graphSerieOptions = {
  [Graphs.ELECTRICITY_CONSUMPTION]: [
    electricityConsumptionStackedBarSerieOptions,
    electricityConsumptionAreaSplineSerieOptions
  ],
  [Graphs.WATER_CONSUMPTION]: [
    waterConsumptionStackedBarSerieOptions,
    waterConsumptionAreaSplineSerieOptions
  ],
  [Graphs.GAS_CONSUMPTION]: [
    gasConsumptionStackedBarSerieOptions,
    gasConsumptionAreaSplineSerieOptions
  ],
  [Graphs.SOLAR_PRODUCTION]: [
    solarProductionStackedBarSerieOptions,
    solarProductionAreaSplineSerieOptions
  ]
}

const formatUnixTimestampToDate = (unixTimestamp: string) => {
  const parsedDate = utcToZonedTime(
    parse(unixTimestamp, "t", new Date()),
    "UTC"
  )
  return format(parsedDate, "dd/MM/yyyy", { timeZone: "UTC" })
}

const formatUnixTimestampToTime = (unixTimestamp: string) => {
  const parsedDate = utcToZonedTime(
    parse(unixTimestamp, "t", new Date()),
    "UTC"
  )
  return format(parsedDate, "HH:mm", { timeZone: "UTC" })
}

const intervalValueMapping = {
  [IntervalEnum.MIN]: 60,
  [IntervalEnum.FIFTEENMIN]: 60 * 15,
  [IntervalEnum.HOUR]: 3600,
  [IntervalEnum.DAY]: 3600 * 24,
  [IntervalEnum.WEEK]: 3600 * 24 * 7,
  [IntervalEnum.MONTH]: 3600 * 24 * 30
}

const xAxisLabelIntervalMapping = {
  [IntervalEnum.MIN]: function () {
    const self: any = this
    const time = formatUnixTimestampToTime(self.value)
    return generateXAxisLabel(time)
  },
  [IntervalEnum.FIFTEENMIN]: function () {
    const self: any = this
    const time = formatUnixTimestampToTime(self.value)
    return generateXAxisLabel(time)
  },
  [IntervalEnum.HOUR]: function () {
    const self: any = this
    const date = formatUnixTimestampToDate(self.value)
    const time = formatUnixTimestampToTime(self.value)
    return generateXAxisLabel(`${date} ${time}`)
  },
  [IntervalEnum.DAY]: function () {
    const self: any = this
    const date = formatUnixTimestampToDate(self.value)
    return generateXAxisLabel(date)
  },
  [IntervalEnum.WEEK]: function () {
    const self: any = this
    const date = formatUnixTimestampToDate(self.value)
    return generateXAxisLabel(date)
  },
  [IntervalEnum.MONTH]: function () {
    const self: any = this
    const date = formatUnixTimestampToDate(self.value)
    return generateXAxisLabel(date)
  }
}

const getGraphOptions = (graph: Graphs, language: Locales): any => {
  switch(graph) {
    case Graphs.ELECTRICITY_CONSUMPTION: return getElectricityConsumptionChartOptions(language)
    case Graphs.WATER_CONSUMPTION: return getWaterConsumptionChartOptions(language)
    case Graphs.GAS_CONSUMPTION: return getGasConsumptionChartOptions(language)
    case Graphs.SOLAR_PRODUCTION: return getSolarProductionChartOptions(language)
  }
}

const getGraphSerieTypeOptions = (
  graph: Graphs,
  graphSerieType: GraphSerieTypes
): any[] => {
  const [stackedBarOptions, areaSplineOptions] = graphSerieOptions[graph]

  switch (graphSerieType) {
    case GraphSerieTypes.COLUMN:
      return stackedBarOptions
    case GraphSerieTypes.AREA_SPLINE:
    default:
      return areaSplineOptions
  }
}

const determineGraphSerieType = (
  serieData: MeterHistoryData[],
  graphWidth: number,
  graphInterval: IntervalEnum
): GraphSerieTypes => {
  if (intervalValueMapping[graphInterval] <= intervalValueMapping[IntervalEnum.DAY]) return GraphSerieTypes.AREA_SPLINE
  return graphWidth / serieData[0].data.length >= 20
    ? GraphSerieTypes.COLUMN
    : GraphSerieTypes.AREA_SPLINE
}

const buildGraphSerieOptions = (
  graph: Graphs,
  meterHistoryData: MeterHistoryData[],
  graphSerieType: GraphSerieTypes,
  language: Locales
): any => {
  const graphOptions = getGraphOptions(graph, language)
  const newOptions = {
    ...getBaseGraphOptions(language),
    ...graphOptions
  }
  newOptions.series = getGraphSerieTypeOptions(graph, graphSerieType).reduce(
    (reducedOptions: any, serieOption: any) => {
      const meterHistory = meterHistoryData.find(
        meterHistory => meterHistory.name === serieOption.name
      )
      if (!meterHistory) return reducedOptions
      serieOption.data = meterHistory.data
      return [...reducedOptions, serieOption]
    },
    []
  )

  return newOptions
}

const buildGraphLegendItems = (graphSerieData: any[], preferredLanguage: Locales): LegendItem[] => {
  const lineLegends = ["electricityConsumption", "gasConsumption", "waterConsumption"]
  return graphSerieData
    .sort((a: any, b: any) => {
      if (
        a.stack !== null &&
        a.stack !== undefined &&
        b.stack !== null &&
        b.stack !== undefined
      ) {
        if (a.stack < b.stack) return -1
        else if (a.stack > b.stack) return 1
        else return 0
      }
      return 0
    })
    .map((serieData: any) => {
      return {
        color: serieData.color,
        type: lineLegends.includes(serieData.name) ? "line" : "circle",
        name: intl[preferredLanguage].formatMessage({
          id: `portal.energy.graphs.series.${serieData.name}`
        })
      } as LegendItem
    })
}

export const buildGraphOptions = (
  graph: Graphs,
  serieData: MeterHistoryData[] | undefined,
  graphWidth: number,
  graphInterval: IntervalEnum,
  preferredLanguage: Locales
): GraphOptions => {
  const options: GraphOptions = {
    legendItems: [],
    graphOptions: getBaseGraphOptions(preferredLanguage)
  }
  if (serieData === undefined || serieData.length === 0) return options
  const graphSerieType = determineGraphSerieType(serieData, graphWidth, graphInterval)
  options.graphOptions = buildGraphSerieOptions(
    graph,
    serieData,
    graphSerieType,
    preferredLanguage
  )
  options.graphOptions.xAxis.tickInterval = intervalValueMapping[graphInterval]
  options.graphOptions.xAxis.labels.formatter = xAxisLabelIntervalMapping[graphInterval]
  options.graphOptions.yAxis = showZeroLineOnBottomIfOnlyZeroValues(options, serieData)
  options.legendItems = buildGraphLegendItems(options.graphOptions.series, preferredLanguage)
  return options
}

const showZeroLineOnBottomIfOnlyZeroValues = (
  options: GraphOptions,
  serieData: MeterHistoryData[] | undefined
) => {
  if (!serieData) return options.graphOptions.yAxis
  const onlyZeros = !serieData.some(serieData =>
    serieData.data.some(([_, value]) => value && value !== 0)
  )
  return onlyZeros
    ? {
        ...options.graphOptions.yAxis,
        min: 0,
        minRange: 1
      }
    : options.graphOptions.yAxis
}