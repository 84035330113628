import { StyledFilterTags } from "./style"
import { FilterTypes } from ".."
import Tag from "./Tag"
import { useTranslate } from "hooks/translate"
import { InvoiceStatusTypes, InvoiceType } from "app/types/invoice"

export type FilterTagsProps = {
  filters: FilterTypes
  setUrlParams: (params: any) => void
}

export type FilterTag = {
  filterType: keyof FilterTypes
  text: string
  code?: string
}

type Props = FilterTagsProps

const FilterTags = ({ filters, setUrlParams }: Props) => {
  const { translate } = useTranslate()

  const mobileFilterBase = "portal.invoices.filter.mobile."

  const convertInvoiceTypeToText = (invoiceType: InvoiceType[]) =>
    invoiceType.map(it => ({
      text: translate(`${mobileFilterBase}type.${it}`),
      code: it
    }))

  const convertInvoiceStatusToText = (statuses: InvoiceStatusTypes[]) =>
    statuses.map(it => ({
      text: translate(`portal.invoices.filter.status.${it}`),
      code: it
    }))

  const reorderFilters = (a: string, b: string) => {
    if (
      a.search("from") === 0 ||
      (a.search("to") === 0 && b.search("from") !== 0) ||
      a < b
    )
      return -1
    if (a > b) return 1
    return 0
  }

  const remove = (filters: FilterTypes, tag: FilterTag) => {
    if (tag.filterType === "invoiceType") {
      return {
        ...filters,
        invoiceType: [...filters.invoiceType!.filter(s => s !== tag.code)]
      }
    }
    if (tag.filterType === "status") {
      return {
        ...filters,
        status: [...filters.status!.filter(s => s !== tag.code)]
      }
    }
    delete filters[tag.filterType]
    return filters
  }

  const tags = Object.entries(filters)
    .sort((a, b) => reorderFilters(a[0], b[0]))
    .map((f): FilterTag | FilterTag[] | undefined => {
      if (!f[1]) return undefined
      else {
        switch (f[0]) {
          case "sort":
            return undefined
          case "invoiceType":
            const invoiceTypes = convertInvoiceTypeToText(
              (f[1] as unknown) as InvoiceType[]
            )
            return invoiceTypes.map(it => ({
              filterType: f[0] as keyof FilterTypes,
              text: it.text,
              code: it.code
            }))
          case "status":
            const statuses = convertInvoiceStatusToText(
              (f[1] as unknown) as InvoiceStatusTypes[]
            )
            return statuses.map(it => ({
              filterType: f[0] as keyof FilterTypes,
              text: it.text,
              code: it.code
            }))
          case "contractNumber":
          case "invoicePeriodTo":
          case "invoicePeriodFrom":
          case "invoiceDateTo":
          case "invoiceDateFrom":
          case "invoiceNumber":
            return {
              filterType: f[0] as keyof FilterTypes,
              text: `${translate(mobileFilterBase + f[0])}: ${f[1] as string}`
            }
          default:
            return {
              filterType: f[0] as keyof FilterTypes,
              text: f[1] as string
            }
        }
      }
    })
    .flat()

  const removeTag = (tag: FilterTag) => {
    const newFilters = remove(filters, tag)

    setUrlParams({
      ...newFilters,
      page: 1,
      perPage: 30
    })
  }

  return (
    <>
      {tags.length > 0 && (
        <StyledFilterTags>
          {tags.map(
            (tag, i) => tag && <Tag key={i} tag={tag} remove={removeTag} />
          )}
        </StyledFilterTags>
      )}
    </>
  )
}

export default FilterTags
