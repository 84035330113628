import { InvoiceStatusTypes } from "app/types/invoice"
import Status from "./Status"
import { Filters } from "routes/Invoices"

type Props = {
  setUrlParams: (params: any) => void
} & Filters

const StatusFilter = ({
  page,
  perPage,
  invoiceType,
  contractNumber,
  invoicePeriodTo,
  invoicePeriodFrom,
  invoiceDateTo,
  invoiceDateFrom,
  invoiceNumber,
  reference,
  sort,
  status,
  setUrlParams
}: Props) => {
  const selectedFilterStatus = status ? status : []
  const queryParams = {
    page,
    perPage,
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort
  }

  const statuses = Object.values(InvoiceStatusTypes).map(s => ({
    checked: selectedFilterStatus.includes(s),
    status: s
  }))

  const clickHandler = (status: InvoiceStatusTypes) => {
    if (selectedFilterStatus.includes(status)) {
      const filteredStatuses = selectedFilterStatus.filter(it => it !== status)
      setUrlParams({
        ...queryParams,
        status: filteredStatuses
      })
    } else {
      setUrlParams({
        ...queryParams,
        page: 1,
        status: [...selectedFilterStatus, status]
      })
    }
  }

  return (
    <>
      {statuses?.map((filterStatus, i) => (
        <Status
          key={`${i}_${filterStatus.status}`}
          checked={filterStatus.checked}
          status={filterStatus.status}
          onClick={clickHandler}
        />
      ))}
    </>
  )
}
export default StatusFilter
