import { Page, Typography, NotFound } from "@paudigital/wdp-components"
import { useLogout } from "hooks/logout"
import { useTranslate } from "hooks/translate"
import { Container, StyledLink, StyledSpan } from "./style"

const AdminNotFoundPage = () => {
  const { translate } = useTranslate()
  const { logout } = useLogout()

  const handleClick = () => {
    logout()
  }
  
  return (
    <Page image={<NotFound />}>
      <Container>
        <Typography variant="h5" as="h1" fontStyle="bold" textAlign="center">
          {translate("portal.route.title.admin.not.found")}
        </Typography>
        <StyledSpan>{translate("portal.route.title.admin.not.found.description")}</StyledSpan>
        <StyledLink
          onClick={handleClick}
          type="default"
          value={translate("portal.noaccess.logout.label")}
        />
    </Container>
</Page>)
}

export default AdminNotFoundPage