import { theme } from "@paudigital/wdp-components"
import { StepEnum } from "app/types/pvProject"
import styled from "styled-components"

export const StepContainer = styled.div.attrs(
  (props: { first: boolean; last: boolean; status: StepEnum }) => props
)`
  background: ${props => {
    switch (props.status) {
      case StepEnum.COMPLETED:
        return `${theme.colors.pistachio.prc100}`
      case StepEnum.IN_PROGRESS:
        return `
          ${theme.colors.orange.prc100}
        `
      case StepEnum.PLANNED:
        return `
          ${theme.colors.neutral.lightGrey}
        `
    }
  }};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin-right: 1.5rem;
  width: 175px;
  height: 64px;
  border-radius: 2px;
  margin-top: 1rem;

  ${(props: { first: boolean; last: boolean; status: StepEnum }) =>
    !props.last
      ? `&:after {
    content: "";
    position: absolute;
    left: 175px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 16px solid ${
      props.status === StepEnum.COMPLETED
        ? `${theme.colors.pistachio.prc100};`
        : props.status === StepEnum.IN_PROGRESS
        ? `${theme.colors.orange.prc100};`
        : `${theme.colors.neutral.lightGrey};`
    }
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
  }`
      : null}

  ${props =>
    !props.first
      ? `&:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 16px solid white;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
  }`
      : null}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StepTitle = styled.h5.attrs((props: { first: boolean }) => props)`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  padding: 0.6rem 0 0 2.4rem;

  ${props =>
    props.first
      ? `
  padding-left: 1rem;
  `
      : ``}
`

export const DateRange = styled.p``

export const Dates = styled.div`
  margin-top: 0.6rem;
  color: ${theme.colors.neutral.darkGrey};
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
`
