import styled from "styled-components"
import { Button, theme } from "@paudigital/wdp-components"

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 0 auto;
`

export const Styledtitle = styled.h1`
  margin-bottom: 1.2rem;
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.black};
  text-align: center;
`

export const StyledBody = styled.p`
  color: ${theme.colors.neutral.darkerGrey};
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.light};
  text-align: center;
`

export const StyledButton = styled(Button)`
  margin-top: 4rem;
`