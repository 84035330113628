import { AssignedPerson } from "app/types/assignedPerson"
import { Action, Types } from "./rentedBuildingContactPerson.actions"

export type RentedBuildingContactPersonsState = {
  data: AssignedPerson[]
  errors?: Record<string, unknown>
  loading: boolean
}

const initialState: RentedBuildingContactPersonsState = {
  data: [],
  errors: undefined,
  loading: false
}

export default (
  state = initialState,
  action: Action
): RentedBuildingContactPersonsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.PUT:
      return {
        ...state,
        loading: true
      }

    case Types.PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: undefined
      }

    case Types.PUT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: undefined
      }

    case Types.POST_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.DELETE:
      return {
        ...state,
        loading: true
      }

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data.filter(d => d.id !== action.payload.id)
        },
        loading: false
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      }

    case Types.CLEAR:
      return {
        ...state
      }

    default:
      return state
  }
}
