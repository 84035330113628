import { Locales } from "app/i18n/config"
import { RentedBuilding } from "./rentedBuilding"

export type Company = {
  id: string
  name?: string
  allowedDomains: string[]
}

export type MyProfile = {
  id: string
  sourceId: string
  profilePictureUrl: string
  firstName: string
  lastName: string
  function: string
  email: string
  telephone: string
  mobilephone: string
  languagePreference: Locales
  preferredBuilding: string
  onboardedAt?: string
  companies: Company[]
  termsAndConditionsAccepted?: string
  privacyPolicyAccepted?: string
  rentedBuildings: RentedBuilding[]
  lastPasswordChange?: string
  monthlyNewsletter?: boolean
  mfaEnrollments: MfaEnrollment[]
  receiveSurveys?: boolean
  groups?: string[]
}

export type MfaEnrollment = {
  id: string
  type: MfaType
  confirmed: boolean
  created_at: string
  name?: string
  last_auth_at: string
}

export enum MfaType {
  OTP = "totp",
  SMS = "sms"
}
