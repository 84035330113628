import { useTranslate } from "hooks/translate"
import { TopBar, Wrapper, StyledTotalTickets, TitleWrapper } from "./style"
import Sort from "../../InvoiceFilter/Sort"
import { getTotal, loading } from "app/redux/invoices/invoices.selectors"
import { useDispatch, useSelector } from "react-redux"
import {
  Spinner,
  Button,
  DownloadIcon,
  theme
} from "@paudigital/wdp-components"
import { downloadInvoiceCSV } from "app/redux/invoices/invoices.actions"
import { Filters } from "routes/Invoices"
import { useApi } from "hooks/api"
import {
  createGAEvent,
  PossibleGAEvents
} from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  setUrlParams: (params: any) => void
} & Filters

const DesktopTopBar = ({
  page,
  perPage,
  invoiceType,
  contractNumber,
  invoicePeriodTo,
  invoicePeriodFrom,
  invoiceDateTo,
  invoiceDateFrom,
  invoiceNumber,
  reference,
  sort,
  status,
  setUrlParams
}: Props) => {
  const { call } = useApi()
  const { translate } = useTranslate()
  const total = useSelector(getTotal)
  const isLoading = useSelector(loading)
  const dispatch = useDispatch()

  const queryParams = {
    page,
    perPage,
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort,
    status
  }

  const isFiltered =
    invoiceType ||
    contractNumber ||
    invoicePeriodFrom ||
    invoicePeriodTo ||
    invoiceDateTo ||
    invoiceDateFrom ||
    invoiceNumber ||
    reference ||
    status

  const handleDownload = () => {
    dispatch(createGAEvent(PossibleGAEvents.DOWNLOAD_OVERVIEW))
    call(downloadInvoiceCSV(queryParams))
  }

  return (
    <TopBar>
      <Wrapper>
        <TitleWrapper>
          {isLoading && <Spinner />}
          <StyledTotalTickets loading={isLoading}>
            {isFiltered
              ? translate(
                  `portal.invoices.total${isLoading ? ".loading" : ""}`,
                  {
                    total
                  }
                )
              : translate("portal.invoices.total.all", { total })}
          </StyledTotalTickets>
        </TitleWrapper>
        <Button
          type="secondary"
          fullWidth
          label={translate("portal.invoices.download.button.label")}
          startIcon={
            <DownloadIcon
              height="3rem"
              width="2.5rem"
              fill={theme.colors.green.contrast}
            />
          }
          onClick={handleDownload}
        />
      </Wrapper>
      <Sort setUrlParams={setUrlParams} {...queryParams} />
    </TopBar>
  )
}

export default DesktopTopBar
