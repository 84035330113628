import {
  Button,
  InputElement,
  InputLabel,
  Radio,
  RadioGroup,
  FileUpload
} from "@paudigital/wdp-components"
import { AppointmentWrapper, ButtonWrapper, FileUploadWrapper } from "./style"
import { getTicket } from "app/redux/ticket/ticket.selectors"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "app/redux/ticketWizard/ticketWizard.actions"
import {
  hideLocationInput,
  validationSchema,
  makeLocationNotRequired
} from "./util"
import { setTicketField, setTicket } from "app/redux/ticket/ticket.actions"
import { useFormik } from "formik"
import { AnswerCodeIds, PostTicket, TicketTypes } from "app/types/ticket"
import { useTranslate } from "hooks/translate"
import { formatStringNumber } from "app/utils/numberFormatter.utils"
import { getCategoryByType } from "app/redux/ticketCategories/ticketCategories.selectors"
import InsuranceClaimStatusCard from "./InsuranceClaimStatusCard"
import FileUploadGuidance from "./FileUploadGuidance"

const ProblemDetails = () => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const ticket = useSelector(getTicket)
  const { type, answerCodes, files } = ticket
  const showNumberOfLightbulbsInput = answerCodes?.find(
    a => a.id === "_WDP_ELEKTRICITEIT" && (a.value === "01" || a.value === "02")
  )
  const category = useSelector(getCategoryByType(type!))
  const showLocationInput = !hideLocationInput(answerCodes)
  const showAppointmentInput = category?.customerContact
  const showGateNumberInput = type === "_WDP_02"
  const locationOptional = makeLocationNotRequired(answerCodes)

  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    setFieldValue,
    handleBlur,
    setTouched,
    touched,
    setFieldTouched
  } = useFormik({
    initialValues: { ...ticket, problem: ticket.problem ?? "" },
    onSubmit: () => {
      if (isValid) dispatch(actions.increaseWizard())
    },
    validationSchema
  })

  const handleClick = (name: string) => {
    const value = name === "true"
    dispatch(setTicketField("priority", value))
    setFieldValue("priority", value)
    if (value) {
      setTouched({ ...touched, explanationPriority: false }, false)
      setFieldValue("explanationPriority", "", false)
      dispatch(setTicketField("explanationPriority", undefined))
    }
  }

  const hasErrors = (name: string): boolean =>
    errors[name as keyof Partial<PostTicket>] &&
    touched[name as keyof Partial<PostTicket>]
      ? true
      : false

  const handleSetContact = (name: string) => {
    dispatch(setTicketField("customerContact", name === "true"))
    setFieldValue("customerContact", name === "true")
  }

  const handleInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value: val, name } = e.currentTarget
    handleChange(e)
    dispatch(setTicketField(name as keyof PostTicket, val))
  }

  const handleAnswerCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: val, name } = e.currentTarget
    const filteredAnswerCodes = answerCodes!.filter(a => a.id !== name) || []
    const newAnswerCodes = [
      ...filteredAnswerCodes,
      {
        id: name,
        value: val
      }
    ]
    setFieldValue("answerCodes", newAnswerCodes)
    dispatch(setTicketField("answerCodes", newAnswerCodes))
  }

  const handleLightBulbAnswerCodeBlur = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: val } = e.currentTarget
    const { name } = e.target
    const formattedValue = formatStringNumber(val)
    const filteredAnswerCodes = answerCodes!.filter(a => a.id !== name) || []
    if (formattedValue === undefined || formattedValue <= 0) {
      dispatch(setTicketField("answerCodes", filteredAnswerCodes))
    } else {
      const newAnswerCodes = [
        ...filteredAnswerCodes,
        {
          id: name,
          value: formattedValue.toString()
        }
      ]
      dispatch(setTicketField("answerCodes", newAnswerCodes))
    }
    setFieldTouched("answerCodes", true)
  }

  const handleLightBulbAnswerChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: val, name } = e.currentTarget
    const formattedValue = formatStringNumber(val)
    const filteredAnswerCodes = answerCodes!.filter(a => a.id !== name) || []
    if (formattedValue === undefined || formattedValue <= 0) {
      setFieldValue("answerCodes", filteredAnswerCodes)
      dispatch(setTicketField("answerCodes", filteredAnswerCodes))
    } else {
      const newAnswerCodes = [
        ...filteredAnswerCodes,
        {
          id: name,
          value: formattedValue.toString()
        }
      ]
      setFieldValue("answerCodes", newAnswerCodes)
      dispatch(setTicketField("answerCodes", newAnswerCodes))
    }
  }

  const lightbulbsValue = values.answerCodes?.find(
    a => a.id === AnswerCodeIds.WDP_ELEKTRICITEIT2
  )
  const gatesValue = values.answerCodes?.find(
    a => a.id === AnswerCodeIds.WDP_POORT_ETC2
  )

  const handlePrevious = () => {
    dispatch(
      setTicket({
        priority: false,
        customerContact: false,
        problem: undefined,
        answerCodes: [],
        type: answerCodes ? undefined : ticket.type
      })
    )
    dispatch(actions.decreaseWizard())
  }

  const handleFileUpload = (files: File[]) => {
    dispatch(setTicketField("files", files))
  }

  const handleCustomBlur = (e: React.FocusEvent<any>) => {
    dispatch(setTicketField(e.target.name, e.currentTarget.value))
    setFieldValue(e.target.name, e.currentTarget.value)
    handleBlur(e)
  }

  const handleAnswerCodeBlur = (e: React.FocusEvent<any>) => {
    const { value: val } = e.currentTarget
    const { name } = e.target
    const filteredAnswerCodes = answerCodes!.filter(a => a.id !== name) || []
    const newAnswerCodes = [
      ...filteredAnswerCodes,
      {
        id: name,
        value: val
      }
    ]
    setFieldValue("answerCodes", newAnswerCodes)
    dispatch(setTicketField("answerCodes", newAnswerCodes))
    handleBlur(e)
  }

  return (
    <>
      {type === TicketTypes.WDP_12 && <InsuranceClaimStatusCard />}
      <form onSubmit={handleSubmit}>
        {showGateNumberInput && (
          <InputElement
            label={translate(
              "portal.facilitymanagement.createticket.step2.gatenumber.label"
            )}
            name={AnswerCodeIds.WDP_POORT_ETC2}
            onBlur={handleAnswerCodeBlur}
            onChange={handleAnswerCodeChange}
            value={gatesValue?.value}
            error={hasErrors("answerCodes")}
            errorMessage={
              hasErrors("answerCodes") &&
              translate(
                "portal.facilitymanagement.createticket.step2.gatenumber.error.required"
              )
            }
            dataTestId="portal.facilitymanagement.createticket.step2.gatenumber"
          />
        )}
        {showNumberOfLightbulbsInput && (
          <InputElement
            type="number"
            name={AnswerCodeIds.WDP_ELEKTRICITEIT2}
            label={translate(
              "portal.facilitymanagement.createticket.step2.lightbulbs.label"
            )}
            optional
            optionalText={translate("portal.input.optional")}
            required={false}
            value={lightbulbsValue?.value}
            error={hasErrors("answerCodes")}
            errorMessage={
              hasErrors("answerCodes") &&
              translate(
                "portal.facilitymanagement.createticket.step2.lightbulbs.error.number"
              )
            }
            onChange={handleLightBulbAnswerChange}
            onBlur={handleLightBulbAnswerCodeBlur}
            dataTestId="portal.facilitymanagement.createticket.step2.lightbulbs"
          />
        )}
        {showLocationInput && (
          <InputElement
            label={translate(
              "portal.facilitymanagement.createticket.step2.location.label"
            )}
            charCount
            charLimit={30}
            name="problem"
            value={values.problem}
            charTranslation={translate(
              "portal.facilitymanagement.createticket.step2.location.characters.label"
            )}
            optionalText={
              locationOptional ? translate("portal.input.optional") : undefined
            }
            required={!locationOptional}
            onChange={handleInputChange}
            onBlur={handleCustomBlur}
            error={hasErrors("problem")}
            errorMessage={hasErrors("problem") && translate(errors.problem!)}
            dataTestId="portal.facilitymanagement.createticket.step2.location"
          />
        )}
        {showAppointmentInput && (
          <AppointmentWrapper>
            <InputLabel
              label={translate(
                "portal.facilitymanagement.createticket.step2.contact.label"
              )}
            />
            <RadioGroup contentCentered={false}>
              <div data-testid="portal.facilitymanagement.createticket.step2.contact.option.yes">
                <Radio
                  name="true"
                  label={translate(
                    "portal.facilitymanagement.createticket.step2.contact.option.yes"
                  )}
                  checked={values.customerContact ? true : false}
                  onClick={handleSetContact}
                />
              </div>
              <div data-testid="portal.facilitymanagement.createticket.step2.contact.option.no">
                <Radio
                  name="false"
                  label={translate(
                    "portal.facilitymanagement.createticket.step2.contact.option.no"
                  )}
                  checked={values.customerContact ? false : true}
                  onClick={handleSetContact}
                />
              </div>
            </RadioGroup>
          </AppointmentWrapper>
        )}
        <InputLabel
          label={translate(
            "portal.facilitymanagement.createticket.step2.urgent.label"
          )}
        />
        <RadioGroup contentCentered={false}>
          <div data-testid="portal.facilitymanagement.createticket.step2.urgent.option.yes">
            <Radio
              name="true"
              label={translate(
                "portal.facilitymanagement.createticket.step2.urgent.option.yes"
              )}
              checked={values.priority ? true : false}
              onClick={handleClick}
            />
          </div>
          <div data-testid="portal.facilitymanagement.createticket.step2.urgent.option.no">
            <Radio
              name="false"
              label={translate(
                "portal.facilitymanagement.createticket.step2.urgent.option.no"
              )}
              checked={values.priority ? false : true}
              onClick={handleClick}
            />
          </div>
        </RadioGroup>
        {values.priority && (
          <InputElement
            label={translate(
              "portal.facilitymanagement.createticket.step2.urgentexplanation.label"
            )}
            charCount
            charLimit={100}
            name="explanationPriority"
            value={values.explanationPriority}
            charTranslation={translate(
              "portal.facilitymanagement.createticket.step2.location.characters.label"
            )}
            error={hasErrors("explanationPriority")}
            errorMessage={
              errors.explanationPriority &&
              translate(errors.explanationPriority)
            }
            onChange={handleInputChange}
            onBlur={handleCustomBlur}
            dataTestId="portal.facilitymanagement.createticket.step2.urgentexplanation"
          />
        )}
        <FileUploadWrapper>
          <FileUpload
            initialValue={files || []}
            label={translate(
              "portal.facilitymanagement.createticket.step2.upload.label"
            )}
            guidanceText={
              type === TicketTypes.WDP_12 ? (
                <FileUploadGuidance />
              ) : (
                translate(
                  "portal.facilitymanagement.createticket.step2.upload.guidance.helpText.6"
                )
              )
            }
            optionalText={translate(
              "portal.facilitymanagement.createticket.step2.upload.optional"
            )}
            optional
            required={false}
            onChange={handleFileUpload}
            inputLimit={15}
            placeholder={translate(
              "portal.facilitymanagement.createticket.step2.upload.placeholder"
            )}
            inputCountTranslation={translate(
              "portal.facilitymanagement.createticket.step2.upload.uploads"
            )}
            fileTypeErrorTranslation={translate(
              "portal.facilitymanagement.createticket.step2.upload.error.type"
            )}
            fileLimitErrorTranslation={translate(
              "portal.facilitymanagement.createticket.step2.upload.error.limit"
            )}
            fileSizeErrorTranslation={translate(
              "portal.facilitymanagement.createticket.step2.upload.error.size"
            )}
          />
        </FileUploadWrapper>
        <ButtonWrapper>
          <Button
            label={translate(
              "portal.facilitymanagement.createticket.step2.button.previous"
            )}
            type="secondary"
            onClick={handlePrevious}
          />
          <Button
            label={translate(
              "portal.facilitymanagement.createticket.step2.button.next"
            )}
            submit
            dataTestId="portal.facilitymanagement.createticket.step2.button.next"
          />
        </ButtonWrapper>
      </form>
    </>
  )
}

export default ProblemDetails
