import { AppEventMapping, PossibleAppEvents } from "./types"
import AccountCreatedPopUp from "../AccountCreatedPopUp"
import { Notification } from "@paudigital/wdp-components"
import { AccountDeletedPopUp } from "../AccountDeletedPopUp"
import DeleteContactPerson from "../DeleteContactPerson"
import CloseReport from "../DeleteReport"
import ExpertPopUp from "../ExpertPopUp"
import TicketCreatedPopup from "../TicketCreatedPopup"
import BuildingSwitcher from "../BuildingSwitcher"
import ReportDetail from "../ReportDetail"
import TicketCommunicationPopup from "../TicketCommunicationPopup"
import TicketFeedbackPopup from "../TicketFeedbackPopup"
import EnergyIncompleteDataNotification from "../EnergyIncompleteDataNotification"
import InvoiceDetailPopUp from "../InvoiceDetailPopUp"
import CreateTicket from "../CreateTicket"
import CreateDoumentPopup from "../CreateDoumentPopUp"
import DocumentCreatedPopup from "../DocumentCreatedPopup"
import PasswordUpdatePopup from "../PasswordUpdatePopup"
import EditPassword from "../Profile/Account/EditPasswordPopUp"
import EditContact from "../Profile/Account/EditContactPopup"
import EditProfile from "../Profile/Account/EditProfilePopUp"
import DetailUpdatePopup from "../DetailUpdatePopup"
import AddColleagueToBuildingPopUp from "../AddColleagueToBuildingPopUp"
import CustomPeriod from "../EnergyPeriod/CustomPeriod"
import EnergyCSVDownload from "../EnergyCSVDownload"
import MfaOtpSetupPopUp from "../Profile/Account/MfaOtpSetupPopUp"
import SecuritySettingsSavedPopUp from "../Profile/Account/SecuritySettingsSavedPopUp"
import MfaOtpDeletePopUp from "../Profile/Account/MfaOtpDeletePopUp"
import DeleteOtpMfa from "../Profile/Account/MfaOtpDeletePopUp/Delete"
import ResendInviteModal from "../ResendInviteModal"
import { CancelTicketConfirmation } from "../CancelTicketConfirmation"
import { TicketCancelledModal } from "../CancelTicketConfirmation/TicketCancelledModal"
import { DocumentLoadingPopUp } from "../DocumentLoadingPopUp"

export const appEventMappings: AppEventMapping[] = [
  {
    component: AccountCreatedPopUp,
    event: PossibleAppEvents.ACCOUNT_CREATED
  },
  {
    component: AccountDeletedPopUp,
    event: PossibleAppEvents.ACCOUNT_DELETED
  },
  {
    component: Notification,
    event: PossibleAppEvents.ERROR
  },
  {
    component: DeleteContactPerson,
    event: PossibleAppEvents.DELETE_CONTACT_PERSON
  },
  {
    component: CloseReport,
    event: PossibleAppEvents.CLOSE_REPORT
  },
  {
    component: CreateTicket,
    event: PossibleAppEvents.CREATE_TICKET
  },
  {
    component: ExpertPopUp,
    event: PossibleAppEvents.SHOW_EXPERT
  },
  { component: TicketCreatedPopup, event: PossibleAppEvents.TICKET_CREATED },
  { component: BuildingSwitcher, event: PossibleAppEvents.BUILDINGSWITCHER },
  { component: ReportDetail, event: PossibleAppEvents.REPORT_DETAIL },
  {
    component: TicketCommunicationPopup,
    event: PossibleAppEvents.TICKET_COMMUNICATION
  },
  {
    component: TicketFeedbackPopup,
    event: PossibleAppEvents.TICKET_FEEDBACK
  },
  {
    component: EnergyIncompleteDataNotification,
    event: PossibleAppEvents.ENERGY_DATA_INCOMPLETE_NOTIFICATION
  },
  {
    component: InvoiceDetailPopUp,
    event: PossibleAppEvents.INVOICE_DETAIL
  },
  {
    component: CreateDoumentPopup,
    event: PossibleAppEvents.CREATE_DOCUMENT
  },
  {
    component: DocumentCreatedPopup,
    event: PossibleAppEvents.DOCUMENT_CREATED
  },
  {
    component: EditPassword,
    event: PossibleAppEvents.PASSWORD_EDIT
  },
  {
    component: PasswordUpdatePopup,
    event: PossibleAppEvents.PASSWORD_UPDATED
  },
  {
    component: EditContact,
    event: PossibleAppEvents.CONTACT_EDIT
  },
  {
    component: EditProfile,
    event: PossibleAppEvents.PROFILE_EDIT
  },
  {
    component: DetailUpdatePopup,
    event: PossibleAppEvents.INFO_UPDATED
  },
  {
    component: AddColleagueToBuildingPopUp,
    event: PossibleAppEvents.ADD_COLLEAGUE
  },
  {
    component: CustomPeriod,
    event: PossibleAppEvents.CUSTOM_PERIOD
  },
  {
    component: EnergyCSVDownload,
    event: PossibleAppEvents.CSV_ENERGY
  },
  {
    component: MfaOtpSetupPopUp,
    event: PossibleAppEvents.MFA_SET_UP_OTP
  },
  {
    component: SecuritySettingsSavedPopUp,
    event: PossibleAppEvents.SECURITY_SETTINGS_SAVED_POPUP
  },
  {
    component: MfaOtpDeletePopUp,
    event: PossibleAppEvents.MFA_DELETE_OTP_START
  },
  {
    component: DeleteOtpMfa,
    event: PossibleAppEvents.MFA_DELETE_OTP
  },
  {
    component: ResendInviteModal,
    event: PossibleAppEvents.RESEND_INVITE_SUCCESS
  },
  {
    component: CancelTicketConfirmation,
    event: PossibleAppEvents.CANCEL_TICKET
  },
  {
    component: TicketCancelledModal,
    event: PossibleAppEvents.TICKET_CANCELLED
  },
  {
    component: DocumentLoadingPopUp,
    event: PossibleAppEvents.DOCUMENT_LOADING
  }
]
