import { SagaIterator } from "redux-saga"
import { put, select, take, takeLatest } from "redux-saga/effects"
import * as actions from "./buildingSwitcher.actions"
import { push } from "redux-first-history"
import { getBuildingById, getGroups, getPreferredBuilding, getRentedBuildings, getShortenedUserRoles } from "../me/me.selectors"
import { getMeSuccess } from "../me/me.actions"

export function* setBuildingSwitcherFlow(
  action: actions.SetBuildingSwitcherAction
): SagaIterator {
  try {
    const { id } = action.payload
    yield take(getMeSuccess)
    const rentedBuilding = yield select(getBuildingById(id))
    const preferredBuilding = yield select(getPreferredBuilding)
    const rentedBuildings = yield select(getRentedBuildings)
    const groups = yield select(getGroups)
    if (!groups) {
      if (!rentedBuilding) yield put(push("/no-permission"))
      const shortenedRoles = yield select(getShortenedUserRoles)
      gtag("set", "user_properties", {
        user_roles: shortenedRoles,
        current_rented_building: rentedBuilding,
        preferred_rented_building: preferredBuilding,
        rented_buildings: rentedBuildings
      })
    }
  } catch (e: any) {
    console.warn(e)
  }
}

export default function* buildingSwitcherSaga(): SagaIterator {
  yield takeLatest(actions.Types.SET, setBuildingSwitcherFlow)
}
