import { theme, Button } from "@paudigital/wdp-components"
import styled from "styled-components"

export const TopBar = styled.div`
  margin: 0;
  grid-area: topbar;
  display: flex;
  gap: 0 1.6rem;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (${theme.breakpoints.desktop}) {
    margin: 0 1rem;
  }
`

export const StyledButton = styled(Button)`
  min-width: 22rem;
  & > button[type="button"] {
    width: unset;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    width: 22rem;
  }
`
export const StyledTotalTickets = styled.h4<{ loading: number }>`
  font-size: ${theme.fontSizes.h6};
  line-height: ${theme.lineHeights.h6};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
  margin-left: ${props => props.loading && "1.2rem"};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`
