export const trimEvent = (e: React.SyntheticEvent<HTMLInputElement>) => {
  const { value: val } = e.currentTarget
  return {
    ...e,
    currentTarget: {
      ...e.currentTarget,
      value: val.trim()
    }
  }
}
