import LinkExpiredPage from "app/components/LinkExpiredPage"
import { useQueryParams } from "hooks/queryParams"
import { useNavigate } from "react-router-dom"

interface ErrorQueryParams {
  error_description?: "Access expired." | string
}

const Error = () => {
  const navigate = useNavigate()
  const { queryParams } = useQueryParams<ErrorQueryParams>()

  if (queryParams?.error_description === "Access expired.") {
    return <LinkExpiredPage />
  }

  return <>{navigate("/")}</>
}

export default Error
