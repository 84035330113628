import { BuildingInfo } from "app/types/buildingSpecification"

export enum Types {
  GET_BUILDINGS = "GET_BUILDINGS",
  GET_BUILDINGS_SUCCESS = "GET_BUILDINGS_SUCCESS",
  GET_BUILDINGS_FAIL = "GET_BUILDINGS_FAIL",
  SET_SEARCH_STRING = "SET_SEARCH_STRING"
}

export type Action =
  | GetBuildingsAction
  | GetBuildingsSuccessAction
  | GetBuildingsFailAction
  | SetSearchString


export type GetBuildingsAction = {
  type: Types.GET_BUILDINGS
}
  
export type GetBuildingsSuccessAction = {
  type: Types.GET_BUILDINGS_SUCCESS,
  payload: { data: BuildingInfo[] }
}
  
export type GetBuildingsFailAction = {
  type: Types.GET_BUILDINGS_FAIL,
  payload: { errors: Record<string, unknown> }
}

export type SetSearchString = {
  type: Types.SET_SEARCH_STRING,
  payload: { value: string | undefined}
}

export function getBuildings(): GetBuildingsAction {
  return {
    type: Types.GET_BUILDINGS
  }
}

export function getBuildingsSuccess(data: BuildingInfo[]): GetBuildingsSuccessAction {
  return {
    type: Types.GET_BUILDINGS_SUCCESS,
    payload: { data }
  }
}

export function getBuildingsFail(errors: Record<string, unknown>) {
  return {
    type: Types.GET_BUILDINGS_FAIL,
    payload: { errors },
  }
}

export function setSearchString(value: string | undefined) {
  return {
    type: Types.SET_SEARCH_STRING,
    payload: { value }
  }
}

  