import EmptyWidgetContainer from "app/components/EmptyWidgetContainer"
import ModuleWidget from "app/components/ModuleWidget"
import { Modules } from "app/enums/modules"
import { getSummary } from "app/redux/invoices/invoices.selectors"
import { useApi } from "hooks/api"
import { useRentedBuilding } from "hooks/rentedBuilding"
import { useTranslate } from "hooks/translate"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import Icon from "./icon"
import WidgetCard from "./WidgetCard"
import * as actions from "app/redux/invoices/invoices.actions"
import { usePermission } from "hooks/permission"

const Invoices = () => {
  const { call } = useApi()
  const summary = useSelector(getSummary)
  const { hasPermission } = usePermission()
  const { rentedBuilding } = useRentedBuilding()
  const permission =
    rentedBuilding && hasPermission(Modules.INVOICES, rentedBuilding?.id!)
  const { translate } = useTranslate()

  useEffect(() => {
    if (rentedBuilding?.id && permission) {
      call(actions.getInvoicesSummary(rentedBuilding?.id))
    }
  }, [rentedBuilding, call, permission])

  return (
    <ModuleWidget
      permissionModule={Modules.INVOICES}
      title={translate("portal.dashboard.widget.invoices.title")}
      description={translate("portal.dashboard.widget.invoices.guidance")}
      image={<Icon />}
      url={`/invoices/${rentedBuilding?.id}`}
    >
      {summary && summary.length > 0 ? (
        summary.map(invoice => <WidgetCard invoice={invoice} />)
      ) : (
        <EmptyWidgetContainer>
          {translate("portal.dashboard.widget.invoices.noinvoices")}
        </EmptyWidgetContainer>
      )}
    </ModuleWidget>
  )
}

export default Invoices
