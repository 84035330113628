import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledDescription = styled.p`
    font-size: ${theme.fontSizes.captionSmall};
    line-height: ${theme.lineHeights.paragraph};
    font-weight: ${theme.fontWeights.bold};
`

export const StyledDate = styled.p`
    font-size: ${theme.fontSizes.captionSmall};
    line-height: ${theme.lineHeights.paragraph};
    margin-left: auto;
    margin-right: 0;
`

export const StyledContainer = styled.span`
    display: flex;
`