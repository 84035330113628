import { MeterHistoryData } from "app/types/energy"
import State from "../state"

export const isLoading = (state: State): boolean =>
  state.solarProductionHistory.loading
export const hasErrors = (state: State): boolean =>
  state.solarProductionHistory.errors ? true : false
export const hasWarning = (state: State): boolean =>
  getSolarProductionHistoryData(state)?.some(h => h.msg) || hasErrors(state)
export const getLastUpdated = (state: State): Date | undefined =>
  state.solarProductionHistory.lastUpdated
export const getSolarProductionHistoryData = (
  state: State
): MeterHistoryData[] | undefined => {
  return state.solarProductionHistory.data
}
