import Section from "app/components/Section"
import { RentedBuildingContract } from "app/types/rentedBuildingContract"
import { useTranslate } from "hooks/translate"
import React from "react"
import { CustomSelect, DocumentIcon } from "@paudigital/wdp-components"
import {
  ContractGridContainer,
  HorizontalRule,
  SelectContractText,
  StyledButton
} from "../style"
import Indexing from "../Indexing"
import ContractualGuaranteeList from "../ContractualGuaranteeList"
import { useNavigate } from "react-router"
import { getActiveBuilding } from "app/redux/buildingSwitcher/buildingSwitcher.selectors"
import { useSelector } from "react-redux"
import { isFuture, isPast } from "date-fns"
import { getDocumentStatistics } from "app/redux/document/document.selectors"
import { DocumentTypes } from "app/enums/documentTypes"
import { ContractStatus } from "./types"
import ContractLabel from "./ContractLabel"
import ContractGroupLabel from "./ContractGroupLabel"

type MultiContractViewProps = {
  contracts: RentedBuildingContract[]
}

type RentedBuildingGroupedContract = {
  [key in ContractStatus]: RentedBuildingContract[]
}

type RentedBuildingGroupedLabel = {
  option: JSX.Element
  isDisabled?: boolean
}

type Props = MultiContractViewProps

const MultiContractView = ({ contracts }: Props) => {
  const rentedBuildingId = useSelector(getActiveBuilding)
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const docStats = useSelector(getDocumentStatistics)

  const docStatsIndex = docStats
    ?.filter(ds => ds.document_type === DocumentTypes.Financial)
    .filter(ds =>
      ds.children.filter(ds => ds.document_type === DocumentTypes.Index_letter)
    )
    .find(ds => ds.count >= 0)

  const docStatsContracts = docStats?.find(
    ds => ds.document_type === DocumentTypes.Contracts
  )

  const [currentContract, setCurrentContract] = React.useState(
    contracts[0].sourceId
  )

  const contractDetails = contracts.find(
    contract => contract.sourceId === currentContract
  ) as RentedBuildingContract

  const groupedContracts = contracts.reduce(
    (
      groupedContracts: RentedBuildingGroupedContract,
      currentContract: RentedBuildingContract
    ) => {
      const { endDate, startDate } = currentContract
      if (isPast(new Date(endDate))) {
        return {
          ...groupedContracts,
          [ContractStatus.INACTIVE]: [
            ...groupedContracts[ContractStatus.INACTIVE],
            currentContract
          ]
        }
      }
      if (isFuture(new Date(startDate))) {
        return {
          ...groupedContracts,
          [ContractStatus.UPCOMING]: [
            ...groupedContracts[ContractStatus.UPCOMING],
            currentContract
          ]
        }
      }
      return {
        ...groupedContracts,
        [ContractStatus.ACTIVE]: [
          ...groupedContracts[ContractStatus.ACTIVE],
          currentContract
        ]
      }
    },
    {
      [ContractStatus.UPCOMING]: [],
      [ContractStatus.ACTIVE]: [],
      [ContractStatus.INACTIVE]: []
    }
  )

  const contractDropdownOptions = Object.entries(groupedContracts).reduce(
    (
      contractGroupedOptions: (
        | RentedBuildingContract
        | RentedBuildingGroupedLabel
      )[],
      currentOption
    ) => {
      if (!currentOption[1].length) return contractGroupedOptions
      const [key, contracts] = currentOption
      const contractOptions = contracts.map((contract: any) => ({
        option: <ContractLabel contract={contract} />,
        value: contract.sourceId
      }))
      return [
        ...contractGroupedOptions,
        {
          option: (
            <ContractGroupLabel
              group={key as ContractStatus}
              groupLength={contractOptions.length}
            />
          ),
          isDisabled: true
        },
        ...contractOptions
      ]
    },
    []
  )

  const handleOnChange = (val: string) => {
    setCurrentContract(val)
  }

  const page = 1
  const perpage = 20
  const sort = "date=desc"

  const RedirectDocuments = (docType: string) => {
    navigate(
      `/documents/${rentedBuildingId}?documentType=${docType}&page=${page}&perPage=${perpage}&sort[]=${sort}`
    )
  }

  return (
    <Section
      title={translate("portal.buildingpassport.contracts.multi.title", {
        amount: contracts.length
      })}
    >
      <SelectContractText>
        {translate(
          "portal.buildingpassport.contract.section.contractualguarantee.select"
        )}
      </SelectContractText>
      <CustomSelect
        options={contractDropdownOptions}
        value={currentContract}
        onChange={handleOnChange}
      />
      <HorizontalRule />
      <ContractGridContainer>
        <ContractualGuaranteeList
          securityDeposits={contractDetails.securityDeposits}
        />
      </ContractGridContainer>
      {contractDetails.indexFirstDate !== null ||
      contractDetails.indexFirstDate ? (
        <Indexing date={contractDetails.indexFirstDate} />
      ) : (
        <></>
      )}
      <>
        {docStatsIndex?.count && (
          <StyledButton
            type="secondary"
            label={translate(
              "portal.buildingpassport.contract.section.indexing.button"
            )}
            onClick={() => RedirectDocuments(DocumentTypes.Index_letter)}
            fullWidth
            startIcon={<DocumentIcon />}
          />
        )}
      </>
      <>
        {docStatsContracts?.count && (
          <StyledButton
            type="secondary"
            label={translate(
              "portal.buildingpassport.contract.section.contract.button",
              {
                amount: docStatsContracts.count
              }
            )}
            onClick={() => RedirectDocuments(DocumentTypes.Contracts)}
            fullWidth
            startIcon={<DocumentIcon />}
          />
        )}
      </>
    </Section>
  )
}

export default MultiContractView
