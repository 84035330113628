import { downloadEnergyCSV } from "app/api/energy"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import * as actions from "./energyCSV.actions"
import { getFileNameFromHeaders } from "app/utils/getFileName"
import { getActiveBuilding } from "../buildingSwitcher/buildingSwitcher.selectors"
import { fire, remove } from "../appEvents/appEvents.actions"
import * as httpErrorActions from "../httpErrors/httpErrors.actions"
import { PossibleAppEvents } from "app/components/AppEventsProvider/types"

export function* downloadEnergyCSVFlow(
  action: actions.DownloadEnergyCSVAction
): SagaIterator {
  try {
    yield put(
      fire({
        eventName: PossibleAppEvents.DOCUMENT_LOADING,
        uniqueIdentifier: "document-loading-popup"
      })
    )
    const rentedBuildingId = yield select(getActiveBuilding)
    const response = yield call(
      downloadEnergyCSV,
      action.payload.data,
      rentedBuildingId
    )
    const { headers } = response
    const fileName = getFileNameFromHeaders(headers)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(actions.downloadEnergyCSVSuccess())
    yield put(remove("csv-energy"))
    yield put(remove("document-loading-popup"))
  } catch (e: any) {
    yield put(remove("document-loading-popup"))
    yield put(
      httpErrorActions.handleHttpError({
        error: e,
        mapping: [
          { expectedHttpStatus: 403, redirectTo: "/no-permission" },
          { expectedHttpStatus: 401, redirectTo: "/no-access" }
        ]
      })
    )
    yield put(actions.downloadEnergyCSVFail(e))
  }
}

export default function* energyCSVSaga(): SagaIterator {
  yield takeLatest(actions.Types.DOWNLOAD_CSV, downloadEnergyCSVFlow)
}
