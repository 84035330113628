import { Button, InputElement } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import { useFormik } from "formik"
import { mfaValidationSchema, MfaValue, mfaInitialValues } from "./config"
import { useDispatch, useSelector } from "react-redux"
import {
  ButtonContainer,
  DescriptionContainer
} from "./style"
import { useEffect } from "react"
import { remove } from "app/redux/appEvents/appEvents.actions"
import {
  // challengeMfa,
  // setMfaType,
  verifyMfa
} from "app/redux/authentication/authentication.actions"
import { Action as ReduxAction } from "redux"
import { getMfaType } from "app/redux/authentication/authentication.selectors"

export type MfaVerificationProps = {
  actionOnSuccess: () => ReduxAction<any>
}

const MfaVerification = ({ actionOnSuccess }: MfaVerificationProps) => {
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const mfaType = useSelector(getMfaType)

  useEffect(() => {
    return () => {
      dispatch(remove("authentication-error"))
    }
  }, [dispatch])

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    dirty
  } = useFormik({
    initialValues: mfaInitialValues,
    onSubmit: ({ oneTimeCode }: MfaValue) => {
      isValid &&
        mfaType &&
        dispatch(verifyMfa({ oneTimeCode, actionOnSuccess, type: mfaType }))
    },
    validationSchema: mfaValidationSchema
  })

  const hasErrors = (name: string) => {
    return errors[name as keyof MfaValue] && touched[name as keyof MfaValue]
      ? true
      : false
  }

  return (
    <form onSubmit={handleSubmit}>
      <DescriptionContainer>
        {translate(`portal.authentication.mfa.description.${mfaType}`)}
      </DescriptionContainer>
      <InputElement
        label={translate("portal.authentication.mfa.one-time-code")}
        name="oneTimeCode"
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasErrors("oneTimeCode")}
        errorMessage={
          hasErrors("oneTimeCode") && translate(errors.oneTimeCode!)
        }
        dataTestId="portal.authenticate.mfa.one-time-code"
      />
      <ButtonContainer>
        <Button
          label={translate("portal.authentication.button.continue")}
          disabled={!isValid || !dirty}
          submit
        />
      </ButtonContainer>
    </form>
  )
}

export default MfaVerification
