import { Locales } from "app/i18n/config"

export enum Types {
  GET = "FAQ_GET",
  GET_SUCCESS = "FAQ_GET_SUCCESS",
  GET_FAIL = "FAQ_GET_FAIL"
}

export type Action = GetFaqAction | GetFaqSuccessAction | GetFaqFailAction

export type GetFaqAction = {
  payload: { language: Locales }
  type: Types.GET
}

export type GetFaqSuccessAction = {
  payload: string
  type: Types.GET_SUCCESS
}

export type GetFaqFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export function getFaq(language: Locales): GetFaqAction {
  return {
    payload: { language },
    type: Types.GET
  }
}

export function getFaqSuccess(payload: string): GetFaqSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getFaqFail(errors: object): GetFaqFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
