import { Notification } from "app/types/notification"
import { format } from "date-fns"
import { groupBy, orderBy } from "lodash"
import Day from "./Day"
import { TimeLineWrapper } from "./style"

export type ModifiedNotification = Omit<Notification, "dateTime"> & {
  dateTime: Date
  _date: string
}

export type TimeLineProps = {
  notifications: Notification[]
}

type Props = TimeLineProps

const TimeLine = ({ notifications }: Props) => {
  const formattedNotifications = notifications.map(n => ({
    ...n,
    dateTime: new Date(n.dateTime),
    _date: format(new Date(n.dateTime), "dd/MM/yyyy")
  }))
  const orderedNotifications = orderBy(
    formattedNotifications,
    ["dateTime"],
    ["desc"]
  )
  const groupedByDayNotifications = groupBy(orderedNotifications, "_date")

  return (
    <TimeLineWrapper>
      {Object.keys(groupedByDayNotifications).map((_, i) => (
        <Day
          key={i}
          notifications={Object.values(groupedByDayNotifications)[i]}
        />
      ))}
    </TimeLineWrapper>
  )
}

export default TimeLine
