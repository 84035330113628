import { Checkbox } from "@paudigital/wdp-components"
import { TagEnum } from "app/redux/graphs/graphs.actions"
import { availableMeters } from "app/redux/graphs/graphs.selectors"
import { useTranslate } from "hooks/translate"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { FiltersWrapper, CategoryTitleWrapper } from "./style"
import ExportSelect from "app/components/DownloadFilters/ExportSelect"
import ExportFilterTitle from "app/components/DownloadFilters/ExportFilterTitle"

type Props = {
  selectedMeters: TagEnum[]
  onSelectedMetersChange: (tags: TagEnum[]) => void
}

export const MeterTypeFilter = ({
  selectedMeters,
  onSelectedMetersChange
}: Props) => {
  const { translate } = useTranslate()
  const meters = useSelector(availableMeters)!
  const clickHandler = (tags: TagEnum[]) => {
    if (tags.some(tag => selectedMeters.includes(tag)))
      onSelectedMetersChange([...selectedMeters.filter(f => !tags.includes(f))])
    else onSelectedMetersChange([...selectedMeters, ...tags])
  }

  const selectAllContracts = () => {
    onSelectedMetersChange(meters.map(m => m.tag))
  }

  const deselectAllContracts = () => {
    onSelectedMetersChange([])
  }

  const tagEnumToName = (tag: TagEnum): string => {
    switch (tag) {
      case TagEnum.ELECTRICITY_CONSUMPTION:
      case TagEnum.ELECTRICITY_YIELD_INJECTION:
        return "electricity"
      case TagEnum.WATER_MAIN:
        return "water"
      case TagEnum.GAS_MAIN:
        return "gas"
      default:
        return tag
    }
  }

  const tagMappingArray = (tag: TagEnum): TagEnum[] => {
    switch (tag) {
      case TagEnum.ELECTRICITY_CONSUMPTION:
        return [TagEnum.ELECTRICITY_CONSUMPTION]
      case TagEnum.ELECTRICITY_YIELD_INJECTION:
        return [
          TagEnum.ELECTRICITY_YIELD_INJECTION,
          TagEnum.ELECTRICITY_CONSUMPTION
        ]
      case TagEnum.WATER_MAIN:
        return [TagEnum.WATER_MAIN]
      case TagEnum.GAS_MAIN:
        return [TagEnum.GAS_MAIN]
      default:
        return []
    }
  }

  const mappedMeters = useMemo(() => {
    const mappedTags = meters.map(meter => {
      return {
        name: tagEnumToName(meter.tag),
        tags: tagMappingArray(meter.tag)
      }
    })
    return mappedTags.filter(
      (mapped, i) => mappedTags.findIndex(tag => tag.name === mapped.name) === i
    )
  }, [meters])

  const renderSelectedMeters = () => {
    return mappedMeters.map(meter => {
      return (
        <Checkbox
          key={meter.name}
          label={translate(
            `portal.energy.export.overview.filters.granularity.${meter.name}`
          )}
          name={meter.name}
          onClick={() => clickHandler(meter.tags)}
          checked={meter.tags.some(tag => selectedMeters.includes(tag))}
        />
      )
    })
  }

  return (
    <FiltersWrapper>
      <CategoryTitleWrapper>
        <ExportFilterTitle>
          {translate("portal.energy.export.overview.filters.meterType.title")}
        </ExportFilterTitle>
        <ExportSelect
          selectAll={selectAllContracts}
          deselectAll={deselectAllContracts}
          deselectText={translate(
            "portal.energy.export.overview.filterfunctions.deselectAll"
          )}
          selectText={translate(
            "portal.energy.export.overview.filterfunctions.selectAll"
          )}
        />
      </CategoryTitleWrapper>
      {renderSelectedMeters()}
    </FiltersWrapper>
  )
}

export default MeterTypeFilter
