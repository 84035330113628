const BuildingIllustration = () => {
  return (
    <svg
      width="206"
      height="65"
      viewBox="0 0 273 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="initial">
        <path
          d="M229.029 91.0415C228.623 92.5428 227.23 93.4727 227.23 93.4727C227.23 93.4727 226.496 91.9683 226.903 90.467C227.309 88.9657 228.702 88.0359 228.702 88.0359C228.702 88.0359 229.436 89.5402 229.029 91.0415Z"
          fill="#3F3D56"
        />
        <path
          d="M228.431 90.7188C227.315 91.8037 227.179 93.4714 227.179 93.4714C227.179 93.4714 228.852 93.3802 229.968 92.2953C231.084 91.2105 231.221 89.5428 231.221 89.5428C231.221 89.5428 229.548 89.634 228.431 90.7188Z"
          fill="#629282"
        />
        <path
          d="M198.056 91.0415C197.649 92.5428 196.256 93.4727 196.256 93.4727C196.256 93.4727 195.522 91.9683 195.929 90.467C196.336 88.9657 197.729 88.0359 197.729 88.0359C197.729 88.0359 198.463 89.5402 198.056 91.0415Z"
          fill="#3F3D56"
        />
        <path
          d="M197.458 90.7188C196.342 91.8037 196.206 93.4714 196.206 93.4714C196.206 93.4714 197.879 93.3802 198.995 92.2953C200.111 91.2105 200.247 89.5428 200.247 89.5428C200.247 89.5428 198.574 89.634 197.458 90.7188Z"
          fill="#629282"
        />
        <path
          d="M18.3857 29.0952L122.425 7.83333L131.252 13.7095L144.939 73.2738L124.298 93.8846H18.3857V29.0952Z"
          fill="#CCCCCC"
        />
        <path
          d="M122.279 7.82254H196.818V93.8736H122.279V7.82254Z"
          fill="#F2F2F2"
        />
        <path
          d="M154.154 63.024H167.885V93.8737H154.154V63.024Z"
          fill="#CCCCCC"
        />
        <path
          d="M182.597 71.8382V66.9414H188.481V71.8382H183.087H182.597Z"
          fill="#3F3D56"
        />
        <path
          d="M183.087 75.7552H188.481V80.6521H182.597V75.7552H183.087Z"
          fill="#3F3D56"
        />
        <path
          d="M172.789 66.9413H178.673V71.8381H172.789V66.9413Z"
          fill="#3F3D56"
        />
        <path
          d="M172.789 75.7556H178.673V80.6524H172.789V75.7556Z"
          fill="#3F3D56"
        />
        <path
          d="M142.385 71.8382V66.9414H148.27V71.8382H142.876H142.385Z"
          fill="#3F3D56"
        />
        <path
          d="M142.876 75.7552H148.27V80.6521H142.385V75.7552H142.876Z"
          fill="#3F3D56"
        />
        <path
          d="M132.576 66.9413H138.461V71.8381H132.576V66.9413Z"
          fill="#3F3D56"
        />
        <path
          d="M132.576 75.7556H138.461V80.6524H132.576V75.7556Z"
          fill="#3F3D56"
        />
        <path
          d="M131.596 20.9118H188.481V32.664H131.596V20.9118Z"
          fill="#3F3D56"
        />
        <path
          d="M131.596 44.4163H188.481V56.1685H131.596V44.4163Z"
          fill="#3F3D56"
        />
        <path d="M0 93.3807H273V93.8602H0V93.3807Z" fill="#3F3D56" />
        <path
          d="M225.873 38.0174H227.283V93.6539H225.873V38.0174Z"
          fill="#3F3D56"
        />
        <path
          d="M212.854 45.1076C212.764 64.5623 226.472 80.3969 226.472 80.3969C226.472 80.3969 240.325 64.6892 240.414 45.2345C240.504 25.7798 226.797 9.94521 226.797 9.94521C226.797 9.94521 212.944 25.6529 212.854 45.1076Z"
          fill="#629282"
        />
        <path
          opacity="0.1"
          d="M212.854 45.1076C212.764 64.5623 226.472 80.3969 226.472 80.3969C226.472 80.3969 240.325 64.6892 240.414 45.2345C240.504 25.7798 226.797 9.94521 226.797 9.94521C226.797 9.94521 212.944 25.6529 212.854 45.1076Z"
          fill="black"
        />
        <path
          d="M244.21 38.0174H245.62V93.6539H244.21V38.0174Z"
          fill="#3F3D56"
        />
        <path
          d="M231.192 45.1076C231.102 64.5623 244.809 80.3969 244.809 80.3969C244.809 80.3969 258.662 64.6892 258.752 45.2345C258.842 25.7798 245.134 9.94521 245.134 9.94521C245.134 9.94521 231.282 25.6529 231.192 45.1076Z"
          fill="#629282"
        />
        <path
          d="M89.1428 48.708L113.657 45.3214V93.4405H89.1428V48.708Z"
          fill="#A4A4A4"
        />
        <path
          d="M56.8286 53.3893L78.5572 50.3571V93.4405H56.8286V53.3893Z"
          fill="#A4A4A4"
        />
        <path
          d="M26.7429 57.5505L46.2429 54.8333V93.4405H26.7429V57.5505Z"
          fill="#A4A4A4"
        />
        <path
          d="M3.47874 77.6628H17.5999V82.941H3.47874V77.6628Z"
          fill="#3F3D56"
        />
        <path
          d="M4.77721 82.32H74.4088V88.2191H4.77721V82.32Z"
          fill="#3F3D56"
        />
        <path
          d="M16.2201 85.2696H32.9382V65.1661H20.4402L16.2201 75.3053V85.2696Z"
          fill="#EE730A"
        />
        <path
          d="M23.1996 66.408H30.3413V73.2385H23.1996V66.408Z"
          fill="white"
        />
        <path
          d="M17.8433 73.3162H22.3879V66.4857H20.4402L17.8433 73.3162Z"
          fill="white"
        />
        <path
          d="M28.3935 77.1195H30.0166V80.0691H28.3935V77.1195Z"
          fill="#3F3D56"
        />
        <path
          d="M21.0083 70.8323H20.9271C20.5182 70.8323 20.126 70.9877 19.8368 71.2643C19.5476 71.5409 19.3852 71.916 19.3852 72.3071C19.3852 72.6982 19.5476 73.0734 19.8368 73.3499C20.126 73.6265 20.5182 73.7819 20.9271 73.7819H21.0083V70.8323Z"
          fill="#3F3D56"
        />
        <path
          d="M9.72767 82.2978C7.25425 82.2978 5.02945 83.4438 3.48572 85.2696H0.31361L3.39753 77.0534L17.7621 74.3438L17.3563 85.2696H15.9696C14.4259 83.4438 12.2011 82.2978 9.72767 82.2978Z"
          fill="#EE730A"
        />
        <path
          d="M9.56543 93.963C5.66601 93.963 2.5049 90.9396 2.5049 87.2101C2.5049 83.4805 5.66601 80.4572 9.56543 80.4572C13.4649 80.4572 16.626 83.4805 16.626 87.2101C16.626 90.9396 13.4649 93.963 9.56543 93.963Z"
          fill="#3F3D56"
        />
        <path
          d="M9.56542 90.7642C7.51309 90.7642 5.84935 89.173 5.84935 87.2101C5.84935 85.2472 7.51309 83.6559 9.56542 83.6559C11.6177 83.6559 13.2815 85.2472 13.2815 87.2101C13.2815 89.173 11.6177 90.7642 9.56542 90.7642Z"
          fill="#D0CDE1"
        />
        <path
          d="M32.4513 62.3718H35.8599V85.8906H32.4513V62.3718Z"
          fill="#3F3D56"
        />
        <path
          d="M32.3143 81.6905H111.429V82.8095H32.3143V81.6905Z"
          fill="#3F3D56"
        />
        <path
          d="M20.0345 73.6266H21.6576V74.2475H20.0345V73.6266Z"
          fill="#3F3D56"
        />
        <path
          d="M2.33643 79.9319L2.41233 79.9594C2.79484 80.0978 3.21915 80.0851 3.59192 79.9243C3.96469 79.7634 4.25538 79.4676 4.40005 79.1017C4.54472 78.7359 4.53152 78.3301 4.36334 77.9735C4.19516 77.617 3.88579 77.339 3.50329 77.2006L3.42738 77.1731L2.33643 79.9319Z"
          fill="#3F3D56"
        />
        <path
          d="M61.2465 94.0482C57.8401 94.0482 55.0786 91.4071 55.0786 88.1491C55.0786 84.8911 57.8401 82.25 61.2465 82.25C64.6529 82.25 67.4143 84.8911 67.4143 88.1491C67.4143 91.4071 64.6529 94.0482 61.2465 94.0482Z"
          fill="#3F3D56"
        />
        <path
          d="M74.6179 94.0482C71.2115 94.0482 68.4501 91.4071 68.4501 88.1491C68.4501 84.8911 71.2115 82.25 74.6179 82.25C78.0243 82.25 80.7858 84.8911 80.7858 88.1491C80.7858 91.4071 78.0243 94.0482 74.6179 94.0482Z"
          fill="#3F3D56"
        />
        <path
          d="M61.2464 91.2538C59.4536 91.2538 58.0002 89.8638 58.0002 88.149C58.0002 86.4343 59.4536 85.0443 61.2464 85.0443C63.0393 85.0443 64.4927 86.4343 64.4927 88.149C64.4927 89.8638 63.0393 91.2538 61.2464 91.2538Z"
          fill="#D0CDE1"
        />
        <path
          d="M74.6179 91.2538C72.8251 91.2538 71.3717 89.8638 71.3717 88.149C71.3717 86.4343 72.8251 85.0443 74.6179 85.0443C76.4108 85.0443 77.8641 86.4343 77.8641 88.149C77.8641 89.8638 76.4108 91.2538 74.6179 91.2538Z"
          fill="#D0CDE1"
        />
        <path
          d="M111.428 82.25H111.347C110.938 82.25 110.546 82.4054 110.257 82.6819C109.968 82.9585 109.805 83.3336 109.805 83.7248C109.805 84.1159 109.968 84.491 110.257 84.7676C110.546 85.0442 110.938 85.1995 111.347 85.1995H111.428V82.25Z"
          fill="#3F3D56"
        />
        <path
          d="M104.704 94.0482C101.297 94.0482 98.5357 91.4071 98.5357 88.1491C98.5357 84.8911 101.297 82.25 104.704 82.25C108.11 82.25 110.871 84.8911 110.871 88.1491C110.871 91.4071 108.11 94.0482 104.704 94.0482Z"
          fill="#3F3D56"
        />
        <path
          d="M104.703 91.2538C102.911 91.2538 101.457 89.8638 101.457 88.1491C101.457 86.4343 102.911 85.0443 104.703 85.0443C106.496 85.0443 107.95 86.4343 107.95 88.1491C107.95 89.8638 106.496 91.2538 104.703 91.2538Z"
          fill="#D0CDE1"
        />
        <rect
          width="75.2143"
          height="25.7381"
          transform="matrix(-1 0 0 1 111.428 55.3929)"
          fill="#CBAC92"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="273"
            height="94"
            fill="white"
            transform="matrix(-1 0 0 1 273 0)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BuildingIllustration
