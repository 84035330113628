import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import { useTranslate } from "hooks/translate"
import { OnChangeValue } from "react-select"
import { InvoicesFilters } from "app/enums/invoices"
import { StyledSort, StyledLabel, StyledDropdown } from "./style"
import { useMemo } from "react"
import { Filters } from "routes/Invoices"

type Props = {
  setUrlParams: (params: any) => void
} & Filters

const Sort = ({
  page,
  perPage,
  invoiceType,
  contractNumber,
  invoicePeriodTo,
  invoicePeriodFrom,
  invoiceDateTo,
  invoiceDateFrom,
  invoiceNumber,
  reference,
  sort,
  setUrlParams
}: Props) => {
  const { translate } = useTranslate()
  const sortBy = (sort && sort[0]) || "invoice_date"
  const queryParams = {
    page,
    perPage,
    invoiceType,
    contractNumber,
    invoicePeriodTo,
    invoicePeriodFrom,
    invoiceDateTo,
    invoiceDateFrom,
    invoiceNumber,
    reference,
    sort
  }
  const optionTranslation = sortBy.substring(0, sortBy.indexOf("="))

  const handleChange = (optionData: OnChangeValue<CustomOptionType, false>) => {
    const queryString = (optionData as CustomOptionType).value
    setUrlParams({
      ...queryParams,
      sort: [`${queryString}=desc`]
    })
  }

  const defaultValue = useMemo(() => {
    const filterKey =
      optionTranslation && optionTranslation.length > 0
        ? optionTranslation
        : "invoiceDate"
    return translate(`portal.invoices.filter.sortBy.option.${filterKey}`)
  }, [optionTranslation, translate])

  return (
    <StyledSort>
      <StyledLabel>{translate(`portal.invoices.filter.sortBy`)}</StyledLabel>
      <StyledDropdown
        onChange={handleChange}
        options={Object.values(InvoicesFilters).map(o => ({
          value: o,
          label: translate(`portal.invoices.filter.sortBy.option.${o}`)
        }))}
        hasDropdownIndicator
        defaultValue={defaultValue}
      />
    </StyledSort>
  )
}

export default Sort
