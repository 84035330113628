import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ContractualGarantueeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  font-size: ${theme.fontSizes.captionSmall};
`
export const ContractualGarantueeContentContainer = styled.div`
  flex: 1 1 0px;
`
export const ContractualGarantueeLabel = styled.label`
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const ContractualGarantueeValue = styled.p`
  font-size: ${theme.fontSizes.captionSmall};
  font-weight: ${theme.fontWeights.regular};
  line-height: ${theme.lineHeights.paragraph};
  color: ${theme.colors.neutral.darkerGrey};
`
