import { theme, StatusCard } from "@paudigital/wdp-components"
import styled from "styled-components"

export const StyledSpan = styled.span`
  display: block;
  font-size: ${theme.fontSizes.captionExtraSmall};

  strong {
    font-weight: ${theme.fontWeights.bold};
  }

  &:first-of-type {
    margin-bottom: 0.8rem;
  }
`

export const StyledStatusCard = styled(StatusCard)`
  margin-bottom: 1.6rem;
`
