import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  background: ${theme.colors.neutral.lightGrey};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  margin: 3.2rem;
  margin-top: 4.8rem;
  border-radius: 0.4rem;

  @media only screen and (${theme.breakpoints.tablet}) {
    position: sticky;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: row;
    height: 11.5rem;
    margin: 0;
    margin-top: 2.4rem;
  }
`

export const StyledContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    margin: 0 3.2rem;
    width: 100%;
  }
`
export const StyledImage = styled.img`
  display: inline-block;
  position: absolute;
  top: -3.6rem;
  border-radius: 50%;
  height: 7.2rem;
  width: 7.2rem;
  border: 4px solid ${theme.colors.neutral.white};

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-right: 2.4rem;
    position: initial;
  }
`

export const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  margin: 5rem 1.6rem 0;
  gap: 0 1.6rem;

  & p:nth-child(1) {
    grid-column: span 2;
    justify-self: center;
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    display: flex;
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }
`

export const StyledDescription = styled.p`
  display: inline-block;
  color: ${theme.colors.neutral.darkGrey};
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  max-width: 20rem;
  margin-bottom: 3.2rem;
  text-align: center;
  display: flex;
  flex-direction: column;

  strong {
    font-weight: ${theme.fontWeights.bold};
  }

  @media only screen and (${theme.breakpoints.tablet}) {
    margin-bottom: initial;
  }
`

export const StyledLink = styled.a`
  color: ${theme.colors.neutral.darkGrey};
`

export const StyledEmailLink = styled(StyledLink)`
  word-break: break-all;
`
