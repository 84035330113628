import { SlideIn } from "@paudigital/wdp-components"
import { ProfileActions } from "app/enums/profile"
import { remove } from "app/redux/appEvents/appEvents.actions"
import {
  authenticate,
  authenticateReset
} from "app/redux/authentication/authentication.actions"
import { useTranslate } from "hooks/translate"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Container, HelpContainer, HelpText } from "./style"
import { actions as wizardActions } from "app/redux/resetPasswordWizard/resetPasswordWizard.actions"
import { Audiences, Scopes } from "app/api/auth0"
import RenewPasswordStepper from "app/components/RenewPasswordStepper"
import {
  PasswordUpdateType,
  updatePassword
} from "app/redux/authorization/authorization.actions"
import { useEffect } from "react"
import { Action as ReduxAction } from "redux"

const EditPasswordDetailsPopUp = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(authenticateReset())
  }, [dispatch])

  const handleClose = () => {
    dispatch(remove(ProfileActions.PASSWORD_EDIT))
    dispatch(authenticateReset())
  }

  const handleOnSubmitVerification = (
    currentPassword: string,
    actionOnSuccess: () => ReduxAction<any>
  ) => {
    dispatch(
      authenticate({
        currentPassword,
        audience: Audiences.Rental,
        scope: Scopes.UpdatePassword,
        actionOnSuccess
      })
    )
  }

  const handleOnPasswordChange = (newPassword: string) => {
    dispatch(updatePassword(newPassword, PasswordUpdateType.PASSWORD_CHANGE))
  }

  const handleOnFaqClick = () => {
    navigate("/help")
    handleClose()
  }

  return (
    <SlideIn showCloseIcon show onClose={handleClose}>
      <>
        <Container data-testid="password-edit-pop-up">
          <RenewPasswordStepper
            handleOnPasswordChange={handleOnPasswordChange}
            description={translate("portal.authentication.description")}
            handleOnVerification={handleOnSubmitVerification}
            actionOnSuccess={wizardActions.increaseWizard}
          />
        </Container>
        <HelpContainer>
          <HelpText onClick={handleOnFaqClick}>
            {translate("portal.navigation.item.faq")}
          </HelpText>
        </HelpContainer>
      </>
    </SlideIn>
  )
}

export default EditPasswordDetailsPopUp
