import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const BuildingSelectionWidgetContainer = styled.div`
  background: ${theme.colors.neutral.white};
  display: flex;
  width: 32rem;
  margin: 2.4rem auto;
  border-radius: 4px;

  @media only screen and (${theme.breakpoints.tablet}) {
    width: 100%;
  }
`

export const BuildingSelectionWidgetBuildingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media only screen and (${theme.breakpoints.tablet}) {
    flex-direction: row;
    margin-bottom: 1.6rem;
  }

  @media only screen and (${theme.breakpoints.desktop}) {
    margin-bottom: 0;
  }
`

export const TypographyWrapper = styled.div`
  line-height: ${theme.lineHeights.h5};
  margin: 2.4rem 0;

  @media only screen and (${theme.breakpoints.tablet}) {
    margin: 0 0 0 2.4rem;
  }
`

export const Container = styled.div`
  margin: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (${theme.breakpoints.desktop}) {
    flex-wrap: wrap-reverse;
    flex-direction: row;
    justify-content: space-between;
  }
`
