import { Refresh } from "@paudigital/wdp-components"
import { useTranslate } from "hooks/translate"
import React, { useState } from "react"
import { calculateMinutesElapsed } from "../../util/basegraph.util"
import { TimeLeftWrapper, StyledTimeLeft, RefreshContainer } from "./style"

type TimeLeftProps = {
  lastUpdated?: Date
  refreshGraph: () => void
}

type Props = TimeLeftProps

const TimeLeft = ({ lastUpdated, refreshGraph }: Props) => {
  const { translate } = useTranslate()

  const [minutesElapsed, setMinutesElapsed] = useState(
    calculateMinutesElapsed(lastUpdated)
  )

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentElapsed = calculateMinutesElapsed(lastUpdated)
      if (currentElapsed !== minutesElapsed) setMinutesElapsed(currentElapsed)
    }, 1000)
    return () => clearInterval(interval)
  })

  const refreshEnabled = minutesElapsed > 1

  const refresh = () => refreshEnabled && refreshGraph()

  return (
    <TimeLeftWrapper>
      <RefreshContainer onClick={refresh} enabled={refreshEnabled}>
        <Refresh width="24" height="24" />
      </RefreshContainer>
      <StyledTimeLeft>
        {minutesElapsed === 0 &&
          translate("portal.energy.graphs.last_updated.lessThan1Minute")}
        {minutesElapsed === 1 &&
          translate("portal.energy.graphs.last_updated.minute")}
        {minutesElapsed > 1 &&
          translate("portal.energy.graphs.last_updated", {
            minutesElapsed
          })}
      </StyledTimeLeft>
    </TimeLeftWrapper>
  )
}

export default TimeLeft
