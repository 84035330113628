import { Button, Link, theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.neutral.white};
  border-radius: 10px;
  padding: 3rem 4rem;
`

export const Title = styled.h5`
  font-size: ${theme.fontSizes.h5};
  line-height: ${theme.lineHeights.h5};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LastUpdatedContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const LastUpdated = styled.p`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  color: ${theme.colors.neutral.darkerGrey};
`
export const LastUpdatedDate = styled.p`
  font-size: ${theme.fontSizes.caption};
  line-height: ${theme.lineHeights.caption};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.neutral.darkerGrey};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;

  @media only screen and (min-width: 40rem) {
    justify-content: space-evenly;
    margin-top: 0;
  }
`

export const StyledLink = styled(Link)`
  margin: 0 1.5rem;
`
export const StyledButton = styled(Button)`
  margin: 1rem 0;
  @media only screen and (min-width: 34rem) {
    margin: 0 1.5rem;
  }
`

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  flex-wrap: wrap;

  @media only screen and (min-width: 60rem) {
    flex-direction: row;
  }
`
