import {
  RentedbuildingResponsibilities,
  UpdateRbResponsibilities
} from "app/types/responsibilities"

export enum Types {
  PATCH = "PATCH_RESPONSIBILITIES",
  PATCH_IN_ONBOARDING = "PATCH_IN_ONBOARDING_RESPONSIBILITIES",
  PATCH_SUCCESS = "PATCH_RESPONSIBILITIES_SUCCESS",
  PATCH_FAIL = "PATCH_RESPONSIBILITIES_FAIL",
  SET = "SET_RESPONSIBILITIES",
  UPDATE = "UPDATE_RESPONSIBILITIES",
  CLEAR = "CLEAR_RESPONSIBILITIES"
}

export type Action =
  | PatchResponsibilitiesAction
  | PatchResponsibilitiesSuccessAction
  | PatchResponsibilitiesFailAction
  | PatchInOnboardingResponsibilitiesAction
  | SetResponsibilitiesAction
  | UpdateResponsibilitiesAction
  | ClearResponsibilitiesAction

export type PatchResponsibilitiesAction = {
  type: Types.PATCH
  payload: {
    contactPersonId: string
    data: RentedbuildingResponsibilities[]
  }
}

export type PatchInOnboardingResponsibilitiesAction = {
  type: Types.PATCH_IN_ONBOARDING
  payload: {
    contactPersonId: string
    data: RentedbuildingResponsibilities[]
  }
}

export type PatchResponsibilitiesSuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchResponsibilitiesFailAction = {
  type: Types.PATCH_FAIL
  payload: {
    errors: Record<string, unknown>
  }
}

export type SetResponsibilitiesAction = {
  type: Types.SET
  payload: {
    data: RentedbuildingResponsibilities[]
  }
}

export type UpdateResponsibilitiesAction = {
  type: Types.UPDATE
  payload: {
    data: UpdateRbResponsibilities
  }
}

export type ClearResponsibilitiesAction = {
  type: Types.CLEAR
}

export function patchResponsiblities(
  contactPersonId: string,
  data: RentedbuildingResponsibilities[]
): PatchResponsibilitiesAction {
  return {
    type: Types.PATCH,
    payload: {
      contactPersonId,
      data
    }
  }
}

export function patchInOnboardingResponsibilities(
  contactPersonId: string,
  data: RentedbuildingResponsibilities[]
): PatchInOnboardingResponsibilitiesAction {
  return {
    type: Types.PATCH_IN_ONBOARDING,
    payload: {
      contactPersonId,
      data
    }
  }
}

export function patchResponsiblitiesSuccess(): PatchResponsibilitiesSuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchResponsiblitiesFailure(
  errors: Record<string, unknown>
): PatchResponsibilitiesFailAction {
  return {
    type: Types.PATCH_FAIL,
    payload: {
      errors
    }
  }
}

export function setResponsibilities(
  data: RentedbuildingResponsibilities[]
): SetResponsibilitiesAction {
  return {
    type: Types.SET,
    payload: {
      data
    }
  }
}

export function updateResponsibilities(
  data: UpdateRbResponsibilities
): UpdateResponsibilitiesAction {
  return {
    type: Types.UPDATE,
    payload: {
      data
    }
  }
}

export function clearResponsibilities(): ClearResponsibilitiesAction {
  return {
    type: Types.CLEAR
  }
}
