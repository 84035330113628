import { StyledStatusIndicator } from './style'
import { FacilityTicketStatus } from "app/enums/ticketStatus"

export enum IndicatorSize {
  "SMALL",
  "LARGE"
}

export type StatusIndicatorProps = {
  status: FacilityTicketStatus
  size?: IndicatorSize
}

type Props = StatusIndicatorProps

const StatusIndicator = ({status, size = IndicatorSize.SMALL}: Props) => {
  return (
    <StyledStatusIndicator size={size} status={status} />
  )
}

export default StatusIndicator
