import { Button, DropDown } from "@paudigital/wdp-components"
import {
  Content,
  ContactButtonWrapper,
  Container,
  Wrapper,
  DropdownWrapper,
  StyledImage,
  Expert,
  ExpertDescription,
  ExpertName,
  Details
} from "./style"
import { options } from "./config"
import { useTranslate } from "hooks/translate"
import { useState } from "react"
import { OnChangeValue } from "react-select"
import { CustomOptionType } from "@paudigital/wdp-components/dist/components/DropDown"
import Placeholder from "./placeholder.jpeg"
import { useContactPerson, mappings } from "hooks/contactPerson"
import { Modules } from "app/enums/modules"
import { useDispatch } from "react-redux"
import { fire } from "app/redux/appEvents/appEvents.actions"
import { PossibleAppEvents } from "../AppEventsProvider/types"
import { ExpertPopupButtonPosition } from "app/redux/gaEvents/gaEvents.actions"

type Props = {
  module: Modules
}

const ContactFooter = ({ module }: Props) => {
  const { contactMapping } = useContactPerson()
  const initialValue = mappings[module]
  const [option, setOption] = useState(initialValue)
  const { translate } = useTranslate()
  const contact = contactMapping[option]
  const dispatch = useDispatch()

  const togglePopUp = () => {
    dispatch(
      fire({
        eventName: PossibleAppEvents.SHOW_EXPERT,
        uniqueIdentifier: "show-expert",
        props: {
          option,
          position: ExpertPopupButtonPosition.BOTTOM
        }
      })
    )
  }

  const handleChange = (o: OnChangeValue<CustomOptionType, false>) => {
    const value = (o as CustomOptionType).value
    setOption(value)
  }

  const person = contact?.person

  return (
    <>
      <Wrapper>
        <DropdownWrapper data-testid="questions">
          <DropDown
            hasDropdownIndicator
            onChange={handleChange}
            options={options.map(o => ({
              value: o,
              label: translate(`portal.contactfooter.option.${o}`)
            }))}
            defaultValue={translate(`portal.contactfooter.option.${option}`)}
            isTransparent
          />
        </DropdownWrapper>
        <Content>
          <Container>
            <StyledImage src={contact?.profilePicture ?? Placeholder} />
            <Details>
              <Expert>
                <ExpertName>
                  {translate("portal.contactfooter.expert.title", {
                    name: person?.firstName
                  })}
                </ExpertName>
                <ExpertDescription>
                  {translate(
                    `portal.contactfooter.expert.${option}.description`,
                    {
                      name: person?.firstName
                    }
                  )}
                </ExpertDescription>
              </Expert>
              <ContactButtonWrapper>
                <Button
                  onClick={togglePopUp}
                  label={translate("portal.contactfooter.button.label", {
                    name: person?.firstName
                  })}
                />
              </ContactButtonWrapper>
            </Details>
          </Container>
        </Content>
      </Wrapper>
    </>
  )
}

export default ContactFooter
