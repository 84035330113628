import { AnswerCode, AnswerCodeIds } from "app/types/ticket"
import { array, mixed, object, string } from "yup"

export const notRequiredLocation: { [key: string]: string[] } = {
  _WDP_BUITENTERREIN: ["04"],
  _WDP_DAK: ["01", "02", "03", "04", "05", "06"]
}

export const makeLocationNotRequired = (
  answerCodes?: AnswerCode[]
): boolean => {
  if (!answerCodes) return false
  const answercode = answerCodes.find(a => {
    const category = notRequiredLocation[a.id]
    return category?.includes(a.value as string)
  })
  return answercode ? true : false
}

export const hideLocation: { [key: string]: string[] } = {
  _WDP_DAK: ["07"],
  _WDP_BUITENTERREIN: ["02", "03", "03"]
}

export const hideAppointment: { [key: string]: string[] } = {
  _WDP_DAK: ["07"],
  _WDP_BUITENTERREIN: ["01", "02", "03", "04", "05", "06"],
  _WDP_VLOEREN: ["06"]
}

export const hideLocationInput = (answerCodes?: AnswerCode[]): boolean => {
  if (!answerCodes) return false
  const answercode = answerCodes.find(a => {
    const category = hideLocation[a.id]
    return category?.includes(a.value as string)
  })
  return answercode ? true : false
}

export const validationSchema = object({
  problem: string().when("answerCodes", (answerCodes: AnswerCode[]) => {
    if (makeLocationNotRequired(answerCodes)) {
      return string()
    }
    if (!hideLocationInput(answerCodes)) {
      return string()
        .required(
          "portal.facilitymanagement.createticket.step2.location.error.required"
        )
        .test(
          "length under 30",
          "portal.facilitymanagement.createticket.step2.location.error.max",
          val => (val && val?.length <= 30 ? true : false)
        )
    }
    return mixed()
  }),
  answerCodes: array().test(
    "answerCodes",
    "answerCodes",
    (answerCodes: any) => {
      const shouldShowLightBulbsInput = answerCodes?.find(
        (a: AnswerCode) =>
          a.id === "_WDP_ELEKTRICITEIT" &&
          (a.value === "02" || a.value === "01")
      )
      if (shouldShowLightBulbsInput) {
        const lightbulbs = answerCodes.find(
          (a: AnswerCode) => a.id === AnswerCodeIds.WDP_ELEKTRICITEIT2
        )
        if (!lightbulbs) return true
        if (!lightbulbs.value) return false
        return Number.isInteger(Number(lightbulbs.value))
      }
      const gatesValue = answerCodes?.find(
        (a: AnswerCode) => a.id === "_WDP_POORT_ETC"
      )
      if (gatesValue) {
        const shouldShowGatesInput = answerCodes.find(
          (a: AnswerCode) => a.id === "_WDP_POORT_ETC2"
        )
        const value = shouldShowGatesInput?.value
        if (!value || value.length === 0) return false
        return true
      }
      return true
    }
  ),
  explanationPriority: string().when("priority", {
    is: true,
    then: string().required(
      "portal.facilitymanagement.createticket.step2.urgent.error.required"
    )
  })
})
