import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 2.4rem 0;
  align-items: flex-start;

  @media only screen and (${theme.breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr;
    gap: 0 2.4rem;
  }
`

export const StyledBuildingEmptyContainer = styled.div`
  background: ${theme.colors.neutral.white};
  border-radius: 4px;
  padding: 3.6rem 2.4rem;
  height: calc(100% - 2*3.6rem);
  @media only screen and (${theme.breakpoints.desktop}) {
    padding: 4.4rem;
    height: calc(100% - 2*4.4rem);
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledBuildingEmpty = styled.div`
  width:100%;
  text-align: center;
`

export const StyledBuildingEmptyContent = styled.div`
  width:100%;
  text-align: center;
  font-size: ${theme.fontSizes.paragraph};
  font-weight: ${theme.fontWeights.bold};
  margin-top: 1rem;
`

export const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 2.4rem 0;
`
