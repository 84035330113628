import { theme } from "@paudigital/wdp-components"
import styled from "styled-components"

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.8rem 0 1.6rem;
`

export const ErrorText = styled.span`
  font-size: ${theme.fontSizes.captionSmall};
  line-height: ${theme.lineHeights.captionSmall};
  color: ${theme.colors.system.error};
  padding-left: 0.8rem;
`
